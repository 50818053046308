import { Fetcher } from "../../../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../../../utils/const";
import { GetIPAddressesTableDataI, SetTableData } from "./IPAddressesI";

const fetcher = new Fetcher();

export class IPAddressesIFunction {
  async getIPAddressesTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    agencyId: string,
  ) {
    setLoading(true);
    let response = await fetcher.get(`/agency/${agencyId}/ip-whitelisting`);
    if (response?.Success && response?.Data) {
      const TableJson = this.createTableJson(response?.Data);
      setTableData(TableJson);
    }
    setLoading(false);
  }

  createTableJson(data: GetIPAddressesTableDataI[]) {
    return data?.map((val: GetIPAddressesTableDataI, i: number) => {
      return {
        "IP Address": val.WhitelistIPAddress,
        Description: val.Description,
        Status: val.Status ? "Active" : "InActive",
        WhitelistIPAddressId: val.WhitelistIPAddressId,
      };
    });
  }

  async deleteIPAddress(
    whitelistIPAddressId: string,
    setLoading: (data: boolean) => void,
    setdeletePopup: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.delete(
      `/agency/${whitelistIPAddressId}/ip-whitelisting`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    if (response?.Success) {
      setLoading(false);
      setdeletePopup(false);
    }
  }
}
