import { MultipleAddressComponents } from "./MultipleAddress";
import { AddressIProps } from "../../ExternalContactI";

export const Address: React.FC<AddressIProps> = ({
  Mainfields,
  setMainfields,
  addressval,
}) => {
  return (
    <>
      <div className="AddressSCroll">
        <div className="Involvement_left_row_checkbox"></div>
        <MultipleAddressComponents
          addressval={addressval}
          Mainfields={Mainfields}
          setMainfields={setMainfields}
        />
      </div>
    </>
  );
};
