import { Fetcher } from "../../../../../../../services/Fetcher";
import {
  GetRolePermissionTableDataI,
  SetTableData,
} from "./RolePermissionIProps";

const fetcher = new Fetcher();
export class RolePermissionTabFunction {
  async getRoleTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    roleId: string,
    setFiltertableData: (data: SetTableData[]) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/role/${roleId}/role-permission`);
    if (response?.Success && response?.Data) {
      const TableJson = this.createTableJson(response?.Data).sort((a, b) => {
        const aHasPermissions = Number(
          a.Create || a.Read || a.Delete || a.General || a.Update
        );
        const bHasPermissions = Number(
          b.Create || b.Read || b.Delete || b.General || b.Update
        );
        return bHasPermissions - aHasPermissions;
      });
      setTableData(TableJson);
      setFiltertableData(TableJson);
    }
    setLoading(false);
  }

  createTableJson(data: GetRolePermissionTableDataI[]) {
    return data?.map((val: GetRolePermissionTableDataI, i: number) => {
      return {
        PermissionID: val.PermissionID,
        Permission: val.PermissionName,
        Description: val.Description,
        General: val.General,
        Create: val.Create,
        Read: val.Read,
        Update: val.Update,
        Delete: val.Delete,
        PolicyNames: val.PolicyNames,
      };
    });
  }

  applyFilterOnUserpolicyTable(
    PermissionFilter: string,
    FiltertableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void
  ) {
    let filteredData: SetTableData[] = [];
    if (PermissionFilter) {
      console.log(FiltertableData);
      filteredData = FiltertableData.filter((item) => {
        return (
          item["Permission"] &&
          item["Permission"]
            .toLowerCase()
            .includes(PermissionFilter.toLowerCase().trim())
        );
      });
    } else {
      filteredData = FiltertableData;
    }
    setTableData(filteredData);
  }
}
