import React, { useEffect, useState } from "react";
import close from "../../../../../../../assets/icons/close-circle.svg";

import { TextBoxComponent } from "../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { FilterIProps } from "./FilterIProps";
import { ButtonIState } from "../../../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";

export const Filter: React.FC<FilterIProps> = ({
    policyNameFilter,
    setpolicyNameFilter,
  
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
    useEffect(() => {
      if (policyNameFilter && policyNameFilter.trim().length > 0) {
        setIsAddButtonDisabled(false);
        setButtonTextColor("red");
      } else {
        setIsAddButtonDisabled(true);
      setButtonTextColor("black");
      }
    }, [policyNameFilter]);
  const clearAllFilters = () => {
    setpolicyNameFilter("");
    setIsAddButtonDisabled(true);
      setButtonTextColor("black");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds">
          <TextBoxComponent
            fieldName={"Role Name"}
            value={policyNameFilter}
            onChangeValue={setpolicyNameFilter}
          />
        </div>
        <div className="clearbutton">
          <div className="clearbutton-sub">
          <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
