
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (
 
): ColDef[] => {
  return [
    { field: "Type" },
    { field: "Make" },
    { field: "Model" },
    { field: "Year" },
    { field: "Color" },
   
  ];
};
