import React, { useEffect, useState } from "react";
import { BackButtonComponent } from "../../../../../CommonComponents/BackButton/BackButtonComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { useNavigate, useParams } from "react-router-dom";
import { NarrativeListFunction } from "../../NarativeListing/NarrativeFunction";
import { FilterIProps } from "../../NarativeListing/Components/FilterIProps";
import {
  NarrativeHistroy,
  NarrativeIParams,
  narrativeTypeDropdown,
} from "../../NarativeListing/NarrativeIState";
import "../../../Narratives/narrative.css";
import { EditIcon } from "../../../../../../assets";
import { formatDatewithTime } from "../../../../../../utils/Helper/Helper";
import BreadcrumbComponent from "src/Components/CommonComponents/breadcrumb/Breadcrumb";
const NarrativeContentFilter: React.FC<FilterIProps> = ({
  setType,
  overView,
  setVersion,
  version,
}) => {
  const navigate = useNavigate();
  const { incidentId, narrativesId } = useParams<NarrativeIParams>();
  const narrativeFunction = new NarrativeListFunction();
  const [RaceDataData, setRaceDataData] = useState<narrativeTypeDropdown[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<NarrativeHistroy[]>([]);
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const backButtonHandler = () => {
    navigate(`/incidents/${incidentId}/narratives`);
  };

  const editNarrativeHandler = () => {
    navigate(`/incidents/${incidentId}/narratives/${narrativesId}/edit`);
  };
  const initialRender = () => {
    if (narrativesId && incidentId) {
      narrativeFunction.getRankData(setRaceDataData);
      narrativeFunction.getHistoryData(
        setLoading,
        setHistory,
        narrativesId,
        incidentId
      );
    }
  };
  useEffect(() => {
    initialRender();
  }, []);

  useEffect(() => {
    if (history && history.length > 0 && setVersion) {
      setVersion(history[0]?.NarrativeHistoryId);
    }
  }, [history, setVersion]);

  return (
    <>
      <div className="_inner_narrative_filter_container">
         <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path:  `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              { title: "Narratives", path:  `/incidents/${incidentId}/narratives` },
              { title: "View", path: "" }
            ]}
          />
        <div className="">
          <BackButtonComponent
            name="Back to narratives"
            onClick={backButtonHandler}
          />
        </div>

        <div className="_narrative_filter_container _narrative_filters">
          <div className="_narrative_filter_left_container">
            <div className="_narrative_overview_title">
              <h3>Narrative</h3>
            </div>

            <div className="_narratives_overview_dropdown_filter">
              <div className="_narrative_report_type">
                <DropdownComponent
                  dataSource={RaceDataData?.map((val) => {
                    return {
                      id: val?.AgencyLevelCodeId,
                      value: val?.LongCode,
                    };
                  })}
                  handleRowClick={(newValue) => {
                    setType(newValue);
                  }}
                  fieldName="Report Type"
                  value={overView?.NarrativeTypeCodeId}
                />

                {/* <TextBoxComponent
                                  fieldName='Report Type'
                                  value={overView?.LongCode}
                                /> */}
              </div>

              <div className="_narrative_version_history">
                <DropdownComponent
                  dataSource={history?.map((val) => {
                    return {
                      id: `${val?.NarrativeHistoryId}`,
                      value:
                        val?.CreatedByUser +
                        " " +
                        formatDatewithTime(val?.CreatedDate) +
                        ` version ${val?.Version}`,
                    };
                  })}
                  handleRowClick={(e) => {
                    if (setVersion) {
                      setVersion(e);
                    }
                  }}
                  fieldName="Version History"
                  value={version}
                />
              </div>
            </div>
          </div>

          <div className="">
            <ButtonComponents
              name="Edit narrative"
              showBackgroundColor={true}
              textColor="white"
              color="rgb(59, 114, 177)"
              borderColor="rgb(59, 114, 177)"
              handleClick={editNarrativeHandler}
              icon={EditIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NarrativeContentFilter;
