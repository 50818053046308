import { TextBoxComponent } from "../../../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import {
  WarrantNumberDataUIIProps,
  WarrantNumberIProps,
} from "./WarrantNumberI";
import { Add } from "../../../../../../../../../../../assets";

const WarrantNumberDataUI: React.FC<WarrantNumberDataUIIProps> = ({
  Mainfields,
  setMainfields,
  WarrantNumberval,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number
  ) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Arrestinformation: {
          ...Mainfields.InvolvementTab.Arrestinformation,
          WarrantNumber:
            Mainfields.InvolvementTab.Arrestinformation.WarrantNumber.map(
              (val) => {
                if (id == val.id) {
                  return {
                    ...val,
                    [key]: value,
                  };
                } else {
                  return { ...val };
                }
              }
            ),
        },
      },
    });
  };

  return (
    <>
      <div style={{ marginTop: "1%" }}>
        <TextBoxComponent
          onChangeValue={(e) => {
            onChangeValueNumber("WarrantNumber", e, WarrantNumberval.id);
          }}
          value={WarrantNumberval.WarrantNumber}
          fieldName=""
        />
      </div>
    </>
  );
};

export const WarrantNumber: React.FC<WarrantNumberIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const handleClick = () => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Arrestinformation: {
          ...Mainfields.InvolvementTab.Arrestinformation,
          WarrantNumber: [
            ...Mainfields.InvolvementTab.Arrestinformation.WarrantNumber,
            {
              id:
                Mainfields.InvolvementTab.Arrestinformation.WarrantNumber
                  .length + 1,
              WarrantNumber: "",
              WarrantId: "",
            },
          ],
        },
      },
    });
  };

  return (
    <>
      <div>
        <p>Warrant Number</p>
        {Mainfields.InvolvementTab.Arrestinformation.WarrantNumber.map(
          (WarrantNumberval) => {
            return (
              <>
                <WarrantNumberDataUI
                  Mainfields={Mainfields}
                  setMainfields={setMainfields}
                  WarrantNumberval={WarrantNumberval}
                />
              </>
            );
          }
        )}
        <div className="edit-button_">
          <ButtonComponents
            name="Add"
            handleClick={handleClick}
            icon={Add}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
          />
        </div>
      </div>
    </>
  );
};
