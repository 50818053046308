import React, { useEffect, useState } from "react";
import { Table } from "../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { CADOverviewFunction } from "./CADOverviewFunction";
import { useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { Cadcomment, cadDetailsIRes, SetTableData } from "./CADOverviewI";
import { Loader } from "src/Components/CommonComponents/Loader/Loader";
import "./CADOverview.css";
import BreadcrumbComponent from "src/Components/CommonComponents/breadcrumb/Breadcrumb";
import { formatDatewithTime } from "src/utils/Helper/Helper";

const CADOverview = () => {
  const cADOverviewFunction = new CADOverviewFunction();
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [CADComment, setCADComment] = useState<Cadcomment[]>([]);
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const [cadDetails, setcadDetails] = useState<cadDetailsIRes>({
    Address: "",
    CadincidentId: "",
    CallbackPhone: "",
    CallerLocation: "",
    CallerName: "",
    CallerType: "",
    IncidentDate: "",
    IncidentId: "",
    IncidentType: "",
    PriorityNumber: "",
    ProblemDescription: "",
    ResponseDate: "",
    TimeFirstCallCleared: "",
    TimeFirstUnitArrived: "",
    TimeFirstUnitEnroute: "",
  });

  const initialRender = () => {
    if (incidentId) {
      cADOverviewFunction.getCADcommentData(
        setLoading,
        incidentId,
        setCADComment
      );

      cADOverviewFunction.getcadDetails(
        setLoading,
        incidentId,
        setcadDetails,
        setTableData,
        setTableTotalRecord
      );
    }
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <div className="UserManagement">
      <Loader loading={loading} />
      <BreadcrumbComponent
        fixedCrumbItems={[
          { title: "Incidents", path: "/incidents" },
          { title: incidentNumber ? incidentNumber : "", path: "" },
          { title: "CAD Overview", path: "" },
        ]}
      />
      <div className="cad">
        <div className="page-container">
          <div className="section">
            <h2 className="cad_Info">CAD Information</h2>
            <div className="overview-grid">
              <div className="overview-label">Problem Description:</div>
              <div className="overview-value">
                {cadDetails?.ProblemDescription}
              </div>
              <div className="overview-label">Incident Type:</div>
              <div className="overview-value">{cadDetails?.IncidentType}</div>
              <div className="overview-label">Address:</div>
              <div className="overview-value">{cadDetails?.Address}</div>
              <div className="overview-label">Priority Number</div>
              <div className="overview-value">{cadDetails?.PriorityNumber}</div>

              <div className="overview-label">Incident Date:</div>
              <div className="overview-value">
                {cadDetails?.IncidentDate
                  ? formatDatewithTime(cadDetails?.IncidentDate)
                  : ""}
              </div>
              <div className="overview-label">Caller Name:</div>
              <div className="overview-value">{cadDetails?.CallerName}</div>
              <div className="overview-label">Callback Phone:</div>
              <div className="overview-value">{cadDetails?.CallbackPhone}</div>

              <div className="overview-label">Priority Number:</div>
              <div className="overview-value">{cadDetails?.PriorityNumber}</div>
              <div className="overview-label">Caller Type:</div>
              <div className="overview-value">{cadDetails?.CallerType}</div>
              <div className="overview-label">Response Date:</div>
              <div className="overview-value">
                {cadDetails?.ResponseDate
                  ? formatDatewithTime(cadDetails?.ResponseDate)
                  : ""}
              </div>
              <div className="overview-label">Time First Unit Enroute:</div>
              <div className="overview-value">
                {cadDetails?.TimeFirstUnitEnroute
                  ? formatDatewithTime(cadDetails?.TimeFirstUnitEnroute)
                  : ""}
              </div>
              <div className="overview-label">Time First Unit Arrived:</div>
              <div className="overview-value">
                {cadDetails?.TimeFirstUnitArrived
                  ? formatDatewithTime(cadDetails?.TimeFirstUnitArrived)
                  : ""}
              </div>
              <div className="overview-label">Time First Call Cleared:</div>
              <div className="overview-value">
                {cadDetails?.TimeFirstCallCleared
                  ? formatDatewithTime(cadDetails?.TimeFirstCallCleared)
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="cad_Info">Employees</h2>
          <Table
            columns={coloum()}
            columnsValue={tableData}
            cursorPointer={true}
            tableTotalRecord={tableTotalRecord}
            setpageCount={setpageCount}
            setpageNumber={setpageNumber}
          />
        </div>

        <div className="cad_">
          <h3 className="cad_Info">CAD Comments:</h3>

          {CADComment.map((CADCommentval) => {
            return (
              <>
                <p className="cadline">{CADCommentval.Comment}</p>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CADOverview;
