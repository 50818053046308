import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import React from "react";
import { SetTableData } from "./UserPermissionI";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";

export const coloum: (
  tableData: SetTableData[],
  setTableData: (data: SetTableData[]) => void,
  setUserPolicyAddData: (data: SetTableData[]) => void,
  userPolicyAddData: SetTableData[],
  Update: boolean
) => ColDef[] = (
  tableData: SetTableData[],
  setTableData: (data: SetTableData[]) => void,
  setUserPolicyAddData: (data: SetTableData[]) => void,
  userPolicyAddData: SetTableData[],
  Update: boolean
) => {
  return [
    { field: "Permission" },
    { field: "Description" },
    {
      field: "General",
      tooltipValueGetter: (params) => {
        return `${params.data.GroupPolicy?.General.Policies.join(",")} - ${
          params.data.GroupPolicy?.General.Individual ? "Individual" : ""
        }`;
      },
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.General == "boolean") {
          return (
            <div>
              <CheckBoxComponent
                colorCoding={
                  params.data.GroupPolicy?.General.Individual &&
                  params.data.General
                    ? "green"
                    : params.data.GroupPolicy?.General.Individual &&
                      !params.data.General
                    ? "red"
                    : "blue"
                }
                disabled={!Update}
                onChangeValue={() => {
                  let temp = tableData.map((val) => {
                    if (val.PermissionId == params.data.PermissionId) {
                      const updatedRolePolicyAddData = userPolicyAddData.map(
                        (item) =>
                          item.PermissionId === params.data.PermissionId
                            ? { ...item, General: !params.data.General }
                            : item
                      );

                      const isPermissionIdPresent = userPolicyAddData.some(
                        (item) => item.PermissionId === params.data.PermissionId
                      );

                      const finalRolePolicyAddData = isPermissionIdPresent
                        ? updatedRolePolicyAddData
                        : [
                            ...userPolicyAddData,
                            {
                              ...params.data,
                              General: !params.data.General,
                              Create: null,
                              Read: null,
                              Update: null,
                              Delete: null,
                            },
                          ];

                      setUserPolicyAddData(finalRolePolicyAddData);

                      return { ...params.data, General: !params.data.General };
                    } else {
                      return val;
                    }
                  });
                  setTableData(temp);
                }}
                value={params.data.General}
              />
            </div>
          );
        }
      },
    },

    {
      field: "Create",
      tooltipValueGetter: (params) =>
        `${params.data.GroupPolicy?.Create.Policies.join(",")} - ${
          params.data.GroupPolicy?.Create.Individual ? "Individual" : ""
        }`,
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Create == "boolean") {
          return (
            <CheckBoxComponent
              colorCoding={
                params.data.GroupPolicy?.Create.Individual && params.data.Create
                  ? "green"
                  : params.data.GroupPolicy?.Create.Individual &&
                    !params.data.Create
                  ? "red"
                  : "blue"
              }
              disabled={!Update}
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = userPolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Create: !params.data.Create }
                          : item
                    );

                    const isPermissionIdPresent = userPolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...userPolicyAddData,
                          {
                            ...params.data,
                            Create: !params.data.Create,
                            Read: null,
                            Update: null,
                            General: null,
                            Delete: null,
                          },
                        ];

                    setUserPolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Create: !params.data.Create };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Create}
            />
          );
        }
      },
    },

    {
      field: "Read",
      tooltipValueGetter: (params) =>
        `${params.data.GroupPolicy?.Read.Policies.join(",")} - ${
          params.data.GroupPolicy?.Read.Individual ? "Individual" : ""
        }`,
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Read == "boolean") {
          return (
            <CheckBoxComponent
              colorCoding={
                params.data.GroupPolicy?.Read.Individual && params.data.Read
                  ? "green"
                  : params.data.GroupPolicy?.Read.Individual &&
                    !params.data.Read
                  ? "red"
                  : "blue"
              }
              disabled={!Update}
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = userPolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Read: !params.data.Read }
                          : item
                    );

                    const isPermissionIdPresent = userPolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...userPolicyAddData,
                          {
                            ...params.data,
                            Read: !params.data.Read,
                            Update: null,
                            Create: null,
                            General: null,
                            Delete: null,
                          },
                        ];

                    setUserPolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Read: !params.data.Read };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Read}
            />
          );
        }
      },
    },
    {
      field: "Update",
      tooltipValueGetter: (params) =>
        `${params.data.GroupPolicy?.Update.Policies.join(",")} - ${
          params.data.GroupPolicy?.Update.Individual ? "Individual" : ""
        }`,
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Update == "boolean") {
          return (
            <CheckBoxComponent
              disabled={!Update}
              colorCoding={
                params.data.GroupPolicy?.Update.Individual && params.data.Update
                  ? "green"
                  : params.data.GroupPolicy?.Update.Individual &&
                    !params.data.Update
                  ? "red"
                  : "blue"
              }
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = userPolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Update: !params.data.Update }
                          : item
                    );

                    const isPermissionIdPresent = userPolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...userPolicyAddData,
                          {
                            ...params.data,
                            Update: !params.data.Update,
                            Create: null,
                            General: null,
                            Read: null,
                            Delete: null,
                          },
                        ];

                    setUserPolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Update: !params.data.Update };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Update}
            />
          );
        }
      },
    },

    {
      field: "Delete",
      tooltipValueGetter: (params) =>
        `${params.data.GroupPolicy?.Delete.Policies.join(",")} - ${
          params.data.GroupPolicy?.Delete.Individual ? "Individual" : ""
        }`,
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Delete == "boolean") {
          return (
            <CheckBoxComponent
              disabled={!Update}
              colorCoding={
                params.data.GroupPolicy?.Delete.Individual && params.data.Delete
                  ? "green"
                  : params.data.GroupPolicy?.Delete.Individual &&
                    !params.data.Delete
                  ? "red"
                  : "blue"
              }
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = userPolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Delete: !params.data.Delete }
                          : item
                    );

                    const isPermissionIdPresent = userPolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...userPolicyAddData,
                          {
                            ...params.data,
                            Delete: !params.data.Delete,
                            Create: null,
                            General: null,
                            Read: null,
                            Update: null,
                          },
                        ];

                    setUserPolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Delete: !params.data.Delete };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Delete}
            />
          );
        }
      },
    },
  ];
};
