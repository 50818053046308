import React, { useEffect, useState } from "react";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { useNavigate } from "react-router-dom";
import { FilterIProps } from "./FilterIProps";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";

export const Filter: React.FC<FilterIProps> = ({
  Name,
  setName,
  Type,
  setType,
  Date,
  setDate,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const navigation = useNavigate();
  const [nameType, setNameType] = useState<string>("");
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const clearAllFilters = () => {
    console.log("dewsj");
    setName("");
    setType("");
    setDate("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };

  useEffect(() => {
    if (Name.trim() !== "" || Type !== "" || Date !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, Type]);

  const handleClick = () => {
    navigation("/incidents/related-cases/add");
  };
  return (
    <>
      <div className="cases_related_filter_container">
        <div className="cases_left_filter_container">
          <div className="search_related_textinput">
            <TextBoxComponent
              fieldName={"Search Cases"}
              value={Name}
              onChangeValue={setName}
            />
          </div>
          <div className="search_related_ageny">
            <DropdownComponent dataSource={[]} fieldName={"Agency"} />
          </div>
          <div className="search_related_ageny">
            <DropdownComponent dataSource={[]} fieldName={"Type"} />
          </div>
          <div className="_case_filter_date_container">
            <DateBoxComponents fieldName="Date  Start" />
          </div>
          <div className="_case_filter_date_container">
          <DateBoxComponents fieldName="Date  End" />

          </div>
          <div className="clearbutton">
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              // handleClick={handleClick}
            />
          </div>
          <div className="clearbutton">
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
