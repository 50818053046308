import { Fetcher } from "../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../utils/const";
import {
  locationTableData,
  locationTableDataList,
} from "./LocationOverviewIState";
import { addressesList } from "./LocationOverviewIState";
const fetcher = new Fetcher();
interface OrderByFieldNameLocation {
  Address: string;
}
type OrderByType = "Address";

const OrderByFieldName: OrderByFieldNameLocation = {
  Address: "Address",
};
export class LocationMainFunction {
  async locationList(
    involvementId: string,
    settableData: (data: locationTableData[]) => void,
    setLoading: (data: boolean) => void,
    SearchText: string,
    setTableTotalRecord: (data: number) => void,
    pageNumber?: number,
    pageCount?: number,
    OrderBy?: string,
    OrderByDescending?: boolean
  ) {
    setLoading(true);
    let response = await fetcher.post(`/commonentity/getaddresses`, {
      EntityType: "Incident",
      EntityId: involvementId,
      SearchText: SearchText,
      PageNumber: pageNumber,
      PageSize: pageCount,
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
    });
    let res: locationTableDataList[] = response?.Data;
    setLoading(false);
    const TableJson = this.createTableJson(res);
    settableData(TableJson);
    setTableTotalRecord(response?.TotalRecords);
    return response;
  }
  createTableJson(data: locationTableDataList[]) {
    return data?.map((val: locationTableDataList, i: number) => {
      return {
        Primary: val?.IsPrimary ? "Yes" : "No",
        Address:
          val?.Street1Name +
          " " +
          val?.StateName +
          " " +
          val?.City +
          " " +
          val?.ZipCode,
        LocationId: val?.LocationId,
      };
    });
  }

  async locationAddressSave(
    {
      addLocationAddress,
    }: {
      addLocationAddress: addressesList;
    },
    incidentId: string,
    goTOEDitMOde: (data: string) => void
  ) {
    const responce = await fetcher.post(`/commonentity/save-address`, {
      Address: addLocationAddress,
      EntityModel: {
        EntityType: "Incident",
        EntityId: incidentId,
      },
    });

    return responce;

    // goTOEDitMOde()
  }

  async deleteLocationList(id: string, setLoading: (data: boolean) => void) {
    setLoading(true);
    const res = fetcher.delete(
      `/commonentity/deleteaddres/${id}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    return res;
  }

  async getAddressById(
    id: string | undefined,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const res = await fetcher.post(`/commonentity/getaddress/${id}`, {});
    setLoading(false);
    return res;
  }

  async updateAddressById(
    setLoading: (data: boolean) => void,
    { addLocationAddress }: { addLocationAddress: addressesList },
    incidentId: string
  ) {
    setLoading(true);
    const res = await fetcher.post(`/commonentity/save-address`, {
      Address: addLocationAddress,
      EntityModel: {
        EntityType: "Incident",
        EntityId: incidentId,
      },
    });
    setLoading(false);
    return res;
  }
}
