import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { TabsIndex } from "../Components/Tab/TabsIndex";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import moment from "moment-timezone";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

export const EditRole: React.FC = () => {
  const { RoleName, Status,ModifiedOn,ModifiedByUserName } = useSelector(
    (store: RootState) => store.RoleReducer
  );
  
  const dateFormate=moment
  .utc(ModifiedOn)
  .local()
  .format("MM-DD-YYYY HH:mm:ss");
  const Navigation = useNavigate();
  const NavigateToRolePage = () => {
    Navigation("/role");
  };

  return (
    <>
      <div className="AddPolicy">
        <div className="AddPolicysub">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Role", path: "/role" },
              { title: "Edit", path: "" },
            ]}
          />
          <div className="lineHeight">
            <BackButtonComponent
              name="Back to Role Listing"
              onClick={NavigateToRolePage}
            />
          </div>
          <div className="EditAgencyManagemant_topdetail">
          <p className="header">{RoleName}</p>
          <p><strong>Status: </strong> {Status == "1" ? "Active" : "InActive"}</p>
          <p><strong>Last Modified By: </strong>{ModifiedByUserName} on {dateFormate}</p>
          </div>

          <TabsIndex viewRoleComponent={false} />
        </div>
      </div>
    </>
  );
};
