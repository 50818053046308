import React, { useState } from "react";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import {
  AddEditFormI,
  AddEditFormIParams,
  SetFormDataIState,
  setFormDataValidation,
} from "./AddEditFormI";
import { AddEditFormFunction } from "./AddEditFormFunction";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { RootState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setPolicyeditComponent } from "../../../../../../Redux/PolicyReducer";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";

export const AddEditForm: React.FC<AddEditFormI> = ({
  viewPolicyComponent,
}) => {
  const { Description, PolicyName, Status } = useSelector(
    (store: RootState) => store.PolicyReducer
  );

  const { policyId } = useParams<AddEditFormIParams>();
  const dispatch = useDispatch();
  console.log(viewPolicyComponent);
  const [edit, setEdit] = useState<boolean>(viewPolicyComponent ? true : false);
  const [formData, setformData] = useState<SetFormDataIState>({
    Description: Description ? Description : "",
    PolicyName: PolicyName ? PolicyName : "",
    Status: Status ? Status : "1",
  });
  const addEditFormFunction = new AddEditFormFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [formDataValidation, setFormDataValidation] =
    useState<setFormDataValidation>({
      PolicyName: false,
      Description: false,
      IsActive: false,
    });
  const handleInputChange = (key: string, val: number | string) => {
    setformData({
      ...formData,
      [key]: val,
    });
    setFormDataValidation({
      ...formDataValidation,
      [key]: false,
    });
    dispatch(setchangeTheForm(true));
  };
  const editDisable = () => {
    setformData({
      Description: Description ? Description : "",
      PolicyName: PolicyName ? PolicyName : "",
      Status: Status ? Status : "1",
    });
    dispatch(setchangeTheForm(false));
  };
  const onSave = () => {
    addEditFormFunction.errorValidation(formData, setFormDataValidation);
    if (formData.PolicyName) {
      if (addEditFormFunction.checkRequiredFields(formData)) {
        if (policyId) {
          addEditFormFunction.editAgency(setLoading, policyId, formData);
          dispatch(setchangeTheForm(false));
        } else {
          if (formData.PolicyName.length) {
            addEditFormFunction.addAgency(setLoading, formData);
            dispatch(setchangeTheForm(false));
          }
        }
      }
    }
  };

  const allowEdit = () => {
    setEdit(false);
    dispatch(
      setPolicyeditComponent({
        viewPolicyComponent: false,
        addPolicyComponent: false,
        editPolicyComponent: true,
      })
    );
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="DetailTab_top">
        <div className="DetailTab_top_header_">
          <div className="DetailTab_top_header_sub">
            <p>New Policy</p>
            {policyId && viewPolicyComponent ? (
              <p onClick={allowEdit} className="SummaryTab_top_header_edit">
                Edit
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="add-ip-content">
          <div className="add-ip-dropdown">
            <DropdownComponent
              dataSource={[
                { id: "1", value: "Active" },
                { id: "2", value: "InActive" },
              ]}
              disabled={edit}
              fieldName="Status*"
              value={formData.Status}
              handleRowClick={(newValue) => {
                handleInputChange("Status", newValue);
              }}
              validation={formDataValidation.IsActive}
              showTooltip={false}
            />
          </div>
          <div className="add-ip-textbox">
            <TextBoxComponent
              disabled={edit}
              value={formData.PolicyName}
              fieldName="Policy Name*"
              onChangeValue={(newValue) => {
                handleInputChange("PolicyName", newValue);
              }}
              validation={formDataValidation.PolicyName}
            />
          </div>
          <div className="add-ip-Desc">
            <TextAreaComponent
              disabled={edit}
              value={formData.Description}
              fieldName="Description*"
              onChangeValue={(newValue) => {
                handleInputChange("Description", newValue);
              }}
              validation={formDataValidation.Description}
            />
          </div>
        </div>
      </div>{" "}
      <div className="button-ip">
        <div className="edit-button">
          {!edit && <Submit onCancel={editDisable} onSave={onSave} />}
        </div>
      </div>
    </>
  );
};
