import { useEffect, useState } from "react";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { FilterIProps } from "./ViewMatchesI";

export const Filter: React.FC<FilterIProps> = ({ Name, setName, onSearch }) => {
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (Name && Name.trim().length > 0) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name]);
  return (
    <>
      <div className="Filter">
        <div className="filds___ ">
          <div className="search_narrative_conatiner">
            <TextBoxComponent
              fieldName={"Name"}
              value={Name}
              onChangeValue={(e) => {
                setName(e);
              }}
            />
          </div>
          <div>
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              handleClick={onSearch}
            />
          </div>
        </div>
        <div className="clearbutton">
          <ButtonComponents
            name="Clear all Filters"
            textColor={buttonTextColor}
            borderColor={isAddButtonDisabled ? "grey" : "red"}
            disabled={true}
          />
        </div>
      </div>
    </>
  );
};
