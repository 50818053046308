import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Type" },
    {
      field: "Date Entered",
      valueFormatter: (params) =>
        params.value && format(new Date(params.value), "MM-dd-yyyy "),
    },
  ];
};
