import { useState } from "react";
import { TabsComponents } from "../../../../CommonComponents/TabsComponents/TabsComponents";
import { SummaryTab } from "./SummaryTab/SummaryTab";
import EmergencyContactTab from "./EmergencyContactTab/EmergencyContactTab";
import { Userpolicy } from "./Userpolicy/Userpolicy";
import { UserPermission } from "./UserPermission/UserPermission";
import RolesTab from "./Roles/RolesTab";
import { EditUserManagementIParams } from "../../EditUserManagement/EditUserManagementI";
import { useParams } from "react-router-dom";

interface TabsIndexIProps {
  viewUserComponent: boolean;
}

export const TabsIndex: React.FC<TabsIndexIProps> = ({ viewUserComponent }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { userId } = useParams<EditUserManagementIParams>();

  return (
    <>
      <div>
        <TabsComponents
          activeTab={activeTab}
          tabList={[
            { name: "Summary", id: 1 },
            { name: userId ? "Emergency Contact" : "", id:1},
            { name: userId ? "Assign Roles" : "", id: 2 },
            { name: userId ? "Assign Policy" : "", id: 3, },
            { name: userId ? "Assign Permission": "", id: 4 },
          ]}
          handleTabChange={setActiveTab}
        />

        {activeTab === 0 ? (
          <SummaryTab viewUserComponent={viewUserComponent} />
        ) : activeTab === 1  && userId ? (
          <EmergencyContactTab />
        ) : activeTab === 2 && userId ? (
          <RolesTab />
        ) : activeTab === 3 && userId ? (
          <Userpolicy />
        ) : activeTab === 4 && userId ? (
          <UserPermission />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
