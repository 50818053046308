import React, { useEffect, useState } from "react";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import add from "../../../../../../../../../assets/icons/Icon.svg";
import {
  EmployeementIProps,
  IdentificationAndContactIProps,
} from "../../../IdentificationContact/IdentificationIProps";
import { PhoneNumberE } from "./Components/EmployementPhone/EmployementPhone";
import { EmailAddress } from "./Components/EmployementEmail/EmployementEmail";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { EmployementAddress } from "./Components/EmployementAddress/Address";
import { EmploymentContact } from "./Components/EmploymentContact/EmploymentContact";
import cross from "../../../../../../../../../assets/icons/cros.svg";
import {
  EmployeementUIDataIProps,
  SystemLevelDropdownIRes,
} from "../../AdditionalIState";
import { AdditionalMainFunction } from "../../AdditionalFunction";
import { EmployeementFunction } from "./EmployeementFunction";
import { Add } from "../../../../../../../../../assets";
import { EmploymentIState } from "../../../Involvement/InvolvementMainI";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

const EmployeementUIData: React.FC<EmployeementUIDataIProps> = ({
  Mainfields,
  setMainfields,
  Employmentval,
  Participantionvalidation,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [EmployerTypeData, setEmployerTypeData] = useState<
    SystemLevelDropdownIRes[]
  >([]);
  const [OccupationData, setOccupationData] = useState<
    SystemLevelDropdownIRes[]
  >([]);
  const [ShiftData, setShiftData] = useState<SystemLevelDropdownIRes[]>([]);

  const onChangeValue = (
    key: string,
    value: string | number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes: true,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            if (key2 && value2) {
              return { ...val, [key]: value, [key2]: value2 };
            } else {
              return {
                ...val,
                [key]: value,
              };
            }
          } else {
            return val;
          }
        }),
      },
    });
  };

  const initialRender = () => {
    additionalMainFunction.getEmployerTypeData(setEmployerTypeData);
    additionalMainFunction.getOccupationData(setOccupationData);
    additionalMainFunction.getShiftData(setShiftData);
  };
  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <TextBoxComponent
          fieldName="Employer Name"
          value={Employmentval.EmployerName}
          onChangeValue={(e) => {
            onChangeValue("EmployerName", e);
          }}
        />
        <DropdownComponent
          fieldName="Employer Type"
          dataSource={EmployerTypeData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          value={Employmentval.EmployerType}
          handleRowClick={(e, val) => {
            onChangeValue("EmployerType", e, "EmployerTypeName", val);
          }}
        />
        <DropdownComponent
          fieldName="Occupation"
          dataSource={OccupationData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          value={Employmentval.Occupation}
          handleRowClick={(e, e2) => {
            onChangeValue("Occupation", e, "OccupationName", e2);
          }}
        />
        <DropdownComponent
          fieldName="Shift"
          dataSource={ShiftData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          value={Employmentval.Shift}
          handleRowClick={(e, e2) => {
            onChangeValue("Shift", e, "ShiftName", e2);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <EmployementAddress
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          Employmentval={Employmentval}
        />
        <div style={{ width: "75%", marginRight: "10%" }}>
          <EmploymentContact
            Employmentval={Employmentval}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
          />
          <PhoneNumberE
            Employmentval={Employmentval}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
            Participantionvalidation={Participantionvalidation}
          />
          <EmailAddress
            Employmentval={Employmentval}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
            Participantionvalidation={Participantionvalidation}
          />
        </div>
      </div>
    </>
  );
};

export const Employeement: React.FC<EmployeementIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<EmploymentIState[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const multiBloodFunction = new EmployeementFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    multiBloodFunction.Save();
  };

  const ViewPreview = (id: number) => {
    multiBloodFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    multiBloodFunction.DeletePreview(id);
  };

  const handleClick = async () => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        Employment: [
          ...Mainfields.AdditionalInfo.Employment.map((val) => {
            return {
              ...val,
              push: true,
            };
          }),
          {
            ExternalContactID: "",
            push: false,
            ContactFirstname: "",
            id: Mainfields.AdditionalInfo.Employment.length + 1,
            ContactLastname: "",
            EmployerName: "",
            EmployerType: "",
            Occupation: "",
            Shift: "",
            PhoneNumber: [
              {
                Country: "",
                TypeDropdownvalue: "",
                Ext: "",
                ExternalContactID: "",
                id: 1,
                Number: "",
                Type: "",
              },
            ],
            Address: {
              AddressLine1: "",
              LocationId: "",
              AddressLine2: "",
              AddressType: "",
              Apartment: "",
              City: "",
              Country: "",
              NoPermanentAddress: false,
              UnknownAddress: false,
              ForeignAddress: false,
              ForeignAddressCity: "",
              ForeignAddressCountry: "",
              ForeignAddressStateProvinceRegion: "",
              ForeignAddressZip: "",
              GridNumber: "",
              StateProvidence: "",
              Street: "",
              StreetNumber: "",
              StreetPrefix: "",
              StreetSuffix: "",
              ZipCode: "",
            },
            Email: [
              {
                Email: "",
                ExternalContactID: "",
                id: 1,
                Type: "",
                TypeDropdownvalue: "",
              },
            ],
          },
        ],
      },
    });
  };

  return (
    <div>
      <p className="subheader">Employement</p>

      <Preview
        DeletePreview={DeletePreview}
        ViewPreview={ViewPreview}
        displaylist={
          edit
            ? editList.map((val) => {
                return {
                  list: [
                    `${val.EmployerName ? `${val.EmployerName},` : ""} ${
                      val.EmployerTypeName ? val.EmployerTypeName : ""
                    }
                     ${val.OccupationName ? `${val.OccupationName},` : ""}
                     ${val.ShiftName ? `${val.ShiftName}` : ""}`,

                    `${
                      val.ContactFirstname ? `${val.ContactFirstname},` : ""
                    } ${val.ContactLastname ? `${val.ContactLastname},` : ""}`,

                    `${
                      val.Address["StreetNumber"]
                        ? `${val.Address["StreetNumber"]},`
                        : ""
                    } ${val.Address.Street ? `${val.Address.Street},` : ""} ${
                      val.Address.City ? `${val.Address.City},` : ""
                    } ${val.Address.ZipCode ? `${val.Address.ZipCode},` : ""} ${
                      val.Address.CountryName ? val.Address.CountryName : ""
                    } 
                    

                    ${
                      val.Address.AddressLine1
                        ? `,${val.Address.AddressLine1}`
                        : ""
                    }
                            ${
                              val.Address.AddressLine2
                                ? `,${val.Address.AddressLine2}`
                                : ""
                            }
                             ${
                               val.Address.ForeignAddressStateProvinceRegion
                                 ? `,${val.Address.ForeignAddressStateProvinceRegion}`
                                 : ""
                             }
                              ${
                                val.Address.ForeignAddressZip
                                  ? `,${val.Address.ForeignAddressZip}`
                                  : ""
                              }

                               ${
                                 val.Address.ForeignAddressCountry
                                   ? `,${val.Address.ForeignAddressCountry}`
                                   : ""
                               }



                    `,
                    ...val.PhoneNumber.map((PhoneNumberval) => {
                      return `${
                        PhoneNumberval.TypeDropdownvalue &&
                        PhoneNumberval.Number &&
                        "Phone : "
                      }  ${
                        PhoneNumberval.TypeDropdownvalue
                          ? `${PhoneNumberval.TypeDropdownvalue},`
                          : ""
                      } ${PhoneNumberval.Number ? PhoneNumberval.Number : ""}`;
                    }),
                    ...val.Email.map((Emailval) => {
                      return `${
                        Emailval.TypeDropdownvalue &&
                        Emailval.TypeDropdownvalue &&
                        "Email : "
                      } ${
                        Emailval.TypeDropdownvalue
                          ? `${Emailval.TypeDropdownvalue},`
                          : ""
                      } ${Emailval.Email ? Emailval.Email : ""}`;
                    }),
                  ],
                  id: val.id,
                  push: val.push ? val.push : false,
                };
              })
            : Mainfields.AdditionalInfo.Employment.map((val) => {
                return {
                  list: [
                    `${val.EmployerName ? `${val.EmployerName},` : ""} ${
                      val.EmployerTypeName ? val.EmployerTypeName : ""
                    }
                     ${val.OccupationName ? `${val.OccupationName},` : ""}
                     ${val.ShiftName ? `${val.ShiftName}` : ""}`,

                    `${
                      val.ContactFirstname ? `${val.ContactFirstname},` : ""
                    } ${val.ContactLastname ? `${val.ContactLastname},` : ""}`,

                    `${
                      val.Address["StreetNumber"]
                        ? `${val.Address["StreetNumber"]},`
                        : ""
                    } ${val.Address.Street ? `${val.Address.Street},` : ""} ${
                      val.Address.City ? `${val.Address.City},` : ""
                    } ${val.Address.ZipCode ? `${val.Address.ZipCode},` : ""} ${
                      val.Address.CountryName ? val.Address.CountryName : ""
                    } 
                    

                    ${
                      val.Address.AddressLine1
                        ? `,${val.Address.AddressLine1}`
                        : ""
                    }
                            ${
                              val.Address.AddressLine2
                                ? `,${val.Address.AddressLine2}`
                                : ""
                            }
                             ${
                               val.Address.ForeignAddressStateProvinceRegion
                                 ? `,${val.Address.ForeignAddressStateProvinceRegion}`
                                 : ""
                             }
                              ${
                                val.Address.ForeignAddressZip
                                  ? `,${val.Address.ForeignAddressZip}`
                                  : ""
                              }

                               ${
                                 val.Address.ForeignAddressCountry
                                   ? `,${val.Address.ForeignAddressCountry}`
                                   : ""
                               }



                    `,
                    ...val.PhoneNumber.map((PhoneNumberval) => {
                      return `${
                        PhoneNumberval.TypeDropdownvalue &&
                        PhoneNumberval.Number &&
                        "Phone : "
                      }  ${
                        PhoneNumberval.TypeDropdownvalue
                          ? `${PhoneNumberval.TypeDropdownvalue},`
                          : ""
                      } ${PhoneNumberval.Number ? PhoneNumberval.Number : ""}`;
                    }),
                    ...val.Email.map((Emailval) => {
                      return `${
                        Emailval.TypeDropdownvalue &&
                        Emailval.TypeDropdownvalue &&
                        "Email : "
                      } ${
                        Emailval.TypeDropdownvalue
                          ? `${Emailval.TypeDropdownvalue},`
                          : ""
                      } ${Emailval.Email ? Emailval.Email : ""}`;
                    }),
                  ],
                  id: val.id,
                  push: val.push ? val.push : false,
                };
              })
        }
      />

      <EmployeementUIData
        Employmentval={
          edit
            ? Mainfields.AdditionalInfo.Employment.filter((val) => {
                if (val.id == previewcount) {
                  return val;
                }
              })[0]
            : Mainfields.AdditionalInfo.Employment[
                Mainfields.AdditionalInfo.Employment.length - 1
              ]
        }
        Mainfields={Mainfields}
        setMainfields={setMainfields}
        Participantionvalidation={Participantionvalidation}
      />
      <div className="edit-button_">
        <ButtonComponents
          name={edit ? "Save" : "Add Employer"}
          handleClick={edit ? Save : handleClick}
          color="white"
          textColor="#3672b3"
          borderColor="#3672b3"
          showBackgroundColor={true}
          icon={Add}
        />
      </div>
    </div>
  );
};
