import { useEffect, useState } from "react";
import {
  AgencyLevelAddEditViewIProps,
  SetFormDataIState,
  codecategory,
  setagencyData,
  setvalidationIState,
} from "./AgencyLevelAddEditViewI";
import { useDispatch } from "react-redux";
import { AgencyLevelAddEditViewFunction } from "./AgencyLevelAddEditViewFunction";
import { CategoryListing } from "./CategoryListing/CategoryListing";
import add from "../../../../../../assets/icons/Icon.svg";
import { AgencyManagementFunction } from "../../../../AgencyManagement/AgencyManagementPage/AgencyManagementFunction";
import { getCurrentDate } from "../../../../../../utils/Helper/Helper";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";
import { ModalComponent } from "../../../../../CommonComponents/Modal/ModalComponent";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { AddCategory } from "../../../../../CommonComponents/Modal/AddCategory/AddCategory";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { BackButtonComponent } from "../../../../../CommonComponents/BackButton/BackButtonComponent";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";
import { Add } from "../../../../../../assets";
import BreadcrumbComponent from "src/Components/CommonComponents/breadcrumb/Breadcrumb";

export const AgencyLevelAddEditView: React.FC<AgencyLevelAddEditViewIProps> = ({
  addmode,
  editMode,
  setaddMode,
  seteditMode,
  setviewMode,
  viewMode,
  setshowAddEditView,
  getRowId,
}) => {
  const { Update } =
  useGetPerticularUserPermission("Code.System");
  const [loading, setLoading] = useState<boolean>(false);
  const agencyLevelAddEditViewFunction = new AgencyLevelAddEditViewFunction();
  const agencyManagementFunction = new AgencyManagementFunction();
  const [AddCategoryshowPopup, setAddCategoryshowPopup] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<SetFormDataIState>({
    IsActive: "1",
    Description: "",
    Category: "",
    LongCode: "",
    ShortCode: "",
    ActiveDate: getCurrentDate(),
    ExpiryDate: undefined,
    Agency: "",
  });

  const [validation, setvalidation] = useState<setvalidationIState>({
    IsActive: false,
    Description: false,
    Category: false,
    LongCode: false,
    ShortCode: false,
    ActiveDate: false,
    ExpiryDate: false,
    Agency: false,
  });

  const [disabled, setdisabled] = useState<boolean>(viewMode ? true : false);
  const dispatch = useDispatch();

  const [categoryData, setcategoryData] = useState<codecategory[]>([]);
  const [agencyData, setagencyData] = useState<setagencyData[]>([]);

  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  const handleInputChange = (field: string, value: string | number | null) => {
    dispatch(setchangeTheForm(true));
    setFormData({
      ...formData,
      [field]: value,
    });
    setvalidation({
      ...validation,
      [field]: false,
    });
  };

  const onCancel = () => {
    setFormData({
      IsActive: "1",
      Description: "",
      Category: "",
      LongCode: "",
      ShortCode: "",
      ActiveDate: getCurrentDate(),
      ExpiryDate: undefined,
      Agency: "",
    });
  };

  const AgencyLevelAdd = (SaveandAddNewinSameCategorycheck?: boolean) => {
    agencyLevelAddEditViewFunction.add(
      setLoading,
      formData,
      setshowAddEditView,
      SaveandAddNewinSameCategorycheck
    );
    dispatch(setchangeTheForm(false));
  };

  const AgencyLevelEDit = () => {
    agencyLevelAddEditViewFunction.edit(
      setLoading,
      formData,
      setshowAddEditView,
      getRowId
    );
    dispatch(setchangeTheForm(false));
  };

  const handleSubmit = async () => {
    agencyLevelAddEditViewFunction.errorValidation(formData, setvalidation);
    if (agencyLevelAddEditViewFunction.checkRequiredFields(formData)) {
      if (addmode) {
        AgencyLevelAdd();
      } else if (editMode || (viewMode && getRowId)) {
        AgencyLevelEDit();
      }
    }
  };

  const openAddCategoryPopup = () => {
    setAddCategoryshowPopup(true);
  };

  const AddCategoryCancel = () => {
    setAddCategoryshowPopup(false);
  };

  const AddCategorySave = () => {
    setAddCategoryshowPopup(false);
  };

  const getallcodecategory = () => {
    agencyLevelAddEditViewFunction.getallcodecategory(
      setcategoryData,
      undefined,
      undefined
    );
  };

  const getallcodeagency = () => {
    agencyLevelAddEditViewFunction.getallcodeagency(setagencyData);
  };

  const InitialRender = () => {
    if (viewMode || editMode || getRowId) {
      agencyLevelAddEditViewFunction.getAgencyLevelData(
        setLoading,
        setFormData,
        getRowId
      );
    }
    if (addmode) {
      setFormData({
        IsActive: "1",
        Description: "",
        Category: "",
        LongCode: "",
        ShortCode: "",
        ActiveDate: getCurrentDate(),
        ExpiryDate:null,
        Agency: "",
      });
    }
    getallcodecategory();
    getallcodeagency();
  };

  useEffect(() => {
    InitialRender();
  }, []);

  const SaveandAddNewinSameCategory = () => {
    agencyLevelAddEditViewFunction.errorValidation(formData, setvalidation);
    if (agencyLevelAddEditViewFunction.checkRequiredFields(formData)) {
      AgencyLevelAdd(true);
      setFormData({
        ...formData,
        Description: "",
        LongCode: "",
        ShortCode: "",
      });
    }
  };

  return (
    <>
      <div>
        
        <Loader loading={loading} />
        <ModalComponent
        onClose={AddCategoryCancel}
          open={AddCategoryshowPopup}
          title="Add Category"
          width={600}
          innerContant={
            <AddCategory
              onCancel={AddCategoryCancel}
              onSave={AddCategorySave}
              getCategoryData={getallcodecategory}
            />
          }
           closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
        />
        <div className="DetailTab_top">
          
          <div className="DetailTab_top_header_">
           
            <div className="DetailTab_top_header_sub">
              <p>Agency Level Code</p>
              <div className="edit_button_">
                {Update && (
 <p
 onClick={() => {
   setaddMode(false);
   seteditMode(true);
   setviewMode(false);
   setdisabled(false);
 }}
>
 {viewMode ? "Edit" : ""}
</p>
                )}
               
                <BackButtonComponent
                  onClick={() => {
                    setshowAddEditView(false);
                  }}
                  name="Back"
                />
              </div>
            </div>
          </div>

          <div className="AddEditViewNCIC">
            <div className="AgencyLevelAddEditView_1">
              <DropdownComponent
                dataSource={agencyData.map((val) => {
                  return { id: val.CodeCategoryId, value: val.CategoryName };
                })}
                value={formData.Agency}
                disabled={disabled}
                fieldName="Agency*"
                handleRowClick={(newValue) => {
                  handleInputChange("Agency", newValue);
                }}
                validation={validation.Agency}
              />

              <DropdownComponent
                dataSource={[
                  { id: "1", value: "Active" },
                  { id: "2", value: "InActive" },
                ]}
                value={formData.IsActive}
                disabled={disabled}
                fieldName="Status*"
                handleRowClick={(newValue) => {
                  handleInputChange("IsActive", newValue);
                }}
                validation={validation.IsActive}
                showTooltip={false}
              />

              <DateBoxComponents
                fieldName={"Active Date"}
                value={formData.ActiveDate}
                onValueChange={(newValue) => {
                  handleInputChange("ActiveDate", newValue);
                }}
                disabled={disabled}
              />
              <DateBoxComponents
                fieldName={"Inactive Date"}
                value={formData.ExpiryDate}
                onValueChange={(newValue) => {
                  handleInputChange("ExpiryDate", newValue);
                }}
                disabled={disabled}
              />
            </div>
            <div className="AgencyLevelAddEditView_1">
              <DropdownComponent
                disabled={disabled}
                dataSource={categoryData.map((state) => ({
                  id: state.CodeCategoryId,
                  value: state.CategoryName,
                }))}
                value={formData.Category}
                fieldName="Category*"
                handleRowClick={(newValue) => {
                  handleInputChange("Category", newValue);
                }}
                validation={validation.Category}
              />
             
                {
                  Update && (
                    <div className="AgencyLevelAddEditView_1_button">
<ButtonComponents
                  handleClick={openAddCategoryPopup}
                  name="Add Category"
                  color="white"
                    textColor="#3672b3"
                    borderColor="#3672b3"
                    showBackgroundColor={true}
                    icon={Add}
                /></div>
                  )
                }
                
              
              <TextBoxComponent
                disabled={disabled}
                value={formData.ShortCode}
                fieldName="Short Code*"
                onChangeValue={(newValue) => {
                  handleInputChange("ShortCode", newValue);
                }}
                validation={validation.ShortCode}
              />
              <TextBoxComponent
                disabled={disabled}
                value={formData.LongCode}
                fieldName="Long Code*"
                onChangeValue={(newValue) => {
                  handleInputChange("LongCode", newValue);
                }}
                validation={validation.LongCode}
              />
            </div>

            <TextAreaComponent
              disabled={disabled}
              value={formData.Description}
              fieldName="Description*"
              onChangeValue={(newValue) =>
                handleInputChange("Description", newValue)
              }
              validation={validation.Description}
            />
            <div className="add-modal"></div>
          </div>
        </div>{" "}
        {
          
            <div className="edit-button">
              {(editMode || addmode) && (
                <div className="button-ip">
                  <Submit onCancel={onCancel} onSave={handleSubmit} />
                  {addmode && (
                    <button
                      style={{ marginTop: "7px" }}
                      onClick={SaveandAddNewinSameCategory}
                      className="save"
                    >
                      Save and Add New in Same Category
                    </button>
                  )}
                </div>
              )}
            </div>
          
        }
        <p className="header">Category</p>
        <CategoryListing Category={formData.Category} />
      </div>
    </>
  );
};
