import { Fetcher } from "../../../../../../services/Fetcher";
import { successCreatedMessage, successUpdateMessage } from "../../../../../../utils/const";
import {
  combineDateTimeToISO,
  convertToISOFormat,
  convertToISOFormatDateOnly,
  formatDate,
  formatTime,
  parseISOToDateTime,
} from "../../../../../../utils/Helper/Helper";
import { setRankDataIState } from "../../../../UserManagement/Components/Tabs/SummaryTab/SummaryTabInterface";
import { GetRaceDataIState } from "../AddVehicleIState";
import { SetFormDataIState, TowDataIRes } from "./TowIState";

const fetcher = new Fetcher();
export class AddTowFunction {
  async addTow(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    incidentId: string,
    vehicleId:string,
    redirectTOEDitMOde:(data:string)=>void
  ) {
    setLoading(true);
    console.log(formData.VehicleTowId)
    try {
      // let TowTime = parseISOToDateTime(formData.TowReleaseDateTime);
      // let notificationTime = parseISOToDateTime(formData.NotificationDateTime);
      // console.log(TowTime.time,notificationTime.time)
          const isUpdating = !!formData.VehicleTowId;
      const response = await fetcher.post(
        `/incident/${incidentId}/vehicle/${vehicleId}/vehicle-tow`,
        {
          vehicleTowModel: {
            VehicleId:formData.vehicleId,
            VehicleTowId: formData.VehicleTowId ? formData.VehicleTowId : null,
            ReasonForImpound: formData.ReasonForImpound
              ? formData.ReasonForImpound
              : null,
            TowReleaseDateTime: formData.TowReleaseDateTime && formData.TowReleaseTime
            ? combineDateTimeToISO(formData.TowReleaseDateTime,formData.TowReleaseTime)
            : null,
            TowReleaseTime: formData.TowReleaseTime,
            OwnerNotifiedOfImpound: formData.OwnerNotifiedOfImpound
              ? formData.OwnerNotifiedOfImpound
              : false,
            NotificationMeans: formData.NotificationMeans
              ? formData.NotificationMeans
              : null,
            NotificationMethodId: formData.NotificationMethodId
              ? formData.NotificationMethodId
              : null,
            NotificationTime:formData.NotificationTime,
            NotificationDateTime:  formData.NotificationDateTime && formData.NotificationTime
            ? combineDateTimeToISO(formData.NotificationDateTime,formData.NotificationTime)
            : null,
            CanVehicleBeReleased: formData.CanVehicleBeReleased
              ? formData.CanVehicleBeReleased
              : false,
            ReasonForHold: formData.ReasonForHold
              ? formData.ReasonForHold
              : null,
              TowCompanyOther:formData.TowCompanyOther? formData.TowCompanyOther : null,
            TowCompanyId: formData.TowCompanyId ? formData.TowCompanyId : null,
            TowInstructions: formData.TowInstructions
              ? formData.TowInstructions
              : null,
            OwnerUnknown: formData.OwnerUnknown ? formData.OwnerUnknown : false,
            TowLocationId: formData.TowLocationId
              ? formData.TowLocationId
              : null,
            TowDestination: formData.TowDestination
              ? formData.TowDestination
              : null,
            ProofOfInsurance: formData.ProofOfInsurance
              ? formData.ProofOfInsurance
              : false,
            ToRegisteredOwner: formData.ToRegisteredOwner
              ? formData.ToRegisteredOwner
              : false,
            Towed: formData.Towed ? formData.Towed : false,
            Trailered: formData.Trailered ? formData.Trailered : false,
            WithTitle: formData.WithTitle ? formData.WithTitle : false,
            WithCurrentTabs: formData.WithCurrentTabs
              ? formData.WithCurrentTabs
              : false,
            ToLicensedDriver: formData.ToLicensedDriver
              ? formData.ToLicensedDriver
              : false,
            Other: formData.Other ? formData.Other : null,
            SameAsTowCompany: formData.SameAsTowCompany || false,
          },
          vehicleIncidentModel: {
            VehicleId:formData.vehicleId,
            VehicleIncidentId: formData.VehicleIncidentId
              ? formData.VehicleIncidentId
              : null,
            OwnerUnknown: formData.OwnerUnknown ? formData.OwnerUnknown : false,
            TowLocationId: formData.TowLocationId
              ? formData.TowLocationId
              : null,
            Involvement: formData.Involvement ? formData.Involvement : null,
            OdometerReading: formData.OdometerReading
              ? formData.OdometerReading
              : null,
            DoorsLocked: formData.DoorsLocked ? formData.DoorsLocked : false,
            IgnitionLocked: formData.IgnitionLocked
              ? formData.IgnitionLocked
              : false,
            TrunkLocked: formData.TrunkLocked ? formData.TrunkLocked : false,
            KeyLocation: formData.KeyLocation ? formData.KeyLocation : null,
            KeysWithVehicle: formData.KeysWithVehicle
              ? formData.KeysWithVehicle
              : false,
            KeysInIgnition: formData.KeysInIgnition
              ? formData.KeysInIgnition
              : false,
            OwnerAllowUse: formData.OwnerAllowUse
              ? formData.OwnerAllowUse
              : false,
            OwnerInvolvement: formData.OwnerInvolvement
              ? formData.OwnerInvolvement
              : null,
            VehicleCondition: formData.VehicleCondition
              ? formData.VehicleCondition
              : null,
            PlatesTaken: formData.PlatesTaken ? formData.PlatesTaken : false,
            EstimatedValue: formData.EstimatedValue
              ? formData.EstimatedValue
              : null,
            VehicleInventory: formData.VehicleInventory
              ? formData.VehicleInventory
              : null,
            SeizedProperty: formData.SeizedProperty
              ? formData.SeizedProperty
              : false,
            SeizedForForfeiture: formData.SeizedForForfeiture
              ? formData.SeizedForForfeiture
              : false,
            ReleasedToOwner: formData.ReleasedToOwner
              ? formData.ReleasedToOwner
              : false,
            TabStolen: formData.TabStolen ? formData.TabStolen : false,
            PlateStolen: formData.PlateStolen ? formData.PlateStolen : false,
            VehicleWanted: formData.VehicleWanted
              ? formData.VehicleWanted
              : false,
            ForfeitureId: formData.ForfeitureId ? formData.ForfeitureId : null,
            ForfeitureVehicleStorageLocationId:
              formData.ForfeitureVehicleStorageLocationId
                ? formData.ForfeitureVehicleStorageLocationId
                : null,
          },
          Address: {
            LocationId: formData.LocationId ? formData.LocationId : null,
            IncidentId:incidentId,
            AddressType: formData.AddressType ? formData.AddressType : null,
            IsPrimary: formData.IsPrimary ? formData.IsPrimary : false,
            LocationCategoryCode: formData.LocationCategoryCode
              ? formData.LocationCategoryCode
              : null,
            LocationName: formData.LocationName ? formData.LocationName : null,
            Description: formData.Description ? formData.Description : null,
            Intersection: formData.Intersection ? formData.Intersection : false,
            HouseNumber: formData.HouseNumber ? formData.HouseNumber : null,
            Ncicstreet1PrefixId: formData.Ncicstreet1PrefixId
              ? formData.Ncicstreet1PrefixId
              : null,
            Street1Name: formData.Street1Name ? formData.Street1Name : null,
            Ncicstreet1SuffixId: formData.Ncicstreet1SuffixId
              ? formData.Ncicstreet1SuffixId
              : null,
            Ncicstreet2PrefixId: formData.Ncicstreet2PrefixId
              ? formData.Ncicstreet2PrefixId
              : null,
            Street2Name: formData.Street2Name ? formData.Street2Name : null,
            Ncicstreet2SuffixId: formData.Ncicstreet2SuffixId
              ? formData.Ncicstreet2SuffixId
              : null,
            City: formData.City ? formData.City : null,
            NcicstateId: formData.NcicstateId ? formData.NcicstateId : null,
            ZipCode: formData.ZipCode ? formData.ZipCode : null,
            Grid:
              formData.Grid !== null && formData.Grid !== undefined
                ? formData.Grid
                : null,

            NciccountryId: formData.NciccountryId
              ? formData.NciccountryId
              : null,
            Address1: formData.Address1 ? formData.Address1 : null,
            Address2: formData.Address2 ? formData.Address2 : null,
            OtherCity: formData.OtherCity ? formData.OtherCity : null,
            OtherState: formData.OtherState! ? formData.OtherState : null,
            OtherZip: formData.OtherZip ? formData.OtherZip : null,
            OtherCountry: formData.OtherCountry ? formData.OtherCountry : null,
            IsForeignAddress: formData.IsForeignAddress
              ? formData.IsForeignAddress
              : false,
            StateName: formData.StateName ? formData.StateName : null,
            CountryName: formData.CountryName ? formData.CountryName : null,
            Street1PrefixName:
              formData.Street1PrefixName !== null
                ? formData.Street1PrefixName
                : null,
            Street1SuffixName:
              formData.Street1SuffixName !== null
                ? formData.Street1SuffixName
                : null,
            Street2PrefixName:
              formData.Street2PrefixName !== null
                ? formData.Street2PrefixName
                : null,
            Street2SuffixName:
              formData.Street2SuffixName !== null
                ? formData.Street2SuffixName
                : null,
          },
        },
       {
        message: isUpdating
          ? successUpdateMessage
          : successCreatedMessage,
        show: true,
      },
        
      );
      redirectTOEDitMOde(vehicleId);
    } catch (error) {
      console.error("Error adding tow:", error);
    } finally {
      setLoading(false);
      
    }
  }


async getTowData(
  setLoading: (data: boolean) => void,
   setFormData: (data: SetFormDataIState) => void,
   incidentId: string,
  vehicleId: string,
) {
  setLoading(true);

  try {
    let res = await fetcher.get(`/incident/${incidentId}/vehicle/${vehicleId}/vehicle-tow`);
    let response = res?.Data;
    console.log('API Response:', response);
    // let time =parseISOToDateTime(response.vehicleModel.TowReleaseDateTime)
    if (response && response.vehicleTowModel && response.vehicleTowModel.VehicleTowId) {
      setFormData({
        vehicleId: vehicleId,
        TowCompanyOther:response.vehicleTowModel.TowCompanyOther,
        TowId: response.vehicleTowModel.VehicleTowId,
        ReasonForImpound: response.vehicleTowModel.ReasonForImpound ?? "",
        TowReleaseDateTime:response.vehicleTowModel.TowReleaseDateTime ? formatDate( response.vehicleTowModel.TowReleaseDateTime) : "",
        TowReleaseTime:response.vehicleTowModel.TowReleaseDateTime ?  formatTime(response.vehicleTowModel.TowReleaseDateTime):"",
        OwnerNotifiedOfImpound: response.vehicleTowModel.OwnerNotifiedOfImpound ?? false,
        NotificationMeans: response.vehicleTowModel.NotificationMeans ?? "",
        NotificationDateTime: response.vehicleTowModel.NotificationDateTime ? formatDate(response.vehicleTowModel.NotificationDateTime ) :"",
        NotificationTime:response.vehicleTowModel.NotificationDateTime ?  formatTime(response.vehicleTowModel.NotificationDateTime):"",
        CanVehicleBeReleased: response.vehicleTowModel.CanVehicleBeReleased ?? false,
        ReasonForHold: response.vehicleTowModel.ReasonForHold ?? "",
        TowCompanyId: response.vehicleTowModel.TowCompanyId ?? "",
        TowInstructions: response.vehicleTowModel.TowInstructions ?? "",
        OwnerUnknown: response.vehicleTowModel.OwnerUnknown ?? false,
        TowLocationId: response.vehicleTowModel.TowLocationId,
        TowDestination: response.vehicleTowModel.TowDestination ?? "",
        ProofOfInsurance: response.vehicleTowModel.ProofOfInsurance ?? false,
        ToRegisteredOwner: response.vehicleTowModel.ToRegisteredOwner ?? false,
        Towed: response.vehicleTowModel.Towed ?? false,
        Trailered: response.vehicleTowModel.Trailered ?? false,
        NotificationMethodId: response.vehicleTowModel.NotificationMethodId ?? "",
        WithTitle: response.vehicleTowModel.WithTitle ?? false,
        WithCurrentTabs: response.vehicleTowModel.WithCurrentTabs ?? false,
        ToLicensedDriver: response.vehicleTowModel.ToLicensedDriver ?? false,
        Other: response.vehicleTowModel.Other ?? "",
        SameAsTowCompany: response.vehicleTowModel.SameAsTowCompany ?? false,
        VehicleTowId: response.vehicleTowModel.VehicleTowId,
        VehicleIncidentId: response.vehicleIncidentModel.VehicleIncidentId ?? "",
        Involvement: response.vehicleTowModel.Involvement ?? "",
        OdometerReading: response.vehicleIncidentModel.OdometerReading ?? 0,
        DoorsLocked: response.vehicleIncidentModel.DoorsLocked ?? false,
        IgnitionLocked: response.vehicleIncidentModel.IgnitionLocked ?? false,
        TrunkLocked: response.vehicleIncidentModel.TrunkLocked ?? false,
        KeyLocation: response.vehicleIncidentModel.KeyLocation ?? "",
        KeysWithVehicle: response.vehicleIncidentModel.KeysWithVehicle ?? false,
        KeysInIgnition: response.vehicleIncidentModel.KeysInIgnition ?? false,
        OwnerAllowUse: response.vehicleIncidentModel.OwnerAllowUse ?? false,
        OwnerInvolvement: response.vehicleIncidentModel.OwnerInvolvement ?? "",
        VehicleCondition: response.vehicleIncidentModel.VehicleCondition ?? "",
        PlatesTaken: response.vehicleIncidentModel.PlatesTaken ?? false,
        EstimatedValue: response.vehicleIncidentModel.EstimatedValue ?? 0,
        VehicleInventory: response.vehicleIncidentModel.VehicleInventory ?? "",
        SeizedProperty: response.vehicleIncidentModel.SeizedProperty ?? false,
        SeizedForForfeiture: response.vehicleIncidentModel.SeizedForForfeiture ?? false,
        ReleasedToOwner: response.vehicleIncidentModel.ReleasedToOwner ?? false,
        PlateStolen: response.vehicleIncidentModel.PlateStolen ?? false,
        TabStolenDetails: response.vehicleIncidentModel.TabStolenDetails ?? "",
        VehicleWanted: response.vehicleIncidentModel.VehicleWanted ?? false,
        ForfeitureId: response.vehicleTowModel.ForfeitureId ?? "",
        ForfeitureVehicleStorageLocationId: response.vehicleTowModel.ForfeitureVehicleStorageLocationId ?? "",
        TabStolen: response.vehicleIncidentModel.TabStolen ?? false,
        AddressType: response.Address[0]?.AddressType,
        IsPrimary: response.vehicleTowModel.IsPrimary ?? false,
        LocationCategoryCode: response.vehicleTowModel.LocationCategoryCode ?? "",
        LocationName: response.vehicleTowModel.LocationName ?? "",
        Description: response.vehicleTowModel.Description ?? "",
        Intersection: response.vehicleTowModel.Intersection ?? false,
        HouseNumber: response.Address[0]?.HouseNumber ?? "",
        Ncicstreet1PrefixId: response.Address[0]?.Ncicstreet1PrefixId ?? "",
        Street1Name: response.Address[0]?.Street1Name ?? "",
        Ncicstreet1SuffixId: response.Address[0]?.Ncicstreet1SuffixId ?? "",
        Ncicstreet2PrefixId: response.Address[0]?.Ncicstreet2PrefixId ?? "",
        Street2Name: response.Address[0]?.Street2Name ?? "",
        Ncicstreet2SuffixId: response.Address[0]?.Ncicstreet2SuffixId ?? "",
        City: response.Address[0]?.City ?? "",
        NcicstateId: response.Address[0]?.NcicstateId ?? "",
        ZipCode: response.Address[0]?.ZipCode ?? "",
        Grid: response.Address[0]?.Grid ?? "",
        NciccountryId: response.Address[0]?.NciccountryId ?? "",
        Address1: response.vehicleTowModel.Address1 ?? "",
        Address2: response.vehicleTowModel.Address2 ?? "",
        OtherCity: response.vehicleTowModel.OtherCity ?? "",
        OtherState: response.vehicleTowModel.OtherState ?? "",
        OtherZip: response.vehicleTowModel.OtherZip ?? "",
        OtherCountry: response.vehicleTowModel.OtherCountry ?? "",
        IsForeignAddress: response.vehicleTowModel.IsForeignAddress ?? false,
        StateName: response.Address[0]?.StateName ?? "",
        CountryName: response.Address[0]?.CountryName ?? "",
        LocationId: response.Address[0]?.LocationId,
        Street1PrefixName: response.vehicleTowModel.Street1PrefixName ?? "",
        Street1SuffixName: response.vehicleTowModel.Street1SuffixName ?? "",
        Street2PrefixName: response.vehicleTowModel.Street2PrefixName ?? "",
        Street2SuffixName: response.vehicleTowModel.Street2SuffixName ?? "",
      });
    } 
    console.log("gett",response.vehicleTowModel) 
  }
   catch (error) {
    console.error('Error fetching tow data:', error);
  } finally {
    setLoading(false);
  }
}

  
  async getCompany(
    setCompany: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/system/category/NotificationsCompany"
    );

    setCompany(response.Data);
    setLoading(false);
  }
  async getOwnerInvolvement(
    setInvolvement: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/system/category/OwnerInvolvement"
    );

    setInvolvement(response.Data);
    setLoading(false);
  }
  async getTow(
    setTow: (data: setRankDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/agency/category/TowCompany");
    setTow(response.Data);
    setLoading(false);
  }
}
