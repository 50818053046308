import { Fetcher } from "../../../../../../services/Fetcher";
import { successCreatedMessage } from "../../../../../../utils/const";
import { SetFormDataIState, setFormDataValidation } from "./AddEditFormI";

const fetcher = new Fetcher();
export class AddEditFormFunction {
  async addAgency(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState
  ) {
    setLoading(true);
    const response = await fetcher.post(
      "/role",
      {
        RoleName: formData.RoleName,
        Description:formData.Description,
        IsActive: true,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
  }

  async editAgency(
    setLoading: (data: boolean) => void,
    Id: string,
    formData: SetFormDataIState
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/role`,
      {
        RoleId: Id,
        RoleName: formData.RoleName,
        Description:formData.Description,
        IsActive: formData.Status == "1" ? true : false,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    console.log(response)
    setLoading(false);
  }


  
  errorValidation(
    formData: SetFormDataIState,
    setFormDataValidation: (data: setFormDataValidation) => void
  ) {
    let valdationerror: setFormDataValidation = {
      RoleName: false,
      Description:false,
      IsActive: false,
    };
    if (!formData.RoleName) {
      valdationerror.RoleName = true;
    } else {
      valdationerror.RoleName = false;
    }
    if (!formData.Description) {
      valdationerror.Description = true;
    } else {
      valdationerror.Description = false;
    }
    if (!formData.Status) {
      valdationerror.IsActive = true;
    } else {
      valdationerror.IsActive = false;
    }
    setFormDataValidation({ ...valdationerror });
  }

  checkRequiredFields(formData: SetFormDataIState) {
    return (
      formData.RoleName &&
      formData.Status && 
      formData.Description
    );
  }
}
