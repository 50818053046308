import React, { useEffect, useState } from "react";
import LocationMetaData from "../../Location/LocationMetaData";
import NarrativeContentFilter from "./NarrativeContents/NarrativeContentFilter";
import NarrativeContent from "./NarrativeContents/NarrativeContent";
import { useParams } from "react-router-dom";
import { NarrativeListFunction } from "../NarativeListing/NarrativeFunction";
import {
  NarrativeIParams,
  NarrativeOverviewDetail,
} from "../NarativeListing/NarrativeIState";
import { Loader } from "../../../../CommonComponents/Loader/Loader";

const NarrativesOverview = () => {
  const narrativeFunction = new NarrativeListFunction();
  const { incidentId, narrativesId } = useParams<NarrativeIParams>();
  const [width, setwidth] = useState<number>(10);
  const [overview, setOverview] = useState<NarrativeOverviewDetail>();
  const [loading, setLoading] = useState<boolean>(false);
  const [Type, setType] = useState<string>("");
  const [version, setVersion] = useState<string>("");

  useEffect(() => {
    getOverviewData();
  }, [narrativesId, version]);


  const getOverviewData = async () => {
    if (incidentId && narrativesId) {
      let res = await narrativeFunction.getNarrativeOverviewByVersion(
        setLoading,
        narrativesId,
        version,
        incidentId
      );
      setOverview(res?.Data);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="_incident_location_container_fluid">
        <div className="_location_left_container">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div
          className="_location_right_container"
          style={{ padding: "10px 20px" }}
        >
          <div className="">
            <NarrativeContentFilter
              Type={Type}
              setType={setType}
              Name=""
              setName={() => {}}
              overView={overview}
              setVersion={setVersion}
              version={version}
            />
          </div>
          <div className="_narrative_content_container">
            <NarrativeContent overview={overview} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NarrativesOverview;
