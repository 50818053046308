import { ButtonComponents } from "../Button/ButtonComponents";
import { CheckBoxComponent } from "../Checkbox/Checkbox";
import { DropdownComponent } from "../Dropdown/DropdownComponent";
import { MaskedInputComponent } from "../MaskedInput/MaskedInputComponent";
import add from "../../../../assets/icons/Icon.svg";

import "./MultiplePhoneNumber.css";
import { useEffect, useState } from "react";
import {
  MultiplePhoneNumberDataUIProps,
  MultiplePhoneNumberIProps,
  setContactTypeDataIState,
  setMultiplePhoneNumberListIState,
} from "./MultiplePhoneNumberI";
import { MultiplePhoneNumberFunction } from "./MultiplePhoneNumberFunction";
import { Add } from "../../../../assets";

const MultiplePhoneNumberDataUI: React.FC<MultiplePhoneNumberDataUIProps> = ({
  data,
  MultiplePhoneNumberList,
  setMultiplePhoneNumberList,
  ContactTypeData,
  validationContactType,
  validationNumber,
  disabled,
}) => {
  const onChangeValueCheckBox = (e: boolean, id: number) => {
    setMultiplePhoneNumberList(
      MultiplePhoneNumberList.map((val) => {
        if (val.id == id) {
          return { ...val, IsPrimary: e };
        } else {
          return { ...val, IsPrimary: false };
        }
      })
    );
  };

  const handleRowClickDropdown = (e: string, id: number) => {
    setMultiplePhoneNumberList(
      MultiplePhoneNumberList.map((val) => {
        if (val.id == id) {
          return { ...val, ContactMethodCodeId: e };
        } else {
          return val;
        }
      })
    );
  };

  const onChangeValueNumber = (e: string, id: number) => {
    setMultiplePhoneNumberList(
      MultiplePhoneNumberList.map((val) => {
        if (val.id == id) {
          return { ...val, ContactValue: e };
        } else {
          return val;
        }
      })
    );
  };

  const remove = (id: number) => {
    if (MultiplePhoneNumberList.length > 1) {
      setMultiplePhoneNumberList(
        MultiplePhoneNumberList.filter((val) => {
          if (val.id != id) {
            return val;
          }
        })
      );
    }
  };
  return (
    <>
      <div className="MultiplePhoneNumber">
        <div style={{ width: "25%" }}>
          <p style={{ marginTop: "5%" }}>Primary</p>
          <CheckBoxComponent
            onChangeValue={(e) => {
              onChangeValueCheckBox(e, data.id);
            }}
            value={data.IsPrimary}
            disabled={disabled}
          />
        </div>
        <DropdownComponent
          dataSource={ContactTypeData.map((val) => {
            return { id: val.SystemLevelCodeId, value: val.LongCode };
          })}
          value={data.ContactMethodCodeId}
          handleRowClick={(e) => {
            handleRowClickDropdown(e, data.id);
          }}
          validation={validationContactType}
          fieldName="Contact Type*"
          disabled={disabled}
        />
        <MaskedInputComponent
          mask="+1 (999)-999-9999"
          value={data.ContactValue}
          fieldName="Phone Number*"
          onChangeValue={(e) => {
            onChangeValueNumber(e, data.id);
          }}
          validation={validationNumber}
          disabled={disabled}
        />
        {!disabled && (
          <p
            title="Remove"
            className="removeIcon"
            onClick={() => {
              remove(data.id);
            }}
          >
            -
          </p>
        )}
      </div>
    </>
  );
};

export const MultiplePhoneNumber: React.FC<MultiplePhoneNumberIProps> = ({
  getMultiplePhoneNumberData,
  value,
  disabled,
  validationNumber,
  validationContactType,
}) => {
  const multiplePhoneNumberFunction = new MultiplePhoneNumberFunction();
  const [Loading, setLoading] = useState<boolean>(false);
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);
  const [MultiplePhoneNumberList, setMultiplePhoneNumberList] = useState<
    setMultiplePhoneNumberListIState[]
  >(
    value.length
      ? value
      : [{ id: 1, ContactValue: "", IsPrimary: false, ContactMethodCodeId: "" }]
  );

  const handleClick = () => {
    setMultiplePhoneNumberList([
      ...MultiplePhoneNumberList,
      {
        id: MultiplePhoneNumberList[MultiplePhoneNumberList.length - 1].id + 1,
        ContactValue: "",
        IsPrimary: false,
        ContactMethodCodeId: "",
      },
    ]);
  };

  useEffect(() => {
    getMultiplePhoneNumberData(MultiplePhoneNumberList);
  }, [MultiplePhoneNumberList]);

  useEffect(() => {
    if (value.length == 1 && !value[0].ContactMethodCodeId) {
      setMultiplePhoneNumberList(
        value.length
          ? value
          : [
              {
                id: 1,
                ContactValue: "",
                IsPrimary: false,
                ContactMethodCodeId: "",
              },
            ]
      );
    }
  }, [value]);

  const [temp, settemp] = useState<boolean>(true);

  useEffect(() => {
    if (value.length) {
      if (value[0].ContactMethodCodeId && temp) {
        setMultiplePhoneNumberList(
          value.length
            ? value
            : [
                {
                  id: 1,
                  ContactValue: "",
                  IsPrimary: false,
                  ContactMethodCodeId: "",
                },
              ]
        );
        settemp(false);
      }
    }
  }, [value]);

  const initialRender = () => {
    multiplePhoneNumberFunction.getContactTypeData(
      setContactTypeData,
      setLoading
    );
  };

  useEffect(() => {
    initialRender();
  }, []);

  console.log(MultiplePhoneNumberList);

  return (
    <>
      <div>
        {MultiplePhoneNumberList.map((val, i) => {
          return (
            <>
              <div key={i}>
                <MultiplePhoneNumberDataUI
                  MultiplePhoneNumberList={MultiplePhoneNumberList}
                  setMultiplePhoneNumberList={setMultiplePhoneNumberList}
                  data={val}
                  ContactTypeData={ContactTypeData}
                  validationNumber={validationNumber}
                  validationContactType={validationContactType}
                  disabled={disabled}
                />
              </div>
            </>
          );
        })}
        {!disabled && (
          <div className="MultiplePhoneNumber_buton">
            <ButtonComponents
              handleClick={handleClick}
              // icon={add}
              name="Add new contact"
              color="white"
              textColor="#3672b3"
              borderColor="#3672b3"
              showBackgroundColor={true}
              icon={Add}
            />
          </div>
        )}
      </div>
    </>
  );
};
