
import { Fetcher } from "../../../../../../services/Fetcher";
import { AddressData } from "../../MasterNameIState";
import { AddressTableIRes } from "./AddressIState";

const fetcher = new Fetcher();

export class AddressFunction {
  async getAddressMain(
    setTableData: (data: AddressData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string
  ) {
    let response = await fetcher.get(
      `/name-master/${nameId}/address`
    );
    let res: AddressTableIRes[] = response.Data;
    const TableJson = this.createTableJson(res);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: AddressTableIRes[]) {
    return data?.map((val: AddressTableIRes, i: number) => {
      return {
        Case: val.IncidentNumber,
        Date: val.CreatedOn,
        "Street Address": val.Street1Name,
        City: val.City,
        State: val.StateCode,
        Zip: val.ZipCode,
      };
    });
  }
}
