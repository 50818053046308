import { Fetcher } from "../../../../../../../services/Fetcher";
import { BusinessAddressData, BusinessAddressTableIRes } from "./BusinessAddressIState";



const fetcher = new Fetcher();

export class BusinessAddressFunction {
  async getBusinessAddressMain(
    setTableData: (data: BusinessAddressData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string
  ) {
    let response = await fetcher.get(
      `/name-master/${nameId}/business-address`
    );
    let res: BusinessAddressTableIRes[] = response.Data;
    console.log("122345",res)
    const TableJson = this.createTableJson(res);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: BusinessAddressTableIRes[]) {
    return data?.map((val: BusinessAddressTableIRes, i: number) => {
      return {
        Case: val.IncidentNumber,
        Date: val.CreatedOn,
        "Street Address": val.Street1Name,
        City: val.City,
        State: val.StateCode,
        Zip: val.ZipCode,
      };
    });
  }
}
