import React, { useEffect, useState } from "react";
import close from "../../../../../../assets/icons/close-circle.svg";
import refresh from "../../../../../../assets/icons/refresh-cw.svg";
import "../Filter/Filter.css";
import { FilterIProps, setUserActionList } from "./FilterIProps";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { ButtonIState } from "../../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";

export const Filter: React.FC<FilterIProps> = ({
  Creationdatefrom,
  setCreationdatefrom,
  Creationdateto,
  setCreationdateto,
  UserName,
  setUserName,
  setAction,
  onRefresh,
  tableData,
  Action,
  setUserActionValue,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");


  useEffect(() => {
    if (Action && Action.trim().length > 0  || (UserName && UserName.trim().length >0 ) || (Creationdatefrom && Creationdatefrom.trim().length >0 )  || (Creationdateto && Creationdateto.trim().length >0 )) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Action,UserName,Creationdatefrom,Creationdateto]);
  const clearAllFilters = () => {
    setCreationdatefrom("");
    setCreationdateto("");
    setUserName("");
    setAction("");

    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };

  // const [UserActionList, setUserActionList] = useState<setUserActionList[]>([]);

  // useEffect(() => {
  //   setUserActionList(
  //     tableData.map((val, i) => {
  //       return {
  //         id: String(i),
  //         value: val.Action,
  //       };
  //     })
  //   );
  // }, [tableData]);

  // useEffect(() => {
  //   UserActionList.map((val) => {
  //     if (val.id == UserAction) {
  //       setUserActionValue(val.value);
  //     }
  //   });
  // }, [UserAction]);

  return (
    <>
      <div className="Filter-audit">
        <div className="Filter-audit_1">
          <TextBoxComponent
            fieldName="Search By Action"
            value={Action}
            onChangeValue={setAction}
          />

          <DateBoxComponents
            fieldName={"From date "}
            value={Creationdatefrom}
            onValueChange={setCreationdatefrom}
          />
          <DateBoxComponents
            fieldName={"To date"}
            value={Creationdateto}
            onValueChange={setCreationdateto}
          />
          <TextBoxComponent
            fieldName="User"
            value={UserName}
            onChangeValue={setUserName}
          /><div className="clearbutton1">
             <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
       
          <ButtonComponents
            // icon={refresh}
            name="Refresh"
            handleClick={onRefresh}
            showBackgroundColor={true}
            textColor="white"
          />
        </div>
      </div>
    </>
  );
};
