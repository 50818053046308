import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AgencyReducer {
  agencyName: string;
  createdby: string;
  AgencyTypeName: string;
  createdOn: string;
  editComponent: boolean;
  addComponent: boolean;
  viewComponent: boolean;
  ModifiedOn:string;
}
export  interface SetAgencyDetailPayload {
  AgencyName: string;
  Createdby: string;
  AgencyTypeName: string;
  CreatedOn: string;
  ModifiedOn:string;
}

const initialState: AgencyReducer = {
  agencyName: "",
  createdby: "",
  AgencyTypeName: "",
  createdOn: "",
  editComponent: false,
  addComponent: true,
  viewComponent: false,
  ModifiedOn:"",
};

export interface SetEditComponentPayload {
  editComponent: boolean;
  addComponent: boolean;
  viewComponent: boolean;
}

//slice

const slice = createSlice({
  name: "AgencyReducer",
  initialState,
  reducers: {
    setAgencyDetail(state, action: PayloadAction<SetAgencyDetailPayload>) {
      state.agencyName = action.payload.AgencyName;
      state.AgencyTypeName = action.payload.AgencyTypeName;
      state.createdby = action.payload.Createdby;
      state.createdOn = action.payload.CreatedOn;
      state.ModifiedOn=action.payload.ModifiedOn;
    },
    seteditComponent(state, action: PayloadAction<SetEditComponentPayload>) {
      state.editComponent = action.payload.editComponent;
      state.addComponent = action.payload.addComponent;
      state.viewComponent = action.payload.viewComponent;
    },
  },
});

export const { setAgencyDetail, seteditComponent } = slice.actions;

export default slice.reducer;
