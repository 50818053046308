import React, { useEffect, useState } from "react";
import { AdditionalIState } from "../ParticipantOverviewIState";
import { AdditionalMainFunction } from "../../../../AddEditParticipantName/AddNewName/AdditionalInfo/AdditionalFunction";
import { InvolvementMainIParams } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useParams } from "react-router-dom";
import { AdditionalInfoEmploymentIRes } from "../../../../AddEditParticipantName/AddNewName/AdditionalInfo/AdditionalIState";

export const Additional: React.FC = () => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [Fields, setFields] = useState<AdditionalIState>({
    Disability: "",
    FingerPrintsonFile: "",
    language: "",
    MaritalStatus: "",
    SchoolGrade: "",
    School: "",
    GangAffiliation: "",
  });

  const [AdditionalInfoEmploymentt, setAdditionalInfoEmployment] = useState<
    AdditionalInfoEmploymentIRes[]
  >([]);

  const { incidentId, participantId } = useParams<InvolvementMainIParams>();

  const getAdditionalInfo = async () => {
    if (incidentId && participantId) {
      let { AdditionalInfo, AdditionalInfoEmployment } =
        await additionalMainFunction.getAdditionalInfo(
          incidentId,
          participantId
        );

      setFields({
        Disability: AdditionalInfo.ParticipantAttributeList.map((val) => {
          if (val.AttributeValue == "DisabilityTypeCode") {
            return val.AttributeCode;
          }
        })
          .filter((val) => val)
          .join(","),
        FingerPrintsonFile: AdditionalInfo.FingerprintIndivator ? "yes" : "No",
        MaritalStatus: AdditionalInfo.NcicmaritialStatusCode
          ? AdditionalInfo.NcicmaritialStatusCode
          : "",
        School: AdditionalInfo.SchoolCode ? AdditionalInfo.SchoolCode : "",
        SchoolGrade: AdditionalInfo.SchoolGrade
          ? AdditionalInfo.SchoolGrade
          : "",
        GangAffiliation: AdditionalInfo.ParticipantAttributeList.map((val) => {
          if (val.AttributeValue == "GangAffiliations") {
            return val.AttributeCode;
          }
        })
          .filter((val) => val)
          .join(","),
        language: AdditionalInfo.ParticipantAttributeList.map((val) => {
          if (val.AttributeValue == "Language") {
            return val.AttributeCode;
          }
        })
          .filter((val) => val)
          .join(","),
      });

      console.log(AdditionalInfoEmployment);

      setAdditionalInfoEmployment(AdditionalInfoEmployment);
    }
  };
  const initialRender = async () => {
    getAdditionalInfo();
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <div style={{ width: "100%", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Additional Information</strong>
          </p>
          <p className="DetailTab_top_"></p>
        </div>
        <div className="overview-content">
          <div className="overview-row">
            <span className="overview-label">
              Disability: {Fields.Disability}
            </span>
            <span className="overview-label">Language: {Fields.language}</span>
            <span className="overview-label">
              Marital Status: {Fields.MaritalStatus}
            </span>

            <span className="overview-label">
              School Grade: {Fields.SchoolGrade}
            </span>

            <span className="overview-label">School: {Fields.School}</span>
            <span className="overview-label">
              Gang Affiliation: {Fields.GangAffiliation}
            </span>
          </div>

          <div className="overview-row">
            <span className="overview-label">
              Finger Prints on File: {Fields.FingerPrintsonFile}
            </span>
          </div>

          <p>
            {AdditionalInfoEmploymentt[0]?.EmployerTypeName}{" "}
            {AdditionalInfoEmploymentt[0]?.OccupationName}{" "}
            {AdditionalInfoEmploymentt[0]?.ShiftName}
          </p>
          <p>
            {AdditionalInfoEmploymentt[0]?.Address[0]?.Street1Name}
            {AdditionalInfoEmploymentt[0]?.Address[0]?.Street2Name}
            {AdditionalInfoEmploymentt[0]?.Address[0]?.City}
            {AdditionalInfoEmploymentt[0]?.Address[0]?.CountryName}
            {AdditionalInfoEmploymentt[0]?.Address[0]?.ZipCode}
          </p>
          <p>
            {AdditionalInfoEmploymentt[0]?.ContactFirstName}{" "}
            {AdditionalInfoEmploymentt[0]?.ContactLastName}
          </p>
          <p>
            Phone Desk{" "}
            {AdditionalInfoEmploymentt[0]?.Contacts.map((val) => {
              if (val.ContactType == "Phone") {
                return val.ContactValue;
              }
            }).join(",")}
          </p>
          <p>
            Email Desk{" "}
            {AdditionalInfoEmploymentt[0]?.Contacts.map((val) => {
              if (val.ContactType == "Email") {
                return val.ContactValue;
              }
            }).join(",")}
          </p>
        </div>
      </div>
    </div>
  );
};
