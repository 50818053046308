import React, { useEffect, useState } from "react";
import { coloum } from "./Config";
import { AlertsData } from "../../MasterNameIState";
import { Table } from "../../../../../CommonComponents/Table/Table";

export const Alerts: React.FC = () => {
  const [tableData, setTableData] = useState<AlertsData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div
        className="overview-container"
        style={{ height: "102vh", border: "1px solid #ffd9d9" }}
      >
        <div className="overview-alert">
          <p>
            <strong>Alerts: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
          <strong>Total Records: {tableTotalRecord}</strong> 
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
         
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
