import { ColDef } from "ag-grid-community";

export const coloum: () => ColDef[] = () => {
  return [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      maxWidth: 90,
      sortable: false,
      cellStyle: { pointerEvents: "" },
    },
    { field: "Policy Name" },
    { field: "Description" },
    { field: "Status" },
  ];
};
