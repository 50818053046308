import { useEffect, useState } from "react";
import { DemographicsIProps, GetRaceDataIState } from "../../InvolvementMainI";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { NumberBoxComponents } from "../../../../../../../../CommonComponents/Fields/NumberBox/NumberBoxComponents";
import { AutoCompleteComponents } from "../../../../../../../../CommonComponents/Fields/AutoComplete/AutoCompleteComponents";

export const Demographics: React.FC<DemographicsIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();

  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [Sex, setSex] = useState<GetRaceDataIState[]>([]);
  const [Race, setRace] = useState<GetRaceDataIState[]>([]);
  const [EyeColor, setEyeColor] = useState<GetRaceDataIState[]>([]);
  const [Hair, setHair] = useState<GetRaceDataIState[]>([]);
  const [Skin, setSkin] = useState<GetRaceDataIState[]>([]);
  const [Build, setBuild] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [State, setState] = useState<GetRaceDataIState[]>([]);
  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Demographics: {
          ...Mainfields.InvolvementTab.Demographics,
          [name]: value,
        },
      },
    });
  };

  const initialRender = () => {
    involvementMainFunction.getRaceData(setRaceDataData);
    involvementMainFunction.getSex(setSex);
    involvementMainFunction.getRace(setRace);
    involvementMainFunction.getEyeColor(setEyeColor);
    involvementMainFunction.getHair(setHair);
    involvementMainFunction.getSkin(setSkin);
    involvementMainFunction.getBuild(setBuild);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setState);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <p className="subheader">Demographics</p>
        <div className="Involvement_left_row">
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.Sex}
            fieldName="Sex"
            handleRowClick={(e) => {
              onChangeValue("Sex", e);
            }}
            dataSource={Sex.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.Race}
            fieldName="Race"
            handleRowClick={(e) => {
              onChangeValue("Race", e);
            }}
            dataSource={Race.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.Eye}
            fieldName="Eye"
            handleRowClick={(e) => {
              onChangeValue("Eye", e);
            }}
            dataSource={EyeColor.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
        </div>{" "}
        <div className="Involvement_left_row">
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.Hair}
            fieldName="Hair Color"
            handleRowClick={(e) => {
              onChangeValue("Hair", e);
            }}
            dataSource={Hair.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.FacialHair}
            fieldName="Facial Hair"
            handleRowClick={(e) => {
              onChangeValue("FacialHair", e);
            }}
            dataSource={[
              { value: "Yes", id: "1" },
              { value: "No", id: "2" },
            ]}
          />
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.Glasses}
            fieldName="Glasses"
            handleRowClick={(e) => {
              onChangeValue("Glasses", e);
            }}
            dataSource={[
              { value: "Yes", id: "1" },
              { value: "No", id: "2" },
            ]}
          />
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.SkinTon}
            fieldName="Skin Tone"
            handleRowClick={(e) => {
              onChangeValue("SkinTon", e);
            }}
            dataSource={Skin.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
        </div>
        <div style={{ width: "50%" }}></div>
        <div className="Involvement_left_row">
          <AutoCompleteComponents
            dataSource={[{ value: "Unknown" }]}
            value={Mainfields.InvolvementTab.Demographics.Height}
            fieldName="Height"
            onChangeValue={(e) => {
              onChangeValue("Height", e);
            }}
          />

          <AutoCompleteComponents
            dataSource={[{ value: "Unknown" }]}
            value={Mainfields.InvolvementTab.Demographics.Weight}
            fieldName="Weight"
            onChangeValue={(e) => {
              onChangeValue("Weight", e);
            }}
          />

          <DropdownComponent
            handleRowClick={(e) => {
              onChangeValue("Build", e);
            }}
            dataSource={Build.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
            value={Mainfields.InvolvementTab.Demographics.Build}
            fieldName="Build"
          />
          <DropdownComponent
            handleRowClick={(e) => {
              onChangeValue("Handedness", e);
            }}
            dataSource={[
              { value: "Yes", id: "1" },
              { value: "No", id: "2" },
            ]}
            value={Mainfields.InvolvementTab.Demographics.Handedness}
            fieldName="Handedness"
          />
        </div>
        <div className="Involvement_left_row">
          {Mainfields.InvolvementTab.Demographics.Height === "Unknown" && (
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Demographics.MinHeight}
              onChangeValue={(e) => {
                onChangeValue("MinHeight", e);
              }}
              fieldName="Min Height"
            />
          )}
          {Mainfields.InvolvementTab.Demographics.Height === "Unknown" && (
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Demographics.MaxHeight}
              onChangeValue={(e) => {
                onChangeValue("MaxHeight", e);
              }}
              fieldName="Max Height"
            />
          )}
          {Mainfields.InvolvementTab.Demographics.Weight === "Unknown" && (
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Demographics.MinWeight}
              onChangeValue={(e) => {
                onChangeValue("MinWeight", e);
              }}
              fieldName="Min Weight"
            />
          )}
          {Mainfields.InvolvementTab.Demographics.Weight === "Unknown" && (
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Demographics.MaxWeight}
              onChangeValue={(e) => {
                onChangeValue("MaxWeight", e);
              }}
              fieldName="Max Weight"
            />
          )}
        </div>
        <div className="Involvement_left_row">
          <TextBoxComponent
            onChangeValue={(e) => {
              onChangeValue("BirthplaceCIty", e);
            }}
            value={Mainfields.InvolvementTab.Demographics.BirthplaceCIty}
            fieldName="Birthplace CIty"
          />
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.StateProvidence}
            handleRowClick={(e) => {
              onChangeValue("StateProvidence", e);
            }}
            dataSource={State.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
            fieldName="State / Providence"
          />
          <DropdownComponent
            value={Mainfields.InvolvementTab.Demographics.Country}
            handleRowClick={(e) => {
              onChangeValue("Country", e);
            }}
            dataSource={Country.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
            fieldName="Country"
          />
        </div>
      </div>
    </>
  );
};
