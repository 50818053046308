import React, { useEffect, useState } from "react";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { AddVehicleFunction } from "./AddVehicleFunction";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { VehicleListFunction } from "../../VehicleListing/VehicleFunction";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { AddDescriptionFormProps } from "./VehicalI";
import { GetRaceDataIState } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { NumberBoxComponents } from "../../../../../CommonComponents/Fields/NumberBox/NumberBoxComponents";
import DateMonthComponents from "src/Components/CommonComponents/Fields/DateBox/DateMonthComponent";
import { DatePicker } from "antd";
import { YearBoxComponents } from "src/Components/CommonComponents/Fields/YearBox/YearBox";

const AddDescriptionForm: React.FC<AddDescriptionFormProps> = ({
  formData,
  setFormData,
}) => {
  const vehicleFunction = new VehicleListFunction();
  const addVehicleFunction = new AddVehicleFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicleType, setVehicleType] = useState<GetRaceDataIState[]>([]);
  const [vehicleMake, setVehicleMake] = useState<GetRaceDataIState[]>([]);
  const [hazardius, setHazardius] = useState<GetRaceDataIState[]>([]);
  const [stateData, setStateData] = useState<GetRaceDataIState[]>([]);
  const [vehicleModel, setVehicleModel] = useState<GetRaceDataIState[]>([]);
  const [vehiclePrimary, setVehiclePrimary] = useState<GetRaceDataIState[]>([]);
  const [vehicleSecondary, setVehicleSecondary] = useState<GetRaceDataIState[]>(
    []
  );
  const [tabYearError, setTabYearError] = useState<string | null>(null);

  const onHandelValueChange = (field: string, value: number | string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const initialRender = () => {
    addVehicleFunction.getStateData(setStateData, setLoading);
    addVehicleFunction.getVehicleMake(setVehicleMake, setLoading);
    addVehicleFunction.getVehicleModel(setVehicleModel, setLoading);
    addVehicleFunction.getPrimaryColor(setVehiclePrimary, setLoading);
    addVehicleFunction.getSecondayColor(setVehicleSecondary, setLoading);
    addVehicleFunction.getHazardious(setHazardius, setLoading);
    vehicleFunction.getVehicleType(setVehicleType);
  };

  useEffect(() => {
    initialRender();
  }, []);

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null
  ) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const handleTabYearChange = (value: number | null) => {
    const numericValue = (value || 0).toString().replace(/[^0-9]/g, "");
    if (numericValue.length <= 4) {
      setFormData({ ...formData, TabYear: Number(numericValue) });
      setTabYearError(null);
    } else {
      setTabYearError("Please enter a valid 4-digit year.");
    }
  };
  return (
    <>
      <Loader loading={loading} />
      <div className="_add_vehicle_form_desc">
        <div className="_add_vehicle_form_desc_upper_forms_list">
          <div className="_license">
            <TextBoxComponent
              fieldName="License #"
              value={formData.PlateNumber}
              onChangeValue={(e) => {
                if (e.length < 11) {
                  onChangeValue("PlateNumber", e);
                }
              }}
            />
          </div>

          <div className="_state">
            <DropdownComponent
              dataSource={stateData.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
              value={formData.State}
              handleRowClick={(e) => {
                onHandelValueChange("State", e);
              }}
              fieldName="State"
            />
          </div>

          <div className="_VIN">
            <TextBoxComponent
              fieldName="VIN #"
              value={formData.VIN}
              onChangeValue={(newValue) => {
                onChangeValue("VIN", newValue);
              }}
            />
          </div>
          <div className="_registration_meta_data _tab_data">
            <NumberBoxComponents
              fieldName="Tab Year"
              value={formData.TabYear}
              onValueChange={(e) => {
                handleTabYearChange(e);
              }}
              validation={!!tabYearError}
            />
            {tabYearError && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {tabYearError}
              </span>
            )}
          </div>
          <div
            className="_license_plate_expiration_container"
            style={{ marginTop: "-0.5%" }}
          >
            <DateMonthComponents
              fieldName="License Plate Expiration"
              value={formData.LicensePlateExpiration}
              onValueChange={(newValue) => {
                onChangeValue("LicensePlateExpiration", newValue);
              }}
            />
          </div>
        </div>

        <div className="_add_vehicle_form_desc_mid_forms_list">
          <div className="_year">
            <YearBoxComponents
              fieldName="Year"
              value={String(formData?.Year)}
              onValueChange={(e) => {
                onChangeValue("Year", e);
              }}
            />
          </div>

          <div className="_midd_metadata">
            <DropdownComponent
              value={formData.Make}
              handleRowClick={(newValue) => {
                onChangeValue("Make", newValue);
              }}
              dataSource={vehicleMake.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
              fieldName="Make"
            />
          </div>

          <div className="_midd_metadata">
            <DropdownComponent
              value={formData.Model}
              handleRowClick={(newValue) => {
                onChangeValue("Model", newValue);
              }}
              dataSource={vehicleModel.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
              fieldName="Model"
            />
          </div>

          <div className="_midd_metadata">
            <DropdownComponent
              value={formData.Type}
              handleRowClick={(newValue) => {
                onChangeValue("Type", newValue);
              }}
              fieldName="Type"
              dataSource={vehicleType.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
            />
          </div>

          <div className="_midd_metadata">
            <DropdownComponent
              fieldName="Primary Color"
              value={formData.PrimaryColor}
              handleRowClick={(newValue) => {
                onChangeValue("PrimaryColor", newValue);
              }}
              dataSource={vehiclePrimary.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
            />
          </div>

          <div className="_midd_metadata">
            <DropdownComponent
              fieldName="Secondary Color"
              value={formData.SecondaryColor}
              handleRowClick={(newValue) => {
                onChangeValue("SecondaryColor", newValue);
              }}
              dataSource={vehicleSecondary.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
            />
          </div>
        </div>

        <div className="_add_vehicle_form_desc_end_forms_list">
          <div className="_midd_metadata">
            <DropdownComponent
              value={formData.HazardousMaterial}
              handleRowClick={(newValue) => {
                onChangeValue("HazardousMaterial", newValue);
              }}
              dataSource={hazardius.map((state) => ({
                id: state.SystemLevelCodeId,
                value: state.LongCode,
              }))}
              fieldName="Hazardous Material"
            />
          </div>
          <div className="" style={{ marginTop: "3.5%" }}>
            <CheckBoxComponent
              fieldName="Commercial Vehicle"
              value={formData.CommercialVehicle}
              onChangeValue={(e) => {
                onChangeValue("CommercialVehicle", e);
              }}
            />
          </div>

          {formData.CommercialVehicle && (
            <div className="_midd_metadata">
              <NumberBoxComponents
                fieldName="Gross Weight"
                value={formData.GrossWeight}
                onValueChange={(newValue) => {
                  onChangeValue("GrossWeight", newValue);
                }}
              />
            </div>
          )}

          {formData.CommercialVehicle && (
            <div className="_midd_metadata" style={{ marginTop: "3%" }}>
              <CheckBoxComponent
                fieldName="Over Weight Measures"
                value={formData.OverWeightMeasures}
                onChangeValue={(e) => {
                  onChangeValue("OverWeightMeasures", e);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddDescriptionForm;
