import { useEffect, useState } from "react";
import { Table } from "../../../CommonComponents/Table/Table";
import { CategoryFunction } from "./CategoryFunction";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import "./Category.css";
import { Filter } from "./components/Filter/Filter";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { CategoryAddEditView } from "./components/CategoryAddEditView/CategoryAddEditView";
import { coloumn } from "./Config";
import { SetTableData, CategoryProps } from "./CategoryInterface";
import useDelayedEffect from "../../../../hooks/useDelayedEffect";
import { useGetPerticularUserPermission } from "../../../../hooks/Permission/useGetPerticularUserPermission";

export const Category: React.FC<CategoryProps> = ({
  calledFromAgencyLevel,
  calledFromNCIC
}) => {
  const { Delete, Update } =
  useGetPerticularUserPermission("Code.System");
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const categoryFunction = new CategoryFunction();
  const [showAddEditView, setshowAddEditView] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [editMode, seteditMode] = useState<boolean>(false);
  const [addMode, setaddMode] = useState<boolean>(false);
  const [viewMode, setviewMode] = useState<boolean>(false);
  const [getRowId, setgetRowId] = useState<string>("");
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [CategoryCode, setCategoryCode] = useState<string>("");
  const [Status, setStatus] = useState<string>("1");
  const [Category, setCategory] = useState<string>("");
  const [Agency, setAgency] = useState<string>("");
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Category Name");

  const Edit = (id: string) => {
    setaddMode(false);
    seteditMode(true);
    setshowAddEditView(true);
    setviewMode(false);
    setgetRowId(id);
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
  };

  const DeleteRow = (id: string) => {
    setdeletePopup(true);
    setgetRowId(id);
  };

  const onRowClicked = (data: SetTableData) => {
    if (!deletePopup) {
      setgetRowId(data.CategoryCodeId);
      setaddMode(false);
      seteditMode(false);
      setshowAddEditView(true);
      setviewMode(true);
    }
  };

  const getCategoryTableData = () => {
    categoryFunction.getCategoryTableData(
      setTableData,
      setLoading,
      setTableTotalRecord,
      pageNumber,
      pageCount,
      Category,
      Status,
      calledFromAgencyLevel,
      Agency,
      OrderByDescending,
      OrderBy,
      calledFromNCIC
    );
  };

  useEffect(() => {
    if (!showAddEditView) {
      getCategoryTableData();
    }
  }, [OrderByDescending, pageNumber, pageCount, showAddEditView]);

  const handleDelete = () => {
    categoryFunction
      .deleteCategory(
        getRowId,
        setLoading,
        setdeletePopup,
        calledFromAgencyLevel
      )
      .then(() => {
        getCategoryTableData();
      });
  };

  useDelayedEffect(getCategoryTableData, 700,[
    Status,
    Category,
    Agency,
  ]);

  const goToAdd = () => {
    setaddMode(true);
    seteditMode(false);
    setshowAddEditView(true);
    setviewMode(false);
    setgetRowId("");
  };

  return (
    <>
      <div>
        <Loader loading={loading} />
        <ModalComponent
         onClose={closeDeletePopup}
          open={deletePopup}
          title="Delete"
          innerContant={
            <DeleteConfirmation
              onClose={closeDeletePopup}
              onDelete={handleDelete}
            />
          }
           closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
        />
        {showAddEditView ? (
          <CategoryAddEditView
            addmode={addMode}
            editMode={editMode}
            viewMode={viewMode}
            setaddMode={setaddMode}
            seteditMode={seteditMode}
            setviewMode={setviewMode}
            setshowAddEditView={setshowAddEditView}
            getRowId={getRowId}
          />
        ) : (
          <div>
            <Filter
              goToAdd={goToAdd}
              Category={Category}
              setCategory={setCategory}
              Status={Status}
              setStatus={setStatus}
              setAgency={setAgency}
              Agency={Agency}
              
              calledFromAgencyLevel={calledFromAgencyLevel}
              calledFromNCIC={calledFromNCIC}
            />
            <div className="table">
              <Table
                columns={coloumn(Edit, DeleteRow,Update,Delete)}
                columnsValue={tableData}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
                onRowClicked={onRowClicked}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                cursorPointer={true}
                 
              
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
