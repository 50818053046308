import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Agency" },
    { field: "Title" },
    { field: "Involvement" },
    { field: "ICR" },
    { field: "Type" },
    {
      field: "Reported Date",
      // valueFormatter: (params) => format(new Date(params.value), "MM-dd-yyyy "),
    },
  ];
};
