import { BackButtonComponent } from "../../../CommonComponents/BackButton/BackButtonComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TabsIndex } from "../Components/Tabs/TabsIndex";
import { RootState } from "../../../../store";
import moment from "moment-timezone";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
import { ConvertToLocalDateFormate } from "src/utils/Helper/Helper";

export const ViewAgencyManagemant: React.FC = () => {
  const navigation = useNavigate();
  const NavigateToAddPage = () => {
    navigation(`/agency`);
  };
  const { agencyName, createdby, createdOn, AgencyTypeName, ModifiedOn } =
    useSelector((store: RootState) => store.AgencyReducer);

  const dateFormate = ModifiedOn ? ConvertToLocalDateFormate(ModifiedOn) : "";

  return (
    <>
      <div className="EditAgencyManagemant">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Agency", path: "/agency" },
            { title: "View", path: "" },
          ]}
        />
        <BackButtonComponent
          name="Back to Agency"
          onClick={NavigateToAddPage}
        />
        <div className="EditAgencyManagemant_topdetail">
          <p className="header">{agencyName}</p>
          <p>
            <strong>Agency Type: </strong>
            {AgencyTypeName}
          </p>
          <p>
            <strong>Last Modified By: </strong>
            {createdby} on {dateFormate}
          </p>
        </div>
        <TabsIndex viewComponent={true} />
      </div>
    </>
  );
};
