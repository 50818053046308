import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ValidateRouteState {
  changeTheForm: boolean;
}

const initialState: ValidateRouteState = {
  changeTheForm: false,
};

const slice = createSlice({
  name: "ValidateRouteReducer",
  initialState,
  reducers: {
    setchangeTheForm(state, action: PayloadAction<boolean>) {
      state.changeTheForm = action.payload;
    },
  },
});

export const { setchangeTheForm } = slice.actions;

export default slice.reducer;
