import { useEffect, useState } from "react";
import Breadcrumbs from "../../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { Table } from "../../../../CommonComponents/Table/Table";
import LocationMetaData from "../../Location/LocationMetaData";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { VehicleListFunction } from "./VehicleFunction";
import { useNavigate, useParams } from "react-router-dom";
import { setTableData } from "./VechicleIState";
import { coloum } from "./config";
import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { Filter } from "./Components/VehicleFilter";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { useGetPerticularUserPermission } from "src/hooks/Permission/useGetPerticularUserPermission";

const VehicleList = () => {
  const { Delete, Update } = useGetPerticularUserPermission("Incident.Location");
  const VehicleFunction = new VehicleListFunction();
  const [width, setwidth] = useState<number>(10);
  const [tableData, setTableData] = useState<setTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("License Plate");
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [deleteVehicleId, setDeleteVehicleId] = useState<string | null>(null);
  const navigation = useNavigate();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const { incidentId } = useParams<InvolvementMainIParams>();
  const getVehicleTableData = (resetPagination?: boolean) => {
    VehicleFunction.getVehicleListTableData(
      setTableData,
      resetPagination ? 1 : pageNumber,
      resetPagination ? 10 : pageCount,
      setLoading,
      setTableTotalRecord,
      Name,
      Type || "",
      OrderByDescending,
      OrderBy
    );
  };

  const initialData = () => {
    getVehicleTableData();
  };
  useEffect(() => {
    initialData();
  }, [pageNumber, pageCount, OrderByDescending]);
  const clearAllFilter = () => {
    setName("");
    setType("")
    VehicleFunction.getVehicleListTableData(
      setTableData,
      pageNumber,
      pageCount,
      setLoading,
      setTableTotalRecord,
      "",
      "",
      OrderByDescending,
      OrderBy
    );
  };

  const NavigateToEditPage = (vehicleId: string) => {
    navigation(`/incidents/${incidentId}/vehicles/${vehicleId}/edit`);
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
    setDeleteVehicleId(null);
  };
  const onRowClicked = (e: setTableData) => {
    if (!deletePopup && e?.VehicleId) {
      navigation(`/incidents/${incidentId}/vehicles/${e?.VehicleId}/overview`);
    }
  };
  const handleDelete = () => {
    if (deleteVehicleId && incidentId) {
      VehicleFunction.deleteVehicle(
        deleteVehicleId,
        incidentId,
        setLoading,
        getVehicleTableData
      );
      closeDeletePopup();
    }
  };
  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="_incident_location_container_fluid_">
        <div className="_location_left_container_">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>
        <div className="UserManagement">
          <div className="_user_management_container">
          <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path:  `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              { title: "Vehicles", path:  `/incidents/${incidentId}/vehicles` },
            ]}
          />
            <p className="header" style={{display:"flex",alignItems:"center"}}>Vehicles <div style={{ margin: "0 10px", borderLeft: "1px solid #000", height: "20px" }}></div> {incidentNumber}</p>
            <Filter
              Name={Name}
              setName={setName}
              Type={Type}
              setType={setType}
              onSearch={getVehicleTableData}
              clearAllFilter={clearAllFilter}
            />
            <div className="table">
              <Table    
                columns={coloum(
                  NavigateToEditPage,
                  setdeletePopup,
                  setDeleteVehicleId,
                  Delete, Update
                )}
                columnsValue={tableData}
                cursorPointer={true}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                headerBackgroundColor="#b3c9e2"
                
                
                onRowClicked={onRowClicked}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleList;
