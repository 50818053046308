import delete_icon from "../../../../assets/icons/delete.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, SetTableData } from "./ParticipantI";

export const OrderByFieldName: OrderByFieldNameI = {
  Involvement: "Involvement",
  "First Name": "FirstName",
  "Middle Name": "MiddleName",
  "Last Name": "LastName",
  Address: "Address",
  Age: "Age",
  DOB: "DOB",
  Status: "Status",
};

export const coloum = (
  setdeletePopup: (data: boolean) => void,
  setDeleteAgencyId: (id: string) => void,
  edit: (id: SetTableData) => void,
  setshowAddresPopup: (data: boolean) => void,
  setshowAddresPopupId: (id: string) => void,
  Delete: boolean,
  Update: boolean
): ColDef[] => {
  return [
    { field: "Involvement" },
    { field: "Last Name" },
    { field: "First Name" },
    { field: "Middle Name" },
    {
      field: "DOB",
      valueFormatter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "MM-dd-yyyy");
        } else {
          return "";
        }
      },
    },

    {
      field: "Address",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  alignItems: "center",
                  marginTop: "0px",
                  width: "145px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inlineBlock",
                }}
              >
                {e.data.Address}
              </p>
              <p style={{ alignItems: "center", marginTop: "0px" }}>
                {e.data.AddressCount > 1 && (
                  <span
                    onClick={() => {
                      setshowAddresPopupId(e.data.ParticipantId);
                      setshowAddresPopup(true);
                    }}
                    style={{ textDecoration: "underline" }}
                  >
                    + more{" "}
                  </span>
                )}
              </p>
            </div>
          </>
        );
      },
    },

    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
              <img
                className="table-cellRenderer-img"
                src={edit_icon}
                title="Edit"
                onClick={() => {
                  if (e?.data?.ParticipantId) {
                    edit(e?.data);
                  }
                }}
              />
            )}
            {Delete && (
              <img
                title="Delete"
                className="table-cellRenderer-img"
                src={delete_icon}
                alt="Delete"
                onClick={() => {
                  setDeleteAgencyId(e?.data?.ParticipantId);
                  setdeletePopup(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
};
