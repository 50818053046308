import { Fetcher } from "../../../../../services/Fetcher";
import {
  GetPermisssionTableDataI,
  SetTableData,
} from "./ListPermissionManagementI";

const fetcher = new Fetcher();

export class ListPermissionFunction {
  async GetCategoryData(
    setLoading: (data: boolean) => void,
    categoryId: string,
    setTableData: (data: SetTableData[]) => void,
    setFiltertableData: (data: SetTableData[]) => void
  ) {
    setLoading(true);

    let response = await fetcher.get(
      `/permission${categoryId ? `?categoryId=${categoryId}` : ""}`
    );
    const TableJson = this.createTableJson(response?.Data).sort((a, b) => {
      const aHasPermissions = Number(
        a.Create || a.Read || a.Delete || a.General || a.Update
      );
      const bHasPermissions = Number(
        b.Create || b.Read || b.Delete || b.General || b.Update
      );
      return bHasPermissions - aHasPermissions;
    });

    setTableData(TableJson);
    setFiltertableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: GetPermisssionTableDataI[]) {
    return data?.map((val: GetPermisssionTableDataI) => {
      return {
        Permission: val.DisplayName,
        Description: val.Description,
        General: val.General,
        ID: val.PermissionId,
        CategoryId: val.CategoryId,
        Create: val.Create,
        Read: val.Read,
        Update: val.Update,
        Delete: val.Delete,
      };
    });
  }

  applyFilterOnUserpolicyTable(
    DisplayName: string,
    FiltertableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void
  ) {
    let filteredData: SetTableData[] = [];
    if (DisplayName) {
      filteredData = FiltertableData.filter((item) => {
        return (
          item["Permission"] &&
          item["Permission"]
            .toLowerCase()
            .includes(DisplayName.toLowerCase().trim())
        );
      });
    } else {
      filteredData = FiltertableData;
    }
    setTableData(filteredData);
  }
}
