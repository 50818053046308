import React, { useState } from 'react'
import LocationMetaData from '../../Location/LocationMetaData'
import NarrativeOverviewEditContent from './NarrativeOverviewEditContent'

const NarrativeOverViewEdit = () => {
    const [width, setwidth] = useState<number>(10);
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    return (
        <>
            <div className="_incident_location_container_fluid">
                <div className="_location_left_container">
                    <LocationMetaData
                        width={width}
                        setWidth={setwidth}
                    />
                </div>

                <div className="_location_right_container">
                    <NarrativeOverviewEditContent
                        Type=''
                        setType={() => { }}
                        Name=''
                        setName={() => { }} 
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        />
                </div>
            </div>
        </>
    )
}

export default NarrativeOverViewEdit