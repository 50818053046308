import {
  setUserdata,
  setUserdataPayload,
} from "../../../../../../Redux/UserReducer";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";
import { Fetcher } from "../../../../../../services/Fetcher";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../utils/const";
import { setMultiplePhoneNumberListIState } from "../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumberI";
import { openNotificationWithIcon } from "../../../../../CommonComponents/Toster/Toster";
import {
  SetFormDataIState,
  setFormDataValidation,
  setHome_UnitDataIState,
  setRankDataIState,
  setsuffixIState,
} from "./SummaryTabInterface";

const fetcher = new Fetcher();
export class SummaryTabFunction {
  async getUserManagemantData(
    setLoading: (data: boolean) => void,
    userId: string,
    setFormData: (data: SetFormDataIState) => void,
    setBase64: (data: string) => void,
    dispatch: (action: { type: string; payload: setUserdataPayload }) => void
  ) {
    setLoading(true);
    let responce = await fetcher.get(`/user/${userId}`);

    let {
      UserId,
      AgencyId,
      Type,
      ProfileImage,
      EmailAddress,
      FirstName,
      MiddleName,
      LastName,
      PostNumber,
      BadgeNumber,
      HomeUnitCodeId,
      RankCodeId,
      SuffixCodeId,
      ModifiedByUserName,
      CreatedByUserName,
      HomeUnitName,
      RankName,
      IsActive,
      Designation,
      StreetAddress,
      Primaryphone,
      SecondaryPhone,
      City,
      StateCodeId,
      EmployNumber,
      ZipCode,
      Contacts,
      TypeCodeId,
      ModifiedOn,
    } = responce?.Data;

    setFormData({
      FirstName: FirstName,
      MiddleName: MiddleName,
      LastName: LastName,
      Suffix: SuffixCodeId,
      PostNumber: PostNumber,
      HomeUnit: HomeUnitCodeId,
      Rank: RankCodeId,
      BadgeNumber,
      Emailaddress: EmailAddress,
      Status: IsActive ? "1" : "2",
      AgencyId: AgencyId,
      City,
      EmployeeNumber: EmployNumber,
      ProfileImage,
      State: StateCodeId,
      Streetaddress: StreetAddress,
      UserId,
      Zipcode: ZipCode,
      UserType: TypeCodeId,
      Contacts: Contacts.map(
        (val: setMultiplePhoneNumberListIState, i: number) => {
          return { ...val, id: i };
        }
      ),
    });
    setBase64(ProfileImage);

    dispatch(
      setUserdata({
        Email: EmailAddress,
        fullname: `${FirstName} ${MiddleName} ${LastName}`,
        HomeUnit: HomeUnitName,
        Status: IsActive ? "Active" : "InActive",
        images: ProfileImage,
        ModifiedByUserName:ModifiedByUserName,
        ModifiedOn:ModifiedOn,
      })
    );

    setLoading(false);
  }

  async addUser(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    base64: string,
    NavigateToEditPage: (data: number) => void
  ) {
    setLoading(true);
    const response = await fetcher.post(
      "/user",
      {
        ProfileImage: base64,
        EmailAddress: formData.Emailaddress,
        FirstName: formData.FirstName,
        MiddleName: formData.MiddleName,
        LastName: formData.LastName,
        PostNumber: formData.PostNumber,
        BadgeNumber: formData.BadgeNumber,
        SuffixCodeId: formData.Suffix,
        HomeUnitCodeId: formData.HomeUnit,
        RankCodeId: formData.Rank,
        StreetAddress: formData.Streetaddress,
        City: formData.City,
        ZipCode: formData.Zipcode,
        Designation: "",
        StateCodeId: formData.State,
        EmployNumber: formData.EmployeeNumber,
        Contacts: formData.Contacts.filter(
          (val: setMultiplePhoneNumberListIState) => {
            if (val.ContactMethodCodeId && val.ContactValue) {
              return {
                ContactMethodCodeId: val.ContactMethodCodeId,
                ContactValue: val.ContactValue,
                IsPrimary: val.IsPrimary,
              };
            }
          }
        ),
        IsActive: formData.Status == "1" ? true : false,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.UserId) {
      NavigateToEditPage(response?.Data?.UserId);
    }
  }

  async editUser(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    UserId: string,
    base64: string
  ) {
    console.log(formData);
    setLoading(true);
    const response = await fetcher.put(
      "/user",
      {
        UserId: UserId,
        ProfileImage: base64,
        EmailAddress: formData.Emailaddress,
        FirstName: formData.FirstName,
        MiddleName: formData.MiddleName,
        LastName: formData.LastName,
        PostNumber: formData.PostNumber,
        BadgeNumber: formData.BadgeNumber,
        SuffixCodeId: formData.Suffix,
        HomeUnitCodeId: formData.HomeUnit,
        RankCodeId: formData.Rank,
        StreetAddress: formData.Streetaddress,
        City: formData.City,
        ZipCode: formData.Zipcode,
        Designation: "",
        StateCodeId: formData.State,
        EmployNumber: formData.EmployeeNumber,
        Contacts: formData.Contacts.filter(
          (val: setMultiplePhoneNumberListIState) => {
            if (val.ContactMethodCodeId && val.ContactValue) {
              return {
                ContactType: val.ContactMethodCodeId,
                ContactValue: val.ContactValue,
                IsPrimary: val.IsPrimary,
              };
            }
          }
        ),
        IsActive: formData.Status == "1" ? true : false,
      },
      { message: successUpdateMessage, show: true }
    );
    setLoading(false);
  }

  async getRankData(
    setRankData: (data: setRankDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/agency/category/PDCQRR1YXR");
    setRankData(response.Data);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getHome_UnitData(
    setHome_UnitData: (data: setHome_UnitDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/agency/category/3JP2VRP0MK");

    setHome_UnitData(response.Data);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getSuffixData(
    setSuffix: (data: setsuffixIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/system/category/WJ7Q3497JG");
    setSuffix(response.Data);
    setLoading(false);
  }

  async getUserTypeData(
    setUserTypeData: (data: setsuffixIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/system/category/1EZPN4W5MV");
    setUserTypeData(response.Data);
    setLoading(false);
  }

  async EmailAddressValidate(
    setLoading: (data: boolean) => void,
    setEmailAddressCheck: (data: boolean) => void,
    emailAddress: string
  ) {
    setLoading(true);
    const response = await fetcher.get(`/user/${emailAddress}/validate`);
    if (response?.Data) {
      setEmailAddressCheck(true);
    } else {
      openNotificationWithIcon("error", response?.Message);
      setEmailAddressCheck(false);
    }
    setLoading(false);
  }

  async errorValidation(
    formData: SetFormDataIState,
    setFormDataValidation: (data: setFormDataValidation) => void
  ) {
    let valdationerror: setFormDataValidation = {
      AgencyId: false,
      BadgeNumber: false,
      City: false,
      Emailaddress: false,
      FirstName: false,
      HomeUnit: false,
      LastName: false,
      MiddleName: false,
      PostNumber: false,
      ProfileImage: false,
      Rank: false,
      State: false,
      Status: false,
      Streetaddress: false,
      Suffix: false,
      UserId: false,
      Zipcode: false,
      EmployeeNumber: false,
      UserType: false,
      Contacts: false,
    };
    if (!formData.Emailaddress) {
      valdationerror.Emailaddress = true;
    } else {
      valdationerror.Emailaddress = false;
    }
    if (!formData.LastName) {
      valdationerror.LastName = true;
    } else {
      valdationerror.LastName = false;
    }

    if (!formData.FirstName) {
      valdationerror.FirstName = true;
    } else {
      valdationerror.FirstName = false;
    }

    if (!formData.PostNumber) {
      valdationerror.PostNumber = true;
    } else {
      valdationerror.PostNumber = false;
    }

    if (!formData.HomeUnit) {
      valdationerror.HomeUnit = true;
    } else {
      valdationerror.HomeUnit = false;
    }

    if (!formData.Rank) {
      valdationerror.Rank = true;
    } else {
      valdationerror.Rank = false;
    }

    if (!formData.Streetaddress) {
      valdationerror.Streetaddress = true;
    } else {
      valdationerror.Streetaddress = false;
    }

    if (!formData.City) {
      valdationerror.City = true;
    } else {
      valdationerror.City = false;
    }

    if (!formData.State) {
      valdationerror.State = true;
    } else {
      valdationerror.State = false;
    }

    if (!formData.Zipcode) {
      valdationerror.Zipcode = true;
    } else {
      valdationerror.Zipcode = false;
    }

    if (!formData.Status) {
      valdationerror.Status = true;
    } else {
      valdationerror.Status = false;
    }

    setFormDataValidation({ ...valdationerror });
  }

  checkRequiredFields(formData: SetFormDataIState, EmailAddressCheck: boolean) {
    return (
      formData.Emailaddress &&
      formData.FirstName &&
      formData.LastName &&
      formData.PostNumber &&
      formData.HomeUnit &&
      formData.Rank &&
      formData.Streetaddress &&
      formData.City &&
      formData.State &&
      formData.Zipcode &&
      formData.Status
    );
  }
}
