import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Case" },
    { field: "Date" },
    { field: "Street Address" },
    { field: "City" },
    { field: "State" },
    { field: "Zip" },
  ];
};
