import { useEffect, useState } from "react";
import { SetTableData, setuserpoliciesIState } from "./ListRoleIState";
import { UserPolicyFunction } from "./RoleFunction";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { coloum } from "./Config";
import useDelayedEffect from "../../../../../../hooks/useDelayedEffect";
import { EditUserManagementIParams } from "../../../EditUserManagement/EditUserManagementI";
import { Filter } from "../Roles/Components/Filter";

const RolesTab: React.FC = () => {
  const { userId } = useParams<EditUserManagementIParams>();
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [RowCheckBox, setRowCheckBox] = useState<
    { [key: string]: string | number | boolean | null }[]
  >([]);
  const [GetrolepoliciesDataLength, setGetrolepoliciesDataLength] =
    useState<number>(0);
  const userPolicyFunction = new UserPolicyFunction();
  const [policyNameFilter, setpolicyNameFilter] = useState<string>("");
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [changeValue, setChangeValue] = useState<boolean>(false);
  const [oneTimeRender, setoneTimeRender] = useState<boolean>(true);

  const selectRowCheckBox = (
    data: { [key: string]: string | number | boolean | null }[]
  ) => {
    userPolicyFunction.selectRowCheckBox(
      FiltertableData,
      data,
      setFiltertableData,
      oneTimeRender,
      GetrolepoliciesDataLength,
      setChangeValue,
      setoneTimeRender,
      tableData,
      changeValue
    );
  };

  const onCancel = () => {
    initialData();
  };

  const GetrolepoliciesData = () => {
    if (userId) {
      userPolicyFunction.GetPolicyTableData(
        setTableData,
        setLoading,
        setFiltertableData,
        userId,
        setGetrolepoliciesDataLength
      );
    }
  };
  const applyFilterOnUserpolicyTable = () => {
    userPolicyFunction.applyFilterOnUserTable(
      policyNameFilter,
      FiltertableData,
      setTableData
    );
  };
  const onSave = () => {
    if (userId) {
      let body: { RoleId: string; isActive: boolean }[] = [];
      FiltertableData.map((val) => {
        if (val.isSelected) {
          body.push({
            RoleId: val.ID,
            isActive: val.Status == "Active" ? true : false,
          });
        }
      });

      userPolicyFunction.userPolicyAdd(setLoading, userId, body);
    }
  };

  const initialData = async () => {
    await GetrolepoliciesData();
  };

  useEffect(() => {
    initialData();
  }, []);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [policyNameFilter]);

  console.log("change value", changeValue);
  return (
    <>
      <Loader loading={loading} />
      <Filter
        policyNameFilter={policyNameFilter}
        setpolicyNameFilter={setpolicyNameFilter}
      />

      <div className="table">
        <Table
          columns={coloum()}
          columnsValue={tableData}
          selectRowCheckBox={selectRowCheckBox}
          allowMultipleRowSelection={true}
          clientSideSorting={true}
        />
        <div className="edit-button">
          {changeValue ? (
            <div className="edit-button">
              <Submit onCancel={onCancel} onSave={onSave} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default RolesTab;
