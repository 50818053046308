import React, { useEffect, useState } from "react";
import add from "../../../../../../assets/icons/Icon.svg";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { FilterIProps } from "./FilterIState";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../../store";
import { useSelector } from "react-redux";
import { InvolvementMainIParams } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { CasesListFunction } from "../../CasesFunctions";
import { agencyLevel } from "../CaseIState";

export const Filter: React.FC<FilterIProps> = ({
  Name,
  setName,
  Type,
  setType,
  setShowExternalCasePopUp,
  setAgency,
  agency
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const navigation = useNavigate();
  const [nameType, setNameType] = useState<string>("");
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const { incidentId } = useParams<InvolvementMainIParams>();

   const casesMainInstance=new CasesListFunction();
  const clearAllFilters = () => {
    setName("");
    setType("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  
  };

  const handleExternalCases=()=>{
      setShowExternalCasePopUp(true)
  }
 
  const agencyLevelHandler=async()=>{
     await casesMainInstance?.getAgencyLevelList(setAgency)
  }
  useEffect(() => {
  
    if (Name.trim() !== "" || Type !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, Type]);

  useEffect(()=>{
     agencyLevelHandler()
  },[]);
  const handleClick = () => {
    navigation(`/incidents/${incidentId}/related-incidents/add`);
  };
  return (
    <>
      <div className="cases_related_filter_container">
        <div className="cases_left_filter_container">
          <div className="search_related_textinput">
          <TextBoxComponent
            fieldName={"Search Related"}
            value={Name}
            onChangeValue={setName}
          />
          </div>
         <div className="search_related_ageny">
          <DropdownComponent
            dataSource={agency?.map((val)=>{
              return {
                id:val?.AgencyId,
                value:val?.AgencyName
              }
            })

            }
            fieldName={"Agency"}
          />
          </div>
          <div className="search_related_ageny">
          <DropdownComponent dataSource={[]} fieldName={"Type"} />
          </div>

          <div className="cases_filter_search_btn">
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
            // handleClick={handleClick}
            />
          </div>
          <div className="cases_filter_clear_btn">
            <ButtonComponents
              // icon={close}
              name="Clear Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="external_cases_btn_container">
          <div>
            <ButtonComponents
              icon={add}
              name="Add External Case"
              textColor="white"
              showBackgroundColor={true}
              handleClick={handleExternalCases}
            />
          </div>
          <div>
            <ButtonComponents
              icon={add}
              name="Add New Related Case"
              textColor="white"
              showBackgroundColor={true}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};
