import React, { useEffect, useState } from "react";
import { coloum } from "./Config";
import { BloodMainFunction } from "./BloodMainFunction";
import { BloodData, MasterMainIParams } from "../../MasterNameIState";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { useParams } from "react-router-dom";

export const Blood: React.FC = () => {
  const bloodFunction = new BloodMainFunction();
  const [tableData, setTableData] = useState<BloodData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const { nameId } = useParams<MasterMainIParams>();
  const getEmergencyContact = () => {
    if(nameId){
      bloodFunction.getBloodMain(setTableData, setTableTotalRecord,nameId);
    }
    
  };

  useEffect(() => {
    getEmergencyContact();
  }, []);

  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Blood Alocohol Level: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
           <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
