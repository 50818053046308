import React, { useEffect, useState } from "react";
import add from "../../../../../../assets/icons/Icon.svg";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";

import { useNavigate } from "react-router-dom";
import { FilterIProps } from "./FilterIProps";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";

export const Filter: React.FC<FilterIProps> = ({
  Name,
  setName,
  Type,
  setType,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const navigation = useNavigate();
  const [nameType, setNameType] = useState<string>("");
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const clearAllFilters = () => {
    setName("");
    setType("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };

  useEffect(() => {
    if (Name.trim() !== "" || Type !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, Type]);

  const handleClick = () => {
    navigation("/incidents/articles/add");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds___">
          <TextBoxComponent
            fieldName={"Search article"}
            value={Name}
            onChangeValue={setName}
          />
<DateBoxComponents fieldName="Stolen Date"/>
<DateBoxComponents fieldName="Recovery Date"/>
          <div className="clearbutton1">
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              // handleClick={handleClick}
            />
          </div>
          <div className="clearbutton1">
            <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
          <div>
            <ButtonComponents
              icon={add}
              name="Add Article"
              textColor="white"
              showBackgroundColor={true}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};
