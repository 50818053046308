import {
  setlowerSidebarDataIstate,
  setnavigationDataIstate,
  SubmenuResponce,
} from "./SidebarExpendI";
import file from "../../../../assets/icons/book.svg";
import user from "../../../../assets/icons/user-tag.svg";
import note from "../../../../assets/icons/note.svg";
import message from "../../../../assets/icons/message-notif.svg";
import fingerprint from "../../../../assets/icons/fingerprint.svg";
import media from "../../../../assets/icons/video-play.svg";
import arrow from "../../../../assets/icons/arrow-square-right.svg";
import codetables from "../../../../assets/icons/book.svg";
import permission from "../../../../assets/icons/security-user.svg";
import ticketstar from "../../../../assets/icons/ticket-star.png";
import shieldsearch from "../../../../assets/icons/shield-search.png";

export const setlowerSidebarData: () => setlowerSidebarDataIstate[] = () => {
  return [
    {
      icon: arrow,
      title: "Collapse Menu",
      action: true,
      id: 5,
    },
  ];
};

export const setnavigationData: (
  AGENCY_VIEW: boolean,
  Code_Agency_VIEW: boolean,
  Code_System_VIEW: boolean,
  USER_VIEW: boolean,
  DynamicSubMenuList: SubmenuResponce[]
) => setnavigationDataIstate[] = (
  AGENCY_VIEW: boolean,
  Code_Agency_VIEW: boolean,
  Code_System_VIEW: boolean,
  USER_VIEW: boolean,
  DynamicSubMenuList: SubmenuResponce[]
) => {
  return [
    {
      id: 1,
      icon: file,
      title: "Incident",
      path: "/incidents",
      dynamic: true,
      submenu: DynamicSubMenuList.map((val) => {
        return {
          id: val.id,
          path: val.path,
          title: val.title,
        };
      }),
    },
    {
      id: 2,
      icon: media,
      title: "Media Management",
      path: "",
    },
    {
      id: 3,
      icon: codetables,
      title: "Master Name Index",
      path: "/master",
    },
    {
      id: 4,
      icon: fingerprint,
      title: "CAD Information",
      path: "/cad",
    },
    {
      id: 5,
      icon: message,
      title: "Citations",
      path: "",
    },
    {
      id: 6,
      icon: user,
      title: USER_VIEW ? "User Management" : "",
      path: USER_VIEW ? "/user" : "",
    },
    {
      id: 7,
      icon: shieldsearch,
      title: AGENCY_VIEW ? "Agency Management" : "",
      path: AGENCY_VIEW ? "/agency" : "",
    },
    {
      id: 8,
      icon: codetables,
      title: "Code Tables",
      path: "",
      submenu: [
        {
          id: 1,
          path: Code_Agency_VIEW ? "/code/agency" : "",
          title: Code_Agency_VIEW ? "Agency Level" : "",
        },
        { id: 2, path: "/code/ncic", title: "NCIC" },
        {
          id: 3,
          path: Code_System_VIEW ? "/code/system" : "",
          title: Code_System_VIEW ? "System Level" : "",
        },
      ],
    },
    {
      id: 9,
      icon: note,
      title: "Audit",
      path: "/audit",
    },
    {
      id: 10,
      icon: permission,
      title: "Role Permission",
      path: "",
      submenu: [
        { id: 1, path: "/permission", title: "Permission" },
        { id: 2, path: "/policy", title: "Policy" },
        { id: 3, path: "/role", title: "Roles" },
      ],
    },

    {
      id: 11,
      icon: ticketstar,
      title: "Config",
      path: "",
      submenu: Code_System_VIEW
        ? [{ id: 1, path: "/config/menu", title: "Menu" }]
        : [],
    },
  ];
};
