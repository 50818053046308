import React from "react";
import { PrimaryAdd } from "../../../../assets";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonComponents } from "../../../CommonComponents/Fields/Button/ButtonComponents";
import { LocationFilterProps } from "./LocationOverviewIState";
import { TextBoxComponent } from "../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { InvolvementMainIParams } from "../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
import { useGetPerticularUserPermission } from "src/hooks/Permission/useGetPerticularUserPermission";

const LocationFilter: React.FC<LocationFilterProps> = ({
  searchText,
  setSearchText,
  getLocationList,
  setIsClearFilter,
  isClearFilter,
}) => {
  const { Create } = useGetPerticularUserPermission("Incident.Location");
  const navigate = useNavigate();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const { incidentId } = useParams<InvolvementMainIParams>();
  const navigateHandler = () => {
    navigate(`/incidents/${incidentId}/locations/add`);
  };
  return (
    <>
      <div className="location_filter_container_fluid">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Incidents", path: "/incidents" },
            { title: incidentNumber ? incidentNumber : "", path: "" },
            { title: "Locations", path: `/incidents/${incidentId}/locations` },
          ]}
        />
        <div className="_location_filter_title">
          <p className="header">List Of Location</p>
        </div>

        <div className="Filter">
          <div className="filds___ ">
            <div className="search_narrative_conatiner">
              <TextBoxComponent
                value={searchText}
                fieldName="Search Location"
                onChangeValue={(e) => setSearchText(e)}
              />
            </div>
            <div className="clearbutton">
              <ButtonComponents
                name="Search"
                color="#3672b3"
                textColor="white"
                borderColor="#3672b3"
                showBackgroundColor={true}
                handleClick={getLocationList}
              />
            </div>
            <div className="clearbutton">
              <ButtonComponents
                disabled={searchText?.length < 1}
                name="Clear all Filters"
                textColor={searchText?.length < 1 ? "gray" : "red"}
                borderColor={searchText?.length < 1 ? "gray" : "red"}
                color="white"
                showBackgroundColor={true}
                handleClick={() => {
                  setIsClearFilter(!isClearFilter);
                  setSearchText("");
                }}
              />
            </div>
          </div>
          <div className="_narrative_filter_btn_container">
            <div className="clearbutton" onClick={navigateHandler}>
              {Create && (
                <ButtonComponents
                  name="Add New Locations"
                  borderColor="#3672b3"
                  showBackgroundColor={true}
                  color="#3672b3"
                  textColor="white"
                  icon={PrimaryAdd}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationFilter;
