import { Fetcher } from "../../../../../services/Fetcher";

const fetcher = new Fetcher();
interface responseData {
  EntityType: string;
  Url: string;
  UserAction: null | string;
  RequestParametersJson: string;
  OldValuesJson: string;
  NewValuesJson: string;
  SystemRemarks: string;
  Dbaction: null | string;
  Ipaddress: string;
  CreateByUserName: null | string;
  CreatedOn: string;
}

export class AuditDetailFunction {
  async getByAuditHistory(
    ID: string,
    setLoading: (data: boolean) => void,
    setoldValue: (
      data: {
        [key: string]: { [key: string]: string | number | boolean }[];
      }[]
    ) => void,
    setnewValue: (
      data: {
        [key: string]: { [key: string]: string | number | boolean }[];
      }[]
    ) => void,
    dropdownOldNewvalue: (
      data: {
        [key: string]: { [key: string]: string };
      }[]
    ) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(`/audithistory/${ID}`);
    let res: responseData = response.Data;
    let oldvalue = JSON.parse(res.OldValuesJson);
    let newvalue = JSON.parse(res.NewValuesJson.split("|")[0]);

    // dropdownOldNewvalue(JSON.parse(res.NewValuesJson?.split("|")?.[1]));

    let oldValueArray = [];
    let newValueArray = [];

    for (let key in oldvalue) {
      oldValueArray.push({ [key]: oldvalue[key] });
    }

    for (let key in newvalue) {
      newValueArray.push({ [key]: newvalue[key] });
    }

    setoldValue(oldValueArray);
    setnewValue(newValueArray);

    setLoading(false);
  }
}
