import { GetAuditHistoryTableDataI, OrderByType, SetTableData } from "./AuditI";
import moment from "moment-timezone";
import {
  AuditHistoryData,
  SetTableDataAuditDetail,
} from "../AuditDetail/AuditDetailI";
import { Fetcher } from "../../../../../services/Fetcher";
import { convertToISOFormat } from "../../../../../utils/Helper/Helper";
import { OrderByFieldName } from "./Config";

const fetcher = new Fetcher();

export class AuditHistoryFunction {
  async getAuditHistoryTableData(
    setTableData: (data: SetTableData[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    Action: string,
    Creationdatefrom: string | null,
    Creationdateto: string | null,
    UserName: string,
    UserActionValue: string,
    OrderByDescending?: boolean,
    OrderBy?: string | null,
    IsDetailExist?: boolean
  ) {
    setLoading(true);

    const isSearch = !!(
      Action ||
      Creationdatefrom ||
      Creationdateto ||
      UserName
    );
    let response = await fetcher.post(`/audithistory/search`, {
      Action: Action ? Action : null,
      StartDate: Creationdatefrom ? convertToISOFormat(Creationdatefrom) : null,
      EndDate: Creationdateto ? convertToISOFormat(Creationdateto) : null,
      pageNumber: isSearch ? 1 : pageNumber,
      IsDetailExist: IsDetailExist,
      PageSize: pageCount,
      Name: UserName ? UserName : null,
      UserAction: Action ? Action : null,
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
    });
    const tableJson = this.createTableJson(response?.Data);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(tableJson);
    setLoading(false);
  }

  async getAllAuditHistoryTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    let response = await fetcher.post(`/audithistory/search`, {
      pageNumber: 1,
      PageSize: 1000,
    });
    const tableJson = this.createTableJson(response?.Data);
    setTableData(tableJson);
    setLoading(false);
  }

  createTableJson(data: GetAuditHistoryTableDataI[]): SetTableData[] {
    return data?.map((val) => ({
      Action: val.Action,
      Method: "",
      Section: val.Section,
      IsDetailExist: val.IsDetailExist,
      "Action By": val.UserName,
      "Reported Date/Time": moment
        .utc(val.CreatedOn)
        .local()
        .format("MM-DD-YYYY HH:mm:ss"),
      "IP Address": val.IPAddress,
      ID: val.AuditTrailId,
      CreatedOn: val.CreatedOn,
    }));
  }

  async getAuditHistoryById(
    ID: number,
    setAuditDetail: (data: AuditHistoryData) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    try {
      const response = await fetcher.get(`/audithistory/${ID}`);
      console.log("API Response:", response.Data);
      setAuditDetail(response.Data);
    } catch (error) {
      console.error("Error fetching audit history by ID:", error);
    }
    setLoading(false);
  }
}
