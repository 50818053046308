import React, { useEffect, useState } from "react";
import { Table } from "../../../../../CommonComponents/Table/Table";

import { StatutesData } from "../../MasterNameIState";
import { coloum } from "./Config";


export const Scars: React.FC = () => {
  const [tableData, setTableData] = useState<StatutesData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);


  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p><strong>Scars Marks, Tattoos and Other Physical Characteristics</strong></p>
          <p className="DetailTab_top_">
 
 <strong>Total Records: {tableData.length}</strong>
</p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
           
           
           headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
