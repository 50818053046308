// import { Fetcher } from "../../../../../../services/Fetcher";
// import { successDeleteMessage } from "../../../../../../utils/const";
// import {
//   SetTableData,
//   getAddEmergencyTableDataI,
// } from "./EmergencyContactTabI";

import { Fetcher } from "../../../../../../../../../../services/Fetcher";
import { successCreatedMessage } from "../../../../../../../../../../utils/const";
import { AddressTableIRes } from "../../../../../../../../MasterNameIndex/MasterOverview/Components/Address/AddressIState";
import { EmergencyTableIRes } from "../../../../../../../../MasterNameIndex/MasterOverview/Components/Emergency/EmergencyIState";
import {
  AdditionalData,
  AddressData,
  ContactData,
  EmergencyData,
} from "../../../../../../../../MasterNameIndex/MasterOverview/MasterNameIState";
import {
  AddressDataOverview,
  Alias,
  Bias,
  CombinedData,
  CombinedPersonDetails,
  ContactI,
  EmergencyContactIRes,
  Identification,
  importMasterIRes,
  IncidentDetails,
  MatchesParticipant,
  namemasterRes,
  NewAddI,
  PersonDetails,
  PhysicalFeatureI,
  USAdditional,
} from "./ViewMatchesOverviewI";

const fetcher = new Fetcher();

export class ViewMatchesOverviewFunction {
  //   async deleteEmergencyContact(
  //     setLoading: (data: boolean) => void,
  //     EmergencyContactId: string,
  //     userId: string
  //   ) {
  //     setLoading(true);
  //     const response = await fetcher.delete(
  //       `/user/${userId}/emergency-contact?emergencyContactId=${EmergencyContactId}`,
  //       {},
  //       {
  //         message: successDeleteMessage,
  //         show: true,
  //       }
  //     );
  //     setLoading(false);
  //   }

  async getMaster(
    NameMasterId: string,
    setData: (data: namemasterRes) => void
  ) {
    const response = await fetcher.get(`/name-master/${NameMasterId}`);
    let data: namemasterRes = response?.Data;
    setData(data);
  }

  async getAddressMain(
    setTableData: (data: AddressDataOverview[]) => void,
    //setTableTotalRecord: (data: number) => void,
    nameId: string
  ) {
    let response = await fetcher.get(`/name-master/${nameId}/address`);
    let res: AddressDataOverview[] = response.Data;
    setTableData(res);
    console.log(res);
  }

  async getDemographicsMain(
    setTableData: (data: PersonDetails[]) => void,
    nameId: string
  ) {
    let response = await fetcher.get(
      `/matches/person/namemaster/${nameId}/demographics`
    );
    let res: PersonDetails[] = response.Data;
    setTableData(res);
    console.log(res);
  }

  async getEmergencyMain(
    setTableData: (data: EmergencyContactIRes[]) => void,
    //setTableTotalRecord: (data: number) => void,
    nameId: string
  ) {
    let response = await fetcher.get(
      `/matches/person/namemaster/${nameId}/emergency-contact`
    );
    let res: EmergencyContactIRes[] = response.Data;
    //setTableTotalRecord(response?.TotalRecords);
    setTableData(res);
  }

  async getIdentification(
    setTableData: (data: IncidentDetails[]) => void,
    nameId: string
  ) {
    let response = await fetcher.get(`/name-master/${nameId}/identifications`);
    let res: IncidentDetails[] = response.Data;
    setTableData(res);
    console.log(res);
  }

  async getAdditional(
    // setLoading: (data: boolean) => void,
    //setfields: (data: AdditionalData) => void,
    setTableData: (data: NewAddI[]) => void,
    nameId: string
  ) {
    //setLoading(true);

    let response = await fetcher.get(`/matches/${nameId}/additionalinfo`);
    console.log(response);
    let resInvolvement: NewAddI[] = response.Data;
    setTableData(resInvolvement);
    // setLoading(false);
  }

  async getAlias(setTableData: (data: Alias[]) => void, nameId: string) {
    //setLoading(true);

    let response = await fetcher.get(
      `/matches/person/namemaster/${nameId}/alias`
    );
    console.log(response);
    let resInvolvement: Alias[] = response.Data;
    setTableData(resInvolvement);
    console.log(resInvolvement);
    // setLoading(false);
  }

  async getBias(setTableData: (data: Bias[]) => void, nameId: string) {
    //setLoading(true);

    let response = await fetcher.get(
      `/matches/person/namemaster/${nameId}/bias`
    );
    console.log(response);
    let resInvolvement: Bias[] = response.Data;
    setTableData(resInvolvement);
    console.log(resInvolvement);
    // setLoading(false);
  }

  async getContactMain(
    setTableData: (data: ContactI[]) => void,
    nameId: string
  ) {
    let response = await fetcher.get(`/name-master/${nameId}/contacts`);
    let res: ContactI[] = response.Data;
    setTableData(res);
  }

  async getScarMarks(
    setTableData: (data: PhysicalFeatureI[]) => void,
    nameId: string
  ) {
    //setLoading(true);

    let response = await fetcher.get(
      `/matches/person/namemaster/${nameId}/scar-mark-tattoo`
    );
    console.log(response);
    let resInvolvement: PhysicalFeatureI[] = response.Data;
    setTableData(resInvolvement);
    console.log(resInvolvement);
    // setLoading(false);
  }

  async importMaster(
    incidentId: string,
    masterId: string,
    combinedDataArray: CombinedData[],
    RedirectToPariticipantEdit: (data: string) => void
  ) {
    let res = await fetcher.post(
      `/matches/person/incident/${incidentId}/master/${masterId}/create`,
      combinedDataArray,
      {
        message: successCreatedMessage,
        show: true,
      }
    );

    let data: importMasterIRes = res.Data;
    RedirectToPariticipantEdit(data.ParticipantId);
  }
}
