import add from "../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import { InvolvementMainFunction } from "../../../Involvement/InvolvementMainFunction";

import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { MaskedInputComponent } from "../../../../../../../../CommonComponents/Fields/MaskedInput/MaskedInputComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import {
  GetRaceDataIState,
  PhoneNumberIProps,
} from "../../../InvolvementMainI";
import { PhoneNumberFunction } from "../../../../AddNewName/ExternalContact/Components/Phone/PhoneNumberFunction";
import { PhoneNumberDataUIProps } from "../../InvolvementMainI";
import { setContactTypeDataIState } from "../../../../AddNewName/ExternalContact/ExternalContactI";
import { Add } from "../../../../../../../../../assets";

export const PhoneNumberDataUI: React.FC<PhoneNumberDataUIProps> = ({
  MainfieldsBusiness,
  setMainfieldsBusiness,
  PhoneNumberval,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const phoneNumberFunction = new PhoneNumberFunction();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null,
    id: number
  ) => {
    setMainfieldsBusiness({
      ...MainfieldsBusiness,
      Involvement: {
        ...MainfieldsBusiness.Involvement,
        PhoneNumber: MainfieldsBusiness.Involvement.PhoneNumber.map((val) => {
          if (val.id == id) {
            return { ...val, [key]: value };
          } else {
            return val;
          }
        }),
      },
    });
  };

  const remove = (id: number) => {
    if (MainfieldsBusiness.Involvement.PhoneNumber.length > 1) {
      setMainfieldsBusiness({
        ...MainfieldsBusiness,
        Involvement: {
          ...MainfieldsBusiness.Involvement,
          PhoneNumber: MainfieldsBusiness.Involvement.PhoneNumber.filter(
            (val) => {
              if (val.id != id) {
                return val;
              }
            }
          ),
        },
      });
    }
  };

  const initialRender = () => {
    involvementMainFunction.getCountry(setRaceDataData);
    phoneNumberFunction.getContactTypeData(setContactTypeData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div className="MultiplePhoneNumber">
        <DropdownComponent
          dataSource={ContactTypeData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          value={PhoneNumberval.Type}
          handleRowClick={(e) => {
            onChangeValue("Type", e, PhoneNumberval.id);
          }}
          fieldName="Type"
        />
        <DropdownComponent
          dataSource={RaceDataData.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          value={PhoneNumberval.Country}
          handleRowClick={(e) => {
            onChangeValue("Country", e, PhoneNumberval.id);
          }}
          fieldName="Country"
        />

        <MaskedInputComponent
          mask="+1 (999)-999-9999"
          value={PhoneNumberval.Number}
          fieldName="Number"
          onChangeValue={(e) => {
            onChangeValue("Number", e, PhoneNumberval.id);
          }}
        />
        <TextBoxComponent
          value={PhoneNumberval.Ext}
          fieldName="Ext"
          onChangeValue={(e) => {
            onChangeValue("Ext", e, PhoneNumberval.id);
          }}
        />
        {MainfieldsBusiness.Involvement.PhoneNumber.length > 1 && (
          <p
            className="removeIcon"
            onClick={() => {
              remove(PhoneNumberval.id);
            }}
            title="Remove"
          >
            -
          </p>
        )}
      </div>
    </>
  );
};

export const PhoneNumber: React.FC<PhoneNumberIProps> = ({
  MainfieldsBusiness,
  setMainfieldsBusiness,
}) => {
  const handleClick = () => {
    setMainfieldsBusiness({
      ...MainfieldsBusiness,
      Involvement: {
        ...MainfieldsBusiness.Involvement,
        PhoneNumber: [
          ...MainfieldsBusiness.Involvement.PhoneNumber,
          {
            id: MainfieldsBusiness.Involvement.PhoneNumber.length + 1,
            Type: "",
            ExternalContactID: "",
            Country: "",
            Number: "",
            Ext: "",
            TypeDropdownvalue: "",
          },
        ],
      },
    });
  };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_">Phone Number</p>
            {MainfieldsBusiness.Involvement.PhoneNumber.map(
              (PhoneNumberval) => {
                return (
                  <>
                    <PhoneNumberDataUI
                      MainfieldsBusiness={MainfieldsBusiness}
                      setMainfieldsBusiness={setMainfieldsBusiness}
                      PhoneNumberval={PhoneNumberval}
                    />
                  </>
                );
              }
            )}
          </div>
        </div>
        <div className="edit-button_" style={{ marginTop: "5%" }}>
          <ButtonComponents
            name="Add Phone Number"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
