import { useEffect, useState } from "react";
import {
  MultipleAddressDataUIProps,
  MultipleAddressIProps,
} from "./MultipleAddressI";
import add from "../../../../../../../../../assets/icons/Icon.svg";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { GetRaceDataIState } from "../../../InvolvementMainI";
import { Add } from "../../../../../../../../../assets";

const MultipleAddressDataUI: React.FC<MultipleAddressDataUIProps> = ({
  MainfieldsBusiness,
  setMainfieldsBusiness,
  MultipleAddressval,
  RaceDataData,
  StreetPrefix,
  StreetSuffix,
  StateCode,
  Country,
  LocationCategory,
  businessValidation,
}) => {
  const [isForeignAddress, setIsForeignAddress] = useState<boolean>(
    MultipleAddressval.ForeignAddress
  );
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number
  ) => {
    setMainfieldsBusiness({
      ...MainfieldsBusiness,
      Involvement: {
        ...MainfieldsBusiness.Involvement,
        Addresses: {
          ...MainfieldsBusiness.Involvement.Addresses,
          AddressData: MainfieldsBusiness.Involvement.Addresses.AddressData.map(
            (val) => {
              if (val.id == id) {
                return { ...val, [key]: value };
              } else {
                return val;
              }
            }
          ),
        },
      },
    });
  };

  const remove = (id: number) => {
    if (setMainfieldsBusiness && MainfieldsBusiness) {
      if (MainfieldsBusiness.Involvement.Addresses.AddressData.length > 1) {
        setMainfieldsBusiness({
          ...MainfieldsBusiness,
          Involvement: {
            ...MainfieldsBusiness.Involvement,
            Addresses: {
              ...MainfieldsBusiness.Involvement.Addresses,
              AddressData:
                MainfieldsBusiness.Involvement.Addresses.AddressData.filter(
                  (val) => {
                    if (val.id != id) {
                      return val;
                    }
                  }
                ),
            },
          },
        });
      }
    }
  };
  const handleForeignAddressChange = () => {
    const newValue = !isForeignAddress;
    setIsForeignAddress(newValue);
    onChangeValueNumber("ForeignAddress", newValue, MultipleAddressval.id);
  };

  return (
    <>
      <div>
        <div className="address-checkbox_">
          {!isForeignAddress && (
            <>
              <div className="p_address_type_container">
                <DropdownComponent
                  value={MultipleAddressval.AddressType}
                  handleRowClick={(e) => {
                    onChangeValueNumber(
                      "AddressType",
                      e,
                      MultipleAddressval.id
                    );
                  }}
                  dataSource={RaceDataData.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Address Type"
                />
              </div>
              <div className="p_address_type_container">
                <DropdownComponent
                  value={MultipleAddressval.LocationCategoryCode}
                  handleRowClick={(e) => {
                    onChangeValueNumber(
                      "LocationCategoryCode",
                      e,
                      MultipleAddressval.id
                    );
                  }}
                  dataSource={LocationCategory.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Location Category"
                  validation={businessValidation.involvement.LocationCategory}
                />
              </div>
             
                <CheckBoxComponent
                  value={MultipleAddressval.NoPermanentAddress}
                  fieldName="No Permanent Address"
                  onChangeValue={(e) => {
                    onChangeValueNumber(
                      "NoPermanentAddress",
                      e,
                      MultipleAddressval.id
                    );
                  }}
                />
                <CheckBoxComponent
                  value={MultipleAddressval.UnknownAddress}
                  fieldName="Unknown Address"
                  onChangeValue={(e) => {
                    onChangeValueNumber(
                      "UnknownAddress",
                      e,
                      MultipleAddressval.id
                    );
                  }}
                />
              
            </>
          )}
          <div>
            <CheckBoxComponent
              value={isForeignAddress}
              fieldName="Foreign Address"
              onChangeValue={handleForeignAddressChange}
            />
          </div>

          {MainfieldsBusiness.Involvement.Addresses.AddressData.length > 1 && (
            <p
              className="removeIcon"
              onClick={() => {
                remove(MultipleAddressval.id);
              }}
              title="Remove"
            >
              -
            </p>
          )}
        </div>
      </div>

      {MultipleAddressval.ForeignAddress ? (
        <>
          <div style={{ width: "70%", marginTop:"-9px" }}>
            <TextBoxComponent
              value={MultipleAddressval.AddressLine1}
              onChangeValue={(e) => {
                onChangeValueNumber("AddressLine1", e, MultipleAddressval.id);
              }}
              fieldName="Address Line 1: Street address, P.O box, company name, c/o"
            />
            <TextBoxComponent
              value={MultipleAddressval.AddressLine2}
              onChangeValue={(e) => {
                onChangeValueNumber("AddressLine2", e, MultipleAddressval.id);
              }}
              fieldName="Address Line 2: Apartment, suite, unit, building, floor, etc"
            />
          </div>
          <div className="Involvement_left_row">
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressCity}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressCity",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="City"
            />
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressStateProvinceRegion}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressStateProvinceRegion",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="State / Province/Region"
            />
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressZip}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressZip",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="Zip / postal Code"
            />
          </div>
          <div style={{ width: "40%" }}>
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressCountry}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressCountry",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="Country"
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="Involvement_left_row">
            <div className="_involvement_meta_info">
              <TextBoxComponent
                value={MultipleAddressval.StreetNumber}
                onChangeValue={(e) => {
                  onChangeValueNumber("StreetNumber", e, MultipleAddressval.id);
                }}
                fieldName="Street Number"
              />
            </div>

            <div className="_involvement_meta_info">
              <DropdownComponent
                handleRowClick={(e) => {
                  onChangeValueNumber("StreetPrefix", e, MultipleAddressval.id);
                }}
                value={MultipleAddressval.StreetPrefix}
                dataSource={StreetPrefix.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Street Prefix"
              />
            </div>

            <div className="involvemet_street_container">
              <TextBoxComponent
                onChangeValue={(e) => {
                  onChangeValueNumber("Street", e, MultipleAddressval.id);
                }}
                value={MultipleAddressval.Street}
                fieldName="Street"
              />
            </div>
            <div className="_involvement_meta_info">
              <DropdownComponent
                value={MultipleAddressval.StreetSuffix}
                handleRowClick={(e) => {
                  onChangeValueNumber("StreetSuffix", e, MultipleAddressval.id);
                }}
                dataSource={StreetSuffix.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Street Suffix"
              />
            </div>
          </div>
          <div className="Involvement_left_row"></div>
          <div className="Involvement_left_row">
            <TextBoxComponent
              value={MultipleAddressval.Apartment}
              onChangeValue={(e) => {
                onChangeValueNumber("Apartment", e, MultipleAddressval.id);
              }}
              fieldName="Apartment"
            />
            <TextBoxComponent
              value={MultipleAddressval.City}
              fieldName="City"
              onChangeValue={(e) => {
                onChangeValueNumber("City", e, MultipleAddressval.id);
              }}
            />
            <DropdownComponent
              value={MultipleAddressval.StateProvidence}
              dataSource={StateCode.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValueNumber(
                  "StateProvidence",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="State / Providence"
            />
            <TextBoxComponent
              value={MultipleAddressval.ZipCode}
              onChangeValue={(e) => {
                onChangeValueNumber("ZipCode", e, MultipleAddressval.id);
              }}
              fieldName="Zip Code"
            />
          </div>
          <div className="country_grid_container">
            <div className="_address_country_container">
              <DropdownComponent
                value={MultipleAddressval.Country}
                handleRowClick={(e) => {
                  onChangeValueNumber("Country", e, MultipleAddressval.id);
                }}
                dataSource={Country.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Country"
              />
            </div>
            <div className="_address_grid_number">
              <TextBoxComponent
                value={MultipleAddressval.GridNumber}
                onChangeValue={(e) => {
                  const value = e;
                  if (/^\d{0,5}$/.test(value)) {
                    onChangeValueNumber(
                      "GridNumber",
                      value,
                      MultipleAddressval.id
                    );
                  }
                }}
                fieldName="Grid Number"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const MultipleAddressComponents: React.FC<MultipleAddressIProps> = ({
  MainfieldsBusiness,
  setMainfieldsBusiness,
  businessValidation,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [StateCode, setStateCode] = useState<GetRaceDataIState[]>([]);
  const [LocationCategory, setLocationCategory] = useState<GetRaceDataIState[]>(
    []
  );

  const handleClick = () => {
    if (setMainfieldsBusiness && MainfieldsBusiness) {
      setMainfieldsBusiness({
        ...MainfieldsBusiness,
        Involvement: {
          ...MainfieldsBusiness.Involvement,
          Addresses: {
            ...MainfieldsBusiness.Involvement.Addresses,
            AddressData: [
              ...MainfieldsBusiness.Involvement.Addresses.AddressData,
              {
                id:
                  MainfieldsBusiness.Involvement.Addresses.AddressData.length +
                  1,
                AddressType: "",
                StreetNumber: "",
                StreetPrefix: "",
                Street: "",
                StreetSuffix: "",
                Apartment: "",
                City: "",
                StateProvidence: "",
                ZipCode: "",
                Country: "",
                GridNumber: "",
                AddressLine1: "",
                AddressLine2: "",
                ForeignAddressCity: "",
                ForeignAddressStateProvinceRegion: "",
                ForeignAddressZip: "",
                ForeignAddressCountry: "",
                ForeignAddress: false,
                NoPermanentAddress: false,
                UnknownAddress: false,
              },
            ],
          },
        },
      });
    }
  };

  const initialRender = () => {
    involvementMainFunction.getAddresType(setRaceDataData);
    involvementMainFunction.getStreetPrefix(setStreetPrefix);
    involvementMainFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setStateCode);
    involvementMainFunction.getLocationType(setLocationCategory);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        {MainfieldsBusiness &&
          MainfieldsBusiness.Involvement.Addresses.AddressData.map(
            (MultipleAddressval) => {
              return (
                <>
                  <MultipleAddressDataUI
                    MainfieldsBusiness={MainfieldsBusiness}
                    setMainfieldsBusiness={setMainfieldsBusiness}
                    MultipleAddressval={MultipleAddressval}
                    RaceDataData={RaceDataData}
                    StreetPrefix={StreetPrefix}
                    StreetSuffix={StreetSuffix}
                    StateCode={StateCode}
                    Country={Country}
                    LocationCategory={LocationCategory}
                    businessValidation={businessValidation}
                  />
                </>
              );
            }
          )}
        <div className="edit-button_">
          <ButtonComponents
            name="Add Address"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
