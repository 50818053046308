import { Fetcher } from "../../../../../../services/Fetcher";
import { AdditionalData } from "../../MasterNameIState";
import { AdditionalTableIRes } from "./AdditionalIState";

const fetcher = new Fetcher();
export class AdditionalMainFunction {
  async getAdditional(
    setLoading: (data: boolean) => void,
    setfields: (data: AdditionalData) => void,
    fields: AdditionalData,
    nameId:string,
  ) {
    setLoading(true);

    let response = await fetcher.get(
      `/name-master/${nameId}/additional-information`
    );
    console.log(response);
    let resInvolvement: AdditionalTableIRes = response.Data;
    console.log(resInvolvement);
    setfields({
      Disability: resInvolvement.NameMasterAdditionInfo,
      FingerPrints: resInvolvement.FingerprintIndivator ? "Yes" :"No",
    });

    setLoading(false);
  }
}
