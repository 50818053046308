import { ColDef } from "ag-grid-community";
import { format } from "date-fns";
export const coloum = (): ColDef[] => {
  return [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      maxWidth: 90,
      sortable: false,
      cellStyle: { pointerEvents: "" },
    },
    { field: "Last Name" },
    { field: "First Name" },
    { field: "Middle Name" },
    { field: "Suffix" },
    { field: "Post No" },
    { field: "Home Unit" },
    { field: "Rank" },
    { field: "Status" },
    { field: "Badge" },
    {
      field: "Last Login",
      valueFormatter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "MM-dd-yyyy");
        } else {
          return "";
        }
      },
    },
  ];
};
