import delete_icon from "../../../../assets/icons/delete.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, SetTableData } from "./MasterNameIndexListingI";
export const OrderByFieldName: OrderByFieldNameI = {
  "First Name": "PersonGivenName",
  "Middle Name": "PersonMiddleName",
  "Last Name/Business Name": "PersonSurName",
  DOB: "DOB",
  Age: "Age",
  "Address": "StreetAddress",
  City: "City",
  State: "State",
};
export const coloum = (): ColDef[] => {
  return [
    { field: "Last Name/Business Name" },
    { field: "First Name" },
    { field: "Middle Name" },
    { field: "DOB" },
    { field: "Age" },
    { field: "Address" },
    { field: "City" },
    { field: "State" },
  
  ];
};
