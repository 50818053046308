import { ColDef } from "ag-grid-community";
import delete_icon from "../../../../assets/icons/delete.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import { OrderByFieldNameI, SetTableData } from "./CategoryInterface";


export const OrderByFieldName: OrderByFieldNameI = {
  Category: "CategoryName",
  Description: "Description",
  Status: "IsActive",
};

export const coloumn = (
  Edit: (data: string) => void,
  DeleteRow: (data: string) => void,
  Update:boolean,
  Delete:boolean,
): ColDef[] => {
  return [
    { field: "Category Name" },
    { field: "Description" },
    { field: "Status" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
            <img
            className="table-cellRenderer-img"
            title="Edit"
            src={edit_icon}
            onClick={() => Edit(e.data.CategoryCodeId)}
          />
            )}
{Delete && (
            <img
            className="table-cellRenderer-img"
            title="Delete"
            src={delete_icon}
            onClick={() => {
              DeleteRow(e.data.CategoryCodeId);
            }}
          />
)}

          </div>
        );
      },
    },
  ];
};
