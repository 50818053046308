import { Filter } from "../Components/Filter/Filter";
import "./AgencyManagement.css";
import { ButtonComponents } from "../../../CommonComponents/Fields/Button/ButtonComponents";
import add from "../../../../assets/icons/Icon.svg";
import { Table } from "../../../CommonComponents/Table/Table";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AgencyManagementFunction } from "./AgencyManagementFunction";
import { SetTableData } from "./AgencyManagementIState";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { coloum } from "./Config";
import useDelayedEffect from "../../../../hooks/useDelayedEffect";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import { useDispatch } from "react-redux";
import { seteditComponent } from "../../../../Redux/AgencyReducer";
import { DeleteConfirmation } from "../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { useGetPerticularUserPermission } from "../../../../hooks/Permission/useGetPerticularUserPermission";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";

export const AgencyManagement: React.FC = () => {
  const { Create, Delete, Update } =
    useGetPerticularUserPermission("Agency");
  const agencyManagementFunction = new AgencyManagementFunction();

  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [Agencyname, setAgencyname] = useState<string>("");
  const [AgencyType, setAgencyType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [deleteAgencyId, setDeleteAgencyId] = useState<string | null>(null);
  const [agencyTypeDataalue, setagencyTypeDataalue] = useState<string>("");
  const [location, setlocation] = useState<string>("");
  const [Creationdatefrom, setCreationdatefrom] = useState<string | null>("");
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Agency Name");
  const [Creationdateto, setCreationdateto] = useState<string | null>("");

  const dispatch = useDispatch();

  const navigation = useNavigate();

  const NavigateToEditPage = (id: string) => {
    dispatch(
      seteditComponent({
        editComponent: true,
        addComponent: false,
        viewComponent: false,
      })
    );
    navigation(`/agency/${id}/edit`);
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
    setDeleteAgencyId(null);
  };

  const GetAgencyManagementTableData = () => {
    agencyManagementFunction.GetAgencyManagementTableData(
      setTableData,
      Agencyname,
      AgencyType,
      pageNumber,
      pageCount,
      setLoading,
      setTableTotalRecord,
      agencyTypeDataalue,
      location,
      Creationdatefrom,
      Creationdateto,
      OrderByDescending,
      OrderBy
    );
  };

  const handleDelete = () => {
    if (deleteAgencyId !== null) {
      agencyManagementFunction.deleteAgency(
        deleteAgencyId,
        setLoading,
        GetAgencyManagementTableData
      );
      closeDeletePopup();
    }
  };

  const changePagegination = () => {
    GetAgencyManagementTableData();
  };
  const changeFilter = () => {
    setpageNumber(1);
    setpageCount(10);
    setOrderByDescending(false);
    GetAgencyManagementTableData();
  };

  useEffect(() => {
    changePagegination();
  }, [pageNumber, pageCount, OrderByDescending]);

  useDelayedEffect(changeFilter, 700, [
    Agencyname,
    agencyTypeDataalue,
    location,
    Creationdatefrom,
    Creationdateto,
  ]);

  const onRowClicked = (e: SetTableData) => {
    if (!deletePopup) {
      if (e?.ID) {
        dispatch(
          seteditComponent({
            editComponent: false,
            addComponent: false,
            viewComponent: true,
          })
        );
        navigation(`/agency/${e?.ID}/view`);
      }
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />

      <div className="AgencyManagement">
     
        <div className="AgencyManagement-Header">
          <p className="header">Agency Management</p>
        </div>

        <div>
          <Filter
            Agencyname={Agencyname}
            setAgencyname={setAgencyname}
            setAgencyType={setAgencyType}
            AgencyType={AgencyType}
            agencyTypeDataalue={agencyTypeDataalue}
            setagencyTypeDataalue={setagencyTypeDataalue}
            location={location}
            setlocation={setlocation}
            Creationdatefrom={Creationdatefrom}
            setCreationdatefrom={setCreationdatefrom}
            Creationdateto={Creationdateto}
            setCreationdateto={setCreationdateto}
          />

          <div className="table">
            <Table
              columns={coloum(
                NavigateToEditPage,
                setdeletePopup,
                setDeleteAgencyId,
                Delete,
                Update
              )}
              columnsValue={tableData}
              setpageCount={setpageCount}
              setpageNumber={setpageNumber}
              tableTotalRecord={tableTotalRecord}
              onRowClicked={onRowClicked}
              setOrderByDescending={setOrderByDescending}
              OrderByDescending={OrderByDescending}
              setOrderBy={setOrderBy}
              OrderBy={OrderBy}
              cursorPointer={true}
              
            />
          </div>
        </div>
      </div>
    </>
  );
};
