import { Fetcher } from "../../../../../../services/Fetcher";
import {
  convertToISOFormat,
  formatDate,
} from "../../../../../../utils/Helper/Helper";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../utils/const";
import {
  SetFormDataIState,
  codecategory,
  getallcodecategory,
  setvalidationIState,
} from "./CreateEditSystemLevelProps";

const fetcher = new Fetcher();

export class CreateEditSystemLevelFunction {
  async getallcodecategory(
    setcategoryData: (data: codecategory[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/category/system");
    setcategoryData(
      response.Data?.map((val: getallcodecategory) => {
        return {
          CodeCategoryId: val.CodeCategoryId,
          CategoryName: val.CategoryName,
        };
      })
    );

    if (setLoading) {
      setLoading(false);
    }
  }

  async addSystemLevel(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    setshowAddEditView: (data: boolean) => void,
    SaveandAddNewinSameCategorycheck?: boolean
  ) {
    setLoading(true);
    const payload = {
      CodeCategoryId: formData.Category, // Adjust this as needed
      ShortCode: formData.ShortCode,
      LongCode: formData.LongCode,
      Description: formData.Description,
      IsActive: formData.IsActive === "1",
      ActiveDate: convertToISOFormat(formData.ActiveDate),
      ExpiryDate: formData.ExpiryDate
        ? convertToISOFormat(formData.ExpiryDate)
        : null,
    };

    const response = await fetcher.post(`/code/system`, payload, {
      message: successCreatedMessage,
      show: true,
    });
    setLoading(false);
    setLoading(false);
    if (
      response?.Data?.SystemLevelCodeId &&
      !SaveandAddNewinSameCategorycheck
    ) {
      setshowAddEditView(false);
    }
  }

  async editSystemLevel(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    setshowAddEditView: (data: boolean) => void,
    getRowId: string
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/code/system`,
      {
        SystemLevelCodeId: getRowId,
        CodeCategoryId: formData.Category,
        ShortCode: formData.ShortCode,
        LongCode: formData.LongCode,
        Description: formData.Description,
        Status: formData.IsActive == "1" ? true : false,
        ActiveDate: convertToISOFormat(formData.ActiveDate),
        ExpiryDate: formData.ExpiryDate
          ? convertToISOFormat(formData.ExpiryDate)
          : null,
      },
      {
        message: successUpdateMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.SystemLevelCodeId) {
      setshowAddEditView(false);
    }
  }

  async getSystemLevelData(
    setLoading: (data: boolean) => void,
    setFormData: (data: SetFormDataIState) => void,
    systemLevelCodeID: string,
    addprefill?: boolean
  ) {
    setLoading(true);
    let responce = await fetcher.get(`/code/${systemLevelCodeID}/system`);
    let {
      CodeCategoryId,
      ShortCode,
      LongCode,
      Description,
      IsActive,
      ActiveDate,
      ExpiryDate,
    } = responce?.Data;
    setFormData({
      ActiveDate: formatDate(ActiveDate),
      ExpiryDate: formatDate(ExpiryDate),
      Category: CodeCategoryId,
      Description: addprefill ? "" : Description,
      IsActive: IsActive ? "1" : "2",
      LongCode: addprefill ? "" : LongCode,
      ShortCode: addprefill ? "" : ShortCode,
    });

    setLoading(false);
  }

  errorValidation(
    formData: SetFormDataIState,
    setvalidation: (data: setvalidationIState) => void
  ) {
    let valdationerror: setvalidationIState = {
      ActiveDate: false,
      Category: false,
      Description: false,
      ExpiryDate: false,
      IsActive: false,
      LongCode: false,
      ShortCode: false,
    };

    if (!formData.Category) {
      valdationerror.Category = true;
    } else {
      valdationerror.Category = false;
    }
    if (!formData.Description) {
      valdationerror.Description = true;
    } else {
      valdationerror.Description = false;
    }

    if (!formData.IsActive) {
      valdationerror.IsActive = true;
    } else {
      valdationerror.IsActive = false;
    }

    if (!formData.LongCode) {
      valdationerror.LongCode = true;
    } else {
      valdationerror.LongCode = false;
    }

    if (!formData.ShortCode) {
      valdationerror.ShortCode = true;
    } else {
      valdationerror.ShortCode = false;
    }

    setvalidation({ ...valdationerror });
  }

  checkRequiredFields(formData: SetFormDataIState) {
    return (
      formData.Category &&
      formData.Description &&
      formData.IsActive &&
      formData.LongCode &&
      formData.ShortCode
    );
  }
}
