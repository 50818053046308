import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MasterMainIParams } from "../../../../MasterOverview/MasterNameIState";
import { BusinessInvolvementData } from "./BusinessInvolvementIState";
import { BusinessInvolvementMainFunction } from "./BusinessInvolvementFunction";

import { coloum } from "./Config";
import { Table } from "../../../../../../CommonComponents/Table/Table";

export const BusinessInvolvement: React.FC = () => {
  const [tableData, setTableData] = useState<BusinessInvolvementData[]>([]);
  const businessInvolvementFunction = new BusinessInvolvementMainFunction();
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const { nameId } = useParams<MasterMainIParams>();
  const getInvolvement = () => {
    if (nameId) {
      businessInvolvementFunction.getBusinessInvolvementMain(
        setTableData,
        setTableTotalRecord,
        nameId
      );
    }
  };

  useEffect(() => {
    getInvolvement();
  }, []);
  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header_">
          <p>
            <strong>Involvements: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
            <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
