import React, { useEffect, useState } from "react";
import view from "../../../../../../assets/icons/Eyeicon.svg";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";

import "../../vehicle.css";
import add from "../../../../../../assets/icons/Icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { FilterIProps } from "./FilterIProps";
import { VehicleListFunction } from "../VehicleFunction";
import { InvolvementMainIParams } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useDispatch } from "react-redux";
import { setIncidentId } from "../../../../../../Redux/CommonRedux";
interface GetRaceDataIState {
  NciccodeId: string;
  ShortCode: string;
  LongCode: string;
  Description: string;
  DisplayOrder: number;
  SystemLevelCodeId: string;
}
export const Filter: React.FC<FilterIProps> = ({
  Name,
  setName,
  Type,
  setType,
  onSearch,
  clearAllFilter,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicleFunction = new VehicleListFunction();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [vehicleType, setVehicleType] = useState<GetRaceDataIState[]>([]);
  const clearAllFilters = () => {
    setName("");
    setType("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
    clearAllFilter();
  };

  useEffect(() => {
    if (Name.trim() !== "" || Type !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, Type]);

  const addNarrativeHandler = () => {
    navigate(`/incidents/${incidentId}/vehicles/add`);
  };
  const initialRender = () => {
    vehicleFunction.getVehicleType(setVehicleType);
  };
  useEffect(() => {
    initialRender();
  }, []);



  return (
    <>
      <div className="Filter">
        <div className="filds___" style={{ width: "50%" }}>
          <TextBoxComponent
            fieldName={"Search  Vehicles"}
            value={Name}
            onChangeValue={setName}
          />
          {/* <DropdownComponent
            fieldName="Type"
            dataSource={vehicleType.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
            handleRowClick={(newValue) => {
              setType(newValue);
            }}
            value={Type}
          /> */}

          {/* <DropdownComponent
  fieldName="Type"
  dataSource={[
    { id: "", value: "All" }, 
    ...vehicleType.map((val) => {
      return {
        id: val.NciccodeId,
        value: val.LongCode,
      };
    }),
  ]}
  handleRowClick={(newValue) => {
    setType(newValue === "All" ? "" : newValue); 
  }}
  value={Type || ""}
/> */}

<DropdownComponent
  fieldName="Type"
  dataSource={[
    { id: "", value: "All" }, 
    ...vehicleType.map((val) => ({
      id: val.NciccodeId,
      value: val.LongCode,
    })),
  ]}
  handleRowClick={(newValue) => {
    setType(newValue === "All" ? "" : newValue);
  }}
  value={Type || ""}
/>


          <div  style={{width:"80%"}}>
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              handleClick={() => {
                onSearch(true);
              }}
            />
          </div>
          <div className="clearbutton1">
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
          <ButtonComponents
            name="Add new vehicle"
            icon={add}
            textColor="white"
            showBackgroundColor={true}
            handleClick={addNarrativeHandler}
          />
        </div>
      </div>
    </>
  );
};
