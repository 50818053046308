

import { Fetcher } from "../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import { BloodData } from "../../MasterNameIState";
import { BloodTableIRes } from "./BloodIState";

const fetcher = new Fetcher();

export class BloodMainFunction {
  async getBloodMain(
    setTableData: (data: BloodData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    let response = await fetcher.get(
      `/name-master/${nameId}/blood-alcohol`
    );
    let res: BloodTableIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: BloodTableIRes[]) {
    return data?.map((val: BloodTableIRes, i: number) => {
      return {
        Case: val.IncidentNumber,
        Type: val.TestType,
        Result: val.TestResult,
        "Date Entered": formatDate(val.TestDateTime),
      };
    });
  }
}
