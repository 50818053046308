import { ColDef } from "ag-grid-community";
import delete_icon from "../../../../assets/icons/delete.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import { format } from "date-fns";
import { OrderByFieldNameI, SetTableData } from "./AgencyLevelI";

export const OrderByFieldName: OrderByFieldNameI = {
  "Date Active": "ActiveDate",
  "Date Inactive": "ExpiryDate",
  "Long Code": "LongCode",
  "Short Code": "ShortCode",
  Category: "CategoryName",
  Description: "Description",
  Status: "IsActive",
};

export const coloum = (
  Edit: (data: string) => void,
  DeleteRow: (data: string) => void,
  Delete:boolean,
  Update:boolean,
): ColDef[] => {
  return [
    { field: "Short Code" },
    { field: "Long Code" },
    { field: "Category" },
    { field: "Description" },
    {
      field: "Date Active",
      valueFormatter: (params) => format(new Date(params.value), "MM-dd-yyyy hh:mm:ss"),
    },
    {
      field: "Date Inactive",
      valueFormatter: (params) => format(new Date(params.value), "MM-dd-yyyy hh:mm:ss"),
    },
    { field: "Status" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
                <img
                className="table-cellRenderer-img"
                title="Edit"
                src={edit_icon}
                onClick={() => Edit(e.data.AgencyLevelCodeId)}
              />
            )}
          {Delete && (
            <img
            className="table-cellRenderer-img"
            title="Delete"
            src={delete_icon}
            onClick={() => {
              DeleteRow(e.data.AgencyLevelCodeId);
            }}
          />
          )}

          </div>
        );
      },
    },
  ];
};
