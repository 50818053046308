import { useEffect, useState } from "react";
import { coloum } from "./Config";
import { CategoryListingIProps, SetTableData } from "./CategoryListingI";
import { AgencyLevelFunction } from "../../../AgencyLevelFunction";
import "./CategoryListing.css";
import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../../CommonComponents/Table/Table";

export const CategoryListing: React.FC<CategoryListingIProps> = ({
  Category,
}) => {
  const agencyLevelFunction = new AgencyLevelFunction();
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Short Code");

  const getAgencyLevelTableData = () => {
    agencyLevelFunction.getAgencyLevelTableData(
      setTableData,
      setLoading,
      setTableTotalRecord,
      pageNumber,
      pageCount,
      Category,
      "1",
      undefined,
      OrderByDescending,
      OrderBy
    );
  };

  useEffect(() => {
    if (Category) {
      getAgencyLevelTableData();
    }
  }, [Category, pageNumber, pageCount, OrderByDescending]);

  return (
    <>
      <Loader loading={loading} />
      <div className="CategoryListing_table">
        <Table
          columns={coloum()}
          columnsValue={tableData}
          setpageCount={setpageCount}
          setpageNumber={setpageNumber}
          tableTotalRecord={tableTotalRecord}
          setOrderByDescending={setOrderByDescending}
          OrderByDescending={OrderByDescending}
          setOrderBy={setOrderBy}
          OrderBy={OrderBy}
           
              
          
        />
      </div>
    </>
  );
};
