
import { Fetcher } from "../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import { EmergencyData } from "../../MasterNameIState";
import { EmergencyTableIRes } from "./EmergencyIState";

const fetcher = new Fetcher();

export class EmergencyMainFunction {
  async getEmergencyMain(
    setTableData: (data: EmergencyData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    let response = await fetcher.get(
      `/name-master/${nameId}/emergency-contacts`
    );
    let res: EmergencyTableIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: EmergencyTableIRes[]) {
    return data?.map((val: EmergencyTableIRes, i: number) => {
      return {
        Case: val.IncidentNumber.toString(),
        Date: formatDate(val.CreatedOn),
        Relation: val.Relation,
        "Last Name": val.FirstName,
        "First Name": val.FirstName,
        "Phone Number": val.PhoneNumber,
      };
    });
  }
}
