import { useEffect, useState } from "react";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { Table } from "../../../../../CommonComponents/Table/Table";
import "./IPAddresses.css";
import { IPAddressesIFunction } from "./IPAddressesIFunction";
import { SetTableData, setgetIpAddressSingleData } from "./IPAddressesI";
import { EditAgencyManagemantIParams } from "../../../EditAgencyManagemant/EditAgencyManagemantI";
import { useParams } from "react-router-dom";

import add from "../../../../../../assets/icons/Icon.svg";
import { CreateEditIPAddress } from "./CreateIPAddress/CreateEditIPAddress";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { ModalComponent } from "../../../../../CommonComponents/Modal/ModalComponent";
import { columns } from "./Config";
import { DeleteConfirmation } from "../../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const IPAddresses: React.FC = () => {
  const { Create, Delete, Update } =
    useGetPerticularUserPermission("Agency");
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const iPAddressesIFunction = new IPAddressesIFunction();
  const { agencyId } = useParams<EditAgencyManagemantIParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddIPAddress, setShowAddIPAddress] = useState<boolean>(false);
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [editMode, seteditMode] = useState<boolean>(false);
  const [addMode, setaddMode] = useState<boolean>(false);
  const [viewMode, setviewMode] = useState<boolean>(false);
  const [WhitelistIPAddressId, setWhitelistIPAddressId] = useState<string>("");
  const [getIpAddressSingleData, setgetIpAddressSingleData] =
    useState<setgetIpAddressSingleData>({
      Description: "",
      IPAddress: "",
      Status: true,
      WhitelistIPAddressId: "",
    });
  const getIPAddressesTableData = () => {
    if (agencyId) {
      iPAddressesIFunction.getIPAddressesTableData(
        setTableData,
        setLoading,
        agencyId
      );
    }
  };
  const closeDeletePopup = () => {
    setdeletePopup(false);
  };
  const openDeletePopup = (id: string) => {
    setdeletePopup(true);
    setWhitelistIPAddressId(id);
  };

  const editIpAddress = (data: SetTableData) => {
    let IPAddress = data["IP Address"];
    let Description = data["Description"];
    let Status = data["Status"];
    let WhitelistIPAddressId = data["WhitelistIPAddressId"];
    setgetIpAddressSingleData({
      Description,
      IPAddress,
      Status: Status == "InActive" ? false : true,
      WhitelistIPAddressId,
    });
    setaddMode(false);
    seteditMode(true);
    setShowAddIPAddress(true);
    setviewMode(false);
  };

  const handleDelete = () => {
    if (agencyId) {
      iPAddressesIFunction
        .deleteIPAddress(WhitelistIPAddressId, setLoading, setdeletePopup)
        .then(() => {
          getIPAddressesTableData();
        });
    }
  };

  useEffect(() => {
    if (!showAddIPAddress) {
      getIPAddressesTableData();
    }
  }, [agencyId, showAddIPAddress]);

  const addIpAddress = () => {
    setgetIpAddressSingleData({
      Description: "",
      IPAddress: "",
      Status: true,
      WhitelistIPAddressId: "",
    });
    setaddMode(true);
    seteditMode(false);
    setviewMode(false);
    setShowAddIPAddress(true);
  };

  const onRowClicked = (data: SetTableData) => {
    if (!deletePopup) {
      let IPAddress = data["IP Address"];
      let Description = data.Description;
      let Status = data?.Status;
      let WhitelistIPAddressId = data?.WhitelistIPAddressId;
      setgetIpAddressSingleData({
        Description,
        IPAddress,
        Status: Status == "InActive" ? false : true,
        WhitelistIPAddressId,
      });
      setaddMode(false);
      seteditMode(false);
      setShowAddIPAddress(true);
      setviewMode(true);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
       onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      {showAddIPAddress ? (
        <CreateEditIPAddress
          addmode={addMode}
          editMode={editMode}
          getIpAddressSingleData={getIpAddressSingleData}
          setShowAddIPAddress={setShowAddIPAddress}
          viewMode={viewMode}
          setaddMode={setaddMode}
          seteditMode={seteditMode}
          setviewMode={setviewMode}
        />
      ) : (
        <div className="DetailTab_top">
          <div className="DetailTab_top_header_">
            <div className="DetailTab_top_header_sub">
              <p>IP Address</p>
              <div className="IPAddress">
                {agencyId && Create && (
                  <ButtonComponents
                    name="Add New IP Address"
                    icon={add}
                    handleClick={addIpAddress}
                    showBackgroundColor={true}
                    textColor="white"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="IPAddresses_table">
            <Table
              columns={columns(editIpAddress, openDeletePopup, Delete, Update)}
              columnsValue={tableData}
              showPagination={false}
              onRowClicked={onRowClicked}
              clientSideSorting={true}
              cursorPointer={true}
              
            />
          </div>
        </div>
      )}
    </>
  );
};
