import React, { useState } from "react";
import { coloum } from "./Config";
import { DetainmentData } from "../../MasterNameIState";
import { Table } from "../../../../../CommonComponents/Table/Table";

export const Detainment: React.FC = () => {
  const [tableData, setTableData] = useState<DetainmentData[]>([]);
  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Detainments: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
            <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
