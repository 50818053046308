import { useEffect, useState } from "react";
import { SetTableDataIstate } from "./UserPolicyTabI";
import { RolePolicyIParams } from "../RolePolicyTab/RolePolicyI";
import { UserPolicyFunction } from "./UserPolicyFunction";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { Submit } from "../../../../../../CommonComponents/Fields/Submit/Submit";
import { coloum } from "./Config";
import { Filter } from "./Components/Filter";
import useDelayedEffect from "../../../../../../../hooks/useDelayedEffect";

export const UserPolicyTab: React.FC = () => {
  const [tableData, setTableData] = useState<SetTableDataIstate[]>([]);
  const [FiltertableData, setFiltertableData] = useState<SetTableDataIstate[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { roleId } = useParams<RolePolicyIParams>();
  const userPolicyFunction = new UserPolicyFunction();
  const [Status, setStatus] = useState<string>("");
  const [Name, setName] = useState<string>("");
  const [changeValue, setChangeValue] = useState<boolean>(false);
  const [oneTimeRender, setoneTimeRender] = useState<boolean>(true);
  const [GetrolepoliciesDataLength, setGetrolepoliciesDataLength] =
    useState<number>(0);

  const selectRowCheckBox = (
    data: { [key: string]: string | number | boolean | null }[]
  ) => {
    userPolicyFunction.selectRowCheckBox(
      FiltertableData,
      data,
      setFiltertableData,
      oneTimeRender,
      GetrolepoliciesDataLength,
      setChangeValue,
      setoneTimeRender,
      tableData
    );
  };

  const onCancel = () => {
    initialData();
  };

  const GetUserManagementTableData = async () => {
    if (roleId) {
      await userPolicyFunction.GetUserManagementTableData(
        setTableData,
        1,
        1000,
        setLoading,
        roleId,
        setFiltertableData,
        setGetrolepoliciesDataLength
      );
    }
  };

  const onSave = () => {
    if (roleId) {
      let body: { UserId: string }[] = [];
      FiltertableData.map((val) => {
        if (val.isSelected) {
          body.push({ UserId: val.ID });
        }
      });
      userPolicyFunction.userPolicyAdd(setLoading, roleId, body);
    }
  };

  const applyFilterOnUserpolicyTable = () => {
    userPolicyFunction.applyFilterOnUserpolicyTable(
      Status,
      Name,
      FiltertableData,
      setTableData
    );
  };

  const initialData = async () => {
    await GetUserManagementTableData();
  };

  useEffect(() => {
    initialData();
  }, []);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [Name, Status]);

  return (
    <>
      <Loader loading={loading} />
      <Filter
        Name={Name}
        setName={setName}
        Status={Status}
        setStatus={setStatus}
      />
      <div className="table">
        <Table
          columns={coloum()}
          columnsValue={tableData}
          selectRowCheckBox={selectRowCheckBox}
          allowMultipleRowSelection={true}
          clientSideSorting={true}
          cursorPointer={false}
           
              
        />
        { changeValue && (
          <div className="edit-button">
            <Submit onCancel={onCancel} onSave={onSave} />
          </div>
        )}
      </div>
    </>
  );
};
