import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Case" },
    { field: "Date Entered" },
    { field: "Type" },
    { field: "Result" },
  ];
};
