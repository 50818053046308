import { MultipleAddressComponents } from "./MultipleAddress";
import { AddressIProps } from "../../InvolvementMainI";

export const Address: React.FC<AddressIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation
}) => {
  return (
    <>
      <div className="AddressSCroll">
        <p className="subheader">Address</p>
        <MultipleAddressComponents
          Mainfields={Mainfields}
          Participantionvalidation={Participantionvalidation}
          setMainfields={setMainfields}
        />
      </div>
    </>
  );
};
