import React, { useEffect, useState } from "react";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { StatutesData } from "../../MasterNameIState";


export const Statutes: React.FC = () => {
  const [tableData, setTableData] = useState<StatutesData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);


  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p><strong>Statutes: {tableData.length}</strong></p>
          <p className="DetailTab_top_">
 
 <strong>Total Records: {tableData.length}</strong>
</p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
         
           
           headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
