import { Fetcher } from "../../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../../utils/const";
import { openNotificationWithIcon } from "../../../../CommonComponents/Toster/Toster";
import { GetPolicyTableDataI, SetTableData } from "./ListRoleIState";

const fetcher = new Fetcher();
export class ListRoleFunction {
  async GetUserManagementTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setFiltertableData: (data: SetTableData[]) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/role`);
    const TableJson = this.createTableJson(response?.Data);
    setTableData(TableJson);
    setFiltertableData(TableJson);
    setLoading(false);
  }
  createTableJson(data: GetPolicyTableDataI[]) {
    return data?.map((val: GetPolicyTableDataI, i: number) => {
      return {
        "Role Name": val.RoleName,
        Description: val.Description,
        Status: val.IsActive ? "Active" : "InActive",
        ID: val.RoleId,
        ModifiedOn: val.ModifiedOn,
        ModifiedByUserName: val.ModifiedByUserName,
        IsSuperAdminOnly: val.IsSuperAdminOnly,
      };
    });
  }

  async CustomDelete(
    Id: string,
    setLoading: (data: boolean) => void,
    setdeletePopup: (data: boolean) => void,
    setDataValue: (data: {
      [key: string]: { [key: string]: string }[];
    }) => void,
    isDeleted: boolean
  ) {
    setLoading(true);

    try {
      const response = await fetcher.CustomDelete(
        `/role?roleId=${Id}&isDeleted=${isDeleted}`
      );

      if (response) {
        setLoading(false);
        setdeletePopup(false);
        openNotificationWithIcon("success", successDeleteMessage);
      } else {
        console.error("Delete request failed. Response:", response);
      }
    } catch (error: any) {
      console.error("Error occurred during delete request:", error);
      setLoading(false);
      setDataValue(error.response?.data.Data);
    }
  }

  // async CustomDelete(
  //    Id: string,
  //   setLoading: (data: boolean) => void,
  //   setDeletePopup: (data: boolean) => void,
  //   setDataValue:(data:{[key:string]:{[key:string]:string}[]})=>void,
  // ) {
  //   setLoading(true);

  //   try {
  //     const response = await fetcher.CustomDelete(
  //       `/role?roleId/${Id}`,
  //     );
  //     console.log('role',response)
  //         setLoading(false);
  //         setDeletePopup(false);
  //         setDataValue({});
  //   } catch (error:any) {
  //     // console.log(error)
  //     console.error("Error during delete:", error);
  //     setLoading(false);
  //   setDataValue(error);
  //   }
  // }
  applyFilterOnUserpolicyTable(
    StatusFilter: string,
    RoleNameFilter: string,
    FiltertableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void
  ) {
    let filteredData: SetTableData[] = FiltertableData;
    let status: string = StatusFilter == "1" ? "active" : "inactive";
    if (RoleNameFilter) {
      filteredData = FiltertableData.filter((item) => {
        return (
          item["Role Name"] &&
          item["Role Name"]
            .toLowerCase()
            .includes(RoleNameFilter.toLowerCase().trim())
        );
      });
    }
    if (StatusFilter) {
      filteredData = filteredData.filter((item) => {
        return item?.["Status"].toLowerCase() == status;
      });
    }
    setTableData(filteredData);
  }
}
