import { BiastypeIState, InvolvementMainIState } from "../../InvolvementMainI";

export class BiasesFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: BiastypeIState[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: BiastypeIState[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  onChangeValue = (key: string, value: string | number | boolean | null) => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        Biases: {
          ...this.Mainfields.InvolvementTab.Biases,
          [key]: value,
        },
      },
    });
  };

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.InvolvementTab.Biases.Biastype);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange: true,
        Biases: {
          ...this.Mainfields.InvolvementTab.Biases,
          Biastype: [
            ...this.Mainfields.InvolvementTab.Biases.Biastype.map((val) => {
              return {
                ...val,
                push: true,
              };
            }),
            {
              Biastype: "",
              id:
                this.Mainfields.InvolvementTab.Biases.Biastype[
                  this.Mainfields.InvolvementTab.Biases.Biastype.length - 1
                ].id + 1,
              BiastypeDropdownName: "",
              push: false,
              Audit: {
                Biastype: {
                  new: "",
                  old: "",
                },
              },
            },
          ],
        },
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.InvolvementTab.Biases.Biastype);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange: true,
        Biases: {
          ...this.Mainfields.InvolvementTab.Biases,
          Biastype: this.Mainfields.InvolvementTab.Biases.Biastype.filter(
            (val) => {
              if (val.id != id) {
                return val;
              }
            }
          ).length
            ? this.Mainfields.InvolvementTab.Biases.Biastype.filter((val) => {
                if (val.id != id) {
                  return val;
                }
              })
            : [
                {
                  Biastype: "",
                  id: 1,
                  BiastypeDropdownName: "",
                  Audit: {
                    Biastype: {
                      new: "",
                      old: "",
                    },
                  },
                },
              ],
        },
      },
    });

    this.setedit(false);
  };
}
