import React, { useEffect, useState } from "react";
import { coloum } from "./Config";
import { AlertsTableData } from "../ParticipantOverviewIState";
import { Table } from "../../../../../../../CommonComponents/Table/Table";
import { useParams } from "react-router-dom";
import { AlertsFunction } from "./AlertsFunction";
import { InvolvementMainIParams } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

export const Alerts: React.FC = () => {
  const alertsFunction = new AlertsFunction();
  const [tableData, setTableData] = useState<AlertsTableData[]>([]);
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  const getAlerts = () => {
    if (participantId && incidentId) {
      alertsFunction.getAlerts(
        participantId,
        incidentId,
        setTableData,
        setTableTotalRecord
      );
    }
  };

  useEffect(() => {
    getAlerts();
  }, []);
  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div
        className="overview-container"
        style={{ height: "94vh", border: "1px solid #ffd9d9" }}
      >
        <div className="overview-alert">
          <p>
            <strong>Alerts</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          headerBackgroundColor="white"
          
          
        />
      </div>
    </div>
  );
};
