import React from "react";
import { Vehicles } from "../Components/Vehicles/Vehicles";
import { Contacts } from "../Components/Contact/Contact";
import { Alerts } from "../Components/Alerts/Alerts";
import { Address } from "../Components/Address/Address";
import { Involvement } from "../Components/Involvement/Involvement";
import "./BusinessOverview.css";
import { BusinessOverviewTab } from "../Components/BusinessOverview/BusinessOverviewTab";

export const BusinessOverview: React.FC = () => {
  return (
    <>
      <div className="ParticipantOverview">
        <div className="container">
          <div className="column">
            <BusinessOverviewTab />
            <Address />
            <Contacts />
          </div>
          <div className="half-width">
            <Alerts />
            <Vehicles />
          </div>
        </div>
        <Involvement />
      </div>
    </>
  );
};
