import { useEffect, useState } from "react";
import { Citizenship } from "./Components/Citizenship/Citizenship";
import { Language } from "./Components/Language/Language";
import { AdditionalMainFunction } from "./AdditionalFunction";
import { Disability } from "./Components/Disability/Disability";
import {
  AdditionalInfoIProps,
  GetBloodTestTypeDataI,
  NCICDropdownI,
} from "./AdditionalIState";
import { CheckBoxComponent } from "../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { Employeement } from "./Components/Employeement/Employeement";
import { MaritalStatus } from "./Components/MaritalStatus/MaritalStatus";
import { Deceased } from "./Components/Deceased/Deceased";
import { FingerPrints } from "./Components/FingerPrints/FingerPrints";
import { School } from "./Components/School/School";
import { MultiDropdownComponent } from "../../../../../../CommonComponents/Fields/MultiDropdown/MultiDropdownComponent";

export const AdditionalInfo: React.FC<AdditionalInfoIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [BloodTestType, setBloodTestType] = useState<GetBloodTestTypeDataI[]>(
    []
  );
  const [MaritalStatusTypeData, setMaritalStatusTypeData] = useState<
    NCICDropdownI[]
  >([]);
  const [GangAffiliation, setGangAffiliation] = useState<NCICDropdownI[]>([]);

  const onChangeValue = (
    name: string,
    value: string | number | boolean | null | string[]
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes: true,
        [name]: value,
      },
    });
  };

  const initialRender = () => {
    additionalMainFunction.getBloodTestTypeData(setBloodTestType);
    additionalMainFunction.MaritalStatusTypeData(setMaritalStatusTypeData);
    additionalMainFunction.getGangAffiliation(setGangAffiliation);
  };

  useEffect(() => {
    initialRender();
  }, []);
  return (
    <>
      <div className="additional-column">
        <div className="additional-row__">
          <Language Mainfields={Mainfields} setMainfields={setMainfields} />

          <Citizenship Mainfields={Mainfields} setMainfields={setMainfields} />

          <MaritalStatus
            MaritalStatusTypeData={MaritalStatusTypeData}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
          />
        </div>
        <div className="additional-row__">
          <div className="width_field">
            <Disability Mainfields={Mainfields} setMainfields={setMainfields} />
          </div>
          <div className="width_field">
            <p className="subheader">Medical</p>

            <div className="width_field">
              <DropdownComponent
                fieldName="Blood Type"
                value={Mainfields.AdditionalInfo.BloodType}
                dataSource={BloodTestType.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                handleRowClick={(e) => {
                  onChangeValue("BloodType", e);
                }}
              />
              <CheckBoxComponent
                fieldName="Gun Shot Injury"
                value={Mainfields.AdditionalInfo.GunShotInjury}
                onChangeValue={(e) => {
                  onChangeValue("GunShotInjury", e);
                }}
              />
            </div>
          </div>
          <Deceased Mainfields={Mainfields} setMainfields={setMainfields} />
        </div>
        <div className="additional-row__">
          <FingerPrints Mainfields={Mainfields} setMainfields={setMainfields} />

          <div className="width_field">
            <p className="subheader">Affiliations</p>
            <div className="width_field">
              <MultiDropdownComponent
                value={
                  Mainfields.AdditionalInfo.GangAffiliation.GangAffiliation
                }
                handleRowClick={(e) => {
                  setMainfields({
                    ...Mainfields,
                    AdditionalInfo: {
                      ...Mainfields.AdditionalInfo,
                      GangAffiliation: {
                        ...Mainfields.AdditionalInfo.GangAffiliation,
                        GangAffiliation: e,
                      },
                    },
                  });
                }}
                fieldName="Gang Affiliation"
                dataSource={GangAffiliation.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
              />
            </div>
          </div>
          <School Mainfields={Mainfields} setMainfields={setMainfields} />
        </div>
      </div>

      <Employeement
        Mainfields={Mainfields}
        setMainfields={setMainfields}
        Participantionvalidation={Participantionvalidation}
      />
    </>
  );
};
