import { Fetcher } from "../../../../../../services/Fetcher";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../utils/const";
import {
  convertToISOFormat,
  convertToISOFormatMonth,
  formatDate,
  formatMonth,
} from "../../../../../../utils/Helper/Helper";
import { setRankDataIState } from "../../../../UserManagement/Components/Tabs/SummaryTab/SummaryTabInterface";
import { GetRaceDataIState } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { setFormDataIState, VehicleDataIRes } from "./VehicalI";

const fetcher = new Fetcher();

export class AddVehicleFunction {
  // async addVehicle(
  //   setLoading: (data: boolean) => void,
  //   formData: setFormDataIState,
  //   incidentId: string,
  //   redirectTOEDitMOde: (data: string) => void
  // ) {
  //   setLoading(true);

  //   let res = await fetcher.post(
  //     `/incident/${incidentId}/vehicle`,

  //     {
  //       VehicleInvolvement: {
  //         VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //         IncidentId: incidentId,
  //         NcictypeId: formData.Type ? formData.Type : null,
  //         NcicmakeId: formData.Make ? formData.Make : null,
  //         NcicmodelId: formData.Model ? formData.Model : null,
  //         TabYear: formData.TabYear ? formData.TabYear : null,
  //         // License: formData.PlateNumber,
  //         NciccolorPrimaryId: formData.PrimaryColor
  //           ? formData.PrimaryColor
  //           : null,
  //         NciccolorSecondaryId: formData.SecondaryColor ? formData.SecondaryColor : null,
  //         Vin: formData.VIN,
  //         InsuranceAgencyOther: formData?.InsuranceAgencyOther ? formData?.InsuranceAgencyOther : null,
  //         InsuranceAgencyCodeId: formData.InsuranceAgencyCodeId ? formData.InsuranceAgencyCodeId : null,
  //         GrossWeight: Number(formData.GrossWeight),
  //         PlateNumber: formData.PlateNumber,
  //         OutOfCityRecovery: true,
  //         OutOfCityRecoveryCityState: "string",
  //         CommercialVehicleIndicator: formData.CommercialVehicle,
  //         HazardousMaterialsIndicator: true,
  //         HazardousMaterial: formData.HazardousMaterial ? formData.HazardousMaterial : null,
  //         VehicleOverWeightMeasure: formData.OverWeightMeasures,
  //         StateId: formData.State ? formData.State : null,
  //       },
  //       VehicleRegistration: {
  //         VehicleRegistrationId: formData.VehicleRegistrationId
  //           ? formData.VehicleRegistrationId
  //           : null,
  //         VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //         Ncictype: formData.Type ? formData.Type : null,
  //         Year: formData.Year ? formData.Year : null,
  //         ValidDates: formData.LicensePlateExpiration
  //           ? convertToISOFormatMonth(formData.LicensePlateExpiration)
  //           : null,
  //         RecovkedPlates: true,
  //       },
  //       RegisteredOwners:
  //         formData.isIndividual == 1
  //           ? formData.Individual.map((val) => {
  //               return {
  //                 RegisteredOwnerInformation: {
  //                   RegisteredOwnerInformationId:
  //                     val.RegisteredOwnerInformationId
  //                       ? val.RegisteredOwnerInformationId
  //                       : null,
  //                   VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //                   IsLien: formData.Lien,
  //                   LienHolder: formData.LienHolder ? formData.LienHolder: null,
  //                   LastName: val.LastName ? val.LastName : null,
  //                   FirstName: val.FirstName ? val.FirstName : null,
  //                   MiddleName: val.MiddleName ? val.MiddleName : null,
  //                   BusinessName: "string",
  //                   Suffix: val.Suffix ? val?.Suffix : null ,
  //                   DOB: val.DOB ? convertToISOFormat(val.DOB) : null,
  //                   IsPrimary: true,
  //                   IsIndividual: formData.isIndividual == 1 ? true : false,
  //                 },
  //                 Address: {
  //                   LocationId: val?.address?.LocationId
  //                     ? val?.address?.LocationId
  //                     : null,
  //                   IncidentId: incidentId ? incidentId : null,
  //                   AddressType: val?.address?.AddressType
  //                     ? val?.address?.AddressType
  //                     : null,
  //                   LocationCategoryCode: val?.address?.LocationCategoryCode
  //                     ? val?.address?.LocationCategoryCode
  //                     : null,
  //                   HouseNumber: val?.address?.Apartment
  //                     ? val?.address?.Apartment
  //                     : null,
  //                   Ncicstreet1PrefixId: val?.address?.StreetPrefix
  //                     ? val?.address?.StreetPrefix
  //                     : null,
  //                   Street1Name: val?.address?.StreetNumber
  //                     ? val?.address?.StreetNumber
  //                     : null,

  //                   Ncicstreet1SuffixId: val?.address?.StreetSuffix
  //                     ? val?.address?.StreetSuffix
  //                     : null,
  //                   Street2Name: val?.address?.Street
  //                     ? val?.address?.Street
  //                     : null,
  //                   City: val?.address?.City ? val?.address?.City : null,
  //                   NcicstateId: val?.address?.StateProvidence
  //                     ? val?.address?.StateProvidence
  //                     : null,
  //                   ZipCode: val?.address?.ZipCode
  //                     ? val?.address?.ZipCode
  //                     : null,
  //                   Grid: val?.address?.GridNumber
  //                     ? val?.address?.GridNumber
  //                     : null,
  //                   NciccountryId: val?.address?.Country
  //                     ? val?.address?.Country
  //                     : null,
  //                   Address1: val?.address?.AddressLine1
  //                     ? val?.address?.AddressLine1
  //                     : null,
  //                   Address2: val?.address?.AddressLine2
  //                     ? val?.address?.AddressLine2
  //                     : null,
  //                   OtherCity: val?.address?.ForeignAddressCity
  //                     ? val?.address?.ForeignAddressCity
  //                     : null,
  //                   OtherState: val?.address?.ForeignAddressStateProvinceRegion
  //                     ? val?.address?.ForeignAddressStateProvinceRegion
  //                     : null,
  //                   OtherZip: val?.address?.ForeignAddressZip
  //                     ? val?.address?.ForeignAddressZip
  //                     : null,
  //                   OtherCountry: val?.address?.ForeignAddressCountry
  //                     ? val?.address?.ForeignAddressCountry
  //                     : null,
  //                   IsForeignAddress: val?.address?.ForeignAddress,
  //                 },
  //               };
  //             })
  //           : [
  //               {
  //                 RegisteredOwnerInformation: {
  //                   RegisteredOwnerInformationId: formData.Business
  //                     .RegisteredOwnerInformationId
  //                     ? formData.Business.RegisteredOwnerInformationId
  //                     : null,
  //                   VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //                   IsLien: formData.Lien,
  //                   LienHolder: formData.LienHolder,
  //                   LastName: null,
  //                   FirstName:null,
  //                   MiddleName: null,
  //                   BusinessName: formData.Business.BusinessName,
  //                   Suffix: null,
  //                   DOB: null,
  //                   IsPrimary: true,
  //                   IsIndividual: formData.isIndividual == 1 ? true : false,
  //                 },
  //                 Address: {
  //                   LocationId: formData.Business.address.LocationId
  //                     ? formData.Business.address.LocationId
  //                     : null,
  //                   IncidentId: incidentId ? incidentId : null,
  //                   AddressType: formData.Business.address.AddressType
  //                     ? formData.Business.address.AddressType
  //                     : null,
  //                   LocationCategoryCode: formData.Business.address
  //                     .LocationCategoryCode
  //                     ? formData.Business.address.LocationCategoryCode
  //                     : null,
  //                   HouseNumber: formData.Business.address.Apartment
  //                     ? formData.Business.address.Apartment
  //                     : null,
  //                   Ncicstreet1PrefixId: formData.Business.address.StreetPrefix
  //                     ? formData.Business.address.StreetPrefix
  //                     : null,
  //                   Street1Name: formData.Business.address.StreetNumber
  //                     ? formData.Business.address.StreetNumber
  //                     : null,

  //                   Ncicstreet1SuffixId: formData.Business.address.StreetSuffix
  //                     ? formData.Business.address.StreetSuffix
  //                     : null,
  //                   Street2Name: formData.Business.address.Street
  //                     ? formData.Business.address.Street
  //                     : null,
  //                   City: formData.Business.address.City
  //                     ? formData.Business.address.City
  //                     : null,
  //                   NcicstateId: formData.Business.address.StateProvidence
  //                     ? formData.Business.address.StateProvidence
  //                     : null,
  //                   ZipCode: formData.Business.address.ZipCode
  //                     ? formData.Business.address.ZipCode
  //                     : null,
  //                   Grid: formData.Business.address.GridNumber
  //                     ? formData.Business.address.GridNumber
  //                     : null,
  //                   NciccountryId: formData.Business.address.Country
  //                     ? formData.Business.address.Country
  //                     : null,
  //                   Address1: formData.Business.address.AddressLine1
  //                     ? formData.Business.address.AddressLine1
  //                     : null,
  //                   Address2: formData.Business.address.AddressLine2
  //                     ? formData.Business.address.AddressLine2
  //                     : null,
  //                   OtherCity: formData.Business.address.ForeignAddressCity
  //                     ? formData.Business.address.ForeignAddressCity
  //                     : null,
  //                   OtherState: formData.Business.address
  //                     .ForeignAddressStateProvinceRegion
  //                     ? formData.Business.address
  //                         .ForeignAddressStateProvinceRegion
  //                     : null,
  //                   OtherZip: formData.Business.address.ForeignAddressZip
  //                     ? formData.Business.address.ForeignAddressZip
  //                     : null,
  //                   OtherCountry: formData.Business.address
  //                     .ForeignAddressCountry
  //                     ? formData.Business.address.ForeignAddressCountry
  //                     : null,
  //                   IsForeignAddress: formData.Business.address.ForeignAddress,
  //                 },
  //               },
  //             ],
  //     },
  //     {
  //       message: successCreatedMessage,
  //       show: true,
  //     }
  //   );
  //   setLoading(false);

  //   redirectTOEDitMOde(res.Data.VehicleId);
  // }

  //   async addVehicle(
  //   setLoading: (data: boolean) => void,
  //   formData: setFormDataIState,
  //   incidentId: string,
  //   redirectTOEDitMOde: (data: string) => void
  // ) {
  //   setLoading(true);

  //   let res = await fetcher.post(
  //     `/incident/${incidentId}/vehicle`,
  //     {
  //       VehicleInvolvement: {
  //         VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //         IncidentId: incidentId,
  //         NcictypeId: formData.Type ? formData.Type : null,
  //         NcicmakeId: formData.Make ? formData.Make : null,
  //         NcicmodelId: formData.Model ? formData.Model : null,
  //         TabYear: formData.TabYear ? formData.TabYear : null,
  //         NciccolorPrimaryId: formData.PrimaryColor ? formData.PrimaryColor : null,
  //         NciccolorSecondaryId: formData.SecondaryColor ? formData.SecondaryColor : null,
  //         Vin: formData.VIN,
  //         InsuranceAgencyOther: formData?.InsuranceAgencyOther ? formData?.InsuranceAgencyOther : null,
  //         InsuranceAgencyCodeId: formData.InsuranceAgencyCodeId ? formData.InsuranceAgencyCodeId : null,
  //         GrossWeight: Number(formData.GrossWeight),
  //         PlateNumber: formData.PlateNumber,
  //         OutOfCityRecovery: true,
  //         OutOfCityRecoveryCityState: "string",
  //         CommercialVehicleIndicator: formData.CommercialVehicle,
  //         HazardousMaterialsIndicator: true,
  //         HazardousMaterial: formData.HazardousMaterial ? formData.HazardousMaterial : null,
  //         VehicleOverWeightMeasure: formData.OverWeightMeasures,
  //         StateId: formData.State ? formData.State : null,
  //       },
  //       VehicleRegistration: {
  //         VehicleRegistrationId: formData.VehicleRegistrationId
  //           ? formData.VehicleRegistrationId
  //           : null,
  //         VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //         Ncictype: formData.Type ? formData.Type : null,
  //         Year: formData.Year ? formData.Year : null,
  //         ValidDates: formData.LicensePlateExpiration
  //           ? convertToISOFormatMonth(formData.LicensePlateExpiration)
  //           : null,
  //         RecovkedPlates: true,
  //       },
  //       RegisteredOwners:
  //         formData.isIndividual == 1
  //           ? formData.Individual.map((val, index) => {
  //               return {
  //                 RegisteredOwnerInformation: {
  //                   RegisteredOwnerInformationId:
  //                     val.RegisteredOwnerInformationId
  //                       ? val.RegisteredOwnerInformationId
  //                       : null,
  //                   VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //                   IsLien: formData.Lien,
  //                   LienHolder: formData.LienHolder ? formData.LienHolder : null,
  //                   LastName: val.LastName ? val.LastName : null,
  //                   FirstName: val.FirstName ? val.FirstName : null,
  //                   MiddleName: val.MiddleName ? val.MiddleName : null,
  //                   BusinessName: "string",
  //                   Suffix: val.Suffix ? val?.Suffix : null,
  //                   DOB: val.DOB ? convertToISOFormat(val.DOB) : null,
  //                   IsPrimary: index === 0,  // First owner gets IsPrimary set to true
  //                   IsIndividual: formData.isIndividual == 1 ? true : false,
  //                 },
  //                 Address: {
  //                   LocationId: val?.address?.LocationId
  //                     ? val?.address?.LocationId
  //                     : null,
  //                   IncidentId: incidentId ? incidentId : null,
  //                   AddressType: val?.address?.AddressType
  //                     ? val?.address?.AddressType
  //                     : null,
  //                   LocationCategoryCode: val?.address?.LocationCategoryCode
  //                     ? val?.address?.LocationCategoryCode
  //                     : null,
  //                   HouseNumber: val?.address?.Apartment
  //                     ? val?.address?.Apartment
  //                     : null,
  //                   Ncicstreet1PrefixId: val?.address?.StreetPrefix
  //                     ? val?.address?.StreetPrefix
  //                     : null,
  //                   Street1Name: val?.address?.StreetNumber
  //                     ? val?.address?.StreetNumber
  //                     : null,
  //                   Ncicstreet1SuffixId: val?.address?.StreetSuffix
  //                     ? val?.address?.StreetSuffix
  //                     : null,
  //                   Street2Name: val?.address?.Street
  //                     ? val?.address?.Street
  //                     : null,
  //                   City: val?.address?.City ? val?.address?.City : null,
  //                   NcicstateId: val?.address?.StateProvidence
  //                     ? val?.address?.StateProvidence
  //                     : null,
  //                   ZipCode: val?.address?.ZipCode ? val?.address?.ZipCode : null,
  //                   Grid: val?.address?.GridNumber ? val?.address?.GridNumber : null,
  //                   NciccountryId: val?.address?.Country ? val?.address?.Country : null,
  //                   Address1: val?.address?.AddressLine1
  //                     ? val?.address?.AddressLine1
  //                     : null,
  //                   Address2: val?.address?.AddressLine2
  //                     ? val?.address?.AddressLine2
  //                     : null,
  //                   OtherCity: val?.address?.ForeignAddressCity
  //                     ? val?.address?.ForeignAddressCity
  //                     : null,
  //                   OtherState: val?.address?.ForeignAddressStateProvinceRegion
  //                     ? val?.address?.ForeignAddressStateProvinceRegion
  //                     : null,
  //                   OtherZip: val?.address?.ForeignAddressZip
  //                     ? val?.address?.ForeignAddressZip
  //                     : null,
  //                   OtherCountry: val?.address?.ForeignAddressCountry
  //                     ? val?.address?.ForeignAddressCountry
  //                     : null,
  //                   IsForeignAddress: val?.address?.ForeignAddress,
  //                 },
  //               };
  //             })
  //           : [
  //               {
  //                 RegisteredOwnerInformation: {
  //                   RegisteredOwnerInformationId: formData.Business
  //                     .RegisteredOwnerInformationId
  //                     ? formData.Business.RegisteredOwnerInformationId
  //                     : null,
  //                   VehicleId: formData.VehicleId ? formData.VehicleId : null,
  //                   IsLien: formData.Lien,
  //                   LienHolder: formData.LienHolder ? formData.LienHolder : null,
  //                   LastName: null,
  //                   FirstName: null,
  //                   MiddleName: null,
  //                   BusinessName: formData.Business.BusinessName,
  //                   Suffix: null,
  //                   DOB: null,
  //                   IsPrimary: true,
  //                   IsIndividual: formData.isIndividual == 1 ? true : false,
  //                 },
  //                 Address: {
  //                   LocationId: formData.Business.address.LocationId
  //                     ? formData.Business.address.LocationId
  //                     : null,
  //                   IncidentId: incidentId ? incidentId : null,
  //                   AddressType: formData.Business.address.AddressType
  //                     ? formData.Business.address.AddressType
  //                     : null,
  //                   LocationCategoryCode: formData.Business.address
  //                     .LocationCategoryCode
  //                     ? formData.Business.address.LocationCategoryCode
  //                     : null,
  //                   HouseNumber: formData.Business.address.Apartment
  //                     ? formData.Business.address.Apartment
  //                     : null,
  //                   Ncicstreet1PrefixId: formData.Business.address.StreetPrefix
  //                     ? formData.Business.address.StreetPrefix
  //                     : null,
  //                   Street1Name: formData.Business.address.StreetNumber
  //                     ? formData.Business.address.StreetNumber
  //                     : null,
  //                   Ncicstreet1SuffixId: formData.Business.address.StreetSuffix
  //                     ? formData.Business.address.StreetSuffix
  //                     : null,
  //                   Street2Name: formData.Business.address.Street
  //                     ? formData.Business.address.Street
  //                     : null,
  //                   City: formData.Business.address.City
  //                     ? formData.Business.address.City
  //                     : null,
  //                   NcicstateId: formData.Business.address.StateProvidence
  //                     ? formData.Business.address.StateProvidence
  //                     : null,
  //                   ZipCode: formData.Business.address.ZipCode
  //                     ? formData.Business.address.ZipCode
  //                     : null,
  //                   Grid: formData.Business.address.GridNumber
  //                     ? formData.Business.address.GridNumber
  //                     : null,
  //                   NciccountryId: formData.Business.address.Country
  //                     ? formData.Business.address.Country
  //                     : null,
  //                   Address1: formData.Business.address.AddressLine1
  //                     ? formData.Business.address.AddressLine1
  //                     : null,
  //                   Address2: formData.Business.address.AddressLine2
  //                     ? formData.Business.address.AddressLine2
  //                     : null,
  //                   OtherCity: formData.Business.address.ForeignAddressCity
  //                     ? formData.Business.address.ForeignAddressCity
  //                     : null,
  //                   OtherState: formData.Business.address
  //                     .ForeignAddressStateProvinceRegion
  //                     ? formData.Business.address
  //                         .ForeignAddressStateProvinceRegion
  //                     : null,
  //                   OtherZip: formData.Business.address.ForeignAddressZip
  //                     ? formData.Business.address.ForeignAddressZip
  //                     : null,
  //                   OtherCountry: formData.Business.address.ForeignAddressCountry
  //                     ? formData.Business.address.ForeignAddressCountry
  //                     : null,
  //                   IsForeignAddress: formData.Business.address.ForeignAddress,
  //                 },
  //               },
  //             ],
  //     },
  //     {
  //       message: successCreatedMessage,
  //       show: true,
  //     }
  //   );
  //   setLoading(false);

  //   redirectTOEDitMOde(res.Data.VehicleId);
  // }

  async addVehicle(
    setLoading: (data: boolean) => void,
    formData: setFormDataIState,
    incidentId: string,
    redirectTOEDitMode: (data: string) => void
  ) {
    setLoading(true);

    let res = await fetcher.post(
      `/incident/${incidentId}/vehicle`,
      {
        VehicleInvolvement: {
          VehicleId: formData.VehicleId || null,
          IncidentId: incidentId,
          NcictypeId: formData.Type || null,
          NcicmakeId: formData.Make || null,
          NcicmodelId: formData.Model || null,
          TabYear: formData.TabYear || null,
          NciccolorPrimaryId: formData.PrimaryColor || null,
          NciccolorSecondaryId: formData.SecondaryColor || null,
          Vin: formData.VIN,
          InsuranceAgencyOther: formData?.InsuranceAgencyOther || null,
          InsuranceAgencyCodeId: formData.InsuranceAgencyCodeId || null,
          GrossWeight: Number(formData.GrossWeight),
          PlateNumber: formData.PlateNumber,
          OutOfCityRecovery: true,
          OutOfCityRecoveryCityState: "string",
          CommercialVehicleIndicator: formData.CommercialVehicle,
          HazardousMaterialsIndicator: true,
          HazardousMaterial: formData.HazardousMaterial || null,
          VehicleOverWeightMeasure: formData.OverWeightMeasures,
          StateId: formData.State || null,
        },
        VehicleRegistration: {
          VehicleRegistrationId: formData.VehicleRegistrationId || null,
          VehicleId: formData.VehicleId || null,
          Ncictype: formData.Type || null,
          Year: formData.Year || null,
          ValidDates: formData.LicensePlateExpiration
            ? convertToISOFormatMonth(formData.LicensePlateExpiration)
            : null,
          RecovkedPlates: true,
        },
        RegisteredOwners:
          formData.isIndividual === 1
            ? formData.Individual.map((val, index) => {
                return {
                  RegisteredOwnerInformation: {
                    RegisteredOwnerInformationId:
                      val.RegisteredOwnerInformationId || null,
                    VehicleId: formData.VehicleId || null,
                    IsLien: formData.Lien,
                    LienHolder: formData.LienHolder || null,
                    LastName: val.LastName || null,
                    FirstName: val.FirstName || null,
                    MiddleName: val.MiddleName || null,
                    BusinessName: "string",
                    Suffix: val.Suffix || null,
                    DOB: val.DOB ? convertToISOFormat(val.DOB) : null,
                    IsPrimary: index === 0, // Ensure first owner is primary
                    IsIndividual: formData.isIndividual === 1,
                  },
                  Address: {
                    LocationId: val?.address?.LocationId || null,
                    IncidentId: incidentId || null,
                    AddressType: val?.address?.AddressType || null,
                    LocationCategoryCode:
                      val?.address?.LocationCategoryCode || null,
                    HouseNumber: val?.address?.Apartment || null,
                    Ncicstreet1PrefixId: val?.address?.StreetPrefix || null,
                    Street1Name: val?.address?.StreetNumber || null,
                    Ncicstreet1SuffixId: val?.address?.StreetSuffix || null,
                    Street2Name: val?.address?.Street || null,
                    City: val?.address?.City || null,
                    NcicstateId: val?.address?.StateProvidence || null,
                    ZipCode: val?.address?.ZipCode || null,
                    Grid: val?.address?.GridNumber || null,
                    NciccountryId: val?.address?.Country || null,
                    Address1: val?.address?.AddressLine1 || null,
                    Address2: val?.address?.AddressLine2 || null,
                    OtherCity: val?.address?.ForeignAddressCity || null,
                    OtherState:
                      val?.address?.ForeignAddressStateProvinceRegion || null,
                    OtherZip: val?.address?.ForeignAddressZip || null,
                    OtherCountry: val?.address?.ForeignAddressCountry || null,
                    IsForeignAddress: val?.address?.ForeignAddress,
                  },
                };
              })
            : [
                {
                  RegisteredOwnerInformation: {
                    RegisteredOwnerInformationId:
                      formData.Business.RegisteredOwnerInformationId || null,
                    VehicleId: formData.VehicleId || null,
                    IsLien: formData.Lien,
                    LienHolder: formData.LienHolder || null,
                    LastName: null,
                    FirstName: null,
                    MiddleName: null,
                    BusinessName: formData.Business.BusinessName,
                    Suffix: null,
                    DOB: null,
                    IsPrimary: true, // Business owner is primary
                    IsIndividual: formData.isIndividual === 1,
                  },
                  Address: {
                    LocationId: formData.Business.address.LocationId || null,
                    IncidentId: incidentId || null,
                    AddressType: formData.Business.address.AddressType || null,
                    LocationCategoryCode:
                      formData.Business.address.LocationCategoryCode || null,
                    HouseNumber: formData.Business.address.Apartment || null,
                    Ncicstreet1PrefixId:
                      formData.Business.address.StreetPrefix || null,
                    Street1Name: formData.Business.address.StreetNumber || null,
                    Ncicstreet1SuffixId:
                      formData.Business.address.StreetSuffix || null,
                    Street2Name: formData.Business.address.Street || null,
                    City: formData.Business.address.City || null,
                    NcicstateId:
                      formData.Business.address.StateProvidence || null,
                    ZipCode: formData.Business.address.ZipCode || null,
                    Grid: formData.Business.address.GridNumber || null,
                    NciccountryId: formData.Business.address.Country || null,
                    Address1: formData.Business.address.AddressLine1 || null,
                    Address2: formData.Business.address.AddressLine2 || null,
                    OtherCity:
                      formData.Business.address.ForeignAddressCity || null,
                    OtherState:
                      formData.Business.address
                        .ForeignAddressStateProvinceRegion || null,
                    OtherZip:
                      formData.Business.address.ForeignAddressZip || null,
                    OtherCountry:
                      formData.Business.address.ForeignAddressCountry || null,
                    IsForeignAddress: formData.Business.address.ForeignAddress,
                  },
                },
              ],
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);

    redirectTOEDitMode(res.Data.VehicleId);
  }

  async getVehicle(
    setLoading: (data: boolean) => void,
    setFormData: (data: setFormDataIState) => void,
    incidentId: string,
    vehicleId: string
  ) {
    setLoading(true);
    let res = await fetcher.get(`/incident/${incidentId}/vehicle/${vehicleId}`);
    let responce: VehicleDataIRes = res?.Data;
    setFormData({
      VehicleId: vehicleId,
      InsuranceAgencyOther: responce?.VehicleInvolvement?.InsuranceAgencyOther,
      InsuranceAgencyName: responce?.VehicleInvolvement?.InsuranceAgencyName,
      VehicleRegistrationId:
        responce?.VehicleRegistration?.VehicleRegistrationId,
      Business: {
        address: {
          AddressLine1: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.Address1
            : "",
          AddressLine2: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.Address2
            : "",
          AddressType: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.AddressType
            : "",
          Apartment: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.HouseNumber
            : "",
          City: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.City
            : "",
          Country: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.NciccountryId
            : "",
          CountryDropdownValue: "",
          ForeignAddress: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.IsForeignAddress
            : false,
          ForeignAddressCity: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.OtherCity
            : "",
          ForeignAddressCountry: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.OtherCountry
            : "",
          ForeignAddressStateProvinceRegion: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.OtherState
            : "",
          ForeignAddressZip: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.OtherZip
            : "",
          GridNumber: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.Grid
            : "",
          id: 1,
          StateProvidence: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.NcicstateId
            : "",
          StateProvidenceDropdownvalue: "",
          Street: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.Street2Name
            : "",
          StreetNumber: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.Street1Name
            : "",
          StreetPrefix: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.Ncicstreet1PrefixId
            : "",
          StreetSuffix: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.Ncicstreet1SuffixId
            : "",
          ZipCode: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.ZipCode
            : "",
          AgencyId: "",
          LocationCategoryCode: !responce.RegisteredOwners[0]
            ?.RegisteredOwnerInformation.IsIndividual
            ? responce.RegisteredOwners[0].Address.LocationCategoryCode
            : "",
          LocationId: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
            .IsIndividual
            ? responce.RegisteredOwners[0].Address.LocationId
            : "",
        },
        RegisteredOwnerInformationId: !responce.RegisteredOwners[0]
          ?.RegisteredOwnerInformation.IsIndividual
          ? responce.RegisteredOwners[0].RegisteredOwnerInformation
              .RegisteredOwnerInformationId
          : "",
        BusinessName: !responce.RegisteredOwners[0]?.RegisteredOwnerInformation
          .IsIndividual
          ? responce.RegisteredOwners[0].RegisteredOwnerInformation.BusinessName
          : "",
      },
      CommercialVehicle: responce.VehicleInvolvement.CommercialVehicleIndicator,
      GrossWeight: Number(responce.VehicleInvolvement.GrossWeight),
      HazardousMaterial: responce.VehicleInvolvement.HazardousMaterial,
      Individual: responce.RegisteredOwners.map((val, i) => {
        return {
          RegisteredOwnerInformationId:
            val.RegisteredOwnerInformation.RegisteredOwnerInformationId,
          address: {
            AddressLine1: val?.Address?.Address1 ? val?.Address?.Address1 : "",
            AddressLine2: val?.Address?.Address2,
            AddressType: val?.Address?.AddressType,
            Apartment: val?.Address?.HouseNumber,
            City: val?.Address?.City,
            Country: val?.Address?.NciccountryId,
            CountryDropdownValue: "",
            ForeignAddress: val?.Address?.IsForeignAddress,
            ForeignAddressCity: val?.Address?.OtherCity,
            ForeignAddressCountry: val?.Address?.OtherCountry,
            ForeignAddressStateProvinceRegion: val?.Address?.OtherState,
            ForeignAddressZip: val?.Address?.OtherZip,
            GridNumber: val?.Address?.Grid,
            id: i + 1,
            StateProvidence: val?.Address?.NcicstateId,
            StateProvidenceDropdownvalue: "",
            Street: val?.Address?.Street2Name,
            StreetNumber: val?.Address?.Street1Name,
            StreetPrefix: val?.Address?.Ncicstreet1PrefixId,
            StreetSuffix: val?.Address?.Ncicstreet1SuffixId,
            ZipCode: val?.Address?.ZipCode,
            AgencyId: "",
            LocationCategoryCode: val?.Address?.LocationCategoryCode,
            LocationId: val?.Address?.LocationId,
          },
          DOB: val.RegisteredOwnerInformation.DOB
            ? formatDate(val?.RegisteredOwnerInformation?.DOB)
            : null,
          FirstName: val?.RegisteredOwnerInformation?.FirstName,
          id: i,
          LastName: val?.RegisteredOwnerInformation?.LastName,
          MiddleName: val?.RegisteredOwnerInformation?.MiddleName,
          Suffix: val?.RegisteredOwnerInformation?.Suffix,
        };
      }),
      InsuranceAgencyCodeId: responce.VehicleInvolvement.InsuranceAgencyCodeId,
      isIndividual: responce.RegisteredOwners[0]?.RegisteredOwnerInformation
        .IsIndividual
        ? 1
        : 2,
      PlateNumber: responce.VehicleInvolvement.PlateNumber,
      // License: responce.VehicleInvolvement.License,
      LicensePlateExpiration: responce.VehicleRegistration.ValidDates
        ? formatMonth(responce.VehicleRegistration.ValidDates)
        : "",
      Lien: responce.RegisteredOwners[0].RegisteredOwnerInformation.IsLien,
      LienHolder:
        responce.RegisteredOwners[0].RegisteredOwnerInformation.LienHolder,
      Make: responce.VehicleInvolvement.NcicmakeId,
      Model: responce.VehicleInvolvement.NcicmodelId,
      OverWeightMeasures: responce.VehicleInvolvement.VehicleOverWeightMeasure
        ? true
        : false,
      PrimaryColor: responce.VehicleInvolvement.NciccolorPrimaryId,
      SecondaryColor: responce.VehicleInvolvement.NciccolorSecondaryId,
      State: responce.VehicleInvolvement.StateId,
      TabYear: Number(responce?.VehicleInvolvement?.TabYear),
      Type: responce.VehicleInvolvement.NcictypeId,
      VIN: responce.VehicleInvolvement.Vin,
      Year: String(responce.VehicleRegistration.Year),
    });

    setLoading(false);
    return res;
  }

  async editTow(
    setLoading: (data: boolean) => void,
    incidentId: string,
    formData: setFormDataIState
  ) {
    setLoading(true);
    console.log(formData);
    let isPrimarySet = false;
    const response = await fetcher.put(
      `/incident/${incidentId}/vehicle`,

      {
        VehicleInvolvement: {
          VehicleId: formData.VehicleId ? formData.VehicleId : null,
          IncidentId: incidentId,
          NcictypeId: formData.Type ? formData.Type : null,
          NcicmakeId: formData.Make ? formData.Make : null,
          NcicmodelId: formData.Model ? formData.Model : null,
          TabYear: formData.TabYear ? formData.TabYear : null,
          // License: formData.PlateNumber,
          NciccolorPrimaryId: formData.PrimaryColor
            ? formData.PrimaryColor
            : null,
          NciccolorSecondaryId: formData.SecondaryColor
            ? formData.SecondaryColor
            : null,
          Vin: formData.VIN ? formData.VIN : null,
          InsuranceAgencyOther: formData?.InsuranceAgencyOther
            ? formData?.InsuranceAgencyOther
            : null,
          InsuranceAgencyCodeId: formData.InsuranceAgencyCodeId
            ? formData.InsuranceAgencyCodeId
            : null,
          GrossWeight: Number(formData.GrossWeight),
          PlateNumber: formData.PlateNumber ? formData.PlateNumber : null,
          OutOfCityRecovery: true,
          OutOfCityRecoveryCityState: "string",
          CommercialVehicleIndicator: formData.CommercialVehicle,
          HazardousMaterialsIndicator: true,
          HazardousMaterial: formData.HazardousMaterial
            ? formData.HazardousMaterial
            : null,
          VehicleOverWeightMeasure: formData.OverWeightMeasures,
          StateId: formData.State ? formData.State : null,
        },
        VehicleRegistration: {
          VehicleRegistrationId: formData.VehicleRegistrationId
            ? formData.VehicleRegistrationId
            : null,
          VehicleId: formData.VehicleId ? formData.VehicleId : null,
          Ncictype: formData.Type ? formData.Type : null,
          Year: formData.Year ? formData.Year : null,
          ValidDates: formData.LicensePlateExpiration
            ? convertToISOFormatMonth(formData.LicensePlateExpiration)
            : null,
          RecovkedPlates: true,
        },

        RegisteredOwners:
          formData.isIndividual == 1
            ? formData.Individual.map((val) => {
                const isPrimaryOwner = !isPrimarySet;
                isPrimarySet = true;
                return {
                  RegisteredOwnerInformation: {
                    RegisteredOwnerInformationId:
                      val.RegisteredOwnerInformationId
                        ? val.RegisteredOwnerInformationId
                        : null,
                    VehicleId: formData.VehicleId ? formData.VehicleId : null,
                    IsLien: formData.Lien,
                    LienHolder: formData.LienHolder
                      ? formData.LienHolder
                      : null,
                    LastName: val.LastName ? val.LastName : null,
                    FirstName: val.FirstName ? val.FirstName : null,
                    MiddleName: val.MiddleName ? val.MiddleName : null,
                    BusinessName: null,
                    Suffix: val.Suffix ? val.Suffix : null,
                    DOB: val?.DOB ? convertToISOFormat(val?.DOB) : null,
                    IsPrimary: isPrimaryOwner,
                    IsIndividual: formData.isIndividual == 1 ? true : false,
                  },
                  Address: {
                    LocationId: val?.address?.LocationId,
                    IncidentId: incidentId ? incidentId : null,
                    AddressType: val?.address?.AddressType
                      ? val?.address?.AddressType
                      : null,
                    LocationCategoryCode: val?.address?.LocationCategoryCode
                      ? val?.address?.LocationCategoryCode
                      : null,
                    HouseNumber: val?.address?.Apartment
                      ? val?.address?.Apartment
                      : null,
                    Ncicstreet1PrefixId: val?.address?.StreetPrefix
                      ? val?.address?.StreetPrefix
                      : null,
                    Street1Name: val?.address?.StreetNumber
                      ? val?.address?.StreetNumber
                      : null,

                    Ncicstreet1SuffixId: val?.address?.StreetSuffix
                      ? val?.address?.StreetSuffix
                      : null,
                    Street2Name: val?.address?.Street
                      ? val?.address?.Street
                      : null,
                    City: val?.address?.City ? val?.address?.City : null,
                    NcicstateId: val?.address?.StateProvidence
                      ? val?.address?.StateProvidence
                      : null,
                    ZipCode: val?.address?.ZipCode
                      ? val?.address?.ZipCode
                      : null,
                    Grid: val?.address?.GridNumber
                      ? val?.address?.GridNumber
                      : null,
                    NciccountryId: val?.address?.Country
                      ? val?.address?.Country
                      : null,
                    Address1: val?.address?.AddressLine1
                      ? val?.address?.AddressLine1
                      : null,
                    Address2: val?.address?.AddressLine2
                      ? val?.address?.AddressLine2
                      : null,
                    OtherCity: val?.address?.ForeignAddressCity
                      ? val?.address?.ForeignAddressCity
                      : null,
                    OtherState: val?.address?.ForeignAddressStateProvinceRegion
                      ? val?.address?.ForeignAddressStateProvinceRegion
                      : null,
                    OtherZip: val?.address?.ForeignAddressZip
                      ? val?.address?.ForeignAddressZip
                      : null,
                    OtherCountry: val?.address?.ForeignAddressCountry
                      ? val?.address?.ForeignAddressCountry
                      : null,
                    IsForeignAddress: val?.address?.ForeignAddress,
                  },
                };
              })
            : [
                {
                  RegisteredOwnerInformation: {
                    RegisteredOwnerInformationId: formData.Business
                      .RegisteredOwnerInformationId
                      ? formData.Business.RegisteredOwnerInformationId
                      : null,
                    VehicleId: formData.VehicleId ? formData.VehicleId : null,
                    IsLien: formData.Lien,
                    LienHolder: formData.LienHolder,
                    LastName: "",
                    FirstName: "",
                    MiddleName: "",
                    BusinessName: formData.Business.BusinessName,
                    Suffix: null,
                    DOB: null,
                    IsPrimary: true,
                    IsIndividual: formData.isIndividual == 1 ? true : false,
                  },
                  Address: {
                    LocationId: formData.Business.address.LocationId
                      ? formData.Business.address.LocationId
                      : null,
                    IncidentId: incidentId ? incidentId : null,
                    AddressType: formData.Business.address.AddressType
                      ? formData.Business.address.AddressType
                      : null,
                    LocationCategoryCode: formData.Business.address
                      .LocationCategoryCode
                      ? formData.Business.address.LocationCategoryCode
                      : null,
                    HouseNumber: formData.Business.address.Apartment
                      ? formData.Business.address.Apartment
                      : null,
                    Ncicstreet1PrefixId: formData.Business.address.StreetPrefix
                      ? formData.Business.address.StreetPrefix
                      : null,
                    Street1Name: formData.Business.address.StreetNumber
                      ? formData.Business.address.StreetNumber
                      : null,

                    Ncicstreet1SuffixId: formData.Business.address.StreetSuffix
                      ? formData.Business.address.StreetSuffix
                      : null,
                    Street2Name: formData.Business.address.Street
                      ? formData.Business.address.Street
                      : null,
                    City: formData.Business.address.City
                      ? formData.Business.address.City
                      : null,
                    NcicstateId: formData.Business.address.StateProvidence
                      ? formData.Business.address.StateProvidence
                      : null,
                    ZipCode: formData.Business.address.ZipCode
                      ? formData.Business.address.ZipCode
                      : null,
                    Grid: formData.Business.address.GridNumber
                      ? formData.Business.address.GridNumber
                      : null,
                    NciccountryId: formData.Business.address.Country
                      ? formData.Business.address.Country
                      : null,
                    Address1: formData.Business.address.AddressLine1
                      ? formData.Business.address.AddressLine1
                      : null,
                    Address2: formData.Business.address.AddressLine2
                      ? formData.Business.address.AddressLine2
                      : null,
                    OtherCity: formData.Business.address.ForeignAddressCity
                      ? formData.Business.address.ForeignAddressCity
                      : null,
                    OtherState: formData.Business.address
                      .ForeignAddressStateProvinceRegion
                      ? formData.Business.address
                          .ForeignAddressStateProvinceRegion
                      : null,
                    OtherZip: formData.Business.address.ForeignAddressZip
                      ? formData.Business.address.ForeignAddressZip
                      : null,
                    OtherCountry: formData.Business.address
                      .ForeignAddressCountry
                      ? formData.Business.address.ForeignAddressCountry
                      : null,
                    IsForeignAddress: formData.Business.address.ForeignAddress,
                  },
                },
              ],
      },
      {
        message: successUpdateMessage,
        show: true,
      }
    );
    setLoading(false);
  }
  async getStateData(
    setStateData: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/ncic/category/StateCode");

    setStateData(response.Data);
    setLoading(false);
  }
  async getSuffixData(setSuffixData: (data: GetRaceDataIState[]) => void) {
    const response = await fetcher.get("/code/ncic/category/NameSuffix");
    setSuffixData(response.Data);
  }
  async getAddresType(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/AddressType");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getVehicleMake(
    setMake: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/VehicleMake");
    let res: GetRaceDataIState[] = response.Data;
    setMake(res);
    if (setLoading) {
      setLoading(false);
    }
  }
  async getVehicleModel(
    setModel: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/VehicleModel");
    let res: GetRaceDataIState[] = response.Data;
    setModel(res);
    if (setLoading) {
      setLoading(false);
    }
  }
  async getPrimaryColor(
    setPrimary: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/VehiclePrimarycolor"
    );
    let res: GetRaceDataIState[] = response.Data;
    setPrimary(res);
    if (setLoading) {
      setLoading(false);
    }
  }
  async getSecondayColor(
    setSecondary: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/VehicleSecondaryColor"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSecondary(res);
    if (setLoading) {
      setLoading(false);
    }
  }
  async getStreetPrefix(
    setPrefix: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/ncic/category/StreetPreDirection"
    );

    setPrefix(response.Data);
    setLoading(false);
  }
  async getStreetPSuffix(
    setSuffix: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/ncic/category/StreetPostDirection"
    );

    setSuffix(response.Data);
    setLoading(false);
  }
  async getCountry(
    setCountry: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/ncic/category/Country");

    setCountry(response.Data);
    setLoading(false);
  }
  async getLocation(
    setLocation: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/ncic/category/LocationType");

    setLocation(response.Data);
    setLoading(false);
  }
  async getHazardious(
    setHazardious: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/system/category/HazardiusMaterial"
    );

    setHazardious(response.Data);
    setLoading(false);
  }
  async getCondition(
    setCondition: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/system/category/VehicleCondition"
    );
    setCondition(response.Data);
    setLoading(false);
  }
  async getInsurance(
    setInsurance: (data: setRankDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/agency/category/InsuranceAgency");
    setInsurance(response.Data);
    setLoading(false);
  }
}
