import { useSelector } from "react-redux";
import { RootState } from "../store";

function useValidateRoutes() {
  const { changeTheForm } = useSelector(
    (store: RootState) => store.ValidateRouteReducer
  );
  return !changeTheForm;
}

export default useValidateRoutes;
