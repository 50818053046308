import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { FilterIProps } from "./filtersI";
import { useEffect, useState } from "react";

export const Filter: React.FC<FilterIProps> = ({
  Name,
  setName,
  EntityType,
  setEntityType,
  onSearch,
  onClear
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");

  const clearAllFilters = () => {
    setName("");
    setEntityType("All");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
    onClear();
  };
  useEffect(() => {
    if (Name.trim() !== "" || EntityType.trim() !== "") {
      //if (Name.trim() !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
      // onSearch();
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, EntityType]);

  const handletype = (value: string) => {
    setEntityType(value);
  };

  return (
    <>
      <div className="Filter">
       
          <div className="filds___">
 <TextBoxComponent
            fieldName={"Search name"}
            value={Name}
            onChangeValue={setName}
          />
          <DropdownComponent
            dataSource={[
              { id: "Business", value: "Business" },
              { id: "Person", value: "Person" },
              { id: "All", value: "All" },
            ]}
            fieldName="Type"
            handleRowClick={handletype}
            value={EntityType}
            allowclear={false}
          />
          <div className="Involvement_right ">
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              handleClick={onSearch}
            />
          </div>
          <div className="clearbutton1">
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
          </div>
         
          
      
      </div>
    </>
  );
};
