import add from "../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import { validateEmail } from "../../../../../../../utils/Helper/Helper";
import { DropdownComponent } from "../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../../assets";
import { EmailAddressDataUIProps, EmailAddressIProps } from "./EmailIProps";

export const EmailAddressDataUI: React.FC<EmailAddressDataUIProps> = ({
  MainfieldsBusiness,setMainfieldsBusiness
}) => {

  const [validationMessage, setValidationMessage] = useState<string>("");

  const onChangeValue = (key: string, value: string, id: number) => {
    if (key === "Email" && !validateEmail(value)) {
      setValidationMessage("Please enter a valid email address");
    } else {
      setValidationMessage("");
    }

   
  };

  const remove = (id: number) => {
   
  };



  return (
    <>
      <div className="MultiplePhoneNumber">
        <DropdownComponent
          dataSource={[]}
          fieldName="Type"
         
        />
        <div className="width_size">
          <TextBoxComponent
            fieldName="Email Address"
            value=""
          />
          {validationMessage && (
            <div style={{ color: "red", fontSize: "13px" }}>
              {validationMessage}
            </div>
          )}
        </div>
        <p
          className="removeIcon"
          onClick={() => {
            // remove(EmailAddressval.id);
          }}
          title="Remove"
        >
          -
        </p>
      </div>
    </>
  );
};

export const EmailAddress: React.FC<EmailAddressIProps> = ({
  MainfieldsBusiness,
  setMainfieldsBusiness,
}) => {
    const handleClick = () => {
        setMainfieldsBusiness({
          ...MainfieldsBusiness,
          Involvement: {
            ...MainfieldsBusiness.Involvement,
            Email: [
              ...MainfieldsBusiness.Involvement.Email,
              {
                id: MainfieldsBusiness.Involvement.Email.length + 1,
                Email: "",
                ExternalContactID: "",
                TypeDropdownvalue: "",
                Type: "",
              },
            ],
          },
        });
      };
  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_">Email Address</p>
            {MainfieldsBusiness.Involvement.Email.map((EmailAddressval) => {
              return (
                <>
                  <EmailAddressDataUI
                    MainfieldsBusiness={MainfieldsBusiness}
                    setMainfieldsBusiness={setMainfieldsBusiness}
                    EmailAddressval={EmailAddressval}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="edit-button_" style={{ marginTop: "5%" }}>
          <ButtonComponents
            name="Add Email Address"
            handleClick={handleClick}
            color="white"
              textColor="#3672b3"
              borderColor="#3672b3"
              showBackgroundColor={true}
              icon={Add}
          />
        </div>
      </div>
    </>
  );
};
