import { Fetcher } from "../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../utils/Helper/Helper";
import { BusinessContactData, BusinessContactMainIRes } from "./BusinessContactIState";



const fetcher = new Fetcher();

export class BusinessContactMainFunction {
  async getBusinessContactMain(
    setTableData: (data: BusinessContactData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    let response = await fetcher.get(
      `/name-master/${nameId}/business-contacts`
    );
    let res: BusinessContactMainIRes[] = response.Data;
    const TableJson = this.createTableJson(res);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: BusinessContactMainIRes[]) {
    return data?.map((val: BusinessContactMainIRes, i: number) => {
      return {
        Case: val.IncidentNumber,
        Date: formatDate(val.CreatedOn),
        Type: val.ContactTypeName,
        Contact: val.ContactValue,
      };
    });
  }
}
