import React, { useEffect, useState } from "react";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../CommonComponents/Table/Table";
import add from "../../../../../../assets/icons/Icon.svg";
import "../EmergencyContactTab/EmergencyContact.css";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { EmergencyContactTabFunction } from "./EmergencyContactTabFunction";
import { EditUserManagementIParams } from "../../../EditUserManagement/EditUserManagementI";
import { useParams } from "react-router-dom";
import { ModalComponent } from "../../../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { coloum } from "./Config";
import { AddEditViewEmergencyContact } from "./AddEditViewEmergencyContact/AddEditViewEmergencyContact";
import {
  SetTableData,
  setgetEmergencyContactSingleDataIState,
} from "./EmergencyContactTabI";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

const EmergencyContactTab = () => {
  const { Update, Create, Delete } = useGetPerticularUserPermission("User");
  const emergencyContactTabFunction = new EmergencyContactTabFunction();
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [showAddEmergency, setShowAddEmergency] = useState<boolean>(false);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [viewMode, setviewMode] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [getEmergencyContactSingleData, setgetEmergencyContactSingleData] =
    useState<setgetEmergencyContactSingleDataIState>({
      "First Name": "",
      "Last Name": "",
      "Middle Name": "",
      "Primary Contact": false,
      City: "",
      Email: "",
      ID: "",
      PrimaryPhone: "",
      Relation: "",
      SecondaryPhone: "",
      StateCodeId: "",
      StreetAddress: "",
      Suffix: "",
      WorkPhone: "",
      ZipCode: "",
      SuffixCodeId: "",
      AgencyId: "",
      EmergencyContactId: "",
      Contacts: [
        { ContactMethodCodeId: "", ContactValue: "", id: 1, IsPrimary: false },
      ],
    });
  const { userId } = useParams<EditUserManagementIParams>();

  const handleAddEmergency = () => {
    setgetEmergencyContactSingleData({
      "First Name": "",
      "Last Name": "",
      "Middle Name": "",
      "Primary Contact": false,
      City: "",
      Email: "",
      ID: "",
      PrimaryPhone: "",
      Relation: "",
      SecondaryPhone: "",
      StateCodeId: "",
      StreetAddress: "",
      Suffix: "",
      WorkPhone: "",
      ZipCode: "",
      SuffixCodeId: "",
      AgencyId: "",
      EmergencyContactId: "",
      Contacts: [
        { ContactMethodCodeId: "", ContactValue: "", id: 1, IsPrimary: false },
      ],
    });
    setShowAddEmergency(true);
    setAddMode(true);
    setEditMode(false);
  };

  const getAddEmergencyTableData = () => {
    if (userId) {
      emergencyContactTabFunction.getAddEmergencyTableData(
        setLoading,
        userId,
        setTableData,
      );
    }
  };

  const editORI = (data: setgetEmergencyContactSingleDataIState) => {
    setAddMode(false);
    setEditMode(true);
    setviewMode(false);
    setShowAddEmergency(true);

    setgetEmergencyContactSingleData({
      Relation: data.Relation,
      "Primary Contact": data["Primary Contact"],
      "Last Name": data["Last Name"],
      "First Name": data["First Name"],
      "Middle Name": data["Middle Name"],
      ID: data.ID,
      Suffix: data.Suffix,
      StreetAddress: data.StreetAddress,
      PrimaryPhone: data.PrimaryPhone,
      SecondaryPhone: data.SecondaryPhone,
      City: data.City,
      StateCodeId: data.StateCodeId,
      ZipCode: data.ZipCode,
      Email: data.Email,
      WorkPhone: data.WorkPhone,
      SuffixCodeId: data.SuffixCodeId,
      AgencyId: data.AgencyId,
      EmergencyContactId: data?.EmergencyContactId,
      Contacts: [
        { ContactMethodCodeId: "", ContactValue: "", id: 1, IsPrimary: false },
      ],
    });
  };

  const deleteData = () => {
    if (deleteId) {
      emergencyContactTabFunction
        .deleteEmergencyContact(setLoading, deleteId, String(userId))
        .then(() => {
          getAddEmergencyTableData();
        });
      setDeletePopup(false);
    }
  };

  const setdeleteData = (data: setgetEmergencyContactSingleDataIState) => {
    setDeletePopup(true);
    setDeleteId(data.ID);
  };

  const closeDeletePopup = () => {
    setDeletePopup(false);
    setDeleteId("");
  };

  const onRowClicked = (Data: setgetEmergencyContactSingleDataIState) => {
    if (!deleteId) {
      let data: setgetEmergencyContactSingleDataIState = Data;
      setAddMode(false);
      setEditMode(false);
      setviewMode(true);
      setShowAddEmergency(true);

      setgetEmergencyContactSingleData({
        Relation: data.Relation,
        "Primary Contact": data["Primary Contact"],
        "Last Name": data["Last Name"],
        "First Name": data["First Name"],
        "Middle Name": data["Middle Name"],
        ID: data.ID,
        Suffix: data.Suffix,
        StreetAddress: data.StreetAddress,
        PrimaryPhone: data.PrimaryPhone,
        SecondaryPhone: data.SecondaryPhone,
        City: data.City,
        StateCodeId: data.StateCodeId,
        ZipCode: data.ZipCode,
        Email: data.Email,
        WorkPhone: data.WorkPhone,
        SuffixCodeId: data.SuffixCodeId,
        AgencyId: data.AgencyId,
        EmergencyContactId: data?.EmergencyContactId,
      });
    }
  };

  useEffect(() => {
    if (!showAddEmergency) {
      getAddEmergencyTableData();
    }
  }, [showAddEmergency]);

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={deleteData}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      {showAddEmergency ? (
        <AddEditViewEmergencyContact
          setAddMode={setAddMode}
          setEditMode={setEditMode}
          setviewMode={setviewMode}
          setShowAddEmergency={setShowAddEmergency}
          addMode={addMode}
          editMode={editMode}
          viewMode={viewMode}
          getEmergencyContactSingleData={getEmergencyContactSingleData}
        />
      ) : (
        <div className="DetailTab_top">
          <div className="DetailTab_top_header_">
            <div className="DetailTab_top_header_sub">
              <p>Emergency Contacts</p>
              <div className="IPAddress">
                {userId && Create && (
                  <ButtonComponents
                    name="Add Emergency Contact"
                    icon={add}
                    handleClick={handleAddEmergency}
                    iconStyle={{ filter: "invert(0)" }}
                    showBackgroundColor={true}
                    //  color="#F0F0F0"
                    textColor="white"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="IPAddresses_table">
            <Table
              columns={coloum(editORI, setdeleteData, Update, Delete)}
              columnsValue={tableData}
              showPagination={false}
              onRowClicked={onRowClicked}
              clientSideSorting={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EmergencyContactTab;
