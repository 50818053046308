import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { AllPagesRoles } from "../../Router/PrivateRoute";

interface useGetUserPermissionI {
  Create: boolean;
  Read: boolean;
  Update: boolean;
  Delete: boolean;
  General: boolean;
}

export const useGetPerticularUserPermission = (
  roleName: AllPagesRoles
): useGetUserPermissionI => {
  const {
    "Code.Agency": CodeAgency,
    "Code.NCIC": CodeNCIC,
    "Code.System": CodeSystem,
    "Incident.Article": IncidentArticle,
    "Incident.CAD": IncidentCAD,
    "Incident.Location": IncidentLocation,
    "Incident.Media": IncidentMedia,
    "Incident.Menu.Config": IncidentMenuConfig,
    "Incident.Narrative": IncidentNarrative,
    "Incident.Participant": IncidentParticipant,
    "Incident.Procecution": IncidentProsecution,
    "Incident.Vehicle": IncidentVehicle,
    Agency,
    CAD,
    Config,
    Incident,
    MasterIndex,
    User,
  } = useSelector((store: RootState) => store.UserPermissionReducer);

  let rolePermissions: string[] = [];
  switch (roleName) {
    case "Code.Agency":
      rolePermissions = CodeAgency;
      break;
    case "Code.NCIC":
      rolePermissions = CodeNCIC;
      break;
    case "Code.System":
      rolePermissions = CodeSystem;
      break;
    case "Incident.Article":
      rolePermissions = IncidentArticle;
      break;
    case "Incident.CAD":
      rolePermissions = IncidentCAD;
      break;
    case "Incident.Location":
      rolePermissions = IncidentLocation;
      break;
    case "Incident.Media":
      rolePermissions = IncidentMedia;
      break;
    case "Incident.Menu.Config":
      rolePermissions = IncidentMenuConfig;
      break;
    case "Incident.Narrative":
      rolePermissions = IncidentNarrative;
      break;
    case "Incident.Participant":
      rolePermissions = IncidentParticipant;
      break;
    case "Incident.Prosecution":
      rolePermissions = IncidentProsecution;
      break;
    case "Incident.Vehicle":
      rolePermissions = IncidentVehicle;
      break;
    case "Agency":
      rolePermissions = Agency;
      break;
    case "CAD":
      rolePermissions = CAD;
      break;
    case "Config":
      rolePermissions = Config;
      break;
    case "Incident":
      rolePermissions = Incident;
      break;
    case "MasterIndex":
      rolePermissions = MasterIndex;
      break;
    case "User":
      rolePermissions = User;
      break;
    default:
      rolePermissions = [];
      break;
  }

  const permissions: useGetUserPermissionI = {
    Create: rolePermissions?.includes("Create"),
    Read: rolePermissions?.includes("Read"),
    Update: rolePermissions?.includes("Update"),
    Delete: rolePermissions?.includes("Delete"),
    General: rolePermissions?.includes("General"),
  };

  return permissions;
};
