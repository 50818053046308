import React from "react";
import { BackButtonComponent } from "../../../CommonComponents/BackButton/BackButtonComponent";
import "../AddUserManagement/AddUserManagement.css";
import { useNavigate } from "react-router-dom";
import { TabsIndex } from "../Components/Tabs/TabsIndex";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
import useValidateRoutes from "src/hooks/useValidateRoutes";
import useSaveChangesPopup from "src/hooks/useSaveChangesPopup";
import { ModalComponent } from "src/Components/CommonComponents/Modal/ModalComponent";
import { SaveTheChangesPopup } from "src/Components/CommonComponents/Modal/SaveTheChangesPopup/SaveTheChangesPopup";

const AddUserManagement: React.FC = () => {
  const navigation = useNavigate();

  let ValidateRoutes = useValidateRoutes();
  const { showSaveTheChangesPopup, openPopup, onClose, onOk, setTemp, temp } =
    useSaveChangesPopup();

  const NavigateToUserPage = () => {
    if (ValidateRoutes) {
      navigation("/user");
    } else {
      openPopup();
    }
  };

  return (
    <div>
      <ModalComponent
        open={showSaveTheChangesPopup}
        title="Alert"
        innerContant={
          <SaveTheChangesPopup
            onClose={onClose}
            onOk={() => {
              onOk();
              navigation("/user");
            }}
          />
        }
      />

      <div className="CreateAgencyManagement">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "User", path: "/user" },
            { title: "Add", path: "" },
          ]}
        />
        <div style={{ lineHeight: "0%" }}>
          <BackButtonComponent
            name="Back to Users"
            onClick={NavigateToUserPage}
          />
        </div>

        <h3 style={{ lineHeight: "10px" }}> Add User</h3>
        <TabsIndex viewUserComponent={false} />
      </div>
    </div>
  );
};

export default AddUserManagement;
