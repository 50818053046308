import { Fetcher } from "../../../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../../../utils/const";
import { setformFieldsIState } from "./AddEditViewEmergencyContact/AddEditViewEmergencyContactI";
import {
  SetTableData,
  getAddEmergencyTableDataI,
} from "./EmergencyContactTabI";

const fetcher = new Fetcher();

export class EmergencyContactTabFunction {
  async getAddEmergencyTableData(
    setLoading: (data: boolean) => void,
    userId: string,
    setTableData: (data: SetTableData[]) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/user/${userId}/emergency-contact`);
    const TableJson = this.createTableJson(response?.Data);
    setTableData(TableJson);

    setLoading(false);
  }

  async getAddEmergencyTableDataById(
    setLoading: (data: boolean) => void,
    userId: string,
    setformFields: (data: setformFieldsIState) => void,
    emergencyId: string
  ) {
    setLoading(true);
    let response = await fetcher.get(
      `/user/${userId}/emergency-contact/${emergencyId}`
    );
    setformFields({
      ...response?.Data,
      PrimaryContact: response?.Data?.PrimaryContact ? "1" : "2",
      Suffix: response?.Data?.SuffixCodeId,
    });
    setLoading(false);
  }

  createTableJson(data: getAddEmergencyTableDataI[]) {
    return data?.map((val: getAddEmergencyTableDataI, i: number) => {
      return {
        Relation: val.Relation,
        "Primary Contact": val.PrimaryContact,
        "Last Name": val.LastName,
        "First Name": val.FirstName,
        "Middle Name": val.MiddleName,
        ID: val.EmergencyContactId,
        Suffix: val.Suffix,
        StreetAddress: val.StreetAddress,
        PrimaryPhone: val.PrimaryPhone,
        SecondaryPhone: val.SecondaryPhone,
        City: val.City,
        StateCodeId: val.StateCodeId,
        ZipCode: val.ZipCode,
        Email: val.Email,
        WorkPhone: val.WorkPhone,
        SuffixCodeId: val.SuffixCodeId,
        AgencyId: val?.AgencyId,
        EmergencyContactId: val?.EmergencyContactId,
      };
    });
  }

  async deleteEmergencyContact(
    setLoading: (data: boolean) => void,
    EmergencyContactId: string,
    userId: string
  ) {
    setLoading(true);
    const response = await fetcher.delete(
      `/user/${userId}/emergency-contact?emergencyContactId=${EmergencyContactId}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
  }
}
