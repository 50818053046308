import { Fetcher } from "../../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../../utils/const";
import { formatDate } from "../../../../../utils/Helper/Helper";
import { GetRaceDataIState } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { OrderByFieldName } from "./config";
import {
  getVehicleTableRes,
  OrderByType,
  setTableData,
} from "./VechicleIState";

const fetcher = new Fetcher();
export class VehicleListFunction {
  async getVehicleListTableData(
    setTableData: (data: setTableData[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    Name: string,
    Type: string,
    OrderByDescending?: boolean,
    OrderBy?: string,
    IsAllRecord?: boolean
  ) {
    setLoading(true);

    const isSearch = !!(Name || Type);
    let response = await fetcher.post(
      `/incident/53C54E75-8FA5-4576-86E1-BBB9465D4269/vehicle/search`,
      {
        PageNumber: isSearch ? 1 : pageNumber,
        PageSize: pageCount,
        PlateNumber: Name ? Name : null,
        NcictypeId: Type ? Type : null,
        IsAllRecord: IsAllRecord ? IsAllRecord : false,
        ...(OrderByDescending != null ? { OrderByDescending } : {}),
        OrderBy: OrderByFieldName[OrderBy as OrderByType],
      }
    );
    const TableJson = this.createTableJson(response?.Data);
    console.log(response);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
    return response;
  }

  createTableJson(data: getVehicleTableRes[]) {
    return data?.map((val: getVehicleTableRes, i: number) => {
      return {
        VehicleId: val.VehicleId,
        "License Plate": val.PlateNumber,
        Year: val.Year,
        Model: val.NcicModel,
        Type: val.NcicType,
        Make: val.NcicMake,
        Color: val.NcicColorPrimary,
   "Registered Owner(s)": `${val.PrimaryOwner ? val.PrimaryOwner : ""}${
          val.SecondaryOwner ? ", " : ""
        }${val.SecondaryOwner ? val.SecondaryOwner : ""}`,


        Commercial: val?.CommercialVehicleIndicator ? "Yes" : "No",
      };
    });
  }
  async deleteVehicle(
    vehicleId: string,
    incidentId: string,
    setLoading: (data: boolean) => void,
    refreshData: () => void
  ) {
    setLoading(true);
    await fetcher.delete(
      `/incident/${incidentId}/vehicle/${vehicleId}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    refreshData();
  }

  async getVehicleType(
    setType: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/VehicleType");
    let res: GetRaceDataIState[] = response.Data;
    setType(res);
    if (setLoading) {
      setLoading(false);
    }
  }
}
