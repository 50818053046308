import React, { useState } from "react";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";

import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { RootState } from "../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";
import { setRoleeditComponent } from "../../../../../../Redux/RoleReducer";
import {
  AddEditFormI,
  AddEditFormIParams,
  SetFormDataIState,
  setFormDataValidation,
} from "./AddEditFormI";
import { AddEditFormFunction } from "./AddEditFormFunction";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const AddEditRole: React.FC<AddEditFormI> = ({ viewRoleComponent }) => {
  const { RoleName, Status, Description } = useSelector(
    (store: RootState) => store.RoleReducer
  );
  const { roleId } = useParams<AddEditFormIParams>();
  const dispatch = useDispatch();
  const addEditFormFunction = new AddEditFormFunction();

  const [edit, setEdit] = useState<boolean>(viewRoleComponent ? true : false);
  const [formData, setformData] = useState<SetFormDataIState>({
    RoleName: RoleName ? RoleName : "",
    Description: Description ? Description : "",
    Status: Status ? Status : "1",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const navigation = useNavigate();

  const handleInputChange = (key: string, val: number | string) => {
    setformData({
      ...formData,
      [key]: val,
    });
    setFormDataValidation({
      ...formDataValidation,
      [key]: false,
    });
    dispatch(setchangeTheForm(true));
  };
  const editDisable = () => {
    setformData({
      RoleName: RoleName ? RoleName : "",
      Description: Description ? Description : "",
      Status: Status ? Status : "1",
    });
    dispatch(setchangeTheForm(false));
  };
  const onSave = () => {
    addEditFormFunction.errorValidation(formData, setFormDataValidation);
    if (formData.RoleName) {
      if (addEditFormFunction.checkRequiredFields(formData)) {
        if (roleId) {
          addEditFormFunction.editAgency(setLoading, roleId, formData);
          dispatch(setchangeTheForm(false));
        } else {
          if (formData.RoleName.length) {
            addEditFormFunction.addAgency(setLoading, formData);
            dispatch(setchangeTheForm(false));
          }
        }
        navigation("/role");
      }
    }
  };

  const allowEdit = () => {
    setEdit(false);
    dispatch(
      setRoleeditComponent({
        viewRoleComponent: false,
        addRoleComponent: false,
        editRoleComponent: true,
      })
    );
  };
  const [formDataValidation, setFormDataValidation] =
    useState<setFormDataValidation>({
      RoleName: false,
      Description: false,
      IsActive: false,
    });

  return (
    <>
      <Loader loading={loading} />
      <div className="DetailTab_top">
        <div className="DetailTab_top_header_">
          <div className="DetailTab_top_header_sub">
            <p>New Role</p>
            {roleId && viewRoleComponent ? (
              <p onClick={allowEdit} className="SummaryTab_top_header_edit">
                Edit
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="add-ip-content">
          <div className="add-ip-textbox">
            <DropdownComponent
              dataSource={[
                { id: "1", value: "Active" },
                { id: "2", value: "InActive" },
              ]}
              disabled={edit}
              fieldName="Status*"
              value={formData.Status}
              handleRowClick={(newValue) => {
                handleInputChange("Status", newValue);
              }}
              validation={formDataValidation.IsActive}
            />
          </div>
          <div className="add-ip-textbox">
            <TextBoxComponent
              disabled={edit}
              value={formData.RoleName}
              fieldName="Role Name*"
              onChangeValue={(newValue) => {
                handleInputChange("RoleName", newValue);
              }}
              validation={formDataValidation.RoleName}
            />
          </div>{" "}
          <div className="add-ip-textbox">
            <TextAreaComponent
              disabled={edit}
              value={formData.Description}
              fieldName="Description*"
              onChangeValue={(newValue) => {
                handleInputChange("Description", newValue);
              }}
              validation={formDataValidation.Description}
            />
          </div>
        </div>
      </div>{" "}
      <div className="button-ip">
        <div className="edit-button">
          {!edit && <Submit onCancel={editDisable} onSave={onSave} />}
        </div>
      </div>
    </>
  );
};
