

import { ColDef } from "ag-grid-community";
import "../Components/Filter/../../../../RolePermissionManagement/Permission/ListPermissionManagement/ListPermission.css"
interface columnDefs{
    value:boolean;
}
export const coloum: () => ColDef[] = () => {
  return [
    { field: "Permission" },
    { field: "Description" },
    {
      field: "General",
      cellRenderer: (params: columnDefs) => {
        if (!params.value) return null;
        return <input type="checkbox" checked={params.value} disabled className="checkbox-input"/>;
      },
    },
    {
      field: "Create",
      cellRenderer: (params: columnDefs) => {
        if (!params.value) return null;
        return <input type="checkbox" checked={params.value} disabled  className="checkbox-input"/>;
      },
    },
    {
      field: "Read",
      cellRenderer: (params: columnDefs) => {
        if (!params.value) return null;
        return <input type="checkbox" checked={params.value} disabled className="checkbox-input"/>;
      },
    },
    {
      field: "Update",
      cellRenderer: (params: columnDefs) => {
        if (!params.value) return null;
        return <input type="checkbox" checked={params.value} disabled  className="checkbox-input"/>;
      },
    },
    {
      field: "Delete",
      cellRenderer: (params: columnDefs) => {
        if (!params.value) return null;
        return <input type="checkbox" checked={params.value} disabled  className="checkbox-input"/>;
      },
    },
  ];
};
