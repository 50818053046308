import React from "react";
import { DeleteConfirmationIProps } from "./DeleteConfirmationI";
import "./DeleteConfirmation.css";

export const DeleteConfirmation: React.FC<DeleteConfirmationIProps> = ({
  onClose,
  onDelete,
}) => {
  return (
    <div>
      <div>
        <div className="modal-content">
          <p className="modal-message">
            Are you sure you want to delete this item?
          </p>
          <div className="modal-actions">
            <button className="modal-button" onClick={onClose}>
              No
            </button>
            <button className="modal-button-confirm" onClick={onDelete}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
