import { useEffect, useState } from "react";
import { DropdownComponent } from "../../Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../Fields/TextBox/TextBoxComponent";
import {
  AddCategoryIProps,
  SetAddCategoryIState,
  codecategory,
} from "./AddCategoryI";
import "../AddCategory/AddCategory.css";

import { getCurrentDate } from "../../../../utils/Helper/Helper";
import { TextAreaComponent } from "../../Fields/TextArea/TextAreaComponent";
import { Submit } from "../../Fields/Submit/Submit";
import { AddCategoryFunction } from "./AddCategoryFunction";
import { useLocation } from "react-router-dom";

export const AddCategory: React.FC<AddCategoryIProps> = ({
  onCancel,
  onSave,
  getCategoryData,
}) => {
  const location = useLocation();

  let calledFromAgencyLevel = location.pathname.includes("agency");
  const [formData, setFormData] = useState<SetAddCategoryIState>({
    IsActive: "1",
    Description: "",
    Category: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const addCategoryFunction = new AddCategoryFunction();

  const handleInputChange = (field: string, value: string | number) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const CategoryAdd = async () => {
    await addCategoryFunction.addCategory(
      setLoading,
      formData,
      onCancel,
      getCategoryData,
      calledFromAgencyLevel
    );
  };
  useEffect(() => {
    setFormData({
      IsActive: "1",
      Description: "",
      Category: "",
    });
  }, [onCancel]);
  return (
    <>
      <div className="AddEditViewNCIC">
        <div className="AgencyLevelAddEditView_1">
          <DropdownComponent
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            value={formData.IsActive}
            fieldName="Status"
            handleRowClick={(newValue) => {
              handleInputChange("IsActive", newValue);
            }}
          />
        </div>
        <div className="AgencyLevelAddEditView_1">
          <TextBoxComponent
            value={formData.Category}
            fieldName="Category Name"
            onChangeValue={(newValue) => {
              handleInputChange("Category", newValue);
            }}
          />
        </div>

        <TextAreaComponent
          value={formData.Description}
          fieldName="Description"
          onChangeValue={(newValue) =>
            handleInputChange("Description", newValue)
          }
        />
        <div className="add-modal">
          {
            <div className="modal-button">
              <div className="modal-submit">
                <Submit onCancel={onCancel} onSave={CategoryAdd} />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};
