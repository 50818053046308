import { Fetcher } from "src/services/Fetcher";
import {
  Cadcomment,
  cadDetailsIRes,
  employeesTableDataIRes,
  SetTableData,
} from "./CADOverviewI";
import { formatDate, formatDatewithTime } from "src/utils/Helper/Helper";

const fetcher = new Fetcher();
export class CADOverviewFunction {
  async getCADcommentData(
    setLoading: (data: boolean) => void,
    incidentId: string,
    setCADComment: (data: Cadcomment[]) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/cad/incident${incidentId}/comment`);
    let res: Cadcomment[] = response.Data;
    setCADComment(res);

    setLoading(false);
  }

  async getcadDetails(
    setLoading: (data: boolean) => void,
    incidentId: string,
    setcadDetails: (data: cadDetailsIRes) => void,
    setTableData: (data: SetTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/cad/cad-details/${incidentId}`);
    let res: cadDetailsIRes = response?.Data;
    setcadDetails(res);
    if (res?.CadincidentId) {
      this.getemployeesTableData(
        res.CadincidentId,
        setTableData,
        setLoading,
        setTableTotalRecord
      );
    }

    setLoading(false);
  }

  async getemployeesTableData(
    cadIncidentId: string,
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(`/squad/cad-squads/${cadIncidentId}`);
    const TableJson = this.createTableJson(response?.Data);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: employeesTableDataIRes[]) {
    return data?.map((val: employeesTableDataIRes, i: number) => {
      return {
        Employee: val.Employee,
        UnitName: val.UnitName,
        TimeAssigned: val.TimeAssigned
          ? formatDatewithTime(val.TimeAssigned)
          : "",
        TimeEnroute: val.TimeEnroute ? formatDatewithTime(val.TimeEnroute) : "",
        TimeAtScene: val.TimeAtScene,
        TimeClearedCall: val.TimeClearedCall
          ? formatDatewithTime(val.TimeClearedCall)
          : "",
      };
    });
  }
}
