import delete_icon from "../../../../../../assets/icons/delete.svg";
import edit_icon from "../../../../../../assets/icons/edit.svg";
import { SetTableData } from "./IPAddressesI";

export const columns = (
  editIpAddress: (data: SetTableData) => void,
  openDeletePopup: (data: string) => void,
  Delete: boolean,
  Update: boolean
) => {
  return [
    { field: "IP Address" },
    { field: "Description" },
    { field: "Status" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
              <img
                className="table-cellRenderer-img"
                src={edit_icon}
                onClick={() => editIpAddress(e.data)}
                title="Edit"
              />
            )}
            {Delete && (
              <img
                className="table-cellRenderer-img"
                src={delete_icon}
                title="Delete"
                onClick={() => {
                  openDeletePopup(e.data.WhitelistIPAddressId);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
};
