import { capitaliseLetter } from "src/utils/Helper/Helper";
import { Fetcher } from "../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../utils/const";
import { OrderByFieldName } from "./Config";
import {
  GetUserManagementTableDataI,
  OrderByType,
  SetTableData,
} from "./ListUserManagementI";

const fetcher = new Fetcher();
export class UserManagementFunction {
  async GetUserManagementTableData(
    setTableData: (data: SetTableData[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    Name: string,
    PostNo: string,
    Badge: string,
    Rank: string,
    HomeUnit: string,
    Status: string,
    OrderByDescending?: boolean,
    OrderBy?: string
  ) {
    setLoading(true);

    const isSearch = !!(Name || PostNo || Badge);
    const response = await fetcher.post(`/user/search`, {
      PageNumber: isSearch ? 1 : pageNumber,
      PageSize: pageCount,
      Name: Name ? Name : null,
      PostNo: PostNo ? PostNo : null,
      Badge: Badge ? Badge : null,
      RankId: Rank ? Rank : null,
      HomeUnitId: HomeUnit ? HomeUnit : null,
      Status: Status == "1" ? true : false,
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
    });
    const TableJson = this.createTableJson(response?.Data);
    if (setTableTotalRecord) {
      setTableTotalRecord(response?.TotalRecords);
    }
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: GetUserManagementTableDataI[]) {
    return data?.map((val: GetUserManagementTableDataI, i: number) => {
      return {
        "First Name": capitaliseLetter(val?.FirstName),
        "Middle Name": capitaliseLetter(val.MiddleName),
        "Last Name": capitaliseLetter(val.LastName),
        Suffix: val.Suffix,
        "Post No": val.PostNumber,
        "Home Unit": val.HomeUnitName,
        Rank: val.RankName,
        Status: val.IsActive ? "Active" : "InActive",
        Badge: val.BadgeNumber,
        "Last Login": "",
        ID: val.UserId,
      };
    });
  }
  async deleteUser(
    id: string,
    setLoading: (data: boolean) => void,
    refreshData: () => void
  ) {
    setLoading(true);
    await fetcher.delete(
      `/user/${id}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    refreshData();
  }
}
