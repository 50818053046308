import { useEffect, useState } from "react";

function useDelayedEffect(
  callback: () => void,
  delay: number,
  dependencies: React.DependencyList
) {
  const [initialNoRender, setInitialNoRender] = useState<boolean>(true);

  useEffect(() => {
    if (initialNoRender) {
      setInitialNoRender(false);
    } else {
      const timeoutId = setTimeout(() => {
        callback();
      }, delay);
      return () => clearTimeout(timeoutId);
    }
  }, dependencies);
}

export default useDelayedEffect;
