import { Fetcher } from "../../../../../../../../../services/Fetcher";
import { GetRaceDataIState } from "../../InvolvementMainI";

const fetcher = new Fetcher();
export class VictimInformationFunction {
  async VictimType(setVictimTypeData: (data: GetRaceDataIState[]) => void) {
    const response = await fetcher.get("/code/ncic/category/VictimType");
    let res: GetRaceDataIState[] = response.Data;
    setVictimTypeData(res);
  }

  async AggravatedAssaulthomicideCode(
    setAggravatedAssaulthomicideCode: (data: GetRaceDataIState[]) => void
  ) {
    const response = await fetcher.get(
      "/code/ncic/category/AggravatedAssaultOrHomicideCircumstance"
    );
    let res: GetRaceDataIState[] = response.Data;
    setAggravatedAssaulthomicideCode(res);
  }

  async InjuryType(setInjuryType: (data: GetRaceDataIState[]) => void) {
    const response = await fetcher.get("/code/ncic/category/InjuryType");
    let res: GetRaceDataIState[] = response.Data;
    setInjuryType(res);
  }

  async AdditionalJustifiablehomicide(
    setAdditionalJustifiablehomicide: (data: GetRaceDataIState[]) => void
  ) {
    const response = await fetcher.get(
      "/code/ncic/category/AdditionalJustifiableHomicideCircumstance"
    );
    let res: GetRaceDataIState[] = response.Data;
    setAdditionalJustifiablehomicide(res);
  }

  async OfficerAssignmentType(
    setOfficerAssignmentType: (data: GetRaceDataIState[]) => void
  ) {
    const response = await fetcher.get(
      "/code/ncic/category/OfficerAssignmentType"
    );
    let res: GetRaceDataIState[] = response.Data;
    setOfficerAssignmentType(res);
  }

  async OfficerActivityOrCircumstanceType(
    setOfficerActivityOrCircumstanceType: (data: GetRaceDataIState[]) => void
  ) {
    const response = await fetcher.get(
      "/code/ncic/category/OfficerActivityOrCircumstanceType"
    );
    let res: GetRaceDataIState[] = response.Data;
    setOfficerActivityOrCircumstanceType(res);
  }
}
