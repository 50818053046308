import React, { useEffect, useState } from "react";
import { StatutesTableData } from "../ParticipantOverviewIState";
import { coloum } from "./Config";
import { Table } from "../../../../../../../CommonComponents/Table/Table";
import { useParams } from "react-router-dom";
import { StatutesFunction } from "./StatutesFunction";
import { InvolvementMainIParams } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

export const Statutes: React.FC = () => {
  const statutesFunction = new StatutesFunction();
  const [tableData, setTableData] = useState<StatutesTableData[]>([]);
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  const getContact = () => {
    if (participantId && incidentId) {
      statutesFunction.getStatutes(
        participantId,
        incidentId,
        setTableData,
        setTableTotalRecord
      );
    }
  };

  useEffect(() => {
    getContact();
  }, []);
  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p><strong>Statutes</strong></p>
          {/* <p className="DetailTab_top_">
  <span className="total-records"><strong>Total Records:{tableData.length}</strong></span>
  <span className="edit"><strong>Edit</strong></span>
  <span className="view"><strong>View</strong></span>
</p> */}
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
           
           
           headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
