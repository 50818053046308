import { useState } from "react";
import { TabsComponents } from "../../../CommonComponents/TabsComponents/TabsComponents";
import { AgencyLevelCode } from "./AgencyLevelCode";
import { Category } from "../Category/Category";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";

export const AgencyLevel: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeTabClone, setactiveTabClone] = useState<number>(0);

  return (
    <>
      <div className="CodeTablePagemain">
        <div className="CodeTablePage">
        
          <div>
            
            <p className="header">Agency Level</p>
            <TabsComponents
              activeTab={activeTabClone}
              tabList={[
                { name: "Code", id: 1 },
                { name: "Category", id: 2 },
              ]}
              handleTabChange={setactiveTabClone}
            />

            {activeTabClone == 0 ? (
              <AgencyLevelCode />
            ) : activeTabClone == 1 ? (
              <Category calledFromAgencyLevel={true} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
