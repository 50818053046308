import React, { useEffect, useState } from "react";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { AddTowFunction } from "./TowAddFunction";
import { GetRaceDataIState } from "../AddVehicleIState";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import { setRankDataIState } from "../../../../UserManagement/Components/Tabs/SummaryTab/SummaryTabInterface";
import { NumberBoxComponents } from "../../../../../CommonComponents/Fields/NumberBox/NumberBoxComponents";
import { TimePickerComponenets } from "../../../../../CommonComponents/Fields/TimePicker/TimePickerComponenets";
import { AddVehicleFunction } from "../Vehicle/AddVehicleFunction";
import { VehicleIParams } from "../Vehicle/VehicalI";
import { useNavigate, useParams } from "react-router-dom";
import useTowFormState from "../../../../../../hooks/VehicleFormState/TowFormIState";
import { useDispatch } from "react-redux";
import { setIncidentId } from "../../../../../../Redux/CommonRedux";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";

const TowForm = () => {
  const { incidentId, vehicleId ,TowId} = useParams<VehicleIParams>();
  const addTowFunction = new AddTowFunction();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const addVehicleFunction = new AddVehicleFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<GetRaceDataIState[]>([]);
  const [Involvement, setInvolvement] = useState<GetRaceDataIState[]>([]);
  const [stateData, setStateData] = useState<GetRaceDataIState[]>([]);
  const [country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [Tow, setTow] = useState<setRankDataIState[]>([]);
  const [vehicleStreetPrefix, setVehicleStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [vehicleStreetSuffix, setVehicleStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [AddressTypeData, setAddressTypeData] = useState<GetRaceDataIState[]>( []);
  const { formData, setFormData } = useTowFormState();
  const [other,setOther]=useState<string>('')
  const handleInputChange = (field: string, value: string | number | null | boolean) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };


  const handleSubmit = () => {
   AddTow();
 };
  const redirectTOEDitMOde = (vehicleId: string) => {
    navigation(`/incidents/${incidentId}/vehicles/${vehicleId}/edit`);
  };
  const AddTow = async () => {
    if (incidentId && vehicleId) {
      await addTowFunction.addTow(
        setLoading,
        formData,
        incidentId,
        vehicleId,
        redirectTOEDitMOde
      );
      
   
    }
  };
  
  const initialRender = () => {
    addVehicleFunction.getStateData(setStateData, setLoading);
 addVehicleFunction.getStreetPrefix(setVehicleStreetPrefix, setLoading);  
 addVehicleFunction.getStreetPSuffix(setVehicleStreetSuffix, setLoading);
 addTowFunction.getCompany(setCompany, setLoading);
 addTowFunction.getTow(setTow, setLoading);
 addTowFunction.getOwnerInvolvement(setInvolvement, setLoading);
 addVehicleFunction.getCountry(setCountry, setLoading);
    addVehicleFunction.getAddresType(setAddressTypeData, setLoading);
    if (incidentId && vehicleId) {
    
            addTowFunction.getTowData(
                setLoading,
                setFormData,
                incidentId,
                vehicleId,
              );
        
     
    }
  };
  useEffect(() => {
    if(incidentId && vehicleId){
        initialRender();
    }
  
  }, []);
  useEffect(() => {
    if (incidentId) {
      dispatch(setIncidentId(incidentId));
    }
  }, [incidentId]);
 const oncancel=()=>{
    setFormData({
        TowId: "",
        ReasonForImpound: "",
        TowReleaseDateTime: "",
        NotificationMethodId: "",
        OwnerNotifiedOfImpound: false,
        NotificationMeans: "",
        NotificationDateTime: "",
        CanVehicleBeReleased: false,
        ReasonForHold: "",
        TowCompanyId: "",
        TowInstructions: "",
        OwnerUnknown: false,
        TowLocationId: "",
        TowDestination: "",
        ProofOfInsurance: false,
        ToRegisteredOwner: false,
        Towed: false,
        Trailered: false,
        WithTitle: false,
        WithCurrentTabs: false,
        ToLicensedDriver: false,
        Other: "",
        SameAsTowCompany: false,
        LocationId: "",
        VehicleTowId: "",
        VehicleIncidentId: "",
        Involvement: "",
        OdometerReading: 0,
        DoorsLocked: false,
        IgnitionLocked: false,
        TrunkLocked: false,
        TowReleaseTime: "",
        KeyLocation: "",
        TowCompanyOther:"",
        KeysWithVehicle: false,
        KeysInIgnition: false,
        OwnerAllowUse: false,
        OwnerInvolvement: "",
        VehicleCondition: "",
        PlatesTaken: false,
        EstimatedValue: 0,
        VehicleInventory: "",
        SeizedProperty: false,
        SeizedForForfeiture: false,
        ReleasedToOwner: false,
        TabStolenDetails: "",
        VehicleWanted: false,
        ForfeitureId: "",
        TabStolen: false,
        ForfeitureVehicleStorageLocationId: "",
        PlateStolen: false,
        AddressType: "",
        IsPrimary: false,
        LocationCategoryCode: "",
        LocationName: "",
        Description: "",
        Intersection: false,
        HouseNumber: "",
        NotificationTime: "",
        Ncicstreet1PrefixId: "",
        Street1Name: "",
        Ncicstreet1SuffixId: "",
        Ncicstreet2PrefixId: "",
        Street2Name: "",
        Ncicstreet2SuffixId: "",
        City: "",
        NcicstateId: "",
        ZipCode: "",
        Grid: "",
        NciccountryId: "",
        Address1: "",
        Address2: "",
        OtherCity: "",
        OtherState: "",
        OtherZip: "",
        OtherCountry: "",
        IsForeignAddress: false,
        StateName: "",
        CountryName: "",
        Street1PrefixName: "",
        Street1SuffixName: "",
        Street2PrefixName: "",
        Street2SuffixName: "",
      })
 }
  return (
    <>
    <Loader loading={loading}/>
      <div className="_tow_vehicle_form_container">
        <div className="_add_vehicle_form_desc_title">
          <p>Tow Company</p>
        </div>

        <div className="_tow_filter_dropdown_container">
          <div className="_tow_comp_dropdown" style={{ display:"flex",flexDirection:"row",gap:"15px",width:"20%"}}>
            <DropdownComponent
              value={formData.TowCompanyId}
              dataSource={Tow.map((val) => {
                return {
                  id: val.AgencyLevelCodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e,val) => {
                setOther(`${val}`)
                handleInputChange("TowCompanyId", e);
              }}
              fieldName="Tow Company"
            />
             {other==='Other' && (
        <div className="width_size">
          <TextBoxComponent fieldName="Other" value={formData.TowCompanyOther} onChangeValue={(newValue) => {
                  handleInputChange("TowCompanyOther", newValue);
                }}/>
        </div>
      )}
          </div>
        </div>

        <div className="_tow_instruction_text_Area">
          <TextAreaComponent
            value={formData.TowInstructions}
            fieldName="Tow Instructions"
            onChangeValue={(newValue) => {
                if(newValue.length< 250){
                    console.log(newValue.length)
                    handleInputChange("TowInstructions", newValue);
                }
              
            }}
          />
        </div>

        <div className="_tow_meta_info_form_container">
          <div className="_tow_information_container">
            <div className="_vehicle_tow_information">
              <div className="_tow_metadata_form_overview">
                <div className="_add_vehicle_form_desc_title">
                  <p>Tow Information</p>
                </div>

                <div className="_tow_vehicle_meta_info_container">
                  <div className="_tow_destination_data_form">
                    <TextBoxComponent
                      fieldName="Tow Destination"
                      value={formData.TowDestination}
                      onChangeValue={(newValue) => {
                        handleInputChange("TowDestination", newValue);
                      }}
                    />
                  </div>
                </div>

                <div className="multiple_vehicle_owner_container">
                  <div className="_owner_address_type_container">
                    <div className="_address_type_container">
                      <DropdownComponent
                        value={formData.AddressType}
                        dataSource={AddressTypeData.map((val) => {
                          return {
                            id: val.NciccodeId,
                            value: val.LongCode,
                          };
                        })}
                        handleRowClick={(e) => {
                          handleInputChange("AddressType", e);
                        }}
                        fieldName="Address Type"
                      />
                    </div>

                    <div className="">
                      <CheckBoxComponent
                        fieldName="Same as Tow Company"
                        value={formData.SameAsTowCompany}
                        onChangeValue={(e) => {
                            handleInputChange("SameAsTowCompany", e);
                          }}
                      />
                    </div>
                  </div>

                  <div className="involvemt_street_inline_container">
                    <div className=" lien_owner_street_address">
                      <div className="_involvement_meta_info">
                        <TextBoxComponent
                          value={formData.HouseNumber}
                          fieldName="Street Number"
                          onChangeValue={(newValue) => {
                            handleInputChange("HouseNumber", newValue);
                          }}
                        />
                      </div>

                      <div className="_involvement_meta_info">
                        <DropdownComponent
                          handleRowClick={(newValue) => {
                            handleInputChange("Ncicstreet1PrefixId", newValue);
                          }}
                          dataSource={vehicleStreetPrefix.map((state) => ({
                            id: state.NciccodeId,
                            value: state.LongCode,
                          }))}
                          value={formData.Ncicstreet1PrefixId}
                          fieldName="Street Prefix"
                        />
                      </div>
                    </div>
                    <div className=" lien_owner_street_address">
                      <div className="involvemet_street_container">
                        <TextBoxComponent
                          value={formData.Street1Name}
                          onChangeValue={(newValue) => {
                            handleInputChange("Street1Name", newValue);
                          }}
                          fieldName="Street"
                        />
                      </div>

                      <div className="_involvement_meta_info">
                        <DropdownComponent
                          handleRowClick={(newValue) => {
                            handleInputChange("Ncicstreet1SuffixId", newValue);
                          }}
                          dataSource={vehicleStreetSuffix.map((state) => ({
                            id: state.NciccodeId,
                            value: state.LongCode,
                          }))}
                          value={formData.Ncicstreet1SuffixId}
                          fieldName="Street Suffix"
                        />
                      </div>
                      <div className="_involvement_meta_info">
                        <TextBoxComponent
                          value={formData.Street2Name}
                          onChangeValue={(newValue) => {
                            handleInputChange("Street2Name", newValue);
                          }}
                          fieldName="Apartment"
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" vehicle_address_city_address_container">
                    <div className="_address_state_providence">
                      <TextBoxComponent
                        value={formData.City}
                        onChangeValue={(newValue) => {
                          handleInputChange("City", newValue);
                        }}
                        fieldName="City"
                      />
                    </div>

                    <div className="_address_state_providence">
                      <DropdownComponent
                        handleRowClick={(newValue) => {
                          handleInputChange("NcicstateId", newValue);
                        }}
                        dataSource={stateData.map((state) => ({
                          id: state.NciccodeId,
                          value: state.LongCode,
                        }))}
                        value={formData.NcicstateId}
                        fieldName="State / Providence"
                      />
                    </div>

                    <div className="_address_zip_code">
                      <TextBoxComponent
                        value={formData.ZipCode}
                        onChangeValue={(newValue) => {
                          handleInputChange("ZipCode", newValue);
                        }}
                        fieldName="Zip Code"
                      />
                    </div>
                  </div>

                  <div className="_vehicle_country_grid_container">
                    <div className="_address_country_container">
                      <DropdownComponent
                        handleRowClick={(newValue) => {
                          handleInputChange("NciccountryId", newValue);
                        }}
                        dataSource={country.map((state) => ({
                          id: state.NciccodeId,
                          value: state.LongCode,
                        }))}
                        value={formData.NciccountryId}
                        fieldName="Country"
                      />
                    </div>

                    <div className="_address_grid_number">
                      <TextBoxComponent
                        value={formData.Grid}
                        onChangeValue={(newValue) => {
                            if(newValue.length<6){
                                handleInputChange("Grid", newValue);
                            }
                          
                        }}
                        fieldName="Grid Number"
                      />
                    </div>
                  </div>
                </div>

                <div className="_toggle_checkbox_container">
                  <div className="_tow_trailed_container">
                    <div className="towed_checkbox_component">
                      <CheckBoxComponent
                        value={formData.Towed}
                        onChangeValue={(e) => {
                            handleInputChange("Towed", e);
                          }}
                        fieldName="Towed"
                      />
                    </div>
                    <div className="towed_checkbox_component">
                      <CheckBoxComponent
                        value={formData.Trailered}
                        onChangeValue={(e) => {
                            handleInputChange("Trailered", e);
                          }}
                        fieldName="Trailed"
                      />
                    </div>
                  </div>

                  <div className="_insurance_title_with_tabs_container">
                    <div className="_tow_info_meta_checkbox">
                      <CheckBoxComponent
                        fieldName="Proof of Insurance"
                        value={formData.ProofOfInsurance}
                        onChangeValue={(e) => {
                            handleInputChange("ProofOfInsurance", e);
                          }}
                      />
                    </div>
                    <div className="_tow_info_meta_checkbox">
                      <CheckBoxComponent
                        fieldName="With Title"
                        value={formData.WithTitle}
                        onChangeValue={(e) => {
                            handleInputChange("WithTitle", e);
                          }}
                      />
                    </div>
                    <div className="_tow_info_meta_checkbox">
                      <CheckBoxComponent
                        fieldName="With Current Tabs"
                        value={formData.WithCurrentTabs}
                        onChangeValue={(e) => {
                            handleInputChange("WithCurrentTabs", e);
                          }}
                      />
                    </div>
                  </div>

                  <div className="_licensed_driver_registered_owner_container">
                    <div className="_tow_info_meta_checkbox">
                      <CheckBoxComponent
                        fieldName="To Licensed Driver"
                        value={formData.ToLicensedDriver}
                        onChangeValue={(e) => {
                            handleInputChange("ToLicensedDriver", e);
                          }}
                      />
                    </div>
                    <div className="_tow_info_meta_checkbox">
                      <CheckBoxComponent
                        fieldName="To Registered Owner"
                        value={formData.ToRegisteredOwner}
                        onChangeValue={(e) => {
                            handleInputChange("ToRegisteredOwner", e);
                          }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="_vehicle_impound_container">
              <div className="__tow_metadata_form_overview">
                <div className="_add_vehicle_form_desc_title">
                  <p>Impound</p>
                </div>

                <div className="_tow_vehicle_meta_info_container">
                  <div className="_tow_destination_data_form impound_text_box">
                    <TextBoxComponent
                      fieldName="Reason for Impound"
                      value={formData.ReasonForImpound}
                      onChangeValue={(newValue) => {
                        handleInputChange("ReasonForImpound", newValue);
                      }}
                    />
                  </div>
                  <div className="_tow_destination_data_form impound_text_box">
                    <TextBoxComponent
                      fieldName="Reason for Hold"
                      value={formData.ReasonForHold}
                      onChangeValue={(newValue) => {
                        handleInputChange("ReasonForHold", newValue);
                      }}
                    />
                  </div>
                </div>

                <div className="_toggle_checkbox_container owner_notified_and_unknow_container">
                  <div className="_tow_info_meta_checkbox">
                    <CheckBoxComponent
                      fieldName="Owner Notified of impound"
                      value={formData.OwnerNotifiedOfImpound}
                      onChangeValue={(e) => {
                        handleInputChange("OwnerNotifiedOfImpound", e);
                      }}
                    />
                  </div>
                  <div className="_tow_info_meta_checkbox">
                    <CheckBoxComponent
                      fieldName="Owner Unknown"
                      value={formData.OwnerUnknown}
                      onChangeValue={(e) => {
                        handleInputChange("OwnerUnknown", e);
                      }}
                    />
                  </div>
                </div>

                <div className="_impound_notification_container">
                  <div className="_notification_means_container">
                    <DropdownComponent
                      fieldName="Notification Method"
                      value={formData.NotificationMethodId}
                      dataSource={company.map((val) => {
                        return {
                          id: val.SystemLevelCodeId,
                          value: val.LongCode,
                        };
                      })}
                      handleRowClick={(e) => {
                        handleInputChange("NotificationMethodId", e);
                      }}
                    />
                  </div>

                  <div className="_notification_date_container">
                    <DateBoxComponents
                      fieldName="Notification Date"
                      value={
                        formData.NotificationDateTime
                          ? formatDate(formData.NotificationDateTime)
                          : ""
                      }
                      onValueChange={(newValue) => {
                        handleInputChange("NotificationDateTime", newValue);
                      }}
                    />
                  </div>

                  <div className="_notification_time_container">
                    <TimePickerComponenets
                      fieldName="Notification Time"
                      value={formData.NotificationTime}
                      onValueChange={(newValue) => {
                        handleInputChange("NotificationTime", newValue);
                      }}
                    />
                  </div>
                </div>

                <div className="_impound_release_container">
                  <div className="_vehicle_release_checkbox_container">
                    <CheckBoxComponent
                      fieldName="Vehicle Can be Released"
                      value={formData.CanVehicleBeReleased}
                      onChangeValue={(e) => {
                        handleInputChange("CanVehicleBeReleased", e);
                      }}
                    />
                  </div>
                  <div className="_notification_date_container">
                    <DateBoxComponents
                      fieldName="Tow Release Date"
                      value={
                        formData.TowReleaseDateTime
                      }
                      onValueChange={(newValue) => {
                        handleInputChange("TowReleaseDateTime", newValue);
                      }}
                    />
                  </div>

                  <div className="_notification_time_container">
                    <TimePickerComponenets
                      fieldName="Tow Release Time"
                      value={formData.TowReleaseTime}
                      onValueChange={(newValue) => {
                        handleInputChange("TowReleaseTime", newValue);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="_impound_information_container">
              <div className="_add_vehicle_form_desc_title">
                <p>Impound Information</p>
              </div>
              <div className="_vehicle_wanted_checkbox_container">
                <CheckBoxComponent
                  fieldName="Vehicle Wanted"
                  value={formData.VehicleWanted}
                  onChangeValue={(e) => {
                    handleInputChange("VehicleWanted", e);
                  }}
                />
              </div>

              <div className="_owner_involvement_meta_info_container">
                <div className="_owner_involvement_dropdown_conatiner">
                  <DropdownComponent
                    value={formData.OwnerInvolvement}
                    dataSource={Involvement.map((val) => {
                      return {
                        id: val.SystemLevelCodeId,
                        value: val.LongCode,
                      };
                    })}
                    handleRowClick={(e) => {
                        handleInputChange("OwnerInvolvement", e);
                    }}
                    fieldName="Owner Involvement"
                  />
                </div>

                <div className="_owner_allow_use_checkbox _vehicle_wanted_checkbox_container">
                  <CheckBoxComponent
                    fieldName="Owner Allow Use"
                    value={formData.OwnerAllowUse}
                    onChangeValue={(e) => {
                        handleInputChange("OwnerAllowUse", e);
                      }}
                  />
                </div>

                <div className="_release_to_owner _vehicle_wanted_checkbox_container">
                  <CheckBoxComponent
                    fieldName="Release to Owner"
                    value={formData.ReleasedToOwner}
                    onChangeValue={(e) => {
                        handleInputChange("ReleasedToOwner", e);
                      }}
                  />
                </div>
              </div>

              <div className="_vehile_physical_meta_info_container">
                <div className="_physicla_meta_container">
                  <NumberBoxComponents
                    value={formData.OdometerReading}
                    onValueChange={(e) => {
                      handleInputChange("OdometerReading", e);
                    }}
                    fieldName="Odometer Reading"
                  />
                </div>

                <div className="_physicla_meta_container">
                  <NumberBoxComponents
                    value={formData.EstimatedValue}
                    onValueChange={(e) => {
                      handleInputChange("EstimatedValue", e);
                    }}
                    fieldName="Estimated Value"
                  />
                </div>

                <div className="_physicla_meta_container">
                  <TextBoxComponent
                    value={formData.KeyLocation}
                    onChangeValue={(newValue) => {
                      handleInputChange("KeyLocation", newValue);
                    }}
                    fieldName="Key Location"
                  />
                </div>

                <div className="_key_vehicle_checkbox">
                  <CheckBoxComponent
                    value={formData.KeysWithVehicle}
                    onChangeValue={(e) => {
                        handleInputChange("KeysWithVehicle", e);
                      }}
                    fieldName="Keys with Vehicle"
                  />
                </div>
              </div>

              <div className="_checkbox_locked_stolen_checkbox_container">
                <div className="_impound_info_toggle_meta_infos_container">
                  <div className="_meta_infos_checkbox_container _tow_meta_infos_checkbox_container">
                    <CheckBoxComponent
                      fieldName="Ignition Locked"
                      value={formData.IgnitionLocked}
                      onChangeValue={(e) => {
                        handleInputChange("IgnitionLocked", e);
                      }}
                    />
                  </div>
                  <div className="_meta_infos_checkbox_container _tow_meta_infos_checkbox_container">
                    <CheckBoxComponent
                      fieldName="Truck Locked"
                      value={formData.TrunkLocked}
                      onChangeValue={(e) => {
                        handleInputChange("TrunkLocked", e);
                      }}
                    />
                  </div>
                  <div className="_meta_infos_checkbox_container _tow_meta_infos_checkbox_container">
                    <CheckBoxComponent
                      fieldName="Plate Stolen"
                      value={formData.PlateStolen}
                      onChangeValue={(e) => {
                        handleInputChange("PlateStolen", e);
                      }}
                    />
                  </div>
                </div>
                <div className="_impound_info_toggle_meta_infos_container">
                  <div className="_meta_infos_checkbox_container _tow_meta_infos_checkbox_container">
                    <CheckBoxComponent
                      fieldName="Doors Locked"
                      value={formData.DoorsLocked}
                      onChangeValue={(e) => {
                        handleInputChange("DoorsLocked", e);
                      }}
                    />
                  </div>
                  <div className="_meta_infos_checkbox_container _tow_meta_infos_checkbox_container">
                    <CheckBoxComponent
                      fieldName="Plates Taken"
                      value={formData.PlatesTaken}
                      onChangeValue={(e) => {
                        handleInputChange("PlatesTaken", e);
                      }}
                    />
                  </div>
                  <div className="_meta_infos_checkbox_container _tow_meta_infos_checkbox_container">
                    <CheckBoxComponent
                      fieldName="Tab Stolen"
                      value={formData.TabStolen}
                      onChangeValue={(e) => {
                        handleInputChange("TabStolen", e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="_pre_existing_conditions">
                <div className="_pre-existing_condition_dropdown">
                  <TextAreaComponent
                    fieldName="Vehicle Condition"
                    value={formData.VehicleCondition}
                    onChangeValue={(newValue) => {
                        if(newValue.length<100){
 handleInputChange("VehicleCondition", newValue);
                        }
                     
                    }}
                  />
                </div>
              </div>

              <div className="_forfeiture_vehicle_container">
                <div className="_seized_property_container">
                  <CheckBoxComponent
                    fieldName="Seized Property"
                    value={formData.SeizedProperty}
                    onChangeValue={(e) => {
                        handleInputChange("SeizedProperty", e);
                      }}
                  />
                </div>
              </div>

              <div className="_vehicle_inventory_container">
                <div className="_vehicle_Inventory_container">
                  <TextAreaComponent
                    fieldName="Vehicle Inventory"
                    value={formData.VehicleInventory}
                    onChangeValue={(newValue) => {
                        if(newValue.length< 100){
handleInputChange("VehicleInventory", newValue);
                        }
                      
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="submit_btn_container">
        <Submit
          onSave={handleSubmit}
          saveColor="#3672B3
"
onCancel={oncancel}
        />
      </div>
    </>
  );
};

export default TowForm;
