import { IdentificationIProps } from "../../IdentificationIProps";
import { MultiIdentification } from "./MultipleIdentification";

export const Identification: React.FC<IdentificationIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  return (
    <>
      <div style={{ width: "100%" }}>
        <MultiIdentification
          Mainfields={Mainfields}
          setMainfields={setMainfields}
        />
      </div>
    </>
  );
};
