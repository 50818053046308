import { Fetcher } from "../../../../services/Fetcher";
import { formatDate } from "../../../../utils/Helper/Helper";
import { OrderByFieldName } from "./config";
import {
  getIncidentListTableIRes,
  OrderByType,
  SetTableData,
} from "./IncidentListI";
const fetcher = new Fetcher();

export class IncidentListFunction {
  async getIncidentListTable(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    PageSize: number,
    PageNumber: number,
    IncidentNumber: string,
    OrderByDescending?: boolean,
    OrderBy?: string
  ) {
    setLoading(true);
    let response = await fetcher.post(`/incident/search`, {
      PageNumber: PageNumber,
      PageSize: PageSize,
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
      IncidentNumber: IncidentNumber,
    });
    const TableJson = this.createTableJson(response?.Data);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: getIncidentListTableIRes[]) {
    return data.map((val: getIncidentListTableIRes, i: number) => {
      return {
        "Incident Number": val.IncidentNumber,
        "Modified On": formatDate(val.ModifiedOn),
        IncidentId: val.IncidentId,
        "Report Date": val.ReportDateTime ? formatDate(val.ReportDateTime) : "",
      };
    });
  }
}
