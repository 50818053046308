import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface initialStateI {
  viewRoleComponent: boolean;
  RoleName: string;
  Description:string;
  Status: string;
  editRoleComponent:boolean;
  addRoleComponent:boolean;
  ModifiedByUserName:string;
  ModifiedOn:string;
}

interface setPolicyeditComponentPayload {
  editRoleComponent: boolean;
  addRoleComponent: boolean;
  viewRoleComponent: boolean;
}

interface setPolicydataPayload {
  RoleName: string;
  Description: string;
  Status: string;
  ModifiedByUserName:string;
  ModifiedOn:string;
}

const initialState: initialStateI = {
  editRoleComponent: false,
  addRoleComponent: true,
  viewRoleComponent: false,
  RoleName: "",
  Description:"",
  Status: "1",
  ModifiedByUserName:"",
  ModifiedOn:"",
};

//slice

const slice = createSlice({
  name: "RoleReducer",
  initialState,
  reducers: {
    setRoleeditComponent(
      state,
      action: PayloadAction<setPolicyeditComponentPayload>
    ) {
      state.viewRoleComponent = action.payload.viewRoleComponent;
    },

    setRoledata(state, action: PayloadAction<setPolicydataPayload>) {
      state.RoleName = action.payload.RoleName;
      state.Status = action.payload.Status;
      state.Description=action.payload.Description;
      state.ModifiedByUserName=action.payload.ModifiedByUserName;
      state.ModifiedOn=action.payload.ModifiedOn;
    },
  },
});

export const { setRoleeditComponent, setRoledata } = slice.actions;

export default slice.reducer;
