import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { MaritalStatusIProps } from "./MaritalStatusI";

export const MaritalStatus: React.FC<MaritalStatusIProps> = ({
  Mainfields,
  setMainfields,
  MaritalStatusTypeData,
}) => {
  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        [name]: value,
        changes: true,
      },
    });
  };
  return (
    <>
      <div className="width_field">
        <div className="Involvement_left">
          <div>
            <p className="subheader">Marital Status</p>
            <div style={{ marginLeft: "5%" }} className="width_field">
              <DropdownComponent
                fieldName="Marital Status"
                value={Mainfields.AdditionalInfo.MaritalStatus}
                dataSource={MaritalStatusTypeData.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                handleRowClick={(e) => {
                  onChangeValue("MaritalStatus", e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
