import { Fetcher } from "../../../../../../../services/Fetcher";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../../utils/const";
import { setMultiplePhoneNumberListIState } from "../../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumberI";
import {
  setFormDataValidation,
  setformFieldsIState,
} from "./AddEditViewEmergencyContactI";

const fetcher = new Fetcher();

export class AddEditViewEmergencyContactFunction {
  async addEmergencyContact(
    setLoading: (data: boolean) => void,
    formData: setformFieldsIState,
    userId: string,
    setShowAddEmergency: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.post(
      `/user/${userId}/emergency-contact`,
      {
        ...formData,
        UserId: userId,
        Suffix: undefined,
        Contacts: formData.Contacts.map(
          (val: setMultiplePhoneNumberListIState) => {
            return {
              ContactMethodCodeId: val.ContactMethodCodeId,
              ContactValue: val.ContactValue,
              IsPrimary: val.IsPrimary,
            };
          }
        ),
        SuffixCodeId: formData.Suffix,
        PrimaryContact: formData.PrimaryContact == "1" ? true : false,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.EmergencyContactId) {
      setShowAddEmergency(false);
    }
  }

  async editEmergencyContact(
    setLoading: (data: boolean) => void,
    formData: setformFieldsIState,
    userId: string,
    setShowAddEmergency: (data: boolean) => void,
    EmergencyContactId: string,
    AgencyId: string
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/user/${userId}/emergency-contact`,
      {
        ...formData,
        UserId: userId,
        PrimaryContact: formData.PrimaryContact == "1" ? true : false,
        EmergencyContactId,
        Suffix: undefined,
        SuffixCodeId: formData.Suffix,
        AgencyId: AgencyId,
      },
      {
        message: successUpdateMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.EmergencyContactId) {
      setShowAddEmergency(false);
    }
  }

  async errorValidation(
    formData: setformFieldsIState,
    setFormDataValidation: (data: setFormDataValidation) => void
  ) {
    let valdationerror: setFormDataValidation = {
      PrimaryContact: false,
      Relation: false,
      LastName: false,
      FirstName: false,
      MiddleName: false,
      Suffix: false,
      StreetAddress: false,
      City: false,
      StateCodeId: false,
      ZipCode: false,
      Email: false,
    };
    if (!formData.PrimaryContact) {
      valdationerror.PrimaryContact = true;
    } else {
      valdationerror.PrimaryContact = false;
    }

    if (!formData.Relation) {
      valdationerror.Relation = true;
    } else {
      valdationerror.Relation = false;
    }

    if (!formData.LastName) {
      valdationerror.LastName = true;
    } else {
      valdationerror.LastName = false;
    }

    if (!formData.FirstName) {
      valdationerror.FirstName = true;
    } else {
      valdationerror.FirstName = false;
    }

    if (!formData.StreetAddress) {
      valdationerror.StreetAddress = true;
    } else {
      valdationerror.StreetAddress = false;
    }

    if (!formData.City) {
      valdationerror.City = true;
    } else {
      valdationerror.City = false;
    }

    if (!formData.StateCodeId) {
      valdationerror.StateCodeId = true;
    } else {
      valdationerror.StateCodeId = false;
    }

    if (!formData.ZipCode) {
      valdationerror.ZipCode = true;
    } else {
      valdationerror.ZipCode = false;
    }

    setFormDataValidation({ ...valdationerror });
  }

  checkRequiredFields(formData: setformFieldsIState) {
    return (
      formData.PrimaryContact &&
      formData.Relation &&
      formData.LastName &&
      formData.FirstName &&
      formData.StreetAddress &&
      formData.City &&
      formData.StateCodeId &&
      formData.ZipCode
    );
  }
}
