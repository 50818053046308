import React, { useEffect, useState } from "react";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { MasterPhone } from "../MasterPhone";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import {
  DetailedSearchData,
  SetTableData,
} from "../../MasterNameIndexListingI";
import { DetailedSearchFunction } from "./DetailedSearchFunction";
import { GetRaceDataIState } from "../../../../Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { masteraddressIState } from "../Address/AddressIState";
import { ParticipantListFunction } from "../../MasterNameIndexFunction";
import { MaskedInputComponent } from "../../../../../CommonComponents/Fields/MaskedInput/MaskedInputComponent";
import { InvolvementMainFunction } from "../../../../Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainFunction";
import { PhoneNumberFunction } from "../../../../Incidents/Participant/AddEditParticipantName/AddNewName/ExternalContact/Components/Phone/PhoneNumberFunction";
import { setContactTypeDataIState } from "../../../../Incidents/Participant/AddEditParticipantName/AddNewName/IdentificationContact/IdentificationIProps";

interface DetailedSearchProps {
  formData: DetailedSearchData;
  setFormData: (data: DetailedSearchData) => void;
  onSearch: () => void;
  handleCloseModal: () => void;
}

const DetailedSearch: React.FC<DetailedSearchProps> = ({
  formData,
  setFormData,
  onSearch,
  handleCloseModal,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const detailedSearchFunction = new DetailedSearchFunction();
  const masterNameFunction = new ParticipantListFunction();
  const [AddressTypeData, setAddressTypeData] = useState<GetRaceDataIState[]>(
    []
  );
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [State, setState] = useState<GetRaceDataIState[]>([]);
  const [Suffix, setSuffix] = useState<GetRaceDataIState[]>([]);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("");
  const involvementMainFunction = new InvolvementMainFunction();
  const phoneNumberFunction = new PhoneNumberFunction();
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);
  const [onchange, setonchange] = useState<boolean>(false);
  const [Race, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [Sex, setSex] = useState<GetRaceDataIState[]>([]);
  const onHandelValueChange = (
    field: string,
    value: number | string | boolean
  ) => {
    setFormData({
      ...formData,
      [field]: value,
    });

    if (String(value ? value : "").trim()) {
      setonchange(true);
    }

    // detailedSearchFunction.onchangeValide(formData, setonchange);
  };

  // useEffect(() => {
  //   detailedSearchFunction.onchangeValide(formData, setonchange);
  // }, [formData]);

  useEffect(() => {
    detailedSearchFunction.getAddresType(setAddressTypeData);
    detailedSearchFunction.getStreetPrefix(setStreetPrefix);
    detailedSearchFunction.getStreetSuffix(setStreetSuffix);
    detailedSearchFunction.getState(setState);
    detailedSearchFunction.getNameSuffix(setSuffix);
    involvementMainFunction.getCountry(setCountry);
    phoneNumberFunction.getContactTypeData(setContactTypeData);
    involvementMainFunction.getRace(setRaceDataData);
    involvementMainFunction.getSex(setSex);
  }, []);

  const handleSubmit = async () => {
    await onSearch();
    handleCloseModal();
    // setTableData(tableData)
  };

  const getTable = () => {
    setLoading(true);
    masterNameFunction.getMasterListTableData(
      setTableData,
      setLoading,
      setTableTotalRecord,
      pageNumber,
      pageCount,
      formData.PersonSurName || "",
      formData.EntityType,
      OrderByDescending,
      OrderBy
    );
  };

  // useEffect(() => {
  //   getTable();
  // }, [pageNumber, pageCount, OrderByDescending]);

  return (
    <div className="width_size">
      <p
        style={{
          textAlign: "center",
          marginTop: "0%",
        }}
        className="header"
      >
        Master Name Index Detailed Search
      </p>
      <div className="detailed">
        <div className="Involvement_left_row1">
          <TextBoxComponent
            fieldName="Last Name"
            value={formData.PersonSurName || ""}
            onChangeValue={(e) => {
              onHandelValueChange("PersonSurName", e);
            }}
          />
          <TextBoxComponent
            fieldName="First Name"
            value={formData.PersonGivenName || ""}
            onChangeValue={(e) => {
              onHandelValueChange("PersonGivenName", e);
            }}
          />
          <TextBoxComponent
            fieldName="Middle Name"
            value={formData.PersonMiddleName || ""}
            onChangeValue={(e) => {
              onHandelValueChange("PersonMiddleName", e);
            }}
          />
        </div>
        <div className="detailed_">
          <DropdownComponent
            fieldName="Suffix"
            value={formData.SuffixCodeId || ""}
            handleRowClick={(e) => {
              onHandelValueChange("SuffixCodeId", e);
            }}
            dataSource={Suffix.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
          <DateBoxComponents
            fieldName="DOB"
            onValueChange={(e: any) => {
              onHandelValueChange("DOB", e);
            }}
            value={formData.DOB}
          />

          <TextBoxComponent
            fieldName="Age"
            value={formData.Age || ""}
            onChangeValue={(e) => {
              onHandelValueChange("Age", e);
            }}
          />
        </div>
      </div>
      <div className="Involvement_left_row">
        <div className="Involvement_main_">
          <DropdownComponent fieldName="Sex" dataSource={[]} />

          <DropdownComponent
            fieldName="Race"
            value={formData.RaceCodeId || ""}
            dataSource={Race.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
            handleRowClick={(e) => {
              onHandelValueChange("RaceCodeId", e);
            }}
          />
          {/* <DropdownComponent
            value={formData.Gender || ""}
            fieldName="Gender"
            handleRowClick={(e) => {
              onHandelValueChange("Gender", e);
            }}
            dataSource={Sex.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          /> */}
          <TextBoxComponent
            fieldName="Height"
            value={formData?.Height !== null ? formData?.Height.toString() : ""}
            onChangeValue={(e) => {
              onHandelValueChange("Height", e);
            }}
          />
          <TextBoxComponent
            fieldName="Weight"
            value={formData?.Weight !== null ? formData?.Weight.toString() : ""}
            onChangeValue={(e) => {
              onHandelValueChange("Weight", e);
            }}
          />
        </div>
      </div>{" "}
      <div className="Involvement_left_row" style={{ width: "100%" }}>
        <div className="AddressSCroll" style={{ width: "60%" }}>
          <p className="header">Address</p>
          <div className="Involvement_left_row_checkbox">
            <div className="master">
              <DropdownComponent
                value={formData.AddressType || ""}
                dataSource={AddressTypeData.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                handleRowClick={(e) => {
                  onHandelValueChange("AddressType", e);
                }}
                fieldName="Address Type"
              />
              <CheckBoxComponent
                value={formData?.NoPermanentAddress}
                fieldName="No Permanent Address"
                onChangeValue={(e) => {
                  onHandelValueChange("NoPermanentAddress", e);
                }}
              />
              <CheckBoxComponent
                value={formData?.UnknownAddress}
                fieldName="Unknown Address"
                onChangeValue={(e) => {
                  onHandelValueChange("UnknownAddress", e);
                }}
              />
              <CheckBoxComponent
                value={formData?.ForeignAddress}
                fieldName="Foreign Address"
                onChangeValue={(e) => {
                  onHandelValueChange("ForeignAddress", e);
                }}
              />
            </div>
          </div>

          <div className="Involvement_left_row">
            <TextBoxComponent
              value={formData.StreetNumber || ""}
              onChangeValue={(e) => {
                onHandelValueChange("StreetNumber", e);
              }}
              fieldName="Street Number"
            />
            <DropdownComponent
              value={formData.StreetPrefix || ""}
              dataSource={StreetPrefix.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onHandelValueChange("StreetPrefix", e);
              }}
              fieldName="Street Prefix"
            />

            <TextBoxComponent
              value={formData.Street || ""}
              fieldName="Street"
              onChangeValue={(e) => {
                onHandelValueChange("Street", e);
              }}
            />

            <DropdownComponent
              value={formData.StreetSuffix || ""}
              dataSource={StreetSuffix.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onHandelValueChange("StreetSuffix", e);
              }}
              fieldName="Street Suffix"
            />
            <TextBoxComponent
              value={formData.Apartment || ""}
              fieldName="Apartment"
              onChangeValue={(e) => {
                onHandelValueChange("Apartment", e);
              }}
            />
          </div>
          <div className="Involvement_left_row"></div>

          <div className="Involvement_left_row">
            <TextBoxComponent
              value={formData.City || ""}
              fieldName="City"
              onChangeValue={(e) => {
                onHandelValueChange("City", e);
              }}
            />
            <DropdownComponent
              value={formData.StateProvidence || ""}
              dataSource={State.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="State / Providence"
              handleRowClick={(e) => {
                onHandelValueChange("StateProvidence", e);
              }}
            />
            <TextBoxComponent
              value={formData.ZipCode || ""}
              onChangeValue={(e) => {
                onHandelValueChange("ZipCode", e);
              }}
              fieldName="Zip Code"
            />
          </div>
          <div className="master">
            <div className="master_">
              <DropdownComponent
                value={formData.Country || ""}
                dataSource={State.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Country"
                handleRowClick={(e) => {
                  onHandelValueChange("Country", e);
                }}
              />
              <div className="">
                <TextBoxComponent
                  value={formData.Grid || ""}
                  onChangeValue={(e) => {
                    const value = e;
                    if (/^\d{0,5}$/.test(value)) {
                      onHandelValueChange("Grid", e);
                    }
                  }}
                  fieldName="Grid Number"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Involvement_left_row" style={{ width: "40%" }}>
          <div className="Involvement_main_">
            <div className="Involvement_left">
              <div>
                <p className="header">Phone Number</p>
                <div className="Involvement_left_row">
                  <DropdownComponent
                    dataSource={ContactTypeData.map((val) => {
                      return {
                        id: val.SystemLevelCodeId,
                        value: val.LongCode,
                      };
                    })}
                    value={formData.PhoneTypeCodeId || ""}
                    handleRowClick={(e) => {
                      onHandelValueChange("PhoneTypeCodeId", e);
                    }}
                    fieldName="Type"
                  />

                  <div>
                    <DropdownComponent
                      dataSource={Country.map((val) => {
                        return {
                          id: val.NciccodeId,
                          value: val.LongCode,
                        };
                      })}
                      value={formData.CountryCodeId || "+1"}
                      handleRowClick={(e) => {
                        onHandelValueChange("CountryCodeId", e);
                      }}
                      fieldName="Country"
                    />
                  </div>

                  {/* <TextBoxComponent
            value={PhoneNumberval.Number}
            fieldName="Number"
            onChangeValue={(e) => {
              onChangeValue("Number", e, PhoneNumberval.id);
            }}
          /> */}

                  <MaskedInputComponent
                    mask="+1 (999)-999-9999"
                    value={formData.PhoneNumber || ""}
                    fieldName="Number"
                    onChangeValue={(e) => {
                      onHandelValueChange("PhoneNumber", e);
                    }}
                  />
                  <TextBoxComponent
                    value={formData.Ext || ""}
                    fieldName="Ext."
                    onChangeValue={(e) => {
                      onHandelValueChange("Ext", e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="clearbutton"
        style={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        {onchange && (
          <ButtonComponents
            name="Search"
            textColor="white"
            showBackgroundColor={true}
            handleClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default DetailedSearch;
