import { Fetcher } from "../../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";
import { VehiclesIRes, VehiclesTableData } from "../ParticipantOverviewIState";

const fetcher = new Fetcher();

export class VehiclesFunction {
  async getVehicles(
    participantId: string,
    incidentId: string,
    setTableData: (data: VehiclesTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/summary-vehicle`
    );
    let res: VehiclesIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: VehiclesIRes[]) {
    return data?.map((val: VehiclesIRes, i: number) => {
      return {
        Case: val.Case,
        Type: val.Type,
        Make: val.Make,
        Model: val.Model,
        Year: val.Year,
        Color: val.Color,
      };
    });
  }
}
