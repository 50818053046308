import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../CommonComponents/Table/Table";
import { Filter } from "../Components/Filter/Filter";
import { column } from "./Config";
import { SetTableData } from "./ListPolicyIState";
import { ListPolicyFunction } from "./ListPolicyFunction";
import { useDispatch } from "react-redux";
import {
  setPolicydata,
  setPolicyeditComponent,
} from "../../../../../Redux/PolicyReducer";
import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import useDelayedEffect from "../../../../../hooks/useDelayedEffect";
import { PolicyDeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/Components/PolicyDeleteModal/PolicyDeleteConfirmation";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

const ListPolicyManagement = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getPolicyFunction = new ListPolicyFunction();
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [Status, setStatus] = useState<string>("1");
  const [PolicyName, setPolicyName] = useState<string>("");
  const [policyId, setpolicyId] = useState<string>("");
  const [data, setData] = useState<{
    [key: string]: { [key: string]: string }[];
  }>({});
  const addPolicy = () => {
    dispatch(
      setPolicyeditComponent({
        viewPolicyComponent: false,
        addPolicyComponent: true,
        editPolicyComponent: false,
      })
    );
    dispatch(
      setPolicydata({
        Description: "",
        PolicyName: "",
        Status: "1",
        ModifiedByUserName: "",
        ModifiedOn: "",
      })
    );
    navigation("/policy/add");
  };

  const GetUserManagementTableData = () => {
    getPolicyFunction.GetPolicyTableData((data) => {
      setFiltertableData(data);
      applyFilterOnUserpolicyTable(data);
      setLoading(false);
    }, setLoading);
  };
  const NavigateToEditPage = (data: SetTableData) => {
    navigation(`/policy/${data.ID}/edit`);
    dispatch(
      setPolicydata({
        Description: data.Description,
        PolicyName: data["Policy Name"],
        Status: data.Status == "Active" ? "1" : "2",
        ModifiedOn: data.ModifiedOn,
        ModifiedByUserName: data.ModifiedByUserName,
      })
    );
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
    setData({});
  };

  const openDeletePopup = (id: string) => {
    setdeletePopup(true);
    setpolicyId(id);
  };
  const handleDelete = async () => {
    await getPolicyFunction.CustomDelete(
      policyId,
      setLoading,
      setdeletePopup,
      setData,
      false
    );
    GetUserManagementTableData();
  };
  const handlePolicyExistDelete = async () => {
    await getPolicyFunction.CustomDelete(
      policyId,
      setLoading,
      setdeletePopup,
      setData,
      true
    );
    GetUserManagementTableData();
  };

  const applyFilterOnUserpolicyTable = (data = FiltertableData) => {
    getPolicyFunction.applyFilterOnUserroleTable(
      Status,
      PolicyName,
      data,
      setTableData
    );
  };
  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [PolicyName, Status]);
  const onRowClicked = (data: SetTableData) => {
    console.log(data);
    if (!deletePopup) {
      navigation(`/policy/${data.ID}/view`);
      dispatch(
        setPolicydata({
          Description: data.Description,
          PolicyName: data["Policy Name"],
          Status: data.Status == "Active" ? "1" : "2",
          ModifiedByUserName: data.ModifiedByUserName,
          ModifiedOn: data.ModifiedOn,
        })
      );
      dispatch(
        setPolicyeditComponent({
          viewPolicyComponent: true,
          addPolicyComponent: true,
          editPolicyComponent: false,
        })
      );
    }
  };
  useEffect(() => {
    GetUserManagementTableData();
  }, []);
  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <PolicyDeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
            showAlreadyUserError={data}
            handlePolicyExistDelete={handlePolicyExistDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="AgencyManagement">
        <div className="Audit-Header">
          <p className="Audit-Header-name">Policy</p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <Filter
              Status={Status}
              setStatus={setStatus}
              PolicyName={PolicyName}
              setPolicyName={setPolicyName}
              addPolicy={addPolicy}
            />
          </div>
        </div>
        <div className="table">
          <Table
            columns={column(NavigateToEditPage, openDeletePopup)}
            columnsValue={tableData}
            showPagination={false}
            onRowClicked={onRowClicked}
            clientSideSorting={true}
            cursorPointer={true}
          />
        </div>
      </div>
    </>
  );
};

export default ListPolicyManagement;
