import { useState } from "react";
import { BusinessTab } from "../../Components/Pages/Incidents/Prosecution/AddProsecution/Components/PhoneNumber/PhoneNumberIProps";
interface SaveChangesPopupHook {
    MainfieldsBusiness: BusinessTab;
    setMainfieldsBusiness: (data: BusinessTab) => void;
}

const useProsecutionIState = (): SaveChangesPopupHook => {
  const [MainfieldsBusiness, setMainfieldsBusiness] = useState<BusinessTab>({
    Involvement: {
      PhoneNumber: [
        {
          Country: "",
          Ext: "",
          ExternalContactID: "",
          id: 1,
          Number: "",
          Type: "",
          TypeDropdownvalue: "",
        },
      ],
     Email:[
        {
  Email:"",
  ExternalContactID:"",
  id:1,
  Type:"",
  TypeDropdownvalue:"",

        }
     ]
    },
  });

  return {
    MainfieldsBusiness,
    setMainfieldsBusiness,
  };
};

export default useProsecutionIState;
