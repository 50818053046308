import { Fetcher } from "../../../../../../../../services/Fetcher";
import {
  InvolvementDetailsIRes,
  InvolvementTableData,
} from "../ParticipantOverviewIState";
const fetcher = new Fetcher();

export class InvolvementFunction {
  async getinvolvement(
    participantId: string,
    incidentId: string,
    setTableData: (data: InvolvementTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/involvements`
    );
    let res: InvolvementDetailsIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: InvolvementDetailsIRes[]) {
    return data?.map((val: InvolvementDetailsIRes, i: number) => {
      return {
        Case: "",
        Agency: val.Agency,
        Title: val.Title,
        Involvement: val.Involvement,
        ICR: val.ICR,
        Type: val.Type,
        Reported: val.ReportedDate,
      };
    });
  }
}
