import { Fetcher } from "../../../../../../services/Fetcher";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../utils/const";
import {
  CategoryAddEditViewFunctionI,
  codecategory,
  SetAddCategoryIState,
  SetFormDataIState,
  setvalidationIState,
} from "./CategoryAddEditViewI";

interface getallcodecategory {
  CodeCategoryId: number;
  AppModule: string;
  CategoryName: string;
  IsActive: boolean;
  CategoryType: string;
  ActiveDate: string;
  ExpiryDate: string;
  Description: string;
  DisplayOrder: number;
}

const fetcher = new Fetcher();
export class CategoryAddEditViewFunction {
  async addCategory(
    setLoading: (data: boolean) => void,
    formData: SetAddCategoryIState,
    setshowAddEditView: (data: boolean) => void,
    calledFromAgencyLevel: boolean
    //AgencyId: number
  ) {
    setLoading(true);
    const payload = {
      CategoryName: formData.Category || "", // Adjust this as needed
      Description: formData.Description,
      IsActive: formData.IsActive == "1" ? true : false,
      //AgencyId : 0
    };
    // if(calledFromAgencyLevel) {
    //   payload.AgencyId = 15
    // }
    const response = await fetcher.post(
      `${calledFromAgencyLevel ? `/category/agency` : "/category/system"}`,
      payload,
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.SystemLevelCodeId) {
      setshowAddEditView(false);
    }
  }

  async editSystemLevel(
    setLoading: (data: boolean) => void,
    formData: SetAddCategoryIState,
    setshowAddEditView: (data: boolean) => void,
    getRowId: string,
    calledFromAgencyLevel: boolean
  ) {
    setLoading(true);

    // Create the payload with conditional AgencyId
    const payload: {
      CategoryName: string;
      Description: string;
      IsActive: boolean;
      CodeCategoryId: string;
      AgencyId?: string;
    } = {
      CategoryName: formData.Category, // Adjust this as needed
      Description: formData.Description,
      IsActive: formData.IsActive === "1" ? true : false,
      CodeCategoryId: getRowId, // Change the name
    };

    // if (calledFromAgencyLevel) {
    //   payload.AgencyId = 15; // Include AgencyId if calledFromAgencyLevel is true
    // }

    try {
      const response = await fetcher.put(
        `${calledFromAgencyLevel ? `/category/agency` : "/category/system"}`,
        payload,
        {
          message: successUpdateMessage,
          show: true,
        }
      );
      setLoading(false);

      if (response?.Data?.CodeCategoryId) {
        setshowAddEditView(false);
      }
    } catch (error) {
      console.error("Error editing category:", error);
      setLoading(false);
    }
  }

  errorValidation(
    formData: SetAddCategoryIState,
    setvalidation: (data: setvalidationIState) => void
  ) {
    let valdationerror: setvalidationIState = {
      Category: false,
      Description: false,
      IsActive: false,
    };
    if (!formData.Category) {
      valdationerror.Category = true;
    } else {
      valdationerror.Category = false;
    }
    if (!formData.Description) {
      valdationerror.Description = true;
    } else {
      valdationerror.Description = false;
    }

    if (!formData.IsActive) {
      valdationerror.IsActive = true;
    } else {
      valdationerror.IsActive = false;
    }
    setvalidation({ ...valdationerror });
  }

  checkRequiredFields(formData: SetAddCategoryIState) {
    return formData.Category && formData.Description && formData.IsActive;
  }

  async getCategoryData(
    setcategoryData: (data: codecategory[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.post("/code/getallcodecategory", {});
    setcategoryData(
      response.Data?.map((val: getallcodecategory) => {
        return {
          CodeCategoryId: val.CodeCategoryId,
          CategoryName: val.CategoryName,
        };
      })
    );

    if (setLoading) {
      setLoading(false);
    }
  }

  async getSystemLevelData(
    setLoading: (data: boolean) => void,
    setFormData: (data: SetAddCategoryIState) => void,
    categoryId: string,
    calledFromAgencyLevel: boolean,
    addprefill?: boolean
  ) {
    setLoading(true);
    let responce = await fetcher.get(
      `${
        calledFromAgencyLevel
          ? `/category/${categoryId}/agency`
          : `/category/${categoryId}/system`
      }`
    );
    let { CategoryName, Description, IsActive } = responce?.Data;
    setFormData({
      Category: CategoryName,
      Description: addprefill ? "" : Description,
      IsActive: IsActive ? "1" : "2",
    });

    setLoading(false);
  }
}
