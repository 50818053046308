import React, { useEffect, useState } from "react";
import close from "../../../../../../assets/icons/close-circle.svg";
import refresh from "../../../../../../assets/icons/refresh-cw.svg";
import { ButtonIState, FilterIProps } from "./FilterIProps";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { AgencyLevelAddEditViewFunction } from "../../../../CodeTables/AgencyLevel/components/AgencyLevelAddEditView/AgencyLevelAddEditViewFunction";
import { codecategory } from "../../../../CodeTables/Category/CategoryInterface";

export const Filter: React.FC<FilterIProps> = ({
  DisplayName,
  setDisplayName,
  Category,
  setCategory,
}) => {
  const agencyLevelAddEditViewFunction = new AgencyLevelAddEditViewFunction();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");

  const clearAllFilters = () => {
    setDisplayName("");
   
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };
  useEffect(() => {
    if (DisplayName.trim() !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [DisplayName]);
  
  const [categoryData, setcategoryData] = useState<codecategory[]>([]);
  useEffect(() => {
    agencyLevelAddEditViewFunction.getallcodecategory(setcategoryData);
  }, []);

  return (
    <>
      <div className="Filter-audit">
        <div className="filds">
          <TextBoxComponent
            fieldName="Permission Name"
            value={DisplayName}
            onChangeValue={setDisplayName}
          />
          {/* <DropdownComponent
            fieldName="Category"
            dataSource={categoryData.map((state) => ({
              id: state.CodeCategoryId,
              value: state.CategoryName,
            }))}
            handleRowClick={setCategory}
            value={Category}
          /> */}
            <div className="clearbutton1">
        <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
        </div>
        </div>
      
      </div>
    </>
  );
};
