import { useEffect, useState } from "react";
import { DateBoxComponents } from "../Fields/DateBox/DateBox";
import { DropdownComponent } from "../Fields/Dropdown/DropdownComponent";
import { MaskedInputComponent } from "../Fields/MaskedInput/MaskedInputComponent";
import { TextAreaComponent } from "../Fields/TextArea/TextAreaComponent";
import { TextBoxComponent } from "../Fields/TextBox/TextBoxComponent";
import {
  FormIProps,
  setFormDataIState,
  setformDataValidationIState,
} from "./FormI";
import { Submit } from "../Fields/Submit/Submit";
import "./Form.css";
import { FormFunction } from "./FormFunction";
import { setchangeTheForm } from "../../../Redux/ValidateRouteReducer";
import { useDispatch } from "react-redux";
import { BackButtonComponent } from "../BackButton/BackButtonComponent";

export const Form: React.FC<FormIProps> = ({
  fieldsListing,
  disabled,
  heading,
  showborder,
  id,
  formBehaviour,
  ChangeformBehaviour,
  defaultValue,
  handleSubmit,
  onCancel,
  BackButtonFunction,
  viewModeUpdate
}) => {
  const formFunction = new FormFunction();
  const [formData, setFormData] = useState<setFormDataIState>(
    defaultValue ? defaultValue : {}
  );

  const [formDataValidation, setformDataValidation] =
    useState<setformDataValidationIState>({});
  const dispatch = useDispatch();

  const onHandelValueChange = (
    key: string,
    value: string | number | boolean | null
  ) => {
    setFormData({ ...formData, [key]: value });
    dispatch(setchangeTheForm(true));
  };

  const Cancel = () => {
    if (defaultValue) {
      setFormData({ ...defaultValue });
    } else {
      setFormData({});
    }
    if (onCancel) {
      onCancel();
    }
    dispatch(setchangeTheForm(false));
  };

  const Save = () => {
    formFunction.errorValidation(
      formData,
      setformDataValidation,
      fieldsListing
    );
    if (formFunction.checkRequiredFields(formData, fieldsListing)) {
      handleSubmit(formData);
      dispatch(setchangeTheForm(false));
    }
  };

  useEffect(() => {
    let a: setformDataValidationIState = {};
    let b: setFormDataIState = {};

    fieldsListing.map((fieldsListingitem) => {
      fieldsListingitem.map((fieldsDetail) => {
        if (fieldsDetail.required) {
          a[fieldsDetail.fieldName] = false;
        }
        b[fieldsDetail.fieldName] = formData[fieldsDetail.fieldName]
          ? formData[fieldsDetail.fieldName]
          : null;
      });
    });
    console.log({ ...b }, formData);
    setFormData({ ...b });
    setformDataValidation({ ...a });
  }, []);

  return (
    <>
      <div className={showborder ? "DetailTab_top" : ""}>
        {showborder && (
          <div className="DetailTab_top_header_">
            <div className="DetailTab_top_header_sub">
              <p>{heading}</p>
              <div className="edit_button_">
                <BackButtonComponent onClick={BackButtonFunction} name="Back" />
                {formBehaviour == "View" && viewModeUpdate ? (
                  <p
                    onClick={ChangeformBehaviour}
                    className="SummaryTab_top_header_edit"
                  >
                    Edit
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
        <div className="custom_Form_main">
          {fieldsListing.map((fieldsListingitem, key1) => {
            return (
              <>
                <div key={key1} className="custom_Form">
                  {fieldsListingitem.map((fieldsDetail, key2) => {
                    return (
                      <>
                        <div
                          key={key2}
                          style={{
                            width:
                              fieldsListingitem.length == 1 ? "40%" : "100%",
                          }}
                        >
                          {fieldsDetail.type == "textbox" && (
                            <>
                              <TextBoxComponent
                                disabled={disabled}
                                value={String(
                                  formData?.[fieldsDetail.fieldName]
                                    ? formData?.[fieldsDetail.fieldName]
                                    : ""
                                )}
                                onChangeValue={(e) => {
                                  onHandelValueChange(
                                    fieldsDetail.fieldName,
                                    e
                                  );
                                }}
                                validation={
                                  formDataValidation?.[fieldsDetail.fieldName]
                                }
                                fieldName={fieldsDetail.label}
                              />
                            </>
                          )}

                          {fieldsDetail.type == "dropdown" &&
                            fieldsDetail.dropdownDataSource && (
                              <>
                                <DropdownComponent
                                  dataSource={fieldsDetail.dropdownDataSource}
                                  disabled={disabled}
                                  fieldName={fieldsDetail.label}
                                  validation={
                                    formDataValidation?.[fieldsDetail.fieldName]
                                  }
                                  value={String(
                                    formData?.[fieldsDetail.fieldName]
                                      ? formData?.[fieldsDetail.fieldName]
                                      : 0
                                  )}
                                  handleRowClick={(e) => {
                                    onHandelValueChange(
                                      fieldsDetail.fieldName,
                                      e
                                    );
                                  }}
                                  showTooltip={false}
                                />
                              </>
                            )}

                          {fieldsDetail.type == "date" && (
                            <>
                              <DateBoxComponents
                                disabled={disabled}
                                fieldName={fieldsDetail.label}
                                validation={
                                  formDataValidation?.[fieldsDetail.fieldName]
                                }
                                value={String(
                                  formData?.[fieldsDetail.fieldName]
                                    ? formData?.[fieldsDetail.fieldName]
                                    : ""
                                )}
                                onValueChange={(e) => {
                                  onHandelValueChange(
                                    fieldsDetail.fieldName,
                                    e
                                  );
                                }}
                              />
                            </>
                          )}

                          {fieldsDetail.type == "masknumber" && (
                            <>
                              <MaskedInputComponent
                                key={key2}
                                disabled={disabled}
                                mask="+1 (999)-999-9999"
                                validation={
                                  formDataValidation?.[fieldsDetail.fieldName]
                                }
                                value={String(
                                  formData?.[fieldsDetail.fieldName]
                                    ? formData?.[fieldsDetail.fieldName]
                                    : ""
                                )}
                                fieldName={fieldsDetail.label}
                                onChangeValue={(e) => {
                                  onHandelValueChange(
                                    fieldsDetail.fieldName,
                                    e
                                  );
                                }}
                              />
                            </>
                          )}

                          {fieldsDetail.type == "textarea" && (
                            <>
                              <TextAreaComponent
                                disabled={disabled}
                                validation={
                                  formDataValidation?.[fieldsDetail.fieldName]
                                }
                                value={String(
                                  formData?.[fieldsDetail.fieldName]
                                    ? formData?.[fieldsDetail.fieldName]
                                    : ""
                                )}
                                fieldName={fieldsDetail.label}
                                onChangeValue={(e) =>
                                  onHandelValueChange(fieldsDetail.fieldName, e)
                                }
                              />
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>

        <div className="edit-button">
          {formBehaviour !== "View" && (
            <Submit onCancel={Cancel} onSave={Save} />
          )}
        </div>
      </div>
    </>
  );
};
