
import { Fetcher } from "../../../../../../services/Fetcher";
import { ContactData } from "../../MasterNameIState";
import { ContactMainIRes } from "./ContactIState";

const fetcher = new Fetcher();

export class ContactMainFunction {
  async getContactMain(
    setTableData: (data: ContactData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    let response = await fetcher.get(
      `/name-master/${nameId}/contacts`
    );
    let res: ContactMainIRes[] = response.Data;
    const TableJson = this.createTableJson(res);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: ContactMainIRes[]) {
    return data?.map((val: ContactMainIRes, i: number) => {
      return {
        Case: val.IncidentNumber,
        Date: val.CreatedOn,
        Type: val.ContactTypeName,
        Contact: val.ContactValue,
      };
    });
  }
}
