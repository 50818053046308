import React, { useEffect, useState } from "react";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { setTableData } from "./NarrativeIState";
import { coloum } from "./Config";
export const Narrative: React.FC = () => {
  const [tableData, setTableData] = useState<setTableData[]>([]);


  return (
    <div className="narrative">
      <div className="overview-container">
        <div className="overview-header">
          <p><strong>Narrative</strong></p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
           headerBackgroundColor="white"
           
        />
      </div>
    </div>
  );
};
