import { Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar/Sidebar";
import "./Layout.css";
import Header from "../Header/Header";

export const Layout: React.FC = () => {
  return (
    <>
      <div className="NavBarContainer1">
        <div>
          <Sidebar />
        </div>
        <div className="allpages">
          <Header />
          <Outlet />
        </div>
      </div>
    </>
  );
};
