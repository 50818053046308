import { useState } from "react";
import { Table } from "../../../../../../../../CommonComponents/Table/Table";
import { columns } from "../Involvement/config";
import { ViewMatchesIProps } from "./ViewMatchesI";
import { ModalComponent } from "../../../../../../../../CommonComponents/Modal/ModalComponent";
import { ViewMatchesOverview } from "./ViewMatchesOverview/ViewMatchesOverview";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Filter } from "./Filter";
export const ViewMatches: React.FC<ViewMatchesIProps> = ({
  tableData,
  Mainfields,
  setMainfields,
  tableTotalRecord,
  setpageNumber,
  setpageCount,
  onSearch,
}) => {
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [NameMasterID, setNameMasterID] = useState<string>("");
  const [Name, setName] = useState<string>("");
  const SetNameMasterID = (id: string) => {
    setNameMasterID(id);
  };
  const closePopup = () => {
    setdeletePopup(false);
  };

  const openPopup = () => {
    setdeletePopup(true);
  };

  return (
    <div style={{ width: "100%", display: "flex", gap: "20px" }}>
      <ModalComponent
        onClose={closePopup}
        open={deletePopup}
        width={1000}
        innerContant={
          <div>
            <ViewMatchesOverview
              NameMasterID={NameMasterID}
              Mainfields={Mainfields}
              setMainfields={setMainfields}
              closePopup={closePopup}
            />
          </div>
        }
        closeIconStyle={{
          backgroundColor: "#ffd9d9",
          border: "2px solid #ffd9d9",
          width: "30px",
          height: "30px",
        }}
      />

      <div className="UserManagement">
        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ width: "30%" }}>
            <Filter Name={Name} setName={setName} onSearch={onSearch} />
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Table
            columns={columns(openPopup, SetNameMasterID)}
            columnsValue={tableData}
            cursorPointer={true}
            headerBackgroundColor="#cccc"
            tableTotalRecord={tableTotalRecord}
            setpageNumber={setpageNumber}
            setpageCount={setpageCount}
          />
        </div>
      </div>
    </div>
  );
};
