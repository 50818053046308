import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../CommonComponents/Table/Table";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
import add from "../../../../../assets/icons/Icon.svg";
import { useDispatch } from "react-redux";

import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { SetTableData } from "./ListRoleIState";
import { column } from "./Config";
import { Filter } from "../Components/Filter/Filter";
import { ListRoleFunction } from "./ListRoleFunction";
import {
  setRoledata,
  setRoleeditComponent,
} from "../../../../../Redux/RoleReducer";
import useDelayedEffect from "../../../../../hooks/useDelayedEffect";
import { useGetPerticularUserPermission } from "../../../../../hooks/Permission/useGetPerticularUserPermission";
import { NewDeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/Components/RoleDeleteModal/RoleDeleteConfirmation";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

const ListRoleManagement = () => {
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getRoleFunction = new ListRoleFunction();
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [Status, setStatus] = useState<string>("1");
  const [RoleName, setRoleName] = useState<string>("");
  const [roleId, setroleId] = useState<string>("");
  const [dataValue,setDataValue]=useState<{[key:string]:{[key:string]:string}[]}>({});
  const [data,setData]=useState<{[key:string]:{[key:string]:string}[]}>({});
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const addRole = () => {
    dispatch(
      setRoleeditComponent({
        viewRoleComponent: false,
        addRoleComponent: true,
        editRoleComponent: false,
      })
    );
    dispatch(
      setRoledata({
        RoleName: "",
        Description: "",
        Status: "1",
        ModifiedOn:"",
        ModifiedByUserName:"",
      })
    );
    navigation("/role/add");
  };
console.log("listrole",dataValue.response)
  const NavigateToEditPage = (data: SetTableData) => {
    navigation(`/role/${data.ID}/edit`);
    dispatch(
      setRoledata({
        RoleName: data["Role Name"],
        Description: data["Description"],
        Status: data.Status == "Active" ? "1" : "2",
        ModifiedByUserName:data.ModifiedByUserName,
        ModifiedOn:data.ModifiedOn,
      })
    );
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
    setDataValue({})
  };

  const openDeletePopup = (id: string) => {
    setdeletePopup(true);
    setroleId(id);
  };

  const handleDelete = async () => {
    await getRoleFunction.CustomDelete(roleId, setLoading, setdeletePopup,setDataValue,false);
    GetUserManagementTableData();
  };
  const handleRoleExistDelete = async () => {
    await getRoleFunction.CustomDelete(roleId, setLoading, setdeletePopup,setDataValue,true);
    GetUserManagementTableData();
  };
  const onRowClicked = (data: SetTableData) => {
    console.log(data)
    if (!deletePopup) {
      navigation(`/role/${data.ID}/view`);
      dispatch(
        setRoledata({
          RoleName: data["Role Name"],
          Description: data["Description"],
          Status: data.Status == "Active" ? "1" : "2",
          ModifiedOn:data.ModifiedOn,
          ModifiedByUserName:data.ModifiedByUserName,
        })
      );
      dispatch(
        setRoleeditComponent({
          viewRoleComponent: true,
          addRoleComponent: true,
          editRoleComponent: false,
        })
      );
    }
  };


  const applyFilterOnUserpolicyTable = (data = FiltertableData) => {
    getRoleFunction.applyFilterOnUserpolicyTable(
      Status,
      RoleName,
      data,
      setTableData
    );
  };
  const GetUserManagementTableData = () => {
    getRoleFunction.GetUserManagementTableData((data) => {
      setFiltertableData(data);
      applyFilterOnUserpolicyTable(data);
      setLoading(false);
    }, setLoading,setFiltertableData);
  };
  

  
  useEffect(() => {
    GetUserManagementTableData();
  }, []);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [RoleName, Status]);

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
       onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <NewDeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
            RoleError={dataValue}
            handleRoleExistDelete={handleRoleExistDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />

      <div className="AgencyManagement">
     
        <div className="Audit-Header">
          <p className="header">Roles</p>
        </div>
        <div className="Filter-audit">
          <div style={{ width: "100%" }}>
            <Filter
              Status={Status}
              setStatus={setStatus}
              RoleName={RoleName}
              setRoleName={setRoleName}
              addRole={addRole}
            />
          </div>

          
        </div>
        <div className="table">
          <Table
            columns={column(
              NavigateToEditPage,
              openDeletePopup,
            )}
            columnsValue={tableData}
            showPagination={false}
            onRowClicked={onRowClicked}
            clientSideSorting={true}
            cursorPointer={true}
             
              
          />
        </div>
      </div>
    </>
  );
};

export default ListRoleManagement;
