import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { BackButtonComponent } from "../../../CommonComponents/BackButton/BackButtonComponent";
import { OverviewTab } from "./Components/Overview/Overview";
import { Vehicles } from "./Components/Vehicles/Vehicles";
import { Identification } from "./Components/Identification/Identification";
import { Contacts } from "./Components/Contacts/Contacts";
import { Alerts } from "./Components/Alerts/Alerts";
import { Address } from "./Components/Address/Address";
import { Emergency } from "./Components/Emergency/Emergency";
import { Involvement } from "./Components/Involvement/Involvement";
import { Detainment } from "./Components/Detainment/Detainment";
import { Substance } from "./Components/Substance/Substance";
import { Additional } from "./Components/Additional/Additional";
import { Blood } from "./Components/Blood/Blood";
import { Statutes } from "./Components/Statutes/Statutes";
import { OverviewMainFunction } from "./Components/Overview/OverviewMainFunction";
import { MasterMainIParams, OverviewData } from "./MasterNameIState";
import { Scars } from "./Components/Scars/Scars";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
import { InvolvementMainIParams } from "../../Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

export const MasterOverview: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const overviewFunction = new OverviewMainFunction();
  const [fields, setFields] = useState<OverviewData>({
    Address: "",
    Age: "",
    DOB: "",
    PersonSurName: "",
    PersonGivenName: "",
    PersonMiddleName: "",
    EyeColor: "",
    FacialHair: "",
    Glasses: "",
    Height: "",
    Race: "",
    Sex: "",
    Complexion: "",
    Skintone: "",
    Weight: "",
    Scars: "",
    Tatoos: "",
  });
  const navigation = useNavigate();
  const NavigateToUserPage = () => {
    navigation("/master");
  };
  const { nameId } = useParams<MasterMainIParams>();
  const initialRender = () => {
    if (nameId) {
      overviewFunction.getOverviewData(setLoading, setFields, fields, nameId);
    }
  };
  useEffect(() => {
    initialRender();
  }, []);
  return (
    <>
      <Loader loading={loading} />
      <div className="AddMasterName_main">
     
        <div className="AddMasterName_child">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Master", path:  `/incidents` },
              { title: "Overview", path: "" },
            ]}
          />
          <BackButtonComponent
            name="Back to name lookup"
            onClick={NavigateToUserPage}
          />
          <p className="header" style={{ marginBottom: "0%" }}>
            {fields?.PersonGivenName} {fields?.PersonMiddleName}{" "}
            {fields?.PersonSurName} {"       "}
            {fields?.DOB ? fields.DOB :""}
          </p>

          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <OverviewTab />
                <Scars />
                <Identification />
                <Address />
                <Contacts />
              </div>
              <div style={{ width: "50%" }}>
                <Alerts />
                <Vehicles />
                <Emergency />
              </div>
            </div>

            <Involvement />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <Statutes />
                <Blood />
              </div>
              <div style={{ width: "50%" }}>
                <Substance />
                <Detainment />
              </div>
            </div>

            <Additional />
          </div>
        </div>
      </div>
    </>
  );
};
