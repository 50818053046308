import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface vehicleCategoryState {
    categoryType: string
}

const initialState: vehicleCategoryState = {
    categoryType: "Vehicle"
}

const slice = createSlice({
    name: "VehicleReducer",
    initialState,
    reducers: {
        setVehicleType(state, action: PayloadAction<string>) {
            state.categoryType = action?.payload
        }
    }
});

export const { setVehicleType } = slice.actions;
export default slice.reducer;