import { useEffect, useState } from "react";
import { Table } from "../../Table/Table";
import { coloum } from "./Config";
import { InvolvementMainFunction } from "../../../Pages/Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainFunction";
import {
  setStateTable,
  ShowAddressPariticipantIProps,
} from "./ShowAddressPariticipantI";
import { ShowAddressPariticipantFunction } from "./ShowAddressPariticipantFunction";

export const ShowAddressPariticipant: React.FC<
  ShowAddressPariticipantIProps
> = ({ participantId, closeaddressPopup }) => {
  const showAddressPariticipantFunction = new ShowAddressPariticipantFunction();
  const [loading, setloading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<setStateTable[]>([]);

  const initialRender = () => {
    if (participantId) {
      showAddressPariticipantFunction.getAddress(
        participantId,
        setTableData,
        setloading
      );
    }
  };

  useEffect(() => {
    setTableData([]);
    initialRender();
  }, [participantId]);
  return (
    <>
      <Table columns={coloum()} columnsValue={tableData} cursorPointer={true} />

      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
      >
        <button className="save" onClick={closeaddressPopup}>
          Cancel
        </button>
      </div>
    </>
  );
};
