import { coloum } from "./Config";
import { SetTableData } from "./AgencyLevelI";

import { useEffect, useState } from "react";
import { AgencyLevelFunction } from "./AgencyLevelFunction";

import "./AgencyLevel.css";
import { Filter } from "./components/Filter/Filter";
import { AgencyLevelAddEditView } from "./components/AgencyLevelAddEditView/AgencyLevelAddEditView";
import useDelayedEffect from "../../../../hooks/useDelayedEffect";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { Table } from "../../../CommonComponents/Table/Table";
import { useGetPerticularUserPermission } from "../../../../hooks/Permission/useGetPerticularUserPermission";

export const AgencyLevelCode: React.FC = () => {
  const { Update,Delete } =
  useGetPerticularUserPermission("Code.System");
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const agencyLevelFunction = new AgencyLevelFunction();
  const [showAddEditView, setshowAddEditView] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [editMode, seteditMode] = useState<boolean>(false);
  const [addMode, setaddMode] = useState<boolean>(false);
  const [viewMode, setviewMode] = useState<boolean>(false);
  const [getRowId, setgetRowId] = useState<string>("");
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [AgencyLevelCode, setAgencyLevelCode] = useState<string>("");
  const [Category, setCategory] = useState<string>("");
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Short Code");
  const [Status, setStatus] = useState<string>("1");

  const Edit = (id: string) => {
    setaddMode(false);
    seteditMode(true);
    setshowAddEditView(true);
    setviewMode(false);
    setgetRowId(id);
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
  };
  const DeleteRow = (id: string) => {
    setdeletePopup(true);
    setgetRowId(id);
  };

  const onRowClicked = (data: SetTableData) => {
    if (!deletePopup) {
      setgetRowId(data.AgencyLevelCodeId);
      setaddMode(false);
      seteditMode(false);
      setshowAddEditView(true);
      setviewMode(true);
    }
  };

  const getAgencyLevelTableData = () => {
    agencyLevelFunction.getAgencyLevelTableData(
      setTableData,
      setLoading,
      setTableTotalRecord,
      pageNumber,
      pageCount,
      Category,
      Status,
      AgencyLevelCode,
      OrderByDescending,
      OrderBy
    );
  };

  const handleDelete = () => {
    agencyLevelFunction
      .deleteAgencyLevel(getRowId, setLoading, setdeletePopup)
      .then(() => {
        getAgencyLevelTableData();
      });
  };

  useDelayedEffect(getAgencyLevelTableData, 700, [
    Category,
    Status,
    AgencyLevelCode,
  ]);

  useEffect(() => {
    if (!showAddEditView) {
      getAgencyLevelTableData();
    }
  }, [OrderByDescending, pageNumber, pageCount, showAddEditView]);

  const goToAdd = () => {
    setaddMode(true);
    seteditMode(false);
    setshowAddEditView(true);
    setviewMode(false);
    setgetRowId("");
  };

  return (
    <>
      <div>
        <Loader loading={loading} />
        <ModalComponent
         onClose={closeDeletePopup}
          open={deletePopup}
          title="Delete"
          innerContant={
            <DeleteConfirmation
              onClose={closeDeletePopup}
              onDelete={handleDelete}
            />
          }
           closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
        />
        {showAddEditView ? (
          <AgencyLevelAddEditView
            addmode={addMode}
            editMode={editMode}
            viewMode={viewMode}
            setaddMode={setaddMode}
            seteditMode={seteditMode}
            setviewMode={setviewMode}
            setshowAddEditView={setshowAddEditView}
            getRowId={getRowId}
          />
        ) : (
          <div>
            <Filter
              goToAdd={goToAdd}
              setAgencyLevelCode={setAgencyLevelCode}
              setCategory={setCategory}
              setStatus={setStatus}
              AgencyLevelCode={AgencyLevelCode}
              Category={Category}
              Status={Status}
            />

            <div className="table">
              <Table
                columns={coloum(Edit,DeleteRow,Delete,Update)}
                columnsValue={tableData}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
                onRowClicked={onRowClicked}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                cursorPointer={true}
              
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
