import React, { useEffect, useState } from "react";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add, DarkAdd, PrimaryAdd } from "../../../../../../assets";
import { RadioComponent } from "../../../../../CommonComponents/Fields/Radio/RadioComponent";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { AddVehicleFunction } from "./AddVehicleFunction";
import { GetRaceDataIState } from "../../../Participant/AddEditParticipantName/AddBusinessName/InvolvementMainI";
import { AddDescriptionFormProps, AddressComponentIProps } from "./VehicalI";
import { InvolvementMainFunction } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainFunction";
import add from "../../../../../../assets/icons/Icon.svg";
const AddressComponent: React.FC<AddressComponentIProps> = ({
  formData,
  setFormData,
  formDataIndividualitem,
  suffixData,
  Country,
  RaceDataData,
  LocationCategory,
  StreetPrefix,
  StateCode,
  StreetSuffix,
}) => {
  const handleInputChange = (
    key: string,
    value: string | boolean | number | null
  ) => {
    setFormData({
      ...formData,
      Individual: formData.Individual.map((val) => {
        if (val.id == formDataIndividualitem.id) {
          return {
            ...val,
            address: {
              ...val.address,
              [key]: value,
            },
          };
        } else {
          return val;
        }
      }),
    });
  };

  const handleChange = (
    key: string,
    value: string | boolean | number | null
  ) => {
    setFormData({
      ...formData,
      Individual: formData.Individual.map((val) => {
        if (val.id == formDataIndividualitem.id) {
          return {
            ...val,
            [key]: value,
          };
        } else {
          return val;
        }
      }),
    });
  };

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <div className="_lien_name_conatiner">
          <TextBoxComponent
            fieldName="Last Name"
            value={formDataIndividualitem.LastName}
            onChangeValue={(newValue) => {
              handleChange("LastName", newValue);
            }}
          />
        </div>

        <div className="_lien_name_conatiner">
          <TextBoxComponent
            fieldName="First Name"
            value={formDataIndividualitem.FirstName}
            onChangeValue={(newValue) => {
              handleChange("FirstName", newValue);
            }}
          />
        </div>

        <div className="_lien_name_conatiner">
          <TextBoxComponent
            fieldName="Middle Name"
            value={formDataIndividualitem.MiddleName}
            onChangeValue={(newValue) => {
              handleChange("MiddleName", newValue);
            }}
          />
        </div>

        <div className="_lien_owner_suffix">
          <DropdownComponent
            fieldName="Suffix"
            dataSource={suffixData.map((state) => ({
              id: state.NciccodeId,
              value: state.LongCode,
            }))}
            value={formDataIndividualitem.Suffix}
            handleRowClick={(e) => {
              handleChange("Suffix", e);
            }}
          />
        </div>

        <div className="_lien_owner_age">
          <DateBoxComponents
            fieldName="Date of Birth"
            value={formDataIndividualitem.DOB}
            onValueChange={(newValue) => {
              handleChange("DOB", newValue);
            }}
          />
        </div>
      </div>
      <div style={{ width: "60%" }}>
        <div className="address-checkbox_">
          {!formDataIndividualitem.address.ForeignAddress && (
            <>
              <div className="p_address_type_container">
                <DropdownComponent
                  value={formDataIndividualitem.address.AddressType}
                  handleRowClick={(e) => {
                    handleInputChange("AddressType", e);
                  }}
                  dataSource={RaceDataData.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Address Type"
                />
              </div>
              <div className="p_address_type_container">
                <DropdownComponent
                  value={formDataIndividualitem.address.LocationCategoryCode}
                  handleRowClick={(e) => {
                    handleInputChange("LocationCategoryCode", e);
                  }}
                  dataSource={LocationCategory.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Location Category"
                />
              </div>
            </>
          )}
          <div style={{ marginTop: "40px" }}>
            <CheckBoxComponent
              value={formDataIndividualitem.address.ForeignAddress}
              fieldName="Foreign Address"
              onChangeValue={(e) => {
                handleInputChange("ForeignAddress", e);
              }}
            />
          </div>
        </div>
        {formDataIndividualitem.address.ForeignAddress ? (
          <>
            <div style={{ width: "70%" }}>
              <TextBoxComponent
                value={formDataIndividualitem.address.AddressLine1}
                onChangeValue={(e) => {
                  handleInputChange("AddressLine1", e);
                }}
                fieldName="Address Line 1: Street address, P.O box, company name, c/o"
              />
              <TextBoxComponent
                value={formDataIndividualitem.address.AddressLine2}
                onChangeValue={(e) => {
                  handleInputChange("AddressLine2", e);
                }}
                fieldName="Address Line 2: Apartment, suite, unit, building, floor, etc"
              />
            </div>
            <div className="Involvement_left_row">
              <TextBoxComponent
                value={formDataIndividualitem.address.ForeignAddressCity}
                onChangeValue={(e) => {
                  handleInputChange("ForeignAddressCity", e);
                }}
                fieldName="City"
              />
              <TextBoxComponent
                value={
                  formDataIndividualitem.address
                    .ForeignAddressStateProvinceRegion
                }
                onChangeValue={(e) => {
                  handleInputChange("ForeignAddressStateProvinceRegion", e);
                }}
                fieldName="State / Province/Region"
              />
              <TextBoxComponent
                value={formDataIndividualitem.address.ForeignAddressZip}
                onChangeValue={(e) => {
                  handleInputChange("ForeignAddressZip", e);
                }}
                fieldName="Zip / postal Code"
              />
            </div>
            <div style={{ width: "40%" }}>
              <TextBoxComponent
                value={formDataIndividualitem.address.ForeignAddressCountry}
                onChangeValue={(e) => {
                  handleInputChange("ForeignAddressCountry", e);
                }}
                fieldName="Country"
              />
            </div>
          </>
        ) : (
          <>
            <div className="involvemt_street_inline_container">
              <div className="Involvement_left_row">
                <div className="_involvement_meta_info">
                  <TextBoxComponent
                    value={formDataIndividualitem.address.StreetNumber}
                    onChangeValue={(e) => {
                      handleInputChange("StreetNumber", e);
                    }}
                    fieldName="Street Number"
                  />
                </div>
                <div className="_involvement_meta_info">
                  <DropdownComponent
                    handleRowClick={(e) => {
                      handleInputChange("StreetPrefix", e);
                    }}
                    value={formDataIndividualitem.address.StreetPrefix}
                    dataSource={StreetPrefix.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    fieldName="Street Prefix"
                  />
                </div>
              </div>
              <div className="Involvement_left_row ">
                <div className="involvemet_street_container">
                  <TextBoxComponent
                    onChangeValue={(e) => {
                      handleInputChange("Street", e);
                    }}
                    value={formDataIndividualitem.address.Street}
                    fieldName="Street"
                  />
                </div>
                <div className="_involvement_meta_info">
                  <DropdownComponent
                    value={formDataIndividualitem.address.StreetSuffix}
                    handleRowClick={(e) => {
                      handleInputChange("StreetSuffix", e);
                    }}
                    dataSource={StreetSuffix.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    fieldName="Street Suffix"
                  />
                </div>
                <div className="_involvement_meta_info">
                  <TextBoxComponent
                    value={formDataIndividualitem.address.Apartment}
                    onChangeValue={(e) => {
                      handleInputChange("Apartment", e);
                    }}
                    fieldName="Apartment"
                  />
                </div>
              </div>
            </div>
            <div className="address_city_address_container">
              <div className="_address_state_providence">
                <TextBoxComponent
                  value={formDataIndividualitem.address.City}
                  fieldName="City"
                  onChangeValue={(e) => {
                    handleInputChange("City", e);
                  }}
                />
              </div>
              <div className="_address_state_providence">
                <DropdownComponent
                  value={formDataIndividualitem.address.StateProvidence}
                  dataSource={StateCode.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  handleRowClick={(e) => {
                    handleInputChange("StateProvidence", e);
                  }}
                  fieldName="State / Providence"
                />
              </div>

              <div className="_address_zip_code">
                <TextBoxComponent
                  value={formDataIndividualitem.address.ZipCode}
                  onChangeValue={(e) => {
                    handleInputChange("ZipCode", e);
                  }}
                  fieldName="Zip Code"
                />
              </div>
            </div>
            <div className="country_grid_container">
              <div className="_address_country_container">
                <DropdownComponent
                  value={formDataIndividualitem.address.Country}
                  handleRowClick={(e, value2) => {
                    handleInputChange("Country", e);
                  }}
                  dataSource={Country.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Country"
                />
              </div>
              <div className="_address_grid_number">
                <TextBoxComponent
                  value={formDataIndividualitem.address.GridNumber}
                  onChangeValue={(e) => {
                    if (e.length < 6) {
                      handleInputChange("GridNumber", e);
                    }
                  }}
                  fieldName="Grid Number"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const AddOwnerInfo: React.FC<AddDescriptionFormProps> = ({
  formData,
  setFormData,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const addVehicleFunction = new AddVehicleFunction();
  const [suffixData, setSuffixData] = useState<GetRaceDataIState[]>([]);
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [StateCode, setStateCode] = useState<GetRaceDataIState[]>([]);
  const [LocationCategory, setLocationCategory] = useState<GetRaceDataIState[]>(
    []
  );

  const handleInputChange = (
    key: string,
    value: string | boolean | number | null
  ) => {
    setFormData({
      ...formData,
      Business: {
        ...formData.Business,
        address: {
          ...formData.Business.address,
          [key]: value,
        },
      },
    });
  };

  const addNewUserHandler = () => {
    setFormData({
      ...formData,
      Individual: [
        ...formData.Individual,
        {
          RegisteredOwnerInformationId: "",
          LastName: "",
          FirstName: "",
          MiddleName: "",
          Suffix: "",
          DOB: "",
          id: 2,
          address: formData.Individual[0].address,
        },
      ],
    });
  };

  const initialRender = () => {
    addVehicleFunction.getSuffixData(setSuffixData);
    involvementMainFunction.getAddresType(setRaceDataData);
    involvementMainFunction.getStreetPrefix(setStreetPrefix);
    involvementMainFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setStateCode);
    involvementMainFunction.getLocationType(setLocationCategory);
  };

  useEffect(() => {
    initialRender();
  }, []);
  const getOwnerLabel = (index: number) => {
    const labels = ["Owner One", "Owner Two"];
    return labels[index] || `Owner ${index + 1}`;
  };
  const handleRemoveOwner = (index: number) => {
    if (formData.Individual.length > 1) {
      setFormData({
        ...formData,
        Individual: formData.Individual.filter((_, i) => i !== index),
      });
    }
  };
  return (
    <>
      <div className="_Registered_owner_info_container">
        <div className="_add_vehicle_form_desc_title">
          <p>Registered Owner Information</p>
        </div>
        <div className="_lience_holder_checkbox">
          <RadioComponent
            RadioTextList={["Individual(s)", "Business"]}
            value={formData.isIndividual}
            onChangeValue={(e) => {
              setFormData({
                ...formData,
                isIndividual: e,
              });
            }}
          />
        </div>

        <div className="multiple_vehicle_owner_container">
          {formData.isIndividual === 1 ? (
            <div>
              {formData.Individual?.map((formDataIndividualitem, index) => {
                return (
                  <>
                    <p className="header" key={index}>
                      {getOwnerLabel(index)}
                      {/* {formData.Individual.length > 1 && (
            <span onClick={() => handleRemoveOwner(index)} style={{ cursor: 'pointer',fontSize:"12px", marginLeft: '10px' }}>
              -
            </span>
          )} */}
                    </p>

                    <AddressComponent
                      formData={formData}
                      suffixData={suffixData}
                      setFormData={setFormData}
                      formDataIndividualitem={formDataIndividualitem}
                      Country={Country}
                      LocationCategory={LocationCategory}
                      RaceDataData={RaceDataData}
                      StateCode={StateCode}
                      StreetPrefix={StreetPrefix}
                      StreetSuffix={StreetSuffix}
                    />
                  </>
                );
              })}
              <div className="_Add_second_owner_btn">
                <div className="btn_container">
                 {formData.Individual.length < 2 && (
  <ButtonComponents
    name="Add Second Owner"
    showBackgroundColor={true}
    icon={formData.Individual[0]?.FirstName && formData.Individual[0]?.LastName && formData.Individual[0]?.Suffix && formData.Individual[0]?.DOB && formData.Individual[0]?.address.City && formData.Individual[0]?.address.AddressType ? Add : ""}
    borderColor={formData.Individual[0]?.FirstName && formData.Individual[0]?.LastName && formData.Individual[0]?.Suffix && formData.Individual[0]?.DOB && formData.Individual[0]?.address.City && formData.Individual[0]?.address.AddressType ? "#3672b3" : "#dcdcdc"}
    
    color={formData.Individual[0]?.FirstName && formData.Individual[0]?.LastName && formData.Individual[0]?.Suffix && formData.Individual[0]?.DOB && formData.Individual[0]?.address.City && formData.Individual[0]?.address.AddressType ? "white" : "#dcdcdc"}
    textColor={formData.Individual[0]?.FirstName && formData.Individual[0]?.LastName && formData.Individual[0]?.Suffix && formData.Individual[0]?.DOB && formData.Individual[0]?.address.City && formData.Individual[0]?.address.AddressType ? "#3672b3" : "black"}
   
    disabled={!(formData.Individual[0]?.FirstName && formData.Individual[0]?.LastName && formData.Individual[0]?.Suffix && formData.Individual[0]?.DOB && formData.Individual[0]?.address.City && formData.Individual[0]?.address.AddressType)}
    handleClick={addNewUserHandler}
  />
)}

                </div>
              </div>
            </div>
          ) : (
            <div className="lien_holder_container">
              <TextBoxComponent
                fieldName="Business Name"
                value={formData.Business.BusinessName}
                onChangeValue={(newValue) => {
                  handleInputChange("BusinessName", newValue);
                  setFormData({
                    ...formData,
                    Business: {
                      ...formData.Business,
                      BusinessName: newValue,
                    },
                  });
                }}
              />
              <div className="address-checkbox_">
                {!formData.Business.address.ForeignAddress && (
                  <>
                    <DropdownComponent
                      value={formData.Business.address.AddressType}
                      handleRowClick={(e) => {
                        handleInputChange("AddressType", e);
                      }}
                      dataSource={RaceDataData.map((val) => {
                        return {
                          id: val.NciccodeId,
                          value: val.LongCode,
                        };
                      })}
                      fieldName="Address Type"
                    />

                    <DropdownComponent
                      value={formData.Business.address.LocationCategoryCode}
                      handleRowClick={(e) => {
                        handleInputChange("LocationCategoryCode", e);
                      }}
                      dataSource={LocationCategory.map((val) => {
                        return {
                          id: val.NciccodeId,
                          value: val.LongCode,
                        };
                      })}
                      fieldName="Location Category"
                    />
                  </>
                )}
                <div style={{ marginTop: "9%" }}>
                  {" "}
                  <CheckBoxComponent
                    value={formData.Business.address.ForeignAddress}
                    fieldName="Foreign Address"
                    onChangeValue={(e) => {
                      handleInputChange("ForeignAddress", e);
                    }}
                  />
                </div>
              </div>
              {formData.Business.address.ForeignAddress ? (
                <>
                  <div style={{ width: "70%" }}>
                    <TextBoxComponent
                      value={formData.Business.address.AddressLine1}
                      onChangeValue={(e) => {
                        handleInputChange("AddressLine1", e);
                      }}
                      fieldName="Address Line 1: Street address, P.O box, company name, c/o"
                    />
                    <TextBoxComponent
                      value={formData.Business.address.AddressLine2}
                      onChangeValue={(e) => {
                        handleInputChange("AddressLine2", e);
                      }}
                      fieldName="Address Line 2: Apartment, suite, unit, building, floor, etc"
                    />
                  </div>
                  <div className="Involvement_left_row">
                    <TextBoxComponent
                      value={formData.Business.address.ForeignAddressCity}
                      onChangeValue={(e) => {
                        handleInputChange("ForeignAddressCity", e);
                      }}
                      fieldName="City"
                    />
                    <TextBoxComponent
                      value={
                        formData.Business.address
                          .ForeignAddressStateProvinceRegion
                      }
                      onChangeValue={(e) => {
                        handleInputChange(
                          "ForeignAddressStateProvinceRegion",
                          e
                        );
                      }}
                      fieldName="State / Province/Region"
                    />
                    <TextBoxComponent
                      value={formData.Business.address.ForeignAddressZip}
                      onChangeValue={(e) => {
                        handleInputChange("ForeignAddressZip", e);
                      }}
                      fieldName="Zip / postal Code"
                    />
                  </div>
                  <div style={{ width: "40%" }}>
                    <TextBoxComponent
                      value={formData.Business.address.ForeignAddressCountry}
                      onChangeValue={(e) => {
                        handleInputChange("ForeignAddressCountry", e);
                      }}
                      fieldName="Country"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="involvemt_street_inline_container">
                    <div className="Involvement_left_row">
                      <div className="_involvement_meta_info">
                        <TextBoxComponent
                          value={formData.Business.address.StreetNumber}
                          onChangeValue={(e) => {
                            handleInputChange("StreetNumber", e);
                          }}
                          fieldName="Street Number"
                        />
                      </div>
                      <div className="_involvement_meta_info">
                        <DropdownComponent
                          handleRowClick={(e) => {
                            handleInputChange("StreetPrefix", e);
                          }}
                          value={formData.Business.address.StreetPrefix}
                          dataSource={StreetPrefix.map((val) => {
                            return {
                              id: val.NciccodeId,
                              value: val.LongCode,
                            };
                          })}
                          fieldName="Street Prefix"
                        />
                      </div>
                    </div>
                    <div className="Involvement_left_row ">
                      <div className="involvemet_street_container">
                        <TextBoxComponent
                          onChangeValue={(e) => {
                            handleInputChange("Street", e);
                          }}
                          value={formData.Business.address.Street}
                          fieldName="Street"
                        />
                      </div>
                      <div className="_involvement_meta_info">
                        <DropdownComponent
                          value={formData.Business.address.StreetSuffix}
                          handleRowClick={(e) => {
                            handleInputChange("StreetSuffix", e);
                          }}
                          dataSource={StreetSuffix.map((val) => {
                            return {
                              id: val.NciccodeId,
                              value: val.LongCode,
                            };
                          })}
                          fieldName="Street Suffix"
                        />
                      </div>
                      <div className="_involvement_meta_info">
                        <TextBoxComponent
                          value={formData.Business.address.Apartment}
                          onChangeValue={(e) => {
                            handleInputChange("Apartment", e);
                          }}
                          fieldName="Apartment"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="address_city_address_container">
                    <div className="_address_state_providence">
                      <TextBoxComponent
                        value={formData.Business.address.City}
                        fieldName="City"
                        onChangeValue={(e) => {
                          handleInputChange("City", e);
                        }}
                      />
                    </div>
                    <div className="_address_state_providence">
                      <DropdownComponent
                        value={formData.Business.address.StateProvidence}
                        dataSource={StateCode.map((val) => {
                          return {
                            id: val.NciccodeId,
                            value: val.LongCode,
                          };
                        })}
                        handleRowClick={(e, value2) => {
                          handleInputChange("StateProvidence", e);
                        }}
                        fieldName="State / Providence"
                      />
                    </div>

                    <div className="_address_zip_code">
                      <TextBoxComponent
                        value={formData.Business.address.ZipCode}
                        onChangeValue={(e) => {
                          handleInputChange("ZipCode", e);
                        }}
                        fieldName="Zip Code"
                      />
                    </div>
                  </div>
                  <div className="country_grid_container">
                    <div className="_address_country_container">
                      <DropdownComponent
                        value={formData.Business.address.Country}
                        handleRowClick={(e, value2) => {
                          handleInputChange("Country", e);
                        }}
                        dataSource={Country.map((val) => {
                          return {
                            id: val.NciccodeId,
                            value: val.LongCode,
                          };
                        })}
                        fieldName="Country"
                      />
                    </div>
                    <div className="_address_grid_number">
                      <TextBoxComponent
                        value={formData.Business.address.GridNumber}
                        onChangeValue={(e) => {
                          if (e.length < 6) {
                            handleInputChange("GridNumber", e);
                          }
                        }}
                        fieldName="Grid Number"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddOwnerInfo;
