import { InvolvementMainIState } from "../../../Involvement/InvolvementMainI";
import { BloodIstate } from "../SubstanceInvolvement/SubstanceIProps";

export class MultiBloodFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: BloodIstate[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: BloodIstate[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.SubstanceTab.BloodTab);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      SubstanceTab: {
        ...this.Mainfields.SubstanceTab,
        onChange: true,
        BloodTab: [
          ...this.Mainfields.SubstanceTab.BloodTab.map((val) => {
            return {
              ...val,
              push: true,
            };
          }),
          {
            id: this.Mainfields.SubstanceTab.BloodTab.length + 1,
            NcicsubstanceTypeCodeId: "",
            TestDate: "",
            TestResults: 0,
            TestTime: "",
            alcholedropdownvalue: "",
            AgencyId: "",
            IncidentId: "",
            ParticipantBloodAlcoholLevelId: "",
            PersonId: "",
            TestTypeDropdownvalue: "",
            push: false,
          },
        ],
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.SubstanceTab.BloodTab);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      SubstanceTab: {
        ...this.Mainfields.SubstanceTab,
        onChange: true,
        BloodTab: this.Mainfields.SubstanceTab.BloodTab.filter((val) => {
          if (val.id != id) {
            return val;
          }
        }).length
          ? this.Mainfields.SubstanceTab.BloodTab.filter((val) => {
              if (val.id != id) {
                return val;
              }
            })
          : [
              {
                id: 1,
                NcicsubstanceTypeCodeId: "",
                TestDate: "",
                TestResults: 0,
                TestTime: "",
                AgencyId: "",
                IncidentId: "",
                ParticipantBloodAlcoholLevelId: "",
                alcholedropdownvalue: "",
                PersonId: "",
                TestTypeDropdownvalue: "",
                push: false,
              },
            ],
      },
    });

    this.setedit(false);
  };
}
