// import React, { useState } from 'react'
// import { Drag, Video } from '../../../../../assets'
// import { ButtonComponents } from '../../../../CommonComponents/Fields/Button/ButtonComponents'
// import { RadioComponent } from '../../../../CommonComponents/Fields/Radio/RadioComponent'
// import { useNavigate } from 'react-router-dom'

// const AddMediaForm = () => {
//     const navigation =useNavigate();
//     const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

//     const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const files = Array.from(event.target.files || []);
//         setUploadedFiles([...uploadedFiles, ...files]);
//     };

//     const handleFileRemove = (index: number) => {
//         const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
//         setUploadedFiles(updatedFiles);
//     };
   
//     return (
//         <>
//             <div className="_addMediaForm_container">
//                 <div className="_case_detail_container">
//                     <h3 className="_case_id_title">Upload Media</h3>
//                 </div>

//                 <div className="drag_drop_files_container">
//                     <div className="_upload_more_file_container">
//                         <div className="add_form_media_title_radio_container">
//                             <p className="upload_more_file_title">
//                                 Upload more files
//                             </p>
//                             <RadioComponent
//                                 RadioTextList={["Files", "Folder"]}
//                                 value={0}
//                             />
//                         </div>

//                         <div className="_drag_drop_file_input_box">
//                             <div className="_drag_drop_icon_container">
//                                 <Drag />
//                                 <p onChange={handleFileUpload}>Drop / Browse files or folders</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* <div className="media_meta_info_container">
//                     <div className="_left_media_meta_info_container">
//                         <div className="_media_Supported_format_container">
//                             <div className="_supported_format_title_container">
//                                 <p className='upload_more_file_title'>Supported format</p>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Video</span>
//                                     <p className='meta_info_type_list'>MP4, Mov, Webm, QuickTime</p>
//                                 </div>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Audio</span>
//                                     <p className='meta_info_type_list'>ACC,MPEGs,Wav,Wma,mp3</p>
//                                 </div>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Image</span>
//                                     <p className='meta_info_type_list'>Jpg,Png,Gif</p>
//                                 </div>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Document</span>
//                                     <p className='meta_info_type_list'>PDF,Docx,Doc,Xlsx,PPTx</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="_right_media_meta_info_container">
//                         <div className="_media_Supported_format_container">
//                             <div className="_supported_format_title_container">
//                                 <p className='upload_more_file_title'>Unsupported format</p>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Video</span>
//                                     <p className='meta_info_type_list'>MP4, Mov, Webm, QuickTime</p>
//                                 </div>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Audio</span>
//                                     <p className='meta_info_type_list'>ACC,MPEGs,Wav,Wma,mp3</p>
//                                 </div>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Image</span>
//                                     <p className='meta_info_type_list'>Jpg,Png,Gif</p>
//                                 </div>
//                             </div>

//                             <div className="_video_format_container">
//                                 <Video />
//                                 <div className='media_info_meta_list_title_container'>
//                                     <span className='media_info_meta_list_title'> Document</span>
//                                     <p className='meta_info_type_list'>PDF,Docx,Doc,Xlsx,PPTx</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div> */}

//                 <div className="submit_location_container">
//                     <div className="_button_conatiner location_submit_btn_container">
//                         <div className="cancel_btn">
//                             <ButtonComponents
//                                 name='Cancel'
//                                 color='white'
//                                 borderColor='white'
//                             />
//                         </div>

//                         <div className='saubit_btn'>
//                             <ButtonComponents
//                                 name='Save'
//                                 color='#3672b3'
//                                 borderColor='#3672b3'
//                                 showBackgroundColor={true}
//                                 textColor='white'

//                             />
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default AddMediaForm





import React, { useState, useRef } from 'react';
import { Drag, Video } from '../../../../../assets';
import { RadioComponent } from '../../../../CommonComponents/Fields/Radio/RadioComponent';
import { Submit } from '../../../../CommonComponents/Fields/Submit/Submit';
import { TextBoxComponent } from '../../../../CommonComponents/Fields/TextBox/TextBoxComponent';
import { TextAreaComponent } from '../../../../CommonComponents/Fields/TextArea/TextAreaComponent';

const AddMediaForm = () => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        setUploadedFiles([...uploadedFiles, ...files]);
    };

    const handleFileRemove = (index: number) => {
        const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
        setUploadedFiles(updatedFiles);
    };

    const triggerFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const onsave=()=>{

    }
    const oncancel=()=>{
    
    }
    return (
        <>
            <div className="_addMediaForm_container">
                <div className="_case_detail_container">
                    <h3 className="_case_id_title">Upload Media</h3>
                </div>

               
                    <div className="_upload_more_file_container">
                        
                    <div className='headerfile'>
                      <p className='header'> Upload more files</p>
                        <RadioComponent
                                RadioTextList={["Files", "Folder"]}
                                value={1}
                            />
                        </div>
                       
<div className='browser'>
    
<div className="_drag_drop_file_input_box" onClick={triggerFileUpload}>
    
                            <div className="_drag_drop_icon_container">
                                <Drag />
                                <p>Drop / Browse files or folders</p>
                            </div>
   

                        </div>
                        <div className="mediaa">
                     <div className="_left_media_meta_info_container">
                         <div className="_media_Supported_format_container">
                             <div className="_supported_format_title_container">
                                 <p className='upload_more_file_title'>Supported format</p>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Video</span>
                                     <p className='meta_info_type_list'>MP4, Mov, Webm, QuickTime</p>
                                 </div>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Audio</span>
                                     <p className='meta_info_type_list'>ACC,MPEGs,Wav,Wma,mp3</p>
                                 </div>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Image</span>
                                     <p className='meta_info_type_list'>Jpg,Png,Gif</p>
                                 </div>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Document</span>
                                     <p className='meta_info_type_list'>PDF,Docx,Doc,Xlsx,PPTx</p>
                                 </div>
                             </div>
                         </div>
                     </div>

                     <div className="_right_media_meta_info_container">
                         <div className="_media_Supported_format_container">
                             <div className="_supported_format_title_container">
                                 <p className='upload_more_file_title'>Unsupported format</p>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Video</span>
                                     <p className='meta_info_type_list'>MP4, Mov, Webm, QuickTime</p>
                                 </div>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Audio</span>
                                     <p className='meta_info_type_list'>ACC,MPEGs,Wav,Wma,mp3</p>
                                 </div>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Image</span>
                                     <p className='meta_info_type_list'>Jpg,Png,Gif</p>
                                 </div>
                             </div>

                             <div className="_video_format_container">
                                 <Video />
                                 <div className='media_info_meta_list_title_container'>
                                     <span className='media_info_meta_list_title'> Document</span>
                                     <p className='meta_info_type_list'>PDF,Docx,Doc,Xlsx,PPTx</p>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div> 

</div>
                     
                    
                        <input 
                            type="file" 
                            multiple 
                            ref={fileInputRef} 
                            style={{ display: 'none' }} 
                            onChange={handleFileUpload} 
                        />
                    </div>
            

              
                <div className="uploaded_media_list">
    {uploadedFiles.length > 0 && (
        <div className="uploaded_media_header">
            <h4>Uploaded Media</h4>
            <p className='remove_' onClick={()=>{setUploadedFiles([])}}> Remove all</p>
        </div>
    )}
    {uploadedFiles.map((file, index) => (
        <div key={index} className="uploaded_media_item">
            <img 
                src={URL.createObjectURL(file)} 
                alt={file.name} 
                className="uploaded_media_thumbnail"
            />
            
            <div className="uploaded_media_info">
            <div className="uploaded_media_remove">
                <p className='uploaded_media_remove' onClick={() => handleFileRemove(index)}>✖</p>
            </div>
            <div className='width_field'>  <TextBoxComponent fieldName='Title' value=''/></div>
             

              <TextAreaComponent fieldName='Description' value=''/>
                <span className="file-name">{file.name}</span>
                <div className="uploaded_media_progress">
                    <div></div>


                </div>
                

            </div>
            <p className="check_icon">✔</p>
        </div>
    ))}
</div>

<div className='editbutton'>
                <Submit saveLabel='Save' cancelLabel='Cancel' onCancel={oncancel} onSave={onsave} saveColor='#3b72b1' />
              </div>
            </div>
        </>
    );
};

export default AddMediaForm;
