import { useEffect, useState } from "react";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DateBoxComponents } from "../../../../../../../../CommonComponents/Fields/DateBox/DateBox";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextAreaComponent } from "../../../../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TimePickerComponenets } from "../../../../../../../../CommonComponents/Fields/TimePicker/TimePickerComponenets";
import {
  ArrestInformationIProps,
  GetRaceDataIState,
  InvolvementMainIParams,
  SameasprimaryincidentaddressIRes,
  setSameasprimaryincidentaddressIState,
} from "../../InvolvementMainI";
import { ArmedWithWeapon } from "./components/ArmedWithWeapon/ArmedWithWeapon";
import { WarrantNumber } from "./components/WarrantNumber/WarrantNumber";
import { ArrestInformationFunction } from "./ArrestInformationFunction";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { useParams } from "react-router-dom";

export const ArrestInformation: React.FC<ArrestInformationIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const arrestInformationFunction = new ArrestInformationFunction();
  const involvementMainFunction = new InvolvementMainFunction();
  const [ArrestCategoryCode, setArrestCategoryCode] = useState<
    GetRaceDataIState[]
  >([]);
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [StateCode, setStateCode] = useState<GetRaceDataIState[]>([]);
  const [ReasonHeld, setReasonHeld] = useState<GetRaceDataIState[]>([]);
  const [Sameasprimaryincidentaddress, setSameasprimaryincidentaddress] =
    useState<setSameasprimaryincidentaddressIState[]>([]);

  const [
    SameasprimaryincidentaddressAllData,
    setSameasprimaryincidentaddressAllData,
  ] = useState<SameasprimaryincidentaddressIRes[]>([]);

  const onChangeValue = (key: string, value: string | boolean | number) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Arrestinformation: {
          ...Mainfields.InvolvementTab.Arrestinformation,
          [key]: value,
        },
      },
    });
  };

  const initialRender = () => {
    arrestInformationFunction.ArrestCategoryCode(setArrestCategoryCode);
    involvementMainFunction.getAddresType(setRaceDataData);
    involvementMainFunction.getStreetPrefix(setStreetPrefix);
    involvementMainFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setStateCode);
    involvementMainFunction.ReasonHeld(setReasonHeld);
    involvementMainFunction.Sameasprimaryincidentaddress(
      setSameasprimaryincidentaddress,
      setSameasprimaryincidentaddressAllData,
      incidentId
    );
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <p className="subheader">Arrest information </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", gap: "20px", width: "50%" }}>
            <DateBoxComponents
              fieldName="Arrested Date"
              value={Mainfields.InvolvementTab.Arrestinformation.ArrestedDate}
              onValueChange={(e) => {
                if (e) {
                  onChangeValue("ArrestedDate", e);
                }
              }}
            />
            <TimePickerComponenets
              fieldName="Arrested Time"
              value={Mainfields.InvolvementTab.Arrestinformation.ArrestedTime}
              onValueChange={(e) => {
                if (e) {
                  onChangeValue("ArrestedTime", e);
                }
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <CheckBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.Photographed}
              fieldName="Photographed"
              onChangeValue={(e) => {
                onChangeValue("Photographed", e);
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <CheckBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.InCustody}
              fieldName="In Custody"
              onChangeValue={(e) => {
                onChangeValue("InCustody", e);
              }}
            />
            <CheckBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.BookandRelease}
              fieldName="Book and Release"
              onChangeValue={(e) => {
                onChangeValue("BookandRelease", e);
              }}
            />
          </div>
          <hr />

          <ArmedWithWeapon
            Mainfields={Mainfields}
            setMainfields={setMainfields}
          />

          <div style={{ alignItems: "center" }} className="Involvement_main">
            <DropdownComponent
              dataSource={ArrestCategoryCode.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValue("ArrestCategoryCode", e);
              }}
              value={
                Mainfields.InvolvementTab.Arrestinformation.ArrestCategoryCode
              }
              fieldName="Arrest Category Code"
            />
            <DropdownComponent
              dataSource={ReasonHeld.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValue("ReasonHeld", e);
              }}
              value={Mainfields.InvolvementTab.Arrestinformation.ReasonHeld}
              fieldName="Reason Held"
            />
          </div>

          <TextAreaComponent
            value={
              Mainfields.InvolvementTab.Arrestinformation.ReasonHeldDetails
            }
            fieldName="Reason Held - Details"
            onChangeValue={(e) => {
              onChangeValue("ReasonHeldDetails", e);
            }}
          />
          <hr />

          <WarrantNumber
            Mainfields={Mainfields}
            setMainfields={setMainfields}
          />
        </div>

        <div style={{ width: "100%" }}>
          <p className="subheader_text">Arrest Location</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <DropdownComponent
              value={Mainfields.InvolvementTab.Arrestinformation.AddressType}
              handleRowClick={(e) => {
                onChangeValue("AddressType", e);
              }}
              dataSource={RaceDataData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Address Type"
            />
            <DropdownComponent
              handleRowClick={(e) => {
                let findaddres = SameasprimaryincidentaddressAllData.find(
                  (val) => val.LocationId == e
                );

                setMainfields({
                  ...Mainfields,
                  InvolvementTab: {
                    ...Mainfields.InvolvementTab,
                    onChange: true,
                    Arrestinformation: {
                      ...Mainfields.InvolvementTab.Arrestinformation,
                      Sameasprimaryincidentaddress: e,
                      AddressType: findaddres?.AddressType
                        ? findaddres?.AddressType
                        : "",
                      Apartment: findaddres?.HouseNumber
                        ? findaddres?.HouseNumber
                        : "",
                      StateProvidence: findaddres?.NcicstateId
                        ? findaddres?.NcicstateId
                        : "",
                      Street: findaddres?.Street2Name
                        ? findaddres?.Street2Name
                        : "",
                      StreetNumber: findaddres?.Street1Name
                        ? findaddres?.Street1Name
                        : "",
                      StreetPrefix: findaddres?.Ncicstreet1PrefixId
                        ? findaddres?.Ncicstreet1PrefixId
                        : "",
                      StreetSuffix: findaddres?.Ncicstreet1SuffixId
                        ? findaddres?.Ncicstreet1SuffixId
                        : "",
                      ZipCode: findaddres?.ZipCode ? findaddres?.ZipCode : "",
                      City: findaddres?.City ? findaddres?.City : "",
                      Country: findaddres?.NciccountryId
                        ? findaddres?.NciccountryId
                        : "",
                      GridNumber: findaddres?.Grid ? findaddres?.Grid : "",
                    },
                  },
                });
              }}
              value={
                Mainfields.InvolvementTab.Arrestinformation
                  .Sameasprimaryincidentaddress
              }
              dataSource={Sameasprimaryincidentaddress}
              fieldName="Same as primary incident address"
            />
            <div style={{ marginTop: "7%" }}>
              <CheckBoxComponent
                value={
                  Mainfields.InvolvementTab.Arrestinformation.UnknownAddress
                }
                fieldName="Unknown Address"
                onChangeValue={(e) => {
                  onChangeValue("UnknownAddress", e);
                }}
              />
            </div>
          </div>

          <div className="Involvement_left_row">
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.StreetNumber}
              onChangeValue={(e) => {
                onChangeValue("StreetNumber", e);
              }}
              fieldName="Street Number"
            />
            <DropdownComponent
              handleRowClick={(e) => {
                onChangeValue("StreetPrefix", e);
              }}
              value={Mainfields.InvolvementTab.Arrestinformation.StreetPrefix}
              dataSource={StreetPrefix.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Street Prefix"
            />
          </div>
          <div className="Involvement_left_row">
            <div style={{ width: "20%" }}>
              <TextBoxComponent
                onChangeValue={(e) => {
                  onChangeValue("Street", e);
                }}
                value={Mainfields.InvolvementTab.Arrestinformation.Street}
                fieldName="Street"
              />
            </div>

            <DropdownComponent
              value={Mainfields.InvolvementTab.Arrestinformation.StreetSuffix}
              handleRowClick={(e) => {
                onChangeValue("StreetSuffix", e);
              }}
              dataSource={StreetSuffix.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Street Suffix"
            />
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.Apartment}
              onChangeValue={(e) => {
                onChangeValue("Apartment", e);
              }}
              fieldName="Apartment"
            />
          </div>
          <div className="Involvement_left_row">
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.City}
              fieldName="City"
              onChangeValue={(e) => {
                onChangeValue("City", e);
              }}
            />
            <DropdownComponent
              value={
                Mainfields.InvolvementTab.Arrestinformation.StateProvidence
              }
              dataSource={StateCode.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValue("StateProvidence", e);
              }}
              fieldName="State / Providence"
            />
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.ZipCode}
              onChangeValue={(e) => {
                onChangeValue("ZipCode", e);
              }}
              fieldName="Zip Code"
            />
          </div>
          <div className="Involvement_left_row">
            <DropdownComponent
              value={Mainfields.InvolvementTab.Arrestinformation.Country}
              handleRowClick={(e) => {
                onChangeValue("Country", e);
              }}
              dataSource={Country.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Country"
            />
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Arrestinformation.GridNumber}
              onChangeValue={(e) => {
                const value = e;
                if (/^\d{0,5}$/.test(value)) {
                  onChangeValue("GridNumber", value);
                }
              }}
              fieldName="Grid Number"
            />
          </div>
        </div>
      </div>
    </>
  );
};
