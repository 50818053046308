import { Fetcher } from "../../../../../../../services/Fetcher";
import {
  combineDateTimeToISO,
  convertToISOFormatDateOnly,
  formatDate,
} from "../../../../../../../utils/Helper/Helper";
import { ParticipantionvalidationIRes } from "../AddNewNameI";
import { MultiAliasesIState } from "./Components/MultiAliases/MultiAliasesI";
import { MultipleAddressIState } from "./Components/MultipleAddress/MultipleAddressI";
import {
  GetRaceDataIState,
  InvolvementTab,
  InvolvementSaveIRes,
  getInvolvementIRes,
  getAddressIRes,
  ScarsMarksTattoosIRes,
  aliaseinformationIRes,
  getVictimsIRes,
  getArrestIRes,
  getBiasesIRes,
  getMatchesIRes,
  SetTableData,
  SameasprimaryincidentaddressIRes,
  setSameasprimaryincidentaddressIState,
} from "./InvolvementMainI";

const fetcher = new Fetcher();
export class InvolvementMainFunction {
  async getMatches(
    FirstName: string,
    LastName: string,
    MiddleName: string,
    DOB: string,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    setTableData: (data: SetTableData[]) => void,
    PageNumber: number,
    PageSize: number
  ) {
    setLoading(true);
    const response = await fetcher.post(`/matches/person/search`, {
      FirstName: FirstName ? FirstName : null,
      LastName: LastName ? LastName : null,
      MiddleName: MiddleName ? MiddleName : null,
      DOB: DOB ? convertToISOFormatDateOnly(DOB) : null,

      PageNumber: PageNumber,
      PageSize: PageSize,
    });
    setTableTotalRecord(response?.TotalRecords ? response?.TotalRecords : 0);
    const TableJson = this.createTableJson(response?.Data);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: getMatchesIRes[]) {
    return data?.map((val: getMatchesIRes, i: number) => {
      return {
        "First Name": val.PersonGivenName ? val.PersonGivenName : "",
        "Last Name": val.PersonSurName ? val.PersonSurName : "",
        "Middle Name": val.PersonMiddleName ? val.PersonMiddleName : "",
        DOB: val.DOB ? formatDate(val.DOB) : "",
        NameMasterID: val.NameMasterID,
        Address: "123 New York Street, New York",
      };
    });
  }

  async getLocationType(setLocationData: (data: GetRaceDataIState[]) => void) {
    const response = await fetcher.get("/code/ncic/category/LocationType");
    let res: GetRaceDataIState[] = response.Data;
    setLocationData(res);
  }

  async getSuffix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/NameSuffix");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getScarsMarks(
    setScars: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/ScarsTattoosMarks");
    let res: GetRaceDataIState[] = response.Data;
    setScars(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getScarsMarksLocation(
    setScarsMarksLocation: (data: GetRaceDataIState[]) => void
  ) {
    const response = await fetcher.get(
      "/code/ncic/category/ScarsTattoosLocation"
    );
    let res: GetRaceDataIState[] = response.Data;
    setScarsMarksLocation(res);
  }

  async getBiasBasedOn(
    setBiasBasedOn: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/BiasBasedOn");
    let res: GetRaceDataIState[] = response.Data;
    setBiasBasedOn(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getRaceData(
    setRaceDataData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/PersonRoleIncidentToPersonAssociation"
    );
    let res: GetRaceDataIState[] = response.Data;
    setRaceDataData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getAddresType(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/AddressType");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getStreetPrefix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/StreetPreDirection"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getRelationData(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/VictimToOffenderRelationship"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getStreetSuffix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/StreetPostDirection"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getState(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/StateCode");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async ReasonHeld(
    setReasonHeld: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/ReasonHeld");
    let res: GetRaceDataIState[] = response.Data;
    setReasonHeld(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async Sameasprimaryincidentaddress(
    setSameasprimaryincidentaddress: (
      data: setSameasprimaryincidentaddressIState[]
    ) => void,
    setSameasprimaryincidentaddressAllData: (
      data: SameasprimaryincidentaddressIRes[]
    ) => void,
    incidentId: string | undefined
  ) {
    if (!incidentId) {
      return;
    }
    const response = await fetcher.post("/commonentity/getaddresses", {
      EntityType: "Incidents",
      EntityId: incidentId,
    });
    let res: SameasprimaryincidentaddressIRes[] = response.Data;

    setSameasprimaryincidentaddress(
      res.map((val) => {
        return {
          value: `${val.HouseNumber} ${val.Street1Name} ${val.City} ${val.StateName} ${val.ZipCode}`,
          id: val.LocationId,
        };
      })
    );

    setSameasprimaryincidentaddressAllData(res);
  }

  async getCountry(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Country");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getMeasurement(
    setMeasurement: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(`/code/system/category/Measurement`);
    setMeasurement(response?.Data);
    setLoading(false);
  }

  async getCountryCode(
    setCountryCode: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(`/code/ncic/category/county`);
    setCountryCode(response?.Data);
    setLoading(false);
  }
  async getSex(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Sex");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getRace(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Race");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getEyeColor(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/EyeColor");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getHair(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/EyeColor");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getSkin(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Complexion");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getBuild(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Build");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getInvolvement(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/person`
    );

    let resInvolvement: getInvolvementIRes = response.Data;
    let resAddress = await this.getAddress(participantId);
    let resScarsMarksTattoos = await this.getScarsMarksTattoos(
      incidentId,
      participantId
    );
    let resaliase = await this.getaliase(incidentId, participantId);

    let resVictims =
      resInvolvement?.ParticipantRole?.RoleId ==
        "bf4d076f-7c65-48f9-b6d6-29afa3d693bc" &&
      (await this.getVictims(incidentId, participantId));
    let resArrest =
      resInvolvement.ParticipantRole.RoleId ==
        "667F4DAA-DFE0-491E-9B67-C3847D5CAC0A".toLowerCase() &&
      (await this.getArrest(incidentId, participantId));
    let resBiases = await this.getBiases(incidentId, participantId);

    return {
      resInvolvement,
      resAddress,
      resScarsMarksTattoos,
      resaliase,
      resVictims,
      resArrest,
      resBiases,
    };
  }

  async getBiases(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/offender-affiliation`
    );
    let res: getBiasesIRes[] = response.Data;
    return res;
  }

  async getVictims(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/victim-information`
    );
    let res: getVictimsIRes = response.Data;
    return res;
  }

  async getArrest(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/arrestee`
    );
    let res: getArrestIRes = response.Data;
    return res;
  }

  async getAddress(participantId: string) {
    let response = await fetcher.post(
      `/commonentity/getaddresses`,

      {
        EntityType: "Participant",
        EntityId: participantId,
        EntitySubType: null,
        EntitySubTypeId: null,
      }
    );
    let res: getAddressIRes[] = response.Data;
    return res;
  }

  async getScarsMarksTattoos(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/physicalfeature`
    );
    let res: ScarsMarksTattoosIRes[] = response.Data;
    return res;
  }

  async getaliase(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/aliaseinformation`
    );
    let res: aliaseinformationIRes[] = response.Data;
    return res;
  }

  async InvolvementSave(
    setLoading: (data: boolean) => void,
    fields: InvolvementTab,
    incidentId: string,
    RediretToEDitPage: (data: string) => void,
    Participantionvalidation: ParticipantionvalidationIRes,
    setParticipantionvalidation: (data: ParticipantionvalidationIRes) => void
  ) {
    setParticipantionvalidation({
      ...Participantionvalidation,
      Involvement: {
        ...Participantionvalidation.Involvement,
        Firstname: fields.Involvement.Firstname ? false : true,
        LastName: fields.Involvement.LastName ? false : true,
        InvolvementType: fields.Involvement.InvolvementType ? false : true,
      },
    });

    let body = {
      ParticipantDetail: {
        ParticipantId: fields.Involvement.ParticipantId
          ? fields.Involvement.ParticipantId
          : null,
        CitationId: fields.Involvement.CitationId
          ? fields.Involvement.CitationId
          : 0,
        NameId: fields.Involvement.NameId ? fields.Involvement.NameId : null,
        EntityTypeId: fields.Involvement.EntityTypeId
          ? fields.Involvement.EntityTypeId
          : 0,
        PersonId: fields.Involvement.PersonId
          ? fields.Involvement.PersonId
          : null,
        BusinessId: fields.Involvement.BusinessId
          ? fields.Involvement.BusinessId
          : null,
      },
      PersonalInfo: {
        PersonId: fields.Involvement.PersonId
          ? fields.Involvement.PersonId
          : null,
        MasterNameId: fields.Involvement.MasterNameId
          ? fields.Involvement.MasterNameId
          : null,
        NameUnknown: true,
        FirstName: fields.Involvement.Firstname
          ? fields.Involvement.Firstname
          : null,
        MiddleName: fields.Involvement.Middlename
          ? fields.Involvement.Middlename
          : null,
        LastName: fields.Involvement.LastName
          ? fields.Involvement.LastName
          : null,
        NcicnameSuffixCodeId: fields.Involvement.Suffix
          ? fields.Involvement.Suffix
          : null,
        Dob: fields.Involvement.DOB
          ? convertToISOFormatDateOnly(fields.Involvement.DOB)
          : null,
        BirthplaceCity: fields.Demographics.BirthplaceCIty
          ? fields.Demographics.BirthplaceCIty
          : null,
        BirthDateRefused: fields.Involvement.DOBRefused
          ? fields.Involvement.DOBRefused
          : null,
        Height:
          fields.Demographics.Height == "Unknown"
            ? null
            : fields.Demographics.Height,
        Weight:
          fields.Demographics.Weight == "Unknown"
            ? null
            : fields.Demographics.Weight,
        NcicbuildCodeCodeId: fields.Demographics.Build
          ? fields.Demographics.Build
          : null,
        NcicraceCodeCodeId: fields.Demographics.Race
          ? fields.Demographics.Race
          : null,
        NcicsexCodeCodeId: fields.Demographics.Sex
          ? fields.Demographics.Sex
          : null,
        NcichairColorCodeCodeId: fields.Demographics.Hair
          ? fields.Demographics.Hair
          : null,
        NciceyeColorCodeCodeId: fields.Demographics.Eye
          ? fields.Demographics.Eye
          : null,
        FacialHair: fields.Demographics.FacialHair == "1" ? true : false,
        Glasses: fields.Demographics.Glasses == "1" ? true : false,
        NciccomplexionCodeCodeId: fields.Demographics.SkinTon
          ? fields.Demographics.SkinTon
          : null,
        IsHandedness: fields.Demographics.Handedness == "1" ? true : false,
        IsJuvenile: fields.Involvement.Juvenile
          ? fields.Involvement.Juvenile
          : null,
        ConfidentialInformant: fields.Involvement.Private
          ? fields.Involvement.Private
          : null,
        UnknownPerson: null,
        NotKnownAddress: fields.Address.UnknownAddress
          ? fields.Address.UnknownAddress
          : null,
        NoPermanentAddress: fields.Address.NoPermanentAddress
          ? fields.Address.NoPermanentAddress
          : null,
        IsDodunknown: null,
        IsCitizenshipUnknown: null,
        IsDeceased: null,
        IsDisabled: null,
        ProcOri: fields.Involvement.ProcORI ? fields.Involvement.ProcORI : null,
        IsAgeDOBUnknown: fields.Involvement.DOBUnknown,

        MinAge: fields.Involvement.MinAge
          ? Number(fields.Involvement.MinAge)
          : null,
        MaxAge: fields.Involvement.MaxAge
          ? Number(fields.Involvement.MaxAge)
          : null,
        MinHeight: fields.Demographics.MinHeight
          ? Number(fields.Demographics.MinHeight)
          : null,
        MinWeight: fields.Demographics.MinWeight
          ? Number(fields.Demographics.MinWeight)
          : null,
        MaxHeight: fields.Demographics.MaxHeight
          ? Number(fields.Demographics.MaxHeight)
          : null,
        MaxWeight: fields.Demographics.MaxWeight
          ? Number(fields.Demographics.MaxWeight)
          : null,
        Age: fields.Involvement.Age ? Number(fields.Involvement.Age) : null,

        InCustody: fields.Involvement.InCustody
          ? fields.Involvement.InCustody
          : null,
        IsMissingRunaway: fields.Involvement.MissingRunaway,
        NcicbirthplaceCountryCodeId: fields.Demographics.Country
          ? fields.Demographics.Country
          : null,
        NcicbirthplaceStateCodeId: fields.Demographics.StateProvidence
          ? fields.Demographics.StateProvidence
          : null,
      },
      NameMasterDetail: {
        NameId: null,
        PersonGivenName: fields.Involvement.Firstname
          ? fields.Involvement.Firstname
          : null,
        PersonMiddleName: fields.Involvement.Middlename
          ? fields.Involvement.Middlename
          : null,
        PersonSurName: fields.Involvement.LastName
          ? fields.Involvement.LastName
          : null,
        PersonNameSuffixText: 0,
        Dob: fields.Involvement.DOB
          ? convertToISOFormatDateOnly(fields.Involvement.DOB)
          : null,
      },
      ParticipantRole: {
        ParticipantRoleId: fields.Involvement.ParticipantRoleId
          ? fields.Involvement.ParticipantRoleId
          : null,
        ParticipantId: fields.Involvement.ParticipantId
          ? fields.Involvement.ParticipantId
          : null,
        RoleId: fields.Involvement.InvolvementType,
      },
    };
    if (this.InvolvementValidation(fields)) {
      setLoading(true);
      let response = await fetcher.post(
        `/incident/${incidentId}/participant`,
        body
      );
      let res: InvolvementSaveIRes = response.Data;

      await this.AddressSave(
        setLoading,
        fields,
        res.ParticipantId,
        incidentId,
        Participantionvalidation,
        setParticipantionvalidation
      );

      await this.AliasesSave(
        setLoading,
        fields,
        res.ParticipantId,
        res.PersonId,
        incidentId
      );
      fields.Involvement.InvolvementType ==
        "bf4d076f-7c65-48f9-b6d6-29afa3d693bc" &&
        (await this.victiminformationSave(
          setLoading,
          fields,
          res.ParticipantId,
          res.PersonId,
          incidentId
        ));

      fields.Involvement.InvolvementType ==
        "667F4DAA-DFE0-491E-9B67-C3847D5CAC0A".toLowerCase() &&
        (await this.ArrestinformationSave(
          setLoading,
          fields,
          res.ParticipantId,
          res.PersonId,
          incidentId
        ));

      await this.physicalfeatureSave(
        setLoading,
        fields,
        res.ParticipantId,
        incidentId,
        res.PersonId,
        setParticipantionvalidation,
        Participantionvalidation
      );
      await this.BiasesSave(setLoading, fields, res.ParticipantId, incidentId);
      setLoading(false);
      RediretToEDitPage(res.ParticipantId);
    }
  }

  async AddressSave(
    setLoading: (data: boolean) => void,
    fields: InvolvementTab,
    ParticipantId: string,
    incidentId: string,
    Participantionvalidation: ParticipantionvalidationIRes,
    setParticipantionvalidation: (data: ParticipantionvalidationIRes) => void
  ) {
    setLoading(true);

    await fetcher.post(`/commonentity/address`, {
      Addresses: fields.Address.AddressData.map((val) => {
        if (
          this.isAddressData(val) &&
          !val.ForeignAddress &&
          val.LocationCategoryCode
        ) {
          return {
            LocationId: val.LocationId ? val.LocationId : null,
            AgencyId: val.AgencyId ? val.AgencyId : null,
            IncidentId: incidentId,
            EntityTypeId: 3,
            EntityId: ParticipantId,
            AddressType: val.AddressType ? val.AddressType : null,
            LocationCategoryCode: val.LocationCategoryCode
              ? val.LocationCategoryCode
              : null,
            City: val.City ? val.City : null,
            HouseNumber: val.Apartment ? val.Apartment : null,
            Ncicstreet1PrefixId: val.StreetPrefix ? val.StreetPrefix : null,
            Street1Name: val.StreetNumber ? val.StreetNumber : null,
            Ncicstreet1SuffixId: val.StreetSuffix ? val.StreetSuffix : null,
            Street2Name: val.Street ? val.Street : null,
            NcicstateId: val.StateProvidence ? val.StateProvidence : null,
            ZipCode: val.ZipCode ? val.ZipCode : null,
            Grid: val.GridNumber ? val.GridNumber : null,
            NciccountryId: val.Country ? val.Country : null,

            Address1: val.AddressLine1 ? val.AddressLine1 : null,
            Address2: val.AddressLine2 ? val.AddressLine2 : null,
            OtherCity: val.ForeignAddressCity ? val.ForeignAddressCity : null,
            OtherState: val.ForeignAddressStateProvinceRegion
              ? val.ForeignAddressStateProvinceRegion
              : null,
            OtherZip: val.ForeignAddressZip ? val.ForeignAddressZip : null,
            OtherCountry: val.ForeignAddressCountry
              ? val.ForeignAddressCountry
              : null,
            IsForeignAddress: val.ForeignAddress ? val.ForeignAddress : false,
          };
        } else if (this.isAddressData(val) && val.ForeignAddress) {
          return {
            LocationId: val.LocationId ? val.LocationId : null,
            AgencyId: val.AgencyId ? val.AgencyId : null,
            IncidentId: incidentId,
            EntityTypeId: 3,
            EntityId: ParticipantId,
            AddressType: val.AddressType ? val.AddressType : null,
            LocationCategoryCode: val.LocationCategoryCode
              ? val.LocationCategoryCode
              : null,
            City: val.City ? val.City : null,
            HouseNumber: val.Apartment ? val.Apartment : null,
            Ncicstreet1PrefixId: val.StreetPrefix ? val.StreetPrefix : null,
            Street1Name: val.StreetNumber ? val.StreetNumber : null,
            Ncicstreet1SuffixId: val.StreetSuffix ? val.StreetSuffix : null,
            Street2Name: val.Street ? val.Street : null,
            NcicstateId: val.StateProvidence ? val.StateProvidence : null,
            ZipCode: val.ZipCode ? val.ZipCode : null,
            Grid: val.GridNumber ? val.GridNumber : null,
            NciccountryId: val.Country ? val.Country : null,

            Address1: val.AddressLine1 ? val.AddressLine1 : null,
            Address2: val.AddressLine2 ? val.AddressLine2 : null,
            OtherCity: val.ForeignAddressCity ? val.ForeignAddressCity : null,
            OtherState: val.ForeignAddressStateProvinceRegion
              ? val.ForeignAddressStateProvinceRegion
              : null,
            OtherZip: val.ForeignAddressZip ? val.ForeignAddressZip : null,
            OtherCountry: val.ForeignAddressCountry
              ? val.ForeignAddressCountry
              : null,
            IsForeignAddress: val.ForeignAddress ? val.ForeignAddress : false,
          };
        } else if (
          !this.isAddressData(val) &&
          !val.ForeignAddress &&
          !val.LocationCategoryCode
        ) {
          setParticipantionvalidation({
            ...Participantionvalidation,
            Involvement: {
              ...Participantionvalidation.Involvement,
              locationcatigory: true,
            },
          });
        }
      }).filter((val) => val),
      EntityModel: {
        EntityType: "Participant",
        EntityId: ParticipantId,
        EntitySubType: null,
        EntitySubTypeId: null,
      },
    });

    setLoading(false);
  }

  async BiasesSave(
    setLoading: (data: boolean) => void,
    fields: InvolvementTab,
    ParticipantId: string,
    incidentId: string
  ) {
    setLoading(true);
    this.BiasesValidation(fields) &&
      (await fetcher.post(
        `/incident/${incidentId}/participant/${ParticipantId}/offender-affiliation`,
        fields.Biases.Biastype.map((val) => {
          if (val.Biastype) {
            return {
              ParticipantId: ParticipantId,
              NcicbiasOffenderGroupAffiliationCodeId: val.Biastype
                ? val.Biastype
                : null,
            };
          }
        })
          .filter((val) => val)
          .filter(
            (item, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t?.NcicbiasOffenderGroupAffiliationCodeId ===
                  item?.NcicbiasOffenderGroupAffiliationCodeId
              )
          )
      ));
    setLoading(false);
  }

  async AliasesSave(
    setLoading: (data: boolean) => void,
    fields: InvolvementTab,
    ParticipantId: string,
    PersonId: string,
    incidentId: string
  ) {
    setLoading(true);
    await fetcher.post(
      `/incident/${incidentId}/participant/${ParticipantId}/aliaseinformation`,
      fields.Aliases.map((val) => {
        if (this.isAliasesData(val)) {
          return {
            AliasNameId: val.AliasNameId ? val.AliasNameId : null,
            PersonId: PersonId,
            FirstName: val.Firstname ? val.Firstname : null,
            MiddleName: val.Middlename ? val.Middlename : null,
            LastName: val.LastName ? val.LastName : null,
            Dob: val.DOB ? convertToISOFormatDateOnly(val.DOB) : null,
          };
        }
      }).filter((val) => val)
    );

    setLoading(false);
  }

  victiminformation(fields: InvolvementTab) {
    let AggravatedAssaulthomicideCodetemp = false;
    let AdditionalJustifiablehomicidetemp = false;
    let InjuryCategoryCodetemp = false;

    fields.Victiminformation.AggravatedAssaulthomicideCode.map((val) => {
      if (val.AggravatedAssaulthomicideCode) {
        AggravatedAssaulthomicideCodetemp = true;
      }
    });

    fields.Victiminformation.AdditionalJustifiablehomicide.map((val) => {
      if (val.AdditionalJustifiablehomicide) {
        AdditionalJustifiablehomicidetemp = true;
      }
    });

    fields.Victiminformation.InjuryCategoryCode.map((val) => {
      if (val.InjuryCategoryCode) {
        InjuryCategoryCodetemp = true;
      }
    });

    return (
      AggravatedAssaulthomicideCodetemp ||
      AdditionalJustifiablehomicidetemp ||
      InjuryCategoryCodetemp ||
      fields.Victiminformation.VictimType ||
      fields.Victiminformation.AgencyId ||
      fields.Victiminformation.VictimType ||
      fields.Victiminformation.EnforcementOfficialActivityCategoryCode ||
      fields.Victiminformation.EnforcementOfficialAssignmentCategoryCode
    );
  }

  async victiminformationSave(
    setLoading: (data: boolean) => void,
    fields: InvolvementTab,
    ParticipantId: string,
    PersonId: string,
    incidentId: string
  ) {
    setLoading(true);
    this.victiminformation(fields) &&
      (await fetcher.post(
        `/incident/${incidentId}/participant/${ParticipantId}/victim-information`,
        {
          Victim: {
            VictimId: fields.Victiminformation.VictimId
              ? fields.Victiminformation.VictimId
              : null,
            AgencyId: fields.Victiminformation.AgencyId
              ? fields.Victiminformation.AgencyId
              : null,
            IncidentId: incidentId,
            PersonId: PersonId,
            OfficerOtherJurisdiction:
              fields.Victiminformation.OfficerOtherJurisdiction,
            VictimTypeCodeId: fields.Victiminformation.VictimType
              ? fields.Victiminformation.VictimType
              : null,
            OfficerActivityCodeId: fields.Victiminformation
              .EnforcementOfficialActivityCategoryCode
              ? fields.Victiminformation.EnforcementOfficialActivityCategoryCode
              : null,
            OfficerAssignmentTypeCodeId: fields.Victiminformation
              .EnforcementOfficialAssignmentCategoryCode
              ? fields.Victiminformation
                  .EnforcementOfficialAssignmentCategoryCode
              : null,
          },
          AggravatedAssaultHomicides:
            fields.Victiminformation.AggravatedAssaulthomicideCode.map(
              (val) => {
                if (val.AggravatedAssaulthomicideCode) {
                  return {
                    AggravatedAssaultHomicideId: val.AggravatedAssaultHomicideId
                      ? val.AggravatedAssaultHomicideId
                      : null,
                    AgencyId: fields.Victiminformation.AgencyId
                      ? fields.Victiminformation.AgencyId
                      : null,
                    IncidentId: incidentId,
                    PersonId: PersonId,
                    AggravatedAssaultHomicideCodeId:
                      val.AggravatedAssaulthomicideCode
                        ? val.AggravatedAssaulthomicideCode
                        : null,
                  };
                }
              }
            ).filter((val) => val),

          AdditionalJustifiableHomicides:
            fields.Victiminformation.AdditionalJustifiablehomicide.map(
              (val) => {
                if (val.AdditionalJustifiablehomicide) {
                  return {
                    AdditionalJustifiableHomicideId:
                      val.AdditionalJustifiableHomicideId
                        ? val.AdditionalJustifiableHomicideId
                        : null,
                    AgencyId: fields.Victiminformation.AgencyId
                      ? fields.Victiminformation.AgencyId
                      : null,
                    IncidentId: incidentId,
                    PersonId: PersonId,
                    AdditionalJustifiableHomicideCodeId:
                      val.AdditionalJustifiablehomicide
                        ? val.AdditionalJustifiablehomicide
                        : null,
                  };
                }
              }
            ).filter((val) => val),

          Injuries: fields.Victiminformation.InjuryCategoryCode.map((val) => {
            if (val.InjuryCategoryCode) {
              return {
                InjuryId: val.InjuryId ? val.InjuryId : null,
                AgencyId: fields.Victiminformation.AgencyId
                  ? fields.Victiminformation.AgencyId
                  : null,
                IncidentId: incidentId,
                PersonId: PersonId,
                InjuryCodeId: val.InjuryCategoryCode,
              };
            }
          }).filter((val) => val),
        }
      ));

    setLoading(false);
  }

  async ArrestinformationSave(
    setLoading: (data: boolean) => void,
    fields: InvolvementTab,
    ParticipantId: string,
    PersonId: string,
    incidentId: string
  ) {
    setLoading(true);

    await fetcher.post(
      `/incident/${incidentId}/participant/${ParticipantId}/arrestee`,
      {
        ArrestId: fields.Arrestinformation.ArrestId
          ? fields.Arrestinformation.ArrestId
          : null,
        ArrestTypeCodeId: fields.Arrestinformation.ArrestCategoryCode
          ? fields.Arrestinformation.ArrestCategoryCode
          : null,
        ArrestDateTime:
          fields.Arrestinformation.ArrestedDate &&
          fields.Arrestinformation.ArrestedTime
            ? combineDateTimeToISO(
                fields.Arrestinformation.ArrestedDate,
                fields.Arrestinformation.ArrestedTime
              )
            : null,
        IsPhotographed: fields.Arrestinformation.Photographed,
        BookAndRelease: fields.Arrestinformation.BookandRelease,
        ReasonHeldDetail: fields.Arrestinformation.ReasonHeldDetails
          ? fields.Arrestinformation.ReasonHeldDetails
          : null,
        ReasonHeld: fields.Arrestinformation.ReasonHeld
          ? fields.Arrestinformation.ReasonHeld
          : null,
        UnknownAddress: fields.Arrestinformation.UnknownAddress,
        InCustody: fields.Arrestinformation.InCustody,
        ArresteeWasArmedWithType: fields.Arrestinformation.ArmedWithWeapon.map(
          (val, i) => {
            if (val.ArmedWithWeapon || val.Weaponautomatic) {
              return {
                ArresteeWasArmedWithTypeId: val.ArresteeWasArmedWithTypeId
                  ? val.ArresteeWasArmedWithTypeId
                  : null,
                ArresteeArmedWithCodeId: val.ArmedWithWeapon
                  ? val.ArmedWithWeapon
                  : null,
                IsAutomatic: val.Weaponautomatic,
              };
            }
          }
        ).filter((val) => val),
        Address: [
          {
            LocationId: fields.Arrestinformation.LocationId
              ? fields.Arrestinformation.LocationId
              : null,
            IncidentId: incidentId,
            AddressType: fields.Arrestinformation.AddressType
              ? fields.Arrestinformation.AddressType
              : null,

            HouseNumber: fields.Arrestinformation.Apartment
              ? fields.Arrestinformation.Apartment
              : null,
            Ncicstreet1PrefixId: fields.Arrestinformation.StreetPrefix
              ? fields.Arrestinformation.StreetPrefix
              : null,
            Street1Name: fields.Arrestinformation.StreetNumber
              ? fields.Arrestinformation.StreetNumber
              : null,
            Ncicstreet1SuffixId: fields.Arrestinformation.StreetSuffix
              ? fields.Arrestinformation.StreetSuffix
              : null,

            Street2Name: fields.Arrestinformation.Street
              ? fields.Arrestinformation.Street
              : null,
            Ncicstreet2SuffixId: null,
            City: fields.Arrestinformation.City
              ? fields.Arrestinformation.City
              : null,

            NcicstateId: fields.Arrestinformation.StateProvidence
              ? fields.Arrestinformation.StateProvidence
              : null,
            ZipCode: fields.Arrestinformation.ZipCode
              ? fields.Arrestinformation.ZipCode
              : null,
            Grid: fields.Arrestinformation.GridNumber
              ? fields.Arrestinformation.GridNumber
              : null,

            NciccountryId: fields.Arrestinformation.Country
              ? fields.Arrestinformation.Country
              : null,
          },
        ],
        WarrantViewModel: fields.Arrestinformation.WarrantNumber.map((val) => {
          return {
            WarrantId: val.WarrantId
              ? val.WarrantId
              : "00000000-0000-0000-0000-000000000000",
            Number: val.WarrantNumber,
          };
        }),
      }
    );

    setLoading(false);
  }

  async physicalfeatureSave(
    setLoading: (data: boolean) => void,
    fields: InvolvementTab,
    ParticipantId: string,
    incidentId: string,
    PersonId: string,
    setParticipantionvalidation: (data: ParticipantionvalidationIRes) => void,
    Participantionvalidation: ParticipantionvalidationIRes
  ) {
    let temp = false;
    fields.ScarsMarksTattoos.map((val) => {
      if (val.Notes && val.ScarMarkTattoos) {
        temp = true;
      }
    });
    setLoading(true);
    temp
      ? await fetcher.post(
          `/incident/${incidentId}/participant/${ParticipantId}/physicalfeature`,
          fields.ScarsMarksTattoos.map((val) => {
            if (val.Notes && val.ScarMarkTattoos) {
              return {
                PersonId: PersonId ? PersonId : null,
                PhysicalFeatureId: val.PhysicalFeatureId
                  ? val.PhysicalFeatureId
                  : null,
                PhysicalFeatureNciccodeId: val.ScarMarkTattoos
                  ? val.ScarMarkTattoos
                  : null,
                PhysicalFeatureText: val.Notes ? val.Notes : null,
                PhysicalFeatureLocationNciccodeId: val.TypeLocation
                  ? val.TypeLocation
                  : null,
              };
            }
          }).filter((val) => val)
        )
      : setParticipantionvalidation({
          ...Participantionvalidation,
          Involvement: {
            ...Participantionvalidation.Involvement,
            ScarsMarksandTattoos: true,
          },
        });
    setLoading(false);
  }

  AddressValidation(fields: InvolvementTab) {
    let temp = false;
    fields.Address.AddressData.map((val) => {
      if (
        val.AddressLine1 ||
        val.AddressLine2 ||
        val.AddressType ||
        val.AgencyId ||
        val.Apartment ||
        val.City ||
        val.Country ||
        val.ForeignAddress ||
        val.ForeignAddressCity ||
        val.ForeignAddressCountry ||
        val.ForeignAddressStateProvinceRegion ||
        val.ForeignAddressZip ||
        val.GridNumber ||
        val.StateProvidence ||
        val.Street ||
        val.StreetNumber ||
        val.StreetPrefix ||
        val.StreetSuffix ||
        val.ZipCode
      ) {
        temp = true;
      }
    });
    return temp;
  }

  isAddressData(val: MultipleAddressIState) {
    return (
      val.AddressLine1 ||
      val.AddressLine2 ||
      val.AddressType ||
      val.AgencyId ||
      val.Apartment ||
      val.City ||
      val.Country ||
      val.ForeignAddress ||
      val.ForeignAddressCity ||
      val.ForeignAddressCountry ||
      val.ForeignAddressStateProvinceRegion ||
      val.ForeignAddressZip ||
      val.GridNumber ||
      val.StateProvidence ||
      val.Street ||
      val.StreetNumber ||
      val.StreetPrefix ||
      val.StreetSuffix ||
      val.ZipCode ||
      val.LocationCategoryCode
    );
  }

  AliasesValidation(fields: InvolvementTab) {
    let temp = false;
    fields.Aliases.map((val) => {
      if (val.DOB || val.LastName || val.Firstname || val.Middlename) {
        temp = true;
      }
    });
    return temp;
  }

  isAliasesData(val: MultiAliasesIState) {
    return val.DOB || val.LastName || val.Firstname || val.Middlename;
  }

  BiasesValidation(fields: InvolvementTab) {
    let temp = false;
    fields.Biases.Biastype.map((val) => {
      if (val.Biastype) {
        temp = true;
      }
    });
    return fields.Biases.BiasOffender && temp;
  }

  InvolvementValidation(fields: InvolvementTab) {
    return (
      fields.Involvement.Firstname &&
      fields.Involvement.LastName &&
      fields.Involvement.InvolvementType
    );
  }
}
