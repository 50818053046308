import { Fetcher } from "../../../../services/Fetcher";
import { successCreatedMessage } from "../../../../utils/const";
import { SetAddCategoryIState } from "./AddCategoryI";

const fetcher = new Fetcher();

export class AddCategoryFunction {
  async addCategory(
    setLoading: (data: boolean) => void,
    formData: SetAddCategoryIState,
    //setshowAddEditView: (data: boolean) => void
    onCancel:()=>void,
    getCategoryData:()=>void,
    calledFromAgencyLevel: boolean,
  ) {
    setLoading(true);
    const payload = {
      CategoryName: formData.Category,
      Description: formData.Description,
      IsActive: formData.IsActive === "1",
    };
    const response = await fetcher.post(
      `${calledFromAgencyLevel ? `/category/agency`:'/category/system'}`,
      payload,
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    console.log('Response', response);
    setLoading(false);
    setLoading(false);
    getCategoryData();
    onCancel();
  }

  //   async editSystemLevel(
  //     setLoading: (data: boolean) => void,
  //     formData: SetFormDataIState,
  //     setshowAddEditView: (data: boolean) => void,
  //     getRowId: number
  //   ) {
  //     setLoading(true);
  //     const response = await fetcher.put(
  //       `/code`,
  //       {
  //         SystemLevelCodeId: getRowId,
  //         CodeCategoryId: formData.Category,
  //         ShortCode: formData.ShortCode,
  //         LongCode: formData.LongCode,
  //         Description: formData.Description,
  //         DisplayOrder: 0,
  //         Status: formData.IsActive == 1 ? true : false,
  //         ActiveDate: convertToISOFormat(formData.ActiveDate),
  //         ExpiryDate: formData.ExpiryDate
  //           ? convertToISOFormat(formData.ExpiryDate)
  //           : null,
  //       },
  //       {
  //         message: successUpdateMessage,
  //         show: true,
  //       }
  //     );
  //     setLoading(false);
  //     if (response?.Data?.SystemLevelCodeId) {
  //       setshowAddEditView(false);
  //     }
  //   }

  //   async getCategoryData(
  //     setcategoryData: (data: codecategory[]) => void,
  //     setLoading?: (data: boolean) => void
  //   ) {
  //     if (setLoading) {
  //       setLoading(true);
  //     }
  //     const response = await fetcher.post("/code/getallcodecategory", {});
  //     setcategoryData(
  //       response.Data?.map((val: getallcodecategory) => {
  //         return {
  //           CodeCategoryId: val.CodeCategoryId,
  //           CategoryName: val.CategoryName,
  //         };
  //       })
  //     );

  //     if (setLoading) {
  //       setLoading(false);
  //     }
  //   }

  //   async getSystemLevelData(
  //     setLoading: (data: boolean) => void,
  //     setFormData: (data: SetFormDataIState) => void,
  //     systemLevelCodeID: number,
  //     addprefill?: boolean
  //   ) {
  //     setLoading(true);
  //     let responce = await fetcher.get(
  //       `/code/systemLevelCode/${systemLevelCodeID}`
  //     );
  //     let {
  //       CodeCategoryId,
  //       ShortCode,
  //       LongCode,
  //       Description,
  //       IsActive,
  //       ActiveDate,
  //       ExpiryDate,
  //     } = responce?.Data;
  //     setFormData({
  //       ActiveDate: formatDate(ActiveDate),
  //       ExpiryDate: formatDate(ExpiryDate),
  //       Category: CodeCategoryId,
  //       Description: addprefill ? "" : Description,
  //       IsActive: IsActive ? 1 : 2,
  //       LongCode: addprefill ? "" : LongCode,
  //       ShortCode: addprefill ? "" : ShortCode,
  //     });

  //     setLoading(false);
  //   }

  //   errorValidation(
  //     formData: SetFormDataIState,
  //     setvalidation: (data: setvalidationIState) => void
  //   ) {
  //     let valdationerror: setvalidationIState = {
  //       ActiveDate: false,
  //       Category: false,
  //       Description: false,
  //       ExpiryDate: false,
  //       IsActive: false,
  //       LongCode: false,
  //       ShortCode: false,
  //     };

  //     if (!formData.Category) {
  //       valdationerror.Category = true;
  //     } else {
  //       valdationerror.Category = false;
  //     }
  //     if (!formData.Description) {
  //       valdationerror.Description = true;
  //     } else {
  //       valdationerror.Description = false;
  //     }

  //     if (!formData.IsActive) {
  //       valdationerror.IsActive = true;
  //     } else {
  //       valdationerror.IsActive = false;
  //     }

  //     if (!formData.LongCode) {
  //       valdationerror.LongCode = true;
  //     } else {
  //       valdationerror.LongCode = false;
  //     }

  //     if (!formData.ShortCode) {
  //       valdationerror.ShortCode = true;
  //     } else {
  //       valdationerror.ShortCode = false;
  //     }

  //     setvalidation({ ...valdationerror });
  //   }

  //   checkRequiredFields(formData: SetFormDataIState) {
  //     return (
  //       formData.Category &&
  //       formData.Description &&
  //       formData.IsActive &&
  //       formData.LongCode &&
  //       formData.ShortCode
  //     );
  //   }
}
