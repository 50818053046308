import delete_icon from "../../../../assets/icons/delete.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, SetTableData } from "./ListUserManagementI";

export const OrderByFieldName: OrderByFieldNameI = {
  "First Name": "FirstName",
  "Middle Name": "MiddleName",
  "Last Name": "LastName",
  Suffix: "Suffix",
  "Post No": "PostNumber",
  "Home Unit": "HomeUnitName",
  Rank: "RankName",
  Status: "IsActive",
  Badge: "BadgeNumber",
  "Last Login": "",
};

export const coloum = (
  NavigateToEditPage: (data: string) => void,
  setdeletePopup: (data: boolean) => void,
  setDeleteAgencyId: (id: string | null) => void,
  Update: boolean,
  Delete: boolean
): ColDef[] => {
  return [
    { field: "Last Name" },
    { field: "First Name" },
    { field: "Middle Name" },
    { field: "Suffix" },
    { field: "Post No" },
    { field: "Home Unit" },
    { field: "Rank" },
    { field: "Status" },
    { field: "Badge" },
    {
      field: "Last Login",
      valueFormatter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "MM-dd-yyyy");
        } else {
          return "";
        }
      },
    },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
              <img
                onClick={() => {
                  NavigateToEditPage(e?.data?.ID);
                }}
                className="table-cellRenderer-img"
                src={edit_icon}
                title="Edit"
              />
            )}
            {Delete && (
              <img
                onClick={() => {
                  setDeleteAgencyId(e?.data?.ID);
                  setdeletePopup(true);
                }}
                title="Delete"
                className="table-cellRenderer-img"
                src={delete_icon}
                alt="Delete"
              />
            )}
          </div>
        );
      },
    },
  ];
};
