import { Fetcher } from "../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import { IdentificationData } from "../../MasterNameIState";
import { IdentificationTableIRes } from "./IdentificationIState";

const fetcher = new Fetcher();

export class IdentificationMainFunction {
  async getIdentificationMain(
    setTableData: (data: IdentificationData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    try {
      let response = await fetcher.get(
        `/name-master/${nameId}/identifications`
      );
      let res: IdentificationTableIRes[] = response.Data;

      const TableJson = this.createTableJson(res);

      setTableTotalRecord(response?.TotalRecords);
      setTableData(TableJson);
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  }

  createTableJson(data: IdentificationTableIRes[]) {
    return data?.map((val: IdentificationTableIRes, i: number) => {
      return {
        Case: val.IncidentNumber,
        Date:formatDate(val.CreatedOn),
        Type:val.IDentificationCode,
        Number:val.PrimaryIDentification,
        Valid:formatDate(val.CreatedOn),
        "Expiration Date":formatDate(val.EffectiveEndDate),
      };
    });
  }
}
