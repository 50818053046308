import React, { useState } from "react";
import { ButtonComponents } from "../../../CommonComponents/Fields/Button/ButtonComponents";
import { useNavigate } from "react-router-dom";
import { TextBoxComponent } from "../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import add from "../../../../assets/icons/Icon.svg";
const MediaFilter = () => {
  const navigation = useNavigate();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");

  const handleClick = () => {
    navigation("/incidents/media/add");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds___">
          <TextBoxComponent value="" fieldName="Search Media" />

          <DropdownComponent
            dataSource={[
              { id: "all", value: "All" },
              { id: "both", value: "Both" },
              { id: "none", value: "None" },
            ]}
            fieldName="Media Type"
            value="All"
          />

          <div className="clearbutton1">
            <ButtonComponents
              name="Search"
              color="#3672b3"
              textColor="white"
              borderColor="#3672b3"
              showBackgroundColor={true}
            />
          </div>

          <div className="clearbutton1">
            <ButtonComponents
              name="Clear all Filters"
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>

        <div className="clearbutton">
          <ButtonComponents
            icon={add}
            name="Add Media"
            textColor="white"
            showBackgroundColor={true}
            handleClick={handleClick}
          />
        </div>
      </div>
    </>
  );
};

export default MediaFilter;
