import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface initialStateI {
  viewPolicyComponent: boolean;
  PolicyName: string;
  Description: string;
  Status: string;
  editPolicyComponent:boolean;
  addPolicyComponent:boolean;
  ModifiedByUserName:string;
  ModifiedOn:string;
}

interface setPolicyeditComponentPayload {
  editPolicyComponent: boolean;
  addPolicyComponent: boolean;
  viewPolicyComponent: boolean;
}

interface setPolicydataPayload {
  PolicyName: string;
  Description: string;
  Status: string;
  ModifiedByUserName:string;
  ModifiedOn:string;
}

const initialState: initialStateI = {
  editPolicyComponent: false,
  addPolicyComponent: true,
  viewPolicyComponent: false,
  PolicyName: "",
  Description: "",
  Status: "1",
  ModifiedOn:"",
  ModifiedByUserName:""
};

//slice

const slice = createSlice({
  name: "PolicyReducer",
  initialState,
  reducers: {
    setPolicyeditComponent(
      state,
      action: PayloadAction<setPolicyeditComponentPayload>
    ) {
      state.viewPolicyComponent = action.payload.viewPolicyComponent;
    },

    setPolicydata(state, action: PayloadAction<setPolicydataPayload>) {
      state.PolicyName = action.payload.PolicyName;
      state.Description = action.payload.Description;
      state.Status = action.payload.Status;
      state.ModifiedByUserName=action.payload.ModifiedByUserName;
      state.ModifiedOn=action.payload.ModifiedOn;
    },
  },
});

export const { setPolicyeditComponent, setPolicydata } = slice.actions;

export default slice.reducer;
