import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./AuditDetail.css";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import CommonIcons from "../Components/CommonIcons/CommonIcons";
import { AuditDetailFunction } from "./AuditDetailFunction";
import {
  formatDatewithTime,
  isValidDate,
} from "../../../../../utils/Helper/Helper";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

const AuditDetail = () => {
  const navigate = useNavigate();
  const { ipaddress, ModifiedByUserName, CreatedOn, ModifiedOn } = useSelector(
    (store: RootState) => store.AuditReducer
  );
  const auditDetailFunction = new AuditDetailFunction();
  const { ID } = useParams<{ ID: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const [dropdownOldNewvalue, setdropdownOldNewvalue] = useState<
    {
      [key: string]: { [key: string]: string };
    }[]
  >([]);

  const [oldValue, setoldValue] = useState<
    {
      [key: string]: { [key: string]: string | number | boolean }[];
    }[]
  >([]);
  const [newValue, setnewValue] = useState<
    {
      [key: string]: { [key: string]: string | number | boolean }[];
    }[]
  >([]);

  useEffect(() => {
    if (ID) {
      auditDetailFunction.getByAuditHistory(
        ID,
        setLoading,
        setoldValue,
        setnewValue,
        setdropdownOldNewvalue
      );
    }
  }, [ID]);

  const NavigateToAuditPage = () => {
    navigate("/audit");
  };

  const dateFormate = moment
    .utc(ModifiedOn)
    .local()
    .format("MM-DD-YYYY HH:mm:ss");

  console.log(dropdownOldNewvalue);
  return (
    <div className="AgencyManagement">
      <Loader loading={loading} />
      <div className="Audit-Header">
        <p className="Audit-Header-name">
          <BackButtonComponent
            name="Back to Audit List"
            onClick={NavigateToAuditPage}
          />
        </p>

        <div className="Image">
          <CommonIcons />
        </div>
      </div>

      {dropdownOldNewvalue?.map((val) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <p>
                {"Biastype "}:{val?.Biastype?.old}{" "}
              </p>
              <p>
                {"Biastype "}:{val?.Biastype?.new}{" "}
              </p>
            </div>
          </>
        );
      })}

      <div className="AuditDetail_main">
        <div className="AuditDetail_main_old">
          {oldValue.map((item) => {
            let keyData: string = "";
            let valueData: { [key: string]: string | number | boolean }[] = [];

            for (let key in item) {
              keyData = key;
              valueData = item[key];
            }

            return (
              <>
                <div className="AuditDetailOldData">
                  <p className="AuditDetailOldData_header">
                    {keyData}-(Old Value)
                  </p>
                  <div className="AuditDetailOldData_underline"></div>

                  {valueData.map(
                    (item2: { [key: string]: string | number | boolean }) => {
                      let valueDataObjectArray: {
                        [key: string]: string | number | boolean;
                      }[] = [];

                      for (let key1 in item2) {
                        if (
                          key1.toLocaleLowerCase().endsWith("id") ||
                          key1.toLocaleLowerCase() == "createdby" ||
                          key1.toLocaleLowerCase() == "createdon" ||
                          key1.toLocaleLowerCase() == "modifiedby" ||
                          key1.toLocaleLowerCase() == "modifiedon"
                        )
                          continue;
                        valueDataObjectArray.push({ [key1]: item2[key1] });
                      }
                      return (
                        <>
                          <div>
                            {valueDataObjectArray.map((item3) => {
                              let keyData2: string = "";
                              let valueData2: string | number | boolean = "";

                              for (let key2 in item3) {
                                keyData2 = key2;
                                valueData2 = item3[key2];
                              }

                              console.log(keyData2);

                              return (
                                <>
                                  <div className="AuditDetailOldData_keyvalue">
                                    <p className="lignt">{keyData2}</p>
                                    <p>
                                      :{" "}
                                      {`${
                                        isValidDate(valueData2)
                                          ? formatDatewithTime(
                                              String(valueData2)
                                            )
                                          : valueData2
                                      }`}
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              </>
            );
          })}
        </div>
        <div className="AuditDetail_main_old">
          {newValue.map((item) => {
            let keyData: string = "";
            let valueData: { [key: string]: string | number | boolean }[] = [];

            for (let key in item) {
              keyData = key;
              valueData = item[key];
            }

            return (
              <>
                <div className="AuditDetailOldData">
                  <p className="AuditDetailOldData_header">
                    {keyData}-(new Value)
                  </p>
                  <div className="AuditDetailOldData_underline"></div>

                  {valueData.map(
                    (item2: { [key: string]: string | number | boolean }) => {
                      let valueDataObjectArray: {
                        [key: string]: string | number | boolean;
                      }[] = [];

                      for (let key1 in item2) {
                        if (
                          key1.toLocaleLowerCase().endsWith("id") ||
                          key1.toLocaleLowerCase() == "createdby" ||
                          key1.toLocaleLowerCase() == "createdon" ||
                          key1.toLocaleLowerCase() == "modifiedby" ||
                          key1.toLocaleLowerCase() == "modifiedon"
                        )
                          continue;
                        valueDataObjectArray.push({ [key1]: item2[key1] });
                      }
                      return (
                        <>
                          <div>
                            {valueDataObjectArray.map((item3) => {
                              let keyData2: string = "";
                              let valueData2: string | number | boolean = "";

                              for (let key2 in item3) {
                                keyData2 = key2;
                                valueData2 = item3[key2];
                              }

                              return (
                                <>
                                  <div className="AuditDetailOldData_keyvalue">
                                    <p className="lignt">{keyData2}</p>
                                    <p>
                                      :{" "}
                                      {`${
                                        isValidDate(valueData2)
                                          ? formatDatewithTime(
                                              String(valueData2)
                                            )
                                          : valueData2
                                      }`}
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AuditDetail;
