import { Fetcher } from "../../../../../../../services/Fetcher";
import { BusinessData, BusinessTableIRes } from "./BusinessIState";


const fetcher = new Fetcher();
export class BusinessFunction {
  async getBusinessData(
    setLoading: (data: boolean) => void,
    setFields: (data:BusinessData ) => void,
    fields: BusinessData,
    nameId:string,
  ) {
    setLoading(true);

    let response = await fetcher.get(
      `/name-master/${nameId}/business`
    );
    let resInvolvement :BusinessTableIRes= response?.Data;

    setFields({
    //   "BusinessMasterID":resInvolvement.BusinessMasterID,
    "BusinessName": resInvolvement.BusinessName,
    "InvolvementType":resInvolvement.InvolvementType,
    "BusinessType":resInvolvement.BusinessType,
    "Address":resInvolvement.Address,
    "Case":resInvolvement.IncidentNumber,
    });

    setLoading(false);
  }
}
