import { Fetcher } from "../../../../../../../services/Fetcher";
import { convertToISOFormat } from "../../../../../../../utils/Helper/Helper";
import { InvolvementMainIState } from "../Involvement/InvolvementMainI";
import {
  AdditionalIData,
  AdditionalInfoEmploymentIRes,
  AdditionalInfoIRes,
  agencyLevelDropdoenIRes,
  GetBloodTestTypeDataI,
  GetCitizenshipDataI,
  GetDisabilityDataI,
  GetLanguageDataI,
  NCICDropdownI,
  SystemLevelDropdownIRes,
} from "./AdditionalIState";

const fetcher = new Fetcher();

export class AdditionalMainFunction {
  async getBloodTestTypeData(
    setLanguageData: (data: GetBloodTestTypeDataI[]) => void
  ) {
    const response = await fetcher.get("/code/ncic/category/BloodTestType");
    let res: GetBloodTestTypeDataI[] = response.Data;
    setLanguageData(res);
  }

  async MaritalStatusTypeData(
    setMaritalStatusTypeData: (data: NCICDropdownI[]) => void
  ) {
    const response = await fetcher.get("/code/ncic/category/MaritalStatus");
    let res: NCICDropdownI[] = response.Data;
    setMaritalStatusTypeData(res);
  }

  async getGangAffiliation(
    setGangAffiliation: (data: NCICDropdownI[]) => void
  ) {
    const response = await fetcher.get("/code/ncic/category/GangAffiliation");
    let res: NCICDropdownI[] = response.Data;
    setGangAffiliation(res);
  }

  async getSchoolGradeData(
    setSchoolGradeData: (data: agencyLevelDropdoenIRes[]) => void
  ) {
    const response = await fetcher.get("/code/agency/category/SchoolGrade's");
    let res: agencyLevelDropdoenIRes[] = response.Data;
    setSchoolGradeData(res);
  }

  async getSchoolData(
    setSchoolData: (data: agencyLevelDropdoenIRes[]) => void
  ) {
    const response = await fetcher.get("/code/agency/category/schoolnew");
    let res: agencyLevelDropdoenIRes[] = response.Data;
    setSchoolData(res);
  }

  async getEmployerTypeData(
    setEmployerTypeData: (data: SystemLevelDropdownIRes[]) => void
  ) {
    const response = await fetcher.get("/code/system/category/EmployerType");
    let res: SystemLevelDropdownIRes[] = response.Data;
    setEmployerTypeData(res);
  }

  async getOccupationData(
    setOccupationData: (data: SystemLevelDropdownIRes[]) => void
  ) {
    const response = await fetcher.get("/code/system/category/Occupation");
    let res: SystemLevelDropdownIRes[] = response.Data;
    setOccupationData(res);
  }

  async getShiftData(setShiftData: (data: SystemLevelDropdownIRes[]) => void) {
    const response = await fetcher.get("/code/system/category/Shift");
    let res: SystemLevelDropdownIRes[] = response.Data;
    setShiftData(res);
  }

  async getCitizenshipData(
    setCitizenshipData: (data: GetCitizenshipDataI[]) => void
  ) {
    const response = await fetcher.get("/code/ncic/category/Citizenship");
    let res: GetCitizenshipDataI[] = response.Data;
    setCitizenshipData(res);
  }

  async getLanguageData(setLanguageData: (data: GetLanguageDataI[]) => void) {
    const response = await fetcher.get("/code/ncic/category/Language");
    let res: GetLanguageDataI[] = response.Data;
    setLanguageData(res);
  }

  async getDisabilityData(
    setDisabilityData: (data: GetDisabilityDataI[]) => void
  ) {
    const response = await fetcher.get("/code/ncic/category/Disability");
    let res: GetDisabilityDataI[] = response.Data;
    setDisabilityData(res);
  }

  async AdditionalSave(
    setLoading: (data: boolean) => void,
    incidentId: string,
    participantId: string,
    Mainfields: InvolvementMainIState
  ) {
    setLoading(true);

    let body = {
      PersonId: Mainfields.InvolvementTab.Involvement.PersonId
        ? Mainfields.InvolvementTab.Involvement.PersonId
        : null,
      AgencyId: Mainfields.AdditionalInfo.AgencyId
        ? Mainfields.AdditionalInfo.AgencyId
        : null,
      IncidentId: Mainfields.AdditionalInfo.IncidentId
        ? Mainfields.AdditionalInfo.IncidentId
        : null,
      MasterNameId: Mainfields.AdditionalInfo.MasterNameId
        ? Mainfields.AdditionalInfo.MasterNameId
        : null,
      LimitedEnglishProficiency: Mainfields.AdditionalInfo.Language
        .LimitedEnglishProficiency
        ? Mainfields.AdditionalInfo.Language.LimitedEnglishProficiency
        : null,
      IsUscitizen: Mainfields.AdditionalInfo.Citizenship.IsUscitizen,

      FingerprintIndivator: Mainfields.AdditionalInfo?.FingerprintIndivator,
      GunShotInjury: Mainfields.AdditionalInfo?.GunShotInjury,

      NcicbloodTypeCodeId: Mainfields.AdditionalInfo?.BloodType
        ? Mainfields.AdditionalInfo?.BloodType
        : null,
      IsDisabled: Mainfields.AdditionalInfo.IsDisabled,

      IsDeceased: Mainfields.AdditionalInfo.IsDeceased,
      IsDodunknown: Mainfields.AdditionalInfo.IsDodunknown,

      DateOfDeath: Mainfields.AdditionalInfo.DateOfDeath
        ? convertToISOFormat(Mainfields.AdditionalInfo.DateOfDeath)
        : null,

      NcicmaritialStatusCodeId: Mainfields.AdditionalInfo.MaritalStatus
        ? Mainfields.AdditionalInfo.MaritalStatus
        : null,
      SchoolCodeId: Mainfields.AdditionalInfo.School
        ? Mainfields.AdditionalInfo.School
        : null,
      SchoolGradeCodeId: Mainfields.AdditionalInfo.SchoolGrade
        ? Mainfields.AdditionalInfo.SchoolGrade
        : null,
      DeadOnArrival: Mainfields.AdditionalInfo.DOA,

      IsCitizenshipUnknown:
        Mainfields.AdditionalInfo.Citizenship.CitizenshipUnknown,

      ParticipantAttributeList: [
        ...Mainfields.AdditionalInfo.Language.Language.map((val, i) => {
          return {
            ParticipantAttributeId: Mainfields.AdditionalInfo.Language
              .ParticipantAttributeId[i]
              ? Mainfields.AdditionalInfo.Language.ParticipantAttributeId[i]
              : null,
            ParticipantId: participantId,
            AttributeId: Mainfields.AdditionalInfo.Language.AttributeId[i]
              ? Mainfields.AdditionalInfo.Language.AttributeId[i]
              : null,
            AttributeName: "NCICCode",
            AttributeValue: "Language",
            AttributeCodeId: val,
          };
        }),

        ...Mainfields.AdditionalInfo.Citizenship.Citizenship.map((val, i) => {
          return {
            ParticipantAttributeId: Mainfields.AdditionalInfo.Citizenship
              .ParticipantAttributeId[i]
              ? Mainfields.AdditionalInfo.Citizenship.ParticipantAttributeId[i]
              : null,
            ParticipantId: participantId,
            AttributeId: Mainfields.AdditionalInfo.Citizenship.AttributeId[i]
              ? Mainfields.AdditionalInfo.Citizenship.AttributeId[i]
              : null,
            AttributeName: "NCICCode",
            AttributeValue: "CitizenzenshipCode",
            AttributeCodeId: val,
          };
        }),

        ...Mainfields.AdditionalInfo.Disability.Disability.map((val, i) => {
          return {
            ParticipantAttributeId: Mainfields.AdditionalInfo.Disability
              .ParticipantAttributeId[i]
              ? Mainfields.AdditionalInfo.Disability.ParticipantAttributeId[i]
              : null,
            ParticipantId: participantId,
            AttributeId: Mainfields.AdditionalInfo.Disability.AttributeId[i]
              ? Mainfields.AdditionalInfo.Disability.AttributeId[i]
              : null,
            AttributeName: "NCICCode",
            AttributeValue: "DisabilityTypeCode",
            AttributeCodeId: val,
          };
        }),

        ...Mainfields.AdditionalInfo.GangAffiliation.GangAffiliation.map(
          (val, i) => {
            return {
              ParticipantAttributeId: Mainfields.AdditionalInfo.GangAffiliation
                .ParticipantAttributeId[i]
                ? Mainfields.AdditionalInfo.GangAffiliation
                    .ParticipantAttributeId[i]
                : null,
              ParticipantId: participantId,
              AttributeId: Mainfields.AdditionalInfo.GangAffiliation
                .AttributeId[i]
                ? Mainfields.AdditionalInfo.GangAffiliation.AttributeId[i]
                : null,
              AttributeName: "NCICCode",
              AttributeValue: "GangAffiliations",
              AttributeCodeId: val,
            };
          }
        ),
      ],
    };

    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/additionalinfo`,
      body
    );
    let res: AdditionalIData = response.Data;

    this.AdditionalEmploymentSave(
      setLoading,
      incidentId,
      participantId,
      Mainfields
    );

    setLoading(false);
  }

  async AdditionalEmploymentSave(
    setLoading: (data: boolean) => void,
    incidentId: string,
    participantId: string,
    Mainfields: InvolvementMainIState
  ) {
    setLoading(true);

    let data = await this.getGUID(Mainfields);

    let body = Mainfields.AdditionalInfo.Employment.map((val, i) => {
      return {
        EmploymentId: val.ExternalContactID ? val.ExternalContactID : data[i],
        PersonId: Mainfields.InvolvementTab.Involvement.PersonId
          ? Mainfields.InvolvementTab.Involvement.PersonId
          : null,
        AgencyId: Mainfields.AdditionalInfo.AgencyId,
        IncidentId: incidentId,
        EmployerTypeCodeId: val.EmployerType ? val.EmployerType : null,
        EmployerName: val.EmployerName ? val.EmployerName : null,
        OccupationCodeId: val.Occupation ? val.Occupation : null,

        ShiftCodeId: val.Shift ? val.Shift : null,

        ContactFirstName: val.ContactFirstname,
        ContactLastName: val.ContactLastname,
        IsUnkownAddress: val.Address.UnknownAddress,
        Address: {
          Addresses: [
            {
              LocationId: val.Address.LocationId
                ? val.Address.LocationId
                : null,
              IncidentId: incidentId,
              AddressType: val.Address.AddressType
                ? val.Address.AddressType
                : null,

              HouseNumber: val.Address.Apartment ? val.Address.Apartment : null,
              Ncicstreet1PrefixId: val.Address.StreetPrefix
                ? val.Address.StreetPrefix
                : null,
              Street1Name: val.Address.StreetNumber
                ? val.Address.StreetNumber
                : null,
              Ncicstreet1SuffixId: val.Address.StreetSuffix
                ? val.Address.StreetSuffix
                : null,

              Street2Name: val.Address.Street ? val.Address.Street : null,

              City: val.Address.City ? val.Address.City : null,
              NcicstateId: val.Address.StateProvidence
                ? val.Address.StateProvidence
                : null,
              ZipCode: val.Address.ZipCode ? val.Address.ZipCode : null,
              Grid: val.Address.GridNumber ? val.Address.GridNumber : null,

              NciccountryId: val.Address.Country ? val.Address.Country : null,

              Address1: val.Address.AddressLine1
                ? val.Address.AddressLine1
                : null,
              Address2: val.Address.AddressLine2
                ? val.Address.AddressLine2
                : null,
              OtherCity: val.Address.ForeignAddressCity
                ? val.Address.ForeignAddressCity
                : null,
              OtherState: val.Address.ForeignAddressStateProvinceRegion
                ? val.Address.ForeignAddressStateProvinceRegion
                : null,
              OtherZip: val.Address.ForeignAddressZip
                ? val.Address.ForeignAddressZip
                : null,
              OtherCountry: val.Address.ForeignAddressCountry
                ? val.Address.ForeignAddressCountry
                : null,
              IsForeignAddress: val.Address.ForeignAddress
                ? val.Address.ForeignAddress
                : false,
            },
          ],
          EntityModel: {
            EntityType: "Participant",
            EntityId: participantId,
            EntitySubType: "Employment",
            EntitySubTypeId: val.ExternalContactID
              ? val.ExternalContactID
              : data[i],
          },
        },
        Contact: {
          Contacts: [
            ...val.Email.map((Emailval) => {
              if (Emailval.Email && Emailval.Type) {
                return {
                  ContactId: Emailval?.ContactId ? Emailval?.ContactId : null,
                  ContactValue: Emailval.Email ? Emailval.Email : null,
                  ContactTypeCodeId: Emailval.Type ? Emailval.Type : null,
                  ContactTypeName: "string",
                  ContactType: "Email",
                };
              }
            }).filter((val) => val),

            ...val.PhoneNumber.map((PhoneNumberval) => {
              if (PhoneNumberval.Type && PhoneNumberval.Number) {
                return {
                  ContactId: PhoneNumberval.ContactId
                    ? PhoneNumberval.ContactId
                    : null,
                  ContactValue: PhoneNumberval.Number,
                  ContactTypeCodeId: PhoneNumberval.Type
                    ? PhoneNumberval.Type
                    : null,
                  CountryCodeId: PhoneNumberval.Country
                    ? PhoneNumberval.Country
                    : null,
                  ContactTypeName: "string",
                  Ext: PhoneNumberval.Ext,
                  ContactType: "Phone",
                };
              }
            }).filter((val) => val),
          ],

          EntityModel: {
            EntityType: "Participant",
            EntityId: participantId,
            EntitySubType: "Employment",
            EntitySubTypeId: val.ExternalContactID
              ? val.ExternalContactID
              : data[i],
          },
        },
      };
    });
    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/participant-employment`,
      body
    );
    let res: AdditionalIData[] = response?.Data;

    setLoading(false);
  }

  async getGUID(Mainfields: InvolvementMainIState) {
    const response = await fetcher.get(
      `/${Mainfields.MultipleEmergencyContact.length}/Guid`
    );
    let data: string[] = response.Data;
    return data;
  }

  async getAdditionalInfo(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/additionalinfo`
    );
    let AdditionalInfo: AdditionalInfoIRes = response.Data;
    let AdditionalInfoEmployment = await this.getAdditionalInfoEmployment(
      incidentId,
      participantId
    );

    return { AdditionalInfo, AdditionalInfoEmployment };
  }

  async getAdditionalInfoEmployment(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/employment`
    );
    let AdditionalInfoEmployment: AdditionalInfoEmploymentIRes[] =
      response.Data;

    return AdditionalInfoEmployment;
  }
}
