import { useEffect, useState } from "react";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { AdditionalMainFunction } from "../../AdditionalFunction";
import { SchoolIProps } from "./SchoolI";
import { agencyLevelDropdoenIRes } from "../../AdditionalIState";

export const School: React.FC<SchoolIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [SchoolGradeData, setSchoolGradeData] = useState<
    agencyLevelDropdoenIRes[]
  >([]);
  const [SchoolData, setSchoolData] = useState<agencyLevelDropdoenIRes[]>([]);

  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        [name]: value,
        changes: true,
      },
    });
  };

  const initialRender = () => {
    additionalMainFunction.getSchoolGradeData(setSchoolGradeData);
    additionalMainFunction.getSchoolData(setSchoolData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div className="width_field">
        <p className="subheader">School</p>
        <div className="width_field">
          <div className="Involvement_left_row">
            <DropdownComponent
              fieldName="School Grade"
              value={Mainfields.AdditionalInfo.SchoolGrade}
              dataSource={SchoolGradeData.map((val) => {
                return {
                  id: val.AgencyLevelCodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValue("SchoolGrade", e);
              }}
            />
            <DropdownComponent
              fieldName="School"
              value={Mainfields.AdditionalInfo.School}
              dataSource={SchoolData.map((val) => {
                return {
                  id: val.AgencyLevelCodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValue("School", e);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
