import { useState } from "react";
import { ButtonComponents } from "../../../CommonComponents/Fields/Button/ButtonComponents";
import { TextBoxComponent } from "../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { FilterIProps } from "./IncidentListI";

export const Filter: React.FC<FilterIProps> = ({
  setIncidentNumber,
  IncidentNumber,
  initialRender,
}) => {
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  return (
    <>
      <div className="Filter">
        <div className="filds___ ">
          <div className="search_narrative_conatiner">
            <TextBoxComponent
              fieldName={"Incident Number"}
              value={IncidentNumber}
              onChangeValue={(e) => {
                setIncidentNumber(e);
              }}
            />
          </div>

          <div className="clearbutton">
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              handleClick={initialRender}
            />
          </div>

          <div className="clearbutton">
            <ButtonComponents
              name="Clear all Filters"
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
