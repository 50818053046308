import { Fetcher } from "../../../../../../../services/Fetcher";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../../utils/const";
import { SetFormDataIState } from "./AddORIInterface";
const fetcher = new Fetcher();
export class AddEditORIFunction {
  async addORI(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    agencyId: string,
    setShowAddORI: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.post(
      `/agency/${agencyId}/oriInformation`,
      { ...formData, Status: formData.Status == "1" ? true : false },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.AgencyOriInformationId) {
      setShowAddORI(false);
    }
  }

  async editORI(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    agencyId: string,
    AgencyOriInformationId: string,
    setShowAddORI: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/agency/${agencyId}/oriInformation`,
      {
        ...formData,
        AgencyOriInformationId,
        Status: formData.Status == "1" ? true : false,
      },
      {
        message: successUpdateMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.AgencyOriInformationId) {
      setShowAddORI(false);
    }
  }
}
