
import {
  setUserPermission,
  setUserPermissionPayload,
  UserPermission,
} from "../Redux/UserPermissionReducer";
import { Fetcher } from "./Fetcher";
import { jwtDecode } from "jwt-decode";

const fetcher = new Fetcher();

export const fetchPermissions = async (
  setloading: (data: boolean) => void,
  dispatch: (action: {
    type: string;
    payload: setUserPermissionPayload;
  }) => void
) => {
  setloading(true);
  const response = await fetcher.get("/user/permissions");

  

  const token = localStorage.getItem("AccessToken");
  
  let userRole: string = "";
  if (token) {
    const decodedToken: any = jwtDecode(token);
    userRole = decodedToken?.roles?.[0];
  }
  if (response?.Data) {
    let res;
    let parmission: UserPermission = response?.Data;
    res = parmission;

    dispatch(
      setUserPermission({
        User: res.User,
        Agency: res.Agency,
        "Code.NCIC": res["Code.NCIC"],
        "Code.Agency": res["Code.Agency"],
        "Code.System": res["Code.System"],
        Config: res.Config,
        CAD: res.CAD,
        Incident: res.Incident,
        "Incident.Participant": res["Incident.Participant"],
        "Incident.Location": res["Incident.Location"],
        "Incident.Narrative": res["Incident.Narrative"],
        "Incident.Procecution": res["Incident.Procecution"],
        "Incident.Vehicle": res["Incident.Vehicle"],
        "Incident.Article": res["Incident.Article"],
        "Incident.Media": res["Incident.Media"],
        "Incident.Menu.Config": res["Incident.Menu.Config"],
        "Incident.CAD": res["Incident.CAD"],
        MasterIndex: res.MasterIndex,
      })
    );
  }
  setloading(false);
};
