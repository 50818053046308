import { InvolvementMainIState } from "../../InvolvementMainI";
import { MultiAliasesIState } from "./MultiAliasesI";

export class AliasesFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: MultiAliasesIState[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: MultiAliasesIState[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.InvolvementTab.Aliases);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange:true,
        Aliases: [
          ...this.Mainfields.InvolvementTab.Aliases.map((val) => {
            return {
              ...val,
              push: true,
            };
          }),
          {
            id:
              this.Mainfields.InvolvementTab.Aliases[
                this.Mainfields.InvolvementTab.Aliases.length - 1
              ].id + 1,
            DOB: "",
            Firstname: "",
            LastName: "",
            Middlename: "",
            push: false,
          },
        ],
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.InvolvementTab.Aliases);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange:true,
        Aliases: this.Mainfields.InvolvementTab.Aliases.filter((val) => {
          if (val.id != id) {
            return val;
          }
        }).length
          ? this.Mainfields.InvolvementTab.Aliases.filter((val) => {
              if (val.id != id) {
                return val;
              }
            })
          : [
              {
                id: 1,
                DOB: "",
                Firstname: "",
                LastName: "",
                Middlename: "",
              },
            ],
      },
    });

    this.setedit(false);
  };
}
