// import React, { useEffect, useState } from "react";
// import close from "../../../../assets/icons/close-circle.svg";
// import "../NCIC/NCIC.css";
// import { SetTableData } from "./NCICII";
// import { coloum } from "./Config";
// import { AddEditViewNCIC } from "./AddEditViewNCIC.tsx/AddEditViewNCIC";
// import { TextBoxComponent } from "../../../CommonComponents/Fields/TextBox/TextBoxComponent";
// import { DropdownComponent } from "../../../CommonComponents/Fields/Dropdown/DropdownComponent";
// import { ButtonComponents } from "../../../CommonComponents/Fields/Button/ButtonComponents";
// import { Table } from "../../../CommonComponents/Table/Table";
// import { NCICFunction } from "./NCICFunction";
// import { Loader } from "../../../CommonComponents/Loader/Loader";
// export const NCICCode = () => {
//   const [tableData, setTableData] = useState<SetTableData[]>([]);
//   const [showAddEditView, setshowAddEditView] = useState<boolean>(false);
//   const [pageNumber, setpageNumber] = useState<number>(1);
//   const [pageCount, setpageCount] = useState<number>(10);
//   const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
//   const [editMode, seteditMode] = useState<boolean>(false);
//   const [addMode, setaddMode] = useState<boolean>(false);
//   const [viewMode, setviewMode] = useState<boolean>(false);
//   const [loading, setLoading] = useState<boolean>(false);
//   const NavigateToEditPage = () => {};
//   const setdeletePopup = () => {};
//   const participantListFunction = new NCICFunction();
//   const onRowClicked = (data: SetTableData) => {
//     setaddMode(false);
//     seteditMode(false);
//     setshowAddEditView(true);
//     setviewMode(true);
//   };

//   const getParticipantListTableData = () => {
//     participantListFunction.getNCICData(
//       setTableData,
//       pageNumber,
//       pageCount,
//       setLoading,
//       setTableTotalRecord,
//     );
//   };
//   const initialData = () => {
//     getParticipantListTableData();
//   };

//   useEffect(() => {
//     initialData();
//   }, [pageNumber, pageCount]);



//   return (
//     <>
//      <Loader loading={loading} />
//       {showAddEditView ? (
//         <AddEditViewNCIC />
//       ) : (
//         <div>
//           <div className="NCIC_Tab">
//             <div className="NCIC_text">
//               <TextBoxComponent fieldName="Search  NCIC Level Code" value="" />
//             </div>
//             <div className="NCIC_drop">
//               <DropdownComponent fieldName="Category" dataSource={[]} />
//               <DropdownComponent fieldName="Status" dataSource={[]} />
//             </div>
//             <div className="NCIC_clear">
//               <ButtonComponents name="Clear Filter" icon={close} />
//             </div>
//           </div>
//           <div className="table">
//             <Table
//               columns={coloum()}
//               columnsValue={tableData}
//               setpageCount={setpageCount}
//               setpageNumber={setpageNumber}
//               tableTotalRecord={tableTotalRecord}
//               onRowClicked={onRowClicked}
//               cursorPointer={true}
//             />
//           </div>
//         </div>
//       )}
//     </>
//   );
// };


import React, { useEffect, useState } from "react";
import close from "../../../../assets/icons/close-circle.svg";
import "../NCIC/NCIC.css";
import { SetTableData } from "./NCICII";
import { coloum } from "./Config";
import { AddEditViewNCIC } from "./AddEditViewNCIC.tsx/AddEditViewNCIC";
import { TextBoxComponent } from "../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../CommonComponents/Fields/Button/ButtonComponents";
import { Table } from "../../../CommonComponents/Table/Table";
import { NCICFunction } from "./NCICFunction";
import { Loader } from "../../../CommonComponents/Loader/Loader";

import useDelayedEffect from "../../../../hooks/useDelayedEffect";
import { Filter } from "./Filter/Filter";

export const NCICCode = () => {
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [showAddEditView, setshowAddEditView] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [editMode, seteditMode] = useState<boolean>(false);
  const [addMode, setaddMode] = useState<boolean>(false);
  const [viewMode, setviewMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const NavigateToEditPage = () => {};
  const setdeletePopup = () => {};
  const participantListFunction = new NCICFunction();
  const [AgencyLevelCode, setAgencyLevelCode] = useState<string>("");
  const [Category, setCategory] = useState<string>("");

  const onRowClicked = (data: SetTableData) => {
    setaddMode(false);
    seteditMode(false);
    setshowAddEditView(true);
    setviewMode(true);
  };

  const getParticipantListTableData = () => {
    participantListFunction.getNCICData(
      setTableData,
      pageNumber,
      pageCount,
      setLoading,
      setTableTotalRecord,
      AgencyLevelCode,
      Category
    );
  };

  const initialData = () => {
    getParticipantListTableData();
  };

  useEffect(() => {
    initialData();
  }, [pageNumber, pageCount]);


  useDelayedEffect(getParticipantListTableData, 700, [
    AgencyLevelCode,
    Category
  ]);


  return (
    <>
      <Loader loading={loading} />
      {showAddEditView ? (
        <AddEditViewNCIC />
      ) : (
        <div>
          <div className="NCIC_Tab">
          <Filter
              setAgencyLevelCode={setAgencyLevelCode}
              setCategory={setCategory}
              AgencyLevelCode={AgencyLevelCode}
              Category={Category}
            />
          </div>
          <div className="table">
            <Table
              columns={coloum()}
              columnsValue={tableData}
              setpageCount={setpageCount}
              setpageNumber={setpageNumber}
              tableTotalRecord={tableTotalRecord}
             
              cursorPointer={false}
            />
          </div>
        </div>
      )}
    </>
  );
};


