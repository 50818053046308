import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Incident Number" },
    { field: "Status" },
    { field: "CreatedOn" },
    { field: "Ipaddress" },
    { field: "CompleteOn" },
  ];
};
