import { AgencyManagement } from "../Components/Pages/AgencyManagement/AgencyManagementPage/AgencyManagement";
import { EditAgencyManagemant } from "../Components/Pages/AgencyManagement/EditAgencyManagemant/EditAgencyManagemant";
import { CreateAgencyManagement } from "../Components/Pages/AgencyManagement/CreateAgencyManagementPage/CreateAgencyManagement";
import { ListUserManagement } from "../Components/Pages/UserManagement/ListUserManagement/ListUserManagement";
import AddUserManagement from "../Components/Pages/UserManagement/AddUserManagement/AddUserManagement";
import EditUserManagement from "../Components/Pages/UserManagement/EditUserManagement/EditUserManagement";
import AuditDetail from "../Components/Pages/Audit/Audit/AuditDetail/AuditDetail";
import AuditPage from "../Components/Pages/Audit/Audit/AuditPage/AuditPage";
import { AgencyLevel } from "../Components/Pages/CodeTables/AgencyLevel/AgencyLevel";
import { SystemLevel } from "../Components/Pages/CodeTables/SystemLevelTab/SystemLevel";
import { NCIC } from "../Components/Pages/CodeTables/NCIC/NCIC";
import ListPermissionManagement from "../Components/Pages/RolePermissionManagement/Permission/ListPermissionManagement/ListPermissionManagement";
import ListPolicyManagement from "../Components/Pages/RolePermissionManagement/Policy/ListPolicyManagement/ListPolicyManagement";
import { EditPolicy } from "../Components/Pages/RolePermissionManagement/Policy/EditPolicy/EditPolicy";
import { AddPolicy } from "../Components/Pages/RolePermissionManagement/Policy/AddPolicy/AddEditPolicy";
import ListRoleManagement from "../Components/Pages/RolePermissionManagement/Roles/ListRolesManagement/ListRolesManagement";
import { EditRole } from "../Components/Pages/RolePermissionManagement/Roles/EditRole/EditRole";
import { AddRole } from "../Components/Pages/RolePermissionManagement/Roles/AddRole/AddRole";
import { PrivateRoute } from "./PrivateRoute";
import { RouteListingObj } from "./RouteI";
import { ViewUserManagement } from "../Components/Pages/UserManagement/ViewUserManagement/ViewUserManagement";
import { ViewAgencyManagemant } from "../Components/Pages/AgencyManagement/ViewAgencyManagemant/ViewAgencyManagemant";
import { ViewPolicy } from "../Components/Pages/RolePermissionManagement/Policy/ViewPolicy/ViewPolicy";
import { ViewRole } from "../Components/Pages/RolePermissionManagement/Roles/ViewRole/ViewRole";
import { Configuration } from "../Components/Pages/Configuration/Configuration";
import { Media } from "../Components/Pages/Incidents/Media/Media";
import { MasterNameIndexListing } from "../Components/Pages/MasterNameIndex/MasterNameIndexListing/MasterNameIndexListing";
import { ParticipantList } from "../Components/Pages/Incidents/Participant/ParticipantList";
import { AddParticipant } from "../Components/Pages/Incidents/Participant/AddParticipant/AddParticipant";
import { EditParticipant } from "../Components/Pages/Incidents/Participant/EditParticipant/EditParticipant";
import { MasterOverview } from "../Components/Pages/MasterNameIndex/MasterOverview/MasterOverview";
import { EditBusiness } from "../Components/Pages/Incidents/Participant/EditBusinessName/EditBusiness";
import Location from "../Components/Pages/Incidents/Location/Location";
import AddLocation from "../Components/Pages/Incidents/Location/AddLocation/AddLocation";
import { ParticipantOverviewList } from "../Components/Pages/Incidents/Participant/ParticipantOverview/ParticipantOverviewList";
import { BusinessOverviewList } from "../Components/Pages/Incidents/Participant/ParticipantOverview/BusinessOverviewList";
import IncidentList from "../Components/Pages/Incidents/IncidentList/IncidentList";
import IncidentOverview from "../Components/Pages/Incidents/Overview/IncidentOverview";
import { MasterBusinessOverview } from "../Components/Pages/MasterNameIndex/MasterNameIndexListing/MasterBusinessOverview/MasterBusinessList";
import NarrativeList from "../Components/Pages/Incidents/Narratives/NarativeListing/NarrativeList";
import NarrativesOverview from "../Components/Pages/Incidents/Narratives/NarrativeOverview/NarrativesOverview";
import NarrativeOverViewEdit from "../Components/Pages/Incidents/Narratives/NarrativeEdit/NarrativeOverViewEdit";
import VehicleList from "../Components/Pages/Incidents/Vehicle/VehicleListing/VehicleList";
import VehicleOverview from "../Components/Pages/Incidents/Vehicle/VehicleOverview/VehicleOverview";
import { CaseList } from "../Components/Pages/Incidents/RelatedCases/CasesListing/CasesListing";
import { AddNewCase } from "../Components/Pages/Incidents/RelatedCases/AddNewCase/AddNewCase";
import { ArticleListing } from "../Components/Pages/Incidents/Articles/ArticlesListing/ArticlesListing";
import { AddArticle } from "../Components/Pages/Incidents/Articles/AddArticles/AddArticle";
import AddMedia from "../Components/Pages/Incidents/Media/AddMedia/AddMedia";
import { IncidentsCADInformation } from "../Components/Pages/Incidents/CADInformation/CADInformationList";
import { CADInformation } from "../Components/Pages/CAD/CADInformation";
import VehicleCategory from "../Components/Pages/Incidents/Vehicle/AddEditVehicles/VehicleCategory";
import { IncidentsCADInformationJSON } from "../Components/Pages/Incidents/CADInformation/IncidentsCADInformationJSON/IncidentsCADInformationJSON";
import NarrativeViewAll from "../Components/Pages/Incidents/Narratives/NarrativeViewAll/NarrativeViewAll";
import ProsecutionList from "../Components/Pages/Incidents/Prosecution/ProsecutionListing.tsx/ProsecutionListing";
import { AddProsecution } from "../Components/Pages/Incidents/Prosecution/AddProsecution/AddProsecution";
import CADOverview from "../Components/Pages/Incidents/CADOverview/CADOverview";

export const RouteListing: () => RouteListingObj[] = () => [
  {
    path: "/incidents",
    element: (
      <PrivateRoute roleName="Incident" actionType="Read">
        <IncidentList />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/participants",
    element: (
      <PrivateRoute roleName="Incident.Participant" actionType="Read">
        <ParticipantList />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/participants/:participantId/overview",
    element: (
      <PrivateRoute roleName="Incident.Participant" actionType="Read">
        <ParticipantOverviewList />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/participants/add",
    element: (
      <PrivateRoute roleName="Incident.Participant" actionType="Create">
        <AddParticipant />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/participants/:participantId/edit",
    element: (
      <PrivateRoute roleName="Incident.Participant" actionType="Update">
        <EditParticipant />
      </PrivateRoute>
    ),
  },

  {
    path: "/incidents/:incidentId/businessparticipant/:participantId/edit",
    element: (
      <PrivateRoute roleName="Incident.Participant" actionType="Update">
        <EditBusiness />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/narratives",
    element: (
      <PrivateRoute roleName="Incident.Narrative" actionType="Read">
        <NarrativeList />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/narratives/all",
    element: (
      <PrivateRoute roleName="Incident.Narrative" actionType="Read">
        <NarrativeViewAll />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/narratives/:narrativesId/overviews",
    element: (
      <PrivateRoute roleName="Incident.Narrative" actionType="Read">
        <NarrativesOverview />
      </PrivateRoute>
    ),
  },

  {
    path: "/incidents/:incidentId/narratives/:narrativeId/:edit",
    element: (
      <PrivateRoute roleName="Incident.Narrative" actionType="Update">
        <NarrativeOverViewEdit />
      </PrivateRoute>
    ),
  },

  {
    path: "/incidents/:incidentId/narratives/add",
    element: (
      <PrivateRoute roleName="Incident.Narrative" actionType="Create">
        <NarrativeOverViewEdit />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/locations",
    element: (
      <PrivateRoute roleName="Incident.Location" actionType="Read">
        <Location />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/locations/add",
    element: (
      <PrivateRoute roleName="Incident.Location" actionType="Create">
        <AddLocation />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/locations/:locationid/edit",
    element: (
      <PrivateRoute roleName="Incident.Location" actionType="Update">
        <AddLocation />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/media",
    element: (
      <PrivateRoute roleName="Incident.Media" actionType="Read">
        <Media />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/media/add",
    element: (
      <PrivateRoute roleName="Incident.Media" actionType="Create">
        <AddMedia />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/articles",
    element: (
      <PrivateRoute roleName="Incident.Article" actionType="Read">
        <ArticleListing />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/articles/add",
    element: (
      <PrivateRoute roleName="Incident.Article" actionType="Create">
        <AddArticle />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/cad-information",
    element: (
      <PrivateRoute roleName="Incident.CAD" actionType="Read">
        <IncidentsCADInformation />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/cad-information/:CadimportId/info",
    element: (
      <PrivateRoute roleName="Incident.CAD" actionType="Read">
        <IncidentsCADInformationJSON />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/vehicles",
    element: (
      <PrivateRoute roleName="Incident.Vehicle" actionType="Read">
        <VehicleList />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/vehicles/:vehicleId/overview",
    element: (
      <PrivateRoute roleName="Incident.Vehicle" actionType="Read">
        <VehicleOverview />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/vehicles/add",
    element: (
      <PrivateRoute roleName="Incident.Vehicle" actionType="Create">
        <VehicleCategory />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/:incidentId/vehicles/:vehicleId/edit",
    element: (
      <PrivateRoute roleName="Incident.Vehicle" actionType="Update">
        <VehicleCategory />
      </PrivateRoute>
    ),
  },
  {
    path: "/master",
    element: (
      <PrivateRoute roleName="MasterIndex" actionType="Read">
        <MasterNameIndexListing />
      </PrivateRoute>
    ),
  },
  {
    path: "/master/overview/:nameId",
    element: (
      <PrivateRoute roleName="MasterIndex" actionType="Read">
        <MasterOverview />
      </PrivateRoute>
    ),
  },
  {
    path: "/master/overview/business/:nameId",
    element: (
      <PrivateRoute roleName="MasterIndex" actionType="Read">
        <MasterBusinessOverview />
      </PrivateRoute>
    ),
  },

  {
    path: "/user",
    element: (
      <PrivateRoute roleName="User" actionType="Read">
        <ListUserManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/user/add",
    element: (
      <PrivateRoute roleName="User" actionType="Create">
        <AddUserManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/user/:userId/edit",
    element: (
      <PrivateRoute roleName="User" actionType="Update">
        <EditUserManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/user/:userId/view",
    element: (
      <PrivateRoute roleName="User" actionType="Read">
        <ViewUserManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/agency",
    element: (
      <PrivateRoute roleName="Agency" actionType="Read">
        <AgencyManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/agency/:agencyId/edit",
    element: (
      <PrivateRoute roleName="Agency" actionType="Update">
        <EditAgencyManagemant />
      </PrivateRoute>
    ),
  },
  {
    path: "/agency/:agencyId/view",
    element: (
      <PrivateRoute roleName="Agency" actionType="Read">
        <ViewAgencyManagemant />
      </PrivateRoute>
    ),
  },
  {
    path: "/agency/add",
    element: (
      <PrivateRoute roleName="Agency" actionType="Create">
        <CreateAgencyManagement />
      </PrivateRoute>
    ),
  },
  {
    path: "/code/agency",
    element: (
      <PrivateRoute roleName="Code.Agency" actionType="Read">
        <AgencyLevel />
      </PrivateRoute>
    ),
  },
  {
    path: "/code/ncic",
    element: (
      <PrivateRoute roleName="Code.System" actionType="Read">
        <NCIC />
      </PrivateRoute>
    ),
  },
  {
    path: "/code/system",
    element: (
      <PrivateRoute roleName="Code.System" actionType="Read">
        <SystemLevel />
      </PrivateRoute>
    ),
  },
  {
    path: "/audit",
    element: <AuditPage />,
  },
  {
    path: "/audit-detail/:ID",
    element: <AuditDetail />,
  },
  {
    path: "/permission",
    element: <ListPermissionManagement />,
  },
  {
    path: "/policy",
    element: <ListPolicyManagement />,
  },
  {
    path: "/policy/add",
    element: <AddPolicy />,
  },
  {
    path: "/policy/:policyId/edit",
    element: <EditPolicy />,
  },
  {
    path: "/policy/:policyId/view",
    element: <ViewPolicy />,
  },
  {
    path: "/role",
    element: <ListRoleManagement />,
  },
  {
    path: "/role/add",
    element: <AddRole />,
  },
  {
    path: "/role/:roleId/edit",
    element: <EditRole />,
  },
  {
    path: "/role/:roleId/view",
    element: <ViewRole />,
  },

  {
    path: "/config/menu",
    element: <Configuration />,
  },

  {
    path: "/incidents/overview",
    element: <IncidentOverview />,
  },

  {
    path: "/incidents/reports",
    element: <Media />,
  },

  {
    path: "/incidents/:incidentId/businessparticipant/:participantId/overview",
    element: <BusinessOverviewList />,
  },
  {
    path: "/incidents/offenses",
    element: <Media />,
  },

  {
    path: "/incidents/media/add",
    element: <></>,
  },

  {
    path: "/incidents/evidence",
    element: <Media />,
  },
  {
    path: "/incidents/property",
    element: <Media />,
  },

  {
    path: "/incidents/citations",
    element: <Media />,
  },

  {
    path: "/cad",
    element: (
      <PrivateRoute roleName="CAD" actionType="Read">
        <CADInformation />
      </PrivateRoute>
    ),
  },
  {
    path: "/incidents/staff-involved",
    element: <Media />,
  },
  {
    path: "/incidents/related-reports",
    element: <Media />,
  },
  {
    path: "/incidents/case-management",
    element: <Media />,
  },
  {
    path: "/incidents/:incidentId/prosecution",
    element: <ProsecutionList />,
  },
  {
    path: "/incidents/:incidentId/prosecution/add",
    element: <AddProsecution />,
  },
  {
    path: "/incidents/unknown-participants",
    element: <Media />,
  },
  {
    path: "/incidents/incident-tasks",
    element: <Media />,
  },
  {
    path: "/incidents/response-to-resistance",
    element: <Media />,
  },

  {
    path: "/incidents/:incidentId/related-incidents",
    element: <CaseList />,
  },
  {
    path: "/incidents/:incidentId/related-incidents/add",
    element: <AddNewCase />,
  },
  {
    path: "/incidents/forms",
    element: <Media />,
  },

  {
    path: "/incidents/audit",
    element: <AuditPage />,
  },
  {
    path: "/incidents/:incidentId/cad-overview",
    element: <CADOverview />,
  },
];
