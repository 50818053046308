import React, { useEffect, useState } from "react";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { MasterMainIParams, SubstanceData } from "../../MasterNameIState";
import { SubstanceMainFunction } from "./SubstanceMainFunction";
import { useParams } from "react-router-dom";

export const Substance: React.FC = () => {
  const substancefunction = new SubstanceMainFunction();
  const [tableData, setTableData] = useState<SubstanceData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const { nameId } = useParams<MasterMainIParams>();
  const initialRender = () => {
    if(nameId){
      substancefunction.getSubstanceMain(setTableData, setTableTotalRecord,nameId);
    }

  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Substance Involvement: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
           <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
         
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
