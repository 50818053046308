import { Fetcher } from "../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../utils/const";
import { formatDate } from "../../../../utils/Helper/Helper";
import { OrderByFieldName } from "./Config";
import {
  GetSystemLevelTableDataI,
  OrderByType,
  SetTableData,
} from "./SystemLevelIState";

const fetcher = new Fetcher();

export class SystemLevelTabFunction {
  async getSystemLevelTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    pageNumber: number,
    pageCount: number,
    Category: string,
    Status?: string,
    SystemLevelCode?: string,
    OrderByDescending?: boolean,
    OrderBy?: string
  ) {
    setLoading(true);
    let response = await fetcher.post(`/code/system/search`, {
      PageNumber: pageNumber,
      PageSize: pageCount,
      LevelCode: SystemLevelCode ? SystemLevelCode : null,
      CategoryID: Category ? Category : null,
      Status: Status == "1" ? true : false,
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
    });
    if (response?.Success && response?.Data) {
      const TableJson = this.createTableJson(response?.Data);
      setTableTotalRecord(response?.TotalRecords ?? 0);
      setTableData(TableJson);
    } else {
      setTableData([]);
      setTableTotalRecord(0);
    }
    setLoading(false);
  }

  createTableJson(data: GetSystemLevelTableDataI[]) {
    return data?.map((val: GetSystemLevelTableDataI, i: number) => {
      return {
        "Date Active":formatDate (val.ActiveDate),
        "Date Inactive":formatDate (val.ExpiryDate),
        "Long Code": val.LongCode,
        "Short Code": val.ShortCode,
        Category: val.CategoryName,
        Description: val.Description,
        Status: val.IsActive ? "Active" : "InActive",
        SystemLevelCodeId: val.SystemLevelCodeId,
      };
    });
  }

  async deleteSystemLevel(
    systemLevelCodeId: string ,
    setLoading: (data: boolean) => void,
    setdeletePopup: (data: boolean) => void
  ) {
    setLoading(true);
    // const response = await fetcher.delete(
    //   `/code/${systemLevelCodeId}`,
      const response = await fetcher.put(
        `/code/${systemLevelCodeId}/system/status`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    setdeletePopup(false);
  }
}
