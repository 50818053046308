
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Case" },
    { field: "Detainment Date" },
    { field: "Detainment Time" },
    { field: "Release Date" },
    { field: "Release Time" },
    { field: "Jail ORI" },
  ];
};
