import add from "../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import {
  EmailAddressDataUIProps,
  EmailAddressIProps,
  setContactTypeDataIState,
} from "../../IdentificationIProps";
import { PhoneNumberFunction } from "../../../ExternalContact/Components/Phone/PhoneNumberFunction";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { validateEmail } from "../../../../../../../../../utils/Helper/Helper";
import cross from "../../../../../../../../../assets/icons/cros.svg";
import { EmailAddressFunction } from "./EmailAddressFunction";
import { EmailIstate } from "../../../ExternalContact/ExternalContactI";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

export const EmailAddressDataUI: React.FC<EmailAddressDataUIProps> = ({
  EmailAddressval,
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);
  const phoneNumberFunction = new PhoneNumberFunction();
  const [validationMessage, setValidationMessage] = useState<string>("");

  const onChangeValue = (
    key: string,
    value: string,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    if (key === "Email" && !validateEmail(value)) {
      setValidationMessage("Please enter a valid email address");
    } else {
      setValidationMessage("");
    }
    setMainfields({
      ...Mainfields,
      IdentificationAndContact: {
        ...Mainfields.IdentificationAndContact,
        onChange: true,
        Email: Mainfields.IdentificationAndContact.Email.map((val) => {
          if (val.id == id) {
            if (key2) {
              return { ...val, [key]: value, [key2]: value2 };
            } else {
              return { ...val, [key]: value };
            }
          } else {
            return val;
          }
        }),
      },
    });
  };

  const initialRender = () => {
    phoneNumberFunction.getContactTypeData(setContactTypeData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div className="MultiplePhoneNumber_" style={{ width: "70%" }}>
        <DropdownComponent
          dataSource={ContactTypeData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          fieldName="Type"
          value={EmailAddressval.Type}
          validation={Participantionvalidation.IdentificationAndContact.email}
          handleRowClick={(e, value2) => {
            onChangeValue(
              "Type",
              e,
              EmailAddressval.id,
              "TypeDropdownvalue",
              value2
            );
          }}
        />
        <div className="width_size">
          <TextBoxComponent
            value={EmailAddressval.Email}
            fieldName="Email Address"
            validation={Participantionvalidation.IdentificationAndContact.email}
            onChangeValue={(e) => {
              onChangeValue("Email", e, EmailAddressval.id);
            }}
          />
          {validationMessage && (
            <div
              style={{
                color: "red",
                fontSize: "13px",
                position: "absolute",
                marginBottom: "10px",
              }}
            >
              {validationMessage}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const EmailAddress: React.FC<EmailAddressIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<EmailIstate[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const phonenumberFunction = new EmailAddressFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    phonenumberFunction.Save();
  };

  const handleClick = () => {
    phonenumberFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    phonenumberFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    phonenumberFunction.DeletePreview(id);
  };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader">Email</p>

            <Preview
              DeletePreview={DeletePreview}
              ViewPreview={ViewPreview}
              showlist={[["TypeDropdownvalue", "comma", "Email"]]}
              list={
                edit
                  ? (editList.map((item) => ({ ...item })) as {
                      [key: string]: string | number | boolean;
                    }[])
                  : (Mainfields.IdentificationAndContact.Email.map((item) => ({
                      ...item,
                    })) as { [key: string]: string | number | boolean }[])
              }
            />
            <EmailAddressDataUI
              Mainfields={Mainfields}
              setMainfields={setMainfields}
              EmailAddressval={
                edit
                  ? Mainfields.IdentificationAndContact.Email.filter((val) => {
                      if (val.id == previewcount) {
                        return val;
                      }
                    })[0]
                  : Mainfields.IdentificationAndContact.Email[
                      Mainfields.IdentificationAndContact.Email.length - 1
                    ]
              }
              Participantionvalidation={Participantionvalidation}
            />
          </div>
        </div>
        <div
          className={"edit-button_"}
          style={{ width: "70%", marginTop: "3%" }}
        >
          <ButtonComponents
            name={edit ? "Save" : "Add Email Address"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={add}
          />
        </div>
      </div>
    </>
  );
};
