import { AxiosError } from "axios";
import { Fetcher } from "../../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../../utils/const";
import {
  GetPolicyTableDataI,
  SetTableData,
} from "./ListPolicyIState";
import { openNotificationWithIcon } from "../../../../CommonComponents/Toster/Toster";

const fetcher = new Fetcher();
export class ListPolicyFunction {
  async GetPolicyTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setFiltertableData?: (data: SetTableData[]) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/policy`);
    const TableJson = this.createTableJson(response?.Data);

    setTableData(TableJson);
    if (setFiltertableData) {
      setFiltertableData(TableJson);
    }
    setLoading(false);
  }
  createTableJson(data: GetPolicyTableDataI[]) {
    return data?.map((val: GetPolicyTableDataI, i: number) => {
      return {
        "Policy Name": val.PolicyName,
        Description: val.Description,
        Status: val.IsActive ? "Active" : "InActive",
        ID: val.PolicyId,
        isSelected: false,
        ModifiedOn: val.ModifiedOn,
        ModifiedByUserName: val.ModifiedByUserName,
        IsSuperAdminOnly: val.IsSuperAdminOnly,
      };
    });
  }

  async delete(
    policyId: string,
    setLoading: (data: boolean) => void,
    setdeletePopup: (data: boolean) => void
  ) {
    setLoading(true);

    const response = await fetcher.delete(
      `/policy/${policyId}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    console.log("Response Data:", response.response.data.Data);
    if (response?.Error) {
      console.log("Message:", response?.Message);
      setLoading(false);
      setdeletePopup(false);
    }
  }

  async CustomDelete(
    policyId: string,
    setLoading: (data: boolean) => void,
    setDeletePopup: (data: boolean) => void,
    setData: (data: { [key: string]: { [key: string]: string }[] }) => void,
    isDeleted: boolean
  ) {
    setLoading(true);

    try {
      const response = await fetcher.CustomDelete(
        `/policy/${policyId}?isDeleted=${isDeleted}`
      );
      setLoading(false);
      setDeletePopup(false);
      setData({});
      openNotificationWithIcon("success", successDeleteMessage);
    } catch (error: any) {
      // console.log(error)
      console.error("Error during delete:", error);
      setLoading(false);
      setData(error.response?.data.Data);
    }
  }

  applyFilterOnUserroleTable(
    StatusFilter: string,
    PolicyNameFilter: string,
    FiltertableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void
  ) {
    let filteredData: SetTableData[] = FiltertableData;
    let status: string = StatusFilter == "1" ? "active" : "inactive";
    if (PolicyNameFilter) {
      filteredData = FiltertableData.filter((item) => {
        return (
          item["Policy Name"] &&
          item["Policy Name"]
            .toLowerCase()
            .includes(PolicyNameFilter.toLowerCase().trim())
        );
      });
    }
    if (StatusFilter) {
      filteredData = filteredData.filter((item) => {
        return item?.["Status"].toLowerCase() == status;
      });
    }
    setTableData(filteredData);
  }
}
