import { useEffect, useState } from "react";
import {
  GetRaceDataIState,
  InvolvementIProps,
  SetTableData,
} from "../../InvolvementMainI";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { DateBoxComponents } from "../../../../../../../../CommonComponents/Fields/DateBox/DateBox";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import useDelayedEffect from "../../../../../../../../../hooks/useDelayedEffect";
import { ModalComponent } from "../../../../../../../../CommonComponents/Modal/ModalComponent";
import { ViewMatches } from "../Matches/ViewMatches";
import { Filter } from "../Matches/Filter"; // Import Filter Component
export const Involvement: React.FC<InvolvementIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [Suffix, setSuffix] = useState<GetRaceDataIState[]>([]);
  const [onNameChange, setonNameChange] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [ViewMatchespopup, setViewMatchespopup] = useState<boolean>(false);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(1);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [filterName, setFilterName] = useState<string>("");
  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Involvement: {
          ...Mainfields.InvolvementTab.Involvement,
          [name]: value,
        },
      },
    });
  };

  const handleClick = () => {
    setViewMatchespopup(true);
  };

  const closePopup = () => {
    setViewMatchespopup(false);
  };

  const masterCount = () => {
    if (
      (Mainfields.InvolvementTab.Involvement.Firstname && onNameChange) ||
      (Mainfields.InvolvementTab.Involvement.LastName && onNameChange) ||
      (Mainfields.InvolvementTab.Involvement.Middlename && onNameChange) ||
      (Mainfields.InvolvementTab.Involvement.DOB && onNameChange)
    ) {
      involvementMainFunction.getMatches(
        Mainfields.InvolvementTab.Involvement.Firstname,
        Mainfields.InvolvementTab.Involvement.LastName,
        Mainfields.InvolvementTab.Involvement.Middlename,
        Mainfields.InvolvementTab.Involvement.DOB,
        setLoading,
        setTableTotalRecord,
        setTableData,
        pageNumber,
        pageCount
      );
    }
  };

  useDelayedEffect(masterCount, 700, [
    Mainfields.InvolvementTab.Involvement.Firstname,
    Mainfields.InvolvementTab.Involvement.LastName,
    Mainfields.InvolvementTab.Involvement.Middlename,
    Mainfields.InvolvementTab.Involvement.DOB,
  ]);

  const initialRender = () => {
    involvementMainFunction.getRaceData(setRaceDataData);
    involvementMainFunction.getSuffix(setSuffix);
  };

  useEffect(() => {
    initialRender();
  }, []);

  useEffect(() => {
    if (
      Mainfields.InvolvementTab.Involvement.DOBRefused ||
      Mainfields.InvolvementTab.Involvement.DOBUnknown
    ) {
      setMainfields({
        ...Mainfields,
        InvolvementTab: {
          ...Mainfields.InvolvementTab,
          Involvement: {
            ...Mainfields.InvolvementTab.Involvement,
            DOB:
              Mainfields.InvolvementTab.Involvement.DOBRefused ||
              Mainfields.InvolvementTab.Involvement.DOBUnknown
                ? ""
                : Mainfields.InvolvementTab.Involvement.DOB,
            Age: Mainfields.InvolvementTab.Involvement.DOBUnknown
              ? ""
              : Mainfields.InvolvementTab.Involvement.Age,
          },
        },
      });
    }
  }, [
    Mainfields.InvolvementTab.Involvement.DOBRefused,
    Mainfields.InvolvementTab.Involvement.DOBUnknown,
  ]);
  const customTitle = (
    <div className="exist">
      <span>Existing Names:</span>
      <div className="existbutton"></div>
    </div>
  );
  return (
    <>
      <ModalComponent
        onClose={closePopup}
        open={ViewMatchespopup}
        width={1300}
        title={customTitle}
        innerContant={
          <ViewMatches
            tableData={tableData}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
            tableTotalRecord={tableTotalRecord}
            setpageNumber={setpageNumber}
            setpageCount={setpageCount}
            onSearch={masterCount}
          />
        }
        closeIconStyle={{
          backgroundColor: "#ffd9d9",
          border: "2px solid #ffd9d9",
          width: "30px",
          height: "30px",
        }}
      />

      <p className="subheader">Involvement</p>
      <div className="Involvement_main">
        <div className="Involvement_left">
          <div style={{ width: "39.5%" }} className="Involvement_left_row">
            <DropdownComponent
              dataSource={RaceDataData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValue("InvolvementType", e);
              }}
              value={Mainfields.InvolvementTab.Involvement.InvolvementType}
              fieldName="Involvement Type*"
              validation={Participantionvalidation.Involvement.InvolvementType}
            />
            <div
              className="Involvement_left_row_checkbox"
              style={{ marginTop: "37px" }}
            >
              <CheckBoxComponent
                onChangeValue={(e) => {
                  onChangeValue("Private", e);
                }}
                value={Mainfields.InvolvementTab.Involvement.Private}
                fieldName="Private"
              />
              <CheckBoxComponent
                onChangeValue={(e) => {
                  onChangeValue("ProcORI", e);
                }}
                value={Mainfields.InvolvementTab.Involvement.ProcORI}
                fieldName="Proc ORI"
              />

              <CheckBoxComponent
                onChangeValue={(e) => {
                  onChangeValue("InCustody", e);
                }}
                value={Mainfields.InvolvementTab.Involvement.InCustody}
                fieldName="In Custody"
              />
              <CheckBoxComponent
                onChangeValue={(e) => {
                  onChangeValue("MissingRunaway", e);
                }}
                value={Mainfields.InvolvementTab.Involvement.MissingRunaway}
                fieldName="Missing/Runaway"
              />
            </div>
          </div>
          <div className="Involvement_left_row">
            <div className="width_size">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextBoxComponent
                  onChangeValue={(e) => {
                    setonNameChange(true);
                    onChangeValue("LastName", e);
                  }}
                  value={Mainfields.InvolvementTab.Involvement.LastName}
                  fieldName="Last Name"
                  validation={Participantionvalidation.Involvement.LastName}
                />
                <p className="view-matches">--Matches {tableTotalRecord}</p>
              </div>
            </div>
            <TextBoxComponent
              onChangeValue={(e) => {
                onChangeValue("Firstname", e);
                setonNameChange(true);
              }}
              value={Mainfields.InvolvementTab.Involvement.Firstname}
              fieldName="First name"
              validation={Participantionvalidation.Involvement.Firstname}
            />
            <TextBoxComponent
              value={Mainfields.InvolvementTab.Involvement.Middlename}
              fieldName="Middle name"
              onChangeValue={(e) => {
                onChangeValue("Middlename", e);
                setonNameChange(true);
              }}
            />
            <DropdownComponent
              handleRowClick={(e) => {
                onChangeValue("Suffix", e);
              }}
              dataSource={Suffix.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              value={Mainfields.InvolvementTab.Involvement.Suffix}
              fieldName="Suffix"
            />
            <div>
              <DateBoxComponents
                fieldName="Date of Birth"
                onValueChange={(e) => {
                  onChangeValue("DOB", e);
                  setonNameChange(true);
                }}
                disabled={
                  Mainfields.InvolvementTab.Involvement.DOBUnknown ||
                  Mainfields.InvolvementTab.Involvement.DOBRefused
                }
                disabledDate={true}
                value={Mainfields.InvolvementTab.Involvement.DOB}
              />

              <CheckBoxComponent
                value={Mainfields.InvolvementTab.Involvement.DOBRefused}
                fieldName="DOB Refused"
                onChangeValue={(e) => {
                  onChangeValue("DOBRefused", e);
                }}
              />
              <CheckBoxComponent
                onChangeValue={(e) => {
                  onChangeValue("Juvenile", e);
                }}
                value={Mainfields.InvolvementTab.Involvement.Juvenile}
                fieldName="Juvenile"
              />
            </div>
            <div>
              <TextBoxComponent
                value={Mainfields.InvolvementTab.Involvement.Age}
                fieldName="Age"
                disabled={Mainfields.InvolvementTab.Involvement.DOBUnknown}
                onChangeValue={(e) => {
                  onChangeValue("Age", e);
                }}
              />

              <CheckBoxComponent
                value={Mainfields.InvolvementTab.Involvement.DOBUnknown}
                fieldName="Age/DOB Unknown"
                onChangeValue={(e) => {
                  onChangeValue("DOBUnknown", e);
                }}
              />
            </div>

            {Mainfields.InvolvementTab.Involvement.DOBUnknown && (
              <div className="additional-row">
                <TextBoxComponent
                  value={Mainfields.InvolvementTab.Involvement.MinAge}
                  fieldName="Min Age"
                  onChangeValue={(e) => {
                    onChangeValue("MinAge", e);
                  }}
                />
                <TextBoxComponent
                  value={Mainfields.InvolvementTab.Involvement.MaxAge}
                  fieldName="Max Age"
                  onChangeValue={(e) => {
                    onChangeValue("MaxAge", e);
                  }}
                />
              </div>
            )}

            <div style={{ marginTop: "2.9%", width: "100%" }}>
              <ButtonComponents
                name="DVS Look Up"
                showBackgroundColor={true}
                color="#F0F0F0"
              />
              <div style={{ marginTop: "20%" }}>
                <ButtonComponents
                  name="View Matches"
                  showBackgroundColor={true}
                  color="#F0F0F0"
                  handleClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
