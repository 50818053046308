import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AddCategoryIProps,
  SetAddCategoryIState,
  codecategory,
  CategoryAddEditViewIProps,
  setvalidationIState,
} from "./CategoryAddEditViewI";
//import { AddCategoryFunction } from "./AddCategoryFunction";
import { CategoryAddEditViewFunction } from "./CategoryAddEditViewFunction";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { useLocation } from "react-router-dom";
import { BackButtonComponent } from "../../../../../CommonComponents/BackButton/BackButtonComponent";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";
export const CategoryAddEditView: React.FC<CategoryAddEditViewIProps> = ({
  addmode,
  editMode,
  setaddMode,
  seteditMode,
  setviewMode,
  viewMode,
  setshowAddEditView,
  getRowId,
}) => {
  const {Update } =useGetPerticularUserPermission("Code.System");
  const location = useLocation();

  let calledFromAgencyLevel = location.pathname.includes("agency");
  const [formData, setFormData] = useState<SetAddCategoryIState>({
    IsActive: "1",
    Description: "",
    Category: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryData, setCategoryData] = useState<codecategory[]>([]);
  const [edit, setEdit] = useState<boolean>(viewMode ? true : false);
  const [disabled, setdisabled] = useState<boolean>(viewMode ? true : false);
  const dispatch = useDispatch();
  const addCategoryFunction = new CategoryAddEditViewFunction();
  const [validation, setvalidation] = useState<setvalidationIState>({
    IsActive: false,
    Description: false,
    Category: false,
  });
  const handleInputChange = (field: string, value: string | number) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    setvalidation({
      ...validation,
      [field]: false,
    });
  };

  const onCancel = () => {
    setFormData({
      IsActive: "1",
      Description: "",
      Category: "",
    });
  };

  useEffect(() => {
    if (viewMode || editMode || getRowId) {
      addCategoryFunction.getSystemLevelData(
        setLoading,
        setFormData,
        getRowId,
        calledFromAgencyLevel
      );
    }
    if (addmode) {
      setFormData({
        IsActive: "1",
        Description: "",
        Category: "",
      });
    }
    // getCategoryData();
  }, []);

  const addCategory = () => {
    addCategoryFunction.addCategory(
      setLoading,
      formData,
      setshowAddEditView,
      calledFromAgencyLevel
      //15 // To do:  need to make it dynamic when particular agency will login (Rohit sir asked)
    );
    dispatch(setchangeTheForm(false));
  };

  const editSystemLevel = () => {
    addCategoryFunction.editSystemLevel(
      setLoading,
      formData,
      setshowAddEditView,
      getRowId,
      calledFromAgencyLevel
    );
    dispatch(setchangeTheForm(false));
  };

  const handleSubmit = async () => {
    addCategoryFunction.errorValidation(formData, setvalidation);
    if (addCategoryFunction.checkRequiredFields(formData)) {
      if (addmode) {
        addCategory();
      } else if (editMode || (viewMode && getRowId)) {
        editSystemLevel();
      }
    }
  };

  return (
    <>
      <div>
        <Loader loading={loading} />

        <div className="DetailTab_top">
          <div className="DetailTab_top_header_">
            <div className="DetailTab_top_header_sub">
              <p>{addmode ? "Add Category" : "Edit Category"}</p>
              <div className="edit_button_">
                {Update && (
                  <p
                  onClick={() => {
                    setEdit(false);
                    setaddMode(false);
                    seteditMode(true);
                    setviewMode(false);
                    setdisabled(false);
                  }}
                >
                  {viewMode ? "Edit" : ""}
                </p>  
                )}
                
                <BackButtonComponent
                  onClick={() => {
                    setshowAddEditView(false);
                  }}
                  name="Back"
                />
              </div>
            </div>
          </div>

          <div className="AddEditViewNCIC">
            <div className="AgencyLevelAddEditView_1">
              <DropdownComponent
                dataSource={[
                  { id: "1", value: "Active" },
                  { id: "2", value: "InActive" },
                ]}
                disabled={edit}
                value={formData.IsActive}
                fieldName="Status"
                handleRowClick={(newValue) => {
                  handleInputChange("IsActive", newValue);
                }}
                validation={validation.IsActive}
              />
            </div>
            <div className="AgencyLevelAddEditView_1">
              <TextBoxComponent
                disabled={edit}
                value={formData.Category}
                fieldName="Category Name"
                onChangeValue={(newValue) => {
                  handleInputChange("Category", newValue);
                }}
                validation={validation.Category}
              />
            </div>

            <TextAreaComponent
              disabled={disabled}
              value={formData.Description}
              fieldName="Description"
              onChangeValue={(newValue) =>
                handleInputChange("Description", newValue)
              }
              validation={validation.Description}
            />
            <div className="add-modal"></div>
          </div>
        </div>
        {
          <div className="edit-button">
            <div className="button-ip">
              {(editMode || addmode) && (
                <div className="button-ip">
                  <Submit onCancel={onCancel} onSave={handleSubmit} />
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </>
  );
};
