import React from "react";
import "./Header.css";
import logo from "../../../assets/icons/newlogo.png";
import notification from "../../../assets/icons/Bell_Icon_UIA.svg";
import setting from "../../../assets/icons/setting-2.svg";
import { TextBoxComponent } from "../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { REACT_Redirect_URL } from "src/utils/const";

const Header: React.FC = () => {
  const { FirstName, LastName, MiddleName } = useSelector(
    (store: RootState) => store.UserInfoRedux
  );

  const { instance } = useMsal();

  const logout = async () => {
    localStorage.clear();
    if (instance) {
      await instance.logoutRedirect({
        postLogoutRedirectUri: REACT_Redirect_URL,
      });
    }
  };
  return (
    <header className="header_">
      <div className="logo-container">
        <img src={logo} alt="logo" className="logo" />
        <p className="header">St. Paul Police Department</p>
      </div>
      <div className="header-right">
        <TextBoxComponent value="" placeholder="Search All..." />
        <img
          src={notification}
          alt="notifications"
          title="Notification"
          style={{ width: "34px", cursor: "pointer" }}
        />
        <img src={setting} alt="notifications" title="Setting" />
        <div
          title={`${FirstName} ${MiddleName} ${LastName}`}
          className="user_name_meta_title"
        >
          {FirstName?.[0]}
          {MiddleName?.[0]}
          {LastName?.[0]}
        </div>
        <p
          onClick={async () => {
            logout();
          }}
          className="EmergencyTab_Secondary"
        >
          Logout
        </p>
      </div>
    </header>
  );
};

export default Header;
