import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { FingerPrintsIProps } from "./FingerPrintsI";

export const FingerPrints: React.FC<FingerPrintsIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        [name]: value,
        changes: true,
      },
    });
  };

  return (
    <>
      <div className="width_field">
        <p className="subheader">Finger Prints</p>
        <CheckBoxComponent
          fieldName="Finger Prints on file"
          value={Mainfields.AdditionalInfo.FingerprintIndivator}
          onChangeValue={(e) => {
            onChangeValue("FingerprintIndivator", e);
          }}
        />
      </div>
    </>
  );
};
