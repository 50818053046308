import React, { useEffect, useState } from "react";
import { DropdownComponent } from "../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { Submit } from "../../../../../../CommonComponents/Fields/Submit/Submit";
import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { TextAreaComponent } from "../../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { useParams, useNavigate } from "react-router-dom";

import { EditAgencyManagemantIParams } from "../../../../EditAgencyManagemant/EditAgencyManagemantI";
import { AddEditORIFunction } from "./AddEditORIFunction";
import {
  AddEditORIIProps,
  SetFormDataIState,
  setvalidationerror,
} from "./AddORIInterface";
import { openNotificationWithIcon } from "../../../../../../CommonComponents/Toster/Toster";
import { setchangeTheForm } from "../../../../../../../Redux/ValidateRouteReducer";
import { useDispatch } from "react-redux";
import { BackButtonComponent } from "../../../../../../CommonComponents/BackButton/BackButtonComponent";
import { useGetPerticularUserPermission } from "../../../../../../../hooks/Permission/useGetPerticularUserPermission";

function validateInput(input: string) {
  const regex = /^[^\s]{9}$/;
  return regex.test(input);
}

export const AddEditORI: React.FC<AddEditORIIProps> = ({
  addmode,
  editMode,
  setShowAddORI,
  getORISingleData,
  setaddMode,
  seteditMode,
  setviewMode,
  viewMode,
}) => {
  const { Update } = useGetPerticularUserPermission("Agency");
  const addEditORIFunction = new AddEditORIFunction();
  const [edit, setEdit] = useState<boolean>(viewMode ? true : false);
  const { agencyId } = useParams<EditAgencyManagemantIParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<SetFormDataIState>({
    OriNumber: getORISingleData.ORINumber ? getORISingleData.ORINumber : "",
    OriDescription: getORISingleData.Description
      ? getORISingleData.Description
      : "",
    Status: getORISingleData.Status ? "1" : "2",
  });
  const [validationerror, setvalidationerror] = useState<setvalidationerror>({
    OriDescription: false,
    OriNumber: false,
    Status: false,
  });
  const dispatch = useDispatch();
  const handleInputChange = (field: string, value: string | number) => {
    dispatch(setchangeTheForm(true));
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async () => {
    let Validationerror = {
      OriNumber: false,
      OriDescription: false,
      Status: false,
    };
    if (!formData.OriNumber) {
      Validationerror.OriNumber = true;
    }
    if (!formData.Status) {
      Validationerror.Status = true;
    }

    setvalidationerror({ ...Validationerror });

    if (agencyId && formData.OriNumber && formData.Status) {
      if (!validateInput(formData.OriNumber)) {
        openNotificationWithIcon(
          "error",
          "ORI number should have 9 characters without spaces."
        );
        return;
      }
      if (addmode) {
        addEditORIFunction.addORI(
          setLoading,
          formData,
          agencyId,
          setShowAddORI
        );
        dispatch(setchangeTheForm(false));
      } else if (editMode && getORISingleData.AgencyOriInformationId) {
        addEditORIFunction.editORI(
          setLoading,
          formData,
          agencyId,
          getORISingleData.AgencyOriInformationId,
          setShowAddORI
        );
        dispatch(setchangeTheForm(false));
      }
    }
  };

  const onCancel = () => {
    setFormData({
      OriNumber: getORISingleData.ORINumber ? getORISingleData.ORINumber : "",
      OriDescription: getORISingleData.Description
        ? getORISingleData.Description
        : "",
      Status: getORISingleData.Status ? "1" : "2",
    });
  };

  const BackButtonFunction = () => {
    setShowAddORI(false);
    dispatch(setchangeTheForm(false));
  };

  return (
    <>
      <Loader loading={loading} />

      <div className="DetailTab_top">
        <div className="DetailTab_top_header_">
          <div className="DetailTab_top_header_sub">
            <p>{addmode ? "Add ORI" : "Edit ORI"}</p>
            <div className="edit_button_">
              <BackButtonComponent onClick={BackButtonFunction} name="Back" />
              {Update && (
                <p
                  onClick={() => {
                    setEdit(false);
                    setaddMode(false);
                    seteditMode(true);
                    setviewMode(false);
                  }}
                  className="edit_button"
                >
                  {viewMode ? "Edit" : ""}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="add-ip-content">
          <div className="add-ip-textbox">
            <DropdownComponent
              dataSource={[
                { id: "1", value: "Active" },
                { id: "2", value: "InActive" },
              ]}
              value={formData.Status}
              disabled={edit}
              fieldName="Status"
              handleRowClick={(newValue) => {
                handleInputChange("Status", newValue);
                setvalidationerror({
                  ...validationerror,
                  Status: false,
                });
              }}
              validation={validationerror?.Status}
            />
          </div>
          <div className="add-ip-textbox">
            <TextBoxComponent
              disabled={edit}
              value={formData.OriNumber}
              fieldName="ORI Number"
              onlyUppercase={true}
              onChangeValue={(newValue) => {
                handleInputChange("OriNumber", newValue);
                setvalidationerror({
                  ...validationerror,
                  OriNumber: false,
                });
              }}
              validation={validationerror?.OriNumber}
            />
          </div>
          <div className="add-ip-textbox">
            <TextAreaComponent
              disabled={edit}
              value={formData.OriDescription}
              fieldName="Description"
              onChangeValue={(newValue) =>
                handleInputChange("OriDescription", newValue)
              }
            />
          </div>
          
          {(addmode || editMode) && (
           
              <div className="edit-button" style={{marginTop:"8%",marginRight:"-99%",}}>
                <Submit onSave={handleSubmit} onCancel={onCancel} />
              </div>
          
          )}
        </div>
      </div>
    </>
  );
};
