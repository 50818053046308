import { useEffect, useState } from "react";
import {
  BloodIstate,
  MultipleBloodDataUIProps,
  MultipleBloodIProps,
} from "../SubstanceInvolvement/SubstanceIProps";
import { AdditionalMainFunction } from "../../../AdditionalInfo/AdditionalFunction";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { DateBoxComponents } from "../../../../../../../../CommonComponents/Fields/DateBox/DateBox";
import { TimePickerComponenets } from "../../../../../../../../CommonComponents/Fields/TimePicker/TimePickerComponenets";
import { NumberBoxComponents } from "../../../../../../../../CommonComponents/Fields/NumberBox/NumberBoxComponents";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { GetBloodTestTypeDataI } from "../../../AdditionalInfo/AdditionalIState";
import { Add } from "../../../../../../../../../assets";
import cross from "../../../../../../../../../assets/icons/cros.svg";
import { MultiBloodFunction } from "./MultiBloodFunction";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

const MultiBloodDataUI: React.FC<MultipleBloodDataUIProps> = ({
  Mainfields,
  setMainfields,
  MultipleBloodval,
  BloodTestType,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      SubstanceTab: {
        ...Mainfields.SubstanceTab,
        onChange: true,
        BloodTab: Mainfields.SubstanceTab.BloodTab.map((val) => {
          if (val.id == id) {
            if (key2 && value2) {
              return { ...val, [key]: value, [key2]: value2 };
            } else {
              return { ...val, [key]: value };
            }
          } else {
            return val;
          }
        }),
      },
    });
  };

  return (
    <>
      <div className="MultiplePhoneNumber_" style={{ width: "60%" }}>
        <DropdownComponent
          fieldName="Test Type"
          dataSource={BloodTestType.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          value={MultipleBloodval.NcicsubstanceTypeCodeId}
          handleRowClick={(e, val2) => {
            onChangeValueNumber(
              "NcicsubstanceTypeCodeId",
              e,
              MultipleBloodval.id,
              "TestTypeDropdownvalue",
              val2
            );
          }}
        />
        <DateBoxComponents
          fieldName="Test Date"
          value={MultipleBloodval.TestDate}
          onValueChange={(e) => {
            onChangeValueNumber("TestDate", e, MultipleBloodval.id);
          }}
        />
        <TimePickerComponenets
          fieldName="Test Time"
          value={MultipleBloodval.TestTime}
          onValueChange={(e) => {
            onChangeValueNumber("TestTime", e, MultipleBloodval.id);
          }}
        />
      </div>
      <div className="MultiplePhoneNumber" style={{ width: "50%" }}>
        <NumberBoxComponents
          fieldName="Test Results"
          value={MultipleBloodval.TestResults}
          onValueChange={(e) => {
            onChangeValueNumber("TestResults", e, MultipleBloodval.id);
          }}
        />
      </div>
    </>
  );
};

export const MultiBlood: React.FC<MultipleBloodIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [BloodTestType, setBloodTestType] = useState<GetBloodTestTypeDataI[]>(
    []
  );

  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<BloodIstate[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const multiBloodFunction = new MultiBloodFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    multiBloodFunction.Save();
  };

  const handleClick = () => {
    multiBloodFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    multiBloodFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    multiBloodFunction.DeletePreview(id);
  };

  const initialRender = () => {
    additionalMainFunction.getBloodTestTypeData(setBloodTestType);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <p className="subheader">Blood Alocohol Level</p>

        <Preview
          DeletePreview={DeletePreview}
          ViewPreview={ViewPreview}
          showlist={[
            [
              "alcholedropdownvalue",
              "comma",
              "TestDate",
              "comma",
              "TestTime",
              "comma",
              "TestResults",
            ],
          ]}
          list={
            edit
              ? (editList.map((item) => ({
                  ...item,
                  alcholedropdownvalue: BloodTestType.filter(
                    (BloodTestTypeval) => {
                      if (
                        BloodTestTypeval.NciccodeId ==
                        item.NcicsubstanceTypeCodeId
                      ) {
                        return BloodTestTypeval;
                      }
                    }
                  )[0]?.LongCode,
                })) as {
                  [key: string]: string | number | boolean;
                }[])
              : (Mainfields.SubstanceTab.BloodTab.map((item) => ({
                  ...item,
                  alcholedropdownvalue: BloodTestType.filter(
                    (BloodTestTypeval) => {
                      if (
                        BloodTestTypeval.NciccodeId ==
                        item.NcicsubstanceTypeCodeId
                      ) {
                        return BloodTestTypeval;
                      }
                    }
                  )[0]?.LongCode,
                })) as { [key: string]: string | number | boolean }[])
          }
        />

        <MultiBloodDataUI
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          MultipleBloodval={
            edit
              ? Mainfields.SubstanceTab.BloodTab.filter((val) => {
                  if (val.id == previewcount) {
                    return val;
                  }
                })[0]
              : Mainfields.SubstanceTab.BloodTab[
                  Mainfields.SubstanceTab.BloodTab.length - 1
                ]
          }
          BloodTestType={BloodTestType}
        />
        <div className="edit-button__" style={{ width: "92%" }}>
          <ButtonComponents
            name={edit ? "Save" : "Add Blood Alocohol Level"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
