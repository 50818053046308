import Breadcrumbs from "../../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { AddEditParticipantName } from "../AddEditParticipantName/AddEditParticipantName";

export const EditParticipant = () => {
  return (
    <>
      <AddEditParticipantName defaultTab={1} />
    </>
  );
};
