import close from "../../../../../../assets/icons/close-circle.svg";
import { FilterIProps, setagencyData } from "./FilterI";
import { FilterFunction } from "./FilterFunction";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import add from "../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";
import { ButtonIState } from "../../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";
// import "../CategoryAddEditView/Category.css"
export const Filter: React.FC<FilterIProps> = ({
  goToAdd,
  Category,
  Status,
  setCategory,
  setStatus,
  setAgency,
  Agency,
  calledFromAgencyLevel,
  calledFromNCIC
}) => {
  const { Create} =
  useGetPerticularUserPermission("Code.System");
  const filterFunction = new FilterFunction();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  useEffect(() => {
    if (Agency && Agency.trim().length > 0 || (Category && Category.trim().length >0  ) || Status !== "1" ) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Agency,Category,Status]);
  useEffect(() => {
    filterFunction.getallcodeagency(setagencyData);
  }, []);
  const [agencyData, setagencyData] = useState<setagencyData[]>([]);
  const clearAllFilters = () => {
    setAgency("");
    setCategory("");
    setStatus("1");
    setIsAddButtonDisabled(true);
      setButtonTextColor("black");
  };
  return (
    <>
      <div  className="filter-button">
        {calledFromAgencyLevel && (
          <>
            <DropdownComponent
              fieldName="Select Agency to work within"
              dataSource={agencyData.map((value) => ({
                id: value.CodeCategoryId,
                value: value.CategoryName,
              }))}
              handleRowClick={setAgency}
              value={Agency}
            />
          </>
        )}
      </div>
      {calledFromAgencyLevel && <hr className="horizontal" />}
      <div className="Filter">
        <div className="filds_">
          <TextBoxComponent
            fieldName="Search Categories"
            value={Category}
            onChangeValue={setCategory}
          />

          <DropdownComponent
            fieldName="Status"
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            handleRowClick={setStatus}
            value={Status}
            allowclear={false}
            showTooltip={false}
          /><div className="clearbutton">
             <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
       
{!calledFromNCIC && Create && (
 <ButtonComponents
 name="Add Category"
 icon={add}
 handleClick={goToAdd}
 showBackgroundColor={true}
 textColor="white"
/>
)}
         
        </div>
      </div>
    </>
  );
};
