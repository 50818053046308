import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { TabsIndex } from "../Components/TabsIndex";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import moment from "moment-timezone";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

export const ViewPolicy: React.FC = () => {
  const { PolicyName, Status ,ModifiedOn,ModifiedByUserName} = useSelector(
    (store: RootState) => store.PolicyReducer
  );
 
  const dateFormate=moment
  .utc(ModifiedOn)
  .local()
  .format("MM-DD-YYYY HH:mm:ss");

  const Navigation = useNavigate();
  const NavigateToPolicyPage = () => {
    Navigation("/policy");
  };
  return (
    <>
      <div className="AddPolicy">
        <div className="AddPolicysub">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Policy", path: "/policy" },
              { title: "View", path: "" },
            ]}
          />
          <div className="BackButton">
            <BackButtonComponent
              name="Back to Policy Listing"
              onClick={NavigateToPolicyPage}
            />
          </div>
<div  className="EditAgencyManagemant_topdetail">
<p className="header">{PolicyName}</p>
          <p><strong>Status: </strong> {Status == "1" ? "Active" : "InActive"}</p>
          <p><strong>Last Modified By: </strong>{ModifiedByUserName} on {dateFormate}</p>
</div>
         
          <TabsIndex viewPolicyComponent={true} />
        </div>
      </div>
    </>
  );
};
