import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "IncidentId" },
    { field: "Incident Date" },
    { field: "Incident Type" },
    { field: "Address" },
  ];
};
