import { Fetcher } from "../../../../../../../../services/Fetcher";
import {
  SubstanceInvolvementIRes,
  SubstanceTableData,
} from "../ParticipantOverviewIState";

const fetcher = new Fetcher();

export class SubstanceFunction {
  async getSubstance(
    participantId: string,
    incidentId: string,
    setTableData: (data: SubstanceTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/substance-involvement`
    );
    let res: SubstanceInvolvementIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: SubstanceInvolvementIRes[]) {
    return data?.map((val: SubstanceInvolvementIRes, i: number) => {
      return {
        Type: val.Type,
        "Date Entered": val.DateEntered,
      };
    });
  }
}
