import { InvolvementMainIState } from "../../InvolvementMainI";
import { MultipleAddressIState } from "./MultipleAddressI";

export class MultipleAddressFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: MultipleAddressIState[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: MultipleAddressIState[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.InvolvementTab.Address.AddressData);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange: true,
        Address: {
          ...this.Mainfields.InvolvementTab.Address,
          AddressData: [
            ...this.Mainfields.InvolvementTab.Address.AddressData.map((val) => {
              return {
                ...val,
                push: true,
              };
            }),
            {
              id:
                this.Mainfields.InvolvementTab.Address.AddressData[
                  this.Mainfields.InvolvementTab.Address.AddressData.length - 1
                ].id + 1,
              AddressType: "",
              StreetNumber: "",
              LocationCategoryCode: "",
              StreetPrefix: "",
              Street: "",
              StreetSuffix: "",
              Apartment: "",
              City: "",
              StateProvidence: "",
              ZipCode: "",
              Country: "",
              GridNumber: "",
              AddressLine1: "",
              AddressLine2: "",
              ForeignAddressCity: "",
              ForeignAddressStateProvinceRegion: "",
              ForeignAddressZip: "",
              ForeignAddressCountry: "",
              ForeignAddress: false,
              CountryDropdownValue: "",
              StateProvidenceDropdownvalue: "",
              push: false,
            },
          ],
        },
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.InvolvementTab.Address.AddressData);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange: true,
        Address: {
          ...this.Mainfields.InvolvementTab.Address,
          AddressData:
            this.Mainfields.InvolvementTab.Address.AddressData.filter(
              (AddressDataval) => {
                if (id != AddressDataval.id) {
                  return AddressDataval;
                }
              }
            ).length
              ? this.Mainfields.InvolvementTab.Address.AddressData.filter(
                  (AddressDataval) => {
                    if (id != AddressDataval.id) {
                      return AddressDataval;
                    }
                  }
                )
              : [
                  {
                    id: 1,
                    AddressType: "",
                    StreetNumber: "",
                    StreetPrefix: "",
                    Street: "",
                    StreetSuffix: "",
                    Apartment: "",
                    City: "",
                    StateProvidence: "",
                    ZipCode: "",
                    Country: "",
                    GridNumber: "",
                    AddressLine1: "",
                    AddressLine2: "",
                    ForeignAddressCity: "",
                    ForeignAddressStateProvinceRegion: "",
                    ForeignAddressZip: "",
                    ForeignAddressCountry: "",
                    ForeignAddress: false,
                    CountryDropdownValue: "",
                    StateProvidenceDropdownvalue: "",
                  },
                ],
        },
      },
    });
    this.setedit(false);
  };
}
