import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MasterMainIParams } from "../../../../MasterOverview/MasterNameIState";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { BusinessContactData } from "./BusinessContactIState";
import { BusinessContactMainFunction } from "./BusinessContactFunction";

export const BusinessContacts: React.FC = () => {
  const BusinessContactFunction = new BusinessContactMainFunction();
  const [tableData, setTableData] = useState<BusinessContactData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  const { nameId } = useParams<MasterMainIParams>();
  const getContact = () => {
    if (nameId) {
      BusinessContactFunction.getBusinessContactMain(setTableData, setTableTotalRecord,nameId);
    }
  };

  useEffect(() => {
    getContact();
  }, []);
  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header_">
          <p>
            <strong>Contacts: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
           <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
        
              
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
