import React, { useEffect, useState } from "react";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { VehiclesData } from "../../MasterNameIState";

export const Vehicles: React.FC = () => {
  const [tableData, setTableData] = useState<VehiclesData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  return (
    <div style={{ width: "100%", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Vehicles: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
           <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
