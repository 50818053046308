import React, { useState } from "react";
import { TextBoxComponentIProps } from "./TextBoxComponentIProps";
import { Input } from "antd";

export const TextBoxComponent: React.FC<TextBoxComponentIProps> = ({
  fieldName,
  value,
  onChangeValue,
  placeholder,
  disabled,
  validation,
  onlyUppercase,
}) => {
  return (
    <>
      <div className="width_size">
        {fieldName && <p style={{ whiteSpace: "nowrap" }}>{fieldName}</p>}
        <div>
          <Input
            disabled={disabled}
            style={{ border: validation ? "1px solid red" : "1px solid #CCCCCC" }}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              if (onChangeValue) {
                if (onlyUppercase) {
                  onChangeValue(e.target.value.toUpperCase());
                } else {
                  onChangeValue(e.target.value);
                }
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
