import { setUserPermissionPayload } from "../../../../../../Redux/UserPermissionReducer";
import { Fetcher } from "../../../../../../services/Fetcher";
import { fetchPermissions } from "../../../../../../services/permissionsService";
import { successCreatedMessage, successPolicyAssign } from "../../../../../../utils/const";
import {
  getUserPermissionDataResponce,
  GetUserPermissionTableDataI,
  SetTableData,
} from "./UserPermissionI";
const fetcher = new Fetcher();
export class UserPermissionFunction {
  async GetUserPermissionTableData(
    setLoading: (data: boolean) => void,
    setTableData: (data: SetTableData[]) => void,
    userId: string,
    setFiltertableData: (data: SetTableData[]) => void
  ) {
    setLoading(true);

    let response = await fetcher.get(`/permission`);
    const TableJson = await this.createTableJson(response?.Data);
    this.getUserPermissionData(
      setLoading,
      userId,
      TableJson,
      setTableData,
      setFiltertableData
    );
  }

  async createTableJson(data: GetUserPermissionTableDataI[]) {
    return data?.map((val: GetUserPermissionTableDataI, i: number) => {
      return {
        PermissionId: val.PermissionId,
        Permission: val.DisplayName,
        Description: val.Description,
        General: val.General ? false : null,
        Create: val.Create ? false : null,
        Read: val.Read ? false : null,
        Update: val.Update ? false : null,
        Delete: val.Delete ? false : null,
      };
    });
  }
  async getUserPermissionData(
    setLoading: (data: boolean) => void,
    userId: string,
    tableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void,
    setFiltertableData: (data: SetTableData[]) => void
  ) {
    let response = await fetcher.get(`/user/user-permission/${userId}`);
    let responce: getUserPermissionDataResponce[] = response?.Data;
    if (responce.length) {
      let defaultCheckData = tableData.map((tableDataItem) => {
        let updateData = responce.find((responceItem) => {
          if (responceItem.PermissionID == tableDataItem.PermissionId) {
            return responceItem;
          }
        });
        if (updateData?.PermissionID) {
          return {
            ...tableDataItem,
            Create: updateData?.Create,
            Delete: updateData?.Delete,
            General: updateData?.General,
            Read: updateData?.Read,
            Update: updateData?.Update,
            GroupPolicy: updateData.GroupPolicy,
          };
        } else {
          return { ...tableDataItem };
        }
      });


      let finalTableData=defaultCheckData.sort((a, b) => {
        const aHasPermissions = Number(
          a.Create || a.Read || a.Delete || a.General || a.Update
        );
        const bHasPermissions = Number(
          b.Create || b.Read || b.Delete || b.General || b.Update
        );
        return bHasPermissions - aHasPermissions;
      });

      setTableData(finalTableData);
      setFiltertableData(finalTableData);
    }

    setLoading(false);
  }

  async userPolicyAdd(
    setLoading: (data: boolean) => void,
    userId: string,
    userPolicyAddData: SetTableData[],
    dispatch: (action: {
      type: string;
      payload: setUserPermissionPayload;
    }) => void
  ) {
    setLoading(true);
    let response = await fetcher.post(
      `/user/${userId}/permission`,
      userPolicyAddData.map((val) => {
        return {
          PermissionId: val.PermissionId,
          Delete: val.Delete,
          Read: val.Read,
          Update: val.Update,
          Create: val.Create,
          General: val.General,
        };
      }),
      {
        message: successPolicyAssign,
        show: true,
      }
    );
    await fetchPermissions(setLoading, dispatch);
    setLoading(false);
  }

  applyFilterOnUserpolicyTable(
    PermissionFilter: string,
    FiltertableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void
  ) {
    let filteredData: SetTableData[] = [];
    if (PermissionFilter) {
      filteredData = FiltertableData.filter((item) => {
        return (
          item["Permission"] &&
          item["Permission"]
            .toLowerCase()
            .includes(PermissionFilter.toLowerCase().trim())
        );
      });
    } else {
      filteredData = FiltertableData;
    }
    setTableData(filteredData);
  }
}
