import { Fetcher } from "../../../../services/Fetcher";
import { formatDatewithTime } from "../../../../utils/Helper/Helper";
import { getCADInformationTableIRes, SetTableData } from "./CADInformationI";

const fetcher = new Fetcher();

export class CADInformationFunction {
  async getCADInformationTable(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    setLoading(true);
    let incidentNumber = localStorage.getItem("Incidentnumber");
    let response = await fetcher.get(`/cad/${incidentNumber}/imports`);
    const TableJson = this.createTableJson(response?.Data);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: getCADInformationTableIRes[]) {
    return data?.map((val: getCADInformationTableIRes, i: number) => {
      return {
        "Incident Number": val.IncidentId,
        Status: val.Status,
        CreatedOn: formatDatewithTime(val.CreatedOn),
        Ipaddress: val.Ipaddress,
        CompleteOn: formatDatewithTime(val.CompleteOn),
        CadimportId: val.CadimportId,
      };
    });
  }
}
