import React, { useEffect, useState } from "react";
import { VehicleListFunction } from "../../../Vehicle/VehicleListing/VehicleFunction";
import {
  setTableData,
  vehicleDropdownList,
} from "../../../Vehicle/VehicleListing/VechicleIState";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { Spin } from "antd";

interface VehicleDropdownProps {
  placeholderHandler: (namesData: string) => void;
}
const VehicleDropdown: React.FC<VehicleDropdownProps> = ({
  placeholderHandler,
}) => {
  const VehicleFunction = new VehicleListFunction();
  const [tableData, setTableData] = useState<setTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [dropdownList, setDropdownList] = useState<vehicleDropdownList[]>([]);
  const vehicleDropdownHanlder = async () => {
    const response = await VehicleFunction.getVehicleListTableData(
      setTableData,
      false ? 1 : 1,
      false ? 10 : 1,
      setLoading,
      setTableTotalRecord,
      "",
      "",
      false,
      "",
      true
    );
    setDropdownList(response?.Data);
  };
  useEffect(() => {
    vehicleDropdownHanlder();
  }, []);
  return (
    <>
      <div className="_toolbar_text_container">
        {loading && <div className="_loader_container">
          <Spin
            spinning={loading}
            tip="Loading"
            fullscreen={false}
            size={"large"}
          />
        </div>}
        <div className="_narrative_name_container">
          {dropdownList?.map((item: vehicleDropdownList, index: number) => (
            <div className="_name_conatiner_list">
              <p
                className="_name _name_narrative_placeholder"
                onClick={() =>
                  placeholderHandler(`
                                    ${
                                      item?.PlateNumber ? item?.PlateNumber : ""
                                    }
                                     ${item?.Year ? item?.Year : ""}
                                     ${item?.NcicModel ? item?.NcicModel : ""}
                                     ${item?.NcicMake ? item?.NcicMake : ""}
                                    `)
                }
              >
                {`${item?.PlateNumber ? item?.PlateNumber : ""}
                                     ${item?.Year ? item?.Year : ""}
                                     ${item?.NcicModel ? item?.NcicModel : ""}
                                     ${item?.NcicMake ? item?.NcicMake : ""}
                                    `}
              </p>
              <ul>
                {item?.PlateNumber && (
                  <li
                    className="name_list name_list_narrative_placeholder"
                    onClick={() =>
                      placeholderHandler(`
                                        ${item?.PlateNumber}
                                        `)
                    }
                  >
                    {item?.PlateNumber}
                  </li>
                )}

                {item?.Year && (
                  <li
                    className="name_list name_list_narrative_placeholder"
                    onClick={() =>
                      placeholderHandler(
                        `
                                            ${item?.Year}
                                            `
                      )
                    }
                  >
                    {item?.Year}
                  </li>
                )}

                {item?.NcicModel && (
                  <li
                    className="name_list name_list_narrative_placeholder"
                    onClick={() => placeholderHandler(`${item?.NcicModel}`)}
                  >
                    {item?.NcicModel}
                  </li>
                )}

                {item?.NcicMake && (
                  <li
                    className="name_list name_list_narrative_placeholder"
                    onClick={() => placeholderHandler(`${item?.NcicMake}`)}
                  >
                    {item?.NcicMake}
                  </li>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VehicleDropdown;
