import React from "react";
import "./breadCrumb.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const Breadcrumbs: React.FC = () => {
  const { IncidentId } = useSelector((store: RootState) => store.CommonRedux);
  const location = useLocation();
  const navigate = useNavigate();
  let path = location?.pathname?.split("/");
  let tempPath = [];
  for (let i = 0; i < path.length; i++) {
    if (i === 2) {
      tempPath?.push("767823487");
    }
    if (!path?.[i]?.includes("-")) {
      tempPath?.push(path?.[i]);
      continue;
    }
  }
  const breadscumHandler = (item: string) => {
    if (item === "participants" || item === "businessparticipant") {
      navigate(`/incidents/${IncidentId}/participants`);
    } else if (item === "767823487") {
      navigate("/incidents/overview");
    } else if (item === "incidents") {
      navigate("/incidents");
    }
  };
  return (
    <>
      <nav className="bread_crumb_nav">
        <ul className="bread_crumb_unorder_list">
          {tempPath.map(
            (item) =>
              item !== "" && (
                <li
                  className="bread_crumb_list"
                  onClick={() => breadscumHandler(item)}
                >
                  <span>{item}&nbsp;/&nbsp;</span>
                </li>
              )
          )}
        </ul>
      </nav>
    </>
  );
};

export default Breadcrumbs;
