import add from "../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import { PhoneNumberDataUIProps, PhoneNumberIProps } from "./PhoneNumberIProps";
import { ButtonComponents } from "../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../../assets";
import { TextBoxComponent } from "../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { MaskedInputComponent } from "../../../../../../CommonComponents/Fields/MaskedInput/MaskedInputComponent";
import { DropdownComponent } from "../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";

export const PhoneNumberDataUI: React.FC<PhoneNumberDataUIProps> = ({MainfieldsBusiness,setMainfieldsBusiness,PhoneNumberval}) => {

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null,
    id: number
  ) => {
   
  };

  const remove = (id: number) => {
    if (MainfieldsBusiness.Involvement.PhoneNumber.length > 1) {
        setMainfieldsBusiness({
          ...MainfieldsBusiness,
          Involvement: {
            ...MainfieldsBusiness.Involvement,
            PhoneNumber: MainfieldsBusiness.Involvement.PhoneNumber.filter(
              (val) => {
                if (val.id != id) {
                  return val;
                }
              }
            ),
          },
        });
      }
  };



  return (
    <>
      <div className="MultiplePhoneNumber">
        <DropdownComponent
          dataSource={[]}
          fieldName="Type"
        />
        <DropdownComponent
          dataSource={[]}
          fieldName="Country"
        />

        <MaskedInputComponent
          mask="+1 (999)-999-9999"
          
          fieldName="Number"
          value=""
        />
        <TextBoxComponent
        value=""
          fieldName="Ext"
          
        />
        <p
          className="removeIcon"
          onClick={() => {
            remove(PhoneNumberval.id);
          }}
          title="Remove"
        >
          -
        </p>
      </div>
    </>
  );
};

export const PhoneNumber: React.FC<PhoneNumberIProps> = ({
 MainfieldsBusiness,setMainfieldsBusiness
}) => {
    const handleClick = () => {
        setMainfieldsBusiness({
          ...MainfieldsBusiness,
          Involvement: {
            ...MainfieldsBusiness.Involvement,
            PhoneNumber: [
              ...MainfieldsBusiness.Involvement.PhoneNumber,
              {
                id: MainfieldsBusiness.Involvement.PhoneNumber.length + 1,
                Type: "",
                ExternalContactID: "",
                Country: "",
                Number: "",
                Ext: "",
                TypeDropdownvalue: "",
              },
            ],
          },
        });
      };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_">Phone Number</p>
            {MainfieldsBusiness.Involvement.PhoneNumber.map(
              (PhoneNumberval) => {
                return (
                  <>
                    <PhoneNumberDataUI
                      MainfieldsBusiness={MainfieldsBusiness}
                      setMainfieldsBusiness={setMainfieldsBusiness}
                      PhoneNumberval={PhoneNumberval}
                    />
                  </>
                );
              }
            )}
          </div>
        </div>
        <div className="editbutton__" style={{ marginTop: "5%" }}>
          <ButtonComponents
            name="Add Phone Number"
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            handleClick={handleClick}
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
