import { InvolvementMainIState } from "../../../Involvement/InvolvementMainI";
import { SubstanceIstate } from "./SubstanceIProps";

export class MultiSubstanceFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: SubstanceIstate[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: SubstanceIstate[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.SubstanceTab.Substance);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      SubstanceTab: {
        ...this.Mainfields.SubstanceTab,
        onChange: true,
        Substance: [
          ...this.Mainfields.SubstanceTab.Substance.map((val) => {
            return {
              ...val,
              push: true,
            };
          }),
          {
            id:
              this.Mainfields.SubstanceTab.Substance[
                this.Mainfields.SubstanceTab.Substance.length - 1
              ].id + 1,
            IsInvolved: "",
            NcicsubstanceTypeCodeId: "",
            AgencyId: "",
            IncidentId: "",
            PersonId: "",
            SubstanceInvolvementId: "",
            TestResult: "",
            substanceTypeDropdownvalue: "",
            push: false,
          },
        ],
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.SubstanceTab.Substance);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      SubstanceTab: {
        ...this.Mainfields.SubstanceTab,
        onChange: true,
        Substance: this.Mainfields.SubstanceTab.Substance.filter((val) => {
          if (val.id != id) {
            return val;
          }
        }).length
          ? this.Mainfields.SubstanceTab.Substance.filter((val) => {
              if (val.id != id) {
                return val;
              }
            })
          : [
              {
                id: 1,
                IsInvolved: "",
                NcicsubstanceTypeCodeId: "",
                AgencyId: "",
                IncidentId: "",
                PersonId: "",
                SubstanceInvolvementId: "",
                TestResult: "",
                substanceTypeDropdownvalue: "",
                push: false,
              },
            ],
      },
    });

    this.setedit(false);
  };
}
