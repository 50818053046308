import React, { useEffect, useState } from "react";
import view from "../../../../../../assets/icons/Eyeicon.svg";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { FilterIProps } from "./FilterIProps";
import { NarrativeListFunction } from "../NarrativeFunction";
import add from "../../../../../../assets/icons/Icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { narrativeTypeDropdown } from "../NarrativeIState";
import { InvolvementMainIParams } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useGetPerticularUserPermission } from "src/hooks/Permission/useGetPerticularUserPermission";

export const Filter: React.FC<FilterIProps> = ({
  Name,
  setName,
  Type,
  setType,
  initialData,
  setIsClear,
  isClear,
}) => {
  const { Create } = useGetPerticularUserPermission("Incident.Narrative");
  const navigate = useNavigate();
  const narrativeFunction = new NarrativeListFunction();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [RaceDataData, setRaceDataData] = useState<narrativeTypeDropdown[]>([]);

  const clearAllFilters = () => {
    setName("");
    setType("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
    if (setIsClear) {
      setIsClear(!isClear);
    }
  };

  const viewAllHandler = () => {
    navigate(`/incidents/${incidentId}/narratives/all`);
  };
  useEffect(() => {
    if (Name.trim() !== "" || Type !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, Type]);

  const addNarrativeHandler = () => {
    navigate(`/incidents/${incidentId}/narratives/add`);
  };

  const searchHandler = () => {
    if (initialData) {
      initialData();
    }
  };
  const initialRender = () => {
    narrativeFunction.getRankData(setRaceDataData);
  };
  useEffect(() => {
    initialRender();
  }, []);
  return (
    <>
      <div className="Filter">
        <div className="filds___ narrative_filter_field">
          <div className="search_narrative_conatiner">
            <TextBoxComponent
              fieldName={"Search  Narratives"}
              value={Name}
              onChangeValue={setName}
            />
          </div>
          <div className="type_narrative_conatiner">
            <DropdownComponent
              fieldName="Type"
              dataSource={[
                { id: "", value: "All" },
                ...RaceDataData.map((val) => {
                  return {
                    id: val.AgencyLevelCodeId,
                    value: val.LongCode,
                  };
                }),
              ]}
              handleRowClick={(newValue) => {
                console.log("hello", newValue);
                setType(newValue === "All" ? "" : newValue);
              }}
              value={Type}
            />
          </div>
          <div className="clearbutton">
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              handleClick={searchHandler}
            />
          </div>

          <div className="clearbutton">
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="_narrative_filter_btn_container">
          <div className="clearbutton1">
            <div className="_add_new_narrative_btn_container">
              {Create && (
                <ButtonComponents
                  name="Add new narrative"
                  icon={add}
                  textColor="white"
                  showBackgroundColor={true}
                  handleClick={addNarrativeHandler}
                />
              )}
            </div>
          </div>
          <div className="clearbutton1">
            <ButtonComponents
              icon={view}
              name="View All"
              textColor="white"
              iconStyle={{ filter: "invert(1)" }}
              showBackgroundColor={true}
              handleClick={viewAllHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
};
