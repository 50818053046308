
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Case" },
    { field: "Date" },
    { field: "Relation" },
   
    { field: "Last Name" },
    { field: "First Name" },
    { field: "Phone Number" },
  ];
};
