import { setUserInfo, setUserInfoPayload } from "../Redux/UserInfoRedux";
import { Fetcher } from "./Fetcher";

const fetcher = new Fetcher();

interface UserIRes {
  UserId: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  EmailAddress: string;
  IsUserActive: boolean;
  IsAgencyActive: boolean;
  IsSuperAdministrator: boolean;
  AgencyId: string;
  AgencyName: string;
  ProfileImage: string;
}

export const UserDetail = async (
  dispatch: (action: { type: string; payload: setUserInfoPayload }) => void,
  setLoading: (data: boolean) => void,
) => {
  setLoading(true);
  const response = await fetcher.get("/user/me");
  setLoading(false);
  if (response?.Data) {
    let {
      AgencyId,
      AgencyName,
      EmailAddress,
      FirstName,
      IsAgencyActive,
      IsSuperAdministrator,
      IsUserActive,
      LastName,
      MiddleName,
      ProfileImage,
      UserId,
    }: UserIRes = response?.Data;
    dispatch(
      setUserInfo({
        AgencyId,
        AgencyName,
        EmailAddress,
        FirstName,
        IsAgencyActive,
        IsSuperAdministrator,
        IsUserActive,
        LastName,
        MiddleName,
        ProfileImage,
        UserId,
      })
    );
    return true;
  }
  if (!response) {
    return false;
  }
};
