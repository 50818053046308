import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./ErrorPage.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../SSO/appConfig";

const ErrorPage: React.FC = () => {
  const { statusCode } = useParams();
  const { instance, accounts } = useMsal();

  const logout = async () => {
    localStorage.clear();
    if (instance) {
      await instance.logoutRedirect().catch((e) => {
        console.error("Logout failed:", e);
      });
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <div className="not-found">
      <div className="not-found-content">
        <h1 className="not-found-title">{statusCode}</h1>
        <p className="not-found-subtitle">Unauthorized</p>
        <p className="not-found-message">
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
        <button
          onClick={async () => {
            logout();
            await instance.loginRedirect(loginRequest).catch((e) => {
              console.error(e);
            });
          }}
          className="not-found-link"
        >
          Go Back to Home
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
