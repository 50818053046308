import { useEffect, useState } from "react";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { Overview } from "./Components/Overview/Overview";
import { Locations } from "./Components/Locations/Locations";
import { Participants } from "./Components/Participants/Participants";
import { Vehicles } from "./Components/Vehicles/Vehicles";
import { IncidentSummary } from "./Components/IncidentSummary/IncidentSummary";
import { Narrative } from "./Components/Narrative/Narrative";
import { UnknownParticipants } from "./Components/UnknownParticipants/UnknownParticipants";
import { Evidence } from "./Components/Evidence/Evidence";
import { useNavigate } from "react-router-dom";
import { Submit } from "../../../../CommonComponents/Fields/Submit/Submit";

export const CaseOverview: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
const navigation=useNavigate();

const GoToCase=()=>{
    navigation("/incidents/related-cases")
}
const onsave=()=>{

}
const oncancel=()=>{
    navigation("/incidents/related-cases")
}
  return (
    <>
      <Loader loading={loading} />
      <div className="AddMasterName_main">
        <div className="case_child">
         <div className="case_">
         <p className="header">
           Case Number:12352525  {""} 17/10/2000
          </p>
          <p className="small_heading" onClick={GoToCase}>Go To Case</p>
         </div>
          

          <div>
            <div
             className="caseOverview"
            >
              <div
                className="caseOverview_"
              ><Overview/>
                
            <Locations/>
               <Participants/>
               <Vehicles/>
              </div>
              <div className="width_field">
                <IncidentSummary/>
                <Narrative/>
                <UnknownParticipants/>
                <Evidence/>
              </div>
            </div>
<div className="editbutton">
<Submit  saveColor="#3b72b1"
                  saveLabel=" Relate Incident "
                  cancelColor="Clear"
                  onSave={onsave}
                  onCancel={oncancel}/>
</div>
           
        
          </div>
        </div>
      </div>
    </>
  );
};
