import { useState } from "react";
import { setFormDataIState } from "../../Components/Pages/Incidents/Vehicle/AddEditVehicles/Vehicle/VehicalI";

interface SaveChangesPopupHook {
  formData: setFormDataIState;
  setFormData: (data: setFormDataIState) => void;
}

const useVehicleFormState = (): SaveChangesPopupHook => {
  const [formData, setFormData] = useState<setFormDataIState>({
    CommercialVehicle: false,
    // License: "",
    State: "",
    VehicleRegistrationId: "",
    VIN: "",
    VehicleId: "",
    TabYear: 0,
    Year: "",
    Make: "",
    Model: "",
    Type: "",
    InsuranceAgencyName: "",
    PrimaryColor: "",
    SecondaryColor: "",
    HazardousMaterial: "",
    GrossWeight: 0,
    PlateNumber: "",
    OverWeightMeasures: false,
    LicensePlateExpiration: "",
    InsuranceAgencyCodeId: "",
    InsuranceAgencyOther: "",
    Individual: [
      {
        RegisteredOwnerInformationId: "",
        LastName: "",
        id: 1,
        FirstName: "",
        MiddleName: "",
        Suffix: "",
        DOB: "",
        address: {
          AddressType: "",
          Apartment: "",
          City: "",
          Country: "",
          GridNumber: "",
          id: 1,
          CountryDropdownValue: "",
          StateProvidenceDropdownvalue: "",
          StateProvidence: "",
          Street: "",
          StreetNumber: "",
          StreetPrefix: "",
          StreetSuffix: "",
          ZipCode: "",
          AddressLine1: "",
          AddressLine2: "",
          ForeignAddressCity: "",
          ForeignAddressStateProvinceRegion: "",
          ForeignAddressZip: "",
          ForeignAddressCountry: "",
          ForeignAddress: false,
          LocationCategoryCode: "",
        },
      },
    ],
    Business: {
      RegisteredOwnerInformationId: "",
      BusinessName: "",
      address: {
        AddressType: "",
        Apartment: "",
        LocationCategoryCode: "",
        City: "",
        Country: "",
        GridNumber: "",
        id: 1,
        CountryDropdownValue: "",
        StateProvidenceDropdownvalue: "",
        StateProvidence: "",
        Street: "",
        StreetNumber: "",
        StreetPrefix: "",
        StreetSuffix: "",
        ZipCode: "",
        AddressLine1: "",
        AddressLine2: "",
        ForeignAddressCity: "",
        ForeignAddressStateProvinceRegion: "",
        ForeignAddressZip: "",
        ForeignAddressCountry: "",
        ForeignAddress: false,
      },
    },
    LienHolder: "",
    Lien: false,
    isIndividual: 1,
  });

  return {
    formData,
    setFormData,
  };
};

export default useVehicleFormState;
