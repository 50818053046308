import { ColDef } from "ag-grid-community";

export const coloum: (Update: boolean) => ColDef[] = (Update: boolean) => {
  return [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      editable: false,
      maxWidth: 90,
      sortable: false,
      cellStyle: { pointerEvents: Update ? "" : "none" },
    },
    { field: "Policy Name" },
    { field: "Description" },
    { field: "Status" },
  ];
};
