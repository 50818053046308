import close from "../../../../../../assets/icons/close-circle.svg";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonIState } from "../../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";
import { AgencyLevelAddEditViewFunction } from "../../../AgencyLevel/components/AgencyLevelAddEditView/AgencyLevelAddEditViewFunction";

import { codecategory } from "../../SystemLevelIState";
import { CreateEditSystemLevelFunction } from "../CreateEditSystemLevel/CreateEditSystemLevelFunction";
import { FilterIProps } from "./FilterIProps";
import { useEffect, useState } from "react";

export const Filter: React.FC<FilterIProps> = ({
  goToAdd,
  SystemLevelCode,
  Category,
  Status,
  setSystemLevelCode,
  setCategory,
  setStatus,
}) => {
  const createEditSystemLevelFunction = new CreateEditSystemLevelFunction();
  const [categoryData, setcategoryData] = useState<codecategory[]>([]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  useEffect(() => {
    if ((SystemLevelCode && SystemLevelCode.trim().length > 0) || (Category && Category.trim().length >0 ) || Status !== "1") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [SystemLevelCode, Category,Status]);

  useEffect(() => {
    createEditSystemLevelFunction.getallcodecategory(setcategoryData);
  }, []);
  const clearAllFilters = () => {
    setSystemLevelCode("");
    setCategory("");
    setStatus("1");
    setIsAddButtonDisabled(true);
      setButtonTextColor("black");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds">
          <TextBoxComponent
            fieldName="Short/Long Code"
            value={SystemLevelCode}
            onChangeValue={setSystemLevelCode}
          />
          <DropdownComponent
            fieldName="Category"
            dataSource={categoryData.map((state) => ({
              id: state.CodeCategoryId,
              value: state.CategoryName,
            }))}
            handleRowClick={setCategory}
            value={Category}
          />
          <DropdownComponent
            fieldName="Status"
            value={Status}
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            handleRowClick={setStatus}
            allowclear={false}
            showTooltip={false}
          />
           <div className="clearbutton1">
        <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
        </div>
        </div>
       
      </div>
    </>
  );
};
