import React from "react";
import { BackButtonComponent } from "../../../CommonComponents/BackButton/BackButtonComponent";
import "../AddUserManagement/AddUserManagement.css";
import { useNavigate } from "react-router-dom";
import { TabsIndex } from "../Components/Tabs/TabsIndex";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import user from "../../../../assets/icons/Frame_5568.svg";
import moment from "moment-timezone";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
export const ViewUserManagement: React.FC = () => {
  const navigation = useNavigate();
  const NavigateToUserPage = () => {
    navigation("/user");
  };

  const { Email, HomeUnit, Status, fullname, images ,ModifiedByUserName,ModifiedOn} = useSelector(
    (store: RootState) => store.UserReducer
  );
 
  const dateFormate=moment
  .utc(ModifiedOn)
  .local()
  .format("MM-DD-YYYY HH:mm:ss");
  return (
    <div className="CreateAgencyManagement">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "User", path: "/user" },
              { title: "View", path: "" },
            ]}
          />
      <BackButtonComponent name="Back to Users" onClick={NavigateToUserPage} />
      <div className="usermanagemnt_header">
        <img src={images ? images : user} />
        <div className="">
          <p className="header">{fullname}</p>
          <p><strong>Home Unit: </strong>{HomeUnit}</p>
          <p>
           <strong>Status: </strong>{Status}
          </p>
          <p><strong>Last Modified By: </strong>{ModifiedByUserName} on {dateFormate}</p>
        </div>
      </div>
      <TabsIndex viewUserComponent={true} />
    </div>
  );
};
