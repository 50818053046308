import { Fetcher } from "../../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";
import { getsummaryoverviewIRes } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { OverviewTableData } from "../ParticipantOverviewIState";
const fetcher = new Fetcher();
export class OverviewFunction {
  async getInvolvement(
    setLoading: (data: boolean) => void,
    incidentId: string,
    participantId: string,
    setfields: (data: OverviewTableData) => void,
    fields: OverviewTableData
  ) {
    setLoading(true);

    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/summary-overview`
    );

    let resInvolvement: getsummaryoverviewIRes = response.Data;

    setfields({
      Age: resInvolvement.Age? String(resInvolvement.Age) :'',
      DOB: resInvolvement?.DOB? formatDate(resInvolvement?.DOB) :"",
      FirstName: resInvolvement.FirstName,
      LastName: resInvolvement.LastName,
      MiddleName: resInvolvement.MiddleName,
      Eye: resInvolvement.Eye ? resInvolvement.Eye : "",
      FacialHair: resInvolvement.FacialHair ? "Y" : "N",
      Glasses: resInvolvement.Glasses ? "Y" : "N",
      Hair: resInvolvement.Hair ? resInvolvement.Hair : "",
      HGT: resInvolvement.Height ? String(resInvolvement.Height) : "",
      Race: resInvolvement.Race ? resInvolvement.Race : "",
      Sex: resInvolvement.Sex ? resInvolvement.Sex : "",
      Skintone: resInvolvement.Complexion ? resInvolvement.Complexion : "",
      WGT: resInvolvement.Weight ? Number(resInvolvement.Weight) : 0,
    });

    setLoading(false);
  }
}
