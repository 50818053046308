import React, { useState } from "react";
import Breadcrumbs from "../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { ExpandSidebar } from "../../../../assets";
import { Edit } from "../../../../assets";
interface LocationMetaDataProps {
  width: number;
  setWidth: (width: number) => void;
}
const LocationMetaData: React.FC<LocationMetaDataProps> = ({
  width,
  setWidth,
}) => {
  const [dummyText, setDummyText] =
    useState<string>(`Lorem ipsum dolor sit amet consectetur adipisicing
                                elit. Pariatur veritatis qui soluta, quis beatae,
                                error, sint minima quae provident totam expedita
                                voluptates? Iste nesciunt debitis in fugit,
                                ad ducimus officia..`);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const expandSidebarHandler = (val: number) => {
    if (val === 10) setWidth(300);
    else if (val === 300) setWidth(10);
  };

  const expandParaHanlder = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      {/* <div
        className={
          width !== 300
            ? "_location_left_container"
            : "_location_left_container_alter"
        }
        style={{
          backgroundColor: width === 10 ? "#fff" : "#f0f0f0",
          marginRight: width === 10 ? "-8px" : "0px",
        }}
      >
        <div
          className="_locationMetaData_container_fluid"
          style={{ width: `${width}px` }}
        >
          <div className="_breadscum_container">
            <div
              className={
                width === 300
                  ? "_collapsable_icon_container"
                  : "_collapsable_icon_container_default"
              }
              onClick={() => expandSidebarHandler(width)}
            >
              <ExpandSidebar />
            </div>
          </div>

          {width === 300 && (
            <div className="_case_data_container">
              <div className="_case_detail_container">
                <h3 className="_case_id_title">Case #12345678</h3>
                <Edit />
              </div>

              <div className="_case_location">
                <p className="location_title">Location</p>
                <p>123 N 5th Street Apt #123, St.Paul Minnesota</p>
              </div>

              <div className="_case_event">
                <p className="location_title">Call Date/Time</p>
                <p>12/10/2023,&nbsp;18:43:19</p>
              </div>

              <div className="_case_review">
                <p className="location_title">Reviewer Status</p>
                <p>Not Completed - 1 Report</p>
              </div>

              <div className="_case_crime_list">
                <p className="location_title">Crimes</p>
                <ol className="crime_list">
                  <li>&nbsp;Lorem ipsum </li>
                  <li>&nbsp;Lorem ipsum </li>
                  <li>&nbsp;Lorem ipsum </li>
                </ol>
              </div>

              <div className="_crime_unit_allocation_container">
                <div className={width === 300 ? "case_crime_row" : ""}>
                  <div className="crime_left_container">
                    <p className="crime_unit_tile">Expunge/Seal</p>
                    <p>N/A</p>
                  </div>
                  <div className="crime_right_container">
                    <p className="crime_unit_tile">Investigator</p>
                    <p>N/A</p>
                  </div>
                </div>
                <div className={width === 300 ? "case_crime_row" : ""}>
                  <div className="crime_left_container">
                    <p className="crime_unit_tile">Unit Assigned</p>
                    <p>N/A</p>
                  </div>
                  <div className="crime_right_container">
                    <p className="crime_unit_tile">Case Status</p>
                    <p>N/A</p>
                  </div>
                </div>
                <div className={width === 300 ? "case_crime_row" : ""}>
                  <div className="crime_left_container">
                    <p className="crime_unit_tile">Case Disposition</p>
                    <p>N/A</p>
                  </div>
                  <div className="crime_right_container">
                    <p className="crime_unit_tile">Case Disp. Date</p>
                    <p>N/A</p>
                  </div>
                </div>
              </div>

              <div className="_crime_summary_container">
                <p className="crime_summary_title">Summary</p>
                <p>
                  {isExpanded ? dummyText : dummyText?.slice(0, 130) + "..."}
                  <span className="readmore-btn" onClick={expandParaHanlder}>
                    {isExpanded ? "Read less" : "Read more"}
                  </span>{" "}
                </p>
              </div>
            </div>
          )}
        </div>
      </div> */}
    </>
  );
};

export default LocationMetaData;
