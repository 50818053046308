import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import "./AddEditPolicy.css";
import { AddEditForm } from "../Components/AddEditForm/AddEditForm";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

export const AddPolicy: React.FC = () => {
  const Navigation = useNavigate();
  const NavigateToPolicyPage = () => {
    Navigation("/policy");
  };
  return (
    <>
      <div className="AddPolicy">
        <div className="AddPolicysub">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Policy", path: "/policy" },
              { title: "Add", path: "" },
            ]}
          />
          <div className="BackButton">
            <BackButtonComponent
              name="Back to Policy Listing"
              onClick={NavigateToPolicyPage}
            />
          </div>

          <p className="AddNew"> Add New Policy</p>
          <AddEditForm viewPolicyComponent={false} />
        </div>
      </div>
    </>
  );
};
