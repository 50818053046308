import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import edit_icon from "../../../../assets/icons/edit.svg";
import delete_icon from "../../../../assets/icons/delete.svg";
import { locationTableDataList } from "./LocationOverviewIState";
import { NavigateFunction } from "react-router-dom";

interface OrderByFieldNameLocation {
  Address: string;
}

export const OrderByFieldName: OrderByFieldNameLocation = {
  Address: "Address",
};

export const coloum: (
  setDeletePopUp: (data: boolean) => void,
  setDeleteLocationId: (data: string) => void,
  navigate: NavigateFunction,
  incidentId: string | undefined,
  Delete: boolean,
  Update: boolean
) => ColDef[] = (
  setDeletePopUp: (data: boolean) => void,
  setDeleteLocationId: (data: string) => void,
  navigate: NavigateFunction,
  incidentId: string | undefined,
  Delete: boolean,
  Update: boolean
) => {
  return [
    { field: "Primary" },
    { field: "Address" },
    {
      field: "Action",
      cellRenderer: (e: { data: locationTableDataList }) => {
        return (
          <>
            <div className="table-cellRenderer">
              {Update && (
                <img
                  className="table-cellRenderer-img"
                  src={edit_icon}
                  title="Edit"
                  onClick={() => {
                    if (incidentId) {
                      navigate(
                        `/incidents/${incidentId}/locations/${e?.data?.LocationId}/edit`
                      );
                    }
                  }}
                />
              )}
              {Delete && (
                <img
                  className="table-cellRenderer-img"
                  src={delete_icon}
                  title="Delete"
                  onClick={() => {
                    setDeleteLocationId(e?.data?.LocationId);
                    setDeletePopUp(true);
                  }}
                />
              )}
            </div>
          </>
        );
      },
    },
  ];
};
