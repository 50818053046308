import React from "react";
import "./SaveTheChangesPopup.css";
import { SaveTheChangesPopupIProps } from "./SaveTheChangesPopupI";

export const SaveTheChangesPopup: React.FC<SaveTheChangesPopupIProps> = ({
  onClose,
  onOk,
}) => {
  return (
    <div>
      <div>
        <div className="modal-content">
          <p className="modal-message">
            Are you sure you want to leave without saving your changes?
          </p>
          <div className="modal-actions">
            <button className="modal-button" onClick={onClose}>
              No
            </button>
            <button className="modal-button-confirm" onClick={onOk}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
