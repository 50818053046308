import delete_icon from "../../../../assets/icons/delete.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { SetTableData, OrderByFieldNameI } from "./AgencyManagementIState";
import { formatDatewithTime } from "../../../../utils/Helper/Helper";

export const OrderByFieldName: OrderByFieldNameI = {
  "Agency Name": "AgencyName",
  "Agency Type": "AgencyTypeName",
  Description: "AgencyDescription",
  Location: "AgencyAddress",
  "Created By": "CreatedByUserName",
  "Created On": "CreatedOn",
  "Modified On": "ModifiedOn",
};

export const coloum: (
  NavigateToEditPage: (data: string) => void,
  setdeletePopup: (data: boolean) => void,
  setDeleteAgencyId: (id: string | null) => void,
  Delete: boolean,
  Update: boolean
) => ColDef[] = (
  NavigateToEditPage: (data: string) => void,
  setdeletePopup: (data: boolean) => void,
  setDeleteAgencyId: (id: string | null) => void,
  Delete: boolean,
  Update: boolean
) => {
  return [
    { field: "Agency Name" },
    { field: "Agency Type" },
    { field: "Description" },
    { field: "Address" },
    { field: "Created By" },
    {
      field: "Created On",
    },
    {
      field: "Modified On",
    },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <>
            <div className="table-cellRenderer">
              {Update && (
                <img
                  onClick={() => {
                    NavigateToEditPage(e?.data?.ID);
                  }}
                  className="table-cellRenderer-img"
                  src={edit_icon}
                  title="Edit"
                />
              )}
              {Delete && (
                <img
                  onClick={() => {
                    setDeleteAgencyId(e?.data?.ID);
                    setdeletePopup(true);
                  }}
                  className="table-cellRenderer-img"
                  src={delete_icon}
                  title="Delete"
                />
              )}
            </div>
          </>
        );
      },
    },
  ];
};
