import { Fetcher } from "../../../../../../services/Fetcher";
import { successCreatedMessage } from "../../../../../../utils/const";
import { SetFormDataIState, setFormDataValidation } from "./AddEditFormI";

const fetcher = new Fetcher();
export class AddEditFormFunction {
  async addAgency(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState
  ) {
    setLoading(true);
    const response = await fetcher.post(
      "/policy",
      {
        AgencyId: "86AE1FB6-01B3-47BE-B80E-85C02CFBFF99",
        PolicyName: formData.PolicyName,
        Description: formData.Description,
        IsActive: formData.Status == "1" ? true : false,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
  }

  async editAgency(
    setLoading: (data: boolean) => void,
    policyId: string,
    formData: SetFormDataIState
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/policy/${policyId}`,
      {
        PolicyId: policyId,
        AgencyId: "86AE1FB6-01B3-47BE-B80E-85C02CFBFF99",
        PolicyName: formData.PolicyName,
        Description: formData.Description,
        IsActive: formData.Status == "1" ? true : false,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
  }

  
  errorValidation(
    formData: SetFormDataIState,
    setFormDataValidation: (data: setFormDataValidation) => void
  ) {
    let valdationerror: setFormDataValidation = {
      PolicyName: false,
    Description: false,
      IsActive: false,
    };
    if (!formData.PolicyName) {
      valdationerror.PolicyName = true;
    } else {
      valdationerror.PolicyName = false;
    }
    if (!formData.Description) {
      valdationerror.Description = true;
    } else {
      valdationerror.Description = false;
    }
    if (!formData.Status) {
      valdationerror.IsActive = true;
    } else {
      valdationerror.IsActive = false;
    }
    setFormDataValidation({ ...valdationerror });
  }

  checkRequiredFields(formData: SetFormDataIState) {
    return (
      formData.PolicyName &&
      formData.Description &&
      formData.Status
    );
  }
}
