import React, { useEffect, useState } from "react";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import AddDescriptionForm from "./AddDescriptionForm";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { AddVehicleFunction } from "./AddVehicleFunction";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { setRankDataIState } from "../../../../UserManagement/Components/Tabs/SummaryTab/SummaryTabInterface";
import AddOwnerInfo from "./AddOwnnerInfo";
import { useNavigate, useParams } from "react-router-dom";
import { setIncidentId } from "../../../../../../Redux/CommonRedux";
import { useDispatch } from "react-redux";
import { VehicleIParams } from "./VehicalI";
import useVehicleFormState from "../../../../../../hooks/VehicleFormState/useVehicleFormState";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";

const VehicleForm: React.FC = () => {
  const addVehicleFunction = new AddVehicleFunction();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { incidentId, vehicleId } = useParams<VehicleIParams>();
  const [insurance, setInsurance] = useState<setRankDataIState[]>([]);
  const { formData, setFormData } = useVehicleFormState();
  const [other, setOther] = useState<string>("");
  const navigation = useNavigate();
  const[oneTimerender,setOneTimeRender]=useState<boolean>(false);
const [isSubmitting, setIsSubmitting] = useState(false);
  const onChangeValue = (
    key: string,
    value: string | number | boolean | null
  ) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
 const handleSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true); // Set submitting to true
      AddVehicle();
    }
  };
  const redirectTOEDitMOde = (vehiclesId: string) => {
    setFormData({
      ...formData,
      VehicleId: vehiclesId,
    });
    navigation(`/incidents/${incidentId}/vehicles/${vehiclesId}/edit`);
  };
  // const AddVehicle = () => {
  //   if (incidentId) {
  //     if (vehicleId) {
  //       addVehicleFunction.editTow(setLoading, incidentId, formData);
  //     } else {
  //       addVehicleFunction.addVehicle(
  //         setLoading,
  //         formData,
  //         incidentId,
  //         redirectTOEDitMOde
  //       );
  //     }
  //   }
  // };



  const AddVehicle = async () => {
  if (!incidentId) return;

  setLoading(true); // Start loading state

  try {
    if (vehicleId) {
      // Update existing vehicle
      await addVehicleFunction.editTow(setLoading, incidentId, formData);
    } else {
      // Add new vehicle
      await addVehicleFunction.addVehicle(
        setLoading,
        formData,
        incidentId,
        redirectTOEDitMOde
      );
    }
  } catch (error) {
    console.error("Error adding/updating vehicle:", error);
  } finally {
    setLoading(false);
  }
};


  const initialRender =async () => {
    addVehicleFunction.getInsurance(setInsurance, setLoading);
    if (incidentId && vehicleId) {
    const response=await addVehicleFunction.getVehicle(
        setLoading,
        setFormData,
        incidentId,
        vehicleId
      );
      if(response.Success){
setOneTimeRender(true)
      }
      
    }

  };

  useEffect(() => {
    initialRender();
  }, []);

  useEffect(() => {
    if (incidentId) {
      dispatch(setIncidentId(incidentId));
    }
  }, [incidentId]);
useEffect(()=>{
setOther(formData.InsuranceAgencyName || formData.InsuranceAgencyOther)
},[oneTimerender]);


  const oncancel = () => {
    setFormData({
      CommercialVehicle: false,
      State: "",
      VehicleRegistrationId: "",
      VIN: "",
      VehicleId: "",
      TabYear: 0,
      Year: "",
      Make: "",
      Model: "",
      Type: "",
      InsuranceAgencyName:"",
      PrimaryColor: "",
      SecondaryColor: "",
      HazardousMaterial: "",
      GrossWeight: 0,
      PlateNumber: "",
      OverWeightMeasures: false,
      LicensePlateExpiration: "",
      InsuranceAgencyCodeId: "",
      InsuranceAgencyOther: "",
      Individual: [
        {
          RegisteredOwnerInformationId: "",
          LastName: "",
          id: 1,
          FirstName: "",
          MiddleName: "",
          Suffix: "",
          DOB: "",
          address: {
            AddressType: "",
            Apartment: "",
            City: "",
            Country: "",
            GridNumber: "",
            id: 1,
            CountryDropdownValue: "",
            StateProvidenceDropdownvalue: "",
            StateProvidence: "",
            Street: "",
            StreetNumber: "",
            StreetPrefix: "",
            StreetSuffix: "",
            ZipCode: "",
            AddressLine1: "",
            AddressLine2: "",
            ForeignAddressCity: "",
            ForeignAddressStateProvinceRegion: "",
            ForeignAddressZip: "",
            ForeignAddressCountry: "",
            ForeignAddress: false,
            LocationCategoryCode: "",
          },
        },
      ],
      Business: {
        RegisteredOwnerInformationId: "",
        BusinessName: "",
        address: {
          AddressType: "",
          Apartment: "",
          LocationCategoryCode: "",
          City: "",
          Country: "",
          GridNumber: "",
          id: 1,
          CountryDropdownValue: "",
          StateProvidenceDropdownvalue: "",
          StateProvidence: "",
          Street: "",
          StreetNumber: "",
          StreetPrefix: "",
          StreetSuffix: "",
          ZipCode: "",
          AddressLine1: "",
          AddressLine2: "",
          ForeignAddressCity: "",
          ForeignAddressStateProvinceRegion: "",
          ForeignAddressZip: "",
          ForeignAddressCountry: "",
          ForeignAddress: false,
        },
      },
      LienHolder: "",
      Lien: false,
      isIndividual: 1,
    });
  };
console.log(other)
  return (
    <>
      <Loader loading={loading} />
      <div className="_add_vehicle_form_desc_title">
        <p>Description</p>
        <div className="_isCommercial_checkbox"></div>
      </div>

      <div className="add_vehicle_desc_container">
        <AddDescriptionForm formData={formData} setFormData={setFormData} />
      </div>
      <div className="_add_vehicle_regis_container">
        <div className="_add_vehicle_registration_form_container">
          <div className="_add_vehicle_form_desc_title">
            <p>{!formData.CommercialVehicle ? "Insurance" : " Registration"}</p>
          </div>

          <div className="_add_vehicle_registration_upper"></div>

          <div
            className="_add_vehicle_registration_lower"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              className="insurance_agency"
              style={{ display: "flex", flexDirection: "row", gap: "20px" }}
            >
              <DropdownComponent
                value={formData?.InsuranceAgencyCodeId}
                handleRowClick={(newValue, val) => {
                  setOther(`${val}`);
                  console.log(val)
                  onChangeValue("InsuranceAgencyCodeId", newValue);
                }}
                dataSource={[
                  ...insurance.map((state) => ({
                    id: state.AgencyLevelCodeId,
                    value: state.LongCode,
                  })),
                ]}
                fieldName="Insurance Agency"
              />
              {other !== "Insurance" && (
                <div>
                  <TextBoxComponent
                    fieldName="Other"
                    value={formData.InsuranceAgencyOther}
                    onChangeValue={(newValue) => {
                      onChangeValue("InsuranceAgencyOther", newValue);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="_add_vehicle_owner_info_conatiner">
        <AddOwnerInfo formData={formData} setFormData={setFormData} />
      </div>

      <div className="_is_lien_information_checkbox_container">
        <div className="_lien_info_container">
          <div className="_add_vehicle_form_desc_title is_lien_info_title">
            <p>Lien Information</p>
          </div>
          <CheckBoxComponent
            fieldName="Lien"
            value={formData.Lien}
            onChangeValue={(e) => {
              onChangeValue("Lien", e);
            }}
          />

          {formData.Lien && (
            <div className="_lien_holder_form_container">
              <TextBoxComponent
                fieldName="Lien Holder"
                value={formData.LienHolder}
                onChangeValue={(newValue) => {
                  onChangeValue("LienHolder", newValue);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="submit_btn_container">
        <Submit onSave={handleSubmit} saveColor="#3672B3" onCancel={oncancel} disabled={isSubmitting} />
      </div>
    </>
  );
};

export default VehicleForm;
