import { ColDef } from "ag-grid-community";
import edit_icon from "../../../../assets/icons/edit.svg";
import delete_icon from "../../../../assets/icons/delete.svg"
export const coloum: (
    setShowModal: (data: boolean) => void,
    setMediaType: (data: string) => void
) => ColDef[] = (
    setShowModal: (data: boolean) => void,
    setMediaType: (data: string) => void
) => {
        return [
            {
                field: "Video",
                cellRenderer: (_: string, index: number) => {
                    return (
                        <>
                            <span
                                onClick={() => {
                                    setShowModal(true)
                                    setMediaType("Video")
                                }}>
                                Video
                            </span>

                            <span
                                onClick={() => {
                                    setShowModal(true)
                                    setMediaType("Audio")
                                }}
                            > &nbsp;Audio</span>

                        </>

                    )
                }
            },
            { field: "Type" },
            { field: "Name" },
            { field: "Description" },
            { field: "Date Entered" },
            { field: "Time" },
            {
                field: "Action",
                cellRenderer: () => {
                  return (
                    <div className="table-cellRenderer">
                      {
                        <img
                          className="table-cellRenderer-img"
                          src={edit_icon}
                          title="Edit"
                          onClick={() => {
                            // if (e?.data?.ParticipantId) {
                            //   edit(e?.data);
                            // }
                          }}
                        />
                      }
                      {
                        <img
                          title="Delete"
                          className="table-cellRenderer-img"
                          src={delete_icon}
                          alt="Delete"
                          onClick={() => {
                            // setDeleteAgencyId(e?.data?.ParticipantId);
                            // setdeletePopup(true);
                          }}
                        />
                      }
                    </div>
                  );
                },
              },
        ]
    }