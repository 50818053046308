import {format} from "date-fns";
import { ColDef } from "ag-grid-community";
import edit_icon from "../../../../../assets/icons/edit.svg";
import delete_icon from "../../../../../assets/icons/delete.svg";
import { setTableData } from "./ProsecutionIState";

export const coloum: (
  NavigateToEditPage: (data: string) => void,
  setdeletePopup: (data: boolean) => void,
  setDeleteVehicleId: (id: string | null) => void,
) => ColDef[] = (
  NavigateToEditPage: (data: string) => void,
  setdeletePopup: (data: boolean) => void,
  setDeleteVehicleId: (id: string | null) => void,
) => {
    return [
      
      { field: "Case sent to"},
      { field: "Prosecution"},
      { field: "Date time sent"},
      { field: "Method sent"},
      { field: "Created On"},
      { field: "Modified On"},
      {
        field: "Action",
        cellRenderer: (e: { data: setTableData }) => {
          return (
            <div className="table-cellRenderer">
              {
                <img
                  className="table-cellRenderer-img"
                  src={edit_icon}
                  title="Edit"
                  onClick={() => {
                  }}
                />
              }
              {
                <img
                  title="Delete"
                  className="table-cellRenderer-img"
                  src={delete_icon}
                  alt="Delete"
                  onClick={() => {
                    setdeletePopup(true);
                  }}
                />
              }
            </div>
          );
        },
      },
    ];
  };