import { useEffect, useState } from "react";
import { coloum } from "./Config";
import { SetTableData } from "./CADInformationI";
import { CADInformationFunction } from "./CADInformationFunction";
import { Table } from "../../CommonComponents/Table/Table";
import { Loader } from "../../CommonComponents/Loader/Loader";

export const CADInformation: React.FC = () => {
  const cADInformationFunction = new CADInformationFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  const initialRender = () => {
    cADInformationFunction.getCADInformationTable(
      setTableData,
      setLoading,
      setTableTotalRecord
    );
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="UserManagement">
        <p className="header">CAD Information</p>
        <div className="table">
          <Table
            columns={coloum()}
            columnsValue={tableData}
            cursorPointer={true}
            setpageCount={setpageCount}
            setpageNumber={setpageNumber}
            tableTotalRecord={tableTotalRecord}
          />
        </div>
      </div>
    </>
  );
};
