import { Fetcher } from "../../../../../services/Fetcher";
import { successUpdateMessage } from "../../../../../utils/const";
import { body, getConfigurationResponceData, Submenu } from "./MenuI";

const fetcher = new Fetcher();
export class MenuFunction {
  async getConfigurationData(
    setLoading: (data: boolean) => void,
    setDynamicSubMenuList: (data: Submenu[]) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(
      `/configuraton/b481379f-0262-4621-b938-49713f5c48ea/menu`
    );
    const DynamicSubMenuList: getConfigurationResponceData[] = response?.Data;

    setDynamicSubMenuList(
      DynamicSubMenuList.map((val, i) => {
        return {
          id: val.Order,
          MenuId: val.MenuId,
          title: val.MenuName,
        };
      }).sort((a, b) => a.id - b.id)
    );
    setLoading(false);
  }

  async saveOrderConfigurationData(
    setLoading: (data: boolean) => void,
    body: body[]
  ) {
    setLoading(true);
    await fetcher.post(
      `/configuraton/b481379f-0262-4621-b938-49713f5c48ea/menu`,
      body,
      { message: successUpdateMessage, show: true }
    );

    setLoading(false);
  }
}
