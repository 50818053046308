import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../CommonComponents/Table/Table";
import LocationMetaData from "../../Location/LocationMetaData";
import { SetTableData } from "./ArticlesIState";
import { coloum } from "./Config";
import { Filter } from "./Components/Filter";
import Breadcrumbs from "../../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

export const ArticleListing: React.FC = () => {
  const [width, setwidth] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Last Name");
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");

  const { incidentId } = useParams<InvolvementMainIParams>();

  let incidentNumber = localStorage.getItem("Incidentnumber");
  const edit = (e: SetTableData) => {};

  return (
    <>
      <Loader loading={loading} />
      <div className="_incident_location_container_fluid_">
        <div className="_location_left_container_">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="UserManagement _location_right_container">
       
          <div className="_user_management_container">
             <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Incidents", path: "/incidents" },
            { title: incidentNumber ? incidentNumber : "", path: "" },
            { title: "Articles", path: `/incidents/${incidentId}/articles`},
          ]}
        />
            <p className="header">Articles {"  "} 1285743</p>
            <Filter
              Name={Name}
              setName={setName}
              setType={setType}
              Type={Type}
            />
            <div className="table">
              <Table
                columns={coloum(edit)}
                columnsValue={tableData}
                cursorPointer={true}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                headerBackgroundColor="#e7e7e7"
                
                
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
