import React from "react";
import { formatDatewithTime } from "../../../../../utils/Helper/Helper";
import { viewAllNarrative } from "../NarativeListing/NarrativeIState";
interface NarrativeViewAllListProps {
  totalLoadedRecords: viewAllNarrative[];
}
const NarrativeViewAllList: React.FC<NarrativeViewAllListProps> = ({
  totalLoadedRecords,
}) => {

  return (
    <div className="_narrative_view_all_container">
      {totalLoadedRecords?.map((values, index) => (
        <div className="narrative_card_container" key={values?.NarrativeId}>
          <div className="_narrative_user_submitted">
            <p>Title</p>
            <div className="_narrative_created_on_value">
              <span className="narrative_meta_values">{values?.Title}</span>
            </div>
          </div>
          <div className="_narrative_overview_meta_data">
            <div className="_narrative_created_on">
              <p>Created On</p>
              <div className="_narrative_created_on_value">
                <span className="narrative_meta_values">
                  {formatDatewithTime(values?.CreatedOn)}
                </span>
              </div>
            </div>
            <div className="_narrative_user_submitted">
              <p>User Submitted</p>
              <div className="_narrative_created_on_value">
                <span className="narrative_meta_values">
                  {values?.FirstName + " " + values?.LastName}
                </span>
              </div>
            </div>
          </div>
          <div className="_public_narrative_content">
            <p
              dangerouslySetInnerHTML={{ __html: values?.Narrative ?? "" }}
            ></p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NarrativeViewAllList;
