import { Fetcher } from "../../../../../../../services/Fetcher";
import { ParticipantionvalidationIRes } from "../AddNewNameI";
import { InvolvementMainIState } from "../Involvement/InvolvementMainI";
import { MultipleAddressIState } from "./Components/MultipleAddress/MultipleAddressI";
import {
  EmergencyMainIState,
  ExternalContactIRes,
  setsuffixIState,
} from "./Components/MultipleExternalAddress/MultipleEmergencyContactI";

const fetcher = new Fetcher();
export class ExternalContactFunction {
  ExternalContactValidation(formData: EmergencyMainIState[]) {
    let temp = false;
    formData.map((val) => {
      if (val.EmergencyContact.Firstname) {
        temp = true;
      }
    });
    return temp;
  }

  isAddressData(val: MultipleAddressIState) {
    return (
      val.AddressLine1 ||
      val.AddressLine2 ||
      val.AddressType ||
      val.AgencyId ||
      val.Apartment ||
      val.City ||
      val.Country ||
      val.ForeignAddress ||
      val.ForeignAddressCity ||
      val.ForeignAddressCountry ||
      val.ForeignAddressStateProvinceRegion ||
      val.ForeignAddressZip ||
      val.GridNumber ||
      val.StateProvidence ||
      val.Street ||
      val.StreetNumber ||
      val.StreetPrefix ||
      val.StreetSuffix ||
      val.LocationCategoryCode ||
      val.ZipCode ||
      val.LocationCategoryCode
    );
  }

  async addExternalContact(
    setLoading: (data: boolean) => void,
    formData: EmergencyMainIState[],
    incidentId: string,
    participantId: string,
    Participantionvalidation: ParticipantionvalidationIRes,
    setParticipantionvalidation: (data: ParticipantionvalidationIRes) => void,
    Mainfields: InvolvementMainIState
  ) {
    if (true) {
      setLoading(true);
      let data = await this.getGUID(Mainfields);
      const response = await fetcher.post(
        `/incident/${incidentId}/participant/${participantId}/emergency-contact`,
        formData
          .map((val, vali) => {
            if (
              val.EmergencyContact.Firstname &&
              this.ExternalContactValidation(formData)
            ) {
              return {
                ExternalContactID: val.ExternalContactID
                  ? val.ExternalContactID
                  : data[vali],
                Relation: val.EmergencyContact.Relation
                  ? val.EmergencyContact.Relation
                  : null,
                LastName: val.EmergencyContact.LastName
                  ? val.EmergencyContact.LastName
                  : null,
                FirstName: val.EmergencyContact.Firstname
                  ? val.EmergencyContact.Firstname
                  : null,
                MiddleName: val.EmergencyContact.Middlename
                  ? val.EmergencyContact.Middlename
                  : null,
                SuffixCodeId: val.EmergencyContact.Suffix
                  ? val.EmergencyContact.Suffix
                  : null,
                NoPermanentAddress: val.Address.NoPermanentAddress,
                UnknownAddress: val.Address.UnknownAddress,
                Address: {
                  Addresses: val.Address.AddressData.map((val2) => {
                    if (this.isAddressData(val2)) {
                      return {
                        LocationId: val2.LocationId ? val2.LocationId : null,
                        IncidentId: incidentId ? incidentId : null,
                        AddressType: val2.AddressType ? val2.AddressType : null,
                        LocationCategoryCode: val2.LocationCategoryCode
                          ? val2.LocationCategoryCode
                          : null,
                        HouseNumber: val2.Apartment ? val2.Apartment : null,
                        Ncicstreet1PrefixId: val2.StreetPrefix
                          ? val2.StreetPrefix
                          : null,
                        Street1Name: val2.StreetNumber
                          ? val2.StreetNumber
                          : null,

                        Ncicstreet1SuffixId: val2.StreetSuffix
                          ? val2.StreetSuffix
                          : null,
                        Street2Name: val2.Street ? val2.Street : null,
                        City: val2.City ? val2.City : null,
                        NcicstateId: val2.StateProvidence
                          ? val2.StateProvidence
                          : null,
                        ZipCode: val2.ZipCode ? val2.ZipCode : null,
                        Grid: val2.GridNumber ? val2.GridNumber : null,
                        NciccountryId: val2.Country ? val2.Country : null,
                        Address1: val2.AddressLine1 ? val2.AddressLine1 : null,
                        Address2: val2.AddressLine2 ? val2.AddressLine2 : null,
                        OtherCity: val2.ForeignAddressCity
                          ? val2.ForeignAddressCity
                          : null,
                        OtherState: val2.ForeignAddressStateProvinceRegion
                          ? val2.ForeignAddressStateProvinceRegion
                          : null,
                        OtherZip: val2.ForeignAddressZip
                          ? val2.ForeignAddressZip
                          : null,
                        OtherCountry: val2.ForeignAddressCountry
                          ? val2.ForeignAddressCountry
                          : null,
                        IsForeignAddress: val2.ForeignAddress,
                      };
                    } else {
                      setParticipantionvalidation({
                        ...Participantionvalidation,
                        EmergencyContact: {
                          ...Participantionvalidation.EmergencyContact,
                          locationcatigory: true,
                        },
                      });
                    }
                  }).filter((val) => val),
                  EntityModel: {
                    EntityType: "Participant",
                    EntityId: participantId,
                    EntitySubType: "Emergency",
                    EntitySubTypeId: val.ExternalContactID
                      ? val.ExternalContactID
                      : data[vali],
                  },
                },
                Contact: {
                  Contacts: [
                    ...val.Email.map((Emailval) => {
                      if (Emailval.Email && Emailval.Type) {
                        return {
                          EntityId: participantId,
                          EntitySubTypeId: val.ExternalContactID
                            ? val.ExternalContactID
                            : data[vali],
                          ContactValue: Emailval.Email ? Emailval.Email : null,
                          ContactTypeCodeId: Emailval.Type
                            ? Emailval.Type
                            : null,
                          ContactType: "Email",
                        };
                      } else {
                        setParticipantionvalidation({
                          ...Participantionvalidation,
                          EmergencyContact: {
                            ...Participantionvalidation.EmergencyContact,
                            email: true,
                          },
                        });
                      }
                    }).filter((val) => val),

                    ...val.PhoneNumber.map((PhoneNumberval) => {
                      if (PhoneNumberval.Type && PhoneNumberval.Number) {
                        return {
                          EntityId: participantId,
                          ContactValue: PhoneNumberval.Number
                            ? PhoneNumberval.Number
                            : null,
                          ContactTypeCodeId: PhoneNumberval.Type
                            ? PhoneNumberval.Type
                            : null,
                          CountryCodeId: PhoneNumberval.Country
                            ? PhoneNumberval.Country
                            : null,

                          Ext: PhoneNumberval.Ext ? PhoneNumberval.Ext : null,
                          ContactType: "Phone",
                        };
                      } else {
                        setParticipantionvalidation({
                          ...Participantionvalidation,
                          EmergencyContact: {
                            ...Participantionvalidation.EmergencyContact,
                            Phone: true,
                          },
                        });
                      }
                    }).filter((val) => val),
                  ],
                  EntityModel: {
                    EntityType: "Participant",
                    EntityId: participantId,
                    EntitySubType: "Emergency",
                    EntitySubTypeId: val.ExternalContactID
                      ? val.ExternalContactID
                      : data[vali],
                  },
                },
              };
            } else {
              setParticipantionvalidation({
                ...Participantionvalidation,
                EmergencyContact: {
                  ...Participantionvalidation.EmergencyContact,
                  firstname: true,
                },
              });
            }
          })
          .filter((val) => val)
      );
      setLoading(false);
    }
  }

  async getEmergencyContact(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/emergency-contact`
    );
    let ExternalContact: ExternalContactIRes[] = response.Data;
    if (ExternalContact.length) {
      return ExternalContact;
    }
  }

  async getGUID(Mainfields: InvolvementMainIState) {
    const response = await fetcher.get(
      `/${Mainfields.MultipleEmergencyContact.length}/Guid`
    );
    let res: string[] = response.Data;
    return res;
  }

  async getSuffixData(
    setSuffix: (data: setsuffixIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/system/category/WJ7Q3497JG");
    setSuffix(response.Data);
    setLoading(false);
  }
}
