import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Street Number" },
    { field: "Street" },
    { field: "Apartment" },
    { field: "City" },
    { field: "Zip Code" },
  ];
};
