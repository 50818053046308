
import React, { useEffect, useState } from "react";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { setTableData } from "./EvidenceIState";
import { coloum } from "./Config";






export const Evidence: React.FC = () => {
  const [tableData, setTableData] = useState<setTableData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);


  return (
    <div className="narrative">
      <div className="overview-container">
        <div className="overview-header_">
          <p><strong>Evidence</strong></p>
         
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
           headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
