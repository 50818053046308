import { useState } from "react";
import { TabsComponents } from "../../../../CommonComponents/TabsComponents/TabsComponents";
import { DetailTab } from "./DetailTab/DetailTab";
import { IPAddresses } from "./IPAddresses/IPAddresses";
import { ORI } from "./ORI/ORI";

interface TabsIndexIProps {
  viewComponent: boolean;
}

export const TabsIndex: React.FC<TabsIndexIProps> = ({ viewComponent }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  
  return (
    <>
      <div>
        <TabsComponents
          activeTab={activeTab}
          tabList={[
            { name: "Details", id: 1 },
            { name: "Groups ", id: 2 },
            { name: "IP Addresses", id: 3 },
            { name: "ORI", id: 4 },
          ]}
          handleTabChange={setActiveTab}
        />

        {activeTab == 0 ? (
          <DetailTab viewComponent={viewComponent} />
        ) : activeTab == 2 ? (
          <IPAddresses />
        ) : activeTab == 3 ? (
          <ORI />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
