import React from 'react'
import { MediaReaderI } from './MediaOverViewStateI'
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
const MediaReader: React.FC<MediaReaderI> = ({ mediaType }) => {
    return (
        <>

            <div className="media_reader_container">
                <div className="_media_title_container">
                    <h3>Lorem Ipsum</h3>
                    <span className="_media_name_container">
                        Firststreet-646
                    </span>
                    <span>12345677891010</span>
                </div>
                <div className="media_date_container">
                    <span>01/28/2023</span>
                </div>
                {mediaType === "Video" ? <div className="_media_player_container">
                    <ReactPlayer url={`https://www.youtube.com/watch?v=M3QaNRttwEw`} />
                </div> : <div className='_audio_media_player_container'>
                    <ReactAudioPlayer
                        src=""
                        autoPlay
                        controls
                    />
                </div>}

            </div>


        </>
    )
}

export default MediaReader