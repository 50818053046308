import { useState } from "react";
import { SetFormDataIState } from "../../Components/Pages/Incidents/Vehicle/AddEditVehicles/Tow/TowIState";
interface SaveChangesPopupHook {
  formData: SetFormDataIState;
  setFormData: (data: SetFormDataIState) => void;
}

const useTowFormState = (): SaveChangesPopupHook => {
    const [formData, setFormData] = useState<SetFormDataIState>({
        TowId: "",
        ReasonForImpound: "",
        TowReleaseDateTime: "",
        NotificationMethodId: "",
        OwnerNotifiedOfImpound: false,
        NotificationMeans: "",
        NotificationDateTime: "",
        CanVehicleBeReleased: false,
        ReasonForHold: "",
        TowCompanyId: "",
        TowInstructions: "",
        OwnerUnknown: false,
        TowLocationId: "",
        TowDestination: "",
        ProofOfInsurance: false,
        ToRegisteredOwner: false,
        Towed: false,
        Trailered: false,
        WithTitle: false,
        WithCurrentTabs: false,
        ToLicensedDriver: false,
        Other: "",
        SameAsTowCompany: false,
        LocationId: "",
        VehicleTowId: "",
        VehicleIncidentId: "",
        Involvement: "",
        OdometerReading: 0,
        DoorsLocked: false,
        IgnitionLocked: false,
        TrunkLocked: false,
        TowReleaseTime: "",
        KeyLocation: "",
        TowCompanyOther:"",
        KeysWithVehicle: false,
        KeysInIgnition: false,
        OwnerAllowUse: false,
        OwnerInvolvement: "",
        VehicleCondition: "",
        PlatesTaken: false,
        EstimatedValue: 0,
        VehicleInventory: "",
        SeizedProperty: false,
        SeizedForForfeiture: false,
        ReleasedToOwner: false,
        TabStolenDetails: "",
        VehicleWanted: false,
        ForfeitureId: "",
        TabStolen: false,
        ForfeitureVehicleStorageLocationId: "",
        PlateStolen: false,
        AddressType: "",
        IsPrimary: false,
        LocationCategoryCode: "",
        LocationName: "",
        Description: "",
        Intersection: false,
        HouseNumber: "",
        NotificationTime: "",
        Ncicstreet1PrefixId: "",
        Street1Name: "",
        Ncicstreet1SuffixId: "",
        Ncicstreet2PrefixId: "",
        Street2Name: "",
        Ncicstreet2SuffixId: "",
        City: "",
        NcicstateId: "",
        ZipCode: "",
        Grid: "",
        NciccountryId: "",
        Address1: "",
        Address2: "",
        OtherCity: "",
        OtherState: "",
        OtherZip: "",
        OtherCountry: "",
        IsForeignAddress: false,
        StateName: "",
        CountryName: "",
        Street1PrefixName: "",
        Street1SuffixName: "",
        Street2PrefixName: "",
        Street2SuffixName: "",
      });

  return {
    formData,
    setFormData,
  };
};

export default useTowFormState;
