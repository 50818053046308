import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import add from "../../../../../../../../../assets/icons/Icon.svg";
import {
  AdditionalJustifiablehomicideUIDataIProps,
  AggravatedAssaulthomicideCodeUIDataIProps,
  InjuryCategoryCodeUIDataIProps,
  VictimInformationIProps,
} from "./VictimInformationI";
import { useEffect, useState } from "react";
import { VictimInformationFunction } from "./VictimInformationFunction";
import { GetRaceDataIState } from "../../InvolvementMainI";

const AggravatedAssaulthomicideCodeUIData: React.FC<
  AggravatedAssaulthomicideCodeUIDataIProps
> = ({
  AggravatedAssaulthomicideCode,
  AggravatedAssaulthomicideCodeval,
  Mainfields,
  setMainfields,
}) => {
  const onchange = (key: String, value: string, id: number) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Victiminformation: {
          ...Mainfields.InvolvementTab.Victiminformation,
          AggravatedAssaulthomicideCode:
            Mainfields.InvolvementTab.Victiminformation.AggravatedAssaulthomicideCode.map(
              (val) => {
                if (val.id == id) {
                  return {
                    ...val,
                    AggravatedAssaulthomicideCode: value,
                    id: id,
                  };
                } else {
                  return { ...val };
                }
              }
            ),
        },
      },
    });
  };

  return (
    <>
      <DropdownComponent
        dataSource={AggravatedAssaulthomicideCode.map((val) => {
          return {
            id: val.NciccodeId,
            value: val.LongCode,
          };
        })}
        handleRowClick={(e) => {
          onchange(
            "AggravatedAssaulthomicideCode",
            e,
            AggravatedAssaulthomicideCodeval.id
          );
        }}
        value={AggravatedAssaulthomicideCodeval.AggravatedAssaulthomicideCode}
        fieldName="Aggravated Assault / homicide Code"
      />
    </>
  );
};

const InjuryCategoryCodeUIData: React.FC<InjuryCategoryCodeUIDataIProps> = ({
  InjuryType,
  InjuryCategoryCodeval,
  Mainfields,
  setMainfields,
}) => {
  const onchange = (key: String, value: string, id: number) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Victiminformation: {
          ...Mainfields.InvolvementTab.Victiminformation,
          InjuryCategoryCode:
            Mainfields.InvolvementTab.Victiminformation.InjuryCategoryCode.map(
              (val) => {
                if (val.id == id) {
                  return {
                    ...val,
                    InjuryCategoryCode: value,
                    id: id,
                  };
                } else {
                  return { ...val };
                }
              }
            ),
        },
      },
    });
  };

  return (
    <>
      <DropdownComponent
        dataSource={InjuryType.map((val) => {
          return {
            id: val.NciccodeId,
            value: val.LongCode,
          };
        })}
        handleRowClick={(e) => {
          onchange("InjuryCategoryCode", e, InjuryCategoryCodeval.id);
        }}
        value={InjuryCategoryCodeval.InjuryCategoryCode}
        fieldName="Injury Category Code"
      />
    </>
  );
};

const AdditionalJustifiablehomicideUIData: React.FC<
  AdditionalJustifiablehomicideUIDataIProps
> = ({
  AdditionalJustifiablehomicide,
  AdditionalJustifiablehomicideval,
  Mainfields,
  setMainfields,
}) => {
  const onchange = (key: String, value: string, id: number) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Victiminformation: {
          ...Mainfields.InvolvementTab.Victiminformation,
          AdditionalJustifiablehomicide:
            Mainfields.InvolvementTab.Victiminformation.AdditionalJustifiablehomicide.map(
              (val) => {
                if (val.id == id) {
                  return {
                    ...val,
                    AdditionalJustifiablehomicide: value,
                    id: id,
                  };
                } else {
                  return { ...val };
                }
              }
            ),
        },
      },
    });
  };

  return (
    <>
      <DropdownComponent
        dataSource={AdditionalJustifiablehomicide.map((val) => {
          return {
            id: val.NciccodeId,
            value: val.LongCode,
          };
        })}
        handleRowClick={(e) => {
          onchange(
            "AdditionalJustifiablehomicide",
            e,
            AdditionalJustifiablehomicideval.id
          );
        }}
        value={AdditionalJustifiablehomicideval.AdditionalJustifiablehomicide}
        fieldName="Additional Justifiable / homicide"
      />
    </>
  );
};

export const VictimInformation: React.FC<VictimInformationIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const victimInformationFunction = new VictimInformationFunction();
  const [VictimTypeData, setVictimTypeData] = useState<GetRaceDataIState[]>([]);
  const [InjuryType, setInjuryType] = useState<GetRaceDataIState[]>([]);
  const [AdditionalJustifiablehomicide, setAdditionalJustifiablehomicide] =
    useState<GetRaceDataIState[]>([]);
  const [OfficerAssignmentType, setOfficerAssignmentType] = useState<
    GetRaceDataIState[]
  >([]);

  const [
    OfficerActivityOrCircumstanceType,
    setOfficerActivityOrCircumstanceType,
  ] = useState<GetRaceDataIState[]>([]);

  const [AggravatedAssaulthomicideCode, setAggravatedAssaulthomicideCode] =
    useState<GetRaceDataIState[]>([]);

  const initialRender = () => {
    victimInformationFunction.VictimType(setVictimTypeData);
    victimInformationFunction.AggravatedAssaulthomicideCode(
      setAggravatedAssaulthomicideCode
    );
    victimInformationFunction.InjuryType(setInjuryType);
    victimInformationFunction.AdditionalJustifiablehomicide(
      setAdditionalJustifiablehomicide
    );

    victimInformationFunction.OfficerAssignmentType(setOfficerAssignmentType);

    victimInformationFunction.OfficerActivityOrCircumstanceType(
      setOfficerActivityOrCircumstanceType
    );
  };

  const AggravatedAssaulthomicideCodeUIDataClick = () => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Victiminformation: {
          ...Mainfields.InvolvementTab.Victiminformation,
          AggravatedAssaulthomicideCode: [
            ...Mainfields.InvolvementTab.Victiminformation
              .AggravatedAssaulthomicideCode,
            {
              AggravatedAssaulthomicideCode: "",
              id:
                Mainfields.InvolvementTab.Victiminformation
                  .AggravatedAssaulthomicideCode.length + 1,
              AgencyId: "",
              AggravatedAssaultHomicideId: "",
              IncidentId: "",
              PersonId: "",
            },
          ],
        },
      },
    });
  };

  const InjuryCategoryCodeUIDataClick = () => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Victiminformation: {
          ...Mainfields.InvolvementTab.Victiminformation,
          InjuryCategoryCode: [
            ...Mainfields.InvolvementTab.Victiminformation.InjuryCategoryCode,
            {
              InjuryCategoryCode: "",
              id:
                Mainfields.InvolvementTab.Victiminformation.InjuryCategoryCode
                  .length + 1,
              AgencyId: "",
              IncidentId: "",
              InjuryId: "",
              PersonId: "",
            },
          ],
        },
      },
    });
  };

  const AdditionalJustifiablehomicideUIDataClick = () => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Victiminformation: {
          ...Mainfields.InvolvementTab.Victiminformation,
          AdditionalJustifiablehomicide: [
            ...Mainfields.InvolvementTab.Victiminformation
              .AdditionalJustifiablehomicide,
            {
              AdditionalJustifiablehomicide: "",
              id:
                Mainfields.InvolvementTab.Victiminformation
                  .AdditionalJustifiablehomicide.length + 1,
              AgencyId: "",
              AggravatedAssaultHomicideId: "",
              IncidentId: "",
              PersonId: "",
              AdditionalJustifiableHomicideId: "",
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <p className="subheader">Victim information</p>
      <div className="Involvement_main">
        <DropdownComponent
          dataSource={VictimTypeData.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          handleRowClick={(e) => {
            setMainfields({
              ...Mainfields,
              InvolvementTab: {
                ...Mainfields.InvolvementTab,
                onChange: true,
                Victiminformation: {
                  ...Mainfields.InvolvementTab.Victiminformation,
                  VictimType: e,
                },
              },
            });
          }}
          value={Mainfields.InvolvementTab.Victiminformation.VictimType}
          fieldName="Victim Type"
        />
        <div style={{ width: "100%" }}>
          <DropdownComponent
            dataSource={[]}
            disabled={true}
            handleRowClick={(e) => {}}
            value={""}
            fieldName="USRCode"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              name="Add Code"
              icon={add}
              showBackgroundColor={true}
              color="#f0f0f0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          {Mainfields.InvolvementTab.Victiminformation.AggravatedAssaulthomicideCode.map(
            (AggravatedAssaulthomicideCodeval, i) => {
              return (
                <>
                  <AggravatedAssaulthomicideCodeUIData
                    AggravatedAssaulthomicideCode={
                      AggravatedAssaulthomicideCode
                    }
                    AggravatedAssaulthomicideCodeval={
                      AggravatedAssaulthomicideCodeval
                    }
                    Mainfields={Mainfields}
                    setMainfields={setMainfields}
                  />
                </>
              );
            }
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              handleClick={AggravatedAssaulthomicideCodeUIDataClick}
              name="Add"
              icon={add}
              showBackgroundColor={true}
              color="#F0F0F0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          {Mainfields.InvolvementTab.Victiminformation.InjuryCategoryCode.map(
            (InjuryCategoryCodeval, i) => {
              return (
                <>
                  <InjuryCategoryCodeUIData
                    InjuryType={InjuryType}
                    InjuryCategoryCodeval={InjuryCategoryCodeval}
                    Mainfields={Mainfields}
                    setMainfields={setMainfields}
                  />
                </>
              );
            }
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              handleClick={InjuryCategoryCodeUIDataClick}
              name="Add"
              icon={add}
              showBackgroundColor={true}
              color="#F0F0F0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
      </div>
      <div style={{ alignItems: "center" }} className="Involvement_main">
        <DropdownComponent
          dataSource={OfficerActivityOrCircumstanceType.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          handleRowClick={(e) => {
            setMainfields({
              ...Mainfields,
              InvolvementTab: {
                ...Mainfields.InvolvementTab,
                onChange: true,
                Victiminformation: {
                  ...Mainfields.InvolvementTab.Victiminformation,
                  EnforcementOfficialActivityCategoryCode: e,
                },
              },
            });
          }}
          value={
            Mainfields.InvolvementTab.Victiminformation
              .EnforcementOfficialActivityCategoryCode
          }
          fieldName="Enforcement Official Activity Category Code"
        />

        <DropdownComponent
          dataSource={OfficerAssignmentType.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          handleRowClick={(e) => {
            setMainfields({
              ...Mainfields,
              InvolvementTab: {
                ...Mainfields.InvolvementTab,
                onChange: true,
                Victiminformation: {
                  ...Mainfields.InvolvementTab.Victiminformation,
                  EnforcementOfficialAssignmentCategoryCode: e,
                },
              },
            });
          }}
          value={
            Mainfields.InvolvementTab.Victiminformation
              .EnforcementOfficialAssignmentCategoryCode
          }
          fieldName="Enforcement Official Assignment Category Code"
        />
        <div style={{ marginTop: "40px" }}>
          <CheckBoxComponent
            value={
              Mainfields.InvolvementTab.Victiminformation
                .OfficerOtherJurisdiction
            }
            fieldName="Officer Other Jurisdiction"
            onChangeValue={(e) => {
              setMainfields({
                ...Mainfields,
                InvolvementTab: {
                  ...Mainfields.InvolvementTab,
                  onChange: true,
                  Victiminformation: {
                    ...Mainfields.InvolvementTab.Victiminformation,
                    OfficerOtherJurisdiction: e,
                  },
                },
              });
            }}
          />
        </div>
        <div style={{ width: "100%" }}>
          {Mainfields.InvolvementTab.Victiminformation.AdditionalJustifiablehomicide.map(
            (AdditionalJustifiablehomicideval, i) => {
              return (
                <>
                  <AdditionalJustifiablehomicideUIData
                    AdditionalJustifiablehomicide={
                      AdditionalJustifiablehomicide
                    }
                    AdditionalJustifiablehomicideval={
                      AdditionalJustifiablehomicideval
                    }
                    Mainfields={Mainfields}
                    setMainfields={setMainfields}
                  />
                </>
              );
            }
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              handleClick={AdditionalJustifiablehomicideUIDataClick}
              name="Add "
              icon={add}
              showBackgroundColor={true}
              color="#F0F0F0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
      </div>
      <div className="Involvement_main">
        <div style={{ width: "100%" }}>
          <DropdownComponent
            dataSource={[]}
            handleRowClick={(e) => {}}
            value={""}
            disabled={true}
            fieldName="Victim Offender Association"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              name="Add Association"
              icon={add}
              showBackgroundColor={true}
              color="#f0f0f0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <DropdownComponent
            dataSource={[]}
            handleRowClick={(e) => {}}
            value={""}
            disabled={true}
            fieldName="Victim Offender Relationship"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              name="Add Relationship"
              icon={add}
              showBackgroundColor={true}
              color="#f0f0f0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <DropdownComponent
            dataSource={[]}
            handleRowClick={(e) => {}}
            value={""}
            disabled={true}
            fieldName="Victim Organization Association"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              name="Add Association"
              icon={add}
              showBackgroundColor={true}
              color="#f0f0f0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <DropdownComponent
            dataSource={[]}
            handleRowClick={(e) => {}}
            value={""}
            disabled={true}
            fieldName="Victim Person Association"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <ButtonComponents
              name="Add Association"
              icon={add}
              showBackgroundColor={true}
              color="#f0f0f0"
              iconStyle={{ filter: "invert(1)" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
