import delete_icon from "../../../../../assets/icons/delete.svg";
import edit_icon from "../../../../../assets/icons/edit.svg";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, SetTableData } from "./ListPolicyIState";

export const OrderByFieldName: OrderByFieldNameI = {
  "Policy Name": "PolicyName",
  Description: "Description",
  Status: "Status",
};

export const column: (
  NavigateToEditPage: (data: SetTableData) => void,
  openDeletePopup: (data: string) => void
) => ColDef[] = (
  NavigateToEditPage: (data: SetTableData) => void,
  openDeletePopup: (data: string) => void
) => {
  return [
    { field: "Policy Name" },
    { field: "Description" },
    { field: "Status" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <>
            <div className="table-cellRenderer">
              {!e.data.IsSuperAdminOnly && (
                <img
                  onClick={() => {
                    NavigateToEditPage(e.data);
                  }}
                  className="table-cellRenderer-img"
                  src={edit_icon}
                  title="Edit"
                />
              )}
              {e.data.Status == "Active" && !e.data.IsSuperAdminOnly && (
                <img
                  onClick={() => {
                    openDeletePopup(e.data.ID);
                  }}
                  className="table-cellRenderer-img"
                  src={delete_icon}
                  title="Delete"
                />
              )}
            </div>
          </>
        );
      },
    },
  ];
};
