import { Fetcher } from "../../../../services/Fetcher";
import {
  convertToISOFormat,
  ConvertToLocalDateFormate,
  formatDatewithTime,
} from "../../../../utils/Helper/Helper";
import { successDeleteMessage } from "../../../../utils/const";
import {
  GetAgencyManagementTableDataI,
  OrderByType,
  SetTableData,
} from "./AgencyManagementIState";
import { OrderByFieldName } from "./Config";

const fetcher = new Fetcher();
export class AgencyManagementFunction {
  async GetAgencyManagementTableData(
    setTableData: (data: SetTableData[]) => void,
    Agencyname: string,
    AgencyType: string,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    agencyTypeDataalue: string,
    location: string,
    Creationdatefrom: string | null,
    Creationdateto: string | null,
    OrderByDescending?: boolean,
    OrderBy?: string
  ) {
    setLoading(true);

    const isSearch = !!(
      Agencyname ||
      agencyTypeDataalue ||
      location ||
      Creationdatefrom ||
      Creationdateto
    );

    const response = await fetcher.post(`/agency/search`, {
      Name: Agencyname ? Agencyname : null,
      PageNumber: !isSearch ? pageNumber : 1,
      PageSize: pageCount,
      AgencyTypeCodeId: agencyTypeDataalue ? agencyTypeDataalue : null,
      Location: location ? location : null,
      StartDate: Creationdatefrom ? convertToISOFormat(Creationdatefrom) : null,
      EndDate: Creationdateto ? convertToISOFormat(Creationdateto) : null,
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
    });
    const TableJson = this.createTableJson(response?.Data);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: GetAgencyManagementTableDataI[]) {
    return data.map((val: GetAgencyManagementTableDataI, i: number) => {
      return {
        "Agency Name": val.AgencyName,
        "Agency Type": val.AgencyTypeName,
        Description: val.AgencyDescription,
        Address: `${val.AgencyAddress ? val.AgencyAddress : ""} ${
          val.AgencyCity ? val.AgencyCity : ""
        } ${val.AgencyStateName ? val.AgencyStateName : ""} ${
          val.AgencyZipcode ? val.AgencyZipcode : ""
        }`,
        "Created By": val.CreatedByUserName,
        "Created On": ConvertToLocalDateFormate(val.CreatedOn),
        "Modified On": ConvertToLocalDateFormate(val.ModifiedOn),
        ID: val.AgencyId,
      };
    });
  }

  async deleteAgency(
    id: string,
    setLoading: (data: boolean) => void,
    refreshData: () => void
  ) {
    setLoading(true);
    await fetcher.delete(
      `/agency/${id}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    refreshData();
  }
}
