import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MasterMainIParams } from "../../../../MasterOverview/MasterNameIState";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { BusinessAddressData } from "./BusinessAddressIState";
import { BusinessAddressFunction } from "./BusinessAddressFunction";

export const BusinessAddress: React.FC = () => {
  const businessAddressFunction =new BusinessAddressFunction();
  const [tableData, setTableData] = useState<BusinessAddressData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const { nameId } = useParams<MasterMainIParams>();
  const getAddress = () => {
    if(nameId){
      businessAddressFunction.getBusinessAddressMain(setTableData, setTableTotalRecord,nameId);
    }
   
  };

  useEffect(() => {
    getAddress();
  }, []);
  return (
    <div
      style={{ width: "auto", display: "flex", gap: "20px"}}
    >
      <div className="overview-container">
        <div className="overview-header_">
          <p>
            <strong>Addresses: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
          <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
              
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
