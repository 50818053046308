import { TextBoxComponent } from "../../../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { EmploymentContactIProps } from "./EmploymentContactI";

export const EmploymentContact: React.FC<EmploymentContactIProps> = ({
  Employmentval,
  Mainfields,
  setMainfields,
}) => {
  const onChangeValue = (
    key: string,
    value: string | number | boolean | null
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes: true,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            return {
              ...val,
              [key]: value,
            };
          } else {
            return val;
          }
        }),
      },
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <p className="subheader_text">Employment Contact</p>
        <div className="employement">
          <TextBoxComponent
            onChangeValue={(e) => {
              onChangeValue("ContactLastname", e);
            }}
            fieldName="Contact Last Name*"
            value={Employmentval.ContactLastname}
          />
          <TextBoxComponent
            fieldName="Contact First Name*"
            value={Employmentval.ContactFirstname}
            onChangeValue={(e) => {
              onChangeValue("ContactFirstname", e);
            }}
          />
        </div>
      </div>
    </>
  );
};
