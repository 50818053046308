import { EmailIstate } from "../../../ExternalContact/ExternalContactI";
import { InvolvementMainIState } from "../../../Involvement/InvolvementMainI";

export class EmailAddressFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: EmailIstate[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: EmailIstate[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.IdentificationAndContact.Email);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      IdentificationAndContact: {
        ...this.Mainfields.IdentificationAndContact,
        onChange: true,
        Email: [
          ...this.Mainfields.IdentificationAndContact.Email.map((val) => {
            return {
              ...val,
              push: true,
            };
          }),
          {
            id:
              this.Mainfields.IdentificationAndContact.Email[
                this.Mainfields.IdentificationAndContact.Email.length - 1
              ].id + 1,
            Email: "",
            ExternalContactID: "",
            Type: "",
            TypeDropdownvalue: "",
            push: false,
          },
        ],
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.IdentificationAndContact.Email);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      IdentificationAndContact: {
        ...this.Mainfields.IdentificationAndContact,
        onChange: true,
        Email: this.Mainfields.IdentificationAndContact.Email.filter((val) => {
          if (val.id != id) {
            return val;
          }
        }).length
          ? this.Mainfields.IdentificationAndContact.Email.filter((val) => {
              if (val.id != id) {
                return val;
              }
            })
          : [
              {
                id: 1,
                Email: "",
                ExternalContactID: "",
                Type: "",
                TypeDropdownvalue: "",
              },
            ],
      },
    });

    this.setedit(false);
  };
}
