import { useState } from "react";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { CheckBoxComponent } from "../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DateBoxComponents } from "../../../../CommonComponents/Fields/DateBox/DateBox";
import LocationMetaData from "../../Location/LocationMetaData";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { TimePickerComponenets } from "../../../../CommonComponents/Fields/TimePicker/TimePickerComponenets";
import { TextAreaComponent } from "../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { PhoneNumber } from "./Components/PhoneNumber/PhoneNumber";
import useProsecutionIState from "../../../../../hooks/ProsecutionIState/ProsecutionIState";
import { EmailAddress } from "./Components/EmailAddress/EmailAddress";
import { Submit } from "../../../../CommonComponents/Fields/Submit/Submit";

export const AddProsecution: React.FC = () => {
    const { MainfieldsBusiness, setMainfieldsBusiness } = useProsecutionIState();
  const [loading, setLoading] = useState<boolean>(false);
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [width, setwidth] = useState<number>(10);


  const handleSave=()=>{

  }
  const oncancel=()=>{

  }
  return (
    <>
      <Loader loading={loading} />
      <div className="_incident_location_container_fluid_">
        <div className="_location_left_container">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="_user_management_container">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path:  `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              { title: "Prosecution", path:  `/incidents/${incidentId}/prosecution` },
              { title: "Add", path:  `/incidents/${incidentId}/prosecution` },
            ]}
          />
          <p className="header">Add Prosecution</p>

          <div className="form-row1">
            <div className="form-group">
            <TextBoxComponent fieldName="Case sent to" value=""/>
            </div>
          </div>
          <div className="article">
            <div className="form-group">
             <DateBoxComponents fieldName="Sent Date"/>
            </div>
            <div className="form-group">
              <TimePickerComponenets fieldName="Sent Time"/>
            </div>
            <div className="form-group">
              <DropdownComponent dataSource={[]} fieldName="Method Sent"/>
            </div>
          </div>

          <div className="form-row1">
            <div className="width_size">
            <TextAreaComponent fieldName="Prosecution notes" value=""/>
            </div>
          </div>

        
          <div className="line"></div>
           <div className="form-group">
              <TextBoxComponent fieldName="Prosecutor Office" value=""/>
            </div>
          <div className="form-row1">
           

            <div className="form-group">
              <TextBoxComponent fieldName="Prosecutor Last Name" value="" />
            </div>
            <div className="form-group">
              <TextBoxComponent fieldName="Prosecutor First Name" value="" />
            </div>
          </div>
          
          <div className="multiple_vehicle_owner_container">
                  <div className="_owner_address_type_container">
                    <div className="_address_type_container">
                      <DropdownComponent
                        dataSource={[]}
                        fieldName="Address Type"
                      />
                    </div>

                    
                  </div>

                  <div className="involvemt_street_inline_container">
                    <div className=" lien_owner_street_address">
                      <div className="_involvement_meta_info">
                        <TextBoxComponent
                          
                          fieldName="Street Number"
                          value=""
                        />
                      </div>

                      <div className="_involvement_meta_info">
                        <DropdownComponent
                        dataSource={[]}
                          fieldName="Street Prefix"
                        />
                      </div>
                    </div>
                    <div className=" lien_owner_street_address">
                      <div className="involvemet_street_container">
                        <TextBoxComponent
                         value=""
                          fieldName="Street"
                        />
                      </div>

                      <div className="_involvement_meta_info">
                        <DropdownComponent
                         dataSource={[]}
                          fieldName="Street Suffix"
                        />
                      </div>
                      <div className="_involvement_meta_info">
                        <TextBoxComponent
                         value=""
                          fieldName="Apartment"
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" vehicle_address_city_address_container">
                    <div className="_address_state_providence">
                      <TextBoxComponent
                        value=""
                        fieldName="City"
                      />
                    </div>

                    <div className="_address_state_providence">
                      <DropdownComponent
                       dataSource={[]}
                        fieldName="State / Providence"
                      />
                    </div>

                    <div className="_address_zip_code">
                      <TextBoxComponent
                       value=""
                        fieldName="Zip Code"
                      />
                    </div>
                  </div>

                  <div className="_vehicle_country_grid_container">
                    <div className="_address_country_container">
                      <DropdownComponent
                       dataSource={[]}
                       
                        fieldName="Country"
                      />
                    </div>

                    <div className="_address_grid_number">
                      <TextBoxComponent
                        value=""
                        fieldName="Grid Number"
                      />
                    </div>
                  </div>
                </div>
                <div style={{gap:"100px",display:"flex",flexDirection:"row"}}>
                    <div className="">
                    <PhoneNumber MainfieldsBusiness={MainfieldsBusiness} setMainfieldsBusiness={setMainfieldsBusiness}/>
                    </div>
                <div className="width_field">
                <EmailAddress MainfieldsBusiness={MainfieldsBusiness} setMainfieldsBusiness={setMainfieldsBusiness}/> 
                </div>
              
                </div>
                
               
        </div>
       
      </div>
      <div className="submit_btn_container">
                <Submit cancelLabel="Cancel" saveLabel="Save" onSave={handleSave} saveColor="#3672B3" onCancel={oncancel}/>
                </div>
    </>
  );
};
