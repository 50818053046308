import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DateBoxComponents } from "../../../../../../../../CommonComponents/Fields/DateBox/DateBox";
import { DeceasedIProps } from "./DeceasedI";

export const Deceased: React.FC<DeceasedIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        [name]: value,
        changes: true,
      },
    });
  };

  return (
    <>
      <div className="width_field">
        <p className="subheader">Deceased</p>
        <div className="Involvement_left_row_checkbox">
          <CheckBoxComponent
            value={Mainfields.AdditionalInfo.IsDeceased}
            fieldName="Deceased"
            onChangeValue={(e) => {
              onChangeValue("IsDeceased", e);
            }}
          />
          <CheckBoxComponent
            value={Mainfields.AdditionalInfo.DOA}
            onChangeValue={(e) => {
              if (e) {
                setMainfields({
                  ...Mainfields,
                  AdditionalInfo: {
                    ...Mainfields.AdditionalInfo,
                    DOA: e,
                    IsDodunknown: false,
                    changes: true,
                  },
                });
              } else {
                setMainfields({
                  ...Mainfields,
                  AdditionalInfo: {
                    ...Mainfields.AdditionalInfo,
                    DOA: e,
                    IsDodunknown: true,
                    changes: true,
                  },
                });
              }
            }}
            fieldName="Date of Death"
          />
          <CheckBoxComponent
            value={Mainfields.AdditionalInfo.IsDodunknown}
            fieldName="Date of Death Unknown"
            onChangeValue={(e) => {
              if (e) {
                setMainfields({
                  ...Mainfields,
                  AdditionalInfo: {
                    ...Mainfields.AdditionalInfo,
                    IsDodunknown: e,
                    changes: true,
                    IsDeceased: true,
                  },
                });
              } else {
                setMainfields({
                  ...Mainfields,
                  AdditionalInfo: {
                    ...Mainfields.AdditionalInfo,
                    IsDodunknown: e,
                    changes: true,
                  },
                });
              }
            }}
          />
        </div>
        <div className="width_field">
          <DateBoxComponents
            fieldName="Date of Death"
            onValueChange={(e) => {
              if (e) {
                setMainfields({
                  ...Mainfields,
                  AdditionalInfo: {
                    ...Mainfields.AdditionalInfo,
                    DateOfDeath: e,
                    DOA: true,
                    changes: true,
                  },
                });
              }
            }}
            value={Mainfields.AdditionalInfo.DateOfDeath}
            disabled={Mainfields.AdditionalInfo.IsDodunknown}
          />
        </div>
      </div>
    </>
  );
};
