import React, { useEffect, useState } from "react";
export const Overview: React.FC = () => {
  return (
    <div className="overview_">
      <div className="width_size">
        <div className="overview-header">
          <p>
            <strong>Overview</strong>
          </p>
         
        </div>
        <div className="overview__">
  <div className="width_field">
    <p>Case Number: <strong>12837733</strong></p>
    <p>Location: <strong>123 N 5th Street Apt #123, St. Paul, Minnesota</strong></p>
    <p>Call Date/Time: <strong>12/10/2023, 18:43:19</strong></p>
    <p>Crimes: <strong>Lorem ipsum</strong></p>
    <p>Expunge/Seal: <strong>--</strong></p>
  </div>
  <div className="summary_">
    <p>Reviewer Status: <strong>Not Completed – 1 Report</strong></p>
    <p>Investigator: <strong>--</strong></p>
    <p>Unit Assigned: <strong>--</strong></p>
    <p>Case Status: <strong>--</strong></p>
    <p>Case Disposition: <strong>--</strong></p>
    <p>Case Disp. Date: <strong>--</strong></p>
  </div>
</div>


       
      </div>
    </div>
  );
};
