import { useEffect, useState } from "react";
import { validateEmail } from "../../../../../../../../../../../utils/Helper/Helper";
import { DropdownComponent } from "../../../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import {
  EmailAddressDataUIProps,
  EmailAddressIProps,
} from "./EmployementEmailI";
import { PhoneNumberFunction } from "../../../../../ExternalContact/Components/Phone/PhoneNumberFunction";
import { setContactTypeDataIState } from "../../../../../ExternalContact/ExternalContactI";
import { Add } from "../../../../../../../../../../../assets";

export const EmailAddressDataUI: React.FC<EmailAddressDataUIProps> = ({
  EmailAddressval,
  Mainfields,
  setMainfields,
  Employmentval,
  ContactTypeData,
  Participantionvalidation,
}) => {
  const [validationMessage, setValidationMessage] = useState<string>("");

  const onChangeValue = (
    key: string,
    value: string,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    if (key === "Email" && !validateEmail(value)) {
      setValidationMessage("Please enter a valid email address");
    } else {
      setValidationMessage("");
    }

    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            return {
              ...val,
              Email: val.Email.map((Emailval) => {
                if (Emailval.id == id) {
                  if (key2) {
                    return { ...Emailval, [key]: value, [key2]: value2 };
                  } else {
                    return { ...Emailval, [key]: value };
                  }
                } else {
                  return Emailval;
                }
              }),
            };
          } else {
            return val;
          }
        }),
      },
    });
  };

  const remove = (id: number) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            if (val.Email.length > 1) {
              return {
                ...val,
                Email: val.Email.filter((Emailval) => {
                  if (Emailval.id != id) {
                    return Emailval;
                  }
                }),
              };
            } else {
              return val;
            }
          } else {
            return val;
          }
        }),
      },
    });
  };

  return (
    <>
      <div className="MultiplePhoneNumber">
        <div style={{ width: "60%" }}>
          <DropdownComponent
            dataSource={ContactTypeData.map((val) => {
              return {
                id: val.SystemLevelCodeId,
                value: val.LongCode,
              };
            })}
            fieldName="Type"
            value={EmailAddressval.Type}
            validation={Participantionvalidation.AdditionalInfo.email}
            handleRowClick={(e, dropdownValue) => {
              onChangeValue(
                "Type",
                e,
                EmailAddressval.id,
                "TypeDropdownvalue",
                dropdownValue
              );
            }}
          />
        </div>
        <div style={{ position: "relative" }} className="width_size">
          <TextBoxComponent
            value={EmailAddressval.Email}
            validation={Participantionvalidation.AdditionalInfo.email}
            fieldName="Email Address"
            onChangeValue={(e) => {
              onChangeValue("Email", e, EmailAddressval.id);
            }}
          />
          {validationMessage && (
            <div
              style={{
                color: "red",
                fontSize: "13px",
                position: "absolute",
                bottom: "-25px",
              }}
            >
              {validationMessage}
            </div>
          )}
        </div>
        {Employmentval.Email.length > 1 && (
          <p
            className="removeIcon"
            onClick={() => {
              remove(EmailAddressval.id);
            }}
            title="Remove"
          >
            -
          </p>
        )}
      </div>
    </>
  );
};

export const EmailAddress: React.FC<EmailAddressIProps> = ({
  Mainfields,
  setMainfields,
  Employmentval,
  Participantionvalidation,
}) => {
  const phoneNumberFunction = new PhoneNumberFunction();
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);
  const handleClick = () => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes: true,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            return {
              ...val,
              Email: [
                ...val.Email,
                {
                  id: val.Email.length + 1,
                  Email: "",
                  ExternalContactID: "",
                  Type: "",
                  TypeDropdownvalue: "",
                },
              ],
            };
          } else {
            return val;
          }
        }),
      },
    });
  };

  const initialRender = () => {
    phoneNumberFunction.getContactTypeData(setContactTypeData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_text">Email</p>
            {Employmentval.Email.map((EmailAddressval) => {
              return (
                <>
                  <EmailAddressDataUI
                    Mainfields={Mainfields}
                    setMainfields={setMainfields}
                    EmailAddressval={EmailAddressval}
                    Employmentval={Employmentval}
                    ContactTypeData={ContactTypeData}
                    Participantionvalidation={Participantionvalidation}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="edit-button_" style={{ marginTop: "5%" }}>
          <ButtonComponents
            name="Add Email Address"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
