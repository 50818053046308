import React from "react";
import { Alerts } from "../../MasterOverview/Components/Alerts/Alerts";
import { Vehicles } from "../../MasterOverview/Components/Vehicles/Vehicles";
import { Involvement } from "../../MasterOverview/Components/Involvement/Involvement";
import { Overview } from "./Components/BusinessOverview/BusinessOverview";
import { BusinessAddress } from "./Components/BusinessAddress/BusinessAddress";
import { BusinessContacts } from "./Components/BusinessContact/BusinessContact";
import { BusinessInvolvement } from "./Components/BusinessInvolvement/BusinessInvolvement";


export const MasterBusinessTables: React.FC = () => {
    console.log("masterbusiness")
  return (
    <>
      <div className="ParticipantOverview">
        <div className="container">
          <div className="column">
          <Overview/>
<BusinessAddress/>
           
            <BusinessContacts/>
          </div>
          <div className="half-width">
            <Alerts />
            <Vehicles />
          </div>
        </div>
     <BusinessInvolvement/>
      </div>
    </>
  );
};
