import React, { useState, useEffect } from "react";
import { SetTableData } from "./PolicyPermissionTabIProps";
import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { useParams } from "react-router-dom";
import { AddEditFormIParams } from "../../AddEditForm/AddEditFormI";
import { PolicyPermissionTabFunction } from "./PolicyPermissionTabFunction";
import { Submit } from "../../../../../../CommonComponents/Fields/Submit/Submit";
import { Filter } from "./Components/Filter";
import useDelayedEffect from "../../../../../../../hooks/useDelayedEffect";

const PolicyPermissionTab = () => {
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { policyId } = useParams<AddEditFormIParams>();
  const [rolePolicyAddData, setrolePolicyAddData] = useState<SetTableData[]>(
    []
  );
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [PermissionFilter, setPermissionFilter] = useState<string>("");
  const policyPermissionTabFunction = new PolicyPermissionTabFunction();
  const PolicyPermissionTableData = () => {
    policyPermissionTabFunction.PolicyPermissionTableData(
      setLoading,
      setTableData,
      policyId,
      setFiltertableData
    );
  };

  const initialRender = () => {
    PolicyPermissionTableData();
  };

  const onSave = () => {
    if (policyId) {
      policyPermissionTabFunction.rolePolicyAdd(
        setLoading,
        policyId,
        rolePolicyAddData
      );
    }
  };

  const applyFilterOnUserpolicyTable = () => {
    policyPermissionTabFunction.applyFilterOnUserpolicyTable(
      PermissionFilter,
      FiltertableData,
      setTableData
    );
  };

  const onCancel = () => {
    initialRender();
  };

  useEffect(() => {
    initialRender();
  }, [policyId]);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [PermissionFilter]);

  return (
    <>
      <Loader loading={loading} />
      <div className="Audit-filter">
        <Filter
          Permission={PermissionFilter}
          setPermission={setPermissionFilter}
        />
      </div>
      <div className="table">
        <Table
          columns={coloum(
            tableData,
            setTableData,
            setrolePolicyAddData,
            rolePolicyAddData
          )}
          columnsValue={tableData}
          clientSideSorting={true}
          showPagination={false}
        />
        {
          <div className="edit-button">
            <Submit onCancel={onCancel} onSave={onSave} />
          </div>
        }
      </div>
    </>
  );
};

export default PolicyPermissionTab;
