import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Case" },
    { field: "Issued/Created Date" },
    { field: "Type" },
    { field: "Detail" },
  ];
};
