import React, { useEffect, useState } from "react";
import "./Overview.css";
import { BusinessOverviewFunction } from "./OverviewFunction";
import { useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

export interface OverviewTableData {
  BusinessName: string;
  InvolvementType: string;
  BusinessType: string;
  Address: string;
}

export const BusinessOverviewTab: React.FC = () => {
  const businessOverviewFunction = new BusinessOverviewFunction();
  const [Loading, setLoading] = useState<boolean>(false);
  const [fields, setfields] = useState<OverviewTableData>({
    Address: "",
    BusinessName: "",
    BusinessType: "",
    InvolvementType: "",
  });
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const initialRender = () => {
    if (incidentId && participantId) {
      businessOverviewFunction.getInvolvement(
        setLoading,
        incidentId,
        participantId,
        setfields
      );
    }
  };
  useEffect(() => {
    initialRender();
  }, []);

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Overview</strong>
          </p>
        </div>

        <div
          className="overview-content"
          style={{ height: "auto", lineHeight: "15px" }}
        >
          <div className="overview-row">
            <p>
              Business Name: <strong>{fields?.BusinessName}</strong>
            </p>
            <p>
              Involvement Type: <strong>{fields?.InvolvementType}</strong>
            </p>
            <p>
              Business Type: <strong>{fields?.BusinessType}</strong>
            </p>
            <p>
              Involvement Type: <strong>{fields?.InvolvementType}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
