import React from "react";
import { MultipleEmergencyContactComponents } from "./Components/MultipleExternalAddress/MultipleEmergencyContact";
import { ExternalContactIProps } from "./ExternalContactI";

export const ExternalContact: React.FC<ExternalContactIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  return (
    <div>
      <MultipleEmergencyContactComponents
        Mainfields={Mainfields}
        setMainfields={setMainfields}
        Participantionvalidation={Participantionvalidation}
      />
      <div className="Involvement_wall_"></div>
    </div>
  );
};
