import { BackButtonComponent } from "../../../CommonComponents/BackButton/BackButtonComponent";
import "./CreateAgencyManagement.css";
import { useNavigate } from "react-router-dom";
import { TabsIndex } from "../Components/Tabs/TabsIndex";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";

export const CreateAgencyManagement: React.FC = () => {
  const navigation = useNavigate();
  const NavigateToAddPage = () => {
    navigation("/agency");
  };

  return (
    <>
      <div className="CreateAgencyManagement">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Agency", path: "/agency" },
            { title: "Add", path: "" },
          ]}
        />
        <div className="lineHeight">
          <BackButtonComponent
            name="Back to Agency"
            onClick={NavigateToAddPage}
          />
        </div>

        <div>
          <p className="header">Create Agency</p>
          <TabsIndex viewComponent={false} />
        </div>
      </div>
    </>
  );
};
