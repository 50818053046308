import { Fetcher } from "../../../../../../../services/Fetcher";
import { successCreatedMessage } from "../../../../../../../utils/const";
import { formatDate } from "../../../../../../../utils/Helper/Helper";
import {
  getMatchesIRes,
  getVictimsIRes,
} from "../../AddNewName/Involvement/InvolvementMainI";
import {
  BusinessTab,
  businessValidationIState,
  ContactIRes,
  getAddressIRes,
  GetRaceDataIState,
  MultipleAddressIState,
  SetbusinessTab,
} from "../InvolvementMainI";
import { SetTableData } from "./Components/Involment/InvolvementI";
import { InvolvementBussinessIRes } from "./InvolvementMainI";

const fetcher = new Fetcher();

export class InvolvementMainFunction {
  async getMatches(
    FirstName: string,
    setTableTotalRecord: (data: number) => void,
    setTableData: (data: SetTableData[]) => void
  ) {
    const response = await fetcher.post(`/matches/business/search`, {
      BusinessName: FirstName ? FirstName : null,
    });
    setTableTotalRecord(response?.TotalRecords ? response?.TotalRecords : 0);
    const TableJson = this.createTableJson(response?.Data);
    setTableData(TableJson);
  }

  createTableJson(data: getMatchesIRes[]) {
    return data?.map((val: getMatchesIRes, i: number) => {
      return {
        "First Name": val.PersonGivenName ? val.PersonGivenName : "",
        "Last Name": val.PersonSurName ? val.PersonSurName : "",
        "Middle Name": val.PersonMiddleName ? val.PersonMiddleName : "",
        DOB: val.DOB ? formatDate(val.DOB) : "",
      };
    });
  }

  async InvolvementSave(
    setLoading: (data: boolean) => void,
    fields: BusinessTab,
    incidentId: string,
    RediretToEDitPage: (data: string) => void,
    ParticipantId: string | undefined,
    setbusinessValidation: (data: businessValidationIState) => void,
    businessValidation: businessValidationIState
  ) {
    if (!fields.Involvement.BusinessType) {
      setbusinessValidation({
        involvement: {
          ...businessValidation.involvement,
          BusinessType: true,
        },
      });

      return;
    }
    if (!fields.Involvement.Firstname) {
      setbusinessValidation({
        involvement: {
          ...businessValidation.involvement,
          BusinessName: true,
        },
      });
      return;
    }
    if (!fields.Involvement.InvolvementType) {
      setbusinessValidation({
        involvement: {
          ...businessValidation.involvement,
          InvolvementType: true,
        },
      });
      return;
    }
    setLoading(true);
    let body = {
      BusinessInfo: {
        BusinessId: fields.Involvement.BusinessId
          ? fields.Involvement.BusinessId
          : null,
        ProcOri: fields.Involvement.ProcORI
          ? fields.Involvement.ProcORI
          : false,
        ConfidentialInformantion: fields.Involvement.Private
          ? fields.Involvement.Private
          : false,
        BusinessName: fields.Involvement.Firstname
          ? fields.Involvement.Firstname
          : "",
        BusinessType: null,
      },
      BusinessParticipantDetail: {
        BusinessId: fields.Involvement.BusinessId
          ? fields.Involvement.BusinessId
          : null,
        ParticipantId: ParticipantId ? ParticipantId : null,
      },
      BusinessRole: {
        BusinessRoleId: fields.Involvement.BusinessRoleId
          ? fields.Involvement.BusinessRoleId
          : null,
        BusinessId: fields.Involvement.BusinessId
          ? fields.Involvement.BusinessId
          : null,
        RoleId: fields.Involvement.InvolvementType
          ? fields.Involvement.InvolvementType
          : null,
      },
      BusinessMaster: {
        BusinessMasterId: fields.Involvement.BusinessMasterId
          ? fields.Involvement.BusinessMasterId
          : null,
        BusinessName: fields.Involvement.Firstname
          ? fields.Involvement.Firstname
          : null,
        BusinessTypeId: fields.Involvement.BusinessType
          ? fields.Involvement.BusinessType
          : null,
      },
    };
    let response = await fetcher.post(
      `/incident/${incidentId}/businessparticipant`,
      body
    );
    let res: InvolvementBussinessIRes = response.Data;

    await this.AddressSave(
      setLoading,
      fields,
      res.ParticipantId,
      incidentId,
      setbusinessValidation,
      businessValidation
    );
    await this.ContactsSave(setLoading, fields, incidentId, res.ParticipantId);

    fields.Involvement.InvolvementType ==
      "bf4d076f-7c65-48f9-b6d6-29afa3d693bc" &&
      (await this.victiminformationSave(
        setLoading,
        fields,
        res.ParticipantId,
        null,
        incidentId,
        setbusinessValidation,
        businessValidation
      ));
    setLoading(false);
    RediretToEDitPage(res.ParticipantId);
  }
  victiminformation(
    fields: BusinessTab,
    setbusinessValidation: (data: businessValidationIState) => void,
    businessValidation: businessValidationIState
  ) {
    if (!fields.Involvement.Victiminformation.VictimType) {
      setbusinessValidation({
        ...businessValidation,
        involvement: {
          ...businessValidation.involvement,
          InvolvementType: true,
        },
      });
    }

    if (
      !fields.Involvement.Victiminformation
        .EnforcementOfficialActivityCategoryCode
    ) {
      setbusinessValidation({
        ...businessValidation,
        involvement: {
          ...businessValidation.involvement,
          EnforcementOfficialActivityCategoryCode: true,
        },
      });
    }

    if (
      !fields.Involvement.Victiminformation
        .EnforcementOfficialAssignmentCategoryCode
    ) {
      setbusinessValidation({
        ...businessValidation,
        involvement: {
          ...businessValidation.involvement,
          EnforcementOfficialAssignmentCategoryCode: true,
        },
      });
    }

    return (
      fields.Involvement.Victiminformation.VictimType ||
      fields.Involvement.Victiminformation
        .EnforcementOfficialActivityCategoryCode ||
      fields.Involvement.Victiminformation
        .EnforcementOfficialAssignmentCategoryCode
    );
  }

  async victiminformationSave(
    setLoading: (data: boolean) => void,
    fields: BusinessTab,
    ParticipantId: string,
    PersonId: null,
    incidentId: string,
    setbusinessValidation: (data: businessValidationIState) => void,
    businessValidation: businessValidationIState
  ) {
    setLoading(true);
    this.victiminformation(fields, setbusinessValidation, businessValidation) &&
      (await fetcher.post(
        `/incident/${incidentId}/participant/${ParticipantId}/victim-information`,
        {
          Victim: {
            VictimId: fields.Involvement.Victiminformation.VictimId
              ? fields.Involvement.Victiminformation.VictimId
              : null,
            AgencyId: fields.Involvement.Victiminformation.AgencyId
              ? fields.Involvement.Victiminformation.AgencyId
              : null,
            IncidentId: incidentId,
            PersonId: PersonId,
            OfficerOtherJurisdiction:
              fields.Involvement.Victiminformation.OfficerOtherJurisdiction,
            VictimTypeCodeId: fields.Involvement.Victiminformation.VictimType
              ? fields.Involvement.Victiminformation.VictimType
              : null,
            OfficerActivityCodeId: fields.Involvement.Victiminformation
              .EnforcementOfficialActivityCategoryCode
              ? fields.Involvement.Victiminformation
                  .EnforcementOfficialActivityCategoryCode
              : null,
            OfficerAssignmentTypeCodeId: fields.Involvement.Victiminformation
              .EnforcementOfficialAssignmentCategoryCode
              ? fields.Involvement.Victiminformation
                  .EnforcementOfficialAssignmentCategoryCode
              : null,
          },
          AggravatedAssaultHomicides:
            fields.Involvement.Victiminformation.AggravatedAssaulthomicideCode.map(
              (val) => {
                if (val.AggravatedAssaulthomicideCode) {
                  return {
                    AggravatedAssaultHomicideId: val.AggravatedAssaultHomicideId
                      ? val.AggravatedAssaultHomicideId
                      : null,
                    AgencyId: fields.Involvement.Victiminformation.AgencyId
                      ? fields.Involvement.Victiminformation.AgencyId
                      : null,
                    IncidentId: incidentId,
                    PersonId: PersonId,
                    AggravatedAssaultHomicideCodeId:
                      val.AggravatedAssaulthomicideCode
                        ? val.AggravatedAssaulthomicideCode
                        : null,
                  };
                }
              }
            ).filter((val) => val),

          AdditionalJustifiableHomicides:
            fields.Involvement.Victiminformation.AdditionalJustifiablehomicide.map(
              (val) => {
                if (val.AdditionalJustifiablehomicide) {
                  return {
                    AdditionalJustifiableHomicideId:
                      val.AdditionalJustifiableHomicideId
                        ? val.AdditionalJustifiableHomicideId
                        : null,
                    AgencyId: fields.Involvement.Victiminformation.AgencyId
                      ? fields.Involvement.Victiminformation.AgencyId
                      : null,
                    IncidentId: incidentId,
                    PersonId: PersonId,
                    AdditionalJustifiableHomicideCodeId:
                      val.AdditionalJustifiablehomicide
                        ? val.AdditionalJustifiablehomicide
                        : null,
                  };
                }
              }
            ).filter((val) => val),

          Injuries: fields.Involvement.Victiminformation.InjuryCategoryCode.map(
            (val) => {
              if (val.InjuryCategoryCode) {
                return {
                  InjuryId: val.InjuryId ? val.InjuryId : null,
                  AgencyId: fields.Involvement.Victiminformation.AgencyId
                    ? fields.Involvement.Victiminformation.AgencyId
                    : null,
                  IncidentId: incidentId,
                  PersonId: PersonId,
                  InjuryCodeId: val.InjuryCategoryCode,
                };
              }
            }
          ).filter((val) => val),
        }
      ));

    setLoading(false);
  }

  async ContactsSave(
    setLoading: (data: boolean) => void,
    fields: BusinessTab,
    incidentId: string,
    participantId: string
  ) {
    setLoading(true);

    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/contacts`,
      {
        Contacts: [
          ...fields.Involvement.Email.map((Emailval) => {
            if (Emailval.Email && Emailval.Type) {
              return {
                EntityId: participantId,
                ContactValue: Emailval.Email,
                ContactTypeCodeId: Emailval.Type ? Emailval.Type : null,
                ContactType: "Email",
              };
            }
          }).filter((val) => val),

          ...fields.Involvement.PhoneNumber.map((PhoneNumberval) => {
            if (PhoneNumberval.Number && PhoneNumberval.Type) {
              return {
                EntityId: participantId,
                ContactValue: PhoneNumberval.Number,
                ContactTypeCodeId: PhoneNumberval.Type
                  ? PhoneNumberval.Type
                  : null,
                CountryCodeId: PhoneNumberval.Country
                  ? PhoneNumberval.Country
                  : null,
                Ext: PhoneNumberval.Ext,
                ContactType: "Phone",
              };
            }
          }).filter((val) => val),
        ],
        EntityModel: {
          EntityType: "Business",
          EntityId: participantId,
        },
      }
    );

    let res: GetRaceDataIState[] = response.Data;
    setLoading(false);
  }

  isAddressData(val: MultipleAddressIState) {
    return (
      val.AddressLine1 ||
      val.AddressLine2 ||
      val.AddressType ||
      val.AgencyId ||
      val.Apartment ||
      val.City ||
      val.Country ||
      val.ForeignAddress ||
      val.ForeignAddressCity ||
      val.ForeignAddressCountry ||
      val.ForeignAddressStateProvinceRegion ||
      val.ForeignAddressZip ||
      val.GridNumber ||
      val.StateProvidence ||
      val.Street ||
      val.StreetNumber ||
      val.StreetPrefix ||
      val.StreetSuffix ||
      val.ZipCode ||
      val.LocationCategoryCode
    );
  }

  async AddressSave(
    setLoading: (data: boolean) => void,
    fields: BusinessTab,
    ParticipantId: string,
    incidentId: string,
    setbusinessValidation: (data: businessValidationIState) => void,
    businessValidation: businessValidationIState
  ) {
    setLoading(true);

    await fetcher.post(`/commonentity/address`, {
      Addresses: fields.Involvement.Addresses.AddressData.map((val) => {
        if (
          this.isAddressData(val) &&
          !val.ForeignAddress &&
          val.LocationCategoryCode
        ) {
          return {
            LocationId: val.LocationId ? val.LocationId : null,
            AgencyId: val.AgencyId ? val.AgencyId : null,
            IncidentId: incidentId,
            EntityTypeId: 3,
            EntityId: ParticipantId,
            AddressType: val.AddressType ? val.AddressType : null,
            LocationCategoryCode: val.LocationCategoryCode
              ? val.LocationCategoryCode
              : null,
            City: val.City ? val.City : null,
            HouseNumber: val.Apartment ? val.Apartment : null,
            Ncicstreet1PrefixId: val.StreetPrefix ? val.StreetPrefix : null,
            Street1Name: val.StreetNumber ? val.StreetNumber : null,
            Ncicstreet1SuffixId: val.StreetSuffix ? val.StreetSuffix : null,
            Street2Name: val.Street ? val.Street : null,
            NcicstateId: val.StateProvidence ? val.StateProvidence : null,
            ZipCode: val.ZipCode ? val.ZipCode : null,
            Grid: val.GridNumber ? val.GridNumber : null,
            NciccountryId: val.Country ? val.Country : null,

            Address1: val.AddressLine1 ? val.AddressLine1 : null,
            Address2: val.AddressLine2 ? val.AddressLine2 : null,
            OtherCity: val.ForeignAddressCity ? val.ForeignAddressCity : null,
            OtherState: val.ForeignAddressStateProvinceRegion
              ? val.ForeignAddressStateProvinceRegion
              : null,
            OtherZip: val.ForeignAddressZip ? val.ForeignAddressZip : null,
            OtherCountry: val.ForeignAddressCountry
              ? val.ForeignAddressCountry
              : null,
            IsForeignAddress: val.ForeignAddress ? val.ForeignAddress : false,
          };
        } else if (this.isAddressData(val) && val.ForeignAddress) {
          return {
            LocationId: val.LocationId ? val.LocationId : null,
            AgencyId: val.AgencyId ? val.AgencyId : null,
            IncidentId: incidentId,
            EntityTypeId: 3,
            EntityId: ParticipantId,
            AddressType: val.AddressType ? val.AddressType : null,
            LocationCategoryCode: val.LocationCategoryCode
              ? val.LocationCategoryCode
              : null,
            City: val.City ? val.City : null,
            HouseNumber: val.Apartment ? val.Apartment : null,
            Ncicstreet1PrefixId: val.StreetPrefix ? val.StreetPrefix : null,
            Street1Name: val.StreetNumber ? val.StreetNumber : null,
            Ncicstreet1SuffixId: val.StreetSuffix ? val.StreetSuffix : null,
            Street2Name: val.Street ? val.Street : null,
            NcicstateId: val.StateProvidence ? val.StateProvidence : null,
            ZipCode: val.ZipCode ? val.ZipCode : null,
            Grid: val.GridNumber ? val.GridNumber : null,
            NciccountryId: val.Country ? val.Country : null,

            Address1: val.AddressLine1 ? val.AddressLine1 : null,
            Address2: val.AddressLine2 ? val.AddressLine2 : null,
            OtherCity: val.ForeignAddressCity ? val.ForeignAddressCity : null,
            OtherState: val.ForeignAddressStateProvinceRegion
              ? val.ForeignAddressStateProvinceRegion
              : null,
            OtherZip: val.ForeignAddressZip ? val.ForeignAddressZip : null,
            OtherCountry: val.ForeignAddressCountry
              ? val.ForeignAddressCountry
              : null,
            IsForeignAddress: val.ForeignAddress ? val.ForeignAddress : false,
          };
        } else if (
          !this.isAddressData(val) &&
          !val.ForeignAddress &&
          !val.LocationCategoryCode
        ) {
          setbusinessValidation({
            ...businessValidation,
            involvement: {
              ...businessValidation.involvement,
              LocationCategory: true,
            },
          });
        }
      }).filter((val) => val),
      EntityModel: {
        EntityType: "Business",
        EntityId: ParticipantId,
        EntitySubType: null,
        EntitySubTypeId: null,
      },
    });

    setLoading(false);
  }

  async getBusinessType(
    setRaceData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/BusinessType");
    let res: GetRaceDataIState[] = response.Data;
    setRaceData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getRaceData(
    setRaceDataData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/PersonRoleIncidentToPersonAssociation"
    );
    let res: GetRaceDataIState[] = response.Data;
    setRaceDataData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getAddresType(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/AddressType");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getStreetPrefix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/StreetPreDirection"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getStreetSuffix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/StreetPostDirection"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getState(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/StateCode");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getLocationType(setLocationData: (data: GetRaceDataIState[]) => void) {
    const response = await fetcher.get("/code/ncic/category/LocationType");
    let res: GetRaceDataIState[] = response.Data;
    setLocationData(res);
  }

  async getCountry(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Country");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getSex(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Sex");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getRace(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Race");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getEyeColor(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/EyeColor");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getInvolvement(
    incidentId: string,
    participantId: string,
    setMainfields: (data: BusinessTab) => void,
    Mainfields: BusinessTab
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/businessparticipant/${participantId}/business`
    );

    let resInvolvement: SetbusinessTab = response.Data;
    let resAddress = await this.getAddress(participantId);
    let resContacts = await this.getContacts(incidentId, participantId);
    let resVictims = null;
    if (
      resInvolvement.BusinessRole.RoleId ==
      "bf4d076f-7c65-48f9-b6d6-29afa3d693bc"
    ) {
      resVictims = await this.getVictims(incidentId, participantId);
    }

    setMainfields({
      ...Mainfields,
      Involvement: {
        ...Mainfields.Involvement,
        BusinessId: resInvolvement.BusinessParticipantDetail.BusinessId,
        ParticipantId: resInvolvement.BusinessParticipantDetail.ParticipantId,
        BusinessMasterId: resInvolvement.BusinessMaster.BusinessMasterId,
        BusinessRoleId: resInvolvement?.BusinessRole?.BusinessRoleId,
        InvolvementType: resInvolvement.BusinessRole.RoleId,
        Firstname: resInvolvement.BusinessMaster.BusinessName,
        BusinessType: resInvolvement.BusinessMaster.BusinessTypeId,
        ProcORI: resInvolvement.BusinessInfo.ProcOri,
        Private: resInvolvement.BusinessInfo.ConfidentialInformantion,
        Addresses: {
          AddressData: resAddress.length
            ? resAddress.map((val, i) => {
                return {
                  AddressLine1: val.Address1,
                  AddressLine2: val.Address2,
                  AddressType: val.AddressType,
                  Apartment: val.HouseNumber,
                  City: val.City,
                  Country: val.NciccountryId,
                  ForeignAddress: val.IsForeignAddress,
                  ForeignAddressCity: val.OtherCity,
                  ForeignAddressCountry: val.OtherCountry,
                  ForeignAddressStateProvinceRegion: val.OtherState,
                  ForeignAddressZip: val.OtherZip,
                  GridNumber: val.Grid,
                  id: i + 1,
                  StateProvidence: val.NcicstateId,
                  Street: val.Street2Name,
                  StreetNumber: val.Street1Name,
                  StreetPrefix: val.Ncicstreet1PrefixId,
                  StreetSuffix: val.Ncicstreet1SuffixId,
                  ZipCode: val.ZipCode,
                  LocationCategoryCode: val.LocationCategoryCode,
                  LocationId: val.LocationId,
                  AgencyId: val.AgencyId,
                  NoPermanentAddress: false,
                  UnknownAddress: false,
                };
              })
            : [
                {
                  AddressType: "",
                  Apartment: "",
                  City: "",
                  Country: "",
                  GridNumber: "",
                  id: 1,
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                  AddressLine1: "",
                  AddressLine2: "",
                  ForeignAddressCity: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  ForeignAddressCountry: "",
                  ForeignAddress: false,
                  NoPermanentAddress: false,
                  UnknownAddress: false,
                },
              ],
        },

        Email: resContacts.filter((Contactsval) => {
          if (Contactsval.ContactType == "Email") {
            return Contactsval;
          }
        }).length
          ? resContacts
              .filter((Contactsval) => {
                if (Contactsval.ContactType == "Email") {
                  return Contactsval;
                }
              })
              .map((ContactEmail, ContactEmaili) => {
                return {
                  ExternalContactID: "",
                  Type: ContactEmail.ContactTypeCodeId,
                  Email: ContactEmail.ContactValue,
                  id: ContactEmaili + 1,
                  TypeDropdownvalue: "",
                };
              })
          : [
              {
                Email: "",
                ExternalContactID: "",
                id: 1,
                Type: "",
                TypeDropdownvalue: "",
              },
            ],

        PhoneNumber: resContacts.filter((Contactsval) => {
          if (Contactsval.ContactType == "Phone") {
            return Contactsval;
          }
        }).length
          ? resContacts
              .filter((Contactsval) => {
                if (Contactsval.ContactType == "Phone") {
                  return Contactsval;
                }
              })
              .map((ContactEmail, ContactEmaili) => {
                return {
                  ExternalContactID: "",
                  Type: ContactEmail.ContactTypeCodeId,
                  TypeDropdownvalue: "",
                  id: ContactEmaili + 1,
                  Country: ContactEmail.CountryCodeId
                    ? ContactEmail.CountryCodeId
                    : "",
                  Number: ContactEmail.ContactValue
                    ? ContactEmail.ContactValue
                    : "",
                  Ext: ContactEmail.Ext ? ContactEmail.Ext : "",
                };
              })
          : [
              {
                Country: "",
                Ext: "",
                TypeDropdownvalue: "",
                ExternalContactID: "",
                id: 1,
                Number: "",
                Type: "",
              },
            ],

        Victiminformation: resVictims
          ? {
              ...Mainfields.Involvement.Victiminformation,
              AgencyId: resVictims?.Victims?.AgencyId,
              VictimId: resVictims?.Victims?.VictimId,
              VictimType: resVictims?.Victims?.VictimTypeCodeId,
              EnforcementOfficialActivityCategoryCode:
                resVictims?.Victims?.OfficerActivityCodeId,
              EnforcementOfficialAssignmentCategoryCode:
                resVictims?.Victims?.OfficerAssignmentTypeCodeId,
              OfficerOtherJurisdiction:
                resVictims?.Victims?.OfficerOtherJurisdiction,
              AdditionalJustifiablehomicide: resVictims
                ?.AdditionalJustifiableHomicides?.length
                ? resVictims.AdditionalJustifiableHomicides.map(
                    (
                      valAdditionalJustifiableHomicidesval,
                      valAdditionalJustifiableHomicidesi
                    ) => {
                      return {
                        AdditionalJustifiablehomicide:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideCodeId,
                        id: valAdditionalJustifiableHomicidesi + 1,
                        AgencyId: valAdditionalJustifiableHomicidesval.AgencyId,
                        AggravatedAssaultHomicideId:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideId,
                        IncidentId:
                          valAdditionalJustifiableHomicidesval.IncidentId,
                        PersonId: valAdditionalJustifiableHomicidesval.PersonId,
                        AdditionalJustifiableHomicideId:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideId,
                      };
                    }
                  )
                : [
                    {
                      AdditionalJustifiablehomicide: "",
                      AgencyId: "",
                      AggravatedAssaultHomicideId: "",
                      id: 1,
                      IncidentId: "",
                      AdditionalJustifiableHomicideId: "",
                      PersonId: "",
                    },
                  ],
              AggravatedAssaulthomicideCode: resVictims
                ?.AggravatedAssaultHomicides?.length
                ? resVictims.AggravatedAssaultHomicides.map(
                    (
                      AggravatedAssaultHomicidesval,
                      AggravatedAssaultHomicidesi
                    ) => {
                      return {
                        AggravatedAssaulthomicideCode:
                          AggravatedAssaultHomicidesval.AggravatedAssaultHomicideCodeId,
                        id: AggravatedAssaultHomicidesi + 1,
                        AgencyId: AggravatedAssaultHomicidesval.AgencyId,
                        AggravatedAssaultHomicideId:
                          AggravatedAssaultHomicidesval.AggravatedAssaultHomicideId,
                        IncidentId: AggravatedAssaultHomicidesval.IncidentId,
                        PersonId: AggravatedAssaultHomicidesval.PersonId,
                      };
                    }
                  )
                : [
                    {
                      AgencyId: "",
                      AggravatedAssaulthomicideCode: "",
                      AggravatedAssaultHomicideId: "",
                      id: 1,
                      IncidentId: "",
                      PersonId: "",
                    },
                  ],
              InjuryCategoryCode: resVictims?.Injuries?.length
                ? resVictims.Injuries.map((Injuriesval, Injuriesi) => {
                    return {
                      id: Injuriesi + 1,
                      InjuryCategoryCode: Injuriesval.InjuryCodeId,
                      AgencyId: Injuriesval.AgencyId,
                      IncidentId: Injuriesval.IncidentId,
                      InjuryId: Injuriesval.InjuryId,
                      PersonId: Injuriesval.PersonId,
                    };
                  })
                : [
                    {
                      AgencyId: "",
                      id: 1,
                      IncidentId: "",
                      InjuryCategoryCode: "",
                      InjuryId: "",
                      PersonId: "",
                    },
                  ],
            }
          : {
              AgencyId: "",
              VictimId: "",
              AggravatedAssaulthomicideCode: [
                {
                  AggravatedAssaulthomicideCode: "",
                  id: 1,
                  AgencyId: "",
                  AggravatedAssaultHomicideId: "",
                  IncidentId: "",
                  PersonId: "",
                },
              ],
              EnforcementOfficialActivityCategoryCode: "",
              EnforcementOfficialAssignmentCategoryCode: "",
              InjuryCategoryCode: [
                {
                  InjuryCategoryCode: "",
                  id: 1,
                  AgencyId: "",
                  IncidentId: "",
                  InjuryId: "",
                  PersonId: "",
                },
              ],
              AdditionalJustifiablehomicide: [
                {
                  AdditionalJustifiablehomicide: "",
                  id: 1,
                  AggravatedAssaultHomicideId: "",
                  AgencyId: "",
                  IncidentId: "",
                  PersonId: "",
                  AdditionalJustifiableHomicideId: "",
                },
              ],
              OfficerOtherJurisdiction: false,
              USRCode: "",
              VictimType: "",
            },
      },
    });
  }

  async getVictims(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/victim-information`
    );
    let res: getVictimsIRes = response.Data;
    return res;
  }

  async getContacts(incidentId: string, participantId: string) {
    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/getcontacts`,
      {
        EntityType: "Business",
        EntityId: participantId,
      }
    );
    let resContacts: ContactIRes[] = response.Data;
    return resContacts;
  }

  async getAddress(participantId: string) {
    let response = await fetcher.post(
      `/commonentity/getaddresses`,

      {
        EntityType: "Business",
        EntityId: participantId,
        EntitySubType: null,
        EntitySubTypeId: null,
      }
    );
    let res: getAddressIRes[] = response.Data;
    return res;
  }
}
