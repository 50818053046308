import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Short Code" },
    { field: "Long Code" },
    { field: "Category" },
    { field: "Description" },
    {
      field: "Date Active",
      valueFormatter: (params) =>
        format(new Date(params.value), "MM-dd-yyyy"),
    },
    {
      field: "Date Inactive",
      valueFormatter: (params) =>
        format(new Date(params.value), "MM-dd-yyyy"),
    },
    { field: "Status" },
  ];
};
