import { useState } from "react";
import { TabsComponents } from "../../CommonComponents/TabsComponents/TabsComponents";
import "./Configuration.css";
import { Menu } from "./Components/Menu/Menu";

export const Configuration: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ margin: "0 auto", width: "96%" }}>
          <p className="header">Config</p>
          <TabsComponents
            activeTab={activeTab}
            tabList={[
              { name: "Configuration", id: 1 },
              { name: "CAD", id: 1 },
            ]}
            handleTabChange={setActiveTab}
          />

          {activeTab === 0 ? <Menu /> : ""}
        </div>
      </div>
    </>
  );
};
