import { Fetcher } from "../../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";
import { StatutesIRes, StatutesTableData } from "../ParticipantOverviewIState";

const fetcher = new Fetcher();

export class StatutesFunction {
  async getStatutes(
    participantId: string,
    incidentId: string,
    setTableData: (data: StatutesTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/statutes`
    );
    let res: StatutesIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: StatutesIRes[]) {
    return data?.map((val: StatutesIRes, i: number) => {
      return {
        Type: val.Type,
        Date: formatDate(val.Date),
      };
    });
  }
}
