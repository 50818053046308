import { InvolvementMainFunction } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainFunction";
import { ScarsMarksTattoosIRes } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { ScarsTatoosIState } from "../ParticipantOverviewIState";

const involvementMainFunction = new InvolvementMainFunction();

export class ScarsTatoosFunction {
  async getScarsTatoos(
    participantId: string,
    incidentId: string,
    setTableData: (data: ScarsTatoosIState[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let res: ScarsMarksTattoosIRes[] =
      await involvementMainFunction.getScarsMarksTattoos(
        incidentId,
        participantId
      );

    console.log(res);

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(res.length);
    setTableData(TableJson);
  }

  createTableJson(data: ScarsMarksTattoosIRes[]) {
    return data?.map((val: ScarsMarksTattoosIRes, i: number) => {
      return {
        "SMT/Other": val.PhysicalFeatureCode,
        "Type/Location": val.PhysicalFeatureLocation,
      };
    });
  }
}
