import { Fetcher } from "../../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";
import { OverviewTableData } from "./BusinessOverviewTab";
const fetcher = new Fetcher();

interface getsummaryoverviewIRes {
  BusinessMasterID: string;
  BusinessName: string;
  InvolvementType: string;
  BusinessType: string;
  Address: null | string;
}

export class BusinessOverviewFunction {
  async getInvolvement(
    setLoading: (data: boolean) => void,
    incidentId: string,
    participantId: string,
    setfields: (data: OverviewTableData) => void
  ) {
    setLoading(true);

    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/summary-overview-business`
    );

    let resInvolvement: getsummaryoverviewIRes = response.Data;

    setfields({
      BusinessName: resInvolvement.BusinessName,
      InvolvementType: resInvolvement.InvolvementType,
      BusinessType: resInvolvement.BusinessType,
      Address: resInvolvement.Address ? resInvolvement.Address : "",
    });

    setLoading(false);
  }
}
