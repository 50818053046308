import { useEffect, useState } from "react";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { CheckBoxComponent } from "../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { TextAreaComponent } from "../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { addressesList } from "../LocationOverviewIState";
import { LocationMainFunction } from "../LocationMainFunction";
import { useParams, useNavigate } from "react-router-dom";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { GetRaceDataIState } from "../../Participant/AddEditParticipantName/AddBusinessName/InvolvementMainI";
import { DetailedSearchFunction } from "../../../MasterNameIndex/MasterNameIndexListing/Components/DetailedSearch/DetailedSearchFunction";
import { InvolvementMainFunction } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainFunction";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { AddRecoveryFunction } from "../../Vehicle/AddEditVehicles/Recovery/AddRecoveryFunction";
import { locationCategory, LocationIParams } from "./AddLocationFormI";
import { AddLocationFormFunction } from "./AddLocationFormFunction";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { openNotificationWithIcon } from "../../../../CommonComponents/Toster/Toster";

const locationMain = new LocationMainFunction();

const AddLocationForm = () => {
  const addrecovery = new AddRecoveryFunction();
  const detailedSearchFunction = new DetailedSearchFunction();
  const addLocationFormFunction = new AddLocationFormFunction();
  const involvementMainFunction = new InvolvementMainFunction();
  const navigate = useNavigate();
  const [locationType, setLocationType] = useState<string>("Residential");
  const [loading, setLoading] = useState<boolean>(false);
  const [locationCategoryList, setLocationCategoryList] = useState<
    locationCategory[]
  >([]);
  const { incidentId, locationid } = useParams<LocationIParams>();
  const [addLocationAddress, setAddLocationAddress] = useState<addressesList>({
    AddressType: null,
    IncidentId: incidentId ? incidentId : null,
    LocationId: locationid ? locationid : null,
    IsPrimary: false,
    LocationCategoryCode: null,
    LocationName: "",
    Description: "",
    Intersection: false,
    HouseNumber: "",
    Street1Name: "",
    UnitType: null,
    UnitNumber: "",
    Street2Name: "",
    City: "",
    ZipCode: "",
    Grid: "",
    IsGeoValidated: false,
    Latitude: null,
    Longitude: null,
    GeoCode: "",
    ParcelId: null,
    NciccountryId: null,
    OtherCity: "",
    OtherState: "",
    OtherZip: "",
    OtherCountry: "",
    IsForeignAddress: false,
    NcicstateId: null,
    CountryName: "",
    Ncicstreet1PrefixId: null,
    Ncicstreet1SuffixId: null,
    Ncicstreet2PrefixId: null,
    Ncicstreet2SuffixId: null,
    Distance: "",
    Direction: "",
    Block: "",
    MeasurementId: null,
    NciccountycodeId: null,
  });
  let incidentNumber = localStorage.getItem("Incidentnumber");

  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [measurement, setMeasurement] = useState<GetRaceDataIState[]>([]);
  const [streetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [streetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [stateCode, setStateCode] = useState<GetRaceDataIState[]>([]);
  const [country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [unitType, setUnitType] = useState<GetRaceDataIState[]>([]);
  const [countryCode, setCountryCode] = useState<GetRaceDataIState[]>([]);
  const [dropdownLoader, setDropdownLoader] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<addressesList>({
    AddressType: null,
    IncidentId: incidentId ? incidentId : null,
    LocationId: locationid ? locationid : null,
    IsPrimary: false,
    LocationCategoryCode: null,
    LocationName: "",
    Description: "",
    Intersection: false,
    HouseNumber: "",
    Street1Name: "",
    UnitType: null,
    UnitNumber: "",
    Street2Name: "",
    City: "",
    ZipCode: "",
    Grid: "",
    IsGeoValidated: false,
    Latitude: null,
    Longitude: null,
    GeoCode: "",
    ParcelId: null,
    NciccountryId: null,
    OtherCity: "",
    OtherState: "",
    OtherZip: "",
    OtherCountry: "",
    IsForeignAddress: false,
    NcicstateId: null,
    CountryName: "",
    Ncicstreet1PrefixId: null,
    Ncicstreet1SuffixId: null,
    Ncicstreet2PrefixId: null,
    Ncicstreet2SuffixId: null,
    Distance: "",
    Direction: "",
    Block: "",
    MeasurementId: null,
    NciccountycodeId: null,
  });

  const getLocationPrefilledHandler = async () => {
    const prefilledData = await locationMain?.getAddressById(
      locationid,
      setLoading
    );
    setAddLocationAddress(prefilledData?.Data);
    setInitialData(prefilledData?.Data);
  };

  const initialRender = () => {
    involvementMainFunction?.getCountryCode(setCountryCode, setDropdownLoader);
    involvementMainFunction?.getMeasurement(setMeasurement, setDropdownLoader);
    addrecovery?.getUnitType(setUnitType, setDropdownLoader);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setStateCode);
    detailedSearchFunction.getStreetPrefix(setStreetPrefix);
    detailedSearchFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getAddresType(setRaceDataData);
    addLocationFormFunction.getLocationDropdown(setLocationCategoryList);
    if (locationid) {
      getLocationPrefilledHandler();
    }
  };
  useEffect(() => {
    initialRender();
  }, []);

  useEffect(() => {
    const filterdata = RaceDataData?.filter(
      (item) => item?.NciccodeId === addLocationAddress?.AddressType
    );
    setLocationType(filterdata?.[0]?.LongCode);
  }, [addLocationAddress, RaceDataData]);

  const locationCategoryHandler = (e: string) => {
    setAddLocationAddress((prevState) => ({
      ...prevState,
      LocationCategoryCode: e,
    }));
  };

  const onHandleValueChange = (
    value: string | boolean | number,
    key: keyof addressesList
  ) => {
    setIsDisabled(false);
    setAddLocationAddress((prevState) => ({
      ...prevState,
      [key]: value !== "" ? value : null,
    }));
  };

  const addLocationAddressHandler = async () => {
    if (
      (addLocationAddress?.Longitude && addLocationAddress?.Longitude < -180) ||
      (addLocationAddress?.Longitude && addLocationAddress?.Longitude > 180)
    ) {
      openNotificationWithIcon("error", "Invalid Longitude");
      return;
    }

    if (
      (addLocationAddress?.Latitude && addLocationAddress?.Latitude < -90) ||
      (addLocationAddress?.Latitude && addLocationAddress?.Latitude > 90)
    ) {
      openNotificationWithIcon("error", "Invalid Latitude");
      return;
    }

    if (
      addLocationAddress?.ZipCode &&
      addLocationAddress?.ZipCode?.length < 2
    ) {
      openNotificationWithIcon("error", "Invalid Zip Code");
      return;
    }
    setIsDisabled(true);
    if (incidentId) {
      if (locationid) {
        await locationMain?.updateAddressById(
          setLoading,
          {
            addLocationAddress,
          },
          incidentId
        );
        backButtonHandler();
      } else {
        const response = await locationMain?.locationAddressSave(
          { addLocationAddress },
          incidentId,
          goTOEDitMOde
        );
        if (response?.Success) {
          backButtonHandler();
        }
      }
    }
    setIsDisabled(false);
  };

  const backButtonHandler = () => {
    navigate(`/incidents/${incidentId}/locations`);
  };

  const goTOEDitMOde = (Locationid: string) => {
    navigate(`/incidents/${incidentId}/locations/${Locationid}/edit`);
  };


   const lastBreadcrumbTitle = locationid ? 'Edit' : 'Add';
  return (
    <>
      <Loader loading={loading} />
      <div className="add_location_form_container">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Incidents", path: `/incidents` },
            { title: incidentNumber ? incidentNumber : "", path: "" },
            { title: "Locations", path: `/incidents/${incidentId}/locations` },
            { title: lastBreadcrumbTitle, path: "" },
          ]}
        />
        <BackButtonComponent
          name="Back to locations"
          onClick={backButtonHandler}
        />
        <div className="add_location_title_container">
          <h3 className="_case_id_title">
            {locationid !== undefined ? "Edit" : "Add"} Location
          </h3>
        </div>

        <div className="add_location_form">
          <div className="upper_search_location">
            <div className="location_type_cont">
              <div className="address_type ">
                <div className="form_select_form">
                  <DropdownComponent
                    dataSource={locationCategoryList?.map((val) => {
                      return {
                        id: val?.NciccodeId,
                        value: val?.LongCode,
                      };
                    })}
                    value={addLocationAddress?.LocationCategoryCode || ""}
                    handleRowClick={(e) => {
                      onHandleValueChange(e, "LocationCategoryCode");
                      locationCategoryHandler(e);
                    }}
                    fieldName="Location Category"
                  />
                </div>
              </div>
            </div>

            <div className="location_type_cont">
              <div className="address_type ">
                <div className="form_select_form">
                  <DropdownComponent
                    dataSource={RaceDataData.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    handleRowClick={(e, val) => {
                      onHandleValueChange(e, "AddressType");
                      setLocationType(`${val}`);
                    }}
                    value={addLocationAddress?.AddressType || ""}
                    fieldName="Address Type"
                  />
                </div>
              </div>
            </div>

            {locationType !== "Intersection" && (
              <div>
                <div className="address_type ">
                  <div className="form_select_form">
                    <TextBoxComponent
                      value={addLocationAddress?.LocationName}
                      fieldName="Location name"
                      onChangeValue={(e) => {
                        if (e.length < 100) {
                          onHandleValueChange(e, "LocationName");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="isPrimary_container">
              <CheckBoxComponent
                value={addLocationAddress?.IsPrimary}
                fieldName="Primary"
                onChangeValue={() => {
                  onHandleValueChange(
                    !addLocationAddress?.IsPrimary,
                    "IsPrimary"
                  );
                }}
              />
            </div>
          </div>

          <div className="add_location_description">
            <TextAreaComponent
              fieldName="Description"
              value={addLocationAddress?.Description}
              onChangeValue={(e) => {
                onHandleValueChange(e, "Description");
              }}
            />
          </div>

          {locationType === "Block" && (
            <div className="_add_location_address">
              <div className="_add_location_address">
                <p className="location_title">Address</p>

                <div className="_block_address_container">
                  <div className="_cross_street_measurement">
                    <TextBoxComponent
                      fieldName="Block"
                      value={addLocationAddress?.Block}
                      onChangeValue={(e) => {
                        onHandleValueChange(e, "Block");
                      }}
                    />
                  </div>

                  <div className="_of_block">of</div>
                  <div className="address_street_Number">
                    <DropdownComponent
                      fieldName="Street 1 prefix"
                      value={addLocationAddress?.Ncicstreet1PrefixId || ""}
                      dataSource={streetPrefix?.map((val) => {
                        return {
                          id: val?.NciccodeId,
                          value: val?.LongCode,
                        };
                      })}
                      handleRowClick={(e) => {
                    
                        onHandleValueChange(e, "Ncicstreet1PrefixId");
                      }}
                    />
                  </div>
                  <div>
                    <div className="street_one_text">
                      <TextBoxComponent
                        fieldName={
                          locationType === "Block"
                            ? "Street"
                            : "Cross Street Name"
                        }
                        value={addLocationAddress?.Street2Name}
                        onChangeValue={(e) => {
                          onHandleValueChange(e, "Street2Name");
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="address_contrains">
                      <DropdownComponent
                        fieldName="Street Suffix"
                        value={addLocationAddress?.Ncicstreet2SuffixId || ""}
                        dataSource={streetSuffix?.map((val) => {
                          return {
                            id: val?.NciccodeId,
                            value: val?.LongCode,
                          };
                        })}
                        handleRowClick={(e) => {
                          onHandleValueChange(e, "Ncicstreet2SuffixId");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {locationType !== "Intersection" &&
            locationType !== "In between Streets" &&
            locationType !== "Block" && (
              <div className="_add_location_address">
                <p className="location_title">Address</p>

                <div className="_add_location_add_address_form_container">
                  <div className="address_street_Number">
                    <TextBoxComponent
                      disabled={false}
                      fieldName="Street Number"
                      value={addLocationAddress?.HouseNumber}
                      onChangeValue={(e) => {
                        onHandleValueChange(e, "HouseNumber");
                      }}
                    />
                  </div>
                  <div className="address_street_Number">
                    <DropdownComponent
                      fieldName="Street 1 prefix"
                      value={addLocationAddress?.Ncicstreet1PrefixId || ""}
                      dataSource={streetPrefix?.map((val) => {
                        return {
                          id: val?.NciccodeId,
                          value: val?.LongCode,
                        };
                      })}
                      handleRowClick={(e) => {
                        onHandleValueChange(e, "Ncicstreet1PrefixId");
                      }}
                    />
                  </div>

                  <div className="full_street_prefix">
                    <TextBoxComponent
                      disabled={false}
                      value={addLocationAddress?.Street1Name}
                      fieldName="Street"
                      onChangeValue={(e) => {
                        onHandleValueChange(e, "Street1Name");
                      }}
                    />
                  </div>

                  <div className="street_one_suffix">
                    <DropdownComponent
                      dataSource={streetSuffix?.map((val) => {
                        return {
                          id: val?.NciccodeId,
                          value: val?.LongCode,
                        };
                      })}
                      value={addLocationAddress?.Ncicstreet1SuffixId || ""}
                      fieldName="Street 1 Suffix"
                      handleRowClick={(e) => {
                        onHandleValueChange(e, "Ncicstreet1SuffixId");
                      }}
                    />
                  </div>

                  <div className="address_unit_type">
                    <DropdownComponent
                      dataSource={unitType?.map((val) => {
                        return {
                          id: val.NciccodeId,
                          value: val.LongCode,
                        };
                      })}
                      value={addLocationAddress?.UnitType || ""}
                      fieldName="Unit type"
                      handleRowClick={(e) => {
                        onHandleValueChange(e, "UnitType");
                      }}
                    />
                  </div>

                  <div className="address_unit_number">
                    <TextBoxComponent
                      value={addLocationAddress?.UnitNumber}
                      fieldName="Unit Number"
                      onChangeValue={(e) => {
                        if (e.length < 10) {
                          onHandleValueChange(e, "UnitNumber");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

          <div className="full_address_container_fluid">
            {(locationType === "Intersection" ||
              locationType === "In between Streets") && (
              <>
                <p className="location_title">Street 1</p>
                <div className="_street_one_container">
                  <div className="_add_location_street_prefix_container">
                    <DropdownComponent
                      value={addLocationAddress?.Ncicstreet1PrefixId || ""}
                      fieldName="Street Prefix"
                      dataSource={streetPrefix?.map((val) => {
                        return {
                          id: val?.NciccodeId,
                          value: val?.LongCode,
                        };
                      })}
                      handleRowClick={(e) => {
                        onHandleValueChange(e, "Ncicstreet1PrefixId");
                      }}
                    />
                  </div>
                  <div className="_add_location_street_name_container">
                    <TextBoxComponent
                      fieldName="Street Name"
                      value={addLocationAddress?.Street1Name}
                      onChangeValue={(e) => {
                        if (e.length < 50) {
                          onHandleValueChange(e, "Street1Name");
                        }
                      }}
                    />
                  </div>
                  <div className="_add_location_street_prefix_container">
                    <DropdownComponent
                      dataSource={streetSuffix?.map((val) => {
                        return {
                          id: val?.NciccodeId,
                          value: val?.LongCode,
                        };
                      })}
                      value={addLocationAddress?.Ncicstreet1SuffixId || ""}
                      fieldName="Street Suffix"
                      handleRowClick={(e) => {
                        onHandleValueChange(e, "Ncicstreet1SuffixId");
                      }}
                    />
                  </div>
                </div>

                {<p className="location_title">Cross Street</p>}

                <div className="_street_one_container">
                  {locationType === "In between Streets" && (
                    <>
                      <div className="_cross_street_distance">
                        <TextBoxComponent
                          fieldName="Distance"
                          value={addLocationAddress?.Distance}
                          onChangeValue={(e) => {
                            onHandleValueChange(e, "Distance");
                          }}
                        />
                      </div>

                      <div className="_cross_street_measurement">
                        <DropdownComponent
                          dataSource={measurement?.map((val) => {
                            return {
                              id: val?.SystemLevelCodeId,
                              value: val?.LongCode,
                            };
                          })}
                          value={addLocationAddress?.MeasurementId || ""}
                          fieldName="Measurement"
                          handleRowClick={(e) => {
                            onHandleValueChange(e, "MeasurementId");
                          }}
                        />
                      </div>
                      <div className="_cross_street_direction">
                        <TextBoxComponent
                          fieldName="Direction"
                          value={addLocationAddress?.Direction}
                          onChangeValue={(e) => {
                            onHandleValueChange(e, "Direction");
                          }}
                        />
                        <div className="_of instreet_of_">of</div>
                      </div>
                    </>
                  )}
                  <div className="_add_location_street_prefix_container">
                    <DropdownComponent
                      fieldName="Street Prefix"
                      dataSource={streetPrefix?.map((val) => {
                        return {
                          id: val?.NciccodeId,
                          value: val?.LongCode,
                        };
                      })}
                      value={addLocationAddress?.Ncicstreet2PrefixId || ""}
                      handleRowClick={(e) => {
                        onHandleValueChange(e, "Ncicstreet2PrefixId");
                      }}
                    />
                  </div>
                  <div>
                    <div className="street_one_text">
                      <TextBoxComponent
                        fieldName="Cross Street Name"
                        value={addLocationAddress?.Street2Name}
                        onChangeValue={(e) => {
                          onHandleValueChange(e, "Street2Name");
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="address_contrains">
                      <DropdownComponent
                        fieldName="Street Suffix"
                        value={addLocationAddress?.Ncicstreet2SuffixId || ""}
                        dataSource={streetSuffix?.map((val) => {
                          return {
                            id: val?.NciccodeId,
                            value: val?.LongCode,
                          };
                        })}
                        handleRowClick={(e) => {
                          onHandleValueChange(e, "Ncicstreet2SuffixId");
                        }}
                      />
                    </div>

                    {/* <div className="_add_street_btn_container">
                                        <ButtonComponents
                                            textColor='#3672b3'
                                            name='Add Street'
                                            borderColor='#3672b3'
                                            icon={Add}
                                        />
                                    </div> */}
                  </div>
                </div>
              </>
            )}

            <div className="_add_location_full_Address_container">
              <div className="_city _location_city">
                <TextBoxComponent
                  value={addLocationAddress?.City}
                  fieldName="City"
                  onChangeValue={(e) => {
                    if (e.length < 25) {
                      onHandleValueChange(e, "City");
                    }
                  }}
                />
              </div>
              <div className="_city">
                <div className="" style={{ width: "102px" }}>
                  <DropdownComponent
                    dataSource={stateCode?.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    value={addLocationAddress?.NcicstateId || ""}
                    fieldName="State"
                    handleRowClick={(e) => {
                      onHandleValueChange(e, "NcicstateId");
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div
                  style={{
                    margin: "0px 10px",
                    width: "102px",
                  }}
                >
                  <TextBoxComponent
                    value={addLocationAddress?.ZipCode}
                    fieldName="Zip Code"
                    onChangeValue={(e) => {
                      if (e.length < 10) {
                        onHandleValueChange(e, "ZipCode");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="_city">
                <div className="city_text">
                  <TextBoxComponent
                    value={addLocationAddress?.Grid}
                    fieldName="Grid"
                    onChangeValue={(e) => {
                      if (e.length < 6) {
                        onHandleValueChange(e, "Grid");
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="country_code_list_conatiner">
              <div className="_countryCode_container">
                <div className="add_location_country_dropdown">
                  <DropdownComponent
                    dataSource={countryCode?.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    value={addLocationAddress?.NciccountycodeId || ""}
                    fieldName="County"
                    handleRowClick={(e) => {
                      onHandleValueChange(e, "NciccountycodeId");
                    }}
                  />
                </div>
              </div>

              <div className="_countryCode_container">
                <div className="add_location_country_dropdown">
                  <DropdownComponent
                    dataSource={country?.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    value={addLocationAddress?.NciccountryId || ""}
                    fieldName="Country"
                    handleRowClick={(e) => {
                      onHandleValueChange(e, "NciccountryId");
                    }}
                  />
                </div>
              </div>

              {/* <div className="_countryCode_container">
                                <div className="add_location_country_dropdown">
                                    <DropdownComponent
                                        dataSource={countryCode?.map((val) => {
                                            return {
                                                id: val.NciccodeId,
                                                value: val.LongCode,
                                            };
                                        })}
                                        value={addLocationAddress?.NciccountrycodeId || ""}
                                        fieldName="Country Code"
                                        handleRowClick={(e) => {
                                            onHandleValueChange(e, "NciccountrycodeId");
                                        }}
                                    />
                                </div>
                            </div> */}
            </div>

            <div className="Geo_location_container">
              <p className="location_title">Geo Location</p>
              <div className="_geo_location_container_fluid">
                <div className="_city">
                  <div className="city_text">
                    <TextBoxComponent
                      value={addLocationAddress?.Latitude?.toString() || ""}
                      fieldName="Latitude"
                      onChangeValue={(e) => {
                        // Allow input if it matches a number or a number with a decimal point.
                        const isNumeric = /^-?\d*\.?\d*$/.test(e);
                        if (isNumeric) {
                          onHandleValueChange(e, "Latitude");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="_city">
                  <div className="city_text">
                    <TextBoxComponent
                      value={addLocationAddress?.Longitude?.toString() || ""}
                      fieldName="Longitude"
                      onChangeValue={(e) => {
                        // Allow input if it matches a number, including negative numbers and decimals.
                        const isNumeric = /^-?\d*\.?\d*$/.test(e);
                        if (isNumeric) {
                          onHandleValueChange(e, "Longitude");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="_city">
                  <div className="city_text">
                    <TextBoxComponent
                      value={addLocationAddress?.GeoCode}
                      fieldName="Geo Code"
                      onChangeValue={(e) => {
                        if (e.length < 6) onHandleValueChange(e, "GeoCode");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="_city">
                <div className="city_text">
                  <TextBoxComponent
                    value={addLocationAddress?.ParcelId || ""}
                    fieldName="Parcel ID"
                    onChangeValue={(e) => {
                      if (e.length < 25) {
                        onHandleValueChange(e, "ParcelId");
                      }
                    }}
                  />
                </div>
              </div>

              <div className="_geo_location_validate_container">
                <CheckBoxComponent
                  value={addLocationAddress?.IsGeoValidated}
                  fieldName="Is Geo Validated"
                  onChangeValue={() => {
                    onHandleValueChange(
                      !addLocationAddress?.IsGeoValidated,
                      "IsGeoValidated"
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="submit_location_container">
          <div className="_button_conatiner location_submit_btn_container">
            <div className="cancel_btn">
              <ButtonComponents
                name="Cancel"
                color="white"
                borderColor="white"
                handleClick={() => setAddLocationAddress(initialData)}
              />
            </div>

            <div className="saubit_btn">
              <ButtonComponents
                disabled={disabled}
                name="Save"
                color="#3672b3"
                borderColor="#3672b3"
                showBackgroundColor={true}
                textColor="white"
                handleClick={addLocationAddressHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLocationForm;
