import { Fetcher } from "../../../../../../services/Fetcher";

import {
    convertToISOFormat,
    formatDate,
  } from "../../../../../../utils/Helper/Helper";
  import {
    successCreatedMessage,
    successUpdateMessage,
  } from "../../../../../../utils/const";
  import {
    setagencyData,
    getallcodeagency
  } from "./FilterI";

const fetcher = new Fetcher();
export class FilterFunction {
    async getallcodeagency(
        setagencyData: (data: setagencyData[]) => void,
        setLoading?: (data: boolean) => void
      ) {
        if (setLoading) {
          setLoading(true);
        }
        const response = await fetcher.get("/agency/agencyList");
        setagencyData(
          response.Data?.map((val: getallcodeagency) => {
            return {
              CodeCategoryId: val.AgencyId,
              CategoryName: val.AgencyName,
            };
          })
        );
    
        if (setLoading) {
          setLoading(false);
        }
      }

}