import edit_icon from "../../../../../../assets/icons/edit.svg";
import delete_icon from "../../../../../../assets/icons/delete.svg";
import { SetTableData } from "./ORIInterface";

export const coloum = (
  editORI: (data: SetTableData) => void,
  openDeletePopup: (data: string) => void,
  Delete: boolean,
  Update: boolean
) => {
  return [
    { field: "ORI" },
    { field: "Description" },
    { field: "Status" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <>
            <div className="table-cellRenderer">
              {Update && (
                <img
                  className="table-cellRenderer-img"
                  src={edit_icon}
                  onClick={() => editORI(e.data)}
                  alt="Edit"
                />
              )}
              {Delete && (
                <img
                  className="table-cellRenderer-img"
                  src={delete_icon}
                  onClick={() => {
                    console.log(e?.data);
                    openDeletePopup(e?.data?.ID);
                  }}
                  alt="Delete"
                />
              )}
            </div>
          </>
        );
      },
    },
  ];
};
