import React, { useEffect, useState } from "react";
import { ButtonComponents } from "src/Components/CommonComponents/Fields/Button/ButtonComponents";
import { DropdownComponent } from "src/Components/CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextAreaComponent } from "src/Components/CommonComponents/Fields/TextArea/TextAreaComponent";
import { TextBoxComponent } from "src/Components/CommonComponents/Fields/TextBox/TextBoxComponent";
import { CasesListFunction } from "../../CasesFunctions";
import { agencyLevel, externalCasePayload } from "../CaseIState";
import { DateBoxComponents } from "src/Components/CommonComponents/Fields/DateBox/DateBox";
import {
  convertToISOFormatDateOnly,
  convertToISOFormatMonth,
} from "src/utils/Helper/Helper";
import { InvolvementMainIParams } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useParams } from "react-router-dom";
import { Loader } from "src/Components/CommonComponents/Loader/Loader";

interface ExternalCasesModalFormProps {
  handleClose: () => void;
  setExternalCasesAdded:(data:boolean)=>void;
}
const ExternalCasesModalForm: React.FC<ExternalCasesModalFormProps> = ({
  handleClose,
  setExternalCasesAdded,
}) => {
  const caseListInstance = new CasesListFunction();
  const [externalCases, setEcternalCases] = useState<externalCasePayload>({});
  const [agencyLevelList, setAgencyLevelList] = useState<agencyLevel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { incidentId } = useParams<InvolvementMainIParams>();

  const handleChange = (key: string, value: string) => {
      setEcternalCases((prev) => ({
        ...prev,
        IncidentId: incidentId || null,
        RelatedIncidentExternalId: null,
        ExternalAgency: null,
        [key]: value,
      }));
  };

 const submitHandler = async () => {
   if (externalCases?.IncidentDate) {
     const [month, day, year] = externalCases.IncidentDate.split("-");
     if (month && day && year) {
       try {
         const formattedDate = new Date(
           `${year}-${month}-${day}`
         ).toISOString();
         externalCases.IncidentDate = formattedDate;
       } catch (error) {
         console.error("Error formatting date:", error);
       }
     } else {
       console.error("Invalid date format for IncidentDate.");
       return; 
     }
   } else {
     console.error("IncidentDate is undefined or invalid.");
     return; 
   }

   const res = await caseListInstance?.submitExternalCase(
     setLoading,
     incidentId ? incidentId : null,
     externalCases
   );

   // Handle response
   if (res?.Success) {
     handleClose();
     setExternalCasesAdded(true);
   }
 };

  useEffect(() => {
    caseListInstance?.getAgencyLevelList(setAgencyLevelList);
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="_external_cases_modal_form_container">
        <div className="_upper_case_number_container">
          <div className="_upper_case_number_left_">
            <div className="_case_number_container_fluid">
              <p className="case_number_title">Case Number</p>
              <div className="_external_case_textbox_container">
                <TextBoxComponent
                  fieldName=""
                  value={externalCases?.IncidentNumber || ""}
                  onChangeValue={(e) => handleChange("IncidentNumber", e)}
                />
              </div>
            </div>
            <div className="_external_date_container">
              <DateBoxComponents
                fieldName=""
                value={externalCases?.IncidentDate || ""}
                onValueChange={(e) => {
                  const value = e ?? "";  
                  handleChange(
                    "IncidentDate",value
                  );
                }}
              />
            </div>
          </div>
          <div className="_upper_case_number_right">
            <ButtonComponents name="Go to cases" />
          </div>
        </div>

        <div className="_middle_case_modal_form_container">
          <div className="_middle_agency_dropdown_container">
            <DropdownComponent
              dataSource={agencyLevelList?.map((val, i) => {
                return {
                  id: val?.AgencyId,
                  value: val?.AgencyName,
                };
              })}
              fieldName="Agency"
              handleRowClick={(e) => {
                handleChange("AgencyId", e);
              }}
              value={externalCases?.AgencyId || ""}
            />
          </div>
        </div>

        <div className="_external_case_modal_text_area_container">
          <TextAreaComponent
            fieldName=""
            onChangeValue={(e) => handleChange("Description", e)}
            value={externalCases?.Description || ""}
          />
        </div>

        <div className="_save_btn_container_fluid">
          <div className="_cancel_btn_container">
            <ButtonComponents name="Cancel" color="white" />
          </div>
          <div className="_submit_btn_container">
            <ButtonComponents
              name="Related Incident"
              color="#3672B3"
              showBackgroundColor={true}
              textColor="white"
              handleClick={submitHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalCasesModalForm;
