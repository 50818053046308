import { InvolvementMainIState } from "../../InvolvementMainI";
import { ScarsMarksTattoosIState } from "./ScarsMarksTattoosI";

export class ScarsMarksTattoosFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: ScarsMarksTattoosIState[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: ScarsMarksTattoosIState[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.InvolvementTab.ScarsMarksTattoos);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange: true,
        ScarsMarksTattoos: [
          ...this.Mainfields.InvolvementTab.ScarsMarksTattoos.map((val) => {
            return {
              ...val,
              push: true,
            };
          }),
          {
            id:
              this.Mainfields.InvolvementTab.ScarsMarksTattoos[
                this.Mainfields.InvolvementTab.ScarsMarksTattoos.length - 1
              ].id + 1,
            Notes: "",
            ScarMarkTattoos: "",
            TypeLocation: "",
            ScarMarkTattoosDropdownValue: "",
            TypeLocationDropdownValue: "",
            push: false,
          },
        ],
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.InvolvementTab.ScarsMarksTattoos);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      InvolvementTab: {
        ...this.Mainfields.InvolvementTab,
        onChange: true,
        ScarsMarksTattoos:
          this.Mainfields.InvolvementTab.ScarsMarksTattoos.filter((val) => {
            if (val.id != id) {
              return val;
            }
          }).length
            ? this.Mainfields.InvolvementTab.ScarsMarksTattoos.filter((val) => {
                if (val.id != id) {
                  return val;
                }
              })
            : [
                {
                  id: 1,
                  Notes: "",
                  ScarMarkTattoos: "",
                  TypeLocation: "",
                  ScarMarkTattoosDropdownValue: "",
                  TypeLocationDropdownValue: "",
                },
              ],
      },
    });

    this.setedit(false);
  };
}
