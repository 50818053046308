import { Fetcher } from "../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../utils/const";
import { formatDate } from "../../../../utils/Helper/Helper";
import {
  OrderByType,
  SetTableData,
  getAgencyLevelTableDataI,
} from "./AgencyLevelI";
import { OrderByFieldName } from "./Config";

const fetcher = new Fetcher();
export class AgencyLevelFunction {
  async getAgencyLevelTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    pageNumber: number,
    pageCount: number,
    Category?: string,
    Status?: string,
    AgencyLevelCode?: string,
    OrderByDescending?: boolean,
    OrderBy?: string
  ) {
    setLoading(true);

    const isSearch = !!(
      AgencyLevelCode ||
      Category ||
      Status == "1" ||
      Status == "0"
    );

    let response = await fetcher.post(`/code/agency/search`, {
      PageNumber: isSearch ? 1 : pageNumber,
      PageSize: pageCount,
      LevelCode: AgencyLevelCode ? AgencyLevelCode : null,
      CategoryID: Category ? Category : null,
      Status: Status == "1" ? true : false,
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
    });
    const TableJson = this.createTableJson(response?.Data);
    setTableTotalRecord(response?.TotalRecords ?? 0);
    setTableData(TableJson);

    setLoading(false);
  }

  createTableJson(data: getAgencyLevelTableDataI[]) {
    return data?.map((val: getAgencyLevelTableDataI, i: number) => {
      return {
        "Date Active": formatDate(val.ActiveDate),
        "Date Inactive": formatDate(val.ExpiryDate),
        "Long Code": val.LongCode,
        "Short Code": val.ShortCode,
        Category: val.CategoryName,
        Description: val.Description,
        Status: val.IsActive ? "Active" : "InActive",
        AgencyLevelCodeId: val.AgencyLevelCodeId,
      };
    });
  }

  async deleteAgencyLevel(
    agencyCodeId: string,
    setLoading: (data: boolean) => void,
    setdeletePopup: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/code/${agencyCodeId}/agency/status`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    setdeletePopup(false);
  }
}
