import { Fetcher } from "../../../../../../../../../../../services/Fetcher";
import { GetRaceDataIState } from "../../../../InvolvementMainI";

const fetcher=new Fetcher()
export class ArmedWithWeaponFunction{
    async ArmedWithWeapon(setArmedWithWeapon: (data: GetRaceDataIState[]) => void) {
        const response = await fetcher.get("/code/ncic/category/ArresteeWasArmedWith");
        let res: GetRaceDataIState[] = response.Data;
        setArmedWithWeapon(res);
      }
}