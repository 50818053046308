import { BrowserRouter } from "react-router-dom";
import { RouterMain } from "./Router/RouterMain";
import QueryProvider from "./QueryProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const App = () => {
  return (
    <>
      <QueryProvider>
        <BrowserRouter>
          <RouterMain />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryProvider>
    </>
  );
};
