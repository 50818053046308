import { Fetcher } from "../../../../../../../../services/Fetcher";
import { ContactIRes, ContactTableData } from "../ParticipantOverviewIState";

const fetcher = new Fetcher();
export class ContactFunction {
  async getContact(
    participantId: string,
    incidentId: string,
    setTableData: (data: ContactTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/getcontacts`,

      {
        EntityType: "Participant",
        EntityId: participantId,
        EntitySubType: null,
        EntitySubTypeId: null,
      }
    );
    let res: ContactIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: ContactIRes[]) {
    return data?.map((val: ContactIRes, i: number) => {
      return {
        Type: val.ContactType,
        Contact: val.ContactValue,
      };
    });
  }
}
