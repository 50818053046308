import { useEffect, useState } from "react";
import { AdditionalMainFunction } from "../../AdditionalFunction";

import {
  AdditionalInfoProps,
  GetCitizenshipDataI,
} from "../../AdditionalIState";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { MultiDropdownComponent } from "../../../../../../../../CommonComponents/Fields/MultiDropdown/MultiDropdownComponent";
export const Citizenship: React.FC<AdditionalInfoProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [CitizenshipData, setCitizenshipData] = useState<GetCitizenshipDataI[]>(
    []
  );
  const onChangeValue = (
    name: string,
    value: string | number | boolean | string[]
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes: true,
        Citizenship: {
          ...Mainfields.AdditionalInfo.Citizenship,
          [name]: value,
        },
      },
    });
  };
  const initialRender = () => {
    additionalMainFunction.getCitizenshipData(setCitizenshipData);
  };

  useEffect(() => {
    initialRender();
  }, []);
  return (
    <>
      <div className="width_field">
        <p className="subheader">Citizenship</p>

        <div className="Involvement_left_row_checkbox">
          <CheckBoxComponent
            fieldName="US Citizen"
            value={Mainfields.AdditionalInfo.Citizenship.IsUscitizen}
            onChangeValue={(e) => {
              onChangeValue("IsUscitizen", e);
            }}
          />
          <CheckBoxComponent
            fieldName="Non US Citizen"
            value={
              Mainfields.AdditionalInfo.Citizenship.IsUscitizen ? false : true
            }
            onChangeValue={(e) => {
              onChangeValue("NonUSCitizen", e);
            }}
          />
          <CheckBoxComponent
            fieldName="Unknown "
            value={Mainfields.AdditionalInfo.Citizenship.CitizenshipUnknown}
            onChangeValue={(e) => {
              onChangeValue("CitizenshipUnknown", e);
            }}
          />
        </div>
        <div className="width_field">
          <MultiDropdownComponent
            fieldName="Citizenship"
            value={Mainfields.AdditionalInfo.Citizenship.Citizenship}
            handleRowClick={(e) => {
              onChangeValue("Citizenship", e);
            }}
            dataSource={CitizenshipData.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
        </div>
      </div>
    </>
  );
};
