import React, { useEffect, useState } from "react";
import { LocationMainFunction } from "../../../Location/LocationMainFunction";
import {
  locationDataValue,
  locationDropdownList,
} from "../../../Location/LocationOverviewIState";
import { useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { Spin } from "antd";

interface LocationDropdownProps {
  placeholderHandler: (namesData: string) => void;
}
const LocationDropdown: React.FC<LocationDropdownProps> = ({
  placeholderHandler,
}) => {
  const locationData = new LocationMainFunction();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [tableData, setTableData] = useState<Array<locationDataValue>>([]);
  const [dropdownList, setDropdownList] = useState<locationDropdownList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const getLocationList = async () => {
    if (incidentId) {
      let data = await locationData?.locationList(
        incidentId,
        setTableData,
        setLoading,
        "",
        setTableTotalRecord
      );
      setDropdownList(data?.Data);
    }
  };

  useEffect(() => {
    getLocationList();
  }, []);
  return (
    <>
      {/* <Loader loading={loading}/> */}
      <div className="_toolbar_text_container">
        <div className="_narrative_name_container">
          {loading && <div className="_loader_container">
            <Spin
              spinning={loading}
              tip="Loading"
              fullscreen={false}
              size={"large"}
            />
          </div>}
          <div className="_name_conatiner_list">
            <ol>
              {dropdownList?.map(
                (item: locationDropdownList, index: number) => (
                  <li
                    className="_name _name_narrative_placeholder"
                    onClick={() =>
                      placeholderHandler(
                        `${item?.HouseNumber ? item?.HouseNumber : ""} ${
                          item?.Street1Name ? item?.Street1Name : ""
                        } ${item?.City ? item?.City : ""} ${
                          item?.CountryName ? item?.CountryName : ""
                        } ${item?.ZipCode ? item?.ZipCode : ""}`
                      )
                    }
                  >
                    {`${item?.HouseNumber ? item?.HouseNumber : ""}
                                        ${
                                          item?.Street1Name
                                            ? item?.Street1Name
                                            : ""
                                        }
                                        ${item?.City ? item?.City : ""}
                                        ${
                                          item?.CountryName
                                            ? item?.CountryName
                                            : ""
                                        }
                                        ${item?.ZipCode ? item?.ZipCode : ""}
                                        `}
                  </li>
                )
              )}
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationDropdown;
