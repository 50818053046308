import { useEffect, useState } from "react";
import {
  MultipleAddressDataUIProps,
  MultipleAddressIProps,
  MultipleAddressIState,
  previewIState,
} from "./MultipleAddressI";
import add from "../../../../../../../../../assets/icons/Icon.svg";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { GetRaceDataIState } from "../../InvolvementMainI";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import "./address.css";
import { Add } from "../../../../../../../../../assets";
import { MultipleAddressFunction } from "./MultipleAddressFunction";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

const MultipleAddressDataUI: React.FC<MultipleAddressDataUIProps> = ({
  Mainfields,
  setMainfields,
  MultipleAddressval,
  RaceDataData,
  StreetPrefix,
  StreetSuffix,
  StateCode,
  Country,
  LocationCategory,
  Participantionvalidation,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    if (setMainfields && Mainfields) {
      setMainfields({
        ...Mainfields,
        InvolvementTab: {
          ...Mainfields.InvolvementTab,
          onChange: true,
          Address: {
            ...Mainfields.InvolvementTab.Address,
            AddressData: Mainfields.InvolvementTab.Address.AddressData.map(
              (val) => {
                if (val.id == id) {
                  if (key2 && value2) {
                    return { ...val, [key]: value, [key2]: value2 };
                  } else {
                    return { ...val, [key]: value };
                  }
                } else {
                  return val;
                }
              }
            ),
          },
        },
      });
    }
  };

  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    if (setMainfields && Mainfields) {
      setMainfields({
        ...Mainfields,
        InvolvementTab: {
          ...Mainfields.InvolvementTab,
          onChange: true,
          Address: {
            ...Mainfields.InvolvementTab.Address,
            [name]: value,
          },
        },
      });
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px" }}>
        <CheckBoxComponent
          value={
            Mainfields
              ? Mainfields.InvolvementTab.Address.NoPermanentAddress
              : false
          }
          fieldName="No Permanent Address"
          onChangeValue={(e) => {
            onChangeValue("NoPermanentAddress", e);
          }}
        />
        <CheckBoxComponent
          value={
            Mainfields
              ? Mainfields.InvolvementTab.Address.UnknownAddress
              : false
          }
          fieldName="Unknown Address"
          onChangeValue={(e) => {
            onChangeValue("UnknownAddress", e);
          }}
        />
      </div>
      <div className="address-checkbox_ address-check_container">
        {!MultipleAddressval.ForeignAddress && (
          <>
            <div className="p_address_type_container">
              <DropdownComponent
                value={MultipleAddressval.AddressType}
                handleRowClick={(e) => {
                  onChangeValueNumber("AddressType", e, MultipleAddressval.id);
                }}
                dataSource={RaceDataData.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Address Type"
              />
            </div>
            <div className="p_address_type_container">
              <DropdownComponent
                value={MultipleAddressval.LocationCategoryCode}
                handleRowClick={(e) => {
                  onChangeValueNumber(
                    "LocationCategoryCode",
                    e,
                    MultipleAddressval.id
                  );
                }}
                validation={
                  Participantionvalidation.Involvement.locationcatigory
                }
                dataSource={LocationCategory.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Location Category"
              />
            </div>
          </>
        )}

        <CheckBoxComponent
          value={MultipleAddressval.ForeignAddress}
          fieldName="Foreign Address"
          onChangeValue={(e) => {
            onChangeValueNumber("ForeignAddress", e, MultipleAddressval.id);
          }}
        />
      </div>
      {MultipleAddressval.ForeignAddress ? (
        <>
          <div style={{ width: "70%" }}>
            <TextBoxComponent
              value={MultipleAddressval.AddressLine1}
              onChangeValue={(e) => {
                onChangeValueNumber("AddressLine1", e, MultipleAddressval.id);
              }}
              fieldName="Address Line 1: Street address, P.O box, company name, c/o"
            />
            <TextBoxComponent
              value={MultipleAddressval.AddressLine2}
              onChangeValue={(e) => {
                onChangeValueNumber("AddressLine2", e, MultipleAddressval.id);
              }}
              fieldName="Address Line 2: Apartment, suite, unit, building, floor, etc"
            />
          </div>
          <div className="Involvement_left_row">
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressCity}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressCity",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="City"
            />
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressStateProvinceRegion}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressStateProvinceRegion",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="State / Province/Region"
            />
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressZip}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressZip",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="Zip / postal Code"
            />
          </div>
          <div style={{ width: "40%" }}>
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressCountry}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressCountry",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="Country"
            />
          </div>
        </>
      ) : (
        <>
          <div className="involvemt_street_inline_container">
            <div className="Involvement_left_row address_alignment_container">
              <div className="_involvement_meta_info">
                <TextBoxComponent
                  value={MultipleAddressval.StreetNumber}
                  onChangeValue={(e) => {
                    onChangeValueNumber(
                      "StreetNumber",
                      e,
                      MultipleAddressval.id
                    );
                  }}
                  fieldName="Street Number"
                />
              </div>
              <div className="_involvement_meta_info">
                <DropdownComponent
                  handleRowClick={(e) => {
                    onChangeValueNumber(
                      "StreetPrefix",
                      e,
                      MultipleAddressval.id
                    );
                  }}
                  value={MultipleAddressval.StreetPrefix}
                  dataSource={StreetPrefix.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Street Prefix"
                />
              </div>
              <div className="involvemet_street_container">
                <TextBoxComponent
                  onChangeValue={(e) => {
                    onChangeValueNumber("Street", e, MultipleAddressval.id);
                  }}
                  value={MultipleAddressval.Street}
                  fieldName="Street"
                />
              </div>
              <div className="_involvement_meta_info">
                <DropdownComponent
                  value={MultipleAddressval.StreetSuffix}
                  handleRowClick={(e) => {
                    onChangeValueNumber(
                      "StreetSuffix",
                      e,
                      MultipleAddressval.id
                    );
                  }}
                  dataSource={StreetSuffix.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Street Suffix"
                />
              </div>
              <div className="_involvement_meta_info">
                <TextBoxComponent
                  value={MultipleAddressval.Apartment}
                  onChangeValue={(e) => {
                    onChangeValueNumber("Apartment", e, MultipleAddressval.id);
                  }}
                  fieldName="Apartment"
                />
              </div>
            </div>
            <div className="Involvement_left_row "></div>
          </div>
          <div className="address_city_address_container">
            <div className="_address_state_providence">
              <TextBoxComponent
                value={MultipleAddressval.City}
                fieldName="City"
                onChangeValue={(e) => {
                  onChangeValueNumber("City", e, MultipleAddressval.id);
                }}
              />
            </div>
            <div className="_address_state_providence">
              <DropdownComponent
                value={MultipleAddressval.StateProvidence}
                dataSource={StateCode.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                handleRowClick={(e, value2) => {
                  onChangeValueNumber(
                    "StateProvidence",
                    e,
                    MultipleAddressval.id,
                    "StateProvidenceDropdownvalue",
                    value2
                  );
                }}
                fieldName="State / Providence"
              />
            </div>

            <div className="_address_zip_code">
              <TextBoxComponent
                value={MultipleAddressval.ZipCode}
                onChangeValue={(e) => {
                  onChangeValueNumber("ZipCode", e, MultipleAddressval.id);
                }}
                fieldName="Zip Code"
              />
            </div>
          </div>
          <div className="country_grid_container">
            <div className="_address_country_container">
              <DropdownComponent
                value={MultipleAddressval.Country}
                handleRowClick={(e, value2) => {
                  onChangeValueNumber(
                    "Country",
                    e,
                    MultipleAddressval.id,
                    "CountryDropdownValue",
                    value2
                  );
                }}
                dataSource={Country.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Country"
              />
            </div>
            <div className="_address_grid_number">
              <TextBoxComponent
                value={MultipleAddressval.GridNumber}
                onChangeValue={(e) => {
                  const value = e;
                  if (/^\d{0,5}$/.test(value)) {
                    onChangeValueNumber(
                      "GridNumber",
                      value,
                      MultipleAddressval.id
                    );
                  }
                }}
                fieldName="Grid Number"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const MultipleAddressComponents: React.FC<MultipleAddressIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [StateCode, setStateCode] = useState<GetRaceDataIState[]>([]);
  const [LocationCategory, setLocationCategory] = useState<GetRaceDataIState[]>(
    []
  );
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<MultipleAddressIState[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const multipleAddressFunction = new MultipleAddressFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    multipleAddressFunction.Save();
  };

  const handleClick = () => {
    multipleAddressFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    multipleAddressFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    multipleAddressFunction.DeletePreview(id);
  };

  const initialRender = () => {
    involvementMainFunction.getAddresType(setRaceDataData);
    involvementMainFunction.getStreetPrefix(setStreetPrefix);
    involvementMainFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setStateCode);
    involvementMainFunction.getLocationType(setLocationCategory);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <Preview
          DeletePreview={DeletePreview}
          ViewPreview={ViewPreview}
          showlist={[
            [
              "StreetNumber",
              "comma",
              "Street",
              "comma",
              "City",
              "comma",
              "StateProvidenceDropdownvalue",
              "comma",
              "ZipCode",
              "comma",
              "AddressLine1",
              "comma",
              "AddressLine2",
              "comma",
              "CountryDropdownValue",
              "comma",
              "ForeignAddressCity",
              "comma",
              "ForeignAddressStateProvinceRegion",
              "comma",
              "ForeignAddressZip",
              "comma",
              "ForeignAddressCountry",
            ],
          ]}
          list={
            edit
              ? (editList.map((item) => ({ ...item })) as {
                  [key: string]: string | number | boolean;
                }[])
              : (Mainfields.InvolvementTab.Address.AddressData.map((item) => ({
                  ...item,
                })) as { [key: string]: string | number | boolean }[])
          }
        />

        <MultipleAddressDataUI
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          MultipleAddressval={
            edit
              ? Mainfields.InvolvementTab.Address.AddressData.filter((val) => {
                  if (val.id == previewcount) {
                    return val;
                  }
                })[0]
              : Mainfields.InvolvementTab.Address.AddressData[
                  Mainfields.InvolvementTab.Address.AddressData.length - 1
                ]
          }
          RaceDataData={RaceDataData}
          StreetPrefix={StreetPrefix}
          StreetSuffix={StreetSuffix}
          StateCode={StateCode}
          Country={Country}
          LocationCategory={LocationCategory}
          Participantionvalidation={Participantionvalidation}
        />
        <div className="edit-button_">
          <ButtonComponents
            name={edit ? "Save" : "Add Address"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
