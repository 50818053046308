import edit_icon from "../../../../../../assets/icons/edit.svg";
import delete_icon from "../../../../../../assets/icons/delete.svg";
import {
  SetTableData,
  setgetEmergencyContactSingleDataIState,
} from "./EmergencyContactTabI";

export const coloum = (
  editORI: (data: setgetEmergencyContactSingleDataIState) => void,
  setdeleteData: (data: setgetEmergencyContactSingleDataIState) => void,
  Update: boolean,
  Delete: boolean
) => {
  return [
    { field: "Primary Contact" },
    { field: "Relation" },
    { field: "Last Name" },
    { field: "First Name" },
    { field: "Middle Name" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
              <img
                className="table-cellRenderer-img"
                src={edit_icon}
                onClick={() => {
                  editORI(e.data);
                }}
                alt="Edit"
                title="Edit"
              />
            )}
            {Delete && (
              <img
                className="table-cellRenderer-img"
                src={delete_icon}
                onClick={() => setdeleteData(e.data)}
                alt="Delete"
                title="Delete"
              />
            )}
          </div>
        );
      },
    },
  ];
};
