import { Fetcher } from "../../../../../../../services/Fetcher";
import { successCreatedMessage } from "../../../../../../../utils/const";
import { combineDateTimeToISO } from "../../../../../../../utils/Helper/Helper";
import {
  InvolvementMainIState,
  InvolvementTab,
  SubstanceTab,
} from "../Involvement/InvolvementMainI";
import {
  SubstanceAndAlcoholIRes,
  SubstanceIstate,
} from "./Components/SubstanceInvolvement/SubstanceIProps";

const fetcher = new Fetcher();
export class SubstanceDetainmentFunction {
  async SubstanceSave(
    setLoading: (data: boolean) => void,
    Mainfields: SubstanceTab,
    incidentId?: string,
    participantId?: string
  ) {
    setLoading(true);

    let body = {
      SubstanceInvolvements: Mainfields.Substance.map((val) => {
        if (val.NcicsubstanceTypeCodeId) {
          return {
            SubstanceInvolvementId: val.SubstanceInvolvementId
              ? val.SubstanceInvolvementId
              : null,
            AgencyId: val.AgencyId ? val.AgencyId : null,
            IncidentId: val.IncidentId ? val.IncidentId : null,
            PersonId: val.PersonId ? val.PersonId : null,
            NcicsubstanceTypeCodeId: val.NcicsubstanceTypeCodeId
              ? val.NcicsubstanceTypeCodeId
              : null,
            IsInvolved: val.IsInvolved === "1" ? true : false,
          };
        }
      }).filter((val) => val),
      BloodAlcoholLevels: Mainfields.BloodTab.map((val) => {
        if (
          val.NcicsubstanceTypeCodeId ||
          val.TestResults ||
          val.TestDate ||
          val.TestTime
        ) {
          return {
            ParticipantBloodAlcoholLevelId: val.ParticipantBloodAlcoholLevelId
              ? val.ParticipantBloodAlcoholLevelId
              : null,
            AgencyId: val.AgencyId ? val.AgencyId : null,
            IncidentId: val.IncidentId ? val.IncidentId : null,
            PersonId: val.PersonId ? val.PersonId : null,
            TestTypeCodeId: val.NcicsubstanceTypeCodeId
              ? val.NcicsubstanceTypeCodeId
              : null,
            TestResult: val.TestResults,
            TestDateTime:
              val.TestDate && val.TestTime
                ? combineDateTimeToISO(val.TestDate, val.TestTime)
                : null,
          };
        }
      }).filter((val) => val),
    };

    try {
      let response = await fetcher.post(
        `/incident/${incidentId}/participant/${participantId}/substance-detainment`,
        body
      );
      let res: SubstanceIstate = response.data;
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async getSubstanceMainDetainment(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/substance-detainment`
    );
    let SubstanceAndAlcohol: SubstanceAndAlcoholIRes = response.Data;
    return SubstanceAndAlcohol;
  }
}
