import { useEffect, useState } from "react";
import { IncidentsCADInformationJSONFunction } from "./IncidentsCADInformationJSONFunction";
import { useParams } from "react-router-dom";
import { IncidentsCADInformationJSONIParams } from "../CADInformationI";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
type JsonData = {
  [key: string]: any;
};

interface JsonViewerProps {
  data: JsonData | string;
}

const JsonViewer: React.FC<JsonViewerProps> = ({ data }) => {
  const styles = {
    container: {
      paddingLeft: "20px",
      borderLeft: "2px solid #ddd",
      margin: "5px 0",
    },
    key: {
      color: "#007acc",
      fontWeight: "bold",
    },
    value: {
      color: "#333",
    },
    nullValue: {
      color: "#999",
    },
  };

  if (typeof data === "object" && data !== null) {
    return (
      <div style={styles.container}>
        {Object.keys(data).map((key) => (
          <div key={key}>
            <span style={styles.key}>{key}:</span>
            {typeof data[key] === "object" && data[key] !== null ? (
              <JsonViewer data={data[key]} />
            ) : (
              <span
                style={data[key] !== null ? styles.value : styles.nullValue}
              >
                {" "}
                {data[key] !== null ? data[key].toString() : "null"}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <span style={data !== null ? styles.value : styles.nullValue}>
        {data !== null ? data.toString() : "null"}
      </span>
    );
  }
};

export const IncidentsCADInformationJSON = () => {
  const incidentsCADInformationJSONFunction =
    new IncidentsCADInformationJSONFunction();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const { incidentId } = useParams<InvolvementMainIParams>();
  const { CadimportId } = useParams<IncidentsCADInformationJSONIParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [CADInformationJSON, setCADInformationJSON] = useState<string>("");
  const [CADInformationJSONErrorMessage, setCADInformationJSONErrorMessage] =
    useState<string>("");

  const initialRender = () => {
    if (CadimportId) {
      incidentsCADInformationJSONFunction.getCADInformationJSON(
        setLoading,
        CadimportId,
        setCADInformationJSON,
        setCADInformationJSONErrorMessage
      );
    }
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="UserManagement">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Incidents", path: "/incidents" },
            { title: incidentNumber ? incidentNumber : "", path: "" },
            {
              title: "CAD Information",
              path: `/incidents/${incidentId}/cad-information`,
            },
            {
              title: "JSON CAD",
              path: `/incidents/${incidentId}/cad-information`,
            },
          ]}
        />
        <div className="caseHeader">
          <p className="header">JSON Of CAD Information</p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flex: 1,
              maxHeight: "80vh",
              overflowY: "auto",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
            }}
          >
            {[CADInformationJSON].map((CADInformationJSONval) => {
              if (!CADInformationJSONval) {
                return null;
              }
              let jsonString = CADInformationJSONval?.replace(/[\n\r\t]/g, "");
              let finaldata = JSON.parse(jsonString);

              return <JsonViewer data={finaldata} />;
            })}
          </div>

          <div
            style={{
              flex: 1,
              maxHeight: "80vh",
              overflowY: "auto",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
            }}
          >
            {[CADInformationJSONErrorMessage].map(
              (CADInformationJSONErrorMessageval) => {
                if (!CADInformationJSONErrorMessageval) {
                  return null;
                }
                let jsonString = CADInformationJSONErrorMessageval?.replace(
                  /[\n\r\t]/g,
                  ""
                );
                let finaldata: any[] = JSON.parse(jsonString);

                return finaldata.map((finaldataval, index) => (
                  <JsonViewer key={index} data={finaldataval} />
                ));
              }
            )}
          </div>
        </div>
      </div>
    </>
  );
};
