import React from 'react'
import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs'

const IncidentOverview = () => {
  return (
    <>
    <div >
      <Breadcrumbs/>
    </div>
      <h3 style={{marginTop:"100px"}}>IncidentOverview</h3>
    </>
  )
}

export default IncidentOverview