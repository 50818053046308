import React, { useEffect, useState } from "react";
import { Table } from "../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { SetTableData } from "./MasterNameIndexListingI";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import DetailedSearch from "./Components/DetailedSearch/DetailedSearch";
import { Filter } from "./Components/filters/filters";
import { useNavigate, useParams } from "react-router-dom";
import { ParticipantListFunction } from "./MasterNameIndexFunction";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { MasterMainIParams } from "../MasterOverview/MasterNameIState";
import { DetailedSearchData } from "./MasterNameIndexListingI";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";

export const MasterNameIndexListing: React.FC = () => {
  const masterNameFunction = new ParticipantListFunction();
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [PersonSurName, setPersonSurName] = useState<string>("");
  const [EntityType, setEntityType] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(10);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("");
  const [formData, setFormData] = useState<DetailedSearchData>({
    PersonSurName: null,
    PersonMiddleName: null,
    PersonGivenName: null,
    SuffixCodeId: null,
    DOB: "",
    Age: null,
    RaceCodeId: null,
    Gender: null,
    EntityType: "All",
    AddressType: "",
    StreetNumber: "",
    StreetPrefix: "",
    Street: "",
    StreetSuffix: "",
    Apartment: "",
    City: "",
    StateProvidence: "",
    ZipCode: "",
    Country: "",
    Grid: "",
    PhoneTypeCodeId: "",
    CountryCodeId: "",
    PhoneNumber: "",
    Ext: "",
    Height: 0,
    Weight: 0,
    NoPermanentAddress: false,
    UnknownAddress: false,
    ForeignAddress:false,
  });

  const navigation = useNavigate();
  const nameId = useParams<MasterMainIParams>();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onRowClicked = (e: SetTableData) => {
    if (nameId && e.EntityType === "Business") {
      navigation(`/master/overview/business/${e.nameId}`);
    } else {
      navigation(`/master/overview/${e.nameId}`);
    }
  };

  const getMasterList = () => {
    if (PersonSurName || EntityType) {
      //const effectiveEntityType = EntityType === "All" ? "" : EntityType;
      const effectiveEntityType = EntityType;
      masterNameFunction.getMasterListTableData(
        setTableData,
        setLoading,
        setTableTotalRecord,
        pageNumber,
        pageCount,
        PersonSurName,
        effectiveEntityType,
        OrderByDescending,
        OrderBy,
        formData
      );
    } else {
      setTableData([]);
      setTableTotalRecord(0);
      setPageNumber(1);
      setPageCount(10);
    }
  };

  const getAdvanceList = () => {
    console.log("form data",formData);
    const effectiveEntityType = EntityType === "All" ? "" : EntityType;
    masterNameFunction.getMasterListTableData(
      setTableData,
      setLoading,
      setTableTotalRecord,
      pageNumber,
      pageCount,
      PersonSurName,
      effectiveEntityType,
      OrderByDescending,
      OrderBy,
      formData
    );
  };

  const initialData = () => {
    getMasterList();
  };

  useEffect(() => {
    if (PersonSurName || EntityType || formData) {
      getMasterList();
    }
  }, [pageNumber, pageCount, OrderByDescending]);
  

  const handleSearch = () => {  
    getAdvanceList();
  };

  const handleClearAll = () => {
    // Reset all search fields
    setPersonSurName("");
    setEntityType("");
    setFormData({
      PersonSurName: null,
      PersonMiddleName: null,
      PersonGivenName: null,
      SuffixCodeId: null,
      DOB: "",
      Age: null,
      RaceCodeId: null,
      Gender: null,
      EntityType: "",
      AddressType: "",
      StreetNumber: "",
      StreetPrefix: "",
      Street: "",
      StreetSuffix: "",
      Apartment: "",
      City: "",
      StateProvidence: "",
      ZipCode: "",
      Country: "",
      Grid: "",
      PhoneTypeCodeId: "",
      CountryCodeId: "",
      PhoneNumber: "",
      Ext: "",
      Height: 0,
      Weight: 0,
      NoPermanentAddress: false,
      UnknownAddress: false,
      ForeignAddress:false,
    });
    // Clear table data
    setTableData([]);
    setTableTotalRecord(0);
    setPageNumber(1);
    setPageCount(10);
  };

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={handleCloseModal}
        open={isModalOpen}
        innerContant={
          <div >
            <DetailedSearch
              formData={formData}
              setFormData={setFormData}
              onSearch={handleSearch}
              handleCloseModal={handleCloseModal}
            />
          </div>
        }
        closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
        
      />
      <div className="UserManagement">
      
        <div>
          <p className="header">Master Name Index</p>
          <Filter
            Name={PersonSurName}
            setName={setPersonSurName}
            EntityType={EntityType}
            setEntityType={setEntityType}
            onSearch={handleSearch}
            onClear={handleClearAll}
          />
          <p
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            Detailed Search
          </p>

          <div className="table">
            <Table
              columns={coloum()}
              columnsValue={tableData}
              onRowClicked={onRowClicked}
              cursorPointer={true}
          headerBackgroundColor="#b3c9e2"
              setpageCount={setPageCount}
              setpageNumber={setPageNumber}
              tableTotalRecord={tableTotalRecord}
              setOrderByDescending={setOrderByDescending}
              OrderByDescending={OrderByDescending}
              OrderBy={OrderBy}
              setOrderBy={setOrderBy}
               
              
            />
          </div>
        </div>
      </div>
    </>
  );
};
