
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (
 
): ColDef[] => {
  return [
    { field: "Street Address" },
    { field: "City" },
    { field: "State" },
    { field: "Zip" },

   
  ];
};
