import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI } from "./AuditI";
export const OrderByFieldName:OrderByFieldNameI = {
  "Action": "UserAction",
  "Method": "",
  "Section": "EntityType",
  "Action By":"UserName",
  "Reported Date/Time": "CreatedOn",
  "IP Address":"IPAddress"
};
export const coloum: () => ColDef[] = () => {
  return [
    
    { field: "Section", headerName: "Section" },
    { field: "Action", headerName: "Action" },
    { field: "Action By", headerName: "Action By" },
    {
      field: "Reported Date/Time",
      valueFormatter: (params) =>
        format(new Date(params.value), "MM-dd-yyyy hh:mm:ss"),
    },
    { field: "IP Address", headerName: "IP Address" },
  ];
};
