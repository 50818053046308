import {
  EmploymentIState,
  InvolvementMainIState,
} from "../../../Involvement/InvolvementMainI";

export class EmployeementFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: EmploymentIState[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: EmploymentIState[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  removePreview(id: number) {
    if (this.Mainfields.AdditionalInfo.Employment.length > 1) {
      this.setMainfields({
        ...this.Mainfields,
        AdditionalInfo: {
          ...this.Mainfields.AdditionalInfo,
          Employment: this.Mainfields.AdditionalInfo.Employment.filter(
            (val) => {
              if (val.id !== id) {
                return val;
              }
            }
          ),
        },
      });
    }
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.AdditionalInfo.Employment);
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.AdditionalInfo.Employment);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      AdditionalInfo: {
        ...this.Mainfields.AdditionalInfo,
        changes: true,
        Employment: this.Mainfields.AdditionalInfo.Employment.filter((val) => {
          if (val.id !== id) {
            return val;
          }
        }).length
          ? this.Mainfields.AdditionalInfo.Employment.filter((val) => {
              if (val.id !== id) {
                return val;
              }
            })
          : [
              {
                ExternalContactID: "",
                push: false,
                ContactFirstname: "",
                id: 1,
                ContactLastname: "",
                EmployerName: "",
                EmployerType: "",
                Occupation: "",
                Shift: "",
                PhoneNumber: [
                  {
                    Country: "",
                    TypeDropdownvalue: "",
                    Ext: "",
                    ExternalContactID: "",
                    id: 1,
                    Number: "",
                    Type: "",
                  },
                ],
                Address: {
                  AddressLine1: "",
                  LocationId: "",
                  AddressLine2: "",
                  AddressType: "",
                  Apartment: "",
                  City: "",
                  Country: "",
                  NoPermanentAddress: false,
                  UnknownAddress: false,
                  ForeignAddress: false,
                  ForeignAddressCity: "",
                  ForeignAddressCountry: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  GridNumber: "",
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                },
                Email: [
                  {
                    Email: "",
                    ExternalContactID: "",
                    id: 1,
                    Type: "",
                    TypeDropdownvalue: "",
                  },
                ],
              },
            ],
      },
    });

    this.setedit(false);
  };
}
