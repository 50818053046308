import add from "../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import {
  previewIState,
  ScarsMarksTattoosComponentIProps,
  ScarsMarksTattoosDataUIIProps,
  ScarsMarksTattoosIState,
} from "./ScarsMarksTattoosI";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { GetRaceDataIState } from "../../InvolvementMainI";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../../../../assets";
import { ScarsMarksTattoosFunction } from "./ScarsMarksTattoosFunction";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

const ScarsMarksTattoosDataUI: React.FC<ScarsMarksTattoosDataUIIProps> = ({
  RaceDataData,
  Mainfields,
  setMainfields,
  ScarsMarksTattoosval,
  ScarsMarksLocation,
  Participantionvalidation,
}) => {
  const [ScarMarkTattoosvalidation, setScarMarkTattoosvalidation] =
    useState<boolean>(false);
  const [Notesvalidation, setNotesvalidation] = useState<boolean>(false);

  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        ScarsMarksTattoos: Mainfields.InvolvementTab.ScarsMarksTattoos.map(
          (val) => {
            if (val.id == id) {
              if (key2 && value2) {
                return { ...val, [key]: value, [key2]: value2 };
              } else {
                return { ...val, [key]: value };
              }
            } else {
              return val;
            }
          }
        ),
      },
    });

    if (
      Participantionvalidation.Involvement.ScarsMarksandTattoos &&
      ScarsMarksTattoosval.Notes
    ) {
      setNotesvalidation(true);
    } else {
      setNotesvalidation(false);
    }

    if (
      Participantionvalidation.Involvement.ScarsMarksandTattoos &&
      ScarsMarksTattoosval.ScarMarkTattoos
    ) {
      setScarMarkTattoosvalidation(true);
    } else {
      setScarMarkTattoosvalidation(false);
    }
  };

  return (
    <>
      <div className="MultiplePhoneNumber">
        <DropdownComponent
          dataSource={RaceDataData.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          value={ScarsMarksTattoosval.ScarMarkTattoos}
          handleRowClick={(e, dropdownValue) => {
            if (dropdownValue) {
              onChangeValueNumber(
                "ScarMarkTattoos",
                e,
                ScarsMarksTattoosval.id,
                "ScarMarkTattoosDropdownValue",
                dropdownValue
              );
            }
          }}
          fieldName="Scar/Mark/Tattoos"
          validation={ScarMarkTattoosvalidation}
        />
        <TextBoxComponent
          value={ScarsMarksTattoosval.Notes}
          fieldName="Notes"
          onChangeValue={(e) => {
            onChangeValueNumber("Notes", e, ScarsMarksTattoosval.id);
          }}
          validation={Notesvalidation}
        />
        <DropdownComponent
          dataSource={ScarsMarksLocation.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          value={ScarsMarksTattoosval.TypeLocation}
          handleRowClick={(e, dropdownValue) => {
            if (dropdownValue) {
              onChangeValueNumber(
                "TypeLocation",
                e,
                ScarsMarksTattoosval.id,
                "TypeLocationDropdownValue",
                dropdownValue
              );
            }
          }}
          fieldName="Type/Location"
        />
      </div>
    </>
  );
};

export const ScarsMarksTattoosComponent: React.FC<
  ScarsMarksTattoosComponentIProps
> = ({ Mainfields, setMainfields, Participantionvalidation }) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const [ScarMarks, setScarsMarks] = useState<GetRaceDataIState[]>([]);
  const [ScarsMarksLocation, setScarsMarksLocation] = useState<
    GetRaceDataIState[]
  >([]);
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<ScarsMarksTattoosIState[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const scarsMarksTattoosFunction = new ScarsMarksTattoosFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    scarsMarksTattoosFunction.Save();
  };

  const handleClick = () => {
    scarsMarksTattoosFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    scarsMarksTattoosFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    scarsMarksTattoosFunction.DeletePreview(id);
  };

  const initialRender = () => {
    involvementMainFunction.getScarsMarks(setScarsMarks);
    involvementMainFunction.getScarsMarksLocation(setScarsMarksLocation);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Preview
        DeletePreview={DeletePreview}
        ViewPreview={ViewPreview}
        showlist={[
          [
            "ScarMarkTattoosDropdownValue",
            "comma",
            "TypeLocationDropdownValue",
          ],
        ]}
        list={
          edit
            ? (editList.map((item) => ({ ...item })) as {
                [key: string]: string | number | boolean;
              }[])
            : (Mainfields.InvolvementTab.ScarsMarksTattoos.map((item) => ({
                ...item,
              })) as { [key: string]: string | number | boolean }[])
        }
      />

      <div>
        <ScarsMarksTattoosDataUI
          setMainfields={setMainfields}
          Mainfields={Mainfields}
          RaceDataData={ScarMarks}
          ScarsMarksTattoosval={
            edit
              ? Mainfields.InvolvementTab.ScarsMarksTattoos.filter((val) => {
                  if (val.id == previewcount) {
                    return val;
                  }
                })[0]
              : Mainfields.InvolvementTab.ScarsMarksTattoos[
                  Mainfields.InvolvementTab.ScarsMarksTattoos.length - 1
                ]
          }
          ScarsMarksLocation={ScarsMarksLocation}
          Participantionvalidation={Participantionvalidation}
        />

        <div className="edit-button_">
          <ButtonComponents
            name={edit ? "Save" : "Add S/M/T"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
