import React, { useEffect, useState } from "react";
import { coloum } from "./Config";
import { EmergencyMainFunction } from "./EmergencyMainfunction";
import { useParams } from "react-router-dom";
import { EmergencyData, MasterMainIParams } from "../../MasterNameIState";
import { Table } from "../../../../../CommonComponents/Table/Table";

export const Emergency: React.FC = () => {
  const emergencyContactFunction = new EmergencyMainFunction();
  const [tableData, setTableData] = useState<EmergencyData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);

  const { nameId } = useParams<MasterMainIParams>();


  const getEmergencyContact = () => {
    if(nameId){
      emergencyContactFunction.getEmergencyMain(
        setTableData,
        setTableTotalRecord,
        nameId)
    }
    
    
  };

  useEffect(() => {
    getEmergencyContact();
  }, []);

  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Emergency Contact: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
           <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
           
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
