// import React, { useEffect, useState } from "react";
// import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
// import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
// import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
// import close from "../../../../../assets/icons/close-circle.svg";
// import "./Filter.css";
// import add from "../../../../../assets/icons/Icon.svg";
// import { FilterIProps } from "./FilterIProps";
// import { DateBoxComponents } from "../../../../CommonComponents/Fields/DateBox/DateBox";
// import { DetailTabFunctions } from "../Tabs/DetailTab/DetailTabFunctions";
// import { setAgencyTypeDataIState } from "../Tabs/DetailTab/DetailTabInterface";
// import { ButtonIState } from "../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { seteditComponent } from "../../../../../Redux/AgencyReducer";
// import { useGetPerticularUserPermission } from "../../../../../hooks/Permission/useGetPerticularUserPermission";

// export const Filter: React.FC<FilterIProps> = ({
//   Agencyname,
//   setAgencyname,
//   AgencyType,
//   setAgencyType,
//   agencyTypeDataalue,
//   setagencyTypeDataalue,
//   location,
//   setlocation,
//   Creationdatefrom,
//   Creationdateto,
//   setCreationdatefrom,
//   setCreationdateto,
// }) => {
//   const detailTabFunctions = new DetailTabFunctions();
//   const [agencyTypeData, setAgencyTypeData] = useState<
//     setAgencyTypeDataIState[]
//   >([]);
//   const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
//   const [buttonTextColor, setButtonTextColor] = useState<string>("black");

//   const { Create } =
//   useGetPerticularUserPermission("Agency");

//   useEffect(() => {
//     if ((Agencyname && Agencyname.trim().length > 0) || (agencyTypeDataalue && agencyTypeDataalue.trim().length > 0 ) || (location && location.trim().length >0 ) || (Creationdateto && Creationdateto.trim().length >0 ) || (Creationdatefrom && Creationdatefrom.trim().length >0 ) ) {
     
//       setIsAddButtonDisabled(false);
//       setButtonTextColor("red");
//     } else {
     
//       setIsAddButtonDisabled(true);
//       setButtonTextColor("black");
//     }
//   }, [Agencyname, agencyTypeDataalue,location,Creationdateto,Creationdatefrom]);


//   useEffect(() => {
//     detailTabFunctions.getAgencyTypeData(setAgencyTypeData);
//   }, []);

//   const clearAllFilters = () => {
//     setCreationdatefrom("");
//     setCreationdateto("");
//     setAgencyname("");
//     setlocation("");
//     setagencyTypeDataalue("");
//     setIsAddButtonDisabled(true);
//     setButtonTextColor("black");
//   };
//   const dispatch = useDispatch();

//   const navigation = useNavigate();

//   const NavigateToAddPage = () => {
//     dispatch(
//       seteditComponent({
//         editComponent: false,
//         addComponent: true,
//         viewComponent: false,
//       })
//     );
//     navigation("/agency/add");
//   };
//   return (
//     <>
//       <div className="Filter">
//         <div className="filds">
//           <TextBoxComponent
//             fieldName={"Agency name"}
//             value={Agencyname}
//             onChangeValue={setAgencyname}
//           />
//           <DropdownComponent
//             dataSource={agencyTypeData.map((state) => ({
//               id: state.SystemLevelCodeId,
//               value: state.LongCode,
//             }))}
//             fieldName="Agency Type"
//             handleRowClick={(newValue) => {
//               setagencyTypeDataalue(newValue);
//             }}
//             value={agencyTypeDataalue}
//           />

//           <TextBoxComponent
//             fieldName={"Location"}
//             value={location}
//             onChangeValue={setlocation}
//           />

//           <DateBoxComponents
//             fieldName={"Creation date from"}
//             value={Creationdatefrom}
//             onValueChange={setCreationdatefrom}
//           />
//           <DateBoxComponents
//             fieldName={"Creation date to"}
//             value={Creationdateto}
//             onValueChange={setCreationdateto}
//           />
//            <div className="clearbutton1">
//         <ButtonComponents
//               // icon={close}
//               name="Clear all Filters"
//               handleClick={clearAllFilters}
//               disabled={isAddButtonDisabled}
//               textColor={buttonTextColor}
//               borderColor={isAddButtonDisabled ? "grey" : "red"}
//             />
//         </div>
       
//         </div>
//         <div className="clearbutton">
//             {Create && (
//               <ButtonComponents
//                 handleClick={NavigateToAddPage}
//                 icon={add}
//                 name="Add new agency"
//                 showBackgroundColor={true}
//                 textColor="white"
//               />
//             )}
//           </div>
//       </div>
//     </>
//   );
// };


import React, { useEffect, useState } from "react";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
import close from "../../../../../assets/icons/close-circle.svg";
import "./Filter.css";
import add from "../../../../../assets/icons/Icon.svg";
import { FilterIProps } from "./FilterIProps";
import { DateBoxComponents } from "../../../../CommonComponents/Fields/DateBox/DateBox";
import { DetailTabFunctions } from "../Tabs/DetailTab/DetailTabFunctions";
import { setAgencyTypeDataIState } from "../Tabs/DetailTab/DetailTabInterface";
import { ButtonIState } from "../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { seteditComponent } from "../../../../../Redux/AgencyReducer";
import { useGetPerticularUserPermission } from "../../../../../hooks/Permission/useGetPerticularUserPermission";
import dayjs from "dayjs"; // Use dayjs for date comparison
import { DateDisableComponents } from "src/Components/CommonComponents/Fields/DateBox/DateDisable";

export const Filter: React.FC<FilterIProps> = ({
  Agencyname,
  setAgencyname,
  AgencyType,
  setAgencyType,
  agencyTypeDataalue,
  setagencyTypeDataalue,
  location,
  setlocation,
  Creationdatefrom,
  Creationdateto,
  setCreationdatefrom,
  setCreationdateto,
}) => {
  const detailTabFunctions = new DetailTabFunctions();
  const [agencyTypeData, setAgencyTypeData] = useState<
    setAgencyTypeDataIState[]
  >([]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [isToDateDisabled, setIsToDateDisabled] = useState<boolean>(true); // New state for disabling the "to" date

  const { Create } = useGetPerticularUserPermission("Agency");

  useEffect(() => {
    if (
      (Agencyname && Agencyname.trim().length > 0) ||
      (agencyTypeDataalue && agencyTypeDataalue.trim().length > 0) ||
      (location && location.trim().length > 0) ||
      (Creationdateto && Creationdateto.trim().length > 0) ||
      (Creationdatefrom && Creationdatefrom.trim().length > 0)
    ) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Agencyname, agencyTypeDataalue, location, Creationdateto, Creationdatefrom]);

  useEffect(() => {
    detailTabFunctions.getAgencyTypeData(setAgencyTypeData);
  }, []);

  useEffect(() => {
    // Disable "Creation date to" if "Creation date from" is not set
    setIsToDateDisabled(!Creationdatefrom) ;
    setCreationdateto("");
  }, [Creationdatefrom]);

  // Function to disable past dates in "Creation date to"
  const disableToDate = (currentDate: dayjs.Dayjs) => {
    if (!Creationdatefrom) {
      return false; // No date selected for "from", so no restriction
    }
    // Disable all dates before the "Creation date from"
    return currentDate && currentDate.isBefore(dayjs(Creationdatefrom, "MM-DD-YYYY"), 'day');
  };

  const clearAllFilters = () => {
    setCreationdatefrom("");
    setCreationdateto("");
    setAgencyname("");
    setlocation("");
    setagencyTypeDataalue("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const NavigateToAddPage = () => {
    dispatch(
      seteditComponent({
        editComponent: false,
        addComponent: true,
        viewComponent: false,
      })
    );
    navigation("/agency/add");
  };

  return (
    <>
      <div className="Filter">
        <div className="filds">
          <TextBoxComponent
            fieldName={"Agency name"}
            value={Agencyname}
            onChangeValue={setAgencyname}
          />
          <DropdownComponent
            dataSource={agencyTypeData.map((state) => ({
              id: state.SystemLevelCodeId,
              value: state.LongCode,
            }))}
            fieldName="Agency Type"
            handleRowClick={(newValue) => {
              setagencyTypeDataalue(newValue);
            }}
            value={agencyTypeDataalue}
          />

          <TextBoxComponent
            fieldName={"Location"}
            value={location}
            onChangeValue={setlocation}
          />

          <DateBoxComponents
            fieldName={"Creation date from"}
            value={Creationdatefrom}
            onValueChange={setCreationdatefrom}
          />
          <DateDisableComponents
            fieldName={"Creation date to"}
            value={Creationdateto}
            onValueChange={setCreationdateto}
            disabled={isToDateDisabled} // Disable based on "from" date
            disabledDates={disableToDate} // Disable past dates based on "from" date
          />
          <div className="clearbutton1">
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
          {Create && (
            <ButtonComponents
              handleClick={NavigateToAddPage}
              icon={add}
              name="Add new agency"
              showBackgroundColor={true}
              textColor="white"
            />
          )}
        </div>
      </div>
    </>
  );
};




