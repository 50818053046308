import { Fetcher } from "../../../../../../services/Fetcher";
import { successCreatedMessage, successPolicyAssign } from "../../../../../../utils/const";
import { setrolepoliciesIState } from "../../../../RolePermissionManagement/Roles/Components/Tab/RolePolicyTab/RolePolicyI";
import { SetTableData, setUserpolicyIState } from "./UserpolicyI";

const fetcher = new Fetcher();
export class UserpolicyFunction {
  async GetPolicyTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setFiltertableData: (data: SetTableData[]) => void,
    userId: string,
    setGetrolepoliciesDataLength: (data: number) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/policy`);
    const TableJson = this.createTableJson(response?.Data);

    this.GetrolepoliciesData(
      setLoading,
      userId,
      TableJson,
      setTableData,
      setFiltertableData,
      setGetrolepoliciesDataLength
    );
  }
  createTableJson(data: setrolepoliciesIState[]) {
    return data?.map((val: setrolepoliciesIState, i: number) => {
      return {
        "Policy Name": val.PolicyName,
        Description: val.Description,
        Status: val.IsActive ? "Active" : "InActive",
        ID: val.PolicyId,
        isSelected: false,
      };
    });
  }

  async GetrolepoliciesData(
    setLoading: (data: boolean) => void,
    userId: string,
    TableJson: SetTableData[],
    setTableData: (data: SetTableData[]) => void,
    setFiltertableData: (data: SetTableData[]) => void,
    setGetrolepoliciesDataLength: (data: number) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/user/${userId}/policy`);
    let data: setUserpolicyIState[] = response?.Data;
    setGetrolepoliciesDataLength(data.length);

    this.setdefaultCheck(data, TableJson, setTableData, setFiltertableData);
    setLoading(false);
  }

  async userpolicyAdd(
    setLoading: (data: boolean) => void,
    userId: string,
    RowCheckBox: { [key: string]: string | boolean | number | null }[]
  ) {
    setLoading(true);
    let response = await fetcher.post(
      `/user/${userId}/policy`,
      [...RowCheckBox],
      {
        message: successPolicyAssign,
        show: true,
      }
    );
    setLoading(false);
  }

  setdefaultCheck(
    rolepolicies: setUserpolicyIState[],
    tableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void,
    setFiltertableData: (data: SetTableData[]) => void
  ) {
    let defaultCheck: SetTableData[] = [];
    for (let i = 0; i < tableData.length; i++) {
      let temp2 = true;
      for (let j = 0; j < rolepolicies.length; j++) {
        if (rolepolicies[j]?.PolicyId == tableData[i]?.ID) {
          defaultCheck.push({ ...tableData[i], isSelected: true });
          temp2 = false;
          break;
        }
      }
      if (temp2) {
        defaultCheck.push({ ...tableData[i], isSelected: false });
      }
    }

    let finalTableData = defaultCheck.sort((a, b) => {
      const aIsSelected = Number(a.isSelected);
      const bIsSelected = Number(b.isSelected);
      return bIsSelected - aIsSelected;
    });

    setTableData(finalTableData);
    setFiltertableData(finalTableData);
  }

  applyFilterOnUserpolicyTable(
    StatusFilter: string,
    policyNameFilter: string,
    FiltertableData: SetTableData[],
    setTableData: (data: SetTableData[]) => void
  ) {
    let filteredData: SetTableData[] = FiltertableData;
    let status: string = StatusFilter == "1" ? "active" : "inactive";
    if (policyNameFilter) {
      filteredData = FiltertableData.filter((item) => {
        return (
          item["Policy Name"] &&
          item["Policy Name"]
            .toLowerCase()
            .includes(policyNameFilter.toLowerCase().trim())
        );
      });
    }
    if (StatusFilter) {
      filteredData = filteredData.filter((item) => {
        return item["Status"].toLowerCase() == status;
      });
    }
    setTableData(filteredData);
  }

  selectRowCheckBox(
    FiltertableData: SetTableData[],
    data: { [key: string]: string | number | boolean | null }[],
    setFiltertableData: (data: SetTableData[]) => void,
    oneTimeRender: boolean,
    GetrolepoliciesDataLength: number,
    setChangeValue: (data: boolean) => void,
    setoneTimeRender: (data: boolean) => void,
    tableData: SetTableData[]
  ) {
    let updatedTableData: SetTableData[] = tableData.map((item) => {
      const isSelected = data.some(
        (selectedItem) => selectedItem?.ID === item?.ID
      );
      return { ...item, isSelected };
    });

    let updatedTableData2: SetTableData[] = FiltertableData.map((item) => {
      const isSelected = updatedTableData.find((selectedItem) => {
        if (selectedItem?.ID === item?.ID) {
          return selectedItem;
        }
      });
      if (isSelected) {
        return { ...item, isSelected: isSelected.isSelected };
      } else {
        return { ...item };
      }
    });

    setFiltertableData(updatedTableData2);

    if (oneTimeRender) {
      const count = updatedTableData2.filter((item) => item.isSelected).length;
      if (GetrolepoliciesDataLength !== count) {
        setChangeValue(true);
        setoneTimeRender(false);
      }
    }
  }
}
