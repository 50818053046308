
// import close from "../../../../../../assets/icons/close-circle.svg";
import { FilterIProps } from "./FilterI";
import { useEffect, useState } from "react";
//import { AgencyLevelAddEditViewFunction } from "../AgencyLevelAddEditView/AgencyLevelAddEditViewFunction";
//import { codecategory } from "../AgencyLevelAddEditView/AgencyLevelAddEditViewI";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
import { codecategory } from "../NCICII";
import { NCICFunction } from "../NCICFunction";
import { ButtonIState } from "../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";
//import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const Filter: React.FC<FilterIProps> = ({
  AgencyLevelCode,
  Category,
  setAgencyLevelCode,
  setCategory,
}) => {
  //const { Create} =useGetPerticularUserPermission("Code.System");
  const agencyLevelAddEditViewFunction = new NCICFunction();
  const [categoryData, setcategoryData] = useState<codecategory[]>([]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  useEffect(() => {
    if ((AgencyLevelCode && AgencyLevelCode.trim().length > 0) || (Category && Category.trim().length >0 )) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [AgencyLevelCode, Category]);

 
  useEffect(() => {
    agencyLevelAddEditViewFunction.getallcodecategory(setcategoryData)
  }, []);
  console.log(categoryData);
  const clearAllFilters = () => {
    setAgencyLevelCode("");
    setCategory("");
    setIsAddButtonDisabled(true);
      setButtonTextColor("black");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds_">
          <TextBoxComponent
            fieldName="Search NCIC Code"
            value={AgencyLevelCode}
            onChangeValue={setAgencyLevelCode}
          />
          <DropdownComponent
            fieldName="Category"
            dataSource={categoryData.map((state) => ({
              id: state.CodeCategoryId,
              value: state.CategoryName,
            }))}
            handleRowClick={(newValue) => {
              setCategory(newValue);
            }}
            //handleRowClick={setCategory}
            value={Category}
          />


          {/* <DropdownComponent
            fieldName="Status"
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            handleRowClick={setStatus}
            value={Status}
            allowclear={false}
          /> */}
          <div className="clearbutton1">
          <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
            
        </div>
        <div className="clearbutton">
      
{/* {Create && (
 <ButtonComponents
 name="Add Agency Level Code"
 icon={add}
 handleClick={goToAdd}
/>
)} */}
         
        </div>
      </div>
    </>
  );
};
