import { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Submenu } from "./MenuI";
import { MenuFunction } from "./MenuFunction";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { Submit } from "../../../../CommonComponents/Fields/Submit/Submit";
import { useDispatch, useSelector } from "react-redux";
import { setrefreshIncidenceMenuList } from "../../../../../Redux/CommonRedux";
import { RootState } from "../../../../../store";

const reorder = (
  list: Submenu[],
  startIndex: number,
  endIndex: number
): Submenu[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const Menu = () => {
  const { refreshIncidenceMenuList } = useSelector(
    (store: RootState) => store.CommonRedux
  );
  const configurationFunction = new MenuFunction();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [DynamicSubMenuList, setDynamicSubMenuList] = useState<Submenu[]>([]);

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;

    const reorderedList = reorder(
      DynamicSubMenuList,
      source.index,
      destination.index
    );
    setDynamicSubMenuList(reorderedList);
  };

  const initialRender = () => {
    configurationFunction.getConfigurationData(
      setLoading,
      setDynamicSubMenuList
    );
  };

  const onCancel = () => {
    configurationFunction.getConfigurationData(
      setLoading,
      setDynamicSubMenuList
    );
  };

  const handleSubmit = async () => {
    await configurationFunction.saveOrderConfigurationData(
      setLoading,
      DynamicSubMenuList.map((val, i) => {
        return {
          MenuId: val.MenuId,
          Order: i + 1,
        };
      })
    );
    dispatch(setrefreshIncidenceMenuList(!refreshIncidenceMenuList));
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="Manumain">
        <div className="DetailTab_top">
          <div className="DetailTab_top_header_">
            <div className="DetailTab_top_header_sub">
              <p>Menu</p>
            </div>
          </div>
          <div className="DetailTab_contant_">
            <div className="Configuration_menu">
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {DynamicSubMenuList.map(
                        (DynamicSubMenuListval, index) => (
                          <Draggable
                            key={DynamicSubMenuListval.id}
                            draggableId={String(DynamicSubMenuListval.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`Configuration_list ${
                                  snapshot.isDragging ? "dragging" : ""
                                }`}
                              >
                                <p>{DynamicSubMenuListval.title}</p>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>

        <div className="edit-button">
          <Submit onSave={handleSubmit} onCancel={onCancel} />
        </div>
      </div>
    </>
  );
};
