import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Short Code" },
    { field: "Long Code" },
    { field: "Category" },
    { field: "Description" }
  ];
};
