import { Fetcher } from "../../../../../services/Fetcher";
import { locationCategory } from "./AddLocationFormI";

const fetcher = new Fetcher();

export class AddLocationFormFunction {
  getLocationDropdown = async (
    setLocationCategoryList: (data: locationCategory[]) => void
  ) => {
    const response = await fetcher.get(`/code/ncic/category/LocationType`);
    setLocationCategoryList(response?.Data);
  };
}
