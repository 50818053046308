import moment from "moment-timezone";

export const getCurrentDate = (): string => {
  const today = new Date();
  let month: number | string = today.getMonth() + 1;
  let day: number | string = today.getDate();
  const year: number = today.getFullYear();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return `${month}-${day}-${year}`;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();
  const year: number = date.getFullYear();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return `${month}-${day}-${year}`;
};

export const formatMonth = (dateString: string): string => {
  const date = new Date(dateString);

  let month: number | string = date.getMonth() + 1;
  const year: number = date.getFullYear();
  if (month < 10) {
    month = "0" + month;
  }

  return `${month}-${year}`;
};

export const formatTime = (dateString: string): string => {
  const date = new Date(dateString);

  let hours: number | string = date.getHours();
  let minutes: number | string = date.getMinutes();
  let seconds: number | string = date.getSeconds();

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return `${hours}:${minutes}:${seconds}`;
};
export const formatToMMYYYY = (date: string) => {
  if (!date) return "";

  const formattedDate = new Date(date);
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-based
  const year = formattedDate.getFullYear();

  return `${month}/${year}`;
};

export const convertToISOFormat = (dateString: string): string => {
  const [month, day, year] = dateString
    .split("-")
    .map((part) => part.padStart(2, "0"));

  const date = `${year}-${month}-${day}T18:30:00`;

  return date;
};

export const convertToISOFormatMonth = (dateString: string): string => {
  const parts = dateString.split("-");
  if (parts.length < 2) {
    throw new Error("Invalid date format. Expected MM-YYYY.");
  }
  const month = parts[0].padStart(2, "0");
  const year = parts[1].padStart(4, "0");

  const formattedDate = `${year}-${month}-01T00:00:00`;

  return formattedDate;
};

export const convertToISOFormatDateOnly = (dateString: string): string => {
  const [month, day, year] = dateString
    .split("-")
    .map((part) => part.padStart(2, "0"));

  const date = `${year}-${month}-${day}`;

  return date;
};

export const ConvertToLocalDateFormate = (date: string) => {
  return moment.utc(date).local().format("MM-DD-YYYY HH:mm:ss");
};

export const formatDatewithTime = (dateString: string): string => {
  const date = new Date(dateString);
  const pad = (num: number): string => num.toString().padStart(2, "0");

  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const year = date.getFullYear();

  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
};

export const isValidDate = (input: string | number | boolean): boolean => {
  if (typeof input == "string") {
    const date = new Date(input);
    if (isNaN(date.getTime())) {
      return false;
    }
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T/;
    if (iso8601Regex.test(input)) {
      return true;
    }
    return false;
  } else {
    return false;
  }
};

export function combineDateTimeToISO(
  dateString: string,
  timeString: string
): string {
  console.log(dateString, timeString);
  const [month, day, year] = dateString.split("-").map(Number);
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a new Date object using the extracted components
  const date = new Date(year, month - 1, day, hours, minutes, seconds);

  // Return the date in ISO format
  return (
    date.toISOString().split("T")[0] + "T" + date.toTimeString().split(" ")[0]
  );
}

export function parseISOToDateTime(isoString: string): {
  date: string;
  time: string;
} {
  let [hours, minutes, seconds] = isoString?.split("T")[1].split(":");
  const date = new Date(isoString);

  // Extract the components
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // Months are zero-based
  const day = date.getUTCDate();

  // Format components to ensure two digits where necessary
  const formattedDate = `${String(month).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")}-${year}`;
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return { date: formattedDate, time: formattedTime };
}

export const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const generateId = () => {
  const timestamp = Date.now().toString(36);
  const randomNum = Math.random().toString(36).substring(2, 8);
  return `${timestamp}-${randomNum}`;
};

export const capitaliseLetter = (name: string) => {
  if (name && name.trim()) {
    return name.trim().charAt(0).toUpperCase() + name.trim().slice(1);
  }
  return "";
};
