import { useEffect, useState } from "react";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { Table } from "../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { SetTableData } from "./ParticipantI";
import { useNavigate, useParams } from "react-router-dom";
import { Filter } from "./Components/filters/filters";
import { ParticipantListFunction } from "./ParticipantListFunction";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { ShowAddressPariticipant } from "../../../CommonComponents/Modal/ShowAddressPariticipant/ShowAddressPariticipant";
import { InvolvementMainIParams } from "./AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
import { useGetPerticularUserPermission } from "src/hooks/Permission/useGetPerticularUserPermission";

export const ParticipantList: React.FC = () => {
  const participantListFunction = new ParticipantListFunction();
  const { Delete, Update } = useGetPerticularUserPermission(
    "Incident.Participant"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [showAddresPopup, setshowAddresPopup] = useState<boolean>(false);
  const [showAddresPopupId, setshowAddresPopupId] = useState<string | null>("");
  const [ParticipantType, setParticipantType] = useState<string>("1");
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Last Name");
  const [Name, setName] = useState<string>("");
  const [involvementType, setInvolvementType] = useState<string>("");
  const navigation = useNavigate();
  const { incidentId } = useParams<InvolvementMainIParams>();
  let incidentNumber = localStorage.getItem("Incidentnumber");

  const AddNewName = () => {
    navigation(`/incidents/${incidentId}/participants/add`);
  };

  const edit = (e: SetTableData) => {
    if (e.EntityType == "Business") {
      navigation(
        `/incidents/${incidentId}/businessparticipant/${e.ParticipantId}/edit`
      );
    } else {
      navigation(
        `/incidents/${incidentId}/participants/${e.ParticipantId}/edit`
      );
    }
  };

  const getParticipantListTableData = (resetPagination?: boolean) => {
    if (incidentId) {
      participantListFunction.getParticipantListTableData(
        setTableData,
        resetPagination ? 1 : pageNumber,
        resetPagination ? 10 : pageCount,
        setLoading,
        setTableTotalRecord,
        Name,
        involvementType,
        ParticipantType,
        incidentId,
        OrderByDescending,
        OrderBy
      );
      if (resetPagination) {
        setpageNumber(1);
        setpageCount(10);
      }
    }
  };

  const clearAllFilter = () => {
    if (incidentId) {
      participantListFunction.getParticipantListTableData(
        setTableData,
        pageNumber,
        pageCount,
        setLoading,
        setTableTotalRecord,
        "",
        "",
        "1",
        incidentId,
        OrderByDescending,
        OrderBy
      );
      setpageNumber(1);
      setpageCount(10);
    }
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
    setDeleteUserId(null);
  };

  const closeaddressPopup = () => {
    setshowAddresPopup(false);
    setshowAddresPopupId(null);
  };

  const handleDelete = () => {
    if (deleteUserId && incidentId) {
      participantListFunction.deleteUser(
        deleteUserId,
        incidentId,
        setLoading,
        getParticipantListTableData
      );
      closeDeletePopup();
    }
  };

  const scrollToTopHandler = () => {
    if (loading) {
      window.scroll({ top: 0 });
    }
  };

  const onRowClicked = (e: SetTableData) => {
    if (!deleteUserId && !showAddresPopupId) {
      if (e.EntityType == "Business") {
        navigation(
          `/incidents/${incidentId}/businessparticipant/${e.ParticipantId}/overview`
        );
      } else {
        navigation(
          `/incidents/${incidentId}/participants/${e.ParticipantId}/overview`
        );
      }
    }
  };

  const initialData = () => {
    getParticipantListTableData();
  };
  useEffect(() => {
    scrollToTopHandler();
    initialData();
  }, [pageNumber, pageCount, OrderByDescending]);

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />

      <ModalComponent
        open={showAddresPopup}
        title="Address"
        width={900}
        // height={200}
        onClose={closeaddressPopup}
        innerContant={
          <ShowAddressPariticipant
            closeaddressPopup={closeaddressPopup}
            participantId={showAddresPopupId}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />

      <div className="UserManagement">
        <div>
          <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path: "/incidents" },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              {
                title: "Participants",
                path: `/incidents/${incidentId}/participants`,
              },
            ]}
          />

          <p className="header">List Of Participants</p>
          <Filter
            involvementType={involvementType}
            setInvolvementType={setInvolvementType}
            AddNewName={AddNewName}
            Name={Name}
            setName={setName}
            ParticipantType={ParticipantType}
            setParticipantType={setParticipantType}
            onSearch={getParticipantListTableData}
            clearAllFilter={clearAllFilter}
          />

          <div className="table">
            <Table
              columns={coloum(
                setdeletePopup,
                setDeleteUserId,
                edit,
                setshowAddresPopup,
                setshowAddresPopupId,
                Delete,
                Update
              )}
              columnsValue={tableData}
              cursorPointer={true}
              setpageCount={setpageCount}
              setpageNumber={setpageNumber}
              onRowClicked={onRowClicked}
              tableTotalRecord={tableTotalRecord}
              setOrderByDescending={setOrderByDescending}
              OrderByDescending={OrderByDescending}
              setOrderBy={setOrderBy}
              OrderBy={OrderBy}
              pageNumber={pageNumber}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </>
  );
};
