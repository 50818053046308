import React, { useEffect, useState } from "react";
import "./Overview.css";
import { OverviewTableData } from "../ParticipantOverviewIState";
import { OverviewFunction } from "./OverviewFunction";
import { useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";

export const OverviewTab: React.FC = () => {
  const overviewFunction = new OverviewFunction();
  const [Loading, setLoading] = useState<boolean>(false);
  const [fields, setfields] = useState<OverviewTableData>({
    Age: "",
    DOB: "",
    FirstName: "",
    LastName: "",
    MiddleName: "",
    Eye: "",
    FacialHair: "",
    Glasses: "",
    Hair: "",
    HGT: "",
    Race: "",
    Sex: "",
    Skintone: "",
    WGT: 0,
  });
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const initialRender = () => {
    if (incidentId && participantId) {
      overviewFunction.getInvolvement(
        setLoading,
        incidentId,
        participantId,
        setfields,
        fields
      );
    }
  };
  useEffect(() => {
    initialRender();
  }, []);

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Overview</strong>
          </p>
        </div>

        <div
          className="overview-content"
          style={{ height: "auto", lineHeight: "15px" }}
        >
          <div className="overview-row">
            <p>
              Last Name: <strong>{fields?.LastName}</strong>
            </p>
            <p>
              First Name: <strong>{fields?.FirstName}</strong>
            </p>
            <p>
              Middle Name: <strong>{fields?.MiddleName}</strong>
            </p>
            <p>
              DOB: <strong>{fields?.DOB ? formatDate(fields.DOB) : ""}</strong>
            </p>

            <p>
              Age: <strong>{fields?.Age}</strong>
            </p>
          </div>
          <div style={{ lineHeight: "1px" }}>
            <p>Last Entered Demographics:</p>
            <div className="overview-row">
              <p>
                Sex: <strong>{fields?.Sex}</strong>
              </p>
              <p>
                Race: <strong>{fields?.Race}</strong>
              </p>
              <p>
                Eye: <strong>{fields?.Eye}</strong>
              </p>
              <p>
                Hair: <strong>{fields?.Hair}</strong>
              </p>
              <p>
                Facial Hair: <strong>{fields?.FacialHair}</strong>
              </p>

              <p>
                Glasses: <strong>{fields?.Glasses}</strong>
              </p>

              <p>
                Skintone: <strong>{fields?.Skintone}</strong>
              </p>

              <p>
                HGT: <strong>{fields?.HGT}</strong>
              </p>

              <p>
                WGT: <strong>{fields?.WGT}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
