import { useEffect, useState } from "react";
import { CheckBoxComponent } from "../../../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";

import { MultipleAddressIProps } from "./EmployementAddressIState";
import { InvolvementMainFunction } from "../../../../../Involvement/InvolvementMainFunction";
import { GetRaceDataIState } from "../../../../../ExternalContact/ExternalContactI";

export const EmployementAddress: React.FC<MultipleAddressIProps> = ({
  Mainfields,
  setMainfields,
  Employmentval,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [StateCode, setStateCode] = useState<GetRaceDataIState[]>([]);

  const onChangeValue = (
    name: string,
    value: string | number | boolean | null,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes: true,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            if (key2 && value2) {
              return {
                ...val,
                Address: {
                  ...val.Address,
                  [name]: value,
                  [key2]: value2,
                },
              };
            } else {
              return {
                ...val,
                Address: {
                  ...val.Address,
                  [name]: value,
                },
              };
            }
          } else {
            return val;
          }
        }),
      },
    });
  };

  const initialRender = () => {
    involvementMainFunction.getAddresType(setRaceDataData);
    involvementMainFunction.getStreetPrefix(setStreetPrefix);
    involvementMainFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setStateCode);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <p className="subheader_text">Employement Address</p>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <DropdownComponent
              value={Employmentval.Address.AddressType}
              handleRowClick={(e) => {
                onChangeValue("AddressType", e);
              }}
              dataSource={RaceDataData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Address Type"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <CheckBoxComponent
                value={
                  Mainfields ? Employmentval.Address.NoPermanentAddress : false
                }
                fieldName="No Permanent Address"
                onChangeValue={(e) => {
                  onChangeValue("NoPermanentAddress", e);
                }}
              />
              <CheckBoxComponent
                value={
                  Mainfields ? Employmentval.Address.UnknownAddress : false
                }
                fieldName="Unknown Address"
                onChangeValue={(e) => {
                  onChangeValue("UnknownAddress", e);
                }}
              />

              <CheckBoxComponent
                value={Employmentval.Address.ForeignAddress}
                fieldName="Foreign Address"
                onChangeValue={(e) => {
                  onChangeValue(
                    "ForeignAddress",
                    !Employmentval.Address.ForeignAddress
                  );
                }}
              />
            </div>
          </div>
          {Employmentval.Address.ForeignAddress ? (
            <>
              <div style={{ width: "70%" }}>
                <TextBoxComponent
                  value={Employmentval.Address.AddressLine1}
                  onChangeValue={(e) => {
                    onChangeValue("AddressLine1", e);
                  }}
                  fieldName="Address Line 1: Street address, P.O box, company name, c/o"
                />
                <TextBoxComponent
                  value={Employmentval.Address.AddressLine2}
                  onChangeValue={(e) => {
                    onChangeValue("AddressLine2", e);
                  }}
                  fieldName="Address Line 2: Apartment, suite, unit, building, floor, etc"
                />
              </div>
              <div className="Involvement_left_row">
                <TextBoxComponent
                  value={Employmentval.Address.ForeignAddressCity}
                  onChangeValue={(e) => {
                    onChangeValue("ForeignAddressCity", e);
                  }}
                  fieldName="City"
                />
                <TextBoxComponent
                  value={
                    Employmentval.Address.ForeignAddressStateProvinceRegion
                  }
                  onChangeValue={(e) => {
                    onChangeValue("ForeignAddressStateProvinceRegion", e);
                  }}
                  fieldName="State / Province/Region"
                />
                <TextBoxComponent
                  value={Employmentval.Address.ForeignAddressZip}
                  onChangeValue={(e) => {
                    onChangeValue("ForeignAddressZip", e);
                  }}
                  fieldName="Zip / postal Code"
                />
              </div>
              <div style={{ width: "40%" }}>
                {/* <DropdownComponent
                  value={Employmentval.Address.ForeignAddressCountry}
                  handleRowClick={(e) => {
                    onChangeValue("ForeignAddressCountry", e);
                  }}
                  dataSource={Country.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                  fieldName="Country"
                /> */}

            <TextBoxComponent
             value={Employmentval.Address.ForeignAddressCountry}
              onChangeValue={(e) => {
                onChangeValue(
                  "ForeignAddressCountry",
                  e,
                );
              }}
              fieldName="Country"
            />
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="involvemt_street_inline_container">
                <div className="Involvement_left_row">
                  <div className="_involvement_meta_info">
                    <TextBoxComponent
                      value={Employmentval.Address.StreetNumber}
                      onChangeValue={(e) => {
                        onChangeValue("StreetNumber", e);
                      }}
                      fieldName="Street Number"
                    />
                  </div>

                  <div className="_involvement_meta_info">
                    <DropdownComponent
                      handleRowClick={(e) => {
                        onChangeValue("StreetPrefix", e);
                      }}
                      value={Employmentval.Address.StreetPrefix}
                      dataSource={StreetPrefix.map((val) => {
                        return {
                          id: val.NciccodeId,
                          value: val.LongCode,
                        };
                      })}
                      fieldName="Street Prefix"
                    />
                  </div>
                </div>
                <div className="Involvement_left_row">
                  <div className="involvemet_street_container">
                    <TextBoxComponent
                      onChangeValue={(e) => {
                        onChangeValue("Street", e);
                      }}
                      value={Employmentval.Address.Street}
                      fieldName="Street"
                    />
                  </div>

                  <div className="_involvement_meta_info">
                    <DropdownComponent
                      value={Employmentval.Address.StreetSuffix}
                      handleRowClick={(e) => {
                        onChangeValue("StreetSuffix", e);
                      }}
                      dataSource={StreetSuffix.map((val) => {
                        return {
                          id: val.NciccodeId,
                          value: val.LongCode,
                        };
                      })}
                      fieldName="Street Suffix"
                    />
                  </div>
                  <div className="_involvement_meta_info">
                    <TextBoxComponent
                      value={Employmentval.Address.Apartment}
                      onChangeValue={(e) => {
                        onChangeValue("Apartment", e);
                      }}
                      fieldName="Apartment"
                    />
                  </div>
                </div>
              </div>
              {/* from here the nexr div for city starts */}
              <div className=" address_city_address_container">
                <div className="_address_state_providence">
                  <TextBoxComponent
                    value={Employmentval.Address.City}
                    fieldName="City"
                    onChangeValue={(e) => {
                      onChangeValue("City", e);
                    }}
                  />
                </div>

                <div className="_address_state_providence">
                  <DropdownComponent
                    value={Employmentval.Address.StateProvidence}
                    dataSource={StateCode.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    handleRowClick={(e) => {
                      onChangeValue("StateProvidence", e);
                    }}
                    fieldName="State / Providence"
                  />
                </div>

                <div className="_address_zip_code">
                  <TextBoxComponent
                    value={Employmentval.Address.ZipCode}
                    onChangeValue={(e) => {
                      onChangeValue("ZipCode", e);
                    }}
                    fieldName="Zip Code"
                  />
                </div>
              </div>
              <div className="country_grid_container">
                <div className="_address_country_container">
                  <DropdownComponent
                    value={Employmentval.Address.Country}
                    handleRowClick={(e, e2) => {
                      onChangeValue("Country", e, "CountryName", e2);
                    }}
                    dataSource={Country.map((val) => {
                      return {
                        id: val.NciccodeId,
                        value: val.LongCode,
                      };
                    })}
                    fieldName="Country"
                  />
                </div>

                <div className="_address_grid_number">
                  <TextBoxComponent
                    value={Employmentval.Address.GridNumber}
                    onChangeValue={(e) => {
                      const value = e;
                      if (/^\d{0,5}$/.test(value)) {
                        onChangeValue(
                          "GridNumber",
                          value,
                        );
                      }
                    }}
                    
                    fieldName="Grid Number"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
