import add from "../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";

import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { validateEmail } from "../../../../../../../../../utils/Helper/Helper";
import {
  EmailAddressDataUIProps,
  EmailAddressIProps,
} from "../../InvolvementMainI";
import { PhoneNumberFunction } from "../../../../AddNewName/ExternalContact/Components/Phone/PhoneNumberFunction";
import { setContactTypeDataIState } from "../../../../AddNewName/ExternalContact/ExternalContactI";
import { Add } from "../../../../../../../../../assets";

export const EmailAddressDataUI: React.FC<EmailAddressDataUIProps> = ({
  EmailAddressval,
  MainfieldsBusiness,
  setMainfieldsBusiness,
}) => {
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);
  const phoneNumberFunction = new PhoneNumberFunction();
  const [validationMessage, setValidationMessage] = useState<string>("");

  const onChangeValue = (key: string, value: string, id: number) => {
    if (key === "Email" && !validateEmail(value)) {
      setValidationMessage("Please enter a valid email address");
    } else {
      setValidationMessage("");
    }

    setMainfieldsBusiness({
      ...MainfieldsBusiness,
      Involvement: {
        ...MainfieldsBusiness.Involvement,
        Email: MainfieldsBusiness.Involvement.Email.map((val) => {
          if (val.id == id) {
            return { ...val, [key]: value };
          } else {
            return val;
          }
        }),
      },
    });
  };

  const remove = (id: number) => {
    if (MainfieldsBusiness.Involvement.Email.length > 1) {
      setMainfieldsBusiness({
        ...MainfieldsBusiness,
        Involvement: {
          ...MainfieldsBusiness.Involvement,
          Email: MainfieldsBusiness.Involvement.Email.filter((val) => {
            if (val.id != id) {
              return val;
            }
          }),
        },
      });
    }
  };

  const initialRender = () => {
    phoneNumberFunction.getContactTypeData(setContactTypeData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div className="MultiplePhoneNumber">
        <DropdownComponent
          dataSource={ContactTypeData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          fieldName="Type"
          value={EmailAddressval.Type}
          handleRowClick={(e) => {
            onChangeValue("Type", e, EmailAddressval.id);
          }}
        />
        <div className="width_size">
          <TextBoxComponent
            value={EmailAddressval.Email}
            fieldName="Email Address"
            onChangeValue={(e) => {
              onChangeValue("Email", e, EmailAddressval.id);
            }}
          />
          {validationMessage && (
            <div style={{ color: "red", fontSize: "13px" }}>
              {validationMessage}
            </div>
          )}
        </div>
        {MainfieldsBusiness.Involvement.Email.length > 1 && (
          <p
            className="removeIcon"
            onClick={() => {
              remove(EmailAddressval.id);
            }}
            title="Remove"
          >
            -
          </p>
        )}
      </div>
    </>
  );
};

export const EmailAddress: React.FC<EmailAddressIProps> = ({
  MainfieldsBusiness,
  setMainfieldsBusiness,
}) => {
  const handleClick = () => {
    setMainfieldsBusiness({
      ...MainfieldsBusiness,
      Involvement: {
        ...MainfieldsBusiness.Involvement,
        Email: [
          ...MainfieldsBusiness.Involvement.Email,
          {
            id: MainfieldsBusiness.Involvement.Email.length + 1,
            Email: "",
            ExternalContactID: "",
            TypeDropdownvalue: "",
            Type: "",
          },
        ],
      },
    });
  };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_">Email Address</p>
            {MainfieldsBusiness.Involvement.Email.map((EmailAddressval) => {
              return (
                <>
                  <EmailAddressDataUI
                    MainfieldsBusiness={MainfieldsBusiness}
                    setMainfieldsBusiness={setMainfieldsBusiness}
                    EmailAddressval={EmailAddressval}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="edit-button_" style={{ marginTop: "5%" }}>
          <ButtonComponents
            name="Add Email Address"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
