import { useEffect, useState } from "react";
import { SetTableData, setUserpolicyIState } from "./UserpolicyI";
import { ListPolicyFunction } from "../../../../RolePermissionManagement/Policy/ListPolicyManagement/ListPolicyFunction";
import { UserpolicyFunction } from "./UserpolicyFunction";
import { useParams } from "react-router-dom";
import { EditUserManagementIParams } from "../../../EditUserManagement/EditUserManagementI";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { Filter } from "./Components/Filter";
import useDelayedEffect from "../../../../../../hooks/useDelayedEffect";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const Userpolicy = () => {
  const getPolicyFunction = new ListPolicyFunction();
  const { Update } = useGetPerticularUserPermission("User");
  const userpolicyFunction = new UserpolicyFunction();
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [StatusFilter, setStatusFilter] = useState<string>("1");
  const [policyNameFilter, setpolicyNameFilter] = useState<string>("");
  const [changeValue, setChangeValue] = useState<boolean>(false);
  const [oneTimeRender, setoneTimeRender] = useState<boolean>(true);
  const [GetrolepoliciesDataLength, setGetrolepoliciesDataLength] =
    useState<number>(0);

  const { userId } = useParams<EditUserManagementIParams>();

  const selectRowCheckBox = (
    data: { [key: string]: string | number | boolean | null }[]
  ) => {
    userpolicyFunction.selectRowCheckBox(
      FiltertableData,
      data,
      setFiltertableData,
      oneTimeRender,
      GetrolepoliciesDataLength,
      setChangeValue,
      setoneTimeRender,
      tableData
    );
  };
  const GetPolicyTableData = async () => {
    if (userId) {
      await userpolicyFunction.GetPolicyTableData(
        setTableData,
        setLoading,
        setFiltertableData,
        userId,
        setGetrolepoliciesDataLength
      );
    }
  };

  const initialData = async () => {
    await GetPolicyTableData();
  };

  const applyFilterOnUserpolicyTable = () => {
    userpolicyFunction.applyFilterOnUserpolicyTable(
      StatusFilter,
      policyNameFilter,
      FiltertableData,
      setTableData
    );
  };

  const onSave = () => {
    if (userId) {
      let body: { PolicyId: string }[] = [];
      FiltertableData.map((val) => {
        if (val.isSelected) {
          body.push({ PolicyId: val.ID });
        }
      });
      userpolicyFunction.userpolicyAdd(setLoading, userId, body);
    }
  };

  const onCancel = () => {
    initialData();
  };

  useEffect(() => {
    initialData();
  }, []);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [
    policyNameFilter,
    StatusFilter,
  ]);

  return (
    <>
      <Loader loading={loading} />
      <Filter
        policyNameFilter={policyNameFilter}
        setpolicyNameFilter={setpolicyNameFilter}
        setStatusFilter={setStatusFilter}
        StatusFilter={StatusFilter}
      />
      <div className="table">
        <Table
          columns={coloum(Update)}
          columnsValue={tableData}
          clientSideSorting={true}
          showPagination={false}
          selectRowCheckBox={selectRowCheckBox}
          allowMultipleRowSelection={true}
           
              
        />
        {Update && changeValue ? (
          <div className="edit-button">
            <Submit onCancel={onCancel} onSave={onSave} />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
