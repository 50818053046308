import { useEffect, useState } from "react";
import { GetRaceDataIState } from "../../../Involvement/InvolvementMainI";
import { AdditionalMainFunction } from "../../AdditionalFunction";
import { AdditionalInfoProps, GetLanguageDataI } from "../../AdditionalIState";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { MultiDropdownComponent } from "../../../../../../../../CommonComponents/Fields/MultiDropdown/MultiDropdownComponent";

export const Language: React.FC<AdditionalInfoProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [LanguageData, setLanguageData] = useState<GetLanguageDataI[]>([]);
  const onChangeValue = (name: string, value: string[] | boolean) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes:true,
        Language: {
          ...Mainfields.AdditionalInfo.Language,
          [name]: value,
        },
      },
    });
  };
  const initialRender = () => {
    additionalMainFunction.getLanguageData(setLanguageData);
  };

  useEffect(() => {
    initialRender();
  }, []);
  return (
    <>
      <div className="width_field">
        <p className="subheader">Language</p>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <div className="">
              <CheckBoxComponent
                fieldName="Limited English Proficiency"
                onChangeValue={(e) => {
                  onChangeValue("LimitedEnglishProficiency", e);
                }}
                value={
                  Mainfields.AdditionalInfo.Language.LimitedEnglishProficiency
                }
              />
              <div className="width_field">
                <MultiDropdownComponent
                  value={Mainfields.AdditionalInfo.Language.Language}
                  handleRowClick={(e) => {
                    onChangeValue("Language", e);
                  }}
                  fieldName="Language"
                  dataSource={LanguageData.map((val) => {
                    return {
                      id: val.NciccodeId,
                      value: val.LongCode,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
