import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { MasterMainIParams, OverviewData } from "../../MasterOverview/MasterNameIState";

import { OverviewMainFunction } from "../../MasterOverview/Components/Overview/OverviewMainFunction";
import { MasterBusinessTables } from "./MasterBusinessOverview";
import { BusinessData } from "./Components/BusinessOverview/BusinessIState";
import { BusinessFunction } from "./Components/BusinessOverview/BusinessFunction";

export const MasterBusinessOverview: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const navigation = useNavigate();
  const NavigateToUserPage = () => {
    navigation("/master");
  };
  const { nameId } = useParams<MasterMainIParams>();
  const businessfunction=new BusinessFunction();
  const [fields, setFields] = useState<BusinessData>({BusinessName:"",BusinessType:"",InvolvementType:"",
  Address:"",Case:"",
  });
  const initialRender = () => {
    if (nameId) {
      businessfunction.getBusinessData(
        setLoading,
        setFields,
        fields,nameId
      );
    }
  };
  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="AddMasterName_main">
        <div className="AddMasterName_child">
          <BackButtonComponent
            name="Back to name lookup"
            onClick={NavigateToUserPage}
          />
          <p className="header" style={{ marginBottom: "0%" }}>
           Business Name: {fields?.BusinessName}
           
          </p>
          <div>
          <MasterBusinessTables/>
          </div>
        </div>
      </div>
    </>
  );
};
