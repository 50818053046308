
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Case" },
    { field: "SMT/Other" },
    { field: "Type/Location" },
   
   
  ];
};
