import React from 'react';
import printer from '../../../../../../assets/icons/printer.svg';
import share from '../../../../../../assets/icons/share.svg';
import dots from '../../../../../../assets/icons/Dots_Menu_Icon_UIA.svg';

const CommonIcons: React.FC = () => {
    return (
        <div className="Image">
          <img src={printer} alt="printer" />
          <img src={share} alt="share" />
          <img src={dots} alt="dots" />
        </div>
      );
    };
export default CommonIcons;
