import { InvolvementMainIState } from "../../../Involvement/InvolvementMainI";
import { IdentificationIstate } from "../../IdentificationIProps";

export class IdentificationFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: IdentificationIstate[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: IdentificationIstate[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(
      this.Mainfields.IdentificationAndContact.IdentificationNumber
    );
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      IdentificationAndContact: {
        ...this.Mainfields.IdentificationAndContact,
        onChange:true,
        IdentificationNumber: [
          ...this.Mainfields.IdentificationAndContact.IdentificationNumber.map(
            (val) => {
              return {
                ...val,
                push: true,
              };
            }
          ),
          {
            id:
              this.Mainfields.IdentificationAndContact.IdentificationNumber[
                this.Mainfields.IdentificationAndContact.IdentificationNumber
                  .length - 1
              ].id + 1,
            PersonIdentificationId: "",
            PersonId: "",
            NcicpersonIdentificationCodeId: "",
            IdentificationId: 0,
            LocationCanadianProvinceCode: "",
            LocationStateUspostalServiceCode: "",
            IdentificationClass: "",
            PrimaryIdentification: "",
            EffectiveStartDate: "",
            EffectiveEndDate: "",
            IdentificationTypeDropdownValue: "",
            StateProvidenceDropdownValue: "",
            push: false,
          },
        ],
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(
      this.Mainfields.IdentificationAndContact.IdentificationNumber
    );
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      IdentificationAndContact: {
        ...this.Mainfields.IdentificationAndContact,
        onChange:true,
        IdentificationNumber:
          this.Mainfields.IdentificationAndContact.IdentificationNumber.filter(
            (val) => {
              if (val.id != id) {
                return val;
              }
            }
          ).length
            ? this.Mainfields.IdentificationAndContact.IdentificationNumber.filter(
                (val) => {
                  if (val.id != id) {
                    return val;
                  }
                }
              )
            : [
                {
                  id: 1,
                  PersonIdentificationId: "",
                  PersonId: "",
                  NcicpersonIdentificationCodeId: "",
                  IdentificationId: 0,
                  LocationCanadianProvinceCode: "",
                  LocationStateUspostalServiceCode: "",
                  IdentificationClass: "",
                  PrimaryIdentification: "",
                  EffectiveStartDate: "",
                  EffectiveEndDate: "",
                  IdentificationTypeDropdownValue: "",
                  StateProvidenceDropdownValue: "",
                },
              ],
      },
    });

    this.setedit(false);
  };
}
