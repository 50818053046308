import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CommonInitialState {
  refreshIncidenceMenuList: boolean;
  IncidentId: string;
}

const initialState: CommonInitialState = {
  refreshIncidenceMenuList: false,
  IncidentId: "",
};

const slice = createSlice({
  name: "CommonRedux",
  initialState,
  reducers: {
    setrefreshIncidenceMenuList(
      state: CommonInitialState,
      action: PayloadAction<boolean>
    ) {
      state.refreshIncidenceMenuList = action.payload;
    },

    setIncidentId(state: CommonInitialState, action: PayloadAction<string>) {
      state.IncidentId = action.payload;
    },
  },
});

export const { setrefreshIncidenceMenuList, setIncidentId } = slice.actions;

export default slice.reducer;
