import { Fetcher } from "../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../utils/const";
import {
  OrderByType,
  SetTableData,
  getCategoryTableDataI,
} from "./CategoryInterface";
import { OrderByFieldName } from "./Config";
const fetcher = new Fetcher();

export class CategoryFunction {
  async getCategoryTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    pageNumber: number,
    pageCount: number,
    categoryName?: string,
    Status?: string,
    calledFromAgencyLevel?: boolean,
    Agency?: string,
    OrderByDescending?: boolean,
    OrderBy?: string,
    calledFromNCIC?: boolean
  ) {
    setLoading(true);
    try {
      const isSearch = !!(
        categoryName ||
        Status == "1" ||
        Status == "0" ||
        calledFromAgencyLevel
      );

      // Construct the payload conditionally
      const payload: {
        PageNumber: number;
        PageSize: number;
        CategoryName: string | undefined;
        Status: boolean;
        AgencyId?: string | null;
        OrderByDescending?: boolean;
        OrderBy?: string;
      } = {
        PageNumber: isSearch ? 1 : pageNumber,
        PageSize: pageCount,
        CategoryName: categoryName,
        Status: Status === "1" ? true : false,
        ...(OrderByDescending != null ? { OrderByDescending } : {}),
        OrderBy: OrderByFieldName[OrderBy as OrderByType],
      };

      if (calledFromAgencyLevel && Agency !== undefined) {
        payload.AgencyId = Agency ? Agency : null;
      }

      // Make the request with the payload
      // const response = await fetcher.post(
      //   `${
      //     calledFromAgencyLevel
      //       ? `/category/agency/search`
      //       : "/category/system/search"
      //   }`,
      //   payload
      // );

      const response = await fetcher.post(
        calledFromAgencyLevel
          ? "/category/agency/search"
          : calledFromNCIC
          ? "/category/ncic/search"
          : "/category/system/search",
        payload
      );

      if (response) {
        const tableJson = this.createTableJson(response.Data);
        setTableTotalRecord(response.TotalRecords ?? 0);
        setTableData(tableJson);
      } else {
        setTableData([]);
        setTableTotalRecord(0);
      }
    } catch (error) {
      console.error("Error fetching category table data:", error);
      setTableData([]);
      setTableTotalRecord(0);
    }

    setLoading(false);
  }

  createTableJson(data: getCategoryTableDataI[]) {
    return data.map((val: getCategoryTableDataI) => ({
      "Category Name": val.CategoryName,
      Description: val.Description,
      Status: val.IsActive ? "Active" : "InActive",
      CategoryCodeId: val.CodeCategoryId,
    }));
  }

  async deleteCategory(
    codeCategoryId: string,
    setLoading: (data: boolean) => void,
    setdeletePopup: (data: boolean) => void,
    calledFromAgencyLevel?: boolean
  ) {
    console.log("codeCategoryId", codeCategoryId);
    setLoading(true);
    try {
      await fetcher.put(
        `${
          calledFromAgencyLevel
            ? `/category/${codeCategoryId}/agency/status`
            : `/category/${codeCategoryId}/system/status`
        }`,
        {},
        {
          message: successDeleteMessage,
          show: true,
        }
      );
    } catch (error) {
      console.error("Error deleting category:", error);
    }
    setLoading(false);
    setdeletePopup(false);
  }
}
