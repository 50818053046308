import { Modal } from "antd";
import { ModalComponentIProps } from "./ModalComponentI";
import { CloseOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";

export const ModalComponent: React.FC<ModalComponentIProps> = ({
  open,
  innerContant,
  title,
  width,
  height,
  onClose,
  style,
  closeIconStyle,
}) => {
  const defaultCloseIconStyle: CSSProperties = {
    backgroundColor: "transparent",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    top: "15px",
    right: "15px",
    zIndex: 1000,
  };

  const combinedCloseIconStyle = {
    ...defaultCloseIconStyle,
    ...closeIconStyle,
  };

  return (
    <>
      <Modal
        title={title}
        wrapClassName="vertical-center-modal"
        centered={true}
        width={width || "auto"}
        open={open}
        footer={null}
        closeIcon={
          <div style={combinedCloseIconStyle}>
            <CloseOutlined />
          </div>
        }
        onCancel={onClose}
        closable={true}
        height={height}
        style={{ ...style }}
      >
        {innerContant}
      </Modal>
    </>
  );
};
