import React, { useEffect, useState } from "react";
import { coloum } from "./Config";
import { IdentificationMainFunction } from "./IdentificationFunction";
import { useParams } from "react-router-dom";
import { IdentificationData, MasterMainIParams } from "../../MasterNameIState";
import { Table } from "../../../../../CommonComponents/Table/Table";

export const Identification: React.FC = () => {
  const [tableData, setTableData] = useState<IdentificationData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const identifiicationFunction = new IdentificationMainFunction();
  const { nameId } = useParams<MasterMainIParams>();
  const getEmergencyContact = () => {
    if(nameId){
      identifiicationFunction.getIdentificationMain(
        setTableData,
        setTableTotalRecord,nameId
      );
    
    }
     
  };

  useEffect(() => {
    getEmergencyContact();
  }, []);
  return (
    <div style={{ width: "100%", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Identification: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
           <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
         
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
