import React, { useState } from 'react'
import LocationMetaData from '../../Location/LocationMetaData'
import Breadcrumbs from '../../../../CommonComponents/Breadcrumbs/Breadcrumbs'
import AddMediaForm from './AddMediaForm';
import { BackButtonComponent } from '../../../../CommonComponents/BackButton/BackButtonComponent';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbComponent from 'src/Components/CommonComponents/breadcrumb/Breadcrumb';
import { InvolvementMainIParams } from '../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI';

const AddMedia = () => {
     const { incidentId } = useParams<InvolvementMainIParams>();
  let incidentNumber = localStorage.getItem("Incidentnumber");
    const [width, setwidth] = useState<number>(10);
    const navigate=useNavigate();
    return (
        <>
            <div className="_incident_location_container_fluid">
                <div>
                    <LocationMetaData
                        width={width}
                        setWidth={setwidth}
                    />
                </div>

                <div className="_location_right_container">
                     <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path:  `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              { title: "Media", path: `/incidents/${incidentId}/media`},
              {title:"Add",path:""}
            ]}
          />
                    <div className="_back_btn_comp" style={{padding:"0px 20px"}}>
                        <BackButtonComponent
                            name='Back to media'
                            onClick={()=>navigate(`/incidents/media`)}
                        />
                    </div>
                    <AddMediaForm />
                </div>
            </div>
        </>
    )
}

export default AddMedia