import { useEffect, useState } from "react";
import add from "../../../../../../../../../assets/icons/Icon.svg";
import { AdditionalMainFunction } from "../../../AdditionalInfo/AdditionalFunction";
import {
  MultipleSubstanceDataUIProps,
  SubstanceInvolvementIProps,
  SubstanceIstate,
} from "./SubstanceIProps";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { GetBloodTestTypeDataI } from "../../../AdditionalInfo/AdditionalIState";
import cross from "../../../../../../../../../assets/icons/cros.svg";
import { Add } from "../../../../../../../../../assets";
import { MultiSubstanceFunction } from "./MultiSubstanceFunction";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

const MultiSubstanceDataUI: React.FC<MultipleSubstanceDataUIProps> = ({
  Mainfields,
  setMainfields,
  MultipleSubstanceval,
  BloodTestType,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      SubstanceTab: {
        ...Mainfields.SubstanceTab,
        onChange: true,
        Substance: Mainfields.SubstanceTab.Substance.map((val) => {
          if (val.id == id) {
            if (key2) {
              return { ...val, [key]: value, [key2]: value2 };
            } else {
              return { ...val, [key]: value };
            }
          } else {
            return val;
          }
        }),
      },
    });
  };

  return (
    <>
      <div className="MultiplePhoneNumber_" style={{ width: "46%" }}>
        <div style={{ width: "115px" }}>
          <DropdownComponent
            fieldName="Yes/No"
            dataSource={[
              { value: "Yes", id: "1" },
              { value: "No", id: "2" },
            ]}
            value={MultipleSubstanceval.IsInvolved}
            handleRowClick={(e) => {
              onChangeValueNumber("IsInvolved", e, MultipleSubstanceval.id);
            }}
          />
        </div>
        <div style={{ width: "80%" }}>
          <DropdownComponent
            fieldName="Type"
            dataSource={BloodTestType.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
            value={MultipleSubstanceval.NcicsubstanceTypeCodeId}
            handleRowClick={(e, value2) => {
              onChangeValueNumber(
                "NcicsubstanceTypeCodeId",
                e,
                MultipleSubstanceval.id,
                "substanceTypeDropdownvalue",
                value2
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export const MultiSubstance: React.FC<SubstanceInvolvementIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [BloodTestType, setBloodTestType] = useState<GetBloodTestTypeDataI[]>(
    []
  );
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<SubstanceIstate[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const multiSubstanceFunction = new MultiSubstanceFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    multiSubstanceFunction.Save();
  };

  const handleClick = () => {
    multiSubstanceFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    multiSubstanceFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    multiSubstanceFunction.DeletePreview(id);
  };

  const initialRender = () => {
    additionalMainFunction.getBloodTestTypeData(setBloodTestType);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <p className="subheader">Substance Involvement</p>

        <Preview
          DeletePreview={DeletePreview}
          ViewPreview={ViewPreview}
          showlist={[["substanceTypeDropdownvalue"]]}
          list={
            edit
              ? (editList.map((item) => ({
                  ...item,
                  substanceTypeDropdownvalue: BloodTestType.filter(
                    (BloodTestTypeval) => {
                      if (
                        BloodTestTypeval.NciccodeId ==
                        item.NcicsubstanceTypeCodeId
                      ) {
                        return BloodTestTypeval;
                      }
                    }
                  )[0]?.LongCode,
                })) as {
                  [key: string]: string | number | boolean;
                }[])
              : (Mainfields.SubstanceTab.Substance.map((item) => ({
                  ...item,
                  substanceTypeDropdownvalue: BloodTestType.filter(
                    (BloodTestTypeval) => {
                      if (
                        BloodTestTypeval.NciccodeId ==
                        item.NcicsubstanceTypeCodeId
                      ) {
                        return BloodTestTypeval;
                      }
                    }
                  )[0]?.LongCode,
                })) as { [key: string]: string | number | boolean }[])
          }
        />

        <MultiSubstanceDataUI
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          MultipleSubstanceval={
            edit
              ? Mainfields.SubstanceTab.Substance.filter((val) => {
                  if (val.id == previewcount) {
                    return val;
                  }
                })[0]
              : Mainfields.SubstanceTab.Substance[
                  Mainfields.SubstanceTab.Substance.length - 1
                ]
          }
          BloodTestType={BloodTestType}
        />

        <div className="edit-button__" style={{ width: "60%" }}>
          <ButtonComponents
            name={edit ? "Save" : "Add Substance Involvement"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
