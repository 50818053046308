import React, { useState, useEffect } from "react";
import "./CreateEditSystemLevel.css";
import add from "../../../../../../assets/icons/Icon.svg";
import {
  CreateEditSystemLevelProps,
  SetFormDataIState,
  codecategory,
  setvalidationIState,
} from "./CreateEditSystemLevelProps";
import { CreateEditSystemLevelFunction } from "./CreateEditSystemLevelFunction";
import { useDispatch } from "react-redux";
import { CategoryListing } from "./CategoryListing/CategoryListing";
import { getCurrentDate } from "../../../../../../utils/Helper/Helper";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";
import { ModalComponent } from "../../../../../CommonComponents/Modal/ModalComponent";
import { AddCategory } from "../../../../../CommonComponents/Modal/AddCategory/AddCategory";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { AgencyLevelAddEditViewFunction } from "../../../AgencyLevel/components/AgencyLevelAddEditView/AgencyLevelAddEditViewFunction";
import { BackButtonComponent } from "../../../../../CommonComponents/BackButton/BackButtonComponent";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";
import { Add } from "src/assets";
export const CreateEditSystemLevel: React.FC<CreateEditSystemLevelProps> = ({
  addmode,
  editMode,
  viewMode,
  setaddMode,
  seteditMode,
  setviewMode,
  setshowAddEditView,
  getRowId,
}) => {
  const { Update } =useGetPerticularUserPermission("Code.System");
  const createEditSystemLevelFunction = new CreateEditSystemLevelFunction();
  const agencyLevelAddEditViewFunction = new AgencyLevelAddEditViewFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(viewMode ? true : false);
  const [categoryData, setCategoryData] = useState<codecategory[]>([]);
  const [AddCategoryshowPopup, setAddCategoryshowPopup] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<SetFormDataIState>({
    IsActive: "1",
    Description: "",
    Category: "",
    LongCode: "",
    ShortCode: "",
    ActiveDate: getCurrentDate(),
    ExpiryDate: undefined,
  });

  const [validation, setvalidation] = useState<setvalidationIState>({
    IsActive: false,
    Description: false,
    Category: false,
    LongCode: false,
    ShortCode: false,
    ActiveDate: false,
    ExpiryDate: false,
  });

  const [disabled, setdisabled] = useState<boolean>(viewMode ? true : false);
  const dispatch = useDispatch();

  const openAddCategoryPopup = () => {
    setAddCategoryshowPopup(true);
  };

  const getCategoryData = () => {
    createEditSystemLevelFunction.getallcodecategory(
      setCategoryData,
      setLoading
    );
  };

  useEffect(() => {
    if (viewMode || editMode || getRowId) {
      createEditSystemLevelFunction.getSystemLevelData(
        setLoading,
        setFormData,
        getRowId
      );
    }
    if (addmode) {
      setFormData({
        IsActive: "1",
        Description: "",
        Category: "",
        LongCode: "",
        ShortCode: "",
        ActiveDate: getCurrentDate(),
        ExpiryDate: undefined,
      });
    }
    getCategoryData();
  }, []);

  const onCancel = () => {
    setFormData({
      IsActive: "1",
      Description: "",
      Category: "",
      LongCode: "",
      ShortCode: "",
      ActiveDate: getCurrentDate(),
      ExpiryDate: undefined,
    });
  };

  const handleInputChange = (field: string, value: string | number | null) => {
    dispatch(setchangeTheForm(true));
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const addSystemLevel = (SaveandAddNewinSameCategorycheck?: boolean) => {
    createEditSystemLevelFunction.addSystemLevel(
      setLoading,
      formData,
      setshowAddEditView,
      SaveandAddNewinSameCategorycheck
    );
    dispatch(setchangeTheForm(false));
  };

  const editSystemLevel = () => {
    createEditSystemLevelFunction.editSystemLevel(
      setLoading,
      formData,
      setshowAddEditView,
      getRowId
    );
    dispatch(setchangeTheForm(false));
  };

  const handleSubmit = async () => {
    createEditSystemLevelFunction.errorValidation(formData, setvalidation);
    if (createEditSystemLevelFunction.checkRequiredFields(formData)) {
      if (addmode) {
        addSystemLevel();
      } else if (editMode || (viewMode && getRowId)) {
        editSystemLevel();
      }
    }
  };

  const AddCategoryCancel = () => {
    setAddCategoryshowPopup(false);
  };

  const AddCategorySave = () => {
    setAddCategoryshowPopup(false);
  };

  const SaveandAddNewinSameCategory = () => {
    createEditSystemLevelFunction.errorValidation(formData, setvalidation);
    if (createEditSystemLevelFunction.checkRequiredFields(formData)) {
      addSystemLevel(true);
      setFormData({
        ...formData,
        Description: "",
        LongCode: "",
        ShortCode: "",
      });
    }
  };

  return (
    <>
      <div>
        <div>
          <ModalComponent
            open={AddCategoryshowPopup}
            title="Add Category"
            width={600}
            innerContant={
              <AddCategory
                onCancel={AddCategoryCancel}
                onSave={AddCategorySave}
                getCategoryData={getCategoryData}
              />
            }
             closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
          />

          <div className="DetailTab_top">
            <div className="DetailTab_top_header_">
              <div className="DetailTab_top_header_sub">
                <p>System Level Code</p>
                <div className="edit_button_">
                  {Update && (
                  <p
                  onClick={() => {
                    setEdit(false);
                    setaddMode(false);
                    seteditMode(true);
                    setviewMode(false);
                    setdisabled(false);
                  }}
                >
                  {viewMode ? "Edit" : ""}
                </p>
                  )}

                  <BackButtonComponent
                    onClick={() => {
                      setshowAddEditView(false);
                    }}
                    name="Back"
                  />
                </div>
              </div>
            </div>

            <div className="System-main">
              <div className="AgencyLevelAddEditView_1">
                <DropdownComponent
                  dataSource={[
                    { id: "1", value: "Active" },
                    { id: "2", value: "InActive" },
                  ]}
                  value={formData.IsActive}
                  disabled={edit}
                  fieldName="Status*"
                  handleRowClick={(newValue) => {
                    handleInputChange("IsActive", newValue);
                  }}
                  validation={validation.IsActive}
                  showTooltip={false}
                />
                <DateBoxComponents
                  fieldName={"Active Date"}
                  value={formData.ActiveDate}
                  onValueChange={(newValue) => {
                    handleInputChange("ActiveDate", newValue);
                  }}
                />
                <DateBoxComponents
                  fieldName={"Inactive Date"}
                  value={formData.ExpiryDate}
                  onValueChange={(newValue) => {
                    handleInputChange("ExpiryDate", newValue);
                  }}
                />
              </div>
              <div className="AgencyLevelAddEditView_1">
                <DropdownComponent
                  dataSource={categoryData.map((category) => ({
                    id: category.CodeCategoryId,
                    value: category.CategoryName,
                  }))}
                  disabled={edit}
                  fieldName="Category*"
                  value={formData.Category}
                  handleRowClick={(newValue) => {
                    handleInputChange("Category", newValue);
                  }}
                  validation={validation.Category}
                />
                {
                  Update && (
                    <div className="AgencyLevelAddEditView_1_button" >
                    <ButtonComponents
                      handleClick={openAddCategoryPopup}
                      name="Add Category"
                      color="white"
                    textColor="#3672b3"
                    borderColor="#3672b3"
                    showBackgroundColor={true}
                    icon={Add}
                    />
                  </div>
                  )
                }
               
                <TextBoxComponent
                  disabled={edit}
                  value={formData.ShortCode}
                  fieldName="Short Code*"
                  onChangeValue={(newValue) => {
                    handleInputChange("ShortCode", newValue);
                  }}
                  validation={validation.ShortCode}
                />
                <TextBoxComponent
                  disabled={edit}
                  value={formData.LongCode}
                  fieldName="Long Code*"
                  onChangeValue={(newValue) => {
                    handleInputChange("LongCode", newValue);
                  }}
                  validation={validation.LongCode}
                />
              </div>
              <TextAreaComponent
                disabled={disabled}
                value={formData.Description}
                fieldName="Description*"
                onChangeValue={(newValue) =>
                  handleInputChange("Description", newValue)
                }
                validation={validation.Description}
              />
            </div>
          </div>
        </div>

        <div className="System-button">
          {(addmode || editMode) && (
            <div className="button-ip">
              <div className="submit-ip">
                <div className="submit-ip">
                  <Submit onCancel={onCancel} onSave={handleSubmit} />
                  {addmode && (
                    <button
                      style={{ marginTop: "7px" }}
                      onClick={SaveandAddNewinSameCategory}
                      className="save"
                    >
                      Save and Add New in Same Category
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <p className="header">Category</p>
      <CategoryListing Category={formData.Category} />
    </>
  );
};
