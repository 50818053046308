import { Fetcher } from "../../../../../../services/Fetcher";
import { successCreatedMessage, successUpdateMessage } from "../../../../../../utils/const";
import {
  combineDateTimeToISO,
  convertToISOFormat,
  convertToISOFormatDateOnly,
  ConvertToLocalDateFormate,
  formatDate,
  formatTime,
  parseISOToDateTime,
} from "../../../../../../utils/Helper/Helper";
import { setRankDataIState } from "../../../../UserManagement/Components/Tabs/SummaryTab/SummaryTabInterface";
import { GetRaceDataIState } from "../AddVehicleIState";
import {
  addressesLocation,
  getSaveAddressIRes,
  SetFormDataIState,
} from "./AddRecoveryIState";

const fetcher = new Fetcher();
export class AddRecoveryFunction {
  async addRecovery(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    incidentId: string,
    vehicleId: string,
    setFormData: (data: SetFormDataIState) => void,
    redirectTOEDitMOde: (data: string) => void,
    setRecoveryId: (id: string) => void
  ) {
    setLoading(true);
    try {
            const isUpdating = !!formData.VehicleRecoveryId;
      const response = await fetcher.post(
        `/incident/${incidentId}/vehicle/${vehicleId}/vehicle-recovery`,

        {
          VehicleRecoveryId: formData.VehicleRecoveryId
            ? formData.VehicleRecoveryId
            : null,
          VehicleId: vehicleId,
          RecoveryDateTime:
            formData.RecoveryDateTime && formData.RecoveryTime
              ? combineDateTimeToISO(
                  formData.RecoveryDateTime,
                  formData.RecoveryTime
                )
              : null,
          RecoveryTime: formData.RecoveryTime ? formData.RecoveryTime : null,
          IsRecovery: formData.IsRecovery ? formData.IsRecovery : false,
          RecoveryOfficer: formData.RecoveryOfficer
            ? formData.RecoveryOfficer
            : null,
          EstimatedValue: formData.EstimatedValue
            ? formData.EstimatedValue
            : null,
          RecoveryLocationId: formData.RecoveryLocationId
            ? formData.RecoveryLocationId
            : null,
          ReleasingUserId: formData.ReleasingUserId
            ? formData.ReleasingUserId
            : null,
          Condition: formData.Condition ? formData.Condition : null,
          OwnerNotifiedOfRecovery: formData.OwnerNotifiedOfRecovery
            ? formData.OwnerNotifiedOfRecovery
            : null,
          NotificationDateTime:
            formData.NotificationDateTime && formData.NotificationTime
              ? combineDateTimeToISO(
                  formData.NotificationDateTime,
                  formData.NotificationTime
                )
              : null,
          NotificationTime: formData.NotificationTime
            ? formData.NotificationTime
            : null,
          IsVehicleDriveable: formData.IsVehicleDriveable
            ? formData.IsVehicleDriveable
            : null,
        },
        {
        message: isUpdating
          ? successUpdateMessage
          : successCreatedMessage,
        show: true,
      }
      );
      if (response && response.Data && response.Data.VehicleRecoveryId) {
        setRecoveryId(response.Data.VehicleRecoveryId);
      }

      redirectTOEDitMOde(vehicleId);

      console.log(response);
    } catch (error) {
      console.error("Error adding tow:", error);
    } finally {
      setLoading(false);
    }
  }

  async getRecoveryData(
    setLoading: (data: boolean) => void,
    setFormData: (data: SetFormDataIState) => void,
    incidentId: string,
    vehicleId: string
  ) {
    setLoading(true);

    try {
      console.log(vehicleId);
      let res = await fetcher.get(
        `/incident/${incidentId}/vehicle/${vehicleId}/vehicle-recovery`
      );
      let response = res?.Data;
      console.log("API Response:", response);
      if (response && response.VehicleRecoveryId) {
        setFormData({
          VehicleId: vehicleId,
          VehicleRecoveryId: response.VehicleRecoveryId,
          RecoveryDateTime: response.RecoveryDateTime
            ? formatDate(response.RecoveryDateTime)
            : "",
          RecoveryTime: response.RecoveryDateTime
            ? ConvertToLocalDateFormate(response.RecoveryDateTime)
            : "",
          IsRecovery: response.IsRecovery ?? false,
          RecoveryOfficer: response.RecoveryOfficer ?? "",
          EstimatedValue: response.EstimatedValue ?? 0,
          RecoveryLocationId: response.RecoveryLocationId ?? "",
          ReleasingUserId: response.ReleasingUserId,
          Condition: response.Condition ?? "",
          NotificationTime: response.NotificationDateTime
            ? ConvertToLocalDateFormate(response.NotificationDateTime)
            : "",
          OwnerNotifiedOfRecovery: response.OwnerNotifiedOfRecovery ?? false,
          NotificationDateTime: response.NotificationDateTime
            ? formatDate(response.NotificationDateTime)
            : "",
          IsVehicleDriveable: response.IsVehicleDriveable ?? false,
        });
      }
    } catch (error) {
      console.error("Error fetching recovery data:", error);
    } finally {
      setLoading(false);
    }
  }

  async getCondition(
    setCondition: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/system/category/VehicleCondition"
    );

    setCondition(response.Data);
    setLoading(false);
  }
  async getCountryCode(
    setCountryCode: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/system/category/CountryCode");

    setCountryCode(response.Data);
    setLoading(false);
  }

  async locationAddressSave(addLocationAddress: addressesLocation) {
    try {
      const res = await fetcher.post(
        `/commonentity/save-address`,
        {
          Address: {
            NciccountrycodeId: addLocationAddress.NciccountrycodeId || null,
            LocationId: addLocationAddress.LocationId || null,
            IncidentId: addLocationAddress.IncidentId || null,
            AddressType: addLocationAddress.AddressType || null,
            IsPrimary: addLocationAddress.IsPrimary || false,
            LocationCategoryCode: addLocationAddress.LocationCategoryCode,
            LocationName: addLocationAddress.LocationName || null,
            Description: addLocationAddress.Description || null,
            Intersection: addLocationAddress.Intersection || null,
            HouseNumber: addLocationAddress.HouseNumber || null,
            Ncicstreet1PrefixId: addLocationAddress.Ncicstreet1PrefixId || null,
            Street1Name: addLocationAddress.Street1Name || null,
            Ncicstreet1SuffixId: addLocationAddress.Ncicstreet1SuffixId || null,
            UnitType: addLocationAddress.UnitType || null,
            UnitNumber: addLocationAddress.UnitNumber || null,
            Ncicstreet2PrefixId: addLocationAddress.Ncicstreet2PrefixId || null,
            Street2Name: addLocationAddress.Street2Name || null,
            Ncicstreet2SuffixId: addLocationAddress.Ncicstreet2SuffixId || null,
            City: addLocationAddress.City || null,
            NcicstateId: addLocationAddress.NcicstateId || null,
            ZipCode: addLocationAddress.ZipCode || null,
            Grid: addLocationAddress.Grid || null,
            IsGeoValidated: addLocationAddress.IsGeoValidated || false,
            Latitude: addLocationAddress.Latitude || null,
            Longitude: addLocationAddress.Longitude || null,
            GeoCode: addLocationAddress.GeoCode || null,
            ParcelId: addLocationAddress.ParcelId || null,
            NciccountryId: addLocationAddress.NciccountryId || null,
          },
          EntityModel: {
            EntityType: "Incident",
            EntityId: "53C54E75-8FA5-4576-86E1-BBB9465D4269",
            EntitySubType: "Vehicle",
            EntitySubTypeId: "57FF2D3B-5DF2-469F-A7B3-12BE8FEB2EFF",
          },
        },
        {
          message: successCreatedMessage,
          show: true,
        }
      );

      console.log(res.Success);

      if (res.Success) {
        const addressData = await this.getSaveAddress();
        console.log(addressData);
      }
    } catch (error) {
      console.error("Error saving address or fetching addresses:", error);
    }
  }

  async getSaveAddress() {
    let response = await fetcher.post(
      `/commonentity/getaddresses`,

      {
        EntityType: "Incident",
        EntityId: "53C54E75-8FA5-4576-86E1-BBB9465D4269",
        EntitySubType: "Vehicle",
        EntitySubTypeId: "57FF2D3B-5DF2-469F-A7B3-12BE8FEB2EFF",
      }
    );
    let res: getSaveAddressIRes[] = response.Data;

    console.log(res);
    return res;
  }

  async getAddresType(
    setAddressTypeData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/AddressType");
    let res: GetRaceDataIState[] = response.Data;
    setAddressTypeData(res);
    if (setLoading) {
      setLoading(false);
    }
  }
  async getUnitType(
    setUnitType: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/LOCATIONUNIT");
    let res: GetRaceDataIState[] = response.Data;
    setUnitType(res);
    if (setLoading) {
      setLoading(false);
    }
  }
  async getLocation(
    setLocation: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/ncic/category/LocationType");

    setLocation(response.Data);
    setLoading(false);
  }

  async getStateData(
    setStateData: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/ncic/category/StateCode");

    setStateData(response.Data);
    setLoading(false);
  }
  async getCountry(
    setCountry: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/ncic/category/Country");

    setCountry(response.Data);
    setLoading(false);
  }
  async getCounty(
    setCounty: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/ncic/category/County");

    setCounty(response.Data);
    setLoading(false);
  }
  async getStreetPrefix(
    setPrefix: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/ncic/category/StreetPreDirection"
    );

    setPrefix(response.Data);
    setLoading(false);
  }
  async getStreetPSuffix(
    setSuffix: (data: GetRaceDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      "/code/ncic/category/StreetPostDirection"
    );

    setSuffix(response.Data);
    setLoading(false);
  }
}
