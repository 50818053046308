import { Fetcher } from "../../../services/Fetcher";
import { getCADInformationTableIRes, SetTableData } from "./CADInformationI";

const fetcher = new Fetcher();

export class CADInformationFunction {
  async getCADInformationTable(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/cad/cad-incident-list`);
    const TableJson = this.createTableJson(response?.Data);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: getCADInformationTableIRes[]) {
    return data?.map((val: getCADInformationTableIRes, i: number) => {
      return {
        IncidentId: val.IncidentId,
        "Incident Date": val.IncidentDate,
        "Incident Type": val.IncidentType,
        Address: val.Address,
      };
    });
  }
}
