import { useEffect, useState } from "react";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { BiastypeIState, GetRaceDataIState } from "../../InvolvementMainI";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { BiasesComponentsIProps, BiastypeDataUIIProps } from "./BiasesI";
import { Add } from "../../../../../../../../../assets";
import { BiasesFunction } from "./BiasesFunction";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

const BiastypeDataUI: React.FC<BiastypeDataUIIProps> = ({
  Mainfields,
  setMainfields,
  Biastype,
  Biastypeval,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    dropdownValue?: string
  ) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Biases: {
          ...Mainfields.InvolvementTab.Biases,
          Biastype: Mainfields.InvolvementTab.Biases.Biastype.map((val) => {
            if (val.id == id) {
              return {
                ...val,
                [key]: value,
                BiastypeDropdownName: dropdownValue ? dropdownValue : "",
                // Audit: {
                //   ...val.Audit,
                //   Biastype: {
                //     ...val.Audit.Biastype,
                //     new: dropdownValue as string,
                //   },
                // },
              };
            } else {
              return val;
            }
          }),
        },
      },
    });
  };

  return (
    <>
      <DropdownComponent
        width="100%"
        dataSource={Biastype.map((val) => {
          return {
            id: val.NciccodeId,
            value: val.LongCode,
          };
        })}
        value={Biastypeval.Biastype}
        handleRowClick={(e, dropdownValue) => {
          onChangeValueNumber("Biastype", e, Biastypeval.id, dropdownValue);
        }}
        fieldName="Bias Type"
      />
    </>
  );
};

export const BiasesComponents: React.FC<BiasesComponentsIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const [Biastype, setBiasBasedOn] = useState<GetRaceDataIState[]>([]);

  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<BiastypeIState[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const biasesFunction = new BiasesFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null
  ) => {
    biasesFunction.onChangeValue(key, value);
  };

  const Save = () => {
    biasesFunction.Save();
  };

  const handleClick = () => {
    biasesFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    biasesFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    biasesFunction.DeletePreview(id);
  };

  const initialRender = () => {
    involvementMainFunction.getBiasBasedOn(setBiasBasedOn);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Preview
        DeletePreview={DeletePreview}
        ViewPreview={ViewPreview}
        showlist={[["BiastypeDropdownName"]]}
        list={
          edit
            ? (editList.map((item) => ({ ...item })) as any)
            : (Mainfields.InvolvementTab.Biases.Biastype.map((item) => ({
                ...item,
              })).filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t?.Biastype === item.Biastype)
              ) as any)
        }
      />

      <div style={{ display: "flex", gap: "20px" }}>
        <CheckBoxComponent
          value={Mainfields.InvolvementTab.Biases.BiasOffender}
          fieldName="Bias Offender"
          onChangeValue={(e) => {
            onChangeValue("BiasOffender", e);
          }}
        />
        <div style={{ width: "100%" }}>
          {Mainfields.InvolvementTab.Biases.BiasOffender && (
            <BiastypeDataUI
              setMainfields={setMainfields}
              Mainfields={Mainfields}
              Biastype={Biastype}
              Biastypeval={
                edit
                  ? Mainfields.InvolvementTab.Biases.Biastype.filter((val) => {
                      if (val.id == previewcount) {
                        return val;
                      }
                    })[0]
                  : Mainfields.InvolvementTab.Biases.Biastype[
                      Mainfields.InvolvementTab.Biases.Biastype.length - 1
                    ]
              }
            />
          )}
          {Mainfields.InvolvementTab.Biases.BiasOffender && (
            <div className="edit-button_">
              <ButtonComponents
                name={edit ? "Save" : "Add Bias Type"}
                handleClick={edit ? Save : handleClick}
                color="white"
                textColor="#3672b3"
                borderColor="#3672b3"
                showBackgroundColor={true}
                icon={Add}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
