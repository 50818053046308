import React, { useEffect, useState } from "react";
import close from "../../../../../../../../assets/icons/close-circle.svg";
import { FilterIProps } from "./FilterIProps";
import { TextBoxComponent } from "../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { ButtonIState } from "../../../../../Permission/Components/Filter/FilterIProps";

export const Filter: React.FC<FilterIProps> = ({
  Permission,
  setPermission,
}) => {

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [nameType, setNameType] = useState<string>("");
  const clearAllFilters = () => {
    setPermission("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };
  useEffect(() => {
    if (Permission.trim() !== "" ) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Permission]);

  return (
    <div className="Filter">
      <div className="fields">
        <TextBoxComponent
          fieldName={"Permission"}
          value={Permission}
          onChangeValue={setPermission}
        />
      </div>
      <div className="clearbutton">
        <div className="clearbutton-sub">
        <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
        </div>
      </div>
    </div>
  );
};
