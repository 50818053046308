import { Fetcher } from "src/services/Fetcher";
import {
  agencyLevel,
  externalCasePayload,
  externalTableRes,
  IncidentInternalFilterProps,
  IncidentInternalRes,
  RelatedIncident,
  setInternalTableIncidentList,
  SetTableData,
  TableDataItem,
} from "./CasesListing/CaseIState";

const fetcher = new Fetcher();
export class CasesListFunction {
  async getCasesListTableData(
    setTableData: (data: SetTableData[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    incidentId: string | undefined
  ) {
    setLoading(true);
    let response = await fetcher.get(
      `/incident/${incidentId}/related-incidents-list`
    );

    const TableJson = this.createTableJson(response?.Data);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: externalTableRes[]) {
    return data?.map((val: externalTableRes) => ({
      Case: val.IncidentNumber || "-",
      Date: val.IncidentDate || null,
      Type: val.IncidentType || "-",
      Agency: val.Agency || "-",
      Description: val.Description || "-",
      IsExternal: val.IsExternal,
      RelatedIncidentId: val.RelatedIncidentId || "-",
      AgencyId: val?.AgencyId,
    }));
  }

  async getAgencyLevelList(setAgencyLevelList: (data: agencyLevel[]) => void) {
    let response = await fetcher.get(`/agency/agencyList`);
    setAgencyLevelList(response?.Data);
  }

  async submitExternalCase(
    setLoading: (data: boolean) => void,
    incidentId: string | null,
    payload: externalCasePayload
  ) {
    setLoading(true);
    const response = await fetcher.post(
      `/incident/${incidentId}/external-related-incident`,
      payload
    );
    setLoading(false);
    return response;
  }

  async deleteExternalCase(
    setLoading: (data: boolean) => void,
    IsExternal: boolean,
    relatedIncidentExternalId: string,
    incidentId: string
  ) {
    setLoading(true);
    let res;
    if (IsExternal) {
      res = await fetcher.delete(
        `/incident/${incidentId}/external-related-incident/${relatedIncidentExternalId}`
      );
    } else {
      res = await fetcher.delete(
        `/incident/${incidentId}/related-incident/${relatedIncidentExternalId}`
      );
    }
    setLoading(false);
    console.log("res", res);
    return res;
  }

  async relatedIncidentsList(
    setLoading: (data: boolean) => void,
    setTableData: (data: setInternalTableIncidentList[]) => void,
    pageNumber: number,
    pageCount: number,
    setTableTotalRecord: (data: number) => void,
    incidentId: string,
    startDate: string | null,
    endDate: string | null,
    OrderByDescending?: boolean
  ) {
    setLoading(true);
    const response = await fetcher.post(`/incident/search`, {
      StartDate: null,
      EndDate: null,
      PageNumber: pageNumber,
      PageSize: pageCount,
      OrderBy: "",
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      IncidentNumber: null,
    });
    const TableJson = this.createInternalTableJson(response?.Data);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createInternalTableJson(data: IncidentInternalRes[]) {
    return data?.map((val: IncidentInternalRes) => ({
      Case: val?.IncidentNumber,
      Date: val?.ReportDateTime,
      "Incident Title": val?.Title,
      Agency: val?.Agency,
      RelatedAgencyId: val?.AgencyId,
      RelatedIncidentId: val?.IncidentId,
      AgencyId: val?.AgencyId,
    }));
  }

  async getRelatedIncidentList(
    incidentId: string,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(`
       /incident/${incidentId}/related-incidents-list
      `);
    setLoading(false);
  }

  async getRelatedIncident(
    setLoading: (data: boolean) => void,
    incidentId: string,
    setFiltertableData: (data: RelatedIncident[]) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(
      `/incident/${incidentId}/related-incidents`
    );
    const tableJson = this.createJsonTable(response?.Data?.RelatedIncidents);
    setFiltertableData(tableJson);
    setLoading(false);
  }

  createJsonTable(data: RelatedIncident[]) {
    return data?.map((val: RelatedIncident) => ({
      RelatedIncidentsId: val?.RelatedIncidentsId,
      RelatedAgencyId: val?.RelatedAgencyId,
      RelatedIncidentId: val?.RelatedIncidentId,
    }));
  }

  selectRowCheckBox(
    FiltertableData: RelatedIncident[],
    data: { [key: string]: string | number | boolean | null }[],
    setFiltertableData: (data: RelatedIncident[]) => void,
    tableData: setInternalTableIncidentList[],
    setSelectedRow: (data: IncidentInternalFilterProps) => void,
    incidentId: string | undefined
  ) {
    console.log("selected checkbox", data);

    const formattedData: IncidentInternalFilterProps = {
      AgencyId: data[0].AgencyId,
      IncidentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      RelatedIncidents: data.map((item) => ({
        RelatedIncidentsId: null,
        RelatedAgencyId: item.RelatedAgencyId || null,
        RelatedIncidentId: item.RelatedIncidentId || null,
      })),
    };

    setSelectedRow(formattedData);
    // let updatedTableData: setInternalTableIncidentList[] = tableData?.map(
    //   (item: setInternalTableIncidentList) => {
    //     const isSelected = data.some(
    //       (selectedItem) =>
    //         selectedItem?.RelatedIncidentId === item?.RelatedIncidentId
    //     );
    //     return { ...item, isSelected };
    //   }
    // );
  }

  async postRelatedIncidents(
    setLoading: (data: boolean) => void,
    selectedRows: IncidentInternalFilterProps,
    incidentId: string
  ) {
    const response = await fetcher.post(
      `/incident/${incidentId}/related-incidents`,
      selectedRows
    );
    
    return response;
  }
}
