import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { Table } from "../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { agencyLevel, IncidentTableData, SetTableData } from "./CaseIState";
import { Filter } from "./Components/Filter";
import LocationMetaData from "../../Location/LocationMetaData";
import "./cases.css";
import { CasesListFunction } from "../CasesFunctions";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import ExternalCasesModalForm from "./Components/ExternalCasesModalForm";

export const CaseList: React.FC = () => {
  const [width, setwidth] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [deleteUserId, setDeleteUserId] = useState<string>("");
  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [showAddresPopup, setshowAddresPopup] = useState<boolean>(false);
  const [showAddresPopupId, setshowAddresPopupId] = useState<string | null>("");
  const [showExternalCasePopUp, setShowExternalCasePopUp] =
    useState<boolean>(false);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Last Name");
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");
  const [agency, setAgency] = useState<agencyLevel[]>([]);
  const [isExternalCasesAdded, setExternalCasesAdded] =
    useState<boolean>(false);
  const navigation = useNavigate();
  const casesMainInstance = new CasesListFunction();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const edit = (e: SetTableData) => {};

  const getTableListHandler = async () => {
    await casesMainInstance?.getCasesListTableData(
      setTableData,
      pageNumber,
      pageCount,
      setLoading,
      incidentId
    );
  };

  const handleDelete = async() => {
    if (incidentId) {
      let res=await casesMainInstance?.deleteExternalCase(
        setLoading,
        isExternal,
        deleteUserId,
        incidentId
      );
      console.log("delete res",res)
      if(res?.Success){
        setdeletePopup(false);
        getTableListHandler();
      }
    }

  };
  const closeDeletePopup = () => {
    setdeletePopup(false);
  };
  const handleClose = () => {
    setShowExternalCasePopUp(false);
  };
  useEffect(() => {
    getTableListHandler();
  }, [isExternalCasesAdded]);

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={handleDelete}
        open={deletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />

      <div className="_external_cases_modal_container">
        <ModalComponent
          open={showExternalCasePopUp}
          title=""
          innerContant={
            <ExternalCasesModalForm
              handleClose={handleClose}
              setExternalCasesAdded={setExternalCasesAdded}
            />
          }
          onClose={handleClose}
           closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
        />
      </div>
      <div className="_incident_location_container_fluid">
        <div className="_location_left_container">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="UserManagement _location_right_container">
          <div className="_user_management_container">
            <p className="header">Related Cases {""} 1285743</p>
            <Filter
              Name={Name}
              setName={setName}
              setType={setType}
              Type={Type}
              setShowExternalCasePopUp={setShowExternalCasePopUp}
              setAgency={setAgency}
              agency={agency}
            />
            <div className="table">
              <Table
                columns={coloum(
                  setdeletePopup,
                  setDeleteUserId,
                  edit,
                  setshowAddresPopup,
                  setshowAddresPopupId,
                  setIsExternal
                )}
                columnsValue={tableData}
                cursorPointer={true}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                //   onRowClicked={onRowClicked}
                tableTotalRecord={tableTotalRecord}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                headerBackgroundColor="#e7e7e7"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
