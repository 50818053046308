import { useState } from "react";
import { InvolvementMainIState } from "../../Components/Pages/Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

interface SaveChangesPopupHook {
  Mainfields: InvolvementMainIState;
  setMainfields: (data: InvolvementMainIState) => void;
}

const useIncidentsParticipantStateManagement = (): SaveChangesPopupHook => {
  const [Mainfields, setMainfields] = useState<InvolvementMainIState>({
    InvolvementTab: {
      Involvement: {
        InCustody: false,
        MissingRunaway: false,
        DOB: "",
        Firstname: "",
        InvolvementType: "",
        LastName: "",
        Middlename: "",
        Private: false,
        ProcORI: false,
        Suffix: "",
        DOBRefused: false,
        Juvenile: false,
        DOBUnknown: false,
        ParticipantId: "",
        CitationId: 1,
        NameId: "",
        EntityTypeId: 1,
        BusinessId: "",
        PersonId: "",
        MasterNameId: "",
        ParticipantRoleId: "",
        Age: "",
        MaxAge: "",
        MinAge: "",
      },
      Aliases: [
        { DOB: "", Firstname: "", id: 1, LastName: "", Middlename: "" },
      ],
      Address: {
        AddressData: [
          {
            AddressType: "",
            Apartment: "",
            City: "",
            Country: "",
            GridNumber: "",
            id: 1,
            CountryDropdownValue: "",
            StateProvidenceDropdownvalue: "",
            StateProvidence: "",
            Street: "",
            StreetNumber: "",
            StreetPrefix: "",
            StreetSuffix: "",
            ZipCode: "",
            AddressLine1: "",
            AddressLine2: "",
            ForeignAddressCity: "",
            ForeignAddressStateProvinceRegion: "",
            ForeignAddressZip: "",
            ForeignAddressCountry: "",
            ForeignAddress: false,
          },
        ],
        NoPermanentAddress: false,
        UnknownAddress: false,
      },
      Demographics: {
        Sex: "",
        Race: "",
        Eye: "",
        Hair: "",
        FacialHair: "",
        Glasses: "",
        SkinTon: "",
        Height: "",
        Weight: "",
        Build: "",
        Handedness: "",
        BirthplaceCIty: "",
        StateProvidence: "",
        Country: "",
        MinHeight: "",
        MinWeight: "",
        MaxHeight: "",
        MaxWeight: "",
      },
      ScarsMarksTattoos: [
        {
          id: 1,
          Notes: "",
          ScarMarkTattoos: "",
          TypeLocation: "",
          ScarMarkTattoosDropdownValue: "",
          TypeLocationDropdownValue: "",
        },
      ],
      Arrestinformation: {
        ArrestId: "",
        LocationId: "",
        ArmedWithWeapon: [
          {
            ArmedWithWeapon: "",
            Weaponautomatic: false,
            id: 1,
            ArresteeWasArmedWithTypeId: "",
          },
        ],
        ArrestCategoryCode: "",
        ArrestedDate: "",
        ArrestedTime: "",
        BookandRelease: false,
        InCustody: false,
        Photographed: false,
        ReasonHeld: "",
        ReasonHeldDetails: "",
        WarrantNumber: [
          {
            WarrantNumber: "",
            id: 1,
            WarrantId: "",
          },
        ],

        AddressType: "",
        Apartment: "",
        City: "",
        Country: "",
        GridNumber: "",
        Sameasprimaryincidentaddress: "",
        StateProvidence: "",
        Street: "",
        StreetNumber: "",
        StreetPrefix: "",
        StreetSuffix: "",
        UnknownAddress: false,
        ZipCode: "",
      },
      Victiminformation: {
        AgencyId: "",
        VictimId: "",
        AggravatedAssaulthomicideCode: [
          {
            AggravatedAssaulthomicideCode: "",
            id: 1,
            AgencyId: "",
            AggravatedAssaultHomicideId: "",
            IncidentId: "",
            PersonId: "",
          },
        ],
        EnforcementOfficialActivityCategoryCode: "",
        EnforcementOfficialAssignmentCategoryCode: "",
        InjuryCategoryCode: [
          {
            InjuryCategoryCode: "",
            id: 1,
            AgencyId: "",
            IncidentId: "",
            InjuryId: "",
            PersonId: "",
          },
        ],
        AdditionalJustifiablehomicide: [
          {
            AdditionalJustifiablehomicide: "",
            id: 1,
            AggravatedAssaultHomicideId: "",
            AgencyId: "",
            IncidentId: "",
            PersonId: "",
            AdditionalJustifiableHomicideId: "",
          },
        ],
        OfficerOtherJurisdiction: false,
        USRCode: "",
        VictimType: "",
      },
      Biases: {
        BiasOffender: true,
        Biastype: [
          {
            Biastype: "",
            id: 1,
            BiastypeDropdownName: "",
            Audit: {
              Biastype: {
                new: "",
                old: "",
              },
            },
          },
        ],
      },
    },
    AdditionalInfo: {
      changes: false,
      DOA: false,
      School: "",
      SchoolGrade: "",
      MaritalStatus: "",
      DateOfDeath: "",
      IsDodunknown: false,
      GunShotInjury: false,
      FingerprintIndivator: false,
      GangAffiliation: {
        AttributeId: [],
        GangAffiliation: [],
        ParticipantAttributeId: [],
      },
      FingerPrints: false,
      BloodType: "",
      Disability: {
        AttributeId: [],
        Disability: [],
        ParticipantAttributeId: [],
      },
      Injury: false,
      IsDisabled: false,
      Deceased: false,
      IsDeceased: false,
      DOD: false,
      DOB: "",
      id: 1,
      PersonId: "",
      AgencyId: "",
      IncidentId: "",
      MasterNameId: "",
      Language: {
        Language: [],
        LimitedEnglishProficiency: false,
        AttributeId: [],
        ParticipantAttributeId: [],
      },
      Employment: [
        {
          ExternalContactID: "",
          ContactFirstname: "",
          id: 1,
          ContactLastname: "",
          EmployerName: "",
          EmployerType: "",
          Occupation: "",
          Shift: "",
          PhoneNumber: [
            {
              Country: "",
              Ext: "",
              ExternalContactID: "",
              id: 1,
              Number: "",
              Type: "",
              TypeDropdownvalue: "",
            },
          ],
          Address: {
            AddressLine1: "",
            AddressLine2: "",
            AddressType: "",
            Apartment: "",
            LocationId: "",
            City: "",
            Country: "",
            NoPermanentAddress: false,
            UnknownAddress: false,
            ForeignAddress: false,
            ForeignAddressCity: "",
            ForeignAddressCountry: "",
            ForeignAddressStateProvinceRegion: "",
            ForeignAddressZip: "",
            GridNumber: "",
            StateProvidence: "",
            Street: "",
            StreetNumber: "",
            StreetPrefix: "",
            StreetSuffix: "",
            ZipCode: "",
          },
          Email: [
            {
              Email: "",
              ExternalContactID: "",
              id: 1,
              Type: "",
              TypeDropdownvalue: "",
            },
          ],
        },
      ],
      Citizenship: {
        IsUscitizen: false,
        Citizenship: [],
        NonUSCitizen: false,
        CitizenshipUnknown: false,
        AttributeId: [],
        ParticipantAttributeId: [],
      },
    },
    MultipleEmergencyContact: [
      {
        Address: {
          AddressData: [
            {
              AddressType: "",
              Apartment: "",
              City: "",
              Country: "",
              GridNumber: "",
              id: 1,
              StateProvidence: "",
              Street: "",
              StreetNumber: "",
              StreetPrefix: "",
              StreetSuffix: "",
              ZipCode: "",
              AddressLine1: "",
              AddressLine2: "",
              ForeignAddressCity: "",
              ForeignAddressStateProvinceRegion: "",
              ForeignAddressZip: "",
              ForeignAddressCountry: "",
              ForeignAddress: false,
              CountryDropdownValue: "",
              StateProvidenceDropdownvalue: "",
            },
          ],
          NoPermanentAddress: false,
          UnknownAddress: false,
        },
        EmergencyContact: {
          ExternalContactID: "",
          Relation: "",
          LastName: "",
          Firstname: "",
          Middlename: "",
          Suffix: "",
        },
        PhoneNumber: [
          {
            Country: "",
            Ext: "",
            ExternalContactID: "",
            id: 1,
            Number: "",
            Type: "",
            TypeDropdownvalue: "",
          },
        ],
        Email: [
          {
            Email: "",
            ExternalContactID: "",
            id: 1,
            Type: "",
            TypeDropdownvalue: "",
          },
        ],
        id: 1,
        ExternalContactID: "",
      },
    ],
    IdentificationAndContact: {
      IdentificationNumber: [
        {
          id: 1,
          PersonIdentificationId: "",
          PersonId: "",
          NcicpersonIdentificationCodeId: "",
          IdentificationId: 1,
          LocationCanadianProvinceCode: "",
          LocationStateUspostalServiceCode: "",
          IdentificationClass: "",
          PrimaryIdentification: "",
          EffectiveStartDate: "",
          EffectiveEndDate: "",
          IdentificationTypeDropdownValue: "",
          StateProvidenceDropdownValue: "",
        },
      ],
      PhoneNumber: [
        {
          Country: "",
          Ext: "",
          ExternalContactID: "",
          id: 1,
          Number: "",
          TypeDropdownvalue: "",
          Type: "",
        },
      ],
      Email: [
        {
          Email: "",
          ExternalContactID: "",
          id: 1,
          Type: "",
          TypeDropdownvalue: "",
        },
      ],
    },
    SubstanceTab: {
      Substance: [
        {
          id: 1,
          IsInvolved: "",
          NcicsubstanceTypeCodeId: "",

          SubstanceInvolvementId: "",
          AgencyId: "",
          IncidentId: "",
          PersonId: "",
          substanceTypeDropdownvalue: "",
        },
      ],
      BloodTab: [
        {
          id: 1,
          TestDate: "",
          TestResults: 0,
          TestTime: "",
          NcicsubstanceTypeCodeId: "",
          TestTypeDropdownvalue: "",
          alcholedropdownvalue: "",
          ParticipantBloodAlcoholLevelId: "",
          AgencyId: "",
          IncidentId: "",
          PersonId: "",
        },
      ],
    },
  });

  return {
    Mainfields,
    setMainfields,
  };
};

export default useIncidentsParticipantStateManagement;
