import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AgencyReducer from "./Redux/AgencyReducer";
import UserReducer from "./Redux/UserReducer";
import ValidateRouteReducer from "./Redux/ValidateRouteReducer";
import PolicyReducer from "./Redux/PolicyReducer";
import RoleReducer from "./Redux/RoleReducer";
import UserPermissionReducer from "./Redux/UserPermissionReducer";
import CommonRedux from "./Redux/CommonRedux";
import AuditReducer from "./Redux/AuditReducer";
import VehicleReducer from "./Redux/VehicleReducer";
import UserInfoRedux from "./Redux/UserInfoRedux";

const rootReducer = combineReducers({
  AgencyReducer,
  UserReducer,
  ValidateRouteReducer,
  PolicyReducer,
  RoleReducer,
  UserPermissionReducer,
  CommonRedux,
  AuditReducer,
  VehicleReducer,
  UserInfoRedux,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
