import { useState } from "react";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { CheckBoxComponent } from "../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DateBoxComponents } from "../../../../CommonComponents/Fields/DateBox/DateBox";
import LocationMetaData from "../../Location/LocationMetaData";
import BreadcrumbComponent from "src/Components/CommonComponents/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

export const AddArticle: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { incidentId } = useParams<InvolvementMainIParams>();

  let incidentNumber = localStorage.getItem("Incidentnumber");
  const [width, setwidth] = useState<number>(10);
  return (
    <>
      <Loader loading={loading} />
      <div className="_incident_location_container_fluid_">
        <div className="_location_left_container">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="_user_management_container">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Incidents", path: "/incidents" },
            { title: incidentNumber ? incidentNumber : "", path: "" },
            { title: "Articles", path: `/incidents/${incidentId}/articles`},
            { title: "Add", path: `/incidents/${incidentId}/articles`},
          ]}
        />
          <p className="header">Add Article</p>
          <p className="head">Description</p>

          <div className="form-row1">
            <div className="form-group1">
              <DropdownComponent fieldName="Status" dataSource={[]} />
            </div>
          </div>
          <div className="article">
            <div className="form-group1">
              <DropdownComponent
                fieldName="Property Type Code "
                dataSource={[]}
              />
            </div>
            <div className="form-group1">
              <DropdownComponent
                fieldName="NIBRS Property Code (PTC) "
                dataSource={[]}
              />
            </div>
          </div>

          <div className="form-row1">
            <div className="form-group1">
              <TextBoxComponent fieldName="Type Description (TYP)" value="" />
            </div>
          </div>

          <div className="checkbox-group1">
            <CheckBoxComponent fieldName="Hazardous Material" value={true} />
            <CheckBoxComponent fieldName="Suspected Drug" value={true} />
          </div>

          <div className="form-row1">
            <div className="form-group1">
              <DropdownComponent fieldName="Brand Name(BRA) " dataSource={[]} />
            </div>
            <div className="form-group1">
              <DropdownComponent fieldName="Model (MOD)" dataSource={[]} />
            </div>

            <div className="form-group1">
              <DropdownComponent fieldName="Color " dataSource={[]} />
            </div>

            <div className="form-group1">
              <TextBoxComponent fieldName="Value" value="" />
            </div>
          </div>
          <div className="form-row1">
            <div className="form-group1">
              <TextBoxComponent fieldName="Serial Number(SER)" value="" />
            </div>

            <div className="form-group1">
              <TextBoxComponent
                fieldName="Owner Applied Number(OAN)"
                value=""
              />
            </div>
          </div>
          <div className="line"></div>
          <p className="head">Theft</p>
          <div className="form-row1">
            <div className="form-group1">
              <DateBoxComponents fieldName="Date Of Theft(DOT)" />
            </div>

            <div className="form-group1">
              <TextBoxComponent fieldName="Quality Stolen" value="" />
            </div>
          </div>
          <div className="line"></div>
          <p className="head">Recovery</p>
          <div className="form-row1">
            <div className="form-group1">
              <DateBoxComponents fieldName="Date Of Recovery" />
            </div>
            <div className="form-group1">
              <DropdownComponent
                fieldName="Recovery Property Type Code"
                dataSource={[]}
              />
            </div>

            <div className="form-group1">
              <TextBoxComponent fieldName="Quality Recovered" value="" />
            </div>
          </div>
          <div className="line"></div>
          <p className="head">Additional Information</p>
          <div className="form-row1">
            <div className="form-group1">
              <TextBoxComponent fieldName="Lot Number(LOT)" value="" />
            </div>

            <div className="form-group1">
              <TextBoxComponent
                fieldName="Partial Lot Indiciator(PLI)"
                value=""
              />
            </div>
          </div>
          <div className="form-group1">
            <DropdownComponent
              fieldName="Notify Originating Agency(NOA)"
              dataSource={[]}
            />
          </div>
          <div className="form-row1">
            <div className="form-group1">
              <TextBoxComponent
                fieldName="Linkage Agency Identifier(LKI)"
                value=""
              />
            </div>

            <div className="form-group1">
              <TextBoxComponent
                fieldName="Linkage Agency Case Number(LKA)"
                value=""
              />
            </div>
          </div>
          <div className="form-group1">
            <TextBoxComponent fieldName="United Nations Number" value="" />
          </div>
          <div className="form-group1">
            <TextBoxComponent fieldName="Miscellaneous(MIS)" value="" />
          </div>
          <div className="form-row1">
            <div className="form-group1">
              <TextBoxComponent fieldName="SIN" value="" />
            </div>

            <div className="form-group1">
              <TextBoxComponent fieldName="NIC" value="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
