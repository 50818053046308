import {
  SetAgencyDetailPayload,
  setAgencyDetail,
} from "../../../../../../Redux/AgencyReducer";
import { Fetcher } from "../../../../../../services/Fetcher";
import { format } from "date-fns";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../utils/const";
import {
  SetFormDataIState,
  getAgencyIRes,
  getSateDataIState,
  setAgencyTypeDataIState,
  setFormDataValidation,
} from "./DetailTabInterface";
import { openNotificationWithIcon } from "../../../../../CommonComponents/Toster/Toster";
import { useNavigate } from "react-router-dom";

const fetcher = new Fetcher();

export class DetailTabFunctions {
  // const navigation = useNavigate();

  async addAgency(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    NavigateToEditPage: (data: number) => void
  ) {
    setLoading(true);
    const ipAddress = await this.fetchIpAddress();
    const response = await fetcher.post(
      "/agency",
      {
        ...formData,
        Contacts: formData.AgencyPhone.filter((val) => {
          if (val?.ContactMethodCodeId && val?.ContactValue) {
            return {
              IsPrimary: val.IsPrimary,
              ContactMethodCodeId: val?.ContactMethodCodeId,
              ContactValue: val?.ContactValue,
            };
          }
        }),
        AgencyPhone: undefined,
        AgencyStateCodeId: formData.AgencyStateCodeId,
        AgencyTypeCodeId: formData?.AgencyTypeCodeId,
        IpAddress: ipAddress,
        IsActive: formData.IsActive == "1" ? true : false,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
    //NavigateToAddPage();
    if (response?.Data?.AgencyId) {
      NavigateToEditPage(response?.Data?.AgencyId);
    }
  }

    async editAgency(
      setLoading: (data: boolean) => void,
      formData: SetFormDataIState,
      AgencyId: string
    ) {
      setLoading(true);
      const response = await fetcher.put(
        "/agency",
        {
          ...formData,
          IsActive: formData.IsActive == "1" ? true : false,
          AgencyId: AgencyId,
          Contacts: formData.AgencyPhone.filter((val) => {
            if (val.ContactMethodCodeId && val.ContactValue) {
              return {
                IsPrimary: val.IsPrimary,
                ContactMethodCodeId: val.ContactMethodCodeId,
                ContactValue: val.ContactValue,
              };
            }
          }),
          AgencyPhone: undefined,
          AgencyStateCodeId: formData.AgencyStateCodeId,
          AgencyTypeCodeId: formData?.AgencyTypeCodeId,
        },
        { message: successUpdateMessage, show: true }
      );
      setLoading(false);
    }

  async getAgencyManagemantData(
    setLoading: (data: boolean) => void,
    agencyId: string,
    setFormData: (data: SetFormDataIState) => void,
    dispatch: (action: {
      type: string;
      payload: SetAgencyDetailPayload;
    }) => void,
    setgetagencyname: (data: string) => void
  ) {
    setLoading(true);
    let responce = await fetcher.get(`/agency/${agencyId}`);

    let {
      AgencyName,
      AgencyDescription,
      AgencyAddress,
      AgencyCity,
      AgencyStateCodeId,
      AgencyZipcode,
      Contacts,
      AgencyFax,
      IsActive,
      Ipaddress,
      CreatedOn,
      AgencyTypeCodeId,
      CreatedByUserName,
      AgencyTypeName,
      ModifiedOn,
    }: getAgencyIRes = responce?.Data;

    setFormData({
      AgencyName: AgencyName,
      AgencyTypeCodeId,
      AgencyDescription: AgencyDescription,
      AgencyAddress: AgencyAddress,
      AgencyPhone: Contacts?.length
        ? Contacts.map((val, i) => {
            return {
              ContactMethodCodeId: val.ContactMethodCodeId,
              ContactValue: val.ContactValue,
              id: i,
              IsPrimary: val.IsPrimary,
            };
          })
        : [
            {
              ContactMethodCodeId: "",
              ContactValue: "",
              id: 1,
              IsPrimary: false,
            },
          ],
      AgencyFax,
      AgencyCity,
      AgencyStateCodeId,
      AgencyZipcode,
      IsActive: IsActive ? "1" : "2",
      IpAddress: Ipaddress ? Ipaddress : "",
    });
    setgetagencyname(AgencyName);

    dispatch(
      setAgencyDetail({
        AgencyName,
        Createdby: CreatedByUserName ? CreatedByUserName : "Jeff Gottstein",
        AgencyTypeName: AgencyTypeName,
        CreatedOn: format(new Date(CreatedOn), "MM-dd-yyyy"),
        ModifiedOn: ModifiedOn,
      })
    );

    setLoading(false);
  }
  async getSateData(
    setStateData: (data: getSateDataIState[]) => void,
    setLoading: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get("/code/system/category/U7CBZ6HTKV");
    setStateData(response.Data);
    setLoading(false);
  }

  async getAgencyTypeData(
    setStateData: (data: setAgencyTypeDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/system/category/MRSTU53DBB");
    setStateData(response.Data);
    if (setLoading) {
      setLoading(false);
    }
  }

  async fetchIpAddress() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "";
    }
  }

  async agencyNameValidate(
    agencyName: string,
    setLoading: (data: boolean) => void,
    setcheckagencyname: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.get(`/agency/${agencyName}/validate`);
    if (!response?.Data) {
      openNotificationWithIcon("error", response?.Message);
      setcheckagencyname(false);
    } else {
      setcheckagencyname(true);
    }
    setLoading(false);
  }

  errorValidation(
    formData: SetFormDataIState,
    setFormDataValidation: (data: setFormDataValidation) => void
  ) {
    let valdationerror: setFormDataValidation = {
      AgencyName: false,
      AgencyTypeCodeId: false,
      AgencyDescription: false,
      AgencyAddress: false,
      AgencyPhone: false,
      AgencyFax: false,
      AgencyCity: false,
      AgencyStateCodeId: false,
      AgencyZipcode: false,
      IsActive: false,
      ContactType: false,
    };
    if (!formData.AgencyName) {
      valdationerror.AgencyName = true;
    } else {
      valdationerror.AgencyName = false;
    }
    if (!formData.AgencyTypeCodeId) {
      valdationerror.AgencyTypeCodeId = true;
    } else {
      valdationerror.AgencyTypeCodeId = false;
    }
    if (!formData.AgencyAddress) {
      valdationerror.AgencyAddress = true;
    } else {
      valdationerror.AgencyAddress = false;
    }

    if (!formData.AgencyCity) {
      valdationerror.AgencyCity = true;
    } else {
      valdationerror.AgencyCity = false;
    }

    if (!formData.AgencyStateCodeId) {
      valdationerror.AgencyStateCodeId = true;
    } else {
      valdationerror.AgencyStateCodeId = false;
    }

    if (!formData.AgencyZipcode) {
      valdationerror.AgencyZipcode = true;
    } else {
      valdationerror.AgencyZipcode = false;
    }

    if (!formData.IsActive) {
      valdationerror.IsActive = true;
    } else {
      valdationerror.IsActive = false;
    }

    if (!formData.AgencyPhone[0].ContactValue) {
      valdationerror.AgencyPhone = true;
    } else {
      valdationerror.AgencyPhone = false;
    }

    if (!formData.AgencyPhone[0].ContactMethodCodeId) {
      valdationerror.ContactType = true;
    } else {
      valdationerror.ContactType = false;
    }

    setFormDataValidation({ ...valdationerror });
  }

  checkRequiredFields(formData: SetFormDataIState) {
    return (
      formData.AgencyName &&
      formData.AgencyTypeCodeId &&
      formData.AgencyAddress &&
      formData.AgencyPhone[0].ContactMethodCodeId &&
      formData.AgencyPhone[0].ContactValue &&
      formData.AgencyCity &&
      formData.AgencyStateCodeId &&
      formData.AgencyZipcode &&
      formData.IsActive
    );
  }
}
