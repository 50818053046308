import { setchangeTheForm } from "../../../../../../../Redux/ValidateRouteReducer";
import { Fetcher } from "../../../../../../../services/Fetcher";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../../utils/const";
import { setFormDataIState } from "../../../../../../CommonComponents/Form/FormI";
import { openNotificationWithIcon } from "../../../../../../CommonComponents/Toster/Toster";
import { SetFormDataIState } from "./CreateIPAddressInterface";

const fetcher = new Fetcher();

export class CreateIPAddressFunction {
  async addIPAddress(
    setLoading: (data: boolean) => void,
    formData: setFormDataIState,
    agencyId: string
  ) {
    setLoading(true);
    const response = await fetcher.post(
      `/agency/${agencyId}/ip-whitelisting`,
      { ...formData, Status: formData["Status"] == 1 ? true : false },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
  }

  async editIPAddress(
    setLoading: (data: boolean) => void,
    formData: setFormDataIState,
    agencyId: string,
    WhitelistIPAddressId: string
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/agency/${agencyId}/ip-whitelisting`,
      {
        ...formData,
        WhitelistIPAddressId,
        Status: formData["Status"] == 1 ? true : false,
      },
      {
        message: successUpdateMessage,
        show: true,
      }
    );
    setLoading(false);
  }

  async getIPAddressData(
    setLoading: (data: boolean) => void,
    agencyId: string,
    ipAddressId: number,
    setFormData: (data: SetFormDataIState) => void
  ) {
    setLoading(true);
    try {
      const response = await fetcher.get(
        `/agency/${agencyId}/ip-whitelisting/${ipAddressId}`
      );
      setFormData(response.Data);
    } catch (error) {
      openNotificationWithIcon("error", "Failed to fetch IP Address data.");
    } finally {
      setLoading(false);
    }
  }
}
