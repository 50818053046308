import { useState } from "react";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { DateBoxComponents } from "../../../../../../../../CommonComponents/Fields/DateBox/DateBox";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import {
  MultiAliasesDataUIIProps,
  MultiAliasesIProps,
  MultiAliasesIState,
} from "./MultiAliasesI";
import { Add } from "../../../../../../../../../assets";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";
import { AliasesFunction } from "./AliasesFunction";

const MultiAliasesDataUI: React.FC<MultiAliasesDataUIIProps> = ({
  MultiAliases,
  setMultiAliases,
  MultiAliasesval,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number
  ) => {
    setMultiAliases({
      ...MultiAliases,
      InvolvementTab: {
        ...MultiAliases.InvolvementTab,
        onChange:true,
        Aliases: MultiAliases.InvolvementTab.Aliases.map((val) => {
          if (val.id == id) {
            return { ...val, [key]: value };
          } else {
            return val;
          }
        }),
      },
    });
  };

  return (
    <>
      <div className="MultiplePhoneNumber">
        <TextBoxComponent
          value={MultiAliasesval.LastName}
          fieldName="Last Name"
          onChangeValue={(e) => {
            onChangeValueNumber("LastName", e, MultiAliasesval.id);
          }}
        />
        <TextBoxComponent
          value={MultiAliasesval.Firstname}
          onChangeValue={(e) => {
            onChangeValueNumber("Firstname", e, MultiAliasesval.id);
          }}
          fieldName="First name"
        />
        <TextBoxComponent
          value={MultiAliasesval.Middlename}
          onChangeValue={(e) => {
            onChangeValueNumber("Middlename", e, MultiAliasesval.id);
          }}
          fieldName="Middle name"
        />

        <DateBoxComponents
          fieldName="Date of Birth"
          value={MultiAliasesval.DOB}
          onValueChange={(e) => {
            onChangeValueNumber("DOB", e, MultiAliasesval.id);
          }}
        />
      </div>
    </>
  );
};

export const MultiAliases: React.FC<MultiAliasesIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<MultiAliasesIState[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const aliasesFunction = new AliasesFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    aliasesFunction.Save();
  };

  const handleClick = () => {
    aliasesFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    aliasesFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    aliasesFunction.DeletePreview(id);
  };

  return (
    <>
      <div>
        <p className="subheader">Aliases</p>
        <Preview
          DeletePreview={DeletePreview}
          ViewPreview={ViewPreview}
          showlist={[["Firstname", "LastName", "comma", "DOB"]]}
          list={
            edit
              ? (editList.map((item) => ({ ...item })) as {
                  [key: string]: string | number | boolean;
                }[])
              : (Mainfields.InvolvementTab.Aliases.map((item) => ({
                  ...item,
                })) as { [key: string]: string | number | boolean }[])
          }
        />

        <MultiAliasesDataUI
          MultiAliases={Mainfields}
          setMultiAliases={setMainfields}
          MultiAliasesval={
            edit
              ? Mainfields.InvolvementTab.Aliases.filter((val) => {
                  if (val.id == previewcount) {
                    return val;
                  }
                })[0]
              : Mainfields.InvolvementTab.Aliases[
                  Mainfields.InvolvementTab.Aliases.length - 1
                ]
          }
        />

        <div className="edit-button_">
          <ButtonComponents
            name={edit ? "Save" : "Add Alliase"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
