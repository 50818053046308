import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MasterMainIParams } from "../../../../MasterOverview/MasterNameIState";
import { BusinessData } from "./BusinessIState";
import { BusinessFunction } from "./BusinessFunction";


export const Overview: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { nameId } = useParams<MasterMainIParams>();
    const businessfunction=new BusinessFunction();
    const [fields, setFields] = useState<BusinessData>({BusinessName:"",BusinessType:"",InvolvementType:"",
    Address:"",Case:"",
    });
    const initialRender = () => {
      if (nameId) {
        businessfunction.getBusinessData(
          setLoading,
          setFields,
          fields,nameId
        );
      }
    };
    useEffect(() => {
      initialRender();
    }, []);
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Overview</strong>
          </p>
         
        </div>

        <div className="overview-content">
          <div className="overview-row">
            <p>
              Business Name: <strong>{fields?.BusinessName}</strong>
            </p>
            <p>
            Involvement Type: <strong>{fields?.InvolvementType}</strong>
            </p>
            <p>
              Business Type: <strong>{fields?.BusinessType}</strong>
            </p>
           
          </div>
        
        </div>
      </div>
    </div>
  );
};
