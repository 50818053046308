import React from "react";
import { DateBoxComponents } from "../../../../../../../../CommonComponents/Fields/DateBox/DateBox";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TimePickerComponenets } from "../../../../../../../../CommonComponents/Fields/TimePicker/TimePickerComponenets";

const DetainmentInfromation: React.FC = () => {
  return (
    <div>
      <p className="subheader">Detainment information</p>
      <div className="additional-column">
        <div className="employement">
          <DateBoxComponents disabled={true} fieldName="Detain Date" value="" />
          <TimePickerComponenets disabled={true} fieldName="Detain Time" />
        </div>
        <div className="employement">
          <DateBoxComponents
            disabled={true}
            fieldName="Release Date"
            value=""
          />
          <TimePickerComponenets disabled={true} fieldName="Release Time" />
        </div>
        <div className="Involvement_wall_"></div>
        <div className="width_field">
          <TextBoxComponent disabled={true} fieldName="Jail ORI" value="" />
        </div>
      </div>
    </div>
  );
};

export default DetainmentInfromation;
