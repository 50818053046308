import React, { useEffect, useState } from "react";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DateBoxComponents } from "../../../../../CommonComponents/Fields/DateBox/DateBox";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../assets";
import { ModalComponent } from "../../../../../CommonComponents/Modal/ModalComponent";
import RecoveryModal from "./RecoveryModal";
import useSaveChangesPopup from "../../../../../../hooks/useSaveChangesPopup";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { getSaveAddressIRes, SetFormDataIState } from "./AddRecoveryIState";
import { AddRecoveryFunction } from "./AddRecoveryFunction";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import { NumberBoxComponents } from "../../../../../CommonComponents/Fields/NumberBox/NumberBoxComponents";
import { GetRaceDataIState } from "../AddVehicleIState";
import { TimePickerComponenets } from "../../../../../CommonComponents/Fields/TimePicker/TimePickerComponenets";
import { useNavigate, useParams } from "react-router-dom";
import { VehicleIParams } from "../Vehicle/VehicalI";

const RecoveryForm: React.FC = () => {
  const addRecovery = new AddRecoveryFunction();
  const navigation = useNavigate();
  const { showSaveTheChangesPopup, openPopup, onClose } = useSaveChangesPopup();
  const [loading, setLoading] = useState<boolean>(false);
  const { incidentId, vehicleId, TowId } = useParams<VehicleIParams>();
  const [Condition, setCondition] = useState<GetRaceDataIState[]>([]);
  const [SaveAddress, setSaveAddress] = useState<getSaveAddressIRes[]>([]);
  const [recoveryId, setRecoveryId] = useState<string>("");
  const [formData, setFormData] = useState<SetFormDataIState>({
    Condition: "",
    VehicleId: "",
    VehicleRecoveryId: "",
    EstimatedValue: 0,
    IsRecovery: false,
    IsVehicleDriveable: false,
    NotificationDateTime: "",
    RecoveryTime: "",
    NotificationTime: "",
    OwnerNotifiedOfRecovery: false,
    RecoveryDateTime: "",
    RecoveryLocationId: "",
    RecoveryOfficer: "",
    ReleasingUserId: "",
  });

  const handleInputChange = (field: string, value: string | number | null) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const redirectTOEDitMOde = (vehicleId: string) => {
    navigation(`/incidents/${incidentId}/vehicles/${vehicleId}/edit`);
  };
  const AddRecovery = () => {
    if (incidentId && vehicleId) {
      addRecovery.addRecovery(
        setLoading,
        formData,
        incidentId,
        vehicleId,
        setFormData,
        redirectTOEDitMOde,
        setRecoveryId
      );
    }
  };
  const handleClick = () => {
    AddRecovery();
  };
  const initialRender = () => {
    addRecovery.getCondition(setCondition, setLoading);

    if (incidentId && vehicleId) {
      addRecovery.getRecoveryData(
        setLoading,
        setFormData,
        incidentId,
        vehicleId
      );
    }
  };

  useEffect(() => {
    initialRender();
  }, []);

  const oncancel = () => {
    setFormData({
      Condition: "",
      VehicleId: "",
      VehicleRecoveryId: "",
      EstimatedValue: 0,
      IsRecovery: false,
      IsVehicleDriveable: false,
      NotificationDateTime: "",
      RecoveryTime: "",
      NotificationTime: "",
      OwnerNotifiedOfRecovery: false,
      RecoveryDateTime: "",
      RecoveryLocationId: "",
      RecoveryOfficer: "",
      ReleasingUserId: "",
    });
  };

  useEffect(() => {
    initialRender();
  }, []);

  const getAddress = async () => {
    try {
      const data = await addRecovery.getSaveAddress();

      return data;
    } catch (error) {
      console.error("Error fetching addresses:", error);

      return [];
    }
  };

  const loadAddressData = async () => {
    const data = await getAddress();
    setSaveAddress(data);
  };

  useEffect(() => {
    loadAddressData();
  }, []);
  const handleAddLocation = () => {
    openPopup();
  };

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null
  ) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={() => {
          onClose();
          loadAddressData();
        }}
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
        open={showSaveTheChangesPopup}
        width={1200}
        innerContant={<RecoveryModal onClose={onClose} />}
      />
      <div className="_recovery_form_container">
        <div className="_add_vehicle_form_desc_title">
          <p>Recovery Information</p>
        </div>

        <div className="_recover_form_field_container">
          <div className="_recovery_checkbox">
            <CheckBoxComponent
              fieldName="Recovery"
              value={formData.IsRecovery}
              onChangeValue={(e) => {
                onChangeValue("IsRecovery", e);
              }}
            />
          </div>

          <div className="_recovery_form_field_upper">
            <div className="_recovery_date">
              <TextBoxComponent
                fieldName="Recovery Officer"
                value={formData.RecoveryOfficer}
                onChangeValue={(newValue) => {
                  handleInputChange("RecoveryOfficer", newValue);
                }}
              />
            </div>
            <div className="_recovery_date">
              <DateBoxComponents
                fieldName="Recovery Date"
                value={formData.RecoveryDateTime}
                onValueChange={(newValue) => {
                  handleInputChange("RecoveryDateTime", newValue);
                }}
              />
            </div>

            <div className="_recovery_time">
              <TimePickerComponenets
                fieldName="Recovery Time"
                value={formData.RecoveryTime}
                onValueChange={(newValue) => {
                  handleInputChange("RecoveryTime", newValue);
                }}
              />
            </div>
          </div>

          <div className="_recovery_form_field_middle">
            <div className="_recovery_location_id">
              <DropdownComponent
                fieldName="Recovery Location"
                dataSource={SaveAddress.map((address) => ({
                  id: address.LocationId,
                  value: address.LocationName,
                }))}
                value={formData.RecoveryLocationId}
                handleRowClick={(e) => {
                  handleInputChange("RecoveryLocationId", e);
                }}
              />
              <div className="_add_condition_btn">
                <ButtonComponents
                  name="Add Location"
                  color="white"
                  textColor="#3672b3"
                  borderColor="white"
                  showBackgroundColor={true}
                  icon={Add}
                  handleClick={handleAddLocation}
                />
              </div>
            </div>
          </div>

          <div className="_recovery_form_field_end">
            <div className="_recovery_date">
              <NumberBoxComponents
                fieldName="Estimated Value"
                value={formData.EstimatedValue}
                onValueChange={(e) => {
                  handleInputChange("EstimatedValue", e);
                }}
              />
            </div>

            <div className="_recovery_condition">
              <DropdownComponent
                fieldName="Condition"
                value={formData.Condition}
                dataSource={Condition.map((val) => {
                  return {
                    id: val.SystemLevelCodeId,
                    value: val.LongCode,
                  };
                })}
                handleRowClick={(e) => {
                  handleInputChange("Condition", e);
                }}
              />
            </div>

            <div className="_vehicle_drivable">
              <CheckBoxComponent
                fieldName="Vehicle Drivable"
                value={formData.IsVehicleDriveable}
                onChangeValue={(e) => {
                  onChangeValue("IsVehicleDriveable", e);
                }}
              />
            </div>
          </div>

          <div className="_owner_notified_recovery">
            <CheckBoxComponent
              fieldName="Owner Notified of recovery"
              value={formData.OwnerNotifiedOfRecovery}
              onChangeValue={(e) => {
                onChangeValue("OwnerNotifiedOfRecovery", e);
              }}
            />
          </div>

          <div className="_recovery_form_field_upper">
            <div className="_notification_date _recovery_date">
              <DateBoxComponents
                fieldName="Notification Date"
                value={formData.NotificationDateTime}
                onValueChange={(newValue) => {
                  handleInputChange("NotificationDateTime", newValue);
                }}
              />
            </div>

            <div className="_notification_time _recovery_date">
              <TimePickerComponenets
                fieldName="Notification Time"
                value={formData.NotificationTime}
                onValueChange={(newValue) => {
                  handleInputChange("NotificationTime", newValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="submit_btn_container">
        <Submit
          onSave={handleClick}
          saveColor="#3672B3
  "
          onCancel={oncancel}
        />
      </div>
    </>
  );
};

export default RecoveryForm;
