import { InvolvementMainIProps } from "./InvolvementMainI";
import { Address } from "./Components/MultipleAddress/Address";
import { PhoneNumber } from "./Components/PhoneNumber/PhoneNumber";
import { EmailAddress } from "./Components/EmailAddress/EmailAddress";
import { VictimInformation } from "./Components/VictimInformation/VictimInformation";
import { Involvement } from "./Components/Involment/Involvement";

export const InvolvementMain: React.FC<InvolvementMainIProps> = ({
  setMainfieldsBusiness,
  MainfieldsBusiness,
  businessValidation,
}) => {
  return (
    <>
      <div>
        <Involvement
          MainfieldsBusiness={MainfieldsBusiness}
          setMainfieldsBusiness={setMainfieldsBusiness}
          businessValidation={businessValidation}
        />
        <div className="Involvement_wall_"></div>
        <div className="Involvement_Address_">
          <div className="Involvement_Address_1" style={{ width: "60%" }}>
            <Address
              MainfieldsBusiness={MainfieldsBusiness}
              setMainfieldsBusiness={setMainfieldsBusiness}
              businessValidation={businessValidation}
            />
          </div>

          <div className="Involvement_wall"></div>
          <div className="Involvement_Address_1">
            <div>
              <PhoneNumber
                MainfieldsBusiness={MainfieldsBusiness}
                setMainfieldsBusiness={setMainfieldsBusiness}
              />
              <EmailAddress
                MainfieldsBusiness={MainfieldsBusiness}
                setMainfieldsBusiness={setMainfieldsBusiness}
              />
            </div>
          </div>
        </div>

        {MainfieldsBusiness.Involvement.InvolvementType ==
          "bf4d076f-7c65-48f9-b6d6-29afa3d693bc" && (
          <VictimInformation
            MainfieldsBusiness={MainfieldsBusiness}
            setMainfieldsBusiness={setMainfieldsBusiness}
            businessValidation={businessValidation}
          />
        )}
      </div>
    </>
  );
};
