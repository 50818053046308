
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Case" },
    { field: "Reported Date" },
    { field: "Agency" },
   
    { field: "Title" },
    { field: "Involvement" },
    { field: "ICR" },
    { field: "Type" },
  ];
};
