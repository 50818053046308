import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../AuditPage/AuditPage.css";
import { coloum } from "./Config";
import { SetTableData } from "./AuditI";
import { AuditHistoryFunction } from "./AuditFunction";
import { Filter } from "../Components/Filter/Filter";
import useDelayedEffect from "../../../../../hooks/useDelayedEffect";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../CommonComponents/Table/Table";
import CommonIcons from "../Components/CommonIcons/CommonIcons";
import { setUserdata } from "../../../../../Redux/AuditReducer";
import { useDispatch } from "react-redux";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

const AuditPage = () => {
  const navigation = useNavigate();

  const auditHistoryFunction = new AuditHistoryFunction();
  const [Action, setAction] = useState<string>("");
  const [UserActionValue, setUserActionValue] = useState<string>("");
  const [Creationdatefrom, setCreationdatefrom] = useState<string | null>("");
  const [Creationdateto, setCreationdateto] = useState<string | null>("");
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [IsDetailExist, setIsDetailExist] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("UserAction");
  const [allAuditHistoryTableData, setallAuditHistoryTableData] = useState<
    SetTableData[]
  >([]);
  const dispatch = useDispatch();
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const getAuditHistoryTableData = () => {
    auditHistoryFunction.getAuditHistoryTableData(
      setTableData,
      pageNumber,
      pageCount,
      setLoading,
      setTableTotalRecord,
      Action,
      Creationdatefrom,
      Creationdateto,
      userName,
      UserActionValue,
      OrderByDescending,
      OrderBy,
      IsDetailExist
    );
  };

  useEffect(() => {
    getAuditHistoryTableData();
  }, [pageCount, pageNumber]);

  useEffect(() => {
    auditHistoryFunction.getAllAuditHistoryTableData(
      setallAuditHistoryTableData,
      setLoading
    );
  }, []);

  useDelayedEffect(getAuditHistoryTableData, 700, [
    userName,
    Action,
    UserActionValue,
    Creationdatefrom,
    Creationdateto,
    OrderByDescending,
  ]);
  const onRowClicked = (data: SetTableData) => {
    if (data?.IsDetailExist) {
      const auditId = data?.ID;
      console.log(data?.IsDetailExist);
      dispatch(
        setUserdata({
          ipaddress: data["IP Address"],
          ModifiedByUserName: data["Action By"],
          CreatedOn: data.CreatedOn,
          ModifiedOn: data.CreatedOn,
        })
      );
      navigation(`/audit-detail/${auditId}`);
    } else {
      console.log("Details do not exist for this row.");
    }
  };
  const onRefresh = () => {
    window.location.reload();
  };
  
  return (
    <>
      <Loader loading={loading} />
      <div className="AgencyManagement">
    
        <div className="Audit-Header">
          <p className="header">Audit</p>
          <div className="Image">
            <CommonIcons />
          </div>
        </div>
        <div className="Audit-filter">
          <Filter
            UserName={userName}
            setUserName={setUserName}
            Action={Action}
            setAction={setAction}
            Creationdatefrom={Creationdatefrom}
            setCreationdatefrom={setCreationdatefrom}
            Creationdateto={Creationdateto}
            setCreationdateto={setCreationdateto}
            onRefresh={onRefresh}
            tableData={allAuditHistoryTableData}
            setUserActionValue={setUserActionValue}
          />
        </div>
        <div className="table">
          <Table
            columns={coloum()}
            columnsValue={tableData}
            setpageCount={setpageCount}
            setpageNumber={setpageNumber}
            tableTotalRecord={tableTotalRecord}
            onRowClicked={onRowClicked}
            OrderByDescending={OrderByDescending}
            setOrderByDescending={setOrderByDescending}
            OrderBy={OrderBy}
            setOrderBy={setOrderBy}
              
          />
        </div>
      </div>
    </>
  );
};

export default AuditPage;
