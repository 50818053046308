import React, { useEffect, useState } from "react";
import { AdditionalMainFunction } from "./AdditionalMainFunction";
import { useParams } from "react-router-dom";
import { AdditionalData, MasterMainIParams } from "../../MasterNameIState";

export const Additional: React.FC = () => {
  const additionalFunction = new AdditionalMainFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<AdditionalData>({
    Disability: [],
    FingerPrints: "",
  });

  const { nameId } = useParams<MasterMainIParams>();
  const initialRender = () => {
    if (nameId) {
      additionalFunction.getAdditional(setLoading, setFields,fields,nameId);
    }
  };
  useEffect(() => {
    initialRender();
  }, []);
  return (
    <div style={{ width: "100%", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Additional Information</strong>
          </p>
          
        </div>
        <div className="overview-content">
          <div className="overview-row">
            <span className="overview-label">
              Disability:<strong>{fields.Disability[0]?.DisabilityCode}</strong>
            </span>
          </div>
          <div className="overview-row">
            <span className="overview-label">
              Finger Prints on File:<strong>{fields.FingerPrints}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
