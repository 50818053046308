import { IdentificationAndContactIProps } from "./IdentificationIProps";
import { Identification } from "./Components/MultipleIdentification/Identification";
import { PhoneNumber } from "./Components/PhoneNumber/PhoneNumber";
import { EmailAddress } from "./Components/EmailAddress/EmailAddress";

export const IdentificationAndContact: React.FC<
  IdentificationAndContactIProps
> = ({ Mainfields, setMainfields, Participantionvalidation }) => {
  return (
    <>
      <div>
        <Identification Mainfields={Mainfields} setMainfields={setMainfields} />
        <div
          className="Involvement_Address"
          style={{ display: "flex", gap: "30px" }}
        >
          <div className="Involvement_Address_1">
            <PhoneNumber
              Mainfields={Mainfields}
              setMainfields={setMainfields}
              Participantionvalidation={Participantionvalidation}
            />
          </div>
          <div className="Involvement_Address_1">
            <EmailAddress
              Mainfields={Mainfields}
              setMainfields={setMainfields}
              Participantionvalidation={Participantionvalidation}
            />
          </div>
        </div>
        <div className="Involvement_wall_"></div>
      </div>
    </>
  );
};
