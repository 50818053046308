import { useEffect, useState } from "react";
import {
  MultiEmergencyUIIProps,
  MultipleEmergencyContactComponentsIProps,
} from "./MultipleEmergencyContactI";
import {
  GetRaceDataIState,
  MultipleEmergencyContactIState,
} from "../../../Involvement/InvolvementMainI";
import { InvolvementMainFunction } from "../../../Involvement/InvolvementMainFunction";
import { Address } from "../MultipleAddress/Address";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../../../../assets";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";
import { MultipleEmergencyContactFunction } from "./MultipleEmergencyContactFunction";
import { PhoneNumber } from "../Phone/PhoneNumber";
import { EmailAddress } from "../Email/EmailAddress";

const MultiAliasesDataUI: React.FC<MultiEmergencyUIIProps> = ({
  Mainfields,
  setMainfields,
  addressval,
  RelationData,
  Participantionvalidation,
  NameSuffix,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (id == val.id) {
            if (key2 && value2) {
              return {
                ...val,
                onChange: true,
                EmergencyContact: {
                  ...val.EmergencyContact,
                  [key]: value,
                  [key2]: value2,
                },
              };
            } else {
              return {
                ...val,
                EmergencyContact: { ...val.EmergencyContact, [key]: value },
                onChange: true,
              };
            }
          } else {
            return { ...val };
          }
        }
      ),
    });
  };

  return (
    <>
      <div className="Involvement_main">
        <div className="Involvement_left">
          <div className="Involvement_left_row">
            <DropdownComponent
              dataSource={RelationData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Relation*"
              value={addressval.EmergencyContact.Relation}
              handleRowClick={(e, dropdownValue) => {
                onChangeValueNumber(
                  "Relation",
                  e,
                  addressval.id,
                  "RelationDropdownName",
                  dropdownValue
                );
              }}
            />
            <TextBoxComponent
              value={addressval.EmergencyContact.LastName}
              fieldName="Last name*"
              onChangeValue={(e) => {
                onChangeValueNumber("LastName", e, addressval.id);
              }}
            />
            <TextBoxComponent
              value={addressval.EmergencyContact.Firstname}
              fieldName="First name*"
              onChangeValue={(e) => {
                onChangeValueNumber("Firstname", e, addressval.id);
              }}
              validation={Participantionvalidation.EmergencyContact.firstname}
            />
            <TextBoxComponent
              value={addressval.EmergencyContact.Middlename}
              fieldName="Middle name*"
              onChangeValue={(e) => {
                onChangeValueNumber("Middlename", e, addressval.id);
              }}
            />
          </div>
        </div>
        <div className="Involvement_right">
          <div className="Involvement_left_row" style={{ width: "50%" }}>
            <DropdownComponent
              dataSource={NameSuffix.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Suffix*"
              value={addressval.EmergencyContact.Suffix}
              handleRowClick={(e) => {
                onChangeValueNumber("Suffix", e, addressval.id);
              }}
            />
          </div>
          <div className="Involvement_right_row"></div>
        </div>
      </div>
      <div className="Involvement_Address_">
        <div className="Involvement_Address_1">
          <Address
            addressval={addressval}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
          />
        </div>
        <div className="Involvement_Address_1" style={{ width: "100%" }}>
          <PhoneNumber
            addressval={addressval}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
            Participantionvalidation={Participantionvalidation}
          />
          <EmailAddress
            addressval={addressval}
            Mainfields={Mainfields}
            setMainfields={setMainfields}
            Participantionvalidation={Participantionvalidation}
          />
        </div>
      </div>
    </>
  );
};

export const MultipleEmergencyContactComponents: React.FC<
  MultipleEmergencyContactComponentsIProps
> = ({ Mainfields, setMainfields, Participantionvalidation }) => {
  const involvementMainFunction = new InvolvementMainFunction();

  const [RelationData, setRelationData] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [NameSuffix, setNameSuffix] = useState<GetRaceDataIState[]>([]);
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<MultipleEmergencyContactIState[]>(
    []
  );
  const [previewcount, setpreviewcount] = useState<number>(1);
  const multipleEmergencyContactFunction = new MultipleEmergencyContactFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    multipleEmergencyContactFunction.Save();
  };

  const handleClick = () => {
    multipleEmergencyContactFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    multipleEmergencyContactFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    multipleEmergencyContactFunction.DeletePreview(id);
  };

  const initialRender = async () => {
    involvementMainFunction.getRelationData(setRelationData);
    involvementMainFunction.getStreetPrefix(setStreetPrefix);
    involvementMainFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getSuffix(setNameSuffix);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <div style={{ marginTop: "2%" }}>
          <p className="subheader">Emergency Contact</p>

          <Preview
            DeletePreview={DeletePreview}
            ViewPreview={ViewPreview}
            displaylist={
              edit
                ? editList.map((val) => {
                    return {
                      list: [
                        `${
                          val.EmergencyContact.RelationDropdownName
                            ? `${val.EmergencyContact.RelationDropdownName},`
                            : ""
                        } ${
                          val.EmergencyContact.Firstname
                            ? val.EmergencyContact.Firstname
                            : ""
                        } ${
                          val.EmergencyContact.LastName
                            ? `, ${val.EmergencyContact.LastName}`
                            : ""
                        }`,
                        ...val.Address.AddressData.map((Addressval) => {
                          return `${
                            Addressval["StreetNumber"]
                              ? `${Addressval["StreetNumber"]}, `
                              : ""
                          } ${
                            Addressval.Street ? `${Addressval.Street},` : ""
                          } ${Addressval.City ? Addressval.City : ""} ${
                            Addressval.StateProvidenceDropdownvalue
                              ? `, ${Addressval.StateProvidenceDropdownvalue}`
                              : ""
                          } ${
                            Addressval.ZipCode ? `,${Addressval.ZipCode}` : ""
                          } ${
                            Addressval.CountryDropdownValue
                              ? `,${Addressval.CountryDropdownValue}`
                              : ""
                          }
                           ${
                             Addressval.AddressLine1
                               ? `,${Addressval.AddressLine1}`
                               : ""
                           }
                            ${
                              Addressval.AddressLine2
                                ? `,${Addressval.AddressLine2}`
                                : ""
                            }
                             ${
                               Addressval.ForeignAddressStateProvinceRegion
                                 ? `,${Addressval.ForeignAddressStateProvinceRegion}`
                                 : ""
                             }
                              ${
                                Addressval.ForeignAddressZip
                                  ? `,${Addressval.ForeignAddressZip}`
                                  : ""
                              }

                               ${
                                 Addressval.ForeignAddressCountry
                                   ? `,${Addressval.ForeignAddressCountry}`
                                   : ""
                               }
                          
                          
                          `;
                        }),

                        ...val.PhoneNumber.map((PhoneNumberval) => {
                          return `${
                            (PhoneNumberval.TypeDropdownvalue ||
                              PhoneNumberval.Number) &&
                            "Phone :"
                          } ${
                            PhoneNumberval.TypeDropdownvalue
                              ? `${PhoneNumberval.TypeDropdownvalue},`
                              : ""
                          } ${
                            PhoneNumberval.Number ? PhoneNumberval.Number : ""
                          }`;
                        }),
                        ...val.Email.map((Emailval) => {
                          return `${
                            (Emailval.TypeDropdownvalue || Emailval.Email) &&
                            "Email :"
                          } ${
                            Emailval.TypeDropdownvalue
                              ? `${Emailval.TypeDropdownvalue},`
                              : ""
                          } ${Emailval.Email ? Emailval.Email : ""}`;
                        }),
                      ],
                      id: val.id,
                      push: val.push ? val.push : false,
                    };
                  })
                : Mainfields.MultipleEmergencyContact.map((val) => {
                    return {
                      list: [
                        `${
                          val.EmergencyContact.RelationDropdownName
                            ? `${val.EmergencyContact.RelationDropdownName},`
                            : ""
                        } ${
                          val.EmergencyContact.Firstname
                            ? val.EmergencyContact.Firstname
                            : ""
                        } ${
                          val.EmergencyContact.LastName
                            ? `, ${val.EmergencyContact.LastName}`
                            : ""
                        }`,
                        ...val.Address.AddressData.map((Addressval) => {
                          return `${
                            Addressval["StreetNumber"]
                              ? `${Addressval["StreetNumber"]}, `
                              : ""
                          } ${
                            Addressval.Street ? `${Addressval.Street},` : ""
                          } ${Addressval.City ? Addressval.City : ""} ${
                            Addressval.StateProvidenceDropdownvalue
                              ? `, ${Addressval.StateProvidenceDropdownvalue}`
                              : ""
                          } ${
                            Addressval.ZipCode ? `,${Addressval.ZipCode}` : ""
                          } ${
                            Addressval.CountryDropdownValue
                              ? `,${Addressval.CountryDropdownValue}`
                              : ""
                          }
                           ${
                             Addressval.AddressLine1
                               ? `,${Addressval.AddressLine1}`
                               : ""
                           }
                            ${
                              Addressval.AddressLine2
                                ? `,${Addressval.AddressLine2}`
                                : ""
                            }
                             ${
                               Addressval.ForeignAddressStateProvinceRegion
                                 ? `,${Addressval.ForeignAddressStateProvinceRegion}`
                                 : ""
                             }
                              ${
                                Addressval.ForeignAddressZip
                                  ? `,${Addressval.ForeignAddressZip}`
                                  : ""
                              }

                               ${
                                 Addressval.ForeignAddressCountry
                                   ? `,${Addressval.ForeignAddressCountry}`
                                   : ""
                               }
                          
                          
                          `;
                        }),

                        ...val.PhoneNumber.map((PhoneNumberval) => {
                          return `${
                            (PhoneNumberval.TypeDropdownvalue ||
                              PhoneNumberval.Number) &&
                            "Phone :"
                          } ${
                            PhoneNumberval.TypeDropdownvalue
                              ? `${PhoneNumberval.TypeDropdownvalue},`
                              : ""
                          } ${
                            PhoneNumberval.Number ? PhoneNumberval.Number : ""
                          }`;
                        }),
                        ...val.Email.map((Emailval) => {
                          return `${
                            (Emailval.TypeDropdownvalue || Emailval.Email) &&
                            "Email :"
                          } ${
                            Emailval.TypeDropdownvalue
                              ? `${Emailval.TypeDropdownvalue},`
                              : ""
                          } ${Emailval.Email ? Emailval.Email : ""}`;
                        }),
                      ],
                      id: val.id,
                      push: val.push ? val.push : false,
                    };
                  })
            }
          />
        </div>

        <MultiAliasesDataUI
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          addressval={
            edit
              ? Mainfields.MultipleEmergencyContact.filter((val) => {
                  if (val.id == previewcount) {
                    return val;
                  }
                })[0]
              : Mainfields.MultipleEmergencyContact[
                  Mainfields.MultipleEmergencyContact.length - 1
                ]
          }
          RelationData={RelationData}
          StreetPrefix={StreetPrefix}
          StreetSuffix={StreetSuffix}
          NameSuffix={NameSuffix}
          Participantionvalidation={Participantionvalidation}
        />
      </div>
      <div className="edit-button_">
        <ButtonComponents
          name={edit ? "Save" : "Add Emergency Contact"}
          handleClick={edit ? Save : handleClick}
          color="white"
          textColor="#3672b3"
          borderColor="#3672b3"
          showBackgroundColor={true}
          icon={Add}
        />
      </div>
    </>
  );
};
