import { BloodAlochol } from "./Components/BloodAlocohol/BloodAlocohol";
import DetainmentInfromation from "./Components/DetainmentInformation/DetainmentInfromation";
import { SubstanceInvolvement } from "./Components/SubstanceInvolvement/Substance";
import { MultipleSubstanceIProps } from "./Components/SubstanceInvolvement/SubstanceIProps";

export const SubstanceMainDetainment: React.FC<MultipleSubstanceIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <div className="width_field">
            <SubstanceInvolvement
              Mainfields={Mainfields}
              setMainfields={setMainfields}
            />
          </div>

          <div className="width_field">
            <BloodAlochol
              setMainfields={setMainfields}
              Mainfields={Mainfields}
            />
          </div>
        </div>
        <div className="width_field">
          <DetainmentInfromation />
        </div>
      </div>
    </>
  );
};
