import { useEffect, useState } from "react";
import { InvolvementMain } from "./Involvement/InvolvementMain";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { TabsComponents } from "../../../../../CommonComponents/TabsComponents/TabsComponents";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { InvolvementMainIParams } from "../AddNewName/Involvement/InvolvementMainI";
import { InvolvementMainFunction } from "./Involvement/InvolvementMainFunction";
import useIncidentsBussinessStateManagement from "../../../../../../hooks/IncidentsParticipant/useIncidentsBussinessStateManagement";
import { businessValidationIState } from "./InvolvementMainI";
import { successCreatedMessage } from "src/utils/const";
import { openNotificationWithIcon } from "src/Components/CommonComponents/Toster/Toster";

export const AddBusinessName: React.FC = () => {
  const { Mainfields, setMainfields } = useIncidentsBussinessStateManagement();
  const [businessValidation, setbusinessValidation] =
    useState<businessValidationIState>({
      involvement: {
        BusinessType: false,
        BusinessName: false,
        InvolvementType: false,
        LocationCategory: false,
        VictimType: false,
        EnforcementOfficialActivityCategoryCode: false,
        EnforcementOfficialAssignmentCategoryCode: false,
      },
    });
  const involvementbusinessMainFunction = new InvolvementMainFunction();
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigation = useNavigate();
  const RediretToEDitPage = (participantIdr: string) => {
    navigation(
      `/incidents/${incidentId}/businessparticipant/${participantIdr}/edit`
    );
  };

  const onInvolvementSave = async () => {
    if (incidentId) {
      await involvementbusinessMainFunction.InvolvementSave(
        setLoading,
        Mainfields,
        incidentId,
        RediretToEDitPage,
        participantId,
        setbusinessValidation,
        businessValidation
      );
      openNotificationWithIcon("success", successCreatedMessage);
    }
  };

  const initialRender = async () => {
    if (incidentId && participantId) {
      setLoading(true);
      await involvementbusinessMainFunction.getInvolvement(
        incidentId,
        participantId,
        setMainfields,
        Mainfields
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div style={{ backgroundColor: "#B3C9E2" }}>
        <TabsComponents
          activeTab={activeTab}
          tabList={[{ name: "Involvement", id: 1 }]}
          handleTabChange={setActiveTab}
        />
      </div>
      {activeTab === 0 && (
        <InvolvementMain
          MainfieldsBusiness={Mainfields}
          setMainfieldsBusiness={setMainfields}
          businessValidation={businessValidation}
        />
      )}
      <div className="edit-button_">
        <Submit
          onSave={() => {
            onInvolvementSave();
          }}
        />
      </div>
    </>
  );
};
