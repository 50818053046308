import { Loader } from "../Components/CommonComponents/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { fetchPermissions } from "../services/permissionsService";
import { Router } from "./Router";
import { loginRequest } from "../Components/Pages/SSO/appConfig";
import { UserDetail } from "../services/UserDetail";
import { useNavigate } from "react-router-dom";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";
import { REACT_Redirect_URL } from "src/utils/const";

export const RouterMain = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const navigation = useNavigate();

  const initialRender = async () => {
    const token = localStorage.getItem("AccessToken");

    try {
      const redirectResponse = await instance.handleRedirectPromise();
      if (redirectResponse) {
        localStorage.setItem("AccessToken", redirectResponse.accessToken);
        window.location.href = "/";
        return;
      }
    } catch (error) {
      console.error("Error during redirect handling:", error);
    }

    if (!token) {
      if (!instance) {
        return;
      }

      if (accounts.length === 0) {
        try {
          await instance.loginRedirect({
            ...loginRequest,
            redirectUri: REACT_Redirect_URL,
          });
          return;
        } catch (error) {
          console.error("Error during login redirect:", error);
          return;
        }
      }

      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        localStorage.setItem("AccessToken", response.accessToken);
        window.location.href = "/";
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          await instance.loginRedirect({
            ...loginRequest,
            redirectUri: REACT_Redirect_URL,
          });
        } else {
          console.error("Token acquisition failed:", error);
        }
      }
    }

    if (token) {
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const tokenExpirationTime = decodedToken.exp;
        if (tokenExpirationTime) {
          if (tokenExpirationTime < currentTime) {
            if (instance) {
              localStorage.clear();
              await instance.logoutRedirect({
                postLogoutRedirectUri: REACT_Redirect_URL,
              });
            }
          }
        }
      }

      let res = await UserDetail(dispatch, setLoading);
      if (!res) {
        navigation("/error/401");
        return;
      }
      await fetchPermissions(setLoading, dispatch);
    }
  };

  useEffect(() => {
    initialRender();
  }, [instance, accounts]);

  return (
    <>
      <Loader loading={loading} />
      {!loading && <Router />}
    </>
  );
};
