import { Fetcher } from "../../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";
import {
  BloodAlocoholIRes,
  BloodTableData,
  ContactIRes,
} from "../ParticipantOverviewIState";

const fetcher = new Fetcher();
export class BloodAlocoholfunction {
  async getBloodAlocohol(
    participantId: string,
    incidentId: string,
    setTableData: (data: BloodTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/blood-alcohol-level`
    );
    let res: BloodAlocoholIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: BloodAlocoholIRes[]) {
    return data?.map((val: BloodAlocoholIRes, i: number) => {
      return {
        Type: val.Type,
        Result: val.TestResult,
        "Date Entered": formatDate(val.TestDateTime),
      };
    });
  }
}
