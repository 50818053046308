import { Address } from "./Components/MultipleAddress/Address";
import { Aliases } from "./Components/MultiAliases/Aliases";
import { ScarsMarksTattoosComponent } from "./Components/ScarsMarksTattoos/ScarsMarksTattoos";
import { Involvement } from "./Components/Involvement/Involvement";
import { InvolvementMainIProps } from "./InvolvementMainI";
import { Demographics } from "./Components/Demographics/Demographics";
import { VictimInformation } from "./Components/VictimInformation/VictimInformation";
import { ArrestInformation } from "./Components/ArrestInformation/ArrestInformation";
import { BiasesComponents } from "./Components/Biases/Biases";

export const InvolvementMain: React.FC<InvolvementMainIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation
}) => {
  return (
    <>
      <div>
        <Involvement Participantionvalidation={Participantionvalidation} Mainfields={Mainfields} setMainfields={setMainfields} />
        <Aliases Mainfields={Mainfields} setMainfields={setMainfields} />
        <div className="Involvement_Address">
          <div className="Involvement_Address_1">
            <Address Mainfields={Mainfields} setMainfields={setMainfields} Participantionvalidation={Participantionvalidation} />

            <div style={{ marginTop: "5%" }}>
              <p className="subheader">Biases </p>
              <BiasesComponents
                Mainfields={Mainfields}
                setMainfields={setMainfields}
              />
            </div>
          </div>

          <div className="Involvement_wall"></div>
          <div className="Involvement_Address_1">
            <Demographics
              Mainfields={Mainfields}
              setMainfields={setMainfields}
              Participantionvalidation={Participantionvalidation}
            />
            <div style={{ marginTop: "5%" }}>
              <p className="subheader">Scars Marks and Tattoos </p>
              <ScarsMarksTattoosComponent
                Mainfields={Mainfields}
                setMainfields={setMainfields}
                Participantionvalidation={Participantionvalidation}
              />
            </div>
          </div>
        </div>
        {Mainfields.InvolvementTab.Involvement.InvolvementType ==
          "bf4d076f-7c65-48f9-b6d6-29afa3d693bc" && (
          <VictimInformation
            Mainfields={Mainfields}
            setMainfields={setMainfields}
          />
        )}
        {Mainfields.InvolvementTab.Involvement.InvolvementType ==
          "667F4DAA-DFE0-491E-9B67-C3847D5CAC0A".toLowerCase() && (
          <ArrestInformation
            Mainfields={Mainfields}
            setMainfields={setMainfields}
          />
        )}
      </div>
    </>
  );
};
