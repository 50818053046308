import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, setTableData } from "./NarrativeIState";
import edit_icon from "../../../../../assets/icons/edit.svg";
import delete_icon from "../../../../../assets/icons/delete.svg";
import { NavigateFunction } from "react-router-dom";
export const OrderByFieldName: OrderByFieldNameI = {
  "User Submitted": "FirstName",
  "First Name": "FirstName",
  Narrative: "Title",
  "Middle Name": "MiddleName",
  "Last Name": "LastName",
  "Created On": "CreatedOn",
  "Modified On": "ModifiedOn",
  "Public Narrative": "LastName",
  "Narrative Type": "NarrativeType",
};
export const coloum: (
  setDeletePop: (data: boolean) => void,
  setDeleteNarrativeId: (data: string) => void,
  navigate: NavigateFunction,
  incidentId: string | undefined,
  Delete: boolean,
  Update: boolean
) => ColDef[] = (
  setDeletePop: (data: boolean) => void,
  setDeleteNarrativeId: (data: string) => void,
  navigate: NavigateFunction,
  incidentId: string | undefined,
  Delete: boolean,
  Update: boolean
) => {
  return [
    { field: "Narrative Type" },
    { field: "User Submitted" },
    { field: "Narrative" },
    { field: "Public Narrative" },
    { field: "Created On" },
    { field: "Modified On" },
    {
      field: "Action",
      cellRenderer: (e: { data: setTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
              <img
                className="table-cellRenderer-img"
                src={edit_icon}
                title="Edit"
                onClick={() => {
                  setDeleteNarrativeId(e?.data?.NarrativeId);
                  navigate(
                    `/incidents/${incidentId}/narratives/${e.data?.NarrativeId}/edit`
                  );
                }}
              />
            )}
            {Delete && (
              <img
                title="Delete"
                className="table-cellRenderer-img"
                src={delete_icon}
                alt="Delete"
                onClick={() => {
                  setDeleteNarrativeId(e?.data?.NarrativeId);
                  setDeletePop(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
};
