import delete_icon from "../../../../assets/icons/delete.svg";
import edit_icon from "../../../../assets/icons/edit.svg";
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, SetTableData } from "./SystemLevelIState";

export const OrderByFieldName: OrderByFieldNameI = {
  "Date Active": "ActiveDate",
  "Date Inactive": "ExpiryDate",
  "Long Code": "LongCode",
  "Short Code": "ShortCode",
  Category: "CategoryName",
  Description: "Description",
  Status: "IsActive",
};

export const coloumn = (
  Edit: (data: string) => void,
  DeleteRow: (data: string) => void,
  Update:boolean,
  Delete:boolean
): ColDef[] => {
  return [
    { field: "Short Code" },
    { field: "Long Code" },
    { field: "Category" },
    { field: "Description" },
    {
      field: "Date Active",
      valueFormatter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "MM-dd-yyyy hh:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      field: "Date Inactive",
      valueFormatter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "MM-dd-yyyy hh:mm:ss");
        } else {
          return "";
        }
      },  
    },
    { field: "Status" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
 <img
 className="table-cellRenderer-img"
 title="Edit"
 src={edit_icon}
 onClick={() => Edit(e.data.SystemLevelCodeId)}
/>
            )}
           {Delete && (
 <img
 className="table-cellRenderer-img"
 title="Delete"
 src={delete_icon}
 onClick={() => {
   DeleteRow(e.data.SystemLevelCodeId);
 }}
/>
           )}
           
          </div>
        );
      },
    },
  ];
};
