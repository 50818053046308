import { capitaliseLetter } from "src/utils/Helper/Helper";
import { Fetcher } from "../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../utils/const";
import { GetRaceDataIState } from "./AddEditParticipantName/AddNewName/ExternalContact/ExternalContactI";
import { OrderByFieldName } from "./Config";
import {
  getParticipantListTableDataIRes,
  OrderByType,
  SetTableData,
} from "./ParticipantI";

const fetcher = new Fetcher();
export class ParticipantListFunction {
  async getParticipantListTableData(
    setTableData: (data: SetTableData[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    Name: string,
    InvolvementType: string,
    ParticipantType: string,
    IncidentId: string,
    OrderByDescending?: boolean,
    OrderBy?: string
  ) {
    setLoading(true);

    const isSearch = !!(Name || InvolvementType);
    const response = await fetcher.post(
      `/incident/${IncidentId}/participant/search`,
      {
        PageNumber: isSearch ? 1 : pageNumber,
        PageSize: pageCount,
        Name: Name ? Name : null,
        InvolvementType: InvolvementType ? InvolvementType : null,
        ...(OrderByDescending != null ? { OrderByDescending } : {}),
        OrderBy: OrderByFieldName[OrderBy as OrderByType],
        EntityType:
          ParticipantType == "1"
            ? "All"
            : ParticipantType == "2"
            ? "Person"
            : ParticipantType == "3"
            ? "Business"
            : null,
      }
    );
    const TableJson = this.createTableJson(response?.Data);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: getParticipantListTableDataIRes[]) {
    return data?.map((val: getParticipantListTableDataIRes, i: number) => {
      return {
        "First Name": capitaliseLetter(val?.FirstName),
        "Last Name":
          capitaliseLetter(val?.LastName) +
          (capitaliseLetter(val?.BusinessType)
            ? ` (${capitaliseLetter(val?.BusinessType)})`
            : ""),
        "Middle Name": capitaliseLetter(val?.MiddleName),
        DOB: val.DOB,
        Age: val.Age,
        Address: val.Address,
        AddressCount: val.AddressCount,
        Status: "Active",
        ParticipantId: val.ParticipantId,
        Involvement: val.Involvement,
        EntityType: val.EntityType,
      };
    });
  }
  async getRaceData(
    setRaceDataData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/PersonRoleIncidentToPersonAssociation"
    );
    console.log(response);
    let res: GetRaceDataIState[] = response.Data;
    setRaceDataData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async deleteUser(
    participantId: string,
    incidentId: string,
    setLoading: (data: boolean) => void,
    refreshData: () => void
  ) {
    setLoading(true);
    await fetcher.delete(
      `/incident/${incidentId}/participant/${participantId}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    refreshData();
  }
}
