import { Fetcher } from "../../../../../../../services/Fetcher";
import { successCreatedMessage } from "../../../../../../../utils/const";
import { GetUserManagementTableDataI } from "../../../../../UserManagement/ListUserManagement/ListUserManagementI";
import { SetTableDataIstate, setuserpoliciesIState } from "./UserPolicyTabI";
const fetcher = new Fetcher();
export class UserPolicyFunction {
  async GetUserManagementTableData(
    setTableData: (data: SetTableDataIstate[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    roleId: string,
    setFiltertableData: (data: SetTableDataIstate[]) => void,
    setGetrolepoliciesDataLength: (data: number) => void
  ) {
    setLoading(true);
    let response = await fetcher.post(`/user/search`, {
      PageNumber: pageNumber,
      PageSize: pageCount,
    });
    const TableJson = this.createTableJson(response?.Data);
    this.GetuserpoliciesData(
      setLoading,
      roleId,
      TableJson,
      setTableData,
      setFiltertableData,
      setGetrolepoliciesDataLength
    );
  }

  createTableJson(data: GetUserManagementTableDataI[]) {
    return data?.map((val: GetUserManagementTableDataI, i: number) => {
      return {
        "First Name": val.FirstName,
        "Middle Name": val.MiddleName,
        "Last Name": val.LastName,
        Suffix: val.Suffix,
        "Post No": val.PostNumber,
        "Home Unit": val.HomeUnitName,
        Rank: val.RankName,
        Status: val.IsActive ? "Active" : "InActive",
        Badge: val.BadgeNumber,
        "Last Login": "",
        ID: val.UserId,
        isSelected: false,
      };
    });
  }

  async GetuserpoliciesData(
    setLoading: (data: boolean) => void,
    roleId: string,
    TableJson: SetTableDataIstate[],
    setTableData: (data: SetTableDataIstate[]) => void,
    setFiltertableData: (data: SetTableDataIstate[]) => void,
    setGetrolepoliciesDataLength: (data: number) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/role/${roleId}/users`);
    let data: setuserpoliciesIState[] = response?.Data;
    setGetrolepoliciesDataLength(data.length);
    this.setdefaultCheck(data, setTableData, TableJson, setFiltertableData);
    setLoading(false);
  }

  applyFilterOnUserpolicyTable(
    StatusFilter: string,
    policyNameFilter: string,
    FiltertableData: SetTableDataIstate[],
    setTableData: (data: SetTableDataIstate[]) => void
  ) {
    let filteredData: SetTableDataIstate[] = FiltertableData;
    let status: string = StatusFilter == "1" ? "active" : "inactive";
    if (policyNameFilter) {
      filteredData = FiltertableData.filter((item) => {
        return (
          `${item["First Name"]} ${item["Middle Name"]} ${item["Last Name"]}` &&
          `${item["First Name"]} ${item["Middle Name"]} ${item["Last Name"]}`
            .toLowerCase()
            .includes(policyNameFilter.toLowerCase().trim())
        );
      });
    }
    if (StatusFilter) {
      filteredData = filteredData.filter((item) => {
        return item?.["Status"].toLowerCase() == status;
      });
    }
    setTableData(filteredData);
  }

  async userPolicyAdd(
    setLoading: (data: boolean) => void,
    roleId: string,
    RowCheckBox: { UserId: string }[]
  ) {
    setLoading(true);
    await fetcher.post(`/role/${roleId}/user`, [...RowCheckBox], {
      message: successCreatedMessage,
      show: true,
    });
    setLoading(false);
  }

  setdefaultCheck(
    rolepolicies: setuserpoliciesIState[],
    setTableData: (data: SetTableDataIstate[]) => void,
    tableData: SetTableDataIstate[],
    setFiltertableData: (data: SetTableDataIstate[]) => void
  ) {
    let defaultCheck: SetTableDataIstate[] = [];

    for (let i = 0; i < tableData.length; i++) {
      let temp2 = true;
      for (let j = 0; j < rolepolicies.length; j++) {
        if (rolepolicies[j]?.UserId == tableData[i]?.ID) {
          defaultCheck.push({ ...tableData[i], isSelected: true });
          temp2 = false;
          break;
        }
      }
      if (temp2) {
        defaultCheck.push({ ...tableData[i], isSelected: false });
      }
    }

    let finalTableData = defaultCheck.sort((a, b) => {
      const aIsSelected = Number(a.isSelected);
      const bIsSelected = Number(b.isSelected);
      return bIsSelected - aIsSelected;
    });

    setTableData(finalTableData);
    setFiltertableData(finalTableData);
  }

  selectRowCheckBox(
    FiltertableData: SetTableDataIstate[],
    data: { [key: string]: string | number | boolean | null }[],
    setFiltertableData: (data: SetTableDataIstate[]) => void,
    oneTimeRender: boolean,
    GetrolepoliciesDataLength: number,
    setChangeValue: (data: boolean) => void,
    setoneTimeRender: (data: boolean) => void,
    tableData: SetTableDataIstate[]
  ) {
    let updatedTableData: SetTableDataIstate[] = tableData.map((item) => {
      const isSelected = data.some(
        (selectedItem) => selectedItem?.ID === item?.ID
      );
      return { ...item, isSelected };
    });

    let updatedTableData2: SetTableDataIstate[] = FiltertableData.map(
      (item) => {
        const isSelected = updatedTableData.find((selectedItem) => {
          if (selectedItem?.ID === item?.ID) {
            return selectedItem;
          }
        });
        if (isSelected) {
          return { ...item, isSelected: isSelected.isSelected };
        } else {
          return { ...item };
        }
      }
    );

    setFiltertableData(updatedTableData2);

    if (oneTimeRender) {
      const count = updatedTableData2.filter((item) => item.isSelected).length;
      if (GetrolepoliciesDataLength !== count) {
        setChangeValue(true);
        setoneTimeRender(false);
      }
    }
  }
}
