import { Fetcher } from "../../../../../services/Fetcher";
import {
  successCreatedMessage,
  successDeleteMessage,
  successUpdateMessage,
} from "../../../../../utils/const";
import { capitaliseLetter, formatDatewithTime } from "../../../../../utils/Helper/Helper";
import { OrderByFieldName } from "./config";
import {
  editNarrative,
  getNarrativeTableRes,
  NarrativeHistroy,
  narrativeTypeDropdown,
  OrderByType,
  setTableData,
} from "./NarrativeIState";

const fetcher = new Fetcher();
export class NarrativeListFunction {
  async getNarrativeListTableData(
    setTableData: (data: setTableData[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    Name: string,
    Type: string,
    incidentId: string,
    OrderByDescending?: boolean,
    OrderBy?: string,
    IsNarrativeInclude?: boolean
  ) {
    setLoading(true);

    const isSearch = !!(Name || Type);

    let response = await fetcher.post(
      `/incident/${incidentId}/narrative-search`,
      {
        PageNumber: isSearch ? 1 : pageNumber,
        PageSize: pageCount,
        SearchText: Name ? Name : null,
        NarrativeTypeCodeId: Type ? Type : null,
        ...(OrderByDescending != null ? { OrderByDescending } : {}),
        OrderBy: OrderByFieldName[OrderBy as OrderByType],
        IsNarrativeInclude: IsNarrativeInclude ? IsNarrativeInclude : false,
      }
    );

    const TableJson = this.createTableJson(response?.Data);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
    return response;
  }

  createTableJson(data: getNarrativeTableRes[]) {
    return data?.map((val: getNarrativeTableRes, i: number) => {
      return {
        "Narrative Type": val.NarrativeType,
        "User Submitted": `${capitaliseLetter(
          val?.FirstName
        )} ${capitaliseLetter(val?.MiddleName)} ${
          capitaliseLetter(val?.LastName)
        }`,
        Narrative: val.Title,
        "Public Narrative": val.LastName,
        "Created On": formatDatewithTime(val.CreatedOn),
        "Modified On": formatDatewithTime(val.ModifiedOn),
        NarrativeId: val?.NarrativeId,
        NarrativeType: val?.NarrativeType,
        NarrativeTypeCodeId: val?.NarrativeTypeCodeId,
        Title: val?.Title,
        Narratives: val?.Narrative,
      };
    });
  }

  async getRankData(
    setRankData: (data: narrativeTypeDropdown[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/agency/category/NarrativeType");
    setRankData(response.Data);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getHistoryData(
    setLoading: (data: boolean) => void,
    setHistory: (data: NarrativeHistroy[]) => void,
    narrativeId: string,
    incidentId: string
  ) {
    setLoading(true);
    const res = await fetcher.get(
      `/incident/${incidentId}/narrative/${narrativeId}/history`
    );
    setHistory(res?.Data);
  }
  async getNarrativeOverviewData(
    setLoading: (data: boolean) => void,
    narrativeId: string,
    incidentId: string
  ) {
    setLoading(true);
    let response = await fetcher.get(
      `/incident/${incidentId}/narrative/${narrativeId}`
    );
    setLoading(false);
    return response;
  }

  async getNarrativeOverviewByVersion(
    setLoading: (data: boolean) => void,
    narrativeId: string,
    narrativeHistoryId: string,
    incidentId: string
  ) {
    setLoading(true);
    let response = await fetcher.get(
      `/incident/${incidentId}/narrative/${narrativeId}/history/${narrativeHistoryId}`
    );
    setLoading(false);
    return response;
  }

  async editNarrativeOverview(
    setLoading: (data: boolean) => void,
    payload: editNarrative,
    incidentId: string | undefined
  ) {
    if (!incidentId) {
      return;
    }
    !payload?.IsAutoSave && setLoading(true);
    let response = await fetcher.put(
      `/incident/${incidentId}/narrative`,
      payload,
      {
        message: successUpdateMessage,
        show: !payload?.IsAutoSave && true,
      }
    );
    !payload?.IsAutoSave && setLoading(false);

    return response;
  }

  async addNarrative(
    setLoading: (data: boolean) => void,
    payload: editNarrative,
    incidentId: string | undefined
  ) {
    if (!incidentId) {
      return;
    }
    !payload?.IsAutoSave && setLoading(true);
    let response = await fetcher.post(
      `/incident/${incidentId}/narrative`,
      payload,
      {
        message: successCreatedMessage,
        show: !payload?.IsAutoSave && true,
      }
    );
    !payload?.IsAutoSave && setLoading(false);
    return response;
  }

  async deleteNarrative(
    narrativeId: string,
    setLoading: (data: boolean) => void,
    incidentId: string
  ) {
    setLoading(true);
    let res = await fetcher.delete(
      `/incident/${incidentId}/narrative/${narrativeId}`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    setLoading(false);
    return res;
  }

  async nameListing(incidentId: string | undefined) {
    if (!incidentId) {
      return;
    }
    let response = await fetcher?.get(`/incident/${incidentId}/name-tags`);
    return response;
  }
}
