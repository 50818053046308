import { useEffect, useState } from "react";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { SetTableData } from "./UserPermissionI";
import { UserPermissionFunction } from "./UserPermissionFunction";
import { EditUserManagementIParams } from "../../../EditUserManagement/EditUserManagementI";
import { useParams } from "react-router-dom";
import { Filter } from "./Components/Filter";
import useDelayedEffect from "../../../../../../hooks/useDelayedEffect";
import { useDispatch } from "react-redux";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const UserPermission = () => {
  const { Update } = useGetPerticularUserPermission("User");
  const userPermissionFunction = new UserPermissionFunction();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [userPolicyAddData, setUserPolicyAddData] = useState<SetTableData[]>(
    []
  );
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [PermissionFilter, setPermissionFilter] = useState<string>("");
  const { userId } = useParams<EditUserManagementIParams>();

  const onCancel = () => {
    GetUserPermissionTableData();
  };

  const onSave = async () => {
    if (userId) {
      setLoading(true);
      await userPermissionFunction.userPolicyAdd(
        setLoading,
        userId,
        userPolicyAddData,
        dispatch
      );
      setLoading(true);
      await GetUserPermissionTableData();
      setLoading(false);
    }
  };

  const GetUserPermissionTableData = async () => {
    if (userId) {
      await userPermissionFunction.GetUserPermissionTableData(
        setLoading,
        setTableData,
        userId,
        setFiltertableData
      );
    }
  };

  const initialRender = () => {
    GetUserPermissionTableData();
  };

  const applyFilterOnUserpolicyTable = () => {
    userPermissionFunction.applyFilterOnUserpolicyTable(
      PermissionFilter,
      FiltertableData,
      setTableData
    );
  };

  useEffect(() => {
    initialRender();
  }, [userId]);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [PermissionFilter]);

  return (
    <>
      <Loader loading={loading} />
      <div className="Audit-filter">
        <Filter
          PermissionFilter={PermissionFilter}
          setPermissionFilter={setPermissionFilter}
        />
      </div>
      <div className="table">
        <Table
          columns={coloum(
            tableData,
            setTableData,
            setUserPolicyAddData,
            userPolicyAddData,
            Update
          )}
          columnsValue={tableData}
          clientSideSorting={true}
          showPagination={false}
        />
        {Update && (
          <div className="edit-button">
            <Submit onCancel={onCancel} onSave={onSave} />
          </div>
        )}
      </div>
    </>
  );
};
