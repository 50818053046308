import { useEffect, useState } from "react";
import Breadcrumbs from "../../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { Table } from "../../../../CommonComponents/Table/Table";
import LocationMetaData from "../../Location/LocationMetaData";
import { Loader } from "../../../../CommonComponents/Loader/Loader";

import { useNavigate, useParams } from "react-router-dom";

import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";

import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { setTableData } from "./ProsecutionIState";
import { Filter } from "../Components/Filter";
import { coloum } from "./Config";

const  ProsecutionList = () => {
  const [width, setwidth] = useState<number>(10);
  const [tableData, setTableData] = useState<setTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("License Plate");
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [deleteVehicleId, setDeleteVehicleId] = useState<string | null>(null);
  const navigation = useNavigate();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const { incidentId } = useParams<InvolvementMainIParams>();

 
 
  const NavigateToEditPage = (vehicleId: string) => {
    
    navigation(`/incidents/${incidentId}/vehicles/${vehicleId}/edit`);
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
    setDeleteVehicleId(null);
  };
 
  const getVehicleTableData=()=>{

  }
  const clearAllFilter=()=>{

  }
  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="_incident_location_container_fluid_">
        <div className="_location_left_container_">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>
        <div className="UserManagement">
       
          <div className="_user_management_container">
          <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path:  `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              { title: "Prosecution", path:  `/incidents/${incidentId}/prosecution` },
            ]}
          />
            <p className="header">Prosecution {"  "} {incidentNumber}</p>
            <Filter
              Name={Name}
              setName={setName}
              onSearch={getVehicleTableData}
              clearAllFilter={clearAllFilter}
            />
            <div className="table">
              <Table
                columns={coloum(
                  NavigateToEditPage,
                  setdeletePopup,
                  setDeleteVehicleId
                )}
                columnsValue={tableData}
                cursorPointer={true}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                headerBackgroundColor="#e7e7e7"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProsecutionList;
