import { useState } from "react";
import { BusinessTab } from "../../Components/Pages/Incidents/Participant/AddEditParticipantName/AddBusinessName/InvolvementMainI";

interface SaveChangesPopupHook {
  Mainfields: BusinessTab;
  setMainfields: (data: BusinessTab) => void;
}

const useIncidentsBussinessStateManagement = (): SaveChangesPopupHook => {
  const [Mainfields, setMainfields] = useState<BusinessTab>({
    Involvement: {
      Firstname: "",
      BusinessId: "",
      BusinessRoleId: "",
      BusinessType: "",
      InvolvementType: "",
      BusinessMasterId: "",
      ParticipantId: "",
      Private: false,
      ProcORI: false,
      Addresses: {
        AddressData: [
          {
            AddressType: "",
            Apartment: "",
            City: "",
            Country: "",
            GridNumber: "",
            id: 1,
            StateProvidence: "",
            Street: "",
            StreetNumber: "",
            StreetPrefix: "",
            StreetSuffix: "",
            ZipCode: "",
            AddressLine1: "",
            AddressLine2: "",
            ForeignAddressCity: "",
            ForeignAddressStateProvinceRegion: "",
            ForeignAddressZip: "",
            ForeignAddressCountry: "",
            ForeignAddress: false,
            NoPermanentAddress: false,
            UnknownAddress: false,
          },
        ],
      },
      Email: [
        {
          Email: "",
          ExternalContactID: "",
          id: 1,
          TypeDropdownvalue: "",
          Type: "",
        },
      ],
      PhoneNumber: [
        {
          Country: "",
          Ext: "",
          ExternalContactID: "",
          id: 1,
          Number: "",
          Type: "",
          TypeDropdownvalue: "",
        },
      ],
      Victiminformation: {
        AgencyId: "",
        VictimId: "",
        AggravatedAssaulthomicideCode: [
          {
            AggravatedAssaulthomicideCode: "",
            id: 1,
            AgencyId: "",
            AggravatedAssaultHomicideId: "",
            IncidentId: "",
            PersonId: "",
          },
        ],
        EnforcementOfficialActivityCategoryCode: "",
        EnforcementOfficialAssignmentCategoryCode: "",
        InjuryCategoryCode: [
          {
            InjuryCategoryCode: "",
            id: 1,
            AgencyId: "",
            IncidentId: "",
            InjuryId: "",
            PersonId: "",
          },
        ],
        AdditionalJustifiablehomicide: [
          {
            AdditionalJustifiablehomicide: "",
            id: 1,
            AggravatedAssaultHomicideId: "",
            AgencyId: "",
            IncidentId: "",
            PersonId: "",
            AdditionalJustifiableHomicideId: "",
          },
        ],
        OfficerOtherJurisdiction: false,
        USRCode: "",
        VictimType: "",
      },
    },
  });

  return {
    Mainfields,
    setMainfields,
  };
};

export default useIncidentsBussinessStateManagement;
