import React, { useEffect, useState } from "react";
import close from "../../../../../../../assets/icons/close-circle.svg";
import { ButtonComponents } from "../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { DropdownComponent } from "../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { FilterIProps } from "./FilterIProps";
import { ButtonIState } from "../../../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";

export const Filter: React.FC<FilterIProps> = ({
  PermissionFilter,
  setPermissionFilter,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
    useEffect(() => {
        if (PermissionFilter && PermissionFilter.trim().length > 0) {
           setIsAddButtonDisabled(false);
      setButtonTextColor("red");
        } else {
        setIsAddButtonDisabled(true);
      setButtonTextColor("black");
        }
      }, [PermissionFilter]);
  const clearAllFilters = () => {
    setPermissionFilter("");
    setIsAddButtonDisabled(true);
      setButtonTextColor("black");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds">
          <TextBoxComponent
            fieldName={"Permission"}
            value={PermissionFilter}
            onChangeValue={setPermissionFilter}
          />
        </div>
        <div className="clearbutton">
          <div className="clearbutton-sub">
          <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
