import { Fetcher } from "../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import { InvolvementData } from "../../MasterNameIState";
import { InvolvementTableIRes } from "./InvolvementIState";

const fetcher = new Fetcher();

export class InvolvementMainFunction {
  async getInvolvementMain(
    setTableData: (data: InvolvementData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    try {
      let response = await fetcher.get(
        `/name-master/${nameId}/involvements`
      );
      let res: InvolvementTableIRes[] = response.Data;

      const TableJson = this.createTableJson(res);

      setTableTotalRecord(response?.TotalRecords);
      setTableData(TableJson);
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  }

  createTableJson(data: InvolvementTableIRes[]) {
    return data?.map((val: InvolvementTableIRes, i: number) => {
      console.log(val)
      return {
        Case:val.IncidentNumber,
        Agency:val.AgencyName,
        Title:val.Title,
        "Reported Date":formatDate(val.CreatedOn),
        Type:val.Type,
        Involvement:val.Involvement,
        ICR:val.ICR,
      };
    });
  }
}
