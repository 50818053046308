import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AllRols = "Create" | "Read" | "Update" | "Delete" | "General";

export interface UserPermission {
  User: AllRols[];
  Agency: AllRols[];
  "Code.NCIC": AllRols[];
  "Code.Agency": AllRols[];
  "Code.System": AllRols[];
  Config: AllRols[];
  CAD: AllRols[];
  Incident: AllRols[];
  "Incident.Participant": AllRols[];
  "Incident.Location": AllRols[];
  "Incident.Narrative": AllRols[];
  "Incident.Procecution": AllRols[];
  "Incident.Vehicle": AllRols[];
  "Incident.Article": AllRols[];
  "Incident.Media": AllRols[];
  "Incident.Menu.Config": AllRols[];
  "Incident.CAD": AllRols[];
  MasterIndex: AllRols[];
}

export interface setUserPermissionPayload {
  User: AllRols[];
  Agency: AllRols[];
  "Code.NCIC": AllRols[];
  "Code.Agency": AllRols[];
  "Code.System": AllRols[];
  Config: AllRols[];
  CAD: AllRols[];
  Incident: AllRols[];
  "Incident.Participant": AllRols[];
  "Incident.Location": AllRols[];
  "Incident.Narrative": AllRols[];
  "Incident.Procecution": AllRols[];
  "Incident.Vehicle": AllRols[];
  "Incident.Article": AllRols[];
  "Incident.Media": AllRols[];
  "Incident.Menu.Config": AllRols[];
  "Incident.CAD": AllRols[];
  MasterIndex: AllRols[];
}

const initialState: UserPermission = {
  User: [],
  Agency: [],
  "Code.NCIC": [],
  "Code.Agency": [],
  "Code.System": [],
  Config: [],
  CAD: [],
  Incident: [],
  "Incident.Participant": [],
  "Incident.Location": [],
  "Incident.Narrative": [],
  "Incident.Procecution": [],
  "Incident.Vehicle": [],
  "Incident.Article": [],
  "Incident.Media": [],
  "Incident.Menu.Config": [],
  "Incident.CAD": [],
  MasterIndex: [],
};

const slice = createSlice({
  name: "UserPermissionReducer",
  initialState,
  reducers: {
    setUserPermission(state, action: PayloadAction<setUserPermissionPayload>) {
      state.Agency = action.payload.Agency;
      state.User = action.payload.User;
      state.CAD = action.payload.CAD;
      state["Code.Agency"] = action.payload["Code.Agency"];
      state["Code.NCIC"] = action.payload["Code.NCIC"];
      state["Code.System"] = action.payload["Code.System"];
      state.Config = action.payload.Config;
      state.Incident = action.payload.Incident;
      state["Incident.Participant"] = action.payload["Incident.Participant"];
      state["Incident.Location"] = action.payload["Incident.Location"];
      state["Incident.Narrative"] = action.payload["Incident.Narrative"];
      state["Incident.Procecution"] = action.payload["Incident.Procecution"];
      state["Incident.Vehicle"] = action.payload["Incident.Vehicle"];
      state["Incident.Article"] = action.payload["Incident.Article"];
      state["Incident.Media"] = action.payload["Incident.Media"];
      state["Incident.Menu.Config"] = action.payload["Incident.Menu.Config"];
      state["Incident.CAD"] = action.payload["Incident.CAD"];
      state.MasterIndex = action.payload.MasterIndex;
    },
  },
});

export const { setUserPermission } = slice.actions;

export default slice.reducer;
