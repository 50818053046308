import { Person, SetTableData } from "../../InvolvementMainI";
import import_icon from "../../../../../../../../../assets/icons/import.svg";
export const columns = (
  openDeletePopup: (data: boolean) => void,
  setNameMasterID: (data: string) => void
) => {
  return [
    { field: "Last Name" },
    { field: "First Name" },
    { field: "Middle Name" },
    { field: "DOB" },
    { field: "Address" },
    {
      field: "Import",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {
              <img
                className="table-cellRenderer-img"
                src={import_icon}
                title="Import"
                onClick={() => {
                  setNameMasterID(e.data.NameMasterID);
                  openDeletePopup(true);
                }}
              />
            }
          </div>
        );
      },
    },
  ];
};
