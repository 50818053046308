import React, { useCallback, useEffect, useState } from "react";
import { Submit } from "../../../../../../CommonComponents/Fields/Submit/Submit";
import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { DropdownComponent } from "../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import "./AddEditViewEmergencyContact.css";

import { EditUserManagementIParams } from "../../../../EditUserManagement/EditUserManagementI";
import { useParams } from "react-router-dom";
import { DetailTabFunctions } from "../../../../../AgencyManagement/Components/Tabs/DetailTab/DetailTabFunctions";
import { setchangeTheForm } from "../../../../../../../Redux/ValidateRouteReducer";
import { useDispatch } from "react-redux";
import {
  AddEmergencyContactIProps,
  setFormDataValidation,
  setformFieldsIState,
} from "./AddEditViewEmergencyContactI";
import { getSateDataIState } from "../../../../../AgencyManagement/Components/Tabs/DetailTab/DetailTabInterface";
import { AddEditViewEmergencyContactFunction } from "./AddEditViewEmergencyContactFunction";
import { SummaryTabFunction } from "../../SummaryTab/SummaryTabFunction";
import { setsuffixIState } from "../../SummaryTab/SummaryTabInterface";
import { MultiplePhoneNumber } from "../../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumber";
import { setMultiplePhoneNumberListIState } from "../../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumberI";
import { useGetPerticularUserPermission } from "../../../../../../../hooks/Permission/useGetPerticularUserPermission";
import { openNotificationWithIcon } from "../../../../../../CommonComponents/Toster/Toster";
import useDelayedEffect from "../../../../../../../hooks/useDelayedEffect";
import { EmergencyContactTabFunction } from "../EmergencyContactTabFunction";
import { string } from "yup";

export const AddEditViewEmergencyContact: React.FC<
  AddEmergencyContactIProps
> = ({
  setAddMode,
  setEditMode,
  setShowAddEmergency,
  setviewMode,
  addMode,
  editMode,
  viewMode,
  getEmergencyContactSingleData,
}) => {
  const { Update } = useGetPerticularUserPermission("User");
  const emergencyInstance = new EmergencyContactTabFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const summaryTabFunction = new SummaryTabFunction();
  const addEditViewEmergencyContactFunction =
    new AddEditViewEmergencyContactFunction();
  const dispatch = useDispatch();
  const [disable, setdisable] = useState<boolean>(viewMode ? true : false);
  const detailTabFunctions = new DetailTabFunctions();
  const [formFields, setformFields] = useState<setformFieldsIState>({
    PrimaryContact: "",
    Relation: "",
    LastName: "",
    FirstName: "",
    MiddleName: "",
    Suffix: "",
    StreetAddress: "",

    City: "",
    StateCodeId: "",
    ZipCode: "",
    Email: "",
    Contacts: [],
  });

  const [Suffix, setSuffix] = useState<setsuffixIState[]>([]);

  const [FormDataValidation, setFormDataValidation] =
    useState<setFormDataValidation>({
      PrimaryContact: false,
      Relation: false,
      LastName: false,
      FirstName: false,
      MiddleName: false,
      Suffix: false,
      StreetAddress: false,

      City: false,
      StateCodeId: false,
      ZipCode: false,
      Email: false,
    });

  const { userId } = useParams<EditUserManagementIParams>();
  const [stateData, setStateData] = useState<getSateDataIState[]>([]);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const onChangeFields = (field: string, value: string) => {
    setformFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  useDelayedEffect(
    () => {
      // if (formFields.Email && !validateEmail(formFields.Email)) {
      //   openNotificationWithIcon(
      //     "error",
      //     "Please provide a valid Email Address."
      //   );
      // }
    },
    1000,
    [formFields.Email]
  );

  const editEnable = () => {
    setAddMode(false);
    setEditMode(true);
    setviewMode(false);
    setdisable(false);
  };

  const onCancel = () => {
    setformFields({
      PrimaryContact: getEmergencyContactSingleData["Primary Contact"]
        ? "1"
        : "2",
      Relation: getEmergencyContactSingleData.Relation,
      LastName: getEmergencyContactSingleData["Last Name"],
      FirstName: getEmergencyContactSingleData["First Name"],
      MiddleName: getEmergencyContactSingleData["Middle Name"],
      Suffix: getEmergencyContactSingleData.SuffixCodeId
        ? getEmergencyContactSingleData.SuffixCodeId
        : "",
      StreetAddress: getEmergencyContactSingleData.StreetAddress,

      City: getEmergencyContactSingleData.City,
      StateCodeId: getEmergencyContactSingleData.StateCodeId
        ? getEmergencyContactSingleData.StateCodeId
        : "",
      ZipCode: getEmergencyContactSingleData.ZipCode,
      Email: getEmergencyContactSingleData.Email,
      Contacts: [
        { ContactMethodCodeId: "", ContactValue: "", id: 1, IsPrimary: false },
      ],
    });
  };

  const getMultiplePhoneNumberData = (
    data: setMultiplePhoneNumberListIState[]
  ) => {
    setformFields({
      ...formFields,
      Contacts: data,
    });
  };

  const handleSubmit = () => {
    addEditViewEmergencyContactFunction.errorValidation(
      formFields,
      setFormDataValidation
    );
    if (formFields.Email && !validateEmail(formFields.Email)) {
      openNotificationWithIcon(
        "error",
        "Please provide a valid Email Address."
      );
      return;
    }
    if (addEditViewEmergencyContactFunction.checkRequiredFields(formFields)) {
      if (userId) {
        if (getEmergencyContactSingleData.ID && (editMode || viewMode)) {
          addEditViewEmergencyContactFunction.editEmergencyContact(
            setLoading,
            formFields,
            userId,
            setShowAddEmergency,
            getEmergencyContactSingleData.ID,
            getEmergencyContactSingleData.AgencyId
          );
          dispatch(setchangeTheForm(false));
        } else if (addMode) {
          addEditViewEmergencyContactFunction.addEmergencyContact(
            setLoading,
            formFields,
            userId,
            setShowAddEmergency
          );
          dispatch(setchangeTheForm(false));
        }
      }
    }
  };
  const getPrefilledData = async () => {
    if (userId) {
      if (getEmergencyContactSingleData?.EmergencyContactId) {
        await emergencyInstance?.getAddEmergencyTableDataById(
          setLoading,
          userId,
          setformFields,
          getEmergencyContactSingleData?.EmergencyContactId
        );
      }
    }
  };
  useEffect(() => {
    getPrefilledData();
    detailTabFunctions.getSateData(setStateData, setLoading);
    summaryTabFunction.getSuffixData(setSuffix, setLoading);
  }, []);

  return (
    <div>
      <Loader loading={loading} />
 
      <div className="DetailTab_top">
        <div className="DetailTab_top_header_">
          <div className="DetailTab_top_header_sub">
            <p>Edit Emergency Contacts</p>
            {viewMode && Update ? (
              <p onClick={editEnable} className="DetailTab_top_header_edit">
                Edit
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="EmergencyTab_contant_">
          <div className="EmergencyTab_contant_sub">
            <div className="EmergencyTab_contant_1">
              <DropdownComponent
                fieldName="Primary Contact*"
                dataSource={[
                  { value: "Yes", id: "1" },
                  { value: "No", id: "2" },
                ]}
                disabled={disable}
                value={formFields.PrimaryContact}
                validation={FormDataValidation.PrimaryContact}
                handleRowClick={(e) => {
                  onChangeFields("PrimaryContact", e);
                }}
              />
              <TextBoxComponent
                disabled={disable}
                onChangeValue={(e) => {
                  onChangeFields("Relation", e);
                }}
                fieldName="Relation*"
                validation={FormDataValidation.Relation}
                value={formFields.Relation}
              />
              <TextBoxComponent
                disabled={disable}
                onChangeValue={(e) => {
                  onChangeFields("LastName", e);
                }}
                fieldName="Last Name*"
                value={formFields.LastName}
                validation={FormDataValidation.LastName}
              />
              <TextBoxComponent
                disabled={disable}
                onChangeValue={(e) => {
                  onChangeFields("FirstName", e);
                }}
                fieldName="First Name*"
                value={formFields.FirstName}
                validation={FormDataValidation.FirstName}
              />
              <TextBoxComponent
                disabled={disable}
                onChangeValue={(e) => {
                  onChangeFields("MiddleName", e);
                }}
                fieldName="Middle Name"
                value={formFields.MiddleName}
              />

              <DropdownComponent
                dataSource={Suffix.map((state) => ({
                  id: state.SystemLevelCodeId,
                  value: state.LongCode,
                }))}
                disabled={disable}
                fieldName="Suffix"
                validation={FormDataValidation.Suffix}
                value={formFields.Suffix}
                handleRowClick={(e) => {
                  onChangeFields("Suffix", e);
                }}
              />
            </div>
            <div className="EmergencyTab_contant_2">
              <div className="EmergencyTab_street">
                <TextBoxComponent
                  disabled={disable}
                  onChangeValue={(e) => {
                    onChangeFields("StreetAddress", e);
                  }}
                  fieldName="Street Address*"
                  value={formFields.StreetAddress}
                  validation={FormDataValidation.StreetAddress}
                />
              </div>
              <div
                style={{ marginRight: "60%", marginLeft: "4px", width: "30%" }}
              >
                <TextBoxComponent
                  disabled={disable}
                  onChangeValue={(e) => {
                    onChangeFields("City", e);
                  }}
                  fieldName="City*"
                  value={formFields.City}
                  validation={FormDataValidation.City}
                />
              </div>
            </div>
            <div className="EmergencyTab_contant_Address">
              <div className="emer-new">
                <DropdownComponent
                  disabled={disable}
                  validation={FormDataValidation.StateCodeId}
                  fieldName="State*"
                  dataSource={stateData.map((state) => ({
                    id: state.SystemLevelCodeId,
                    value: state.LongCode,
                  }))}
                  value={formFields.StateCodeId}
                  handleRowClick={(e) => {
                    onChangeFields("StateCodeId", e);
                  }}
                />
                <TextBoxComponent
                  disabled={disable}
                  onChangeValue={(e) => {
                    onChangeFields("ZipCode", e);
                  }}
                  fieldName="Zip code*"
                  validation={FormDataValidation.ZipCode}
                  value={formFields.ZipCode}
                />
              </div>
              <div className="EmergencyTab_textbox">
                <TextBoxComponent
                  disabled={disable}
                  onChangeValue={(e) => {
                    onChangeFields("Email", e);
                  }}
                  fieldName="Email"
                  value={formFields.Email}
                />
              </div>
            </div>
            <div className="EmergencyTab_Primary">
              <MultiplePhoneNumber
                getMultiplePhoneNumberData={getMultiplePhoneNumberData}
                value={formFields.Contacts}
                disabled={disable}
              />
            </div>
          </div>
        </div>
      </div>
      {(addMode || editMode) && (
        <div className="edit-button">
          <Submit onSave={handleSubmit} onCancel={onCancel} />
        </div>
      )}
    </div>
  );
};
