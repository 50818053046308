import {
  formatDate,
  parseISOToDateTime,
} from "../../../../../../utils/Helper/Helper";
import { AdditionalMainFunction } from "./AdditionalInfo/AdditionalFunction";
import { ExternalContactFunction } from "./ExternalContact/ExternalContactFunction";
import { IdentificationMainFunction } from "./IdentificationContact/IdentificationFunction";
import { InvolvementMainFunction } from "./Involvement/InvolvementMainFunction";
import { InvolvementMainIState } from "./Involvement/InvolvementMainI";
import { SubstanceDetainmentFunction } from "./SubstanceandDetainment/SubstanceDetainmentFunction";

const involvementMainFunction = new InvolvementMainFunction();
const substanceMainFunction = new SubstanceDetainmentFunction();
const identificationMainFunction = new IdentificationMainFunction();
const additionalMainFunction = new AdditionalMainFunction();
const multipleEmergencyFunction = new ExternalContactFunction();

export class AddNewNameFunction {
  async getAllAddNewNameTabData(
    setLoading: (data: boolean) => void,
    incidentId: string,
    participantId: string,
    setMainfields: (data: InvolvementMainIState) => void,
    Mainfields: InvolvementMainIState
  ) {
    setLoading(true);
    let {
      resAddress,
      resInvolvement,
      resScarsMarksTattoos,
      resaliase,
      resVictims,
      resArrest,
      resBiases,
    } = await involvementMainFunction.getInvolvement(incidentId, participantId);
    let { time, date } = resArrest
      ? resArrest?.ArrestDateTime
        ? parseISOToDateTime(resArrest?.ArrestDateTime)
        : { time: "", date: "" }
      : { time: "", date: "" };

    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        Demographics: {
          BirthplaceCIty: resInvolvement.PersonalInfo.BirthplaceCity,
          Build: resInvolvement.PersonalInfo.NcicbuildCodeCodeId,
          Eye: resInvolvement.PersonalInfo.NciceyeColorCodeCodeId,
          FacialHair: resInvolvement.PersonalInfo.FacialHair ? "1" : "2",
          Glasses: resInvolvement.PersonalInfo.Glasses ? "1" : "2",
          Hair: resInvolvement.PersonalInfo.NcichairColorCodeCodeId,
          Handedness: resInvolvement.PersonalInfo.IsHandedness ? "1" : "2",
          Height:
            resInvolvement.PersonalInfo.Height == null
              ? "Unknown"
              : resInvolvement.PersonalInfo.Height,
          Race: resInvolvement.PersonalInfo.NcicraceCodeCodeId,
          Sex: resInvolvement.PersonalInfo.NcicsexCodeCodeId,
          SkinTon: resInvolvement.PersonalInfo.NciccomplexionCodeCodeId,
          StateProvidence:
            resInvolvement.PersonalInfo.NcicbirthplaceStateCodeId,
          Weight:
            resInvolvement.PersonalInfo.Weight == null
              ? "Unknown"
              : String(resInvolvement.PersonalInfo.Weight),
          MinHeight: resInvolvement.PersonalInfo.MinHeight,
          MinWeight: resInvolvement.PersonalInfo.MinWeight,
          MaxHeight: resInvolvement.PersonalInfo.MaxHeight,
          MaxWeight: resInvolvement.PersonalInfo.MaxWeight,
          Country: resInvolvement.PersonalInfo.NcicbirthplaceCountryCodeId,
        },
        Involvement: {
          InCustody: resInvolvement.PersonalInfo.InCustody,
          MissingRunaway: resInvolvement.PersonalInfo.IsMissingRunaway,
          DOB: resInvolvement.NameMasterDetail.Dob
            ? formatDate(resInvolvement.NameMasterDetail.Dob)
            : "",
          DOBRefused: resInvolvement.PersonalInfo.BirthDateRefused,
          Firstname: resInvolvement.PersonalInfo.FirstName,
          InvolvementType: resInvolvement.ParticipantRole?.RoleId,
          Juvenile: resInvolvement.PersonalInfo.IsJuvenile,
          LastName: resInvolvement.NameMasterDetail.PersonSurName,
          Middlename: resInvolvement.NameMasterDetail.PersonMiddleName,
          Private: resInvolvement.PersonalInfo.ConfidentialInformant,
          ProcORI: resInvolvement.PersonalInfo.ProcOri,
          Suffix: resInvolvement.PersonalInfo.NcicnameSuffixCodeId,
          DOBUnknown: resInvolvement.PersonalInfo.IsAgeDOBUnknown,
          ParticipantId: resInvolvement.ParticipantDetail.ParticipantId,
          CitationId: resInvolvement.ParticipantDetail.CitationId,
          NameId: resInvolvement.ParticipantDetail.NameId,
          EntityTypeId: resInvolvement.ParticipantDetail.EntityTypeId,
          BusinessId: resInvolvement.ParticipantDetail.BusinessId,

          PersonId: resInvolvement.ParticipantDetail.PersonId,
          MasterNameId: resInvolvement.PersonalInfo.MasterNameId,
          ParticipantRoleId: resInvolvement.ParticipantRole.ParticipantRoleId,
          Age: resInvolvement.PersonalInfo.Age,
          MaxAge: resInvolvement.PersonalInfo.MaxAge,
          MinAge: resInvolvement.PersonalInfo.MinAge,
        },
        Address: {
          ...Mainfields.InvolvementTab.Address,
          NoPermanentAddress: resInvolvement.PersonalInfo.NoPermanentAddress,
          UnknownAddress: resInvolvement.PersonalInfo.NotKnownAddress,
          AddressData: resAddress.length
            ? [
                ...resAddress.map((val, i) => {
                  return {
                    AddressLine1: val.Address1,
                    CountryDropdownValue: val.CountryName,
                    StateProvidenceDropdownvalue: val.StateName,
                    AddressLine2: val.Address2,
                    AddressType: val.AddressType,
                    Apartment: val.HouseNumber,
                    City: val.City,
                    Country: val.NciccountryId,
                    ForeignAddress: val.IsForeignAddress,
                    ForeignAddressCity: val.OtherCity,
                    ForeignAddressCountry: val.OtherCountry,
                    ForeignAddressStateProvinceRegion: val.OtherState,
                    ForeignAddressZip: val.OtherZip,
                    GridNumber: val.Grid,
                    id: i + 1,
                    StateProvidence: val.NcicstateId,
                    Street: val.Street2Name,
                    StreetNumber: val.Street1Name,
                    StreetPrefix: val.Ncicstreet1PrefixId,
                    StreetSuffix: val.Ncicstreet1SuffixId,
                    ZipCode: val.ZipCode,
                    LocationCategoryCode: val.LocationCategoryCode,
                    LocationId: val.LocationId,
                    AgencyId: val.AgencyId,
                    push: true,
                  };
                }),
                {
                  AddressType: "",
                  Apartment: "",
                  City: "",
                  Country: "",
                  GridNumber: "",
                  id: resAddress.length + 1,
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                  AddressLine1: "",
                  AddressLine2: "",
                  ForeignAddressCity: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  ForeignAddressCountry: "",
                  CountryDropdownValue: "",
                  StateProvidenceDropdownvalue: "",
                  ForeignAddress: false,
                },
              ]
            : [
                {
                  AddressType: "",
                  Apartment: "",
                  City: "",
                  Country: "",
                  GridNumber: "",
                  id: 1,
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                  AddressLine1: "",
                  AddressLine2: "",
                  ForeignAddressCity: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  ForeignAddressCountry: "",
                  CountryDropdownValue: "",
                  StateProvidenceDropdownvalue: "",
                  ForeignAddress: false,
                },
              ],
        },
        ScarsMarksTattoos: resScarsMarksTattoos.length
          ? [
              ...resScarsMarksTattoos.map((val, i) => {
                return {
                  Notes: val.PhysicalFeatureText,
                  ScarMarkTattoosDropdownValue: val.PhysicalFeatureCode,
                  TypeLocationDropdownValue: val.PhysicalFeatureLocation,
                  ScarMarkTattoos: val.PhysicalFeatureNciccodeId,
                  id: i + 1,
                  PhysicalFeatureId: val.PhysicalFeatureId,
                  TypeLocation: val.PhysicalFeatureLocationNciccodeId,
                  push: true,
                };
              }),
              {
                id: resScarsMarksTattoos.length,
                Notes: "",
                ScarMarkTattoos: "",
                TypeLocation: "",
                ScarMarkTattoosDropdownValue: "",
                TypeLocationDropdownValue: "",
              },
            ]
          : [
              {
                id: 1,
                Notes: "",
                ScarMarkTattoos: "",
                TypeLocation: "",
                ScarMarkTattoosDropdownValue: "",
                TypeLocationDropdownValue: "",
              },
            ],
        Aliases: resaliase.length
          ? [
              ...resaliase.map((val, i) => {
                return {
                  DOB: formatDate(val.Dob),
                  Firstname: val.FirstName,
                  id: i + 1,
                  LastName: val.LastName,
                  Middlename: val.MiddleName,
                  AliasNameId: val.AliasNameId,
                  push: true,
                };
              }),
              {
                DOB: "",
                Firstname: "",
                id: resaliase.length + 1,
                LastName: "",
                Middlename: "",
              },
            ]
          : [{ DOB: "", Firstname: "", id: 1, LastName: "", Middlename: "" }],

        Victiminformation: resVictims
          ? {
              ...Mainfields.InvolvementTab.Victiminformation,
              AgencyId: resVictims?.Victims?.AgencyId,
              VictimId: resVictims?.Victims?.VictimId,
              VictimType: resVictims?.Victims?.VictimTypeCodeId,
              EnforcementOfficialActivityCategoryCode:
                resVictims?.Victims?.OfficerActivityCodeId,
              EnforcementOfficialAssignmentCategoryCode:
                resVictims?.Victims?.OfficerAssignmentTypeCodeId,
              OfficerOtherJurisdiction:
                resVictims?.Victims?.OfficerOtherJurisdiction,
              AdditionalJustifiablehomicide: resVictims
                .AdditionalJustifiableHomicides.length
                ? resVictims.AdditionalJustifiableHomicides.map(
                    (
                      valAdditionalJustifiableHomicidesval,
                      valAdditionalJustifiableHomicidesi
                    ) => {
                      return {
                        AdditionalJustifiablehomicide:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideCodeId,
                        id: valAdditionalJustifiableHomicidesi + 1,
                        AgencyId: valAdditionalJustifiableHomicidesval.AgencyId,
                        AggravatedAssaultHomicideId:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideId,
                        IncidentId:
                          valAdditionalJustifiableHomicidesval.IncidentId,
                        PersonId: valAdditionalJustifiableHomicidesval.PersonId,
                        AdditionalJustifiableHomicideId:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideId,
                      };
                    }
                  )
                : [
                    {
                      AdditionalJustifiablehomicide: "",
                      AgencyId: "",
                      AggravatedAssaultHomicideId: "",
                      id: 1,
                      IncidentId: "",
                      AdditionalJustifiableHomicideId: "",
                      PersonId: "",
                    },
                  ],
              AggravatedAssaulthomicideCode: resVictims
                .AggravatedAssaultHomicides.length
                ? resVictims.AggravatedAssaultHomicides.map(
                    (
                      AggravatedAssaultHomicidesval,
                      AggravatedAssaultHomicidesi
                    ) => {
                      return {
                        AggravatedAssaulthomicideCode:
                          AggravatedAssaultHomicidesval.AggravatedAssaultHomicideCodeId,
                        id: AggravatedAssaultHomicidesi + 1,
                        AgencyId: AggravatedAssaultHomicidesval.AgencyId,
                        AggravatedAssaultHomicideId:
                          AggravatedAssaultHomicidesval.AggravatedAssaultHomicideId,
                        IncidentId: AggravatedAssaultHomicidesval.IncidentId,
                        PersonId: AggravatedAssaultHomicidesval.PersonId,
                      };
                    }
                  )
                : [
                    {
                      AgencyId: "",
                      AggravatedAssaulthomicideCode: "",
                      AggravatedAssaultHomicideId: "",
                      id: 1,
                      IncidentId: "",
                      PersonId: "",
                    },
                  ],
              InjuryCategoryCode: resVictims.Injuries.length
                ? resVictims.Injuries.map((Injuriesval, Injuriesi) => {
                    return {
                      id: Injuriesi + 1,
                      InjuryCategoryCode: Injuriesval.InjuryCodeId,
                      AgencyId: Injuriesval.AgencyId,
                      IncidentId: Injuriesval.IncidentId,
                      InjuryId: Injuriesval.InjuryId,
                      PersonId: Injuriesval.PersonId,
                    };
                  })
                : [
                    {
                      AgencyId: "",
                      id: 1,
                      IncidentId: "",
                      InjuryCategoryCode: "",
                      InjuryId: "",
                      PersonId: "",
                    },
                  ],
            }
          : {
              AgencyId: "",
              VictimId: "",
              AggravatedAssaulthomicideCode: [
                {
                  AggravatedAssaulthomicideCode: "",
                  id: 1,
                  AgencyId: "",
                  AggravatedAssaultHomicideId: "",
                  IncidentId: "",
                  PersonId: "",
                },
              ],
              EnforcementOfficialActivityCategoryCode: "",
              EnforcementOfficialAssignmentCategoryCode: "",
              InjuryCategoryCode: [
                {
                  InjuryCategoryCode: "",
                  id: 1,
                  AgencyId: "",
                  IncidentId: "",
                  InjuryId: "",
                  PersonId: "",
                },
              ],
              AdditionalJustifiablehomicide: [
                {
                  AdditionalJustifiablehomicide: "",
                  id: 1,
                  AggravatedAssaultHomicideId: "",
                  AdditionalJustifiableHomicideId: "",
                  AgencyId: "",
                  IncidentId: "",
                  PersonId: "",
                },
              ],
              OfficerOtherJurisdiction: false,
              USRCode: "",
              VictimType: "",
            },
        Arrestinformation: resArrest
          ? {
              ArrestId: resArrest.ArrestId,
              LocationId: resArrest.ArrestId,
              AddressType: resArrest?.Address[0]?.AddressType,
              Apartment: resArrest.Address[0]?.HouseNumber,
              ArmedWithWeapon: resArrest?.ArresteeWasArmedWithType?.length
                ? resArrest?.ArresteeWasArmedWithType?.map(
                    (
                      ArresteeWasArmedWithTypeval,
                      ArresteeWasArmedWithTypei
                    ) => {
                      return {
                        ArmedWithWeapon:
                          ArresteeWasArmedWithTypeval.ArresteeArmedWithCodeId,
                        id: ArresteeWasArmedWithTypei + 1,
                        Weaponautomatic:
                          ArresteeWasArmedWithTypeval.IsAutomatic,
                        ArresteeWasArmedWithTypeId:
                          ArresteeWasArmedWithTypeval.ArresteeWasArmedWithTypeId,
                      };
                    }
                  )
                : [
                    {
                      ArmedWithWeapon: "",
                      ArresteeWasArmedWithTypeId: "",
                      id: 1,
                      Weaponautomatic: false,
                    },
                  ],
              ArrestCategoryCode: resArrest.ArrestTypeCodeId,
              ArrestedDate: formatDate(resArrest.ArrestDateTime),
              ArrestedTime: time,
              BookandRelease: resArrest.BookAndRelease,
              City: resArrest.Address[0]?.City,
              Country: resArrest.Address[0]?.NciccountryId,
              GridNumber: resArrest.Address[0]?.Grid,
              InCustody: resArrest.InCustody,
              Photographed: resArrest.IsPhotographed,
              ReasonHeld: resArrest.ReasonHeld,
              ReasonHeldDetails: resArrest.ReasonHeldDetail,
              Sameasprimaryincidentaddress: "",
              StateProvidence: resArrest.Address[0]?.NcicstateId,
              Street: resArrest.Address[0]?.Street2Name,
              StreetNumber: resArrest.Address[0]?.Street1Name,
              StreetPrefix: resArrest.Address[0]?.Ncicstreet1PrefixId,
              StreetSuffix: resArrest.Address[0]?.Ncicstreet1SuffixId,
              UnknownAddress: resArrest.UnknownAddress,
              WarrantNumber: resArrest.WarrantViewModel.length
                ? resArrest.WarrantViewModel.map(
                    (WarrantViewModelval, WarrantViewModelvali) => {
                      return {
                        id: WarrantViewModelvali + 1,
                        WarrantNumber: WarrantViewModelval.Number,
                        WarrantId: WarrantViewModelval.WarrantId,
                      };
                    }
                  )
                : [
                    {
                      WarrantNumber: "",
                      id: 1,
                      WarrantId: "",
                    },
                  ],
              ZipCode: resArrest.Address[0]?.ZipCode,
            }
          : {
              ArrestId: "",
              LocationId: "",
              ArmedWithWeapon: [
                {
                  ArmedWithWeapon: "",
                  Weaponautomatic: false,
                  id: 1,
                  ArresteeWasArmedWithTypeId: "",
                },
              ],
              ArrestCategoryCode: "",
              ArrestedDate: "",
              ArrestedTime: "",
              BookandRelease: false,
              InCustody: false,
              Photographed: false,
              ReasonHeld: "",
              ReasonHeldDetails: "",
              WarrantNumber: [
                {
                  WarrantNumber: "",
                  id: 1,
                  WarrantId: "",
                },
              ],

              AddressType: "",
              Apartment: "",
              City: "",
              Country: "",
              GridNumber: "",
              Sameasprimaryincidentaddress: "",
              StateProvidence: "",
              Street: "",
              StreetNumber: "",
              StreetPrefix: "",
              StreetSuffix: "",
              UnknownAddress: false,
              ZipCode: "",
            },
        Biases: {
          BiasOffender: true,
          Biastype: resBiases.length
            ? [
                ...resBiases.map((val, i) => {
                  return {
                    BiastypeDropdownName: val.BiasOffenderGroupAffiliation,
                    Biastype: val.NcicbiasOffenderGroupAffiliationCodeId,
                    id: i + 1,
                    push: true,
                    Audit: {
                      Biastype: {
                        old: val.BiasOffenderGroupAffiliation,
                        new: "",
                      },
                    },
                  };
                }),
                {
                  Biastype: "",
                  BiastypeDropdownName: "",
                  id: resBiases.length + 1,
                  push: false,
                  Audit: {
                    Biastype: {
                      old: "",
                      new: "",
                    },
                  },
                },
              ]
            : [
                {
                  Biastype: "",
                  BiastypeDropdownName: "",
                  id: 1,
                  push: false,
                  Audit: {
                    Biastype: {
                      old: "",
                      new: "",
                    },
                  },
                },
              ],
        },
      },
    });
    setLoading(false);

    let { personIdentificationIRes, resContacts } =
      await identificationMainFunction.getIdentification(
        incidentId,
        participantId
      );
    let ExternalContact = await multipleEmergencyFunction.getEmergencyContact(
      incidentId,
      participantId
    );
    let SubstanceAndAlcohol =
      await substanceMainFunction.getSubstanceMainDetainment(
        incidentId,
        participantId
      );
    let { AdditionalInfo, AdditionalInfoEmployment } =
      await additionalMainFunction.getAdditionalInfo(incidentId, participantId);

    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        Demographics: {
          BirthplaceCIty: resInvolvement.PersonalInfo.BirthplaceCity,
          Build: resInvolvement.PersonalInfo.NcicbuildCodeCodeId,
          Eye: resInvolvement.PersonalInfo.NciceyeColorCodeCodeId,
          FacialHair: resInvolvement.PersonalInfo.FacialHair ? "1" : "2",
          Glasses: resInvolvement.PersonalInfo.Glasses ? "1" : "2",
          Hair: resInvolvement.PersonalInfo.NcichairColorCodeCodeId,
          Handedness: resInvolvement.PersonalInfo.IsHandedness ? "1" : "2",
          Height:
            resInvolvement.PersonalInfo.Height == null
              ? "Unknown"
              : resInvolvement.PersonalInfo.Height,
          Race: resInvolvement.PersonalInfo.NcicraceCodeCodeId,
          Sex: resInvolvement.PersonalInfo.NcicsexCodeCodeId,
          SkinTon: resInvolvement.PersonalInfo.NciccomplexionCodeCodeId,
          StateProvidence:
            resInvolvement.PersonalInfo.NcicbirthplaceStateCodeId,
          Weight:
            resInvolvement.PersonalInfo.Weight == null
              ? "Unknown"
              : String(resInvolvement.PersonalInfo.Weight),
          MinHeight: resInvolvement.PersonalInfo.MinHeight,
          MinWeight: resInvolvement.PersonalInfo.MinWeight,
          MaxHeight: resInvolvement.PersonalInfo.MaxHeight,
          MaxWeight: resInvolvement.PersonalInfo.MaxWeight,
          Country: resInvolvement.PersonalInfo.NcicbirthplaceCountryCodeId,
        },
        Involvement: {
          InCustody: resInvolvement.PersonalInfo.InCustody,
          MissingRunaway: resInvolvement.PersonalInfo.IsMissingRunaway,
          DOB: resInvolvement.NameMasterDetail.Dob
            ? formatDate(resInvolvement.NameMasterDetail.Dob)
            : "",
          DOBRefused: resInvolvement.PersonalInfo.BirthDateRefused,
          Firstname: resInvolvement.PersonalInfo.FirstName,
          InvolvementType: resInvolvement.ParticipantRole?.RoleId,
          Juvenile: resInvolvement.PersonalInfo.IsJuvenile,
          LastName: resInvolvement.NameMasterDetail.PersonSurName,
          Middlename: resInvolvement.NameMasterDetail.PersonMiddleName,
          Private: resInvolvement.PersonalInfo.ConfidentialInformant,
          ProcORI: resInvolvement.PersonalInfo.ProcOri,
          Suffix: resInvolvement.PersonalInfo.NcicnameSuffixCodeId,
          DOBUnknown: resInvolvement.PersonalInfo.IsAgeDOBUnknown,
          ParticipantId: resInvolvement.ParticipantDetail.ParticipantId,
          CitationId: resInvolvement.ParticipantDetail.CitationId,
          NameId: resInvolvement.ParticipantDetail.NameId,
          EntityTypeId: resInvolvement.ParticipantDetail.EntityTypeId,
          BusinessId: resInvolvement.ParticipantDetail.BusinessId,

          PersonId: resInvolvement.ParticipantDetail.PersonId,
          MasterNameId: resInvolvement.PersonalInfo.MasterNameId,
          ParticipantRoleId: resInvolvement.ParticipantRole.ParticipantRoleId,
          Age: resInvolvement.PersonalInfo.Age,
          MaxAge: resInvolvement.PersonalInfo.MaxAge,
          MinAge: resInvolvement.PersonalInfo.MinAge,
        },
        Address: {
          ...Mainfields.InvolvementTab.Address,
          NoPermanentAddress: resInvolvement.PersonalInfo.NoPermanentAddress,
          UnknownAddress: resInvolvement.PersonalInfo.NotKnownAddress,
          AddressData: resAddress.length
            ? [
                ...resAddress.map((val, i) => {
                  return {
                    AddressLine1: val.Address1,
                    CountryDropdownValue: val.CountryName,
                    StateProvidenceDropdownvalue: val.StateName,
                    AddressLine2: val.Address2,
                    AddressType: val.AddressType,
                    Apartment: val.HouseNumber,
                    City: val.City,
                    Country: val.NciccountryId,
                    ForeignAddress: val.IsForeignAddress,
                    ForeignAddressCity: val.OtherCity,
                    ForeignAddressCountry: val.OtherCountry,
                    ForeignAddressStateProvinceRegion: val.OtherState,
                    ForeignAddressZip: val.OtherZip,
                    GridNumber: val.Grid,
                    id: i + 1,
                    StateProvidence: val.NcicstateId,
                    Street: val.Street2Name,
                    StreetNumber: val.Street1Name,
                    StreetPrefix: val.Ncicstreet1PrefixId,
                    StreetSuffix: val.Ncicstreet1SuffixId,
                    ZipCode: val.ZipCode,
                    LocationCategoryCode: val.LocationCategoryCode,
                    LocationId: val.LocationId,
                    AgencyId: val.AgencyId,
                    push: true,
                  };
                }),
                {
                  AddressType: "",
                  Apartment: "",
                  City: "",
                  Country: "",
                  GridNumber: "",
                  id: resAddress.length + 1,
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                  AddressLine1: "",
                  AddressLine2: "",
                  ForeignAddressCity: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  ForeignAddressCountry: "",
                  CountryDropdownValue: "",
                  StateProvidenceDropdownvalue: "",
                  ForeignAddress: false,
                },
              ]
            : [
                {
                  AddressType: "",
                  Apartment: "",
                  City: "",
                  Country: "",
                  GridNumber: "",
                  id: 1,
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                  AddressLine1: "",
                  AddressLine2: "",
                  ForeignAddressCity: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  ForeignAddressCountry: "",
                  CountryDropdownValue: "",
                  StateProvidenceDropdownvalue: "",
                  ForeignAddress: false,
                },
              ],
        },
        ScarsMarksTattoos: resScarsMarksTattoos.length
          ? [
              ...resScarsMarksTattoos.map((val, i) => {
                return {
                  Notes: val.PhysicalFeatureText,
                  ScarMarkTattoosDropdownValue: val.PhysicalFeatureCode,
                  TypeLocationDropdownValue: val.PhysicalFeatureLocation,
                  ScarMarkTattoos: val.PhysicalFeatureNciccodeId,
                  id: i + 1,
                  PhysicalFeatureId: val.PhysicalFeatureId,
                  TypeLocation: val.PhysicalFeatureLocationNciccodeId,
                  push: true,
                };
              }),
              {
                id: resScarsMarksTattoos.length,
                Notes: "",
                ScarMarkTattoos: "",
                TypeLocation: "",
                ScarMarkTattoosDropdownValue: "",
                TypeLocationDropdownValue: "",
              },
            ]
          : [
              {
                id: 1,
                Notes: "",
                ScarMarkTattoos: "",
                TypeLocation: "",
                ScarMarkTattoosDropdownValue: "",
                TypeLocationDropdownValue: "",
              },
            ],
        Aliases: resaliase.length
          ? [
              ...resaliase.map((val, i) => {
                return {
                  DOB: formatDate(val.Dob),
                  Firstname: val.FirstName,
                  id: i + 1,
                  LastName: val.LastName,
                  Middlename: val.MiddleName,
                  AliasNameId: val.AliasNameId,
                  push: true,
                };
              }),
              {
                DOB: "",
                Firstname: "",
                id: resaliase.length + 1,
                LastName: "",
                Middlename: "",
              },
            ]
          : [{ DOB: "", Firstname: "", id: 1, LastName: "", Middlename: "" }],

        Victiminformation: resVictims
          ? {
              ...Mainfields.InvolvementTab.Victiminformation,
              AgencyId: resVictims?.Victims?.AgencyId,
              VictimId: resVictims?.Victims?.VictimId,
              VictimType: resVictims?.Victims?.VictimTypeCodeId,
              EnforcementOfficialActivityCategoryCode:
                resVictims?.Victims?.OfficerActivityCodeId,
              EnforcementOfficialAssignmentCategoryCode:
                resVictims?.Victims?.OfficerAssignmentTypeCodeId,
              OfficerOtherJurisdiction:
                resVictims?.Victims?.OfficerOtherJurisdiction,
              AdditionalJustifiablehomicide: resVictims
                .AdditionalJustifiableHomicides.length
                ? resVictims.AdditionalJustifiableHomicides.map(
                    (
                      valAdditionalJustifiableHomicidesval,
                      valAdditionalJustifiableHomicidesi
                    ) => {
                      return {
                        AdditionalJustifiablehomicide:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideCodeId,
                        id: valAdditionalJustifiableHomicidesi + 1,
                        AgencyId: valAdditionalJustifiableHomicidesval.AgencyId,
                        AggravatedAssaultHomicideId:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideId,
                        IncidentId:
                          valAdditionalJustifiableHomicidesval.IncidentId,
                        PersonId: valAdditionalJustifiableHomicidesval.PersonId,
                        AdditionalJustifiableHomicideId:
                          valAdditionalJustifiableHomicidesval.AdditionalJustifiableHomicideId,
                      };
                    }
                  )
                : [
                    {
                      AdditionalJustifiablehomicide: "",
                      AgencyId: "",
                      AggravatedAssaultHomicideId: "",
                      id: 1,
                      IncidentId: "",
                      AdditionalJustifiableHomicideId: "",
                      PersonId: "",
                    },
                  ],
              AggravatedAssaulthomicideCode: resVictims
                .AggravatedAssaultHomicides.length
                ? resVictims.AggravatedAssaultHomicides.map(
                    (
                      AggravatedAssaultHomicidesval,
                      AggravatedAssaultHomicidesi
                    ) => {
                      return {
                        AggravatedAssaulthomicideCode:
                          AggravatedAssaultHomicidesval.AggravatedAssaultHomicideCodeId,
                        id: AggravatedAssaultHomicidesi + 1,
                        AgencyId: AggravatedAssaultHomicidesval.AgencyId,
                        AggravatedAssaultHomicideId:
                          AggravatedAssaultHomicidesval.AggravatedAssaultHomicideId,
                        IncidentId: AggravatedAssaultHomicidesval.IncidentId,
                        PersonId: AggravatedAssaultHomicidesval.PersonId,
                      };
                    }
                  )
                : [
                    {
                      AgencyId: "",
                      AggravatedAssaulthomicideCode: "",
                      AggravatedAssaultHomicideId: "",
                      id: 1,
                      IncidentId: "",
                      PersonId: "",
                    },
                  ],
              InjuryCategoryCode: resVictims.Injuries.length
                ? resVictims.Injuries.map((Injuriesval, Injuriesi) => {
                    return {
                      id: Injuriesi + 1,
                      InjuryCategoryCode: Injuriesval.InjuryCodeId,
                      AgencyId: Injuriesval.AgencyId,
                      IncidentId: Injuriesval.IncidentId,
                      InjuryId: Injuriesval.InjuryId,
                      PersonId: Injuriesval.PersonId,
                    };
                  })
                : [
                    {
                      AgencyId: "",
                      id: 1,
                      IncidentId: "",
                      InjuryCategoryCode: "",
                      InjuryId: "",
                      PersonId: "",
                    },
                  ],
            }
          : {
              AgencyId: "",
              VictimId: "",
              AggravatedAssaulthomicideCode: [
                {
                  AggravatedAssaulthomicideCode: "",
                  id: 1,
                  AgencyId: "",
                  AggravatedAssaultHomicideId: "",
                  IncidentId: "",
                  PersonId: "",
                },
              ],
              EnforcementOfficialActivityCategoryCode: "",
              EnforcementOfficialAssignmentCategoryCode: "",
              InjuryCategoryCode: [
                {
                  InjuryCategoryCode: "",
                  id: 1,
                  AgencyId: "",
                  IncidentId: "",
                  InjuryId: "",
                  PersonId: "",
                },
              ],
              AdditionalJustifiablehomicide: [
                {
                  AdditionalJustifiablehomicide: "",
                  id: 1,
                  AggravatedAssaultHomicideId: "",
                  AdditionalJustifiableHomicideId: "",
                  AgencyId: "",
                  IncidentId: "",
                  PersonId: "",
                },
              ],
              OfficerOtherJurisdiction: false,
              USRCode: "",
              VictimType: "",
            },
        Arrestinformation: resArrest
          ? {
              ArrestId: resArrest.ArrestId,
              LocationId: resArrest.ArrestId,
              AddressType: resArrest?.Address[0]?.AddressType,
              Apartment: resArrest.Address[0]?.HouseNumber,
              ArmedWithWeapon: resArrest?.ArresteeWasArmedWithType?.length
                ? resArrest?.ArresteeWasArmedWithType?.map(
                    (
                      ArresteeWasArmedWithTypeval,
                      ArresteeWasArmedWithTypei
                    ) => {
                      return {
                        ArmedWithWeapon:
                          ArresteeWasArmedWithTypeval.ArresteeArmedWithCodeId,
                        id: ArresteeWasArmedWithTypei + 1,
                        Weaponautomatic:
                          ArresteeWasArmedWithTypeval.IsAutomatic,
                        ArresteeWasArmedWithTypeId:
                          ArresteeWasArmedWithTypeval.ArresteeWasArmedWithTypeId,
                      };
                    }
                  )
                : [
                    {
                      ArmedWithWeapon: "",
                      ArresteeWasArmedWithTypeId: "",
                      id: 1,
                      Weaponautomatic: false,
                    },
                  ],
              ArrestCategoryCode: resArrest.ArrestTypeCodeId,
              ArrestedDate: formatDate(resArrest.ArrestDateTime),
              ArrestedTime: time,
              BookandRelease: resArrest.BookAndRelease,
              City: resArrest.Address[0]?.City,
              Country: resArrest.Address[0]?.NciccountryId,
              GridNumber: resArrest.Address[0]?.Grid,
              InCustody: resArrest.InCustody,
              Photographed: resArrest.IsPhotographed,
              ReasonHeld: resArrest.ReasonHeld,
              ReasonHeldDetails: resArrest.ReasonHeldDetail,
              Sameasprimaryincidentaddress: "",
              StateProvidence: resArrest.Address[0]?.NcicstateId,
              Street: resArrest.Address[0]?.Street2Name,
              StreetNumber: resArrest.Address[0]?.Street1Name,
              StreetPrefix: resArrest.Address[0]?.Ncicstreet1PrefixId,
              StreetSuffix: resArrest.Address[0]?.Ncicstreet1SuffixId,
              UnknownAddress: resArrest.UnknownAddress,
              WarrantNumber: resArrest.WarrantViewModel.length
                ? resArrest.WarrantViewModel.map(
                    (WarrantViewModelval, WarrantViewModelvali) => {
                      return {
                        id: WarrantViewModelvali + 1,
                        WarrantNumber: WarrantViewModelval.Number,
                        WarrantId: WarrantViewModelval.WarrantId,
                      };
                    }
                  )
                : [
                    {
                      WarrantNumber: "",
                      id: 1,
                      WarrantId: "",
                    },
                  ],
              ZipCode: resArrest.Address[0]?.ZipCode,
            }
          : {
              ArrestId: "",
              LocationId: "",
              ArmedWithWeapon: [
                {
                  ArmedWithWeapon: "",
                  Weaponautomatic: false,
                  id: 1,
                  ArresteeWasArmedWithTypeId: "",
                },
              ],
              ArrestCategoryCode: "",
              ArrestedDate: "",
              ArrestedTime: "",
              BookandRelease: false,
              InCustody: false,
              Photographed: false,
              ReasonHeld: "",
              ReasonHeldDetails: "",
              WarrantNumber: [
                {
                  WarrantNumber: "",
                  id: 1,
                  WarrantId: "",
                },
              ],

              AddressType: "",
              Apartment: "",
              City: "",
              Country: "",
              GridNumber: "",
              Sameasprimaryincidentaddress: "",
              StateProvidence: "",
              Street: "",
              StreetNumber: "",
              StreetPrefix: "",
              StreetSuffix: "",
              UnknownAddress: false,
              ZipCode: "",
            },
        Biases: {
          BiasOffender: true,
          Biastype: resBiases.length
            ? [
                ...resBiases.map((val, i) => {
                  return {
                    BiastypeDropdownName: val.BiasOffenderGroupAffiliation,
                    Biastype: val.NcicbiasOffenderGroupAffiliationCodeId,
                    id: i + 1,
                    push: true,
                    Audit: {
                      Biastype: {
                        old: val.BiasOffenderGroupAffiliation,
                        new: "",
                      },
                    },
                  };
                }),
                {
                  Biastype: "",
                  BiastypeDropdownName: "",
                  id: resBiases.length + 1,
                  push: false,
                  Audit: {
                    Biastype: {
                      old: "",
                      new: "",
                    },
                  },
                },
              ]
            : [
                {
                  Biastype: "",
                  BiastypeDropdownName: "",
                  id: 1,
                  push: false,
                  Audit: {
                    Biastype: {
                      old: "",
                      new: "",
                    },
                  },
                },
              ],
        },
      },
      IdentificationAndContact: {
        IdentificationNumber: personIdentificationIRes.length
          ? [
              ...personIdentificationIRes.map((val, i) => {
                return {
                  PersonIdentificationId: val.PersonIdentificationId,
                  NcicpersonIdentificationCodeId:
                    val.NcicpersonIdentificationCodeId,
                  LocationCanadianProvinceCode:
                    val.LocationCanadianProvinceCode,
                  LocationStateUspostalServiceCode:
                    val.LocationStateUspostalServiceCodeId,
                  IdentificationClass: val.IdentificationClass,
                  PrimaryIdentification: val.PrimaryIdentification,
                  EffectiveEndDate: formatDate(val.EffectiveEndDate),
                  EffectiveStartDate: formatDate(val.EffectiveStartDate),
                  id: i + 1,
                  IdentificationId: val.IdentificationId,
                  PersonId: val.PersonIdentificationId,
                  IdentificationTypeDropdownValue:
                    val.NcicpersonIdentificationCode,
                  StateProvidenceDropdownValue: "",
                  push: true,
                };
              }),
              {
                id: personIdentificationIRes.length + 1,
                PersonIdentificationId: "",
                PersonId: "",
                NcicpersonIdentificationCodeId: "",
                IdentificationId: 1,
                LocationCanadianProvinceCode: "",
                LocationStateUspostalServiceCode: "",
                IdentificationClass: "",
                PrimaryIdentification: "",
                EffectiveStartDate: "",
                EffectiveEndDate: "",
                IdentificationTypeDropdownValue: "",
                StateProvidenceDropdownValue: "",
              },
            ]
          : [
              {
                id: 1,
                PersonIdentificationId: "",
                PersonId: "",
                NcicpersonIdentificationCodeId: "",
                IdentificationId: 1,
                LocationCanadianProvinceCode: "",
                LocationStateUspostalServiceCode: "",
                IdentificationClass: "",
                PrimaryIdentification: "",
                EffectiveStartDate: "",
                EffectiveEndDate: "",
                IdentificationTypeDropdownValue: "",
                StateProvidenceDropdownValue: "",
              },
            ],
        Email: resContacts.filter((val) => {
          if (val.ContactType == "Email") {
            return val;
          }
        }).length
          ? [
              ...resContacts
                .filter((val) => {
                  if (val.ContactType == "Email") {
                    return val;
                  }
                })
                .map((resContactsval, resContactsi) => {
                  return {
                    Email: resContactsval.ContactValue,
                    ExternalContactID: "",
                    id: resContactsi + 1,
                    TypeDropdownvalue: resContactsval.ContactTypeName
                      ? resContactsval.ContactTypeName
                      : "",
                    Type: resContactsval.ContactTypeCodeId,
                    push: true,
                  };
                }),
              {
                Email: "",
                ExternalContactID: "",
                TypeDropdownvalue: "",
                id: resContacts.length + 1,
                Type: "",
              },
            ]
          : [
              {
                Email: "",
                ExternalContactID: "",
                TypeDropdownvalue: "",
                id: 1,
                Type: "",
              },
            ],
        PhoneNumber: resContacts.filter((val) => {
          if (val.ContactType == "Phone") {
            return val;
          }
        }).length
          ? [
              ...resContacts
                .filter((val) => {
                  if (val.ContactType == "Phone") {
                    return val;
                  }
                })
                .map((resContactsval, resContactsi) => {
                  return {
                    ExternalContactID: "",
                    id: resContactsi + 1,
                    Type: resContactsval.ContactTypeCodeId,

                    Country: resContactsval.CountryCodeId
                      ? resContactsval.CountryCodeId
                      : "",
                    Ext: resContactsval.Ext ? resContactsval.Ext : "",
                    Number: resContactsval.ContactValue,
                    push: true,
                    TypeDropdownvalue: resContactsval.ContactTypeName
                      ? resContactsval.ContactTypeName
                      : "",
                  };
                }),
              {
                Country: "",
                Ext: "",
                ExternalContactID: "",
                id: resContacts.length + 1,
                Number: "",
                TypeDropdownvalue: "",
                Type: "",
              },
            ]
          : [
              {
                Country: "",
                Ext: "",
                ExternalContactID: "",
                id: 1,
                Number: "",
                TypeDropdownvalue: "",
                Type: "",
              },
            ],
      },
      MultipleEmergencyContact: ExternalContact?.length
        ? [
            ...ExternalContact.map((val, MultipleEmergencyContacti) => {
              return {
                EmergencyContact: {
                  ExternalContactID: "",
                  Firstname: val.FirstName,
                  LastName: val.LastName,
                  Middlename: val.MiddleName,
                  Relation: val.Relation,
                  Suffix: val.SuffixCodeId,
                  Address: val.Address,
                  RelationDropdownName: val.RelationName
                    ? val.RelationName
                    : "",
                },
                Address: {
                  NoPermanentAddress: val.NoPermanentAddress,
                  UnknownAddress: val.UnknownAddress,
                  AddressData: val.Address.Addresses.length
                    ? val.Address.Addresses.map((Addressesval, i) => {
                        return {
                          id: i + 1,
                          AddressType: Addressesval.AddressType,
                          StreetNumber: Addressesval.Street1Name,
                          StreetPrefix: Addressesval.Ncicstreet1PrefixId,
                          Street: Addressesval.Street2Name,
                          StreetSuffix: Addressesval.Ncicstreet1SuffixId,
                          Apartment: Addressesval.HouseNumber,
                          City: Addressesval.City,
                          StateProvidence: Addressesval.NcicstateId,
                          ZipCode: Addressesval.ZipCode,
                          Country: Addressesval.NciccountryId,
                          GridNumber: Addressesval.Grid,
                          AddressLine1: Addressesval.Address1,
                          AddressLine2: Addressesval.Address2,
                          ForeignAddressCity: Addressesval.OtherCity,
                          ForeignAddressStateProvinceRegion:
                            Addressesval.OtherState,
                          ForeignAddressZip: Addressesval.OtherZip,
                          ForeignAddressCountry: Addressesval.OtherCountry,
                          ForeignAddress: Addressesval.IsForeignAddress,
                          LocationCategoryCode:
                            Addressesval.LocationCategoryCode,
                          LocationId: Addressesval.LocationId,
                          AgencyId: "00000000-0000-0000-0000-000000000000",
                          CountryDropdownValue: Addressesval.CountryName,
                          StateProvidenceDropdownvalue: Addressesval.StateName,
                        };
                      })
                    : [
                        {
                          AddressType: "",
                          Apartment: "",
                          City: "",
                          Country: "",
                          GridNumber: "",
                          id: 1,
                          StateProvidence: "",
                          Street: "",
                          StreetNumber: "",
                          StreetPrefix: "",
                          StreetSuffix: "",
                          ZipCode: "",
                          AddressLine1: "",
                          AddressLine2: "",
                          ForeignAddressCity: "",
                          ForeignAddressStateProvinceRegion: "",
                          ForeignAddressZip: "",
                          ForeignAddressCountry: "",
                          ForeignAddress: false,
                          CountryDropdownValue: "",
                          StateProvidenceDropdownvalue: "",
                        },
                      ],
                },
                Email: val.Contact.Contacts.length
                  ? val.Contact.Contacts.filter((Contactsval) => {
                      if (Contactsval.ContactType == "Email") {
                        return Contactsval;
                      }
                    }).map((ContactEmail, ContactEmaili) => {
                      return {
                        ExternalContactID: "",
                        Type: ContactEmail.ContactTypeCodeId,
                        Email: ContactEmail.ContactValue,
                        id: ContactEmaili + 1,
                        TypeDropdownvalue: ContactEmail.ContactTypeName
                          ? ContactEmail.ContactTypeName
                          : "",
                      };
                    })
                  : [
                      {
                        Email: "",
                        ExternalContactID: "",
                        id: 1,
                        Type: "",
                        TypeDropdownvalue: "",
                      },
                    ],
                ExternalContactID: val.ExternalContactID,
                id: MultipleEmergencyContacti + 1,
                push: true,
                PhoneNumber: val.Contact.Contacts.length
                  ? val.Contact.Contacts.filter((Contactsval) => {
                      if (Contactsval.ContactType == "Phone") {
                        return Contactsval;
                      }
                    }).map((ContactEmail, ContactEmaili) => {
                      return {
                        ExternalContactID: "",
                        Type: ContactEmail.ContactTypeCodeId,
                        id: ContactEmaili + 1,
                        Country: ContactEmail.CountryCodeId,
                        Number: ContactEmail.ContactValue,
                        Ext: ContactEmail.Ext ? ContactEmail.Ext : "",
                        TypeDropdownvalue: ContactEmail.ContactTypeName
                          ? ContactEmail.ContactTypeName
                          : "",
                      };
                    })
                  : [
                      {
                        Country: "",
                        Ext: "",
                        ExternalContactID: "",
                        id: 1,
                        Number: "",
                        Type: "",
                        TypeDropdownvalue: "",
                      },
                    ],
              };
            }),
            {
              Address: {
                AddressData: [
                  {
                    AddressType: "",
                    Apartment: "",
                    City: "",
                    Country: "",
                    GridNumber: "",
                    id: 1,
                    StateProvidence: "",
                    Street: "",
                    StreetNumber: "",
                    StreetPrefix: "",
                    StreetSuffix: "",
                    ZipCode: "",
                    AddressLine1: "",
                    AddressLine2: "",
                    ForeignAddressCity: "",
                    ForeignAddressStateProvinceRegion: "",
                    ForeignAddressZip: "",
                    ForeignAddressCountry: "",
                    ForeignAddress: false,
                    CountryDropdownValue: "",
                    StateProvidenceDropdownvalue: "",
                  },
                ],
                NoPermanentAddress: false,
                UnknownAddress: false,
              },
              push: false,
              EmergencyContact: {
                ExternalContactID: "",
                Relation: "",
                LastName: "",
                Firstname: "",
                Middlename: "",
                Suffix: "",
                RelationDropdownName: "",
              },
              PhoneNumber: [
                {
                  Country: "",
                  Ext: "",
                  ExternalContactID: "",
                  id: 1,
                  Number: "",
                  TypeDropdownvalue: "",
                  Type: "",
                },
              ],
              Email: [
                {
                  Email: "",
                  ExternalContactID: "",
                  id: 1,
                  Type: "",
                  TypeDropdownvalue: "",
                },
              ],
              id: ExternalContact.length + 1,
              ExternalContactID: "",
            },
          ]
        : [
            {
              Address: {
                AddressData: [
                  {
                    AddressType: "",
                    Apartment: "",
                    City: "",
                    Country: "",
                    GridNumber: "",
                    id: 1,
                    StateProvidence: "",
                    Street: "",
                    StreetNumber: "",
                    StreetPrefix: "",
                    StreetSuffix: "",
                    ZipCode: "",
                    AddressLine1: "",
                    AddressLine2: "",
                    ForeignAddressCity: "",
                    ForeignAddressStateProvinceRegion: "",
                    ForeignAddressZip: "",
                    ForeignAddressCountry: "",
                    ForeignAddress: false,
                    CountryDropdownValue: "",
                    StateProvidenceDropdownvalue: "",
                  },
                ],
                NoPermanentAddress: false,
                UnknownAddress: false,
              },
              push: false,
              EmergencyContact: {
                ExternalContactID: "",
                Relation: "",
                LastName: "",
                Firstname: "",
                Middlename: "",
                Suffix: "",
                RelationDropdownName: "",
              },
              PhoneNumber: [
                {
                  Country: "",
                  Ext: "",
                  ExternalContactID: "",
                  id: 1,
                  Number: "",
                  TypeDropdownvalue: "",
                  Type: "",
                },
              ],
              Email: [
                {
                  Email: "",
                  ExternalContactID: "",
                  id: 1,
                  Type: "",
                  TypeDropdownvalue: "",
                },
              ],
              id: 1,
              ExternalContactID: "",
            },
          ],

      SubstanceTab: {
        BloodTab: SubstanceAndAlcohol.BloodAlcoholLevels.length
          ? [
              ...SubstanceAndAlcohol.BloodAlcoholLevels.map((val, i) => {
                let { time } = val.TestDateTime
                  ? parseISOToDateTime(val.TestDateTime)
                  : {
                      time: "",
                    };
                return {
                  id: i + 1,
                  NcicsubstanceTypeCodeId: val.TestTypeCodeId,
                  TestTypeDropdownvalue: val.TestTypeCodeId
                    ? val.TestTypeCodeId
                    : "",
                  TestDate: val.TestDateTime
                    ? formatDate(val.TestDateTime)
                    : "",
                  TestResults: val.TestResult,
                  TestTime: time ? time : "",
                  ParticipantBloodAlcoholLevelId:
                    val.ParticipantBloodAlcoholLevelId,
                  AgencyId: val.AgencyId,
                  IncidentId: val.IncidentId,
                  PersonId: val.PersonId,
                  alcholedropdownvalue: "",
                  push: true,
                };
              }),
              {
                id: SubstanceAndAlcohol.BloodAlcoholLevels.length + 1,
                NcicsubstanceTypeCodeId: "",
                TestTypeDropdownvalue: "",
                TestDate: "",
                TestResults: 0,
                TestTime: "",
                ParticipantBloodAlcoholLevelId: "",
                AgencyId: "",
                IncidentId: "",
                PersonId: "",
                alcholedropdownvalue: "",
              },
            ]
          : [
              {
                id: 1,
                NcicsubstanceTypeCodeId: "",
                TestTypeDropdownvalue: "",
                TestDate: "",
                TestResults: 0,
                TestTime: "",
                ParticipantBloodAlcoholLevelId: "",
                AgencyId: "",
                IncidentId: "",
                PersonId: "",
                alcholedropdownvalue: "",
              },
            ],
        Substance: SubstanceAndAlcohol.SubstanceInvolvements.length
          ? [
              ...SubstanceAndAlcohol.SubstanceInvolvements.map((val, i) => {
                return {
                  id: i + 1,
                  IsInvolved: val.IsInvolved ? "1" : "2",
                  NcicsubstanceTypeCodeId: val.NcicsubstanceTypeCodeId
                    ? val.NcicsubstanceTypeCodeId
                    : "",
                  TestResult: "",

                  SubstanceInvolvementId: val.SubstanceInvolvementId,
                  AgencyId: val.AgencyId,
                  IncidentId: val.IncidentId,
                  PersonId: val.PersonId,
                  substanceTypeDropdownvalue: "",
                  push: true,
                };
              }),
              {
                id: SubstanceAndAlcohol.SubstanceInvolvements.length + 1,
                IsInvolved: "",
                NcicsubstanceTypeCodeId: "",
                TestResult: "",

                SubstanceInvolvementId: "",
                AgencyId: "",
                IncidentId: "",
                PersonId: "",
                substanceTypeDropdownvalue: "",
              },
            ]
          : [
              {
                id: 1,
                IsInvolved: "",
                NcicsubstanceTypeCodeId: "",
                TestResult: "",

                SubstanceInvolvementId: "",
                AgencyId: "",
                IncidentId: "",
                PersonId: "",
                substanceTypeDropdownvalue: "",
              },
            ],
      },

      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,

        FingerprintIndivator: AdditionalInfo.FingerprintIndivator
          ? AdditionalInfo.FingerprintIndivator
          : false,
        GunShotInjury: AdditionalInfo.GunShotInjury
          ? AdditionalInfo.GunShotInjury
          : false,
        IsDeceased: AdditionalInfo.IsDeceased,
        IsDisabled: AdditionalInfo.IsDisabled,
        IsDodunknown: AdditionalInfo.IsDodunknown,
        BloodType: AdditionalInfo.NcicbloodTypeCodeId
          ? AdditionalInfo.NcicbloodTypeCodeId
          : "",

        PersonId: AdditionalInfo.PersonId,
        AgencyId: AdditionalInfo.AgencyId,
        IncidentId: AdditionalInfo.IncidentId,
        MasterNameId: AdditionalInfo.MasterNameId,
        DateOfDeath: AdditionalInfo.DateOfDeath
          ? formatDate(AdditionalInfo.DateOfDeath)
          : "",
        DOA: AdditionalInfo.DeadOnArrival,
        Employment: AdditionalInfoEmployment.length
          ? [
              ...AdditionalInfoEmployment.map((val, i) => {
                return {
                  Address: {
                    id: i + 1,
                    CountryName: val.Address[0]?.CountryName,
                    AddressType: val.Address[0]?.AddressType,
                    StreetNumber: val.Address[0]?.Street1Name,
                    StreetPrefix: val.Address[0]?.Ncicstreet1PrefixId,
                    Street: val.Address[0]?.Street2Name,
                    StreetSuffix: val.Address[0]?.Ncicstreet1SuffixId,
                    Apartment: val.Address[0]?.HouseNumber,
                    City: val.Address[0]?.City,
                    StateProvidence: val.Address[0]?.NcicstateId,
                    ZipCode: val.Address[0]?.ZipCode,
                    Country: val.Address[0]?.NciccountryId,
                    GridNumber: val.Address[0]?.Grid,
                    AddressLine1: val.Address[0]?.Address1,
                    AddressLine2: val.Address[0]?.Address2,
                    ForeignAddressCity: val.Address[0]?.OtherCity,
                    ForeignAddressStateProvinceRegion:
                      val.Address[0]?.OtherState,
                    ForeignAddressZip: val.Address[0]?.OtherZip,
                    ForeignAddressCountry: val.Address[0]?.OtherCountry,
                    ForeignAddress: val.Address[0]?.IsForeignAddress,
                    LocationCategoryCode: val.Address[0]?.LocationCategoryCode,
                    LocationId: val.Address[0]?.LocationId,
                    NoPermanentAddress: false,
                    UnknownAddress: val.IsUnkownAddress,
                  },
                  push: true,
                  EmployerTypeName: val.EmployerTypeName,
                  OccupationName: val.OccupationName,
                  ShiftName: val.ShiftName,
                  ContactFirstname: val.ContactFirstName,
                  ContactLastname: val.ContactLastName,
                  Email: val.Contacts.length
                    ? val.Contacts.filter((Contactsval) => {
                        if (Contactsval.ContactType == "Email") {
                          return Contactsval;
                        }
                      }).map((ContactEmail, ContactEmaili) => {
                        return {
                          ExternalContactID: "",
                          Type: ContactEmail.ContactTypeCodeId,
                          Email: ContactEmail.ContactValue,
                          id: ContactEmaili + 1,
                          ContactId: ContactEmail.ContactId,
                          TypeDropdownvalue: ContactEmail.ContactType
                            ? ContactEmail.ContactType
                            : "",
                        };
                      })
                    : [
                        {
                          Email: "",
                          ExternalContactID: "",
                          id: 1,
                          Type: "",
                          ContactId: "",
                          TypeDropdownvalue: "",
                        },
                      ],
                  EmployerName: val.EmployerName,
                  EmployerType: val.EmployerTypeCodeId,
                  ExternalContactID: val.EmploymentId
                    ? val.EmploymentId
                    : Mainfields.AdditionalInfo.Employment[i].ExternalContactID,
                  id: i + 1,
                  Occupation: val.OccupationCodeId,
                  PhoneNumber: val.Contacts.length
                    ? val.Contacts.filter((Contactsval) => {
                        if (Contactsval.ContactType == "Phone") {
                          return Contactsval;
                        }
                      }).map((ContactEmail, ContactEmaili) => {
                        return {
                          ExternalContactID: "",
                          Type: ContactEmail.ContactTypeCodeId,
                          id: ContactEmaili + 1,
                          Country: ContactEmail.CountryCodeId,
                          Number: ContactEmail.ContactValue,
                          Ext: ContactEmail.Ext ? ContactEmail.Ext : "",
                          ContactId: ContactEmail.ContactId,
                          TypeDropdownvalue: ContactEmail.ContactType
                            ? ContactEmail.ContactType
                            : "",
                        };
                      })
                    : [
                        {
                          Country: "",
                          Ext: "",
                          ExternalContactID: "",
                          id: 1,
                          Number: "",
                          Type: "",
                          ContactId: "",
                          TypeDropdownvalue: "",
                        },
                      ],
                  Shift: val.ShiftCodeId,
                };
              }),
              {
                ExternalContactID: "",
                ContactFirstname: "",
                id: AdditionalInfoEmployment.length + 1,
                ContactLastname: "",
                EmployerName: "",
                EmployerType: "",
                Occupation: "",
                Shift: "",
                PhoneNumber: [
                  {
                    Country: "",
                    Ext: "",
                    ExternalContactID: "",
                    id: 1,
                    Number: "",
                    Type: "",
                    TypeDropdownvalue: "",
                  },
                ],
                Address: {
                  AddressLine1: "",
                  AddressLine2: "",
                  AddressType: "",
                  Apartment: "",
                  LocationId: "",
                  City: "",
                  Country: "",
                  NoPermanentAddress: false,
                  UnknownAddress: false,
                  ForeignAddress: false,
                  ForeignAddressCity: "",
                  ForeignAddressCountry: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  GridNumber: "",
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                },
                Email: [
                  {
                    Email: "",
                    ExternalContactID: "",
                    TypeDropdownvalue: "",
                    id: 1,
                    Type: "",
                  },
                ],
              },
            ]
          : [
              {
                ExternalContactID: "",
                ContactFirstname: "",
                id: 1,
                ContactLastname: "",
                EmployerName: "",
                EmployerType: "",
                Occupation: "",
                Shift: "",
                PhoneNumber: [
                  {
                    Country: "",
                    Ext: "",
                    ExternalContactID: "",
                    id: 1,
                    Number: "",
                    Type: "",
                    TypeDropdownvalue: "",
                  },
                ],
                Address: {
                  AddressLine1: "",
                  AddressLine2: "",
                  AddressType: "",
                  Apartment: "",
                  LocationId: "",
                  City: "",
                  Country: "",
                  NoPermanentAddress: false,
                  UnknownAddress: false,
                  ForeignAddress: false,
                  ForeignAddressCity: "",
                  ForeignAddressCountry: "",
                  ForeignAddressStateProvinceRegion: "",
                  ForeignAddressZip: "",
                  GridNumber: "",
                  StateProvidence: "",
                  Street: "",
                  StreetNumber: "",
                  StreetPrefix: "",
                  StreetSuffix: "",
                  ZipCode: "",
                },
                Email: [
                  {
                    Email: "",
                    ExternalContactID: "",
                    TypeDropdownvalue: "",
                    id: 1,
                    Type: "",
                  },
                ],
              },
            ],
        Citizenship: {
          Citizenship: AdditionalInfo.ParticipantAttributeList.map((val) => {
            if (val.AttributeValue == "CitizenzenshipCode") {
              return val.AttributeCodeId;
            } else {
              return "";
            }
          }).filter((val) => {
            if (val) {
              return val;
            }
          }),
          AttributeId: AdditionalInfo.ParticipantAttributeList.map((val) => {
            if (val.AttributeValue == "CitizenzenshipCode") {
              return val.AttributeId;
            } else {
              return "";
            }
          }).filter((val) => {
            if (val) {
              return val;
            }
          }),
          ParticipantAttributeId: AdditionalInfo.ParticipantAttributeList.map(
            (val) => {
              if (val.AttributeValue == "CitizenzenshipCode") {
                return val.ParticipantAttributeId;
              } else {
                return "";
              }
            }
          ).filter((val) => {
            if (val) {
              return val;
            }
          }),
          CitizenshipUnknown: AdditionalInfo.IsCitizenshipUnknown,
          IsUscitizen: AdditionalInfo.IsUscitizen
            ? AdditionalInfo.IsUscitizen
            : false,
          NonUSCitizen: false,
        },
        GangAffiliation: {
          AttributeId: AdditionalInfo.ParticipantAttributeList.map((val) => {
            if (val.AttributeValue == "GangAffiliations") {
              return val.AttributeId;
            } else {
              return "";
            }
          }).filter((val) => {
            if (val) {
              return val;
            }
          }),
          GangAffiliation: AdditionalInfo.ParticipantAttributeList.map(
            (val) => {
              if (val.AttributeValue == "GangAffiliations") {
                return val.AttributeCodeId;
              } else {
                return "";
              }
            }
          ).filter((val) => {
            if (val) {
              return val;
            }
          }),
          ParticipantAttributeId: AdditionalInfo.ParticipantAttributeList.map(
            (val) => {
              if (val.AttributeValue == "GangAffiliations") {
                return val.ParticipantAttributeId;
              } else {
                return "";
              }
            }
          ).filter((val) => {
            if (val) {
              return val;
            }
          }),
        },
        Language: {
          Language: AdditionalInfo.ParticipantAttributeList.map((val) => {
            if (val.AttributeValue == "Language") {
              return val.AttributeCodeId;
            } else {
              return "";
            }
          }).filter((val) => {
            if (val) {
              return val;
            }
          }),
          AttributeId: AdditionalInfo.ParticipantAttributeList.map((val) => {
            if (val.AttributeValue == "Language") {
              return val.AttributeId;
            } else {
              return "";
            }
          }).filter((val) => {
            if (val) {
              return val;
            }
          }),
          ParticipantAttributeId: AdditionalInfo.ParticipantAttributeList.map(
            (val) => {
              if (val.AttributeValue == "Language") {
                return val.ParticipantAttributeId;
              } else {
                return "";
              }
            }
          ).filter((val) => {
            if (val) {
              return val;
            }
          }),
          LimitedEnglishProficiency: AdditionalInfo.LimitedEnglishProficiency
            ? AdditionalInfo.LimitedEnglishProficiency
            : false,
        },

        FingerPrints: AdditionalInfo.FingerprintIndivator
          ? AdditionalInfo.FingerprintIndivator
          : false,
        Deceased: AdditionalInfo.IsDeceased ? AdditionalInfo.IsDeceased : false,
        Disability: {
          AttributeId: AdditionalInfo.ParticipantAttributeList.map((val) => {
            if (val.AttributeValue == "DisabilityTypeCode") {
              return val.AttributeId;
            } else {
              return "";
            }
          }).filter((val) => {
            if (val) {
              return val;
            }
          }),
          Disability: AdditionalInfo.ParticipantAttributeList.map((val) => {
            if (val.AttributeValue == "DisabilityTypeCode") {
              return val.AttributeCodeId;
            } else {
              return "";
            }
          }).filter((val) => {
            if (val) {
              return val;
            }
          }),
          ParticipantAttributeId: AdditionalInfo.ParticipantAttributeList.map(
            (val) => {
              if (val.AttributeValue == "DisabilityTypeCode") {
                return val.ParticipantAttributeId;
              } else {
                return "";
              }
            }
          ).filter((val) => {
            if (val) {
              return val;
            }
          }),
        },
        School: AdditionalInfo.SchoolCodeId ? AdditionalInfo.SchoolCodeId : "",
        SchoolGrade: AdditionalInfo.SchoolGradeCodeId
          ? AdditionalInfo.SchoolGradeCodeId
          : "",
        MaritalStatus: AdditionalInfo.NcicmaritialStatusCodeId
          ? AdditionalInfo.NcicmaritialStatusCodeId
          : "",
      },
    });
  }
}
