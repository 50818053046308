
import React, { useEffect } from "react";
import { DeleteConfirmationIProps } from "../../DeleteConfirmationI";
import RoleDeleteModal from "./RoleDeleteModal";

export const NewDeleteConfirmation: React.FC<DeleteConfirmationIProps> = ({
  onClose,
  onDelete,
  RoleError,
  handleRoleExistDelete,
}) => {
  let confirmationMessage = "";

  useEffect(() => {
    if (RoleError && Object.keys(RoleError).length !== 0) {
      confirmationMessage = "Currently used this policy";
    }
  }, [RoleError]);

  return (
    <RoleDeleteModal
    RoleError={RoleError}
    confirmationMessage={confirmationMessage}
    onClose={onClose}
    onDelete={onDelete}
    handleRoleExistDelete={handleRoleExistDelete}
  />
  );
};
