import { Fetcher } from "../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import { OverviewData } from "../../MasterNameIState";
import { OverviewTableIRes } from "./OverviewIState";

const fetcher = new Fetcher();
export class OverviewMainFunction {
  async getOverviewData(
    setLoading: (data: boolean) => void,
    setFields: (data: OverviewData) => void,
    fields: OverviewData,
    nameId:string,
  ) {
    setLoading(true);

    let response = await fetcher.get(
      `/name-master/${nameId}`
    );
console.log(response)
    let resInvolvement :OverviewTableIRes= response?.Data;

    setFields({
        Address:resInvolvement?.Address,
      Age: resInvolvement?.Age,
      DOB: resInvolvement?.DOB? formatDate(resInvolvement?.DOB) :"",
      Complexion:resInvolvement?.Complexion,
      PersonGivenName:resInvolvement?.PersonGivenName,
      PersonSurName: resInvolvement?.PersonSurName,
      PersonMiddleName: resInvolvement?.PersonMiddleName,
      EyeColor: resInvolvement?.EyeColor,
      FacialHair: resInvolvement?.FacialHair ? "Y" : "N",
      Glasses: resInvolvement?.Glasses ? "Y" : "N",
      Height: resInvolvement?.Height,
      Race:resInvolvement?.Race, 
      Sex:resInvolvement?.Sex,
      Skintone:resInvolvement?.Complexion,
      Weight:resInvolvement?.PhysicalFeatureText,
      Scars: resInvolvement?.PhysicalFeatureText,
      Tatoos:resInvolvement?.PhysicalFeatureCode,
    });

    setLoading(false);
  }
}
