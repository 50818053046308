import { Fetcher } from "../../../../../services/Fetcher";
import { getCADInformationJSONIRes } from "../CADInformationI";

const fetcher = new Fetcher();

export class IncidentsCADInformationJSONFunction {
  async getCADInformationJSON(
    setLoading: (data: boolean) => void,
    cadimportid: string,
    setCADInformationJSON: (data: string) => void,
    setCADInformationJSONErrorMessage: (data: string) => void
  ) {
    setLoading(true);
    let response = await fetcher.get(`/cad/${cadimportid}`);
    let res: getCADInformationJSONIRes = response?.Data;
    setCADInformationJSON(res.Cadjson);
    setCADInformationJSONErrorMessage(res.ErrorMessage);
    setLoading(false);
  }
}
