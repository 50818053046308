import add from "../../../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import { InvolvementMainFunction } from "../../../../../Involvement/InvolvementMainFunction";
import { DropdownComponent } from "../../../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { MaskedInputComponent } from "../../../../../../../../../../CommonComponents/Fields/MaskedInput/MaskedInputComponent";
import { TextBoxComponent } from "../../../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { PhoneNumberFunction } from "../../../../../ExternalContact/Components/Phone/PhoneNumberFunction";
import { setContactTypeDataIState } from "../../../../../ExternalContact/ExternalContactI";
import { GetRaceDataIState } from "../../../../../Involvement/InvolvementMainI";
import { AdditionalInfoProps } from "../../../../AdditionalIState";
import {
  EmployementPhoneIProps,
  PhoneNumberDataUIProps,
} from "./EmployementPhoneI";
import { Add } from "../../../../../../../../../../../assets";

export const PhoneNumberDataUI: React.FC<PhoneNumberDataUIProps> = ({
  Mainfields,
  setMainfields,
  Employmentval,
  PhoneNumberval,
  Participantionvalidation,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const phoneNumberFunction = new PhoneNumberFunction();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            return {
              ...val,
              PhoneNumber: val.PhoneNumber.map((PhoneNumberval) => {
                if (PhoneNumberval.id == id) {
                  if (key2) {
                    return { ...PhoneNumberval, [key]: value, [key2]: value2 };
                  } else {
                    return { ...PhoneNumberval, [key]: value };
                  }
                } else {
                  return PhoneNumberval;
                }
              }),
            };
          } else {
            return val;
          }
        }),
      },
    });
  };

  const remove = (id: number) => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            if (val.PhoneNumber.length > 1) {
              return {
                ...val,
                PhoneNumber: val.PhoneNumber.filter((PhoneNumberval) => {
                  if (PhoneNumberval.id != id) {
                    return PhoneNumberval;
                  }
                }),
              };
            } else {
              return val;
            }
          } else {
            return val;
          }
        }),
      },
    });
  };

  const initialRender = () => {
    phoneNumberFunction.getContactTypeData(setContactTypeData);
    involvementMainFunction.getCountry(setRaceDataData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div className="MultiplePhoneNumber">
        <DropdownComponent
          dataSource={ContactTypeData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          value={PhoneNumberval?.Type}
          handleRowClick={(e, dropdownValue) => {
            onChangeValue(
              "Type",
              e,
              PhoneNumberval.id,
              "TypeDropdownvalue",
              dropdownValue
            );
          }}
          validation={Participantionvalidation.AdditionalInfo.Phone}
          fieldName="Type"
        />
        <DropdownComponent
          dataSource={RaceDataData.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          value={PhoneNumberval?.Country}
          handleRowClick={(e) => {
            onChangeValue("Country", e, PhoneNumberval.id);
          }}
          fieldName="Country"
        />

        <MaskedInputComponent
          mask="+1 (999)-999-9999"
          value={PhoneNumberval?.Number}
          fieldName="Number"
          validation={Participantionvalidation.AdditionalInfo.Phone}
          onChangeValue={(e) => {
            onChangeValue("Number", e, PhoneNumberval.id);
          }}
        />
        <TextBoxComponent
          value={PhoneNumberval.Ext}
          fieldName="Ext"
          onChangeValue={(e) => {
            onChangeValue("Ext", e, PhoneNumberval.id);
          }}
        />
        {Employmentval.PhoneNumber.length > 1 && (
          <p
            className="removeIcon"
            onClick={() => {
              remove(PhoneNumberval.id);
            }}
            title="Remove"
          >
            -
          </p>
        )}
      </div>
    </>
  );
};

export const PhoneNumberE: React.FC<EmployementPhoneIProps> = ({
  Mainfields,
  setMainfields,
  Employmentval,
  Participantionvalidation,
}) => {
  const handleClick = () => {
    setMainfields({
      ...Mainfields,
      AdditionalInfo: {
        ...Mainfields.AdditionalInfo,
        changes: true,
        Employment: Mainfields.AdditionalInfo.Employment.map((val) => {
          if (val.id == Employmentval.id) {
            return {
              ...val,
              PhoneNumber: [
                ...val.PhoneNumber,
                {
                  id: val.PhoneNumber.length + 1,
                  Type: "",
                  ExternalContactID: "",
                  Country: "",
                  Number: "",
                  Ext: "",
                  TypeDropdownvalue: "",
                },
              ],
            };
          } else {
            return val;
          }
        }),
      },
    });
  };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_text">Phone Number</p>
            {Employmentval.PhoneNumber.map((PhoneNumberval) => {
              return (
                <>
                  <PhoneNumberDataUI
                    Mainfields={Mainfields}
                    setMainfields={setMainfields}
                    PhoneNumberval={PhoneNumberval}
                    Employmentval={Employmentval}
                    Participantionvalidation={Participantionvalidation}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="edit-button_">
          <ButtonComponents
            name="Add Phone Number"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
