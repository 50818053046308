import React, { useEffect, useState } from "react";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
import { VehicleOverviewFunction } from "./VehicleOverviewFunction";
import {
  RouteParams,
  SetFormDataIState,
  VehicleInvolvement,
} from "./VehicleOverviewIState";
import { useNavigate, useParams } from "react-router-dom";
import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { VehicleListFunction } from "../VehicleListing/VehicleFunction";
import { setTableData } from "../VehicleListing/VechicleIState";
import { useDispatch } from "react-redux";
import { VehicleIParams } from "../AddEditVehicles/Vehicle/VehicalI";

const VehicleOverviewDesc = () => {
  const VehicleOverview = new VehicleOverviewFunction();
  const VehicleFunction = new VehicleListFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [incidenId, setIncidentId] = useState<string>("");
  const { vehicleId } = useParams<RouteParams>();
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [deleteVehicleId, setDeleteVehicleId] = useState<string | null>(null);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [tableData, setTableData] = useState<setTableData[]>([]);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("License Plate");
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");
  const navigation = useNavigate();
  const [formData, setFormData] = useState<SetFormDataIState>({
    VehicleId: "",
    IncidentId: "",
    NcicModel: "",
    License: "",
    State: "",
    Style: "",
    NcicmodelId: "",
    NcicstateId: "",
    CountryName: "",
    Grid: "",
    ZipCode: "",
    CitationId: "",
    Ncictype: "",
    Ncicmake: "",
    TabYear: null,
    NciccolorPrimary: "",
    NciccolorSecondary: "",
    Vin: "",
    InsuranceAgencyCodeId: "",
    VehicleCondition: 0,
    GrossWeight: 0,
    RegisteredAddressId: null,
    PlateNumber: "",
    AdditionalDetails: "",
    OutOfCityRecovery: false,
    OutOfCityRecoveryCityState: "",
    PropertyOffenseId: "",
    NcicpropertyCategoryId: "",
    NcicpropertyCodeId: "",
    NcicvehicleInvolvement: "",
    CommercialVehicleIndicator: false,
    HazardousMaterialsIndicator: false,
    HazardousMaterial: "",
    IsParked: false,
    NoPlateIndicator: false,
    _21dayTempIndicator: false,
    CommercialDriverLicenseIndicator: false,
    VehicleOverWeightMeasure: false,
    PropertyId: "",
    ParticipantId: null,
    VehicleRegistrationId: "",
    NcicauthorityId: "",
    NciccountryId: "",
    Year: 0,
    Status: "",
    Restrictions: "",
    Ncicclass: "",
    ValidDates: "",
    RecovkedPlates: false,
    TabId: "",
    IsLien: false,
    LienHolder: "",
    LastName: "",
    FirstName: "",
    MiddleName: "",
    BusinessName: "",
    Suffix: "",
    DOB: "",
    IsPrimary: false,
  });
  const dispatch = useDispatch();
  const { incidentId } = useParams<VehicleIParams>();
  useEffect(() => {
    VehicleOverview.getVehicleoverviewData(
      setLoading,
      incidenId,
      vehicleId,
      setFormData
    );

    if (vehicleId) {
      VehicleOverview.getVehicleoverviewData(
        setLoading,
        incidenId,
        vehicleId,
        setFormData
      );
    }
  }, []);

  const closeDeletePopup = () => {
    setdeletePopup(false);
    setDeleteVehicleId(null);
  };
  const getVehicleTableData = () => {
    VehicleFunction.getVehicleListTableData(
      setTableData,
      pageNumber,
      pageCount,
      setLoading,
      setTableTotalRecord,
      Name,
      Type,
      OrderByDescending,
      OrderBy
    );
  };
  const handleDelete = () => {
    if (deleteVehicleId && incidentId) {
      VehicleFunction.deleteVehicle(
        deleteVehicleId,
        incidentId,
        setLoading,
        getVehicleTableData
      );
      closeDeletePopup();
      getVehicleTableData()
      navigation(`/incidents/${incidentId}/vehicles`);
    }
  };
  const openDeletePopup = (vehicleId: string) => {
    setDeleteVehicleId(vehicleId);
    setdeletePopup(true);
  };

  const NavigateToEditPage = () => {
    navigation(`/incidents/${incidentId}/vehicles/${vehicleId}/edit`);
  };
  return (
    <>
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="_vehicle_overview_description_container">
        <div className="_vehicle_overview_upper_title">
          <div className="_vehicle_overview_upper_left_container">
            <p>Description</p>
          </div>

          <div className="_vehicle_overview_upper_right_container">
            <div className="_under_score_btn">
              <p
                className="DetailTab_top_header_edit"
                onClick={NavigateToEditPage}
              >
                Edit
              </p>
            </div>

            <div className="_under_score_btn">
              <p
                className="DetailTab_top_header_edit"
                onClick={() => openDeletePopup(formData.VehicleId)}
              >
                Delete
              </p>
            </div>
          </div>
        </div>
        <div className="_horizontal_line"></div>

        <div className="_vehicle_desc_detail_overview_container">
          <div className="_vehicle_desc_detail_overview_upper">
            <div>
              <p className="crime_summary_title">License plate #</p>
              <p>{formData?.PlateNumber}</p>
            </div>

            <div>
              <p className="crime_summary_title">State</p>
              <p>{formData?.State}</p>
            </div>

            <div>
              <p className="crime_summary_title">VIN</p>
              <p>{formData?.Vin}</p>
            </div>
          </div>

          <div className="_vehicle_desc_detail_overview_upper">
            <div>
              <p className="crime_summary_title">Year</p>
              <p>{formData?.Year}</p>
            </div>

            <div>
              <p className="crime_summary_title">Make</p>
              <p>{formData?.MakerName}</p>
            </div>

            <div>
              <p className="crime_summary_title">Model</p>
              <p>{formData?.ModelName}</p>
            </div>

            <div>
              <p className="crime_summary_title">Type</p>
              <p>{formData?.Ncictype}</p>
            </div>

            <div className="primary_color_container">
              <div className="vehicle_color_meta_heading">
                <p className="crime_summary_title">Primary color</p>
                <p>{formData?.NciccolorPrimary}</p>
              </div>
              <div
                className="color_container"
                style={{ backgroundColor: "black", marginLeft: "18px" }}
              ></div>
            </div>

            <div className="primary_color_container">
              <div className="vehicle_color_meta_heading">
                <p className="crime_summary_title">Secondary color</p>
                <p>{formData?.NciccolorSecondary}</p>
              </div>
              <div
                className="color_container"
                style={{ backgroundColor: "red", marginLeft: "10px" }}
              ></div>
            </div>
          </div>

          <div className="_vehicle_desc_detail_overview_upper">
            <div>
              {/* <p className="crime_summary_title">Mileage</p>
                            <p>{formData?.State}</p> */}
            </div>

            <div>
              {/* <p className="crime_summary_title">Condition</p>
                            <p>{formData?.VehicleCondition}</p> */}
            </div>
          </div>
        </div>

        <div className="_minor_line_break"></div>

        <div className="vehicle_info_container">
          <div className="_vehicle_info_meta_data">
            <p className="crime_summary_title">Commercial</p>
            <p>{formData?.CommercialVehicleIndicator ? "Yes" : "No"}</p>
          </div>

          <div className="_vehicle_info_meta_data">
            <p className="crime_summary_title">Hazardous Materials</p>
            <p>{formData?.HazardousMaterial}</p>
          </div>

          <div className="_vehicle_info_meta_data">
            <p className="crime_summary_title">Gross Weight</p>
            <p>{formData?.GrossWeight}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleOverviewDesc;
