import React, { useEffect, useState } from "react";
import { OverviewMainFunction } from "./OverviewMainFunction";
import { MasterMainIParams, OverviewData } from "../../MasterNameIState";
import { useParams } from "react-router-dom";

export const OverviewTab: React.FC = () => {
  const overviewMainfunction = new OverviewMainFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<OverviewData>({
    Address: "",
    Age: "",
    DOB: "",
    PersonSurName: "",
    PersonGivenName: "",
    PersonMiddleName: "",
    EyeColor: "",
    FacialHair: "",
    Glasses: "",
    Height: "",
    Race: "",
    Sex: "",
    Complexion: "",
    Skintone: "",
    Weight: "",
    Scars: "",
    Tatoos: "",
  });
  const { nameId } = useParams<MasterMainIParams>();

  const initialRender = () => {
    if (nameId) {
      overviewMainfunction.getOverviewData(
        setLoading,
        setFields,
        fields,
        nameId
      );
    }
  };
  useEffect(() => {
    initialRender();
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Overview</strong>
          </p>
        </div>

        <div
          className="overview-content"
          style={{ height: "auto", lineHeight: "15px" }}
        >
          <div className="overview-row">
            <p>
              Last Name: <strong>{fields?.PersonGivenName}</strong>
            </p>
            <p>
              First Name: <strong>{fields?.PersonGivenName}</strong>
            </p>
            <p>
              Middle Name: <strong>{fields?.PersonMiddleName}</strong>
            </p>
            <p>
              DOB: <strong>{fields?.DOB ? formatDate(fields.DOB) : ""}</strong>
            </p>

            <p>
              Age: <strong>{fields?.Age}</strong>
            </p>
          </div>
          <div style={{ lineHeight: "1px" }}>
            <p>Last Entered Demographics:</p>
            <div className="overview-row">
              <p>
                Sex: <strong>{fields?.Sex}</strong>
              </p>
              <p>
                Race: <strong>{fields?.Race}</strong>
              </p>
              <p>
                Eye: <strong>{fields?.EyeColor}</strong>
              </p>
              <p>
                Hair: <strong></strong>
              </p>
              <p>
                Facial Hair: <strong>{fields?.FacialHair}</strong>
              </p>

              <p>
                Glasses: <strong>{fields?.Glasses}</strong>
              </p>

              <p>
                Skintone: <strong>{fields?.Complexion}</strong>
              </p>

              <p>
                HGT: <strong>{fields?.Height}</strong>
              </p>

              <p>
                WGT: <strong>{fields?.Weight}</strong>
              </p>
            </div>
          </div>
          <p className="overview-row">
            Last Entered Address:<strong>{fields?.Address}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};
