import { Fetcher } from "../../../../../../services/Fetcher";
import {
  convertToISOFormat,
  formatDate,
} from "../../../../../../utils/Helper/Helper";
import { GetRaceDataIState } from "../../../../Incidents/Participant/AddEditParticipantName/AddBusinessName/InvolvementMainI";
import {
  DetailedData,
  DetailedSearchData,
  getMasterNameIRes,
  setDetailedSearch,
  SetTableData,
} from "../../MasterNameIndexListingI";

const fetcher = new Fetcher();

export class DetailedSearchFunction {
  async getAdvanceSearch(
    setLoading: (data: boolean) => void,
    formData: DetailedSearchData,
    setTableData: (data: SetTableData[]) => void,
    setTableTotalRecord: (data: number) => void,
    PageNumber: number,
    pageCount: number,
    OrderByDescending: boolean,
    OrderBy: string
  ) {
    setLoading(true);
    formData = {
      PersonSurName: formData.PersonSurName ? formData.PersonSurName : null,
      PersonGivenName: formData.PersonGivenName
        ? formData.PersonGivenName
        : null,
      PersonMiddleName: formData.PersonMiddleName
        ? formData.PersonMiddleName
        : null,
      SuffixCodeId: formData.SuffixCodeId ? formData.SuffixCodeId : null,
      DOB: formData.DOB ? convertToISOFormat(formData.DOB) : null,
      Age: formData.Age ? formData.Age : null,
      RaceCodeId: formData.RaceCodeId ? formData.RaceCodeId : null,
      Gender: formData.Gender ? formData.Gender : null,
      EntityType: formData.EntityType ? formData.EntityType : "All",
      AddressType: formData.AddressType ? formData.AddressType : null,
      StreetPrefix: formData.StreetPrefix ? formData.StreetPrefix : null,
      Street: formData.Street ? formData.Street : null,
      StreetSuffix: formData.StreetSuffix ? formData.StreetSuffix : null,
      Apartment: formData.Apartment ? formData.Apartment : null,
      City: formData.City ? formData.City : null,
      StateProvidence: formData.StateProvidence
        ? formData.StateProvidence
        : null,
      ZipCode: formData.ZipCode ? formData.ZipCode : null,
      Country: formData.Country ? formData.Country : null,
      StreetNumber: formData.StreetNumber ? formData.StreetNumber : null,
      Grid: formData.Grid ? formData.Grid : null,
      PhoneTypeCodeId: formData.PhoneTypeCodeId
        ? formData.PhoneTypeCodeId
        : null,
      CountryCodeId: formData.CountryCodeId ? formData.CountryCodeId : null,
      PhoneNumber: formData.PhoneNumber ? formData.PhoneNumber : null,
      Ext: formData.Ext ? formData.Ext : null,
      Height: formData?.Height ? formData?.Height : null,
      Weight: formData?.Weight ? formData?.Height : null,
      NoPermanentAddress: formData?.NoPermanentAddress,
      UnknownAddress: formData?.UnknownAddress,
      ForeignAddress: formData?.ForeignAddress,
      // Address : formData.Address
    };

    try {
      const response = await fetcher.post(
        "/name-master/search",
        formData
        // {
        //   PageNumber: PageNumber,
        //   PageSize: pageCount,
        //   ...(OrderByDescending != null ? { OrderByDescending } : {}),
        // }
      );
      const TableJson = this.createTableJson(response?.Data);
      if (response) {
        // Handle the response data
        console.log(response);
      }
      setTableData(TableJson);
    } catch (error) {
      console.error("Error fetching category table data:", error);
    }

    setLoading(false);
  }

  createTableJson(data: getMasterNameIRes[]) {
    return data?.map((val: getMasterNameIRes, i: number) => {
      console.log(val);
      return {
        "First Name": val.PersonGivenName,
        "Last Name/Business Name":
          val.PersonSurName +
          (val.BusinessType ? ` (${val.BusinessType})` : ""),
        "Middle Name": val.PersonMiddleName,
        DOB: val.DOB ? formatDate(val.DOB) : "",
        Age: val.Age,
        Address: val.StreetAddress,
        City: val.City,
        State: val.State,
        nameId: val.NameId,
        EntityType: val.EntityType,
      };
    });
  }

  async getAddresType(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/LocationType");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getStreetPrefix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/StreetPreDirection"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getStreetSuffix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/StreetPostDirection"
    );
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getState(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/StateCode");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getCountry(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/Country");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getNameSuffix(
    setSuffixData: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/ncic/category/NameSuffix");
    let res: GetRaceDataIState[] = response.Data;
    setSuffixData(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  onchangeValide(
    formData: DetailedSearchData,
    setonchange: (data: boolean) => void
  ) {
    if (
      !formData.AddressType &&
      !formData.Age &&
      !formData.Apartment &&
      !formData.City &&
      !formData.DOB &&
      !formData.Ext &&
      !formData.Gender &&
      !formData.Grid &&
      !formData.PersonGivenName?.trim() &&
      !formData.PersonMiddleName?.trim() &&
      !formData.PersonSurName?.trim() &&
      !formData.PhoneNumber &&
      !formData.PhoneTypeCodeId &&
      !formData.ZipCode &&
      !formData.SuffixCodeId &&
      !formData.StreetSuffix &&
      !formData.StreetPrefix &&
      !formData.StreetNumber
    ) {
      setonchange(false);
    }
  }
}
