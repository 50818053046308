import { useState } from "react";
import LocationMetaData from "../Location/LocationMetaData";
import MediaTableData from "./MediaTableData";
import "./media.css";
import { Table } from "../../../CommonComponents/Table/Table";
import { MediaTableDataI } from "./MediaOverViewStateI";
import MediaFilter from "./MediaFilter";
import { coloum } from "./config";
import Breadcrumbs from "../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import MediaReader from "./MediaReader";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
export const Media: React.FC = () => {
  const [width, setwidth] = useState<number>(10);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [tableData, setTableData] = useState<MediaTableDataI[]>([
    {
      Preview: "view",
      Type: "Video",
      Name: "First Street",
      Description: "Lorem Ipsum dolor sit amet",
      Date: "01/23/2024",
      Time: "17:31:04",
    },
    {
      Preview: "view",
      Type: "Audio",
      Name: "Second Street",
      Description: "Lorem Ipsum dolor sit amet",
      Date: "01/23/2024",
      Time: "17:31:04",
    },
  ]);
  
  const { incidentId } = useParams<InvolvementMainIParams>();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [mediaType, setMediaType] = useState<string>("");
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <ModalComponent
        open={showModal}
        innerContant={<MediaReader mediaType={mediaType} />}
        onClose={handleClose}
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="_incident_location_container_fluid">
        <div className="_location_left_container_">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>
        <div className="UserManagement _location_right_container">
       
          <div className="_user_management_container">
             <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path:  `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              { title: "Media", path: `/incidents/${incidentId}/participants`},
            ]}
          />
            <p className="header"> Media {""} 1237282</p>
            <MediaFilter />
            <div className="table">
              <Table
                columns={coloum(setShowModal, setMediaType)}
                columnsValue={tableData}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
