import React, { useState } from 'react'
import LocationMetaData from '../../Location/LocationMetaData'
import VehicleOverviewForm from './VehicleOverviewForm'

const VehicleOverview = () => {
    const [width, setwidth] = useState<number>(10);
    return (
        <>
         <div className="_incident_location_container_fluid">
                <div className="_location_left_container">
                <LocationMetaData 
           width={width}
           setWidth={setwidth}
          />
                </div>
              <div className="_location_right_container">
               <VehicleOverviewForm/>
              </div>
            </div>
        </>
    )
}

export default VehicleOverview