import { Fetcher } from "../../../../services/Fetcher";
import {
  codecategory,
  CodeCategoryIRes,
  getallcodecategory,
  getCategoryTableDataI,
  SetTableData,
} from "./NCICII";

const fetcher = new Fetcher();

export class NCICFunction {
  async getNCICData(
    setTableData: (data: SetTableData[]) => void,
    pageNumber: number,
    pageCount: number,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    LevelCode: string,
    Category: string
  ) {
    setLoading(true);

    const isSearch = !!(LevelCode || Category);
    let response = await fetcher.post(`/code/ncic/search`, {
      PageNumber: isSearch ? 1 : pageNumber,
      PageSize: pageCount,
      LevelCode: LevelCode ? LevelCode : null,
      CategoryID: Category ? Category : null,
    });
    const TableJson = this.createTableJson(response?.Data);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: getCategoryTableDataI[]): SetTableData[] {
    return data?.map((val: getCategoryTableDataI) => {
      return {
        "Short Code": val.ShortCode,
        "Long Code": val.LongCode,
        Category: val.CategoryName,
        Description: val.Description,
        Status: val.IsActive, // keep it as boolean
      };
    });
  }

  async getallcodecategory(
    setagencyData: (data: codecategory[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/category/ncic");
    setagencyData(
      response.Data?.map((val: CodeCategoryIRes) => {
        return {
          CodeCategoryId: val.CodeCategoryId,
          CategoryName: val.CategoryName,
        };
      })
    );

    if (setLoading) {
      setLoading(false);
    }
  }
}
