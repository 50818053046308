import React, { useEffect, useState } from "react";
import close from "../../../../../../../../assets/icons/close-circle.svg";
import { TextBoxComponent } from "../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { FilterIProps } from "./FilterIProps";
import { ButtonIState } from "../../../../../Permission/Components/Filter/FilterIProps";

export const Filter: React.FC<FilterIProps> = ({
  Name,
  Status,
  setName,
  setStatus,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  useEffect(() => {
    if ((Name && Name.trim().length > 0) || Status !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, Status]);

  const clearAllFilters = () => {
    setName("");
    setStatus("");
    setIsAddButtonDisabled(true);
      setButtonTextColor("black");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds">
          <TextBoxComponent
            fieldName={"Name"}
            value={Name}
            onChangeValue={setName}
          />
          <DropdownComponent
            fieldName={"Status"}
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            handleRowClick={setStatus}
            value={Status}
            showTooltip={false}
          />
        </div>
        <div className="clearbutton">
          <div className="clearbutton-sub">
          <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
