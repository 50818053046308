import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";

interface initialStateI {
  editUserComponent: boolean;
  addUserComponent: boolean;
  viewUserComponent: boolean;
  fullname: string;
  HomeUnit: string;
  Email: string;
  Status: string;
  images: string;
  ModifiedByUserName:string;
  ModifiedOn:string;
}
interface setUsereditComponentPayload {
  editUserComponent: boolean;
  addUserComponent: boolean;
  viewUserComponent: boolean;
}

export interface setUserdataPayload {
  fullname: string;
  HomeUnit: string;
  Email: string;
  Status: string;
  images: string;
  ModifiedByUserName:string;
  ModifiedOn:string;
}

const initialState: initialStateI = {
  editUserComponent: false,
  addUserComponent: true,
  viewUserComponent: false,
  images: "",
  fullname: "",
  HomeUnit: "",
  Email: "",
  Status: "",
  ModifiedByUserName:"",
  ModifiedOn:"",
};

//slice

const slice = createSlice({
  name: "UserReducer",
  initialState,
  reducers: {
    setUsereditComponent(
      state,
      action: PayloadAction<setUsereditComponentPayload>
    ) {
      state.editUserComponent = action.payload.editUserComponent;
      state.addUserComponent = action.payload.addUserComponent;
      state.viewUserComponent = action.payload.viewUserComponent;
    },

    setUserdata(state, action: PayloadAction<setUserdataPayload>) {
      state.fullname = action.payload.fullname;
      state.HomeUnit = action.payload.HomeUnit;
      state.Email = action.payload.Email;
      state.Status = action.payload.Status;
      state.images = action.payload.images;
      state.ModifiedByUserName=action.payload.ModifiedByUserName;
      state.ModifiedOn=action.payload.ModifiedOn;
    },
  },
});

export const { setUsereditComponent, setUserdata } = slice.actions;

export default slice.reducer;
