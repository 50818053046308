import Breadcrumbs from "../../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { AddEditParticipantName } from "../AddEditParticipantName/AddEditParticipantName";

export const AddParticipant = () => {
  return (
    <>
      <AddEditParticipantName />
    </>
  );
};
