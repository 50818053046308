import React, { useEffect, useRef, useState } from "react";
import LocationFilter from "./LocationFilter";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Table } from "../../../CommonComponents/Table/Table";
import { coloum } from "./config";
import { LocationMainFunction } from "./LocationMainFunction";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useGetPerticularUserPermission } from "src/hooks/Permission/useGetPerticularUserPermission";

interface locationDataValue {
  Primary: string;
  Address: string;
}
const LocationTableData: React.FC = () => {
  const { Delete, Update } =
    useGetPerticularUserPermission("Incident.Location");
  const navigate = useNavigate();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const locationData = new LocationMainFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [tableData, setTableData] = useState<Array<locationDataValue>>([]);
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [deleteLocationId, setDeleteLocationId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [isClearFilter, setIsClearFilter] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Address");
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(true);
  const getLocationList = async () => {
    if (incidentId) {
      await locationData?.locationList(
        incidentId,
        setTableData,
        setLoading,
        searchText,
        setTableTotalRecord,
        pageNumber,
        pageCount,
        OrderBy,
        OrderByDescending
      );
    }
  };

  const closeDeletePopup = () => {
    setDeletePopUp(false);
    setDeleteLocationId(null);
  };

  const handleDelete = async () => {
    let res;
    if (deleteLocationId !== null) {
      res = await locationData?.deleteLocationList(
        deleteLocationId,
        setLoading
      );
    }
    closeDeletePopup();
    if (res?.Success) {
      getLocationList();
    }
  };
  useEffect(() => {
    getLocationList();
  }, [isClearFilter, pageCount, pageNumber, OrderByDescending]);

  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={closeDeletePopup}
        open={deletePopUp}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="_location_TableData_container">
        <LocationFilter
          searchText={searchText}
          setSearchText={setSearchText}
          getLocationList={getLocationList}
          setIsClearFilter={setIsClearFilter}
          isClearFilter={isClearFilter}
        />
        <div className="_table_data">
          <Table
            columns={coloum(
              setDeletePopUp,
              setDeleteLocationId,
              navigate,
              incidentId,
              Delete,
              Update
            )}
            cursorPointer={true}
            columnsValue={tableData}
            setpageCount={setpageCount}
            setpageNumber={setpageNumber}
            tableTotalRecord={tableTotalRecord}
            OrderByDescending={OrderByDescending}
            setOrderByDescending={setOrderByDescending}
            setOrderBy={setOrderBy}
            OrderBy={OrderBy}
          />
        </div>
      </div>
    </>
  );
};

export default LocationTableData;
