import { useEffect, useState } from "react";
import { Loader } from "../../../../../../../../../CommonComponents/Loader/Loader";
import { Submit } from "../../../../../../../../../CommonComponents/Fields/Submit/Submit";
import { Checkbox, Radio, Divider } from "antd";
import { CheckBoxComponent } from "../../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { RadioComponent } from "../../../../../../../../../CommonComponents/Fields/Radio/RadioComponent";
import { Fetcher } from "../../../../../../../../../../services/Fetcher";
import { ViewMatchesOverviewFunction } from "./ViewMatchesOverviewFunction";
import {
  //AdditionalInfoData,
  AddressDataOverview,
  AddressIProps,
  Alias,
  AliassIProps,
  Bias,
  CombinedData,
  MatchesParticipant,
  namemasterRes,
  PersonDetails,
  PersonIProps,
  ViewMatchesOverviewIProps,
  RaceIProps,
  EyeIProps,
  HeightIProps,
  BuildIProps,
  GlassesIProps,
  SkinToneIProps,
  HandnessIProps,
  FacialHairIProps,
  PhysicalIProps,
  PhysicalFeatureI,
  IdentificationIProps,
  IncidentDetails,
  ContactIProps,
  ContactI,
  EmergencyContactIRes,
  EmergencyIProps,
  CombinedPersonDetails,
  //NewAddIRes,
  USCitizenIProps,
  NewAddI,
  USAdditional,
  LanguageIProps,
  DisabilityIProps,
  MedicalIProps,
  FingerPrintsIProps,
  SchoolIProps,
  EmployerIProps,
  MaritalStatusIProps,
} from "./ViewMatchesOverviewI";
import {
  AdditionalData,
  AddressData,
  ContactData,
  EmergencyData,
} from "../../../../../../../../MasterNameIndex/MasterOverview/MasterNameIState";
import { InvolvementMainIParams } from "../../../InvolvementMainI";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../../../../../../store";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../../../../../../../utils/Helper/Helper";
import { EmployementAddress } from "../../../../AdditionalInfo/Components/Employeement/Components/EmployementAddress/Address";

const Address: React.FC<AddressIProps> = ({
  address,
  index,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<boolean>(false);
  return (
    <>
      <div>
        <CheckBoxComponent
          fieldName={`${address.HouseNumber}, ${address.Street1Name}, ${address.City}, ${address.StateCode}, ${address.ZipCode},(04/10/2023)`}
          onChangeValue={() => {
            console.log(address.LocationID);
            setCombinedDataArray([
              ...combinedDataArray,
              {
                EntityId: address.LocationID,
                EntityType: "Address",
                MatchesParticipantId: address.ParticipantID,
              },
            ]);
            setTemp(!temp);
          }}
          value={temp}
        />
      </div>
    </>
  );
};

const Identification: React.FC<IdentificationIProps> = ({
  identification,
  index,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<boolean>(false);
  return (
    <>
      <div>
        <CheckBoxComponent
          fieldName={`${identification.IDentificationCode}, ${identification.PrimaryIDentification},(04/10/2023)`}
          onChangeValue={() => {
            setCombinedDataArray([
              ...combinedDataArray,
              {
                EntityId: identification.PersonIDentificationID,
                EntityType: "Identification",
                MatchesParticipantId: identification.ParticipantID,
              },
            ]);
            setTemp(!temp);
          }}
          value={temp}
        />
      </div>
    </>
  );
};

const PhysicalFeature: React.FC<PhysicalIProps> = ({
  physical,
  index,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<boolean>(false);
  return (
    <>
      <div>
        <CheckBoxComponent
          fieldName={`${physical?.PhysicalFeatureNciccode}, ${formatDate(
            physical?.CreatedOn
          )}`}
          onChangeValue={() => {
            setCombinedDataArray([
              ...combinedDataArray,
              {
                EntityId: physical.PhysicalFeatureId,
                EntityType: "PhysicalCharacteristics",
                MatchesParticipantId: physical.ParticipantId,
              },
            ]);
            setTemp(!temp);
          }}
          value={temp}
        />
      </div>
    </>
  );
};

const Aliass: React.FC<AliassIProps> = ({
  alias,
  index,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<boolean>(false);
  return (
    <>
      <div>
        <CheckBoxComponent
          fieldName={`${alias.FirstName}, ${alias.MiddleName}, ${
            alias.LastName
          },${formatDate(alias.CreatedOn)}`}
          onChangeValue={() => {
            setCombinedDataArray([
              ...combinedDataArray,
              {
                EntityId: alias.AliasNameId,
                EntityType: "Alias",
                MatchesParticipantId: alias.ParticipantID,
              },
            ]);
            setTemp(!temp);
          }}
          value={temp}
        />
      </div>
    </>
  );
};







// const Employer: React.FC<EmployerIProps> = ({
//   employer,
//   index,
//   setCombinedDataArray,
//   combinedDataArray,
// }) => {
//   const [temp, setTemp] = useState<boolean>(false);
//   return (
//     <>
//       <div>
//         <CheckBoxComponent
//           fieldName={`${employer.map()}, ${alias.MiddleName}, ${
//             alias.LastName
//           },${formatDate(alias.CreatedOn)}`}
//           onChangeValue={() => {
//             setCombinedDataArray([
//               ...combinedDataArray,
//               {
//                 EntityId: alias.AliasNameId,
//                 EntityType: "Alias",
//                 MatchesParticipantId: alias.ParticipantID,
//               },
//             ]);
//             setTemp(!temp);
//           }}
//           value={temp}
//         />
//       </div>
//     </>
//   );
// };

const Contact: React.FC<ContactIProps> = ({
  contact,
  index,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<boolean>(false);
  return (
    <>
      <div>
        <CheckBoxComponent
          fieldName={`${contact.ContactTypeName}, ${contact.ContactValue}`}
          onChangeValue={() => {
            setCombinedDataArray([
              ...combinedDataArray,
              {
                EntityId: contact.ContactID,
                EntityType: "Contact",
                MatchesParticipantId: contact.ParticipantID,
              },
            ]);
            setTemp(!temp);
          }}
          value={temp}
        />
      </div>
    </>
  );
};

const Emergency: React.FC<EmergencyIProps> = ({
  emergency,
  index,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<boolean>(false);

  const fieldName = emergency.EmergencyContact.map((val2) => {
    const addresses = val2.Address.Addresses.map(
      (addr) => `${addr.StateName} ${addr.City} ${addr.ZipCode}`
    ).join(" ");

    const contacts = val2.Contact.Contacts.map(
      (contact) =>
        `${contact.ContactValue} ${
          contact.ContactTypeName ? contact.ContactTypeName : ""
        }`
    ).join(" ");

    return `${val2.RelationName} ${val2.FirstName} ${val2.LastName} ${addresses} ${contacts},(04/10/2023)`;
  }).join(" ");
  return (
    <>
      <div>
        <CheckBoxComponent
          fieldName={fieldName}
          onChangeValue={() => {
            setCombinedDataArray([
              ...combinedDataArray,
              {
                EntityId: emergency.ParticipantId,
                EntityType: "Emergency",
                MatchesParticipantId: emergency.ParticipantId,
              },
            ]);
            setTemp(!temp);
          }}
          value={temp}
        />
      </div>
    </>
  );
};

const Demographics: React.FC<PersonIProps> = ({
  sex,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...sex.map((val) => {
              return val.Sex;
            }),
            "None",
          ]}
            onChangeValue={(e) => {
              if (e== 
                [
                  ...sex.map((val) => {
                  return val.Sex;
                }),
                "None",
              ].length

              ) {

              } else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: sex[e - 1].PersonID,
                    EntityType: "Sex",
                    MatchesParticipantId: sex[e - 1].ParticipantID,
                  },
                ]);
              }
                setTemp(e);
              }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Race: React.FC<RaceIProps> = ({
  race,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...race
              .map((val) => {
                if (val?.Race) {
                  return val?.Race;
                } else {
                  return "";
                }
              })
              .filter((val) => val),
              "None",
            ]}
            onChangeValue={(e) => {
              if (e== [
                ...race
                .map((val) => {
                  if (val?.Race) {
                    return val?.Race;
                  } else {
                    return "";
                  }
                })
                .filter((val) => val),
                "None",
              ].length

              ) {

              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: race[e - 1].PersonID,
                    EntityType: "Race",
                    MatchesParticipantId: race[e - 1].ParticipantID,
                  },
                ]);
              }
             
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const SkinTone: React.FC<SkinToneIProps> = ({
  skin,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...skin
              .map((val) => {
                if (val?.Complexion) {
                  return val?.Complexion;
                } else {
                  return "";
                }
              })
              .filter((val) => val),
              "None",
            ]}

            onChangeValue={(e) => {
              if (e== [
                ...skin
                .map((val) => {
                  if (val?.Complexion) {
                    return val?.Complexion;
                  } else {
                    return "";
                  }
                })
                .filter((val) => val),
                "None",
              ].length ) {

              } else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: skin[e - 1].PersonID,
                    EntityType: "Complexion",
                    MatchesParticipantId: skin[e - 1].ParticipantID,
                  },
                ]);
              } 
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Glasses: React.FC<GlassesIProps> = ({
  glasses,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...glasses
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.Glasses ? "Yes" : "No"}` ===
                      `${item.Glasses ? "Yes" : "No"}`
                  )
              )
              .map((val) => {
                return val.Glasses ? "Yes" : "No";
              }),
              "None",
            ]
            }
            onChangeValue={(e) => {
              if (e== [
                ...glasses
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.Glasses ? "Yes" : "No"}` ===
                        `${item.Glasses ? "Yes" : "No"}`
                    )
                )
                .map((val) => {
                  return val.Glasses ? "Yes" : "No";
                }),
                "None",
              ].length) {

              }

               else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: glasses[e - 1].PersonID,
                    EntityType: "Glasses",
                    MatchesParticipantId: glasses[e - 1].ParticipantID,
                  },
                ]);
               
              }
             
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Eye: React.FC<EyeIProps> = ({
  eye,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...eye
              .map((val) => {
                //return val?.EyeColor
                if (val?.EyeColor) {
                  return val?.EyeColor;
                } else {
                  return "";
                }
              })
              .filter((val) => val),
            "None",
            ]}
            onChangeValue={(e) => {
              if(e== [
                ...eye
                .map((val) => {
                  //return val?.EyeColor
                  if (val?.EyeColor) {
                    return val?.EyeColor;
                  } else {
                    return "";
                  }
                })
                .filter((val) => val),
              "None",
              ].length ) {

              } 
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: eye[e - 1].PersonID,
                    EntityType: "EyeColour",
                    MatchesParticipantId: eye[e - 1].ParticipantID,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Height: React.FC<HeightIProps> = ({
  height,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...height
              .map((val) => {
                //return val?.Height
                if (val?.Height) {
                  return val?.Height;
                } else {
                  return "";
                }
              })
              .filter((val) => val),
              "None",
            ]}
            onChangeValue={(e) => {
              if(e== [
                ...height
                .map((val) => {
                  //return val?.Height
                  if (val?.Height) {
                    return val?.Height;
                  } else {
                    return "";
                  }
                })
                .filter((val) => val),
                "None",
              ].length ) {

              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: height[e - 1].PersonID,
                    EntityType: "Height",
                    MatchesParticipantId: height[e - 1].ParticipantID,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Build: React.FC<BuildIProps> = ({
  build,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...build
              .map((val) => {
                if (val?.Build) {
                  return val?.Build;
                } else {
                  return "";
                }
              })
              .filter((val) => val),
              "None",
            ]}
            onChangeValue={(e) => {
             if(e== [
              ...build
              .map((val) => {
                if (val?.Build) {
                  return val?.Build;
                } else {
                  return "";
                }
              })
              .filter((val) => val),
              "None",
            ].length) {

             }
             else {
              setCombinedDataArray([
                ...combinedDataArray,
                {
                  EntityId: build[e - 1].PersonID,
                  EntityType: "Build",
                  MatchesParticipantId: build[e - 1].ParticipantID,
                },
              ]);
             }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const FacialHair: React.FC<FacialHairIProps> = ({
  hair,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...hair
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.FacialHair ? "Yes" : "No"}` ===
                      `${item.FacialHair ? "Yes" : "No"}`
                  )
              )
              .map((val) => {
                return val.FacialHair ? "Yes" : "No";
              }),
              "None",
            ]}
            onChangeValue={(e) => {
              if (e==[
                ...hair
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.FacialHair ? "Yes" : "No"}` ===
                        `${item.FacialHair ? "Yes" : "No"}`
                    )
                )
                .map((val) => {
                  return val.FacialHair ? "Yes" : "No";
                }),
                "None",
              ].length) {

              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: hair[e - 1].PersonID,
                    EntityType: "FacialHair",
                    MatchesParticipantId: hair[e - 1].ParticipantID,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Handness: React.FC<HandnessIProps> = ({
  handness,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...handness
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.IsHandedness ? "Yes" : "No"}` ===
                      `${item.IsHandedness ? "Yes" : "No"}`
                  )
              )
              .map((val) => {
                return val.IsHandedness ? "Yes" : "No";
              }),
            "None",
            ]}
            onChangeValue={(e) => {
              if(e== [
                ...handness
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.IsHandedness ? "Yes" : "No"}` ===
                        `${item.IsHandedness ? "Yes" : "No"}`
                    )
                )
                .map((val) => {
                  return val.IsHandedness ? "Yes" : "No";
                }),
              "None",
              ].length) {
              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: handness[e - 1].PersonID,
                    EntityType: "Handness",
                    MatchesParticipantId: handness[e - 1].ParticipantID,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const UScitizen: React.FC<USCitizenIProps> = ({
  uscitizen,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...uscitizen
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.IsUscitizen ? "Yes" : "No"}` ===
                      `${item.IsUscitizen ? "Yes" : "No"}`
                  )
              )
              .map((val) => {
                return val.IsUscitizen ? "Yes" : "No";
              }),
              "None",
            ]}
            onChangeValue={(e) => {
              if(e==[
                ...uscitizen
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.IsUscitizen ? "Yes" : "No"}` ===
                        `${item.IsUscitizen ? "Yes" : "No"}`
                    )
                )
                .map((val) => {
                  return val.IsUscitizen ? "Yes" : "No";
                }),
                "None",
              ].length) {

              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: uscitizen[e - 1].PersonId,
                    EntityType: "USCitizen",
                    MatchesParticipantId: uscitizen[e - 1].ParticipantId,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Marital: React.FC<MaritalStatusIProps> = ({
  marital,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...marital
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.NcicmaritialStatusCode}` ===
                      `${item.NcicmaritialStatusCode}`
                  )
              )
              .map((val) => {
                return val.NcicmaritialStatusCode;
              }),
            "None",
            ]}
            onChangeValue={(e) => {
              if(e==[
                ...marital
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.NcicmaritialStatusCode}` ===
                        `${item.NcicmaritialStatusCode}`
                    )
                )
                .map((val) => {
                  return val.NcicmaritialStatusCode;
                }),
              "None",
              ].length) {
                
              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: marital[e - 1].PersonId,
                    EntityType: "MaritalStatus",
                    MatchesParticipantId: marital[e - 1].ParticipantId,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Language: React.FC<LanguageIProps> = ({
  language,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...language
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.LimitedEnglishProficiency ? "Yes" : "No"}` ===
                        `${item.LimitedEnglishProficiency ? "Yes" : "No"}`
                    )
                )
                .map((val) => {
                  return val.LimitedEnglishProficiency ? "Yes" : "No";
                }),
              "None",
            ]}
            onChangeValue={(e) => {
              if (
                e ==
                [
                  ...language
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            `${t.LimitedEnglishProficiency ? "Yes" : "No"}` ===
                            `${item.LimitedEnglishProficiency ? "Yes" : "No"}`
                        )
                    )
                    .map((val) => {
                      return val.LimitedEnglishProficiency ? "Yes" : "No";
                    }),
                  "None",
                ].length
              ) {} else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: language[e - 1].PersonId,
                    EntityType: "Language",
                    MatchesParticipantId: language[e - 1].ParticipantId,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const Disability: React.FC<DisabilityIProps> = ({
  disability,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...disability
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.IsDisabled ? "Yes" : "No"}` ===
                      `${item.IsDisabled ? "Yes" : "No"}`
                  )
              )
              .map((val) => {
                return val.IsDisabled ? "Yes" : "No";
              }),
              "None",
            ]}
            onChangeValue={(e) => {
              if (
                e== [
                  ...disability
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.IsDisabled ? "Yes" : "No"}` ===
                      `${item.IsDisabled ? "Yes" : "No"}`
                  )
              )
              .map((val) => {
                return val.IsDisabled ? "Yes" : "No";
              }),
              "None",
            ].length
              ) {} else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: disability[e - 1].PersonId,
                    EntityType: "Disability",
                    MatchesParticipantId: disability[e - 1].ParticipantId,
                  },
                ]);
              }
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};



// const Medical : React.FC<MedicalIProps> = ({
//   medical,
//   setCombinedDataArray,
//   combinedDataArray,
// }) => {
//   const [temp, setTemp] = useState<number>(0);
//   return (
//     <>
//       <div>
//         <div className="demo">
//           <RadioComponent
//             RadioTextList={medical
//               .filter(
//                 (item, index, self) =>
//                   index ===
//                   self.findIndex(
//                     (t) =>
//                       `${t.IsUscitizen ? "Yes" : "No"}` ===
//                       `${item.IsUscitizen ? "Yes" : "No"}`
//                   )
//               )
//               .map((val) => {
//                 return val.IsUscitizen ? "Yes" : "No";
//               })}
//             onChangeValue={(e) => {
//               setCombinedDataArray([
//                 ...combinedDataArray,
//                 {
//                   EntityId: uscitizen[e - 1].PersonId,
//                   EntityType: "USCitizen",
//                   MatchesParticipantId: uscitizen[e - 1].ParticipantId,
//                 },
//               ]);
//               setTemp(e);
//             }}
//             value={temp}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

const Finger: React.FC<FingerPrintsIProps> = ({
  finger,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...finger
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.FingerprintIndivator ? "Yes" : "No"}` ===
                      `${item.FingerprintIndivator ? "Yes" : "No"}`
                  )
              )
              .map((val) => {
                return val.FingerprintIndivator ? "Yes" : "No";
              }),
              "None",
            ]}
            onChangeValue={(e) => {
              if(e==[
                ...finger
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.FingerprintIndivator ? "Yes" : "No"}` ===
                        `${item.FingerprintIndivator ? "Yes" : "No"}`
                    )
                )
                .map((val) => {
                  return val.FingerprintIndivator ? "Yes" : "No";
                }),
                "None",
              ].length) {

              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: finger[e - 1].PersonId,
                    EntityType: "FingerPrints",
                    MatchesParticipantId: finger[e - 1].ParticipantId,
                  },
                ]);  
              }
             setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const School: React.FC<SchoolIProps> = ({
  school,
  setCombinedDataArray,
  combinedDataArray,
}) => {
  const [temp, setTemp] = useState<number>(0);
  return (
    <>
      <div>
        <div className="demo">
          <RadioComponent
            RadioTextList={[
              ...school
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.SchoolGrade ? t.SchoolGrade : "None"}` ===
                      `${item.SchoolGrade ? item.SchoolGrade : "None"}`
                  )
              )
              .map((val) => {
                return val.SchoolGrade;
              }),
            "None",
            ]}
            onChangeValue={(e) => {
              if(e==[
                ...school
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.SchoolGrade ? t.SchoolGrade : "None"}` ===
                        `${item.SchoolGrade ? item.SchoolGrade : "None"}`
                    )
                )
                .map((val) => {
                  return val.SchoolGrade;
                }),
              "None",
              ].length) {

              }
              else {
                setCombinedDataArray([
                  ...combinedDataArray,
                  {
                    EntityId: school[e - 1].PersonId,
                    EntityType: "School",
                    MatchesParticipantId: school[e - 1].ParticipantId,
                  },
                ]);
              }
              
              setTemp(e);
            }}
            value={temp}
          />
        </div>
      </div>
    </>
  );
};

const fetcher = new Fetcher();

export const ViewMatchesOverview: React.FC<ViewMatchesOverviewIProps> = ({
  NameMasterID,
  Mainfields,
  setMainfields,
  closePopup,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { IncidentId } = useSelector((store: RootState) => store.CommonRedux);
  const viewMatchesOverview = new ViewMatchesOverviewFunction();
  const [Addressdata, setAddressdata] = useState<AddressDataOverview[]>([]);
  const [scarsdata, setscarsdata] = useState<PhysicalFeatureI[]>([]);
  const [personData, setpersonData] = useState<PersonDetails[]>([]);
  const [emergencyContact, setemergencyContact] = useState<
    EmergencyContactIRes[]
  >([]);
  const [identification, setidentification] = useState<IncidentDetails[]>([]);
  const [additional, setadditional] = useState<NewAddI[]>([]);
  const [selectedAddress, setSelectedAddress] =
    useState<AddressDataOverview | null>(null);
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [alias, setalias] = useState<Alias[]>([]);
  const [bias, setbias] = useState<Bias[]>([]);
  const [contacts, setcontacts] = useState<ContactI[]>([]);

  const [selectedPerson, setSelectedPerson] = useState<PersonDetails | null>(
    null
  );
  const Navigate = useNavigate();
  const [data, setdata] = useState<namemasterRes>({
    Address: "",
    Age: 0,
    Complexion: "",
    DOB: "",
    EyeColor: "",
    FacialHair: false,
    Glasses: false,
    HairColor: "",
    Height: 0,
    MaxAge: 0,

    MaxHeight: 0,
    MaxWeight: 0,
    MinAge: 0,
    MinHeight: 0,
    MinWeight: 0,
    NameID: "",
    PersonGivenName: "",
    PersonMiddleName: "",
    PersonNameSuffixText: 0,
    PersonSurName: "",
    PhysicalFeatureCode: "",
    PhysicalFeatureLocationCode: "",
    PhysicalFeatureText: "",
    Race: "",
    Sex: "",
    Weight: 0,
  });

  const [combinedDataArray, setCombinedDataArray] = useState<CombinedData[]>(
    []
  );

  const RedirectToPariticipantEdit = (participantId: string) => {
    Navigate(`/incidents/${IncidentId}/participants/${participantId}/edit`);
  };

  const onsave = () => {
    if (incidentId && NameMasterID) {
      viewMatchesOverview.importMaster(
        incidentId,
        NameMasterID,
        combinedDataArray,
        RedirectToPariticipantEdit
      );
    }

    closePopup();
  };

  
  const initialData = async () => {
    await viewMatchesOverview.getMaster(NameMasterID, setdata);
    await viewMatchesOverview.getAddressMain(setAddressdata, NameMasterID);
    await viewMatchesOverview.getDemographicsMain(setpersonData, NameMasterID);

    await viewMatchesOverview.getIdentification(
      setidentification,
      NameMasterID
    );

    await viewMatchesOverview.getAlias(setalias, NameMasterID);
    await viewMatchesOverview.getBias(setbias, NameMasterID);
    await viewMatchesOverview.getContactMain(setcontacts, NameMasterID);
    await viewMatchesOverview.getScarMarks(setscarsdata, NameMasterID);
    await viewMatchesOverview.getAdditional(setadditional, NameMasterID);
    await viewMatchesOverview.getEmergencyMain(
      setemergencyContact,
      NameMasterID
    );
  };

  console.log("emer", additional);

  useEffect(() => {
    if (NameMasterID) {
      initialData();
    }
  }, [NameMasterID]);

  return (
    <>
      <Loader loading={loading} />
      <div className="AddMasterName_main_">
        <div className="case_child">
          <div className="case_">
            <p className="header1_">
              Select Information to bring into Name Record{" "}
              {data.PersonGivenName}, 123-456-7890 1234 First street, St. Paul
              MN, 55104
            </p>
          </div>

          <div className="form-section">
            <div className="demo__" style={{ gap: "20%" }}>
              <div className="demographics">
                <p className="header">Name and DOB</p>
                <div></div>
                <p>
                  {" "}
                  {data?.PersonGivenName} {data?.PersonMiddleName} , DOB{" "}
                  {formatDate(data?.DOB)} (04/10/2023)
                </p>
              </div>
              <div className="demographics">
                <p className="header">Alias</p>
                <div>
                  {alias
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            `${t.FirstName}, ${t.MiddleName}, ${
                              t.LastName
                            },${formatDate(t.CreatedOn)}` ===
                            `${item.FirstName}, ${item.MiddleName}, ${
                              item.LastName
                            },${formatDate(item.CreatedOn)}`
                        )
                    )
                    .map((alias, index) => (
                      <Aliass
                        alias={alias}
                        index={index}
                        setCombinedDataArray={setCombinedDataArray}
                        combinedDataArray={combinedDataArray}
                      />
                    ))}
                </div>
              </div>
            </div>

            <div className="addresses">
              <p className="header">Addresses</p>
              <div className="line_"></div>
              {Addressdata.filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      `${t.HouseNumber}, ${t.Street1Name}, ${t.City}, ${t.StateCode}, ${t.ZipCode},(04/10/2023)` ===
                      `${item.HouseNumber}, ${item.Street1Name}, ${item.City}, ${item.StateCode}, ${item.ZipCode},(04/10/2023)`
                  )
              ).map((address, index) => (
                <Address
                  address={address}
                  index={index}
                  setCombinedDataArray={setCombinedDataArray}
                  combinedDataArray={combinedDataArray}
                />
              ))}
            </div>

            <div className="demographics">
              <p className="header">Demographics</p>
              <div></div>
              <div className="demographics-row">
                <div className="demographic-item">
                  <p>Sex</p>

                  <Demographics
                    sex={personData
                      .filter((val) => {
                        if (val?.Sex) {
                          return val;
                        }
                      })
                      .filter(
                        (item, index, self) =>
                          index === self.findIndex((t) => t.Sex === item.Sex)
                      )}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                  {/* <RadioComponent RadioTextList={["None"]} value={0} /> */}
                </div>
                <div className="demographic-item">
                  <p>Race</p>
                  <Race
                    race={personData
                      .filter((val) => {
                        if (val?.Race) {
                          return val;
                        }
                      })
                      .filter(
                        (item, index, self) =>
                          index === self.findIndex((t) => t?.Race === item.Race)
                      )}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                
                </div>
                <div className="demographic-item">
                  <p>Skin Tone</p>
                  <SkinTone
                    skin={personData
                      .filter((val) => {
                        if (val?.Complexion) {
                          return val;
                        }
                      })
                      .filter(
                        (item, index, self) =>
                          index ===
                          self.findIndex(
                            (t) => t?.Complexion === item.Complexion
                          )
                      )}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                 
                </div>
                <div className="demographic-item">
                  <p>Facial Hair</p>
                  <FacialHair
                    hair={personData}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                
                </div>
                <div className="demographic-item">
                  <p>Glasses</p>
                  <Glasses
                    glasses={personData}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                 
                </div>
              </div>

              <div className="demographics-row">
                <div className="demographic-item">
                  <p>Eye</p>
                  <Eye
                    eye={personData
                      .filter((val) => {
                        if (val?.EyeColor) {
                          return val;
                        }
                      })
                      .filter(
                        (item, index, self) =>
                          index ===
                          self.findIndex((t) => t?.EyeColor === item.EyeColor)
                      )}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                 
                </div>
                <div className="demographic-item">
                  <p>Height</p>
                  <Height
                    height={personData
                      .filter((val) => {
                        if (val?.Height) {
                          return val;
                        }
                      })
                      .filter(
                        (item, index, self) =>
                          index ===
                          self.findIndex((t) => t?.Height === item.Height)
                      )}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                 
                </div>
                <div className="demographic-item">
                  <p>Build</p>
                  <Build
                    build={personData
                      .filter((val) => {
                        if (val?.Build) {
                          return val;
                        }
                      })
                      .filter(
                        (item, index, self) =>
                          index ===
                          self.findIndex((t) => t?.Build === item.Build)
                      )}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                 
                </div>
                <div className="demographic-item">
                  <p>Handness</p>
                  <Handness
                    handness={personData}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                
                </div>
              </div>
            </div>
          </div>
          <div className="demo__" style={{ gap: "100px" }}>
            <div className="demographics" style={{ marginTop: "5%" }}>
              <p className="header">
                Scars, marks, Tattoos and other Physical Characteristics
              </p>
              <div></div>
              <div className="demographics-row">
                <div>
                  {/* {scarsdata.map((physical, index) => (
                    <PhysicalFeature
                      physical={physical}
                      index={index}
                      setCombinedDataArray={setCombinedDataArray}
                      combinedDataArray={combinedDataArray}
                    />
                  ))}
                  ${physical?.PhysicalFeatureNciccode}, ${physical?.CreatedOn},(04/10/2023)
                  */}

                  {scarsdata
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            `${t.PhysicalFeatureNciccode},${formatDate(
                              t.CreatedOn
                            )}` ===
                            `${item.PhysicalFeatureNciccode},${formatDate(
                              item.CreatedOn
                            )}`
                        )
                    )
                    .map((physical, index) => (
                      <PhysicalFeature
                        physical={physical}
                        index={index}
                        setCombinedDataArray={setCombinedDataArray}
                        combinedDataArray={combinedDataArray}
                      />
                    ))}
                 
                </div>
              </div>
            </div>
          </div>

          <div>
            <Divider />
          </div>

          <div className="demo__" style={{ gap: "100px" }}>
            <div className="demographics">
              <p className="header">Identification Number</p>
              <div></div>
              {identification
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.IDentificationCode}, ${t.PrimaryIDentification}` ===
                        `${item.IDentificationCode}, ${item.PrimaryIDentification}`
                    )
                )
                .map((identification, index) => (
                  <Identification
                    identification={identification}
                    index={index}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                ))}
             
            </div>
            <div className="demographics">
              <p className="header">Contact</p>
              <div></div>
              {/* <div className="demographics-row">
                <div className="">
                  <CheckBoxComponent
                    fieldName={contacts[0]?.Type}
                    value={true}
                  />
                  <RadioComponent RadioTextList={["None"]} value={0} />
                </div>
              </div> */}

              {contacts
                .filter(
                  (item, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        `${t.ContactTypeName}, ${t.ContactValue}` ===
                        `${item.ContactTypeName}, ${item.ContactValue}`
                    )
                )
                .map((identification, index) => (
                  <Contact
                    contact={identification}
                    index={index}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                ))}
            </div>
          </div>

          <div>
            <Divider />
          </div>
          <div className="demo__" style={{ gap: "100px" }}>
            <div className="demographics">
              <p className="header">Emergency Contact</p>
              <div></div>
              <div className="demographics-row">
                <div>
                  {emergencyContact
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            `${t.EmergencyContact.map((val2) => {
                              return (
                                <>
                                  {val2.RelationName} {val2.FirstName}{" "}
                                  {val2.LastName}
                                  {val2.Address.Addresses.map((Addressval) => {
                                    return `${Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}`;
                                  })}
                                  {val2.Contact.Contacts.map((Contacts) => {
                                    return `${Contacts.ContactValue} ${Contacts.ContactTypeName}`;
                                  })}
                                </>
                              );
                            })}` ===
                            `${item.EmergencyContact.map((val2) => {
                              return (
                                <>
                                  {val2.RelationName} {val2.FirstName}{" "}
                                  {val2.LastName}
                                  {val2.Address.Addresses.map((Addressval) => {
                                    return `${Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}`;
                                  })}
                                  {val2.Contact.Contacts.map((Contacts) => {
                                    return `${Contacts.ContactValue} ${Contacts.ContactTypeName}`;
                                  })}
                                </>
                              );
                            })}`
                        )
                    )
                    .map((val) => {
                      return (
                        <>
                          {val.EmergencyContact.map((val2) => {
                            return (
                              <>
                                <div style={{ border: "1px solid black" }}>
                                  <input
                                    onClick={() => {
                                      setCombinedDataArray([
                                        ...combinedDataArray,
                                        {
                                          EntityId: val2.ExternalContactID,
                                          EntityType: "EmergencyContact",
                                          MatchesParticipantId:
                                            val.ParticipantId,
                                        },
                                      ]);
                                    }}
                                    type="checkbox"
                                  />
                                  <p>
                                    {val2.RelationName} {val2.FirstName}{" "}
                                    {val2.LastName}
                                  </p>
                                  <p>
                                    {val2.Address.Addresses.map(
                                      (Addressval) => {
                                        return `${Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}`;
                                      }
                                    )}
                                  </p>
                                  <p>
                                    {val2.Contact.Contacts.map((Contacts) => {
                                      return `${Contacts.ContactValue} ${Contacts.ContactTypeName}`;
                                    })}
                                  </p>
                                </div>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
               
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "-1%" }}>
            <Divider />
          </div>
          <div className="demo__" style={{ gap: "100px" }}>
            <div className="demographics">
              <p className="header">Limited English Proficiency</p>
              <div></div>
              <Language
                language={additional}
                setCombinedDataArray={setCombinedDataArray}
                combinedDataArray={combinedDataArray}
              />
            </div>
            <div className="demographics">
              <p className="header">US Citizen</p>
              <div></div>

              <UScitizen
                uscitizen={additional}
                setCombinedDataArray={setCombinedDataArray}
                combinedDataArray={combinedDataArray}
              />
            </div>

            <div className="demographics">
              <p className="header">Martial Status</p>
              <div></div>
              <div className="demographics-row">
                <div className="">
                  <Marital
                    marital={additional}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="demo__" style={{ gap: "100px" }}>
            <div className="demographics">
              <p className="header">Disability</p>
              <div></div>
              <div className="demographics-row">
                <div className="">
                  <Disability
                    disability={additional}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                </div>
              </div>
            </div>
            {/* <div className="demographics">
              <p className="header">Medical</p>
              <div ></div>
              <div className="demographics-row">
                <div className="">
                  <CheckBoxComponent
                    fieldName="A Positive Blood(04/10/2023)"
                    value={true}
                  />

                  <RadioComponent RadioTextList={["None"]} value={0} />
                </div>
              </div>
            </div> */}
          </div>
          <div className="demo__" style={{ gap: "44%" }}>
            <div className="demographics">
              <p className="header">Finger Prints</p>
              <div></div>
              <div className="demographics-row">
                <div className="">
                  <Finger
                    finger={additional}
                    setCombinedDataArray={setCombinedDataArray}
                    combinedDataArray={combinedDataArray}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="demo__" style={{ gap: "50%" }}>
            <div className="demographics">
              <p className="header">School</p>
              <div></div>
              <div className="demographics-row">
                <School
                  school={additional}
                  setCombinedDataArray={setCombinedDataArray}
                  combinedDataArray={combinedDataArray}
                />
              </div>
            </div>
            <div className="demographics">
              <p className="header">Employer</p>
              <div></div>
              {/* <div className="demographics-row">
                <div className="">
                  <CheckBoxComponent
                    fieldName="Life Time Fitness(04/10/2023)"
                    value={true}
                  />

                  <RadioComponent RadioTextList={["None"]} value={0} />
                </div>
              </div> */}

              <div>
                {additional
                  .filter(
                    (item, index, self) =>
                      index ===
                      self.findIndex(
                        (t) =>
                          `${t.EmploymentInformation.map((val2) => {
                            return (
                              <>
                                {val2.EmployerName} {val2.Occupation}{" "}
                                {val2.OccupationName}
                                {val2.Address.map((Addressval) => {
                                  return `${Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}`;
                                })}
                                {/* {Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}(04/10/2023) */}
                                {/* {val2.Contact.Contacts.map((Contacts) => {
                                    return `${Contacts.ContactValue} ${Contacts.ContactTypeName}`;
                                  })} */}
                              </>
                            );
                          })}` ===
                          `${item.EmploymentInformation.map((val2) => {
                            return (
                              <>
                                {val2.EmployerName} {val2.Occupation}{" "}
                                {val2.OccupationName}
                                {val2.Address.map((Addressval) => {
                                  return `${Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}`;
                                })}
                                {/* {val2.Contact.Contacts.map((Contacts) => {
                                    return `${Contacts.ContactValue} ${Contacts.ContactTypeName}`;
                                  })} */}
                              </>
                            );
                          })}`
                      )
                  )
                  .map((val) => {
                    return (
                      <>
                        {val.EmploymentInformation.map((val2) => {
                          return (
                            <>
                              <div style={{ border: "1px solid black" }}>
                                <input
                                  onClick={() => {
                                    setCombinedDataArray([
                                      ...combinedDataArray,
                                      {
                                        EntityId: val2.EmploymentId,
                                        EntityType: "Employment",
                                        MatchesParticipantId:
                                          val?.ParticipantId,
                                      },
                                    ]);
                                  }}
                                  type="checkbox"
                                />
                                <p>
                                  {val2?.EmployerName}
                                  {val2?.Occupation}
                                  {val2?.OccupationName}{" "}
                                </p>
                                <p>
                                  {val2.Address.map((Addressval) => {
                                    return `${Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}`;
                                  })}
                                </p>
                                {/* <p>
                                    {val2.Contact.Contacts.map((Contacts) => {
                                      return `${Contacts.ContactValue} ${Contacts.ContactTypeName}`;
                                    })}
                                  </p> */}
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
              </div>

              {/* <div>
                {additional.map((val) => {
                  return (
                    <>
                      {val?.EmploymentInformation.map((val2) => {
                        return (
                          <>
                            <div>
                              <input
                                onClick={() => {
                                  setCombinedDataArray([
                                    ...combinedDataArray,
                                    {
                                      EntityId: val2.EmploymentId,
                                      EntityType: "Employment",
                                      MatchesParticipantId: val?.ParticipantId,
                                    },
                                  ]);
                                }}
                                type="checkbox"
                              />
                              <p>
                                {val2?.EmployerName}
                                {val2?.Occupation}
                                {val2?.OccupationName}{" "}
                              </p>
                              <p>
                                {val2.Address.map((Addressval) => {
                                  return `${Addressval.StateName} ${Addressval.City} ${Addressval.ZipCode} ${Addressval.CountryName}(04/10/2023)`;
                                })}
                              </p>
                              <RadioComponent
                                RadioTextList={["None"]}
                                value={0}
                              />
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </div> */}
            </div>
          </div>
        </div>

        <div className="editbutton">
          <Submit
            saveColor="#3b72b1"
            saveLabel="Confirm"
            onSave={onsave}
            //onCancel={oncancel}
          />
        </div>
      </div>
    </>
  );
};
