import React, { useEffect, useState } from "react";
import LocationMetaData from "../../Location/LocationMetaData";
import { Filter } from "./Components/NarrativeFilter";
import { Table } from "../../../../CommonComponents/Table/Table";
import { setTableData } from "./NarrativeIState";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { NarrativeListFunction } from "./NarrativeFunction";
import { coloum } from "./config";
import useDelayedEffect from "../../../../../hooks/useDelayedEffect";
import { orderBy } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import { DeleteConfirmation } from "../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useGetPerticularUserPermission } from "src/hooks/Permission/useGetPerticularUserPermission";

const NarrativeList = () => {
  const { Delete, Update } = useGetPerticularUserPermission("Incident.Narrative");
  const navigate = useNavigate();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const narrativeFunction = new NarrativeListFunction();
  const [width, setwidth] = useState<number>(10);
  const [tableData, setTableData] = useState<setTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(true);
  const [OrderBy, setOrderBy] = useState<string>("Created On");
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [deletePopup, setDeletePopUp] = useState<boolean>(false);
  const [deleteNarrativeId, setDeleteNarrativeId] = useState<string>("");
  const [isClear,setIsClear]=useState<boolean>(false);
  const getNarrativeListTableData = () => {
    if (incidentId) {
      narrativeFunction.getNarrativeListTableData(
        setTableData,
        pageNumber,
        pageCount,
        setLoading,
        setTableTotalRecord,
        Name,
        Type,
        incidentId,
        OrderByDescending,
        OrderBy
      );
    }
  };
  const initialData = () => {
    getNarrativeListTableData();
  };

  const closeDeletePopup = () => {
    setDeletePopUp(false);
    setDeleteNarrativeId("");
  };

  const rowClicked = (e: setTableData) => {
    if (deleteNarrativeId === "") {
      navigate(
        `/incidents/${incidentId}/narratives/${e?.NarrativeId}/overviews`
      );
    }
  };

  const handleDelete = async () => {
    let res;
    if (deleteNarrativeId !== "" && incidentId) {
      res = await narrativeFunction?.deleteNarrative(
        deleteNarrativeId,
        setLoading,
        incidentId
      );
    }
    closeDeletePopup();
    if (res?.Success) {
      initialData();
      setDeleteNarrativeId("");
    }
  };
  useEffect(() => {
    initialData();
  }, [pageNumber, pageCount, OrderByDescending, isClear]);

  useDelayedEffect(getNarrativeListTableData, 700, []);
  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        open={deletePopup}
        onClose={closeDeletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="_incident_location_container_fluid_">
        <div className="_location_left_container_">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="UserManagement _location_right_container">
          <div className="_user_management_container">
            <div>
              <BreadcrumbComponent
                fixedCrumbItems={[
                  { title: "Incidents", path: "/incidents" },
                  { title: incidentNumber ? incidentNumber : "", path: "" },
                  {
                    title: "Narratives",
                    path: `/incidents/${incidentId}/narratives`,
                  },
                ]}
              />
              <p className="header">List Of Narratives </p>
            </div>

            <Filter
              Name={Name}
              Type={Type}
              setName={setName}
              setType={setType}
              initialData={initialData}
              setIsClear={setIsClear}
              isClear={isClear}
            />

            <div className="table">
              <Table
                columns={coloum(
                  setDeletePopUp,
                  setDeleteNarrativeId,
                  navigate,
                  incidentId,
                  Delete,
                  Update
                )}
                columnsValue={tableData}
                cursorPointer={true}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                onRowClicked={rowClicked}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NarrativeList;
