import React, { useEffect, useState } from "react";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { CheckBoxComponent } from "../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { addressesLocation, RecoveryModalIProps } from "./AddRecoveryIState";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { AddRecoveryFunction } from "./AddRecoveryFunction";
import { GetRaceDataIState, getStateDataIState } from "../AddVehicleIState";
import { NumberBoxComponents } from "../../../../../CommonComponents/Fields/NumberBox/NumberBoxComponents";

const RecoveryModal: React.FC<RecoveryModalIProps> = ({ onClose }) => {
  const addrecovery = new AddRecoveryFunction();
  const [loading, setLoading] = useState<boolean>(false);
  const [AddressTypeData, setAddressTypeData] = useState<GetRaceDataIState[]>(
    []
  );
  const [Code, setCode] = useState<GetRaceDataIState[]>([]);
  const [UnitType, setUnitType] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [county, setCounty] = useState<GetRaceDataIState[]>([]);
  const [stateData, setStateData] = useState<GetRaceDataIState[]>([]);
  const [Location, setLocation] = useState<GetRaceDataIState[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addLocationAddress, setAddLocationAddress] =
    useState<addressesLocation>({
      IncidentId: "53C54E75-8FA5-4576-86E1-BBB9465D4269",
      LocationId: "",
      IsPrimary: false,
      LocationCategoryCode: "",
      LocationName: "",
      Description: "",
      AddressType: "",

      County:"",
      Intersection: false,
      HouseNumber: "",
      Street1Name: "",
      UnitType: "",
      NciccountrycodeId: "",
      UnitNumber: "",
      Street2Name: "",
      City: "",
      ZipCode: "",
      Grid: "",
      IsGeoValidated: false,
      Latitude: 0,
      Longitude: 0,
      GeoCode: "",
      ParcelId: "",
      NciccountryId: "",
      OtherCity: "",
      OtherState: "",
      OtherZip: "",
      OtherCountry: "",
      IsForeignAddress: false,
      NcicstateId: "",
      CountryName: "",
      Ncicstreet1PrefixId: "",
      Ncicstreet1SuffixId: "",
      Ncicstreet2PrefixId: "",
      Ncicstreet2SuffixId: "",
    });
  const handleInputChange = (
    field: string,
    value: string | number | null | boolean
  ) => {
    setAddLocationAddress({
      ...addLocationAddress,
      [field]: value,
    });
  };

  const oncancel = () => {
    setAddLocationAddress({
      IncidentId: "53C54E75-8FA5-4576-86E1-BBB9465D4269",
      LocationId: "",
      IsPrimary: false,
      LocationCategoryCode: "",
      LocationName: "",
      Description: "",
      AddressType: "",
      Intersection: false,
      HouseNumber: "",
      Street1Name: "",
      UnitType: "",
      County:"",
      NciccountrycodeId: "",
      UnitNumber: "",
      Street2Name: "",
      City: "",
      ZipCode: "",
      Grid: "",
      IsGeoValidated: false,
      Latitude: 0,
      Longitude: 0,
      GeoCode: "",
      ParcelId: "",
      NciccountryId: "",
      OtherCity: "",
      OtherState: "",
      OtherZip: "",
      OtherCountry: "",
      IsForeignAddress: false,
      NcicstateId: "",
      CountryName: "",
      Ncicstreet1PrefixId: "",
      Ncicstreet1SuffixId: "",
      Ncicstreet2PrefixId: "",
      Ncicstreet2SuffixId: "",
    });
  };
  const AddAddressLocation = () => {
    addrecovery.locationAddressSave(addLocationAddress);
  };
  const handleClick = () => {
    AddAddressLocation();
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const initialRender = () => {
    addrecovery.getAddresType(setAddressTypeData, setLoading);
    addrecovery.getLocation(setLocation, setLoading);
    addrecovery.getStateData(setStateData, setLoading);
    addrecovery.getCountry(setCountry, setLoading);
    addrecovery.getUnitType(setUnitType, setLoading);
    addrecovery.getStreetPSuffix(setStreetSuffix, setLoading);
    addrecovery.getStreetPrefix(setStreetPrefix, setLoading);
    addrecovery.getCountryCode(setCode, setLoading);
    addrecovery.getCounty(setCounty, setLoading);
  };

  useEffect(() => {
    initialRender();
  }, []);
  return (
    <>
      <div className="_add_location_modal_content_container">
        <div className="_add_location_title_container">
          <h3>Add Location</h3>
        </div>

        <div className="_address_type_modal_container">
          <div className="_address_type_dropdown">
            <DropdownComponent
              value={addLocationAddress.LocationCategoryCode}
              handleRowClick={(e) => {
                handleInputChange("LocationCategoryCode", e);
              }}
              dataSource={Location.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Location Category"
            />
          </div>
          <div className="_address_type_dropdown">
            <DropdownComponent
              value={addLocationAddress.AddressType}
              dataSource={AddressTypeData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                handleInputChange("AddressType", e);
              }}
              fieldName="Address type"
            />
          </div>
          <div className="_address_type_dropdown">
            <TextBoxComponent
              value={addLocationAddress.LocationName}
              fieldName="Address Name"
              onChangeValue={(newValue) => {
                handleInputChange("LocationName", newValue);
              }}
            />
          </div>
          <div className="_isLocationPrimary">
            <CheckBoxComponent
              fieldName="Primary"
              value={addLocationAddress.IsPrimary}
              onChangeValue={(newValue) => {
                handleInputChange("IsPrimary", newValue);
              }}
            />
          </div>
        </div>

        <div className="_address_description_container">
          <TextAreaComponent
            fieldName="Description"
            value={addLocationAddress.Description}
            onChangeValue={(newValue) => {
              handleInputChange("Description", newValue);
            }}
          />
        </div>

        <div className="_add_location_form_container">
          <div className="_add_vehicle_form_desc_title">
            <p>Address</p>
          </div>
        </div>

        <div className="_add_location_street_address_container">
          <div className="">
            <div className="_add_location_street_number_container">
              <TextBoxComponent
                fieldName="Street Number"
                value={addLocationAddress.HouseNumber}
                onChangeValue={(newValue) => {
                  handleInputChange("HouseNumber", newValue);
                }}
              />
            </div>
          </div>
          <div className="_add_location_street_number_container">
            {" "}
            <DropdownComponent
              fieldName="Street Suffix"
              value={addLocationAddress.Ncicstreet1SuffixId}
              handleRowClick={(e) => {
                handleInputChange("Ncicstreet1SuffixId", e);
              }}
              dataSource={StreetSuffix.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
            />
          </div>

          <div className="_add_location_street_container">
            <TextBoxComponent
              fieldName="Street"
              value={addLocationAddress.Street2Name}
              onChangeValue={(newValue) => {
                handleInputChange("Street2Name", newValue);
              }}
            />
          </div>
          <div className="_add_location_street_suffix">
            <DropdownComponent
              fieldName="Street Preffix"
              value={addLocationAddress.Ncicstreet1PrefixId}
              handleRowClick={(e) => {
                handleInputChange("Ncicstreet1PrefixId", e);
              }}
              dataSource={StreetPrefix.map((state) => ({
                id: state.NciccodeId,
                value: state.LongCode,
              }))}
            />
          </div>

          <div className="_add_location_unit_type_container">
            <DropdownComponent
              fieldName="Unit type"
              value={addLocationAddress.UnitType}
              dataSource={UnitType.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                handleInputChange("UnitType", e);
              }}
            />
          </div>
          <div className="_add_location_unit_number">
            <TextBoxComponent
              fieldName="Unit number"
              value={addLocationAddress.UnitNumber}
              onChangeValue={(newValue) => {
                handleInputChange("UnitNumber", newValue);
              }}
            />
          </div>
        </div>

        <div className="_add_location_full_address">
          <div className="_add_location_city_container">
            <TextBoxComponent
              value={addLocationAddress.City}
              onChangeValue={(newValue) => {
                handleInputChange("City", newValue);
              }}
              fieldName="City"
            />
          </div>

          <div className="_full_address_state_conatiner">
            <DropdownComponent
              fieldName="State"
              value={addLocationAddress.NcicstateId}
              handleRowClick={(e) => {
                handleInputChange("NcicstateId", e);
              }}
              dataSource={stateData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
            />
          </div>

          <div className="_add_location_zip_code_container">
            <TextBoxComponent
              fieldName="Zip code"
              value={addLocationAddress.ZipCode}
              onChangeValue={(newValue) => {
                handleInputChange("ZipCode", newValue);
              }}
            />
          </div>

          <div className="add_location_grid">
            <TextBoxComponent
              fieldName="Grid"
              value={addLocationAddress.Grid}
              onChangeValue={(newValue) => {
                if(newValue.length< 6){
                    handleInputChange("Grid", newValue);    
                }
                
              }}
            />
          </div>
        </div>

        <div className="_add_location_country_and_code_container">
          <div className="_add_location_country_container ">
          <DropdownComponent
              fieldName="County"
              value={addLocationAddress.County}
              handleRowClick={(e) => {
                handleInputChange("County", e);
              }}
              dataSource={county.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
            />
           
          </div>
          <div className="_add_location_country_container ">
          <DropdownComponent
              fieldName="Country"
              value={addLocationAddress.CountryName}
              handleRowClick={(e) => {
                handleInputChange("CountryName", e);
              }}
              dataSource={country.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
            />    
          </div>
          
        </div>

        <div className="_add_location_geo_location_container">
          <div className="_add_vehicle_form_desc_title">
            <p>Geo Location</p>
          </div>

          <div className="_add_location_country_and_code_container add_location_geo_location_container">
            <div className="_add_location_country_container ">
              <NumberBoxComponents
                fieldName="Latitude"
                value={addLocationAddress.Latitude}
                onValueChange={(newValue) => {
                  handleInputChange("Latitude", newValue);
                }}
              />
            </div>

            <div className="_add_location_country_container ">
              <NumberBoxComponents
                fieldName="Longitude"
                value={addLocationAddress.Longitude}
                onValueChange={(newValue) => {
                  handleInputChange("Longitude", newValue);
                }}
              />
            </div>

            <div className="_add_location_country_container ">
              <TextBoxComponent
                fieldName="Geo code"
                value={addLocationAddress.GeoCode}
                onChangeValue={(newValue) => {
                  handleInputChange("GeoCode", newValue);
                }}
              />
            </div>
          </div>
          <div className="_parcel_id_container">
            <TextBoxComponent
              fieldName="Parcel ID"
              value={addLocationAddress.ParcelId}
              onChangeValue={(newValue) => {
                handleInputChange("ParcelId", newValue);
              }}
            />
          </div>

          <div className="_submit_btn_container_is_geo_checkbox">
            <div className="checkbox_container">
              <CheckBoxComponent
                fieldName="Is Geo Validated"
                value={addLocationAddress.IsGeoValidated}
                onChangeValue={(e) => {
                  handleInputChange("IsGeoValidated", e);
                }}
              />
            </div>

            <div className="submit_btn_container">
              <Submit
                onCancel={oncancel}
                onSave={handleClick}
                saveColor="#3672B3
"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoveryModal;
