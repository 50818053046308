import { MultipleBloodIProps } from "../SubstanceInvolvement/SubstanceIProps";
import { MultiBlood } from "./MultipleBloodAlocohol";

export const BloodAlochol: React.FC<MultipleBloodIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  return (
    <>
      <MultiBlood Mainfields={Mainfields} setMainfields={setMainfields} />
    </>
  );
};
