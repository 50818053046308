import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (): ColDef[] => {
  return [
    { field: "Short Code" },
    { field: "Long Code" },
    { field: "Category" },
    { field: "Description" },
    {
      field: "Date Active",
      valueFormatter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "MM-dd-yyyy hh:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      field: "Date InActive",
      valueFormatter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "MM-dd-yyyy hh:mm:ss");
        } else {
          return "";
        }
      },
    },
    { field: "Status" },
  ];
};
