import { CheckBoxComponent } from "../../../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import {
  ArmedWithWeaponDataUIIProps,
  ArmedWithWeaponIProps,
} from "./ArmedWithWeaponI";
import { useEffect, useState } from "react";
import { ArmedWithWeaponFunction } from "./ArmedWithWeaponFunction";
import { GetRaceDataIState } from "../../../../InvolvementMainI";
import { Add } from "../../../../../../../../../../../assets";

const ArmedWithWeaponDataUI: React.FC<ArmedWithWeaponDataUIIProps> = ({
  ArmedWithWeaponval,
  Mainfields,
  setMainfields,
  ArmedWithWeapon,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean,
    id: number
  ) => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Arrestinformation: {
          ...Mainfields.InvolvementTab.Arrestinformation,
          ArmedWithWeapon:
            Mainfields.InvolvementTab.Arrestinformation.ArmedWithWeapon.map(
              (val) => {
                if (id == val.id) {
                  return {
                    ...val,
                    [key]: value,
                  };
                } else {
                  return { ...val };
                }
              }
            ),
        },
      },
    });
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px", width: "50%" }}>
        <DropdownComponent
          dataSource={ArmedWithWeapon.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          handleRowClick={(e) => {
            onChangeValueNumber("ArmedWithWeapon", e, ArmedWithWeaponval.id);
          }}
          value={ArmedWithWeaponval.ArmedWithWeapon}
          fieldName=""
        />
        <div className="_weapon_automatic_container">
          <CheckBoxComponent
            value={ArmedWithWeaponval.Weaponautomatic}
            fieldName="Weapon automatic"
            onChangeValue={(e) => {
              onChangeValueNumber("Weaponautomatic", e, ArmedWithWeaponval.id);
            }}
          />
        </div>
      </div>
    </>
  );
};

export const ArmedWithWeapon: React.FC<ArmedWithWeaponIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const armedWithWeaponFunction = new ArmedWithWeaponFunction();
  const [ArmedWithWeapon, setArmedWithWeapon] = useState<GetRaceDataIState[]>(
    []
  );
  const initialRender = () => {
    armedWithWeaponFunction.ArmedWithWeapon(setArmedWithWeapon);
  };

  useEffect(() => {
    initialRender();
  }, []);

  const handleClick = () => {
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        onChange: true,
        Arrestinformation: {
          ...Mainfields.InvolvementTab.Arrestinformation,
          ArmedWithWeapon: [
            ...Mainfields.InvolvementTab.Arrestinformation.ArmedWithWeapon,
            {
              ArmedWithWeapon: "",
              id:
                Mainfields.InvolvementTab.Arrestinformation.ArmedWithWeapon
                  .length + 1,
              Weaponautomatic: false,
              ArresteeWasArmedWithTypeId: "",
            },
          ],
        },
      },
    });
  };

  return (
    <>
      <div>
        <p>Armed With Weapon</p>
        {Mainfields?.InvolvementTab?.Arrestinformation?.ArmedWithWeapon.map(
          (ArmedWithWeaponval) => {
            return (
              <>
                <ArmedWithWeaponDataUI
                  Mainfields={Mainfields}
                  setMainfields={setMainfields}
                  ArmedWithWeaponval={ArmedWithWeaponval}
                  ArmedWithWeapon={ArmedWithWeapon}
                />
              </>
            );
          }
        )}
        <div className="edit-button_">
          <ButtonComponents
            name="Add"
            handleClick={handleClick}
            icon={Add}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
          />
        </div>
      </div>
    </>
  );
};
