import { useEffect, useState } from "react";
import {
  MultipleAddressDataUIProps,
  MultipleAddressIProps,
} from "./MultipleAddressI";
import add from "../../../../../../../../../assets/icons/Icon.svg";
import { InvolvementMainFunction } from "../../../Involvement/InvolvementMainFunction";
import { GetRaceDataIState } from "../../ExternalContactI";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../../../../assets";

const MultipleAddressDataUI: React.FC<MultipleAddressDataUIProps> = ({
  Mainfields,
  setMainfields,
  MultipleAddressval,
  RaceDataData,
  StreetPrefix,
  StreetSuffix,
  State,
  Country,
  NameSuffix,
  addressval,
  LocationCategory,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (val.id == addressval.id) {
            return {
              ...val,
              onChange: true,
              Address: {
                ...val.Address,
                AddressData: val.Address.AddressData.map((val) => {
                  if (val.id == id) {
                    if (key2 && value2) {
                      return { ...val, [key]: value, [key2]: value2 };
                    } else {
                      return { ...val, [key]: value };
                    }
                  } else {
                    return val;
                  }
                }),
              },
            };
          } else {
            return val;
          }
        }
      ),
    });
  };

  const remove = (id: number) => {
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (val.id == addressval.id) {
            return {
              ...val,
              onChange: true,
              Address: {
                ...val.Address,
                AddressData: val.Address.AddressData.filter((val) => {
                  if (val.id !== id) {
                    return val;
                  }
                }).length
                  ? val.Address.AddressData.filter((val) => {
                      if (val.id !== id) {
                        return val;
                      }
                    })
                  : [
                      {
                        id: 1,
                        AddressType: "",
                        StreetNumber: "",
                        StreetPrefix: "",
                        Street: "",
                        StreetSuffix: "",
                        Apartment: "",
                        City: "",
                        StateProvidence: "",
                        ZipCode: "",
                        Country: "",
                        GridNumber: "",
                        LocationCategoryCode: "",
                        AddressLine1: "",
                        AddressLine2: "",
                        ForeignAddressCity: "",
                        ForeignAddressStateProvinceRegion: "",
                        ForeignAddressZip: "",
                        ForeignAddressCountry: "",
                        ForeignAddress: false,
                        CountryDropdownValue: "",
                        StateProvidenceDropdownvalue: "",
                      },
                    ],
              },
            };
          } else {
            return val;
          }
        }
      ),
    });
  };

  return (
    <>
      <div className="address-checkbox_">
        {!MultipleAddressval.ForeignAddress && (
          <>
            <div className="p_address_type_container">
              <DropdownComponent
                value={MultipleAddressval.AddressType}
                handleRowClick={(e) => {
                  onChangeValueNumber("AddressType", e, MultipleAddressval.id);
                }}
                dataSource={RaceDataData.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Address Type"
              />
            </div>
            <div className="p_address_type_container">
              <DropdownComponent
                value={MultipleAddressval.LocationCategoryCode}
                handleRowClick={(e) => {
                  onChangeValueNumber(
                    "LocationCategoryCode",
                    e,
                    MultipleAddressval.id
                  );
                }}
                dataSource={LocationCategory.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Location Category"
              />
            </div>
          </>
        )}
        <div style={{ marginTop: "5%" }}>
          <CheckBoxComponent
            value={MultipleAddressval.ForeignAddress}
            fieldName="Foreign Address"
            onChangeValue={(e) => {
              onChangeValueNumber("ForeignAddress", e, MultipleAddressval.id);
            }}
          />
        </div>
        {addressval.Address.AddressData.length > 1 && (
          <p
            className="removeIcon"
            onClick={() => {
              remove(MultipleAddressval.id);
            }}
            title="Remove"
          >
            -
          </p>
        )}
      </div>

      {MultipleAddressval.ForeignAddress ? (
        <>
          <div style={{ width: "70%" }}>
            <TextBoxComponent
              value={MultipleAddressval.AddressLine1}
              onChangeValue={(e) => {
                onChangeValueNumber("AddressLine1", e, MultipleAddressval.id);
              }}
              fieldName="Address Line 1: Street address, P.O box, company name, c/o"
            />
            <TextBoxComponent
              value={MultipleAddressval.AddressLine2}
              onChangeValue={(e) => {
                onChangeValueNumber("AddressLine2", e, MultipleAddressval.id);
              }}
              fieldName="Address Line 2: Apartment, suite, unit, building, floor, etc"
            />
          </div>
          <div className="Involvement_left_row">
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressCity}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressCity",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="City"
            />
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressStateProvinceRegion}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressStateProvinceRegion",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="State / Province/Region"
            />
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressZip}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressZip",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="Zip / postal Code"
            />
          </div>
          <div style={{ width: "40%" }}>
            {/* <DropdownComponent
              value={MultipleAddressval.ForeignAddressCountry}
              handleRowClick={(e) => {
                onChangeValueNumber(
                  "ForeignAddressCountry",
                  e,
                  MultipleAddressval.id
                );
              }}
              dataSource={Country.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName="Country"
            /> */}
            <TextBoxComponent
              value={MultipleAddressval.ForeignAddressCountry}
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "ForeignAddressCountry",
                  e,
                  MultipleAddressval.id
                );
              }}
              fieldName="Country"
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="Involvement_left_row">
            <div className="_involvement_meta_info">
              <TextBoxComponent
                value={MultipleAddressval.StreetNumber}
                onChangeValue={(e) => {
                  onChangeValueNumber("StreetNumber", e, MultipleAddressval.id);
                }}
                fieldName="Street Number"
              />
            </div>
            <div className="_involvement_meta_info">
              <DropdownComponent
                handleRowClick={(e) => {
                  onChangeValueNumber("StreetPrefix", e, MultipleAddressval.id);
                }}
                value={MultipleAddressval.StreetPrefix}
                dataSource={StreetPrefix.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Street Prefix"
              />
            </div>

            <div className="involvemet_street_container">
              <TextBoxComponent
                onChangeValue={(e) => {
                  onChangeValueNumber("Street", e, MultipleAddressval.id);
                }}
                value={MultipleAddressval.Street}
                fieldName="Street"
              />
            </div>

            <div className="_involvement_meta_info">
              <DropdownComponent
                value={MultipleAddressval.StreetSuffix}
                handleRowClick={(e) => {
                  onChangeValueNumber("StreetSuffix", e, MultipleAddressval.id);
                }}
                dataSource={StreetSuffix.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Street Suffix"
              />
            </div>

            <div className="_involvement_meta_info">
              <TextBoxComponent
                value={MultipleAddressval.Apartment}
                onChangeValue={(e) => {
                  onChangeValueNumber("Apartment", e, MultipleAddressval.id);
                }}
                fieldName="Apartment"
              />
            </div>
          </div>
          <div className="address_city_address_container">
            <div className="_address_state_providence">
              <TextBoxComponent
                value={MultipleAddressval.City}
                fieldName="City"
                onChangeValue={(e) => {
                  onChangeValueNumber("City", e, MultipleAddressval.id);
                }}
              />
            </div>
            <div className="_address_state_providence">
              <DropdownComponent
                value={MultipleAddressval.StateProvidence}
                dataSource={State.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                handleRowClick={(e, value2) => {
                  onChangeValueNumber(
                    "StateProvidence",
                    e,
                    MultipleAddressval.id,
                    "StateProvidenceDropdownValue",
                    value2
                  );
                }}
                fieldName="State / Providence"
              />
            </div>
            <div className="_address_zip_code">
              <TextBoxComponent
                value={MultipleAddressval.ZipCode}
                onChangeValue={(e) => {
                  onChangeValueNumber("ZipCode", e, MultipleAddressval.id);
                }}
                fieldName="Zip Code"
              />
            </div>
          </div>
          <div className="country_grid_container">
            <div className="_address_country_container">
              <DropdownComponent
                value={MultipleAddressval.Country}
                handleRowClick={(e, value2) => {
                  onChangeValueNumber(
                    "Country",
                    e,
                    MultipleAddressval.id,
                    "CountryDropdownValue",
                    value2
                  );
                }}
                dataSource={Country.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
                fieldName="Country"
              />
            </div>
            <div className="_address_grid_number">
              <TextBoxComponent
                value={MultipleAddressval.GridNumber}
                onChangeValue={(e) => {
                  const value = e;
                  if (/^\d{0,5}$/.test(value)) {
                    onChangeValueNumber(
                      "GridNumber",
                      value,
                      MultipleAddressval.id
                    );
                  }
                }}
                fieldName="Grid Number"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const MultipleAddressComponents: React.FC<MultipleAddressIProps> = ({
  Mainfields,
  setMainfields,
  addressval,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [StreetPrefix, setStreetPrefix] = useState<GetRaceDataIState[]>([]);
  const [StreetSuffix, setStreetSuffix] = useState<GetRaceDataIState[]>([]);
  const [State, setState] = useState<GetRaceDataIState[]>([]);
  const [NameSuffix, setNameSuffix] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [LocationCategory, setLocationCategory] = useState<GetRaceDataIState[]>(
    []
  );

  const handleClick = () => {
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (val.id == addressval.id) {
            return {
              ...val,
              onChange: true,
              Address: {
                ...val.Address,
                AddressData: [
                  ...val.Address.AddressData,
                  {
                    id: val.Address.AddressData.length + 1,
                    AddressType: "",
                    StreetNumber: "",
                    StreetPrefix: "",
                    Street: "",
                    StreetSuffix: "",
                    Apartment: "",
                    City: "",
                    StateProvidence: "",
                    ZipCode: "",
                    Country: "",
                    GridNumber: "",
                    LocationCategoryCode: "",
                    AddressLine1: "",
                    AddressLine2: "",
                    ForeignAddressCity: "",
                    ForeignAddressStateProvinceRegion: "",
                    ForeignAddressZip: "",
                    ForeignAddressCountry: "",
                    ForeignAddress: false,
                    CountryDropdownValue: "",
                    StateProvidenceDropdownvalue: "",
                  },
                ],
              },
            };
          } else {
            return val;
          }
        }
      ),
    });
  };

  const initialRender = () => {
    involvementMainFunction.getAddresType(setRaceDataData);
    involvementMainFunction.getStreetPrefix(setStreetPrefix);
    involvementMainFunction.getStreetSuffix(setStreetSuffix);
    involvementMainFunction.getCountry(setCountry);
    involvementMainFunction.getState(setState);
    involvementMainFunction.getLocationType(setLocationCategory);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <p className="subheader_">Address</p>

        <div className="address-checkbox">
          <CheckBoxComponent
            value={addressval.Address.NoPermanentAddress}
            fieldName="No Permanent Address"
            onChangeValue={(e) => {
              setMainfields({
                ...Mainfields,
                MultipleEmergencyContact:
                  Mainfields.MultipleEmergencyContact.map((val) => {
                    if (val.id == addressval.id) {
                      return {
                        ...val,
                        onChange: true,
                        Address: {
                          ...val.Address,
                          NoPermanentAddress: e,
                        },
                      };
                    } else {
                      return val;
                    }
                  }),
              });
            }}
          />
          <CheckBoxComponent
            value={addressval.Address.UnknownAddress}
            fieldName="Unknown Address"
            onChangeValue={(e) => {
              setMainfields({
                ...Mainfields,
                MultipleEmergencyContact:
                  Mainfields.MultipleEmergencyContact.map((val) => {
                    if (val.id == addressval.id) {
                      return {
                        ...val,
                        onChange: true,
                        Address: {
                          ...val.Address,
                          UnknownAddress: e,
                        },
                      };
                    } else {
                      return val;
                    }
                  }),
              });
            }}
          />
        </div>
        {addressval.Address.AddressData.map((MultipleAddressval) => {
          return (
            <>
              <MultipleAddressDataUI
                Mainfields={Mainfields}
                setMainfields={setMainfields}
                MultipleAddressval={MultipleAddressval}
                RaceDataData={RaceDataData}
                StreetPrefix={StreetPrefix}
                StreetSuffix={StreetSuffix}
                NameSuffix={NameSuffix}
                State={State}
                Country={Country}
                addressval={addressval}
                LocationCategory={LocationCategory}
              />
              <div className="Involvement_wall_"></div>
            </>
          );
        })}
        <div className="edit-button_">
          <ButtonComponents
            name="Add Address"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
