import { useState, useEffect } from "react";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { useNavigate, useParams } from "react-router-dom";

import add from "../../../../../../assets/icons/Icon.svg";
import "../ORI/ORI.css";
import { EditAgencyManagemantIParams } from "../../../EditAgencyManagemant/EditAgencyManagemantI";
import { ORIFunction } from "./ORIFunction";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { SetTableData, setgetORISingleData } from "./ORIInterface";
import { AddEditORI } from "./AddORI/AddEditORI";
import { ModalComponent } from "../../../../../CommonComponents/Modal/ModalComponent";
import { coloum } from "./config";
import { DeleteConfirmation } from "../../../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const ORI: React.FC = () => {
  const { Create, Delete, Update } =
    useGetPerticularUserPermission("Agency");
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [showAddORI, setShowAddORI] = useState<boolean>(false);
  const [editMode, seteditMode] = useState<boolean>(false);
  const [viewMode, setviewMode] = useState<boolean>(false);
  const [AgencyOriInformationId, setAgencyOriInformationId] =
    useState<string>("");
  const [getORISingleData, setgetORISingleData] = useState<setgetORISingleData>(
    {
      Description: "",
      ORINumber: "",
      Status: true,
      AgencyOriInformationId: "",
    }
  );
  const [addMode, setaddMode] = useState<boolean>(false);
  const oRIFunction = new ORIFunction();
  const { agencyId } = useParams<EditAgencyManagemantIParams>();
  const getORITableData = () => {
    if (agencyId) {
      oRIFunction.getORITableData(setTableData, setLoading, agencyId);
    }
  };

  const editORI = (data: SetTableData) => {
    let ORINumber = data["ORI"];
    let Description = data["Description"];
    let Status = data["Status"];
    let AgencyOriInformationId = data["ID"];
    setgetORISingleData({
      Description,
      ORINumber,
      Status: Status == "InActive" ? false : true,
      AgencyOriInformationId,
    });
    setaddMode(false);
    seteditMode(true);
    setShowAddORI(true);
    setviewMode(false);
  };

  const openDeletePopup = (id: string) => {
    setdeletePopup(true);
    setAgencyOriInformationId(id);
  };

  const handleAddORIClick = () => {
    setgetORISingleData({
      Description: "",
      ORINumber: "",
      Status: true,
      AgencyOriInformationId: "",
    });
    setaddMode(true);
    seteditMode(false);
    setShowAddORI(true);
    setviewMode(false);
  };

  const closeDeletePopup = () => {
    setdeletePopup(false);
  };

  const handleDelete = () => {
    if (agencyId) {
      oRIFunction
        .deleteORI(AgencyOriInformationId, setLoading, setdeletePopup)
        .then(() => {
          getORITableData();
        });
    }
  };

  const onRowClicked = (data: SetTableData) => {
    if (!deletePopup) {
      let ORINumber = data?.ORI;
      let Description = data?.Description;
      let Status = data?.Status;
      let AgencyOriInformationId = data?.ID;
      setgetORISingleData({
        Description,
        ORINumber,
        Status: Status == "InActive" ? false : true,
        AgencyOriInformationId,
      });
      setaddMode(false);
      seteditMode(false);
      setShowAddORI(true);
      setviewMode(true);
    }
  };

  useEffect(() => {
    if (!showAddORI) {
      getORITableData();
    }
  }, [agencyId, showAddORI]);
  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        open={deletePopup}
        onClose={closeDeletePopup}
        title="Delete"
        innerContant={
          <DeleteConfirmation
            onClose={closeDeletePopup}
            onDelete={handleDelete}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      {showAddORI ? (
        <AddEditORI
          addmode={addMode}
          editMode={editMode}
          setShowAddORI={setShowAddORI}
          getORISingleData={getORISingleData}
          viewMode={viewMode}
          setaddMode={setaddMode}
          seteditMode={seteditMode}
          setviewMode={setviewMode}
        />
      ) : (
        <div className="DetailTab_top">
          <div className="DetailTab_top_header_">
            <div className="DetailTab_top_header_sub">
              <p>Originating Agency Identifier</p>
              <div className="IPAddress">
                {agencyId && (
                  <ButtonComponents
                    name="Add New ORI"
                    icon={add}
                    handleClick={handleAddORIClick}
                     showBackgroundColor={true}
                    textColor="white"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="IPAddresses_table">
            <Loader loading={loading} />
            <Table
              columns={coloum(editORI, openDeletePopup, Delete, Update)}
              columnsValue={tableData}
              showPagination={false}
              onRowClicked={onRowClicked}
              clientSideSorting={true}
              
            />
          </div>
        </div>
      )}
    </>
  );
};
