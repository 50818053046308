import { Fetcher } from "../../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";
import { AlertsIRes, AlertsTableData } from "../ParticipantOverviewIState";

const fetcher = new Fetcher();

export class AlertsFunction {
  async getAlerts(
    participantId: string,
    incidentId: string,
    setTableData: (data: AlertsTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/summary-alert`
    );
    let res: AlertsIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: AlertsIRes[]) {
    return data?.map((val: AlertsIRes, i: number) => {
      return {
        Type: val.Type,
        Details: val.Detail,
        "Issued/Created Date": formatDate(val.IssuedDate),
      };
    });
  }
}
