import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";

import { AddEditRole } from "../Components/AddEditForm/AddEditForm";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

export const AddRole: React.FC = () => {
  const Navigation = useNavigate();
  const NavigateToRolePage = () => {
    Navigation("/role");
  };
  return (
    <>
      <div className="AddPolicy">
        <div className="AddPolicysub">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Role", path: "/role" },
              { title: "Add", path: "" },
            ]}
          />
          <div className="lineHeight">
            <BackButtonComponent
              name="Back to Role Listing"
              onClick={NavigateToRolePage}
            />
          </div>

          <p className="AddNew"> Add New Role</p>
          <AddEditRole
          // editComponent={true}
          />
        </div>
      </div>
    </>
  );
};


