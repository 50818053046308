import { useState } from "react";
import { TabsComponents } from "../../../CommonComponents/TabsComponents/TabsComponents";
import { Category } from "../Category/Category";
import { NCICCode } from "./NCICCode";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";

export const NCIC: React.FC = () => {
  const [activeTabClone, setactiveTabClone] = useState<number>(0);
  return (
    <>
      <div>
        <div className="CodeTablePage">
          <div>
            <p className="header">NCIC</p>
            <TabsComponents
              activeTab={activeTabClone}
              tabList={[
                { name: "Code", id: 1 },
                { name: "Category", id: 2 },
              ]}
              handleTabChange={setactiveTabClone}
            />

            {activeTabClone == 0 ? (
              <NCICCode />
            ) : activeTabClone == 1 ? (
              <Category calledFromNCIC={true}/>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
