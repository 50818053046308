import { ColDef } from "ag-grid-community";

export const colom = (): ColDef[] => {
  return [
    { field: "Type" },
    { field: "Case" },
    { field: "Model" },
    { field: "Year" },
    { field: "Color" },
  ];
};
