import React, { useEffect, useState } from 'react'
import { ButtonComponents } from '../../../../CommonComponents/Fields/Button/ButtonComponents'
import { VehicleOverviewFunction } from './VehicleOverviewFunction';
import { useParams } from 'react-router-dom';
import { RouteParams, SetFormDataIState } from './VehicleOverviewIState';
import { formatDate, formatMonth } from '../../../../../utils/Helper/Helper';

const VehicleOverviewRegis = () => {

    const VehicleOverview=new VehicleOverviewFunction();
    const [loading, setLoading] = useState<boolean>(false);
    const [incidenId, setIncidentId] = useState<string>("");
    const { vehicleId } = useParams<RouteParams>();
    const [formData, setFormData] = useState<SetFormDataIState>({
        VehicleId: "",
        IncidentId: "",
        License: "",
        State: "",
        Style: "",
        CitationId: "",
        Ncictype: "",
        Ncicmake: "",
        TabYear: null,
        NciccolorPrimary: "",
        NcicmodelId:"",
        NciccolorSecondary: "",
        Vin: "",
        NcicstateId:"",
        CountryName:"",
        Grid:"",
        InsuranceAgencyCodeId: "",
        VehicleCondition: 0,
        GrossWeight: 0,
        RegisteredAddressId: null,
        PlateNumber: "",
        NcicModel:"",
        AdditionalDetails: "",
        OutOfCityRecovery: false,
        OutOfCityRecoveryCityState: "",
        PropertyOffenseId: "",
        NcicpropertyCategoryId: "",
        NcicpropertyCodeId: "",
        NcicvehicleInvolvement: "",
        CommercialVehicleIndicator: false,
        HazardousMaterialsIndicator: false,
        HazardousMaterial: "",
        IsParked: false,
        NoPlateIndicator: false,
        _21dayTempIndicator: false,
        CommercialDriverLicenseIndicator: false,
        VehicleOverWeightMeasure: false,
        PropertyId: "",
        ParticipantId: null,
        VehicleRegistrationId: "",
        NcicauthorityId: "",
        NciccountryId: "",
        Year: 0,
        Status: "",
        Restrictions: "",
        Ncicclass: "",
        ValidDates: "",
        RecovkedPlates: false,
        IsLien: false,
        LienHolder: "",
        LastName: "",
        FirstName: "",
        MiddleName: "",
        BusinessName: "",
        Suffix: "",
        DOB: "",
        IsPrimary: false,
    TabId:"",
// RegisteredOwners:{
//     RegisteredOwnerInformation:{
//         BusinessName:"",
//         DOB:"",
//         FirstName:"",
//         IsLien:false,
//         IsPrimary:false,
//         LastName:"",
//         LienHolder:"",
//         MiddleName:"",
//         Suffix:"",
//     }
// }
       
    });
    
    useEffect(() => {
        VehicleOverview.getVehicleoverviewData( setLoading,incidenId,vehicleId,setFormData);
      
        if (vehicleId) {
          VehicleOverview.getVehicleoverviewData(
            setLoading,
            incidenId,
            vehicleId,
            setFormData
          );
        }
      }, []);
    return (
        <>
            <div className="_vehicle_overview_description_container _vehicle_registration_form_container">
                <div className="_vehicle_overview_upper_title">
                    <div className="_vehicle_overview_upper_left_container">
                        <p>Registration</p>
                    </div>
                </div>

                <div className="_horizontal_line"></div>
                <div className="_vehicle_desc_detail_overview_container">
                    <div className="_vehicle_desc_detail_overview_upper">
                        <div style={{width:"17%"}}>
                            <p className="crime_summary_title">Tab Year</p>
                            <p>{formData?.TabYear}</p>
                        </div>

                       

                        <div>
                            <p className="crime_summary_title" >Valid Dates</p>
                            <p>{formData?.ValidDates ? formatMonth(formData?.ValidDates) : ""}</p>
                        </div>
                    </div>

                    <div className="_vehicle_desc_detail_overview_upper">
                        <div>
                            <p className="registration_ownner_name">Registered Owner Last Name </p>
                            <p>{formData?.LastName}</p>
                        </div>

                        <div>
                            <p className="registration_ownner_name">Registered Owner First Name </p>
                            <p>{formData?.FirstName}</p>
                        </div>
                    </div>

                    <div className="_vehicle_desc_detail_overview_upper">
                        <div>
                            <p className="vehicle_insaurance_title">Insurance Agency </p>
                            <p>{formData?.InsuranceAgencyName}</p>
                        </div>
                    </div>
                </div>

            
            </div>
        </>
    )
}

export default VehicleOverviewRegis