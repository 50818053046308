import { ColDef } from "ag-grid-community";
import { SetTableData } from "./PolicyPermissionTabIProps";
import { CheckBoxComponent } from "../../../../../../CommonComponents/Fields/Checkbox/Checkbox";

export const coloum: (
  tableData: SetTableData[],
  setTableData: (data: SetTableData[]) => void,
  setrolePolicyAddData: (data: SetTableData[]) => void,
  rolePolicyAddData: SetTableData[]
) => ColDef[] = (
  tableData: SetTableData[],
  setTableData: (data: SetTableData[]) => void,
  setrolePolicyAddData: (data: SetTableData[]) => void,
  rolePolicyAddData: SetTableData[]
) => {
  return [
    { field: "Permission" },

    { field: "Description" },

    {
      field: "General",
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.General == "boolean") {
          return (
            <CheckBoxComponent
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = rolePolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, General: !params.data.General }
                          : item
                    );

                    const isPermissionIdPresent = rolePolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...rolePolicyAddData,
                          { ...params.data, General: !params.data.General },
                        ];

                    setrolePolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, General: !params.data.General };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.General}
            />
          );
        }
      },
    },

    {
      field: "Create",
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Create == "boolean") {
          return (
            <CheckBoxComponent
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = rolePolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Create: !params.data.Create }
                          : item
                    );

                    const isPermissionIdPresent = rolePolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...rolePolicyAddData,
                          { ...params.data, Create: !params.data.Create },
                        ];

                    setrolePolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Create: !params.data.Create };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Create}
            />
          );
        }
      },
    },

    {
      field: "Read",
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Read == "boolean") {
          return (
            <CheckBoxComponent
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = rolePolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Read: !params.data.Read }
                          : item
                    );

                    const isPermissionIdPresent = rolePolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...rolePolicyAddData,
                          { ...params.data, Read: !params.data.Read },
                        ];

                    setrolePolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Read: !params.data.Read };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Read}
            />
          );
        }
      },
    },

    {
      field: "Update",
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Update == "boolean") {
          return (
            <CheckBoxComponent
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = rolePolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Update: !params.data.Update }
                          : item
                    );

                    const isPermissionIdPresent = rolePolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...rolePolicyAddData,
                          { ...params.data, Update: !params.data.Update },
                        ];

                    setrolePolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Update: !params.data.Update };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Update}
            />
          );
        }
      },
    },

    {
      field: "Delete",
      cellRenderer: (params: { data: SetTableData }) => {
        if (typeof params.data.Delete == "boolean") {
          return (
            <CheckBoxComponent
              onChangeValue={() => {
                let temp = tableData.map((val) => {
                  if (val.PermissionId == params.data.PermissionId) {
                    const updatedRolePolicyAddData = rolePolicyAddData.map(
                      (item) =>
                        item.PermissionId === params.data.PermissionId
                          ? { ...item, Delete: !params.data.Delete }
                          : item
                    );

                    const isPermissionIdPresent = rolePolicyAddData.some(
                      (item) => item.PermissionId === params.data.PermissionId
                    );

                    const finalRolePolicyAddData = isPermissionIdPresent
                      ? updatedRolePolicyAddData
                      : [
                          ...rolePolicyAddData,
                          { ...params.data, Delete: !params.data.Delete },
                        ];

                    setrolePolicyAddData(finalRolePolicyAddData);

                    return { ...params.data, Delete: !params.data.Delete };
                  } else {
                    return val;
                  }
                });
                setTableData(temp);
              }}
              value={params.data.Delete}
            />
          );
        }
      },
    },
  ];
};
