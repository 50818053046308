import { useEffect, useRef, useState } from "react";
import "./SummaryTab.css";
import {
  SetFormDataIState,
  SummaryTabProps,
  setFormDataValidation,
  setHome_UnitDataIState,
  setRankDataIState,
  setUserTypeDataIState,
  setsuffixIState,
} from "./SummaryTabInterface";

import user from "../../../../../../assets/icons/Frame_5568.svg";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { EditUserManagementIParams } from "../../../EditUserManagement/EditUserManagementI";
import { useNavigate, useParams } from "react-router-dom";
import { SummaryTabFunction } from "./SummaryTabFunction";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";
import { DetailTabFunctions } from "../../../../AgencyManagement/Components/Tabs/DetailTab/DetailTabFunctions";
import { getSateDataIState } from "../../../../AgencyManagement/Components/Tabs/DetailTab/DetailTabInterface";
import { RootState } from "../../../../../../store";
import useDelayedEffect from "../../../../../../hooks/useDelayedEffect";
import { setMultiplePhoneNumberListIState } from "../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumberI";
import { MultiplePhoneNumber } from "../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumber";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const SummaryTab: React.FC<SummaryTabProps> = ({viewUserComponent}) => {
  
  const { Update } = useGetPerticularUserPermission("User");
  
  const detailTabFunctions = new DetailTabFunctions();
  const summaryTabFunction = new SummaryTabFunction();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [edit, setEdit] = useState<boolean>(viewUserComponent ? true : false);
  const [loading, setLoading] = useState<boolean>(false);
  const { userId } = useParams<EditUserManagementIParams>();
  const [RankData, setRankData] = useState<setRankDataIState[]>([]);
  const [Home_Unit, setHome_Unit] = useState<setHome_UnitDataIState[]>([]);
  const [Suffix, setSuffix] = useState<setsuffixIState[]>([]);
  const [EmailAddressCheck, setEmailAddressCheck] = useState<boolean>(false);
  const [stateData, setStateData] = useState<getSateDataIState[]>([]);
  const [base64, setBase64] = useState<string>("");
  const [changeEmail, setchangeEmail] = useState<boolean>(false);
  const [formData, setFormData] = useState<SetFormDataIState>({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Suffix: "",
    PostNumber: "",
    HomeUnit: "",
    Rank: "",
    BadgeNumber: "",
    Emailaddress: "",
    Status: "1",
    UserId: "",
    AgencyId: "",
    ProfileImage: "",
    Streetaddress: "",
    City: "",
    State: "",
    Zipcode: "",
    EmployeeNumber: "",
    Contacts: [
      { ContactMethodCodeId: "", ContactValue: "", id: 1, IsPrimary: false },
    ],
    UserType: "",
  });
  const [formDataValidation, setFormDataValidation] =
    useState<setFormDataValidation>({
      AgencyId: false,
      BadgeNumber: false,
      City: false,
      Emailaddress: false,
      FirstName: false,
      HomeUnit: false,
      LastName: false,
      MiddleName: false,
      PostNumber: false,
      ProfileImage: false,
      Rank: false,
      State: false,
      Status: false,
      Streetaddress: false,
      Suffix: false,
      UserId: false,
      Zipcode: false,
      EmployeeNumber: false,
      UserType: false,
      Contacts: false,
    });

  const editDisable = () => {
    getUserManagemantData();
    dispatch(setchangeTheForm(false));
  };

  const NavigateToEditPage = (id: number) => {
    navigation(`/user/${id}/edit`);
  };

  const allowEdit = () => {
    setEdit(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeimage = () => {
    setBase64("");
  };

  const getMultiplePhoneNumberData = (
    data: setMultiplePhoneNumberListIState[]
  ) => {
    setFormData({
      ...formData,
      Contacts: data,
    });

    setFormDataValidation({
      ...formDataValidation,
      ["Contacts"]: false,
    });
  };

  const onHandelValueChange = (field: string, value: number | string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    setFormDataValidation({
      ...formDataValidation,
      [field]: false,
    });
    dispatch(setchangeTheForm(true));
  };

  const emailValidate = () => {
    if (changeEmail && formData.Emailaddress) {
      summaryTabFunction.EmailAddressValidate(
        setLoading,
        setEmailAddressCheck,
        formData.Emailaddress
      );
    }
  };

  const getUserManagemantData = () => {
    if (userId) {
      summaryTabFunction.getUserManagemantData(
        setLoading,
        userId,
        setFormData,
        setBase64,
        dispatch
      );
    }
  };

  const handleSubmit = async () => {
    summaryTabFunction.errorValidation(formData, setFormDataValidation);
    if (summaryTabFunction.checkRequiredFields(formData, EmailAddressCheck)) {
      if (userId) {
        summaryTabFunction.editUser(setLoading, formData, userId, base64);
        dispatch(setchangeTheForm(false));
      } else if (!userId) {
        summaryTabFunction.addUser(
          setLoading,
          formData,
          base64,
          NavigateToEditPage
        );
        dispatch(setchangeTheForm(false));
      }
    }
  };

  const initialRender = () => {
    getUserManagemantData();
    summaryTabFunction.getRankData(setRankData, setLoading);
    summaryTabFunction.getHome_UnitData(setHome_Unit, setLoading);
    detailTabFunctions.getSateData(setStateData, setLoading);
    summaryTabFunction.getSuffixData(setSuffix, setLoading);
  };

  useEffect(() => {
    initialRender();
  }, []);

  useDelayedEffect(emailValidate, 700, [formData.Emailaddress]);
  return (
    <>
      <Loader loading={loading} />

      <div className="DetailTab_top">
        <div className="DetailTab_top_header_">
          <div className="DetailTab_top_header_sub">
            <p>Detail</p>
            {userId && viewUserComponent && Update ? (
              <p onClick={allowEdit} className="SummaryTab_top_header_edit">
                Edit
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="SummaryTab_contant">
          <div className="SummaryTab_content_left">
            <div className="width_size">
              <div className="SummaryTab_contant_1">
                <DropdownComponent
                  dataSource={[
                    { id: "1", value: "Active" },
                    { id: "2", value: "InActive" },
                  ]}
                  disabled={edit}
                  fieldName="Status*"
                  allowclear={false}
                  value={formData.Status}
                  handleRowClick={(e) => {
                    onHandelValueChange("Status", e);
                  }}
                  showTooltip={false}
                  validation={formDataValidation.Status}
                />
                <TextBoxComponent
                  disabled={userId ? true : false}
                  value={formData.Emailaddress}
                  onChangeValue={(e) => {
                    setchangeEmail(true);
                    onHandelValueChange("Emailaddress", e);
                  }}
                  fieldName="Login name*"
                  validation={formDataValidation.Emailaddress}
                />
                <TextBoxComponent
                  disabled={edit}
                  value={formData.LastName}
                  onChangeValue={(e) => {
                    onHandelValueChange("LastName", e);
                  }}
                  fieldName="Last Name*"
                  validation={formDataValidation.LastName}
                />
                <TextBoxComponent
                  disabled={edit}
                  value={formData.FirstName}
                  onChangeValue={(e) => {
                    onHandelValueChange("FirstName", e);
                  }}
                  fieldName="First Name*"
                  validation={formDataValidation.FirstName}
                />
                <TextBoxComponent
                  disabled={edit}
                  value={formData.MiddleName}
                  onChangeValue={(e) => {
                    onHandelValueChange("MiddleName", e);
                  }}
                  fieldName="Middle Name"
                />
              </div>

              <div className="SummaryTab_contant_Address">
                <div className="SummaryTab_contant_Address_1">
                  <DropdownComponent
                    dataSource={Suffix.map((state) => ({
                      id: state.SystemLevelCodeId,
                      value: state.LongCode,
                    }))}
                    disabled={edit}
                    fieldName="Suffix"
                    validation={formDataValidation.Suffix}
                    value={formData.Suffix}
                    handleRowClick={(e) => {
                      onHandelValueChange("Suffix", e);
                    }}
                  />
                  <TextBoxComponent
                    disabled={edit}
                    value={formData.BadgeNumber}
                    onChangeValue={(e) => {
                      onHandelValueChange("BadgeNumber", e);
                    }}
                    fieldName="Badge Number"
                  />
                  <TextBoxComponent
                    disabled={edit}
                    value={formData.PostNumber}
                    onChangeValue={(e) => {
                      onHandelValueChange("PostNumber", e);
                    }}
                    fieldName="Post Number*"
                    validation={formDataValidation.PostNumber}
                  />

                  <DropdownComponent
                    dataSource={Home_Unit.map((state) => ({
                      id: state.AgencyLevelCodeId,
                      value: state.LongCode,
                    }))}
                    disabled={edit}
                    fieldName="Home Unit*"
                    validation={formDataValidation.HomeUnit}
                    value={formData.HomeUnit}
                    handleRowClick={(e) => {
                      onHandelValueChange("HomeUnit", e);
                    }}
                  />

                  <DropdownComponent
                    dataSource={RankData.map((state) => ({
                      id: state.AgencyLevelCodeId,
                      value: state.LongCode,
                    }))}
                    disabled={edit}
                    handleRowClick={(e) => {
                      onHandelValueChange("Rank", e);
                    }}
                    fieldName="Rank*"
                    value={formData.Rank}
                    validation={formDataValidation.Rank}
                  />
                </div>
              </div>

              <div className="SummaryTab_contant_Address">
                <div className="SummaryTab_contant_Address_1">
                  <TextBoxComponent
                    disabled={edit}
                    onChangeValue={(e) => {
                      onHandelValueChange("EmployeeNumber", e);
                    }}
                    fieldName="Employee Number"
                    value={formData.EmployeeNumber}
                  />
                  <TextBoxComponent
                    disabled={edit}
                    value={formData.Streetaddress}
                    onChangeValue={(e) => {
                      onHandelValueChange("Streetaddress", e);
                    }}
                    fieldName="Street Address*"
                    validation={formDataValidation.Streetaddress}
                  />
                  <TextBoxComponent
                    disabled={edit}
                    value={formData.City}
                    onChangeValue={(e) => {
                      onHandelValueChange("City", e);
                    }}
                    fieldName="City*"
                    validation={formDataValidation.City}
                  />

                  <DropdownComponent
                    dataSource={stateData.map((state) => ({
                      id: state.SystemLevelCodeId,
                      value: state.LongCode,
                    }))}
                    disabled={edit}
                    fieldName="State*"
                    value={formData.State}
                    handleRowClick={(e) => {
                      onHandelValueChange("State", e);
                    }}
                    validation={formDataValidation.State}
                  />
                  <TextBoxComponent
                    disabled={edit}
                    value={formData.Zipcode}
                    onChangeValue={(e) => {
                      onHandelValueChange("Zipcode", e);
                    }}
                    fieldName="Zip code*"
                    validation={formDataValidation.Zipcode}
                  />
                </div>
              </div>

              <div className="SummaryTab_contant_Address">
                <div className="SummaryTab_contant_Address_1">
                  <div style={{ width: "50%" }}>
                    <MultiplePhoneNumber
                      disabled={edit}
                      getMultiplePhoneNumberData={getMultiplePhoneNumberData}
                      value={formData.Contacts}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="profile-image">
              <div>
                <div className="pro-img">
                  <label htmlFor="fileInput">
                    <img className="upload-icon" src={base64 ? base64 : user} />
                  </label>
                </div>

                <input
                  id="fileInput"
                  disabled={edit}
                  type="file"
                  ref={inputRef}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>

              {(!viewUserComponent|| !edit)  &&  <p onClick={removeimage}>Remove</p>}
            </div>
          </div>
        </div>
        <div className="edit-button">
          {!edit && <Submit onCancel={editDisable} onSave={handleSubmit} />}
        </div>
      </div>
    </>
  );
};
