import { useState } from "react";
import { useDispatch } from "react-redux";
import { setchangeTheForm } from "../Redux/ValidateRouteReducer";

interface SaveChangesPopupHook {
  showSaveTheChangesPopup: boolean;
  openPopup: () => void;
  onClose: () => void;
  onOk: () => void;
  setTemp: (data: string | number) => void;
  temp: string | number;
}

const useSaveChangesPopup = (): SaveChangesPopupHook => {
  const [showSaveTheChangesPopup, setShowSaveTheChangesPopup] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [temp, setTemp] = useState<string | number>("");

  const onClose = (): void => {
    setShowSaveTheChangesPopup(false);
  };

  const onOk = (): void => {
    dispatch(setchangeTheForm(false));
    setShowSaveTheChangesPopup(false);
  };

  const openPopup = (): void => {
    setShowSaveTheChangesPopup(true);
  };

  return {
    showSaveTheChangesPopup,
    openPopup,
    onClose,
    onOk,
    setTemp,
    temp,
  };
};

export default useSaveChangesPopup;
