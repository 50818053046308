import React from "react";
import back from "../../../assets/icons/back.svg";
import { BackButtonComponentIProps } from "./BackButtonComponentIProps";
import "./BackButton.css";

export const BackButtonComponent: React.FC<BackButtonComponentIProps> = ({
  name,
  onClick,
}) => {
  return (
    <>
      <div className="BackButton">
        <img src={back} onClick={onClick}/>
        <p
          onClick={onClick}
          className="BackButton-name"
        >
          {name}
        </p>
      </div>
    </>
  );
};
