import React, { useEffect, useState } from "react";
import { coloum } from "./Config";
import { AddressFunction } from "./AddressMainFunction";
import { AddressData, MasterMainIParams } from "../../MasterNameIState";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { useParams } from "react-router-dom";

export const Address: React.FC = () => {
  const addressFunction = new AddressFunction();
  const [tableData, setTableData] = useState<AddressData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const { nameId } = useParams<MasterMainIParams>();
  const getAddress = () => {
    if(nameId){
      addressFunction.getAddressMain(setTableData, setTableTotalRecord,nameId);
    }
   
  };

  useEffect(() => {
    getAddress();
  }, []);
  return (
    <div
      style={{ width: "auto", display: "flex", gap: "20px"}}
    >
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Addresses: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
          <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
         
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
