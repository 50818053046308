import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TextAreaComponent } from "../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  BtnBulletList,
  BtnClearFormatting,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
import { FilterIProps } from "../NarativeListing/Components/FilterIProps";
import { NarrativeListFunction } from "../NarativeListing/NarrativeFunction";
import {
  ApiResponse,
  editNarrative,
  NameTags,
  NarrativeIParams,
  NarrativeResponseData,
  narrativeTypeDropdown,
} from "../NarativeListing/NarrativeIState";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import {
  Archive,
  Car,
  CollapseIcon,
  Expand,
  Location,
  Menu,
  Save,
} from "../../../../../assets";
import { formatDate } from "../../../../../utils/Helper/Helper";
import VehicleDropdown from "../NarativeListing/Components/VehicleDropdown";
import LocationDropdown from "../NarativeListing/Components/LocationDropdown";
import { openNotificationWithIcon } from "../../../../CommonComponents/Toster/Toster";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

const NarrativeOverviewEditContent: React.FC<FilterIProps> = ({
  Type,
  setType,
}) => {
  const { incidentId, narrativeId } = useParams<NarrativeIParams>();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const narrativeFunction = new NarrativeListFunction();
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(0);
  const [version, setVersion] = useState<number>(1);
  const [narrative, setNarrative] = useState<editNarrative>({
    ...(narrativeId !== undefined && { NarrativeId: narrativeId }),
    Title: "",
    IncidentId: incidentId,
    Narrative: "",
    NarrativeTypeCodeId: "",
    Version: 1,
  });
  const [initialData, setInitialData] = useState<editNarrative>({
    ...(narrativeId !== undefined && { NarrativeId: narrativeId }),
    Title: "",
    IncidentId: incidentId,
    Narrative: "",
    NarrativeTypeCodeId: "",
    Version: 1,
  });

  const [autoSavePayload, setAutoSavePayload] = useState<editNarrative>({
    ...(narrativeId !== undefined && { NarrativeId: narrativeId }),
    Title: "",
    IncidentId: incidentId,
    Narrative: "",
    NarrativeTypeCodeId: "a27fe93c-0f0a-4013-8eb9-2bc5d9bc33c7",
    Version: narrativeId !== undefined ? version : 1,
  });
  const [dropdownNumber, setDropdownNumber] = useState<number>(0);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isAutoSave, setIsAutoSave] = useState<boolean>(false);
  const [autoSaveNarrativeId, setAutoSaveNarrativeId] = useState<
    string | undefined
  >("");
  const [loading, setLoading] = useState<boolean>(false);
  const [RaceDataData, setRaceDataData] = useState<narrativeTypeDropdown[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [editorToolbar, setEditorToolbar] = useState<boolean>(false);
  const [nameList, setNameList] = useState<NameTags[]>();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const backButtonHandler = () => {
    navigate(`/incidents/${incidentId}/narratives`);
  };

  const initialRender = () => {
    narrativeFunction.getRankData(setRaceDataData);
  };

  const handleChange = (value: string | undefined, key: string) => {
    setIsDisabled(false);

    if (key === "Narrative") {
      value = value?.replace(/<pre\b[^>]*>/g, "");
      value = value?.replace(/<\/pre>/g, "");
    }
   
    setIsModified(true);
    setNarrative((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const submitNarrativeHandler = async () => {
    let res;
    setIsDisabled(true);

    if (narrative?.Narrative && narrative?.Narrative?.length < 10) {
      openNotificationWithIcon(
        "error",
        "Narrative must contain atleast 10 characters"
      );
      return;
    }
    if (
      !narrative?.NarrativeTypeCodeId &&
      !narrative?.NarrativeTypeCodeId?.length
    ) {
      openNotificationWithIcon("error", "Report type is a required field.");
      return;
    }
    if (!narrative?.Narrative && !narrative?.Narrative?.length) {
      openNotificationWithIcon("error", "Narrative is a required field.");
      return;
    }

    if (narrativeId !== undefined) {
      const payload = { ...narrative, Version: version };
      if (incidentId) {
        res = await narrativeFunction.editNarrativeOverview(
          setLoading,
          payload,
          incidentId
        );
      }
    } else {
      if (autoSaveNarrativeId === "" || count === 0) {
        res = await narrativeFunction.addNarrative(
          setLoading,
          narrative,
          incidentId
        );
        setAutoSaveNarrativeId(res?.Data?.NarrativeId);
      } else {
        const updatedPayload = {
          ...narrative,
          NarrativeId: autoSaveNarrativeId,
          Version: version,
        };
        res = await narrativeFunction?.editNarrativeOverview(
          setLoading,
          updatedPayload,
          incidentId
        );
      }
    }

    setIsDisabled(false);
    if (res?.Success) {
      navigate(`/incidents/${incidentId}/narratives`);
    }
  };
  const prefilledDataHandler = async () => {
    if (incidentId && narrativeId) {
      let res = await narrativeFunction.getNarrativeOverviewData(
        setLoading,
        narrativeId,
        incidentId
      );
      const { NarrativeId, Title, Narrative, Version } = res?.Data;
      setVersion(Version + 1);
      setNarrative({
        NarrativeId: NarrativeId || narrativeId,
        Title: Title || "",
        IncidentId: incidentId,
        Narrative: Narrative || "",
        NarrativeTypeCodeId: "a27fe93c-0f0a-4013-8eb9-2bc5d9bc33c7",
        Version: Version,
      });
      setInitialData({
        NarrativeId: NarrativeId || narrativeId,
        Title: Title || "",
        IncidentId: incidentId,
        Narrative: Narrative || "",
        NarrativeTypeCodeId: "a27fe93c-0f0a-4013-8eb9-2bc5d9bc33c7",
        Version: Version,
      });
    }
  };

  const expandEditorHandler = () => {
    setIsExpanded(!isExpanded);
  };

  const triggerAutoSave = async (updatedNarrative: editNarrative) => {
    if (
      updatedNarrative?.Title &&
      updatedNarrative?.Title?.length > 3 &&
      updatedNarrative?.NarrativeTypeCodeId !== undefined &&
      updatedNarrative?.NarrativeTypeCodeId !== "" &&
      updatedNarrative?.Narrative?.length &&
      updatedNarrative?.Narrative?.length > 10
    ) {
      setIsAutoSave(true);
      let res: ApiResponse<NarrativeResponseData> | undefined;
      if (narrativeId !== undefined) {
        res = await narrativeFunction?.editNarrativeOverview(
          setLoading,
          updatedNarrative,
          incidentId
        );
      } else {
        let autoSaveResponse: ApiResponse<NarrativeResponseData> | undefined;
        if (count === 1 || autoSaveNarrativeId === "") {
          autoSaveResponse = await narrativeFunction?.addNarrative(
            setLoading,
            updatedNarrative,
            incidentId
          );
          setAutoSaveNarrativeId(autoSaveResponse?.Data?.NarrativeId);

          sessionStorage?.setItem(
            "narrativeId",
            autoSaveResponse?.Data?.NarrativeId || ""
          );
        } else {
          const editAutoSavePayload = {
            ...updatedNarrative,
            NarrativeId: autoSaveNarrativeId,
          };
          res = await narrativeFunction?.editNarrativeOverview(
            setLoading,
            editAutoSavePayload,
            incidentId
          );
        }
      }
    }
    setIsAutoSave(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toolbarRef.current &&
      !toolbarRef.current.contains(event.target as Node)
    ) {
      setEditorToolbar(false);
    }
  };

  const placeholderHandler = (namesData: string) => {
    const { Narrative } = narrative;
    if (!Narrative) return;
    const atRateIndex = Narrative.indexOf("@");
    if (atRateIndex === -1) return;
    const beforeAtRateVal = Narrative.slice(0, atRateIndex);
    const afterAtRateVal = Narrative.slice(atRateIndex + 1);
    const appendedNames = `${beforeAtRateVal}&nbsp;<span style="background-color: #F0F6Fe">${namesData}</span> ${afterAtRateVal}`;
    setNarrative((prev) => ({
      ...prev,
      Narrative: appendedNames,
    }));
    setEditorToolbar(false);
  };

  const nameListHandler = async () => {
    const response = await narrativeFunction?.nameListing(incidentId);
    setNameList(response?.Data);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.shiftKey && event.key === "@") {
      setEditorToolbar(true);
      // setCount(0);
    }
  };

  useEffect(() => {
    initialRender();
    nameListHandler();
    if (narrativeId !== undefined) {
      prefilledDataHandler();
    }
    const interval = setInterval(() => {
      setNarrative((prevState) => {
        const updatedNarrative = {
          ...prevState,
          IsAutoSave: true,
          Version: version,
        };
        setAutoSavePayload(updatedNarrative);

        return updatedNarrative;
      });
      setCount((prev) => prev + 1);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isModified) {
      if (narrativeId !== undefined) {
        const editAutosavePayload = { ...autoSavePayload, Version: version };
        triggerAutoSave(editAutosavePayload);
      } else {
        triggerAutoSave(autoSavePayload);
      }
      setIsModified(false);
    }
    // onReloadHandler()
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [count]);
  const lastCrumbTitle = narrativeId !== undefined ? "Edit" : "Add";
  return (
    <>
      <Loader loading={loading} />
      <div className="_narrative_overview_edit_container">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Incidents", path: `/incidents` },
            { title: incidentNumber ? incidentNumber : "", path: "" },
            {
              title: "Narratives",
              path: `/incidents/${incidentId}/narratives`,
            },
            { title: lastCrumbTitle, path: "" },
          ]}
        />
        {!isExpanded && (
          <div className="">
            <BackButtonComponent
              name="Back to narrative"
              onClick={backButtonHandler}
            />

            <div className="_narrative_overview_title">
              <h3>{narrativeId !== undefined ? "Edit " : "Add "}Narrative</h3>
            </div>
          </div>
        )}

        <div className="narrative_filter_container">
          {!isExpanded && (
            <div>
              <div className="_narrative_report_type">
                <DropdownComponent
                  dataSource={RaceDataData?.map((val) => {
                    return {
                      id: val.AgencyLevelCodeId,
                      value: val.LongCode,
                    };
                  })}
                  handleRowClick={(newValue) => {
                    handleChange(newValue, "NarrativeTypeCodeId");
                  }}
                  fieldName="Report Type"
                  value={narrative?.NarrativeTypeCodeId}
                />
              </div>

              <div className="narrative_editable_ttitle">
                <TextBoxComponent
                  value={narrative?.Title ?? ""}
                  fieldName="Title"
                  onChangeValue={(e) => {
                    if (e.length < 100) {
                      handleChange(e, "Title");
                    }
                  }}
                />
              </div>
            </div>
          )}

          <div className="upper_editor_toolbar_container">
            <div className="_leftside_toolbar_container" ref={toolbarRef}>
              <div
                className={
                  dropdownNumber === 0
                    ? "active_addName_btn_container "
                    : "_addName_btn_container"
                }
                onClick={() => {
                  setEditorToolbar(!editorToolbar);
                  setDropdownNumber(0);
                }}
              >
                <span className="narrative_icon">
                  <Menu />
                </span>
                <span>Add Name</span>
              </div>
              <div
                className={
                  dropdownNumber === 1
                    ? "active_addName_btn_container"
                    : "_addName_btn_container"
                }
                onClick={() => {
                  setEditorToolbar(!editorToolbar);
                  setDropdownNumber(1);
                }}
              >
                <span className="narrative_icon">
                  <Car />
                </span>
                <span>Add Vehicle</span>
              </div>
              <div
                className={
                  dropdownNumber === 2
                    ? "active_addName_btn_container"
                    : "_addName_btn_container"
                }
                onClick={() => {
                  setEditorToolbar(!editorToolbar);
                  setDropdownNumber(2);
                }}
              >
                <span className="narrative_icon">
                  <Location />
                </span>
                <span>Add Location</span>
              </div>
              <div
                className={
                  dropdownNumber === 3
                    ? "active_addName_btn_container"
                    : "_addName_btn_container"
                }
                onClick={() => {
                  setDropdownNumber(3);
                  setEditorToolbar(!editorToolbar);
                }}
              >
                <span className="narrative_icon">
                  <Archive />
                </span>
                <span>Add Article</span>
              </div>
              {isAutoSave && (
                <div className="_addName_btn_container">
                  <span className="narrative_icon">
                    <Save />
                  </span>
                  <span>Saving...</span>
                </div>
              )}
            </div>

            <div
              className="_rigthside_toolbar_container"
              onClick={() => expandEditorHandler()}
            >
              {isExpanded ? <CollapseIcon /> : <Expand />}
            </div>
          </div>
          <div className="_narrative_edit_text_editor">
            <EditorProvider>
              <Editor
                value={narrative?.Narrative}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  handleChange(e.target.value, "Narrative");
                }}
              >
                <Toolbar>
                  <BtnBold />
                  <BtnItalic />
                  <BtnBulletList />
                  <BtnClearFormatting />
                  <BtnNumberedList />
                  {/* <BtnRedo /> */}
                  <BtnStrikeThrough />
                  <BtnStyles />
                  <BtnUnderline />
                  <BtnUndo />
                  <BtnRedo />
                  <HtmlButton />
                  <Separator />
                </Toolbar>
              </Editor>
            </EditorProvider>
            {/* lexical editor  */}

            {editorToolbar && dropdownNumber === 0 && (
              <div className="_toolbar_text_container" ref={toolbarRef}>
                <div className="_narrative_name_container">
                  {nameList?.map((names, index) => (
                    <div className="_name_conatiner_list" key={index}>
                      <p
                        className="_name _name_narrative_placeholder"
                        onClick={() =>
                          placeholderHandler(`${
                            names?.FirstName ? names.FirstName + " " : ""
                          }
                                                  ${
                                                    names?.MiddleName
                                                      ? names.MiddleName + " "
                                                      : ""
                                                  }
                                                  ${
                                                    names?.LastName
                                                      ? names.LastName + " "
                                                      : ""
                                                  }
                                                  ${
                                                    names?.CreatedDate
                                                      ? formatDate(
                                                          names.CreatedDate
                                                        ) + " "
                                                      : ""
                                                  }
                                                  ${
                                                    names?.BusinessName
                                                      ? names.BusinessName
                                                      : ""
                                                  }`)
                        }
                      >
                        {`${names?.FirstName ? names.FirstName + " " : ""}
                                                  ${
                                                    names?.MiddleName
                                                      ? names.MiddleName + " "
                                                      : ""
                                                  }
                                                  ${
                                                    names?.LastName
                                                      ? names.LastName + " "
                                                      : ""
                                                  }
                                                  ${
                                                    names?.CreatedDate
                                                      ? formatDate(
                                                          names.CreatedDate
                                                        ) + " "
                                                      : ""
                                                  }
                                                  ${
                                                    names?.BusinessName
                                                      ? names.BusinessName
                                                      : ""
                                                  }`}
                      </p>
                      <ul>
                        <li
                          className="name_list name_list_narrative_placeholder"
                          onClick={() =>
                            placeholderHandler(
                              `${names?.FirstName ? names.FirstName : ""} ${
                                names?.MiddleName ? names?.MiddleName : ""
                              } ${names?.LastName ? names?.LastName : ""}`
                            )
                          }
                        >{`${names?.FirstName ? names.FirstName : ""} ${
                          names?.MiddleName ? names?.MiddleName : ""
                        } ${names?.LastName ? names?.LastName : ""}`}</li>

                        {names.FirstName && (
                          <li
                            className="name_list name_list_narrative_placeholder"
                            onClick={() =>
                              placeholderHandler(`${names?.FirstName}`)
                            }
                          >
                            {names?.FirstName}
                          </li>
                        )}

                        {names.MiddleName && (
                          <li
                            className="name_list name_list_narrative_placeholder"
                            onClick={() =>
                              placeholderHandler(`${names?.MiddleName}`)
                            }
                          >
                            {names?.MiddleName}
                          </li>
                        )}

                        {names.LastName && (
                          <li
                            className="name_list name_list_narrative_placeholder"
                            onClick={() =>
                              placeholderHandler(`${names?.LastName}`)
                            }
                          >
                            {names?.LastName}
                          </li>
                        )}

                        {names.BusinessName && (
                          <li
                            className="name_list name_list_narrative_placeholder"
                            onClick={() =>
                              placeholderHandler(`${names.BusinessName}`)
                            }
                          >
                            {names?.BusinessName}
                          </li>
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {editorToolbar && dropdownNumber === 1 && (
              <div ref={toolbarRef}>
                <VehicleDropdown placeholderHandler={placeholderHandler} />
              </div>
            )}
            {editorToolbar && dropdownNumber === 2 && (
              <div ref={toolbarRef}>
                <LocationDropdown placeholderHandler={placeholderHandler} />
              </div>
            )}
          </div>

          <div className="submit_btn_container">
            <div className="_cancel_btn">
              <ButtonComponents
                name="Cancel"
                borderColor="white"
                handleClick={() => setNarrative(initialData)}
              />
            </div>

            <div className="save_btn">
              <ButtonComponents
                disabled={disabled}
                name="Save"
                showBackgroundColor={true}
                color="#3672B3"
                textColor="white"
                borderColor="#3672B3"
                handleClick={submitNarrativeHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NarrativeOverviewEditContent;
