import { ColDef } from "ag-grid-community";

export const coloum: () => ColDef[] = () => {
  return [
    { field: "Employee" },
    { field: "UnitName" },
    { field: "TimeAssigned" },
    { field: "TimeEnroute" },

    { field: "TimeAtScene" },
    { field: "TimeClearedCall" },
  ];
};
