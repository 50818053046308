import { MultiAliases } from "./MultiAliases";
import { AliasesIProps } from "../../InvolvementMainI";

export const Aliases: React.FC<AliasesIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  return (
    <>
      <div className="alias">
        <MultiAliases Mainfields={Mainfields} setMainfields={setMainfields} />
      </div>
    </>
  );
};
