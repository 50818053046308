import logo_new from "../../../../assets/icons/mini.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useValidateRoutes from "../../../../hooks/useValidateRoutes";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import useSaveChangesPopup from "../../../../hooks/useSaveChangesPopup";
import { SaveTheChangesPopup } from "../../../CommonComponents/Modal/SaveTheChangesPopup/SaveTheChangesPopup";
import {
  Note,
  CourtHouse,
  SecuritySafe,
  Book,
} from "../../../../../src/assets";
import {
  setlowerSidebarDataIstate,
  setnavigationDataIstate,
  submenu,
  SubmenuResponce,
} from "./SidebarExpendI";
import { BackButtonComponent } from "../../../CommonComponents/BackButton/BackButtonComponent";
import { setlowerSidebarData, setnavigationData } from "./Config";
import { useGetAllViewPermission } from "../../../../hooks/Permission/useGetAllViewPermission";
import { SidebarExpendFunction } from "./SidebarExpendFunction";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import { InvolvementMainIParams } from "../../../Pages/Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useDispatch } from "react-redux";

export const SidebarExpend: React.FC = () => {
  const { AGENCY_VIEW, Code_Agency_VIEW, Code_System_VIEW, USER_VIEW } =
    useGetAllViewPermission();
  const { refreshIncidenceMenuList, IncidentId } = useSelector(
    (store: RootState) => store.CommonRedux
  );
  const { incidentId } = useParams<InvolvementMainIParams>();
  const dispatch = useDispatch();
  const sidebarExpendFunction = new SidebarExpendFunction();
  const { showSaveTheChangesPopup, openPopup, onClose, onOk, setTemp, temp } =
    useSaveChangesPopup();
  const { pathname } = useLocation();
  const navigation = useNavigate();
  let ValidateRoutes = useValidateRoutes();
  let lowerSidebarData: setlowerSidebarDataIstate[] = setlowerSidebarData();

  const [setSubMenuList, setsetSubMenuList] = useState<submenu[]>([]);
  const [DynamicSubMenuList, setDynamicSubMenuList] = useState<
    SubmenuResponce[]
  >([]);
  let navigationData: setnavigationDataIstate[] = setnavigationData(
    AGENCY_VIEW,
    Code_Agency_VIEW,
    Code_System_VIEW,
    USER_VIEW,
    DynamicSubMenuList
  );
  const [setSubMenuListshow, setsetSubMenuListshow] = useState<boolean>(false);
  const [sideBarColloapse, setSideBarColloapse] = useState<boolean>(true);
  const [activeItem, setActiveItem] = useState<number>(
    localStorage.getItem("setActiveRouteIcon")
      ? Number(localStorage.getItem("setActiveRouteIcon"))
      : 1
  );
  const [activeItemv2, setactiveItemv2] = useState<number>(0);
  const [activeItemv3, setactiveItemv3] = useState<number>(0);

  const SideBarColloapse = () => {
    setSideBarColloapse(!sideBarColloapse);
  };

  const SubMenuListHide = () => {
    setsetSubMenuListshow(false);
  };

  const NavigateTOPage = (route: string) => {
    if (ValidateRoutes) {
      navigation(route);
    } else {
      openPopup();
      setTemp(route);
    }
  };

  const handleItemClick = (item: setnavigationDataIstate) => {
    if (item.path) {
      NavigateTOPage(item.path);
    }
    setActiveItem(item.id);
    setactiveItemv2(0);
    localStorage.setItem("setActiveRouteIcon", String(item.id));
    if (item.submenu) {
      setsetSubMenuList(item.submenu);
      setactiveItemv3(1);
      if (item.path == "/incidents") {
        setsetSubMenuListshow(false);
      } else {
        navigation(item.submenu[0].path);
        setsetSubMenuListshow(true);
      }
    } else {
      setsetSubMenuList([]);
      setsetSubMenuListshow(false);
    }
  };
  const handleItemClickv2 = (item: setlowerSidebarDataIstate) => {
    if (item.action) {
      SideBarColloapse();
    }
    setactiveItemv2(item.id);
    setActiveItem(0);
  };
  useEffect(() => {
    if (IncidentId) {
      sidebarExpendFunction.getConfigurationData(
        setDynamicSubMenuList,
        IncidentId
      );
    }
  }, [refreshIncidenceMenuList, IncidentId]);

  useEffect(() => {
    sidebarExpendFunction.setincidenceSideBar(
      setsetSubMenuListshow,
      dispatch,
      incidentId,
      setsetSubMenuList,
      DynamicSubMenuList,
      pathname
    );
  }, [pathname, DynamicSubMenuList]);

  return (
    <>
      <ModalComponent
        open={showSaveTheChangesPopup}
        title="Alert"
        innerContant={
          <SaveTheChangesPopup
            onClose={onClose}
            onOk={() => {
              onOk();
              if (typeof temp == "string") {
                navigation(temp);
              }
            }}
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />
      <div className="main">
        <div
          className="main_"
          style={{
            minWidth: !sideBarColloapse ? "230px" : "60px",
            width: !sideBarColloapse ? "100%" : "60px",
            overflow: "hidden",
          }}
        >
          <div className="sidebar-expend">
            <div className="label1">
              <img
                className={`image ${!sideBarColloapse ? "" : "center"}`}
                src={logo_new}
                alt="logo"
              />
              <div
                className={
                  !sideBarColloapse ? "sidebar-text-show" : "sidebar-text-hide"
                }
                style={{ fontWeight: "bold", color: "black" }}
              >
                Company Name
              </div>
            </div>

            {navigationData.map(
              (item, index) =>
                item.title && (
                  <div
                    key={index}
                    onClick={() => handleItemClick(item)}
                    className={`label ${
                      activeItem === item.id ? "active" : ""
                    }`}
                  >
                    <img
                      className={`${
                        activeItem === item.id ? "imgactive" : ""
                      } ${!sideBarColloapse ? "" : "center"}`}
                      src={item.icon}
                      alt={`${item.title} Icon`}
                      title={item.title}
                    />
                    <div
                      className={
                        !sideBarColloapse
                          ? "sidebar-text-show"
                          : "sidebar-text-hide"
                      }
                    >
                      {item.title}
                    </div>
                  </div>
                )
            )}
          </div>

          <div className="lower">
            {lowerSidebarData.map((item, index) => (
              <div
                key={index}
                className={`label ${activeItemv2 === item.id ? "active" : ""}`}
                onClick={() => {
                  handleItemClickv2(item);
                }}
              >
                <img
                  src={item.icon}
                  alt={`${item.title} Icon`}
                  className={`${activeItemv2 === item.id ? "imgactive" : ""} ${
                    !sideBarColloapse ? "" : "center"
                  }`}
                  style={
                    item.title === "Night Mode" ||
                    item.title === "Notifications"
                      ? { width: "25px" }
                      : {}
                  }
                />
                <div
                  className={
                    !sideBarColloapse
                      ? "sidebar-text-show"
                      : "sidebar-text-hide"
                  }
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>

        {setSubMenuListshow ? (
          <div className="sideBarSubList">
            <div className="sideBarSubList_listing">
              <div className="sideBarSubList_listing_">
                <BackButtonComponent onClick={SubMenuListHide} name="Back" />
              </div>
            </div>

            {setSubMenuList.map((setSubMenuListval, index) => (
              <div
                key={index}
                onClick={() => {
                  navigation(setSubMenuListval.path);
                  setactiveItemv3(setSubMenuListval.id);
                }}
                className={`sideBarSubList_listing ${
                  activeItemv3 === setSubMenuListval.id ? "active" : ""
                }`}
              >
                <div className="sideBarSubList_listing_">
                  <p>
                    <span>
                      {" "}
                      {index % 4 === 0 ? (
                        <Note />
                      ) : index % 3 === 0 ? (
                        <Book />
                      ) : index % 2 === 0 ? (
                        <CourtHouse />
                      ) : (
                        <SecuritySafe />
                      )}
                    </span>
                    <span>{setSubMenuListval.title}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
