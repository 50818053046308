import { Fetcher } from "../../../../../../../../services/Fetcher";
import {
  EmergencyTableData,
  ExternalContactIRes,
} from "../ParticipantOverviewIState";

const fetcher = new Fetcher();
export class EmergencyContactFunction {
  async getEmergencyContact(
    participantId: string,
    incidentId: string,
    setTableData: (data: EmergencyTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/emergency-contact`
    );
    let res: ExternalContactIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: ExternalContactIRes[]) {
    return data?.map((val: ExternalContactIRes, i: number) => {
      return {
        Relation: val.RelationName,
        "Last Name": val.LastName,
        "First Name": val.FirstName,
        "Phone Number": val.Contact.Contacts.length
          ? val.Contact.Contacts[i].ContactValue
          : "",
        "Date Entered": "",
      };
    });
  }
}
