import React from "react";
import { NarrativeOverviewDetail } from "../../NarativeListing/NarrativeIState";
import { formatDatewithTime } from "../../../../../../utils/Helper/Helper";

interface NarrativeContentProps {
  overview: NarrativeOverviewDetail | undefined;
}
const NarrativeContent: React.FC<NarrativeContentProps> = ({ overview }) => {
  const userNameHandler = (data: string) => {
    let fullName = data?.split(",");

    return `${fullName?.[0] ? fullName?.[0] : ""} ${
      fullName?.[1] ? fullName?.[1]?.trim() : ""
    }`;
  };
  return (
    <>
      <div className="_narrative_overview_content_container">
        <div className="_narrative_overview_meta_data">
          <div className="_narrative_created_on">
            <p>Created On</p>
            <div className="_narrative_created_on_value">
              <span className="narrative_meta_values">
                {overview?.CreatedDate &&
                  formatDatewithTime(overview?.CreatedDate ?? "")}
              </span>
            </div>
          </div>

          {userNameHandler(`${overview?.CreatedByUser}`) !== undefined && (
            <div className="_narrative_user_submitted">
              <p>User Submitted</p>
              <div className="_narrative_created_on_value">
                <span className="narrative_meta_values">
                  {userNameHandler(`${overview?.CreatedByUser}`)}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="narrative_overview_metadata_value">
          <span className="narrative_meta_values">Title :</span>
          <p>{overview?.Title}</p>
        </div>

        <div className="_narrative_long_content_para_container">
          <div className="_narrative_title_container">
            <span className="narrative_meta_values">Narrative</span>
          </div>
          <div className="_public_narrative_content">
            <p
              dangerouslySetInnerHTML={{ __html: overview?.Narrative ?? "" }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NarrativeContent;
