import { Fetcher } from "../../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../../utils/Helper/Helper";
import { IdentificationTableData } from "../ParticipantOverviewIState";
import { IdentificationsIRes } from "./IdentificationsI";
const fetcher = new Fetcher();
export class IdentificationsFunction {
  async getIdentifications(
    participantId: string,
    incidentId: string,
    setTableData: (data: IdentificationTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
       `/incident/${incidentId}/participant/${participantId}/summary-identification`
    );
    let res: IdentificationsIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: IdentificationsIRes[]) {
    return data?.map((val: IdentificationsIRes, i: number) => {
      return {
        Type: val.IDentificationCode,
        Number: val?.PrimaryIdentification ? val?.PrimaryIdentification : "",
        Valid: formatDate(val.EffectiveEndDate),
      };
    });
  }
}
