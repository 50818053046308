import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { ModalComponent } from "../../../../CommonComponents/Modal/ModalComponent";
import { Table } from "../../../../CommonComponents/Table/Table";
import { Filter } from "./Components/Filter";
import { coloum } from "./Config";
import { SetTableData } from "./AddNewCaseIState";
import { Submit } from "../../../../CommonComponents/Fields/Submit/Submit";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import LocationMetaData from "../../Location/LocationMetaData";
import { CaseOverview } from "../CaseOverview/CaseOverview";
import { CasesListFunction } from "../CasesFunctions";
import {
  IncidentInternalFilterProps,
  RelatedIncident,
  setInternalTableIncidentList,
} from "../CasesListing/CaseIState";
import { NarrativeIParams } from "../../Narratives/NarativeListing/NarrativeIState";

export const AddNewCase: React.FC = () => {
  const [width, setwidth] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<setInternalTableIncidentList[]>(
    []
  );
  const [FiltertableData, setFiltertableData] = useState<RelatedIncident[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [showAddresPopup, setshowAddresPopup] = useState<boolean>(false);
  const [showAddresPopupId, setshowAddresPopupId] = useState<string | null>(
    null
  );
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Last Name");
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");
  const [Date, setDate] = useState<string>("");
  const navigation = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [selectedRowData, setSelectedRowData] = useState<SetTableData | null>(
    null
  );
  const [selectedRow, setSelectedRow] = useState<IncidentInternalFilterProps>();
  const CasesListFunctionInstance = new CasesListFunction();
  const { incidentId } = useParams<NarrativeIParams>();
  const edit = (e: SetTableData) => {};

  const onsave = () => {
    if (selectedRow && incidentId) {
      CasesListFunctionInstance?.postRelatedIncidents(
        setLoading,
        selectedRow,
        incidentId
      );
    }
  };

  const oncancel = () => {};

  const NavigateToCaseList = () => {
    navigation("/incidents/related-cases");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
  };

  const onRowClicked = (data: SetTableData) => {
    setSelectedRowData(data);
  };

  const getInternalIncidentListHandler = async () => {
    if (incidentId) {
      await CasesListFunctionInstance?.relatedIncidentsList(
        setLoading,
        setTableData,
        pageNumber,
        pageCount,
        setTableTotalRecord,
        incidentId,
        startDate,
        endDate
      );

      await CasesListFunctionInstance?.getRelatedIncident(
        setLoading,
        incidentId,
        setFiltertableData
      );
    }
  };

  const selectRowCheckBox = (
    data: { [key: string]: string | number | boolean | null }[]
  ) => {
    if (incidentId) {
      CasesListFunctionInstance?.selectRowCheckBox(
        FiltertableData,
        data,
        setFiltertableData,
        tableData,
        setSelectedRow,
        incidentId
      );
    }
  };
  useEffect(() => {
    getInternalIncidentListHandler();
  }, []);
  return (
    <>
      <Loader loading={loading} />
      <ModalComponent
        onClose={handleCloseModal}
        open={isModalOpen}
        innerContant={
          <div>
            <CaseOverview />
          </div>
        }
        closeIconStyle={{
          backgroundColor: "#ffd9d9",
          border: "2px solid #ffd9d9",
          width: "30px",
          height: "30px",
        }}
        style={{ padding: "30px" }}
      />

      <div className="_incident_location_container_fluid">
        <div className="_location_left_container">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="UserManagement _location_right_container">
          <div className="_user_management_container">
            <BackButtonComponent
              name="Back to Case details"
              onClick={NavigateToCaseList}
            />
            <div>
              <div style={{ paddingLeft: "5px" }}>
                <p className="header" style={{ lineHeight: "5px" }}>
                  Related Incidents {""} 1285743
                </p>
                <p className="header">Add a Related Case</p>
              </div>

              <Filter
                Name={Name}
                setName={setName}
                setType={setType}
                Type={Type}
                Date={Date}
                setDate={setDate}
              />
              <div className="table">
                <Table
                  columns={coloum(
                    setdeletePopup,
                    setDeleteUserId,
                    edit,
                    setshowAddresPopup,
                    setshowAddresPopupId,
                    handleOpenModal
                  )}
                  columnsValue={tableData}
                  cursorPointer={true}
                  setpageCount={setpageCount}
                  setpageNumber={setpageNumber}
                  onRowClicked={onRowClicked}
                  tableTotalRecord={tableTotalRecord}
                  setOrderByDescending={setOrderByDescending}
                  OrderByDescending={OrderByDescending}
                  setOrderBy={setOrderBy}
                  OrderBy={OrderBy}
                  headerBackgroundColor="#e7e7e7"
                  allowMultipleRowSelection={true}
                  clientSideSorting={true}
                  selectRowCheckBox={selectRowCheckBox}
                />
              </div>
              <div className="edit-button">
                <Submit
                  onCancel={oncancel}
                  onSave={onsave}
                  saveColor="#3b72b1"
                  saveLabel=" Add "
                  cancelColor="Clear"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
