import { Fetcher } from "../../../../../../../../services/Fetcher";
import { parseISOToDateTime } from "../../../../../../../../utils/Helper/Helper";
import {
  DetainmentIRes,
  DetainmentTableData,
} from "../ParticipantOverviewIState";

const fetcher = new Fetcher();

export class DetainmentFunction {
  async getDetainment(
    participantId: string,
    incidentId: string,
    setTableData: (data: DetainmentTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/detainment`
    );

    let res: DetainmentIRes[] = response.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(res.length);
    setTableData(TableJson);
  }

  createTableJson(data: DetainmentIRes[]) {
    return data?.map((val: DetainmentIRes, i: number) => {
      let DetainmentDateTime = parseISOToDateTime(val.DetainmentDateTime);
      let ReleaseDateTime = parseISOToDateTime(val.ReleaseDateTime);

      return {
        "Detainment Date": DetainmentDateTime.date,
        "Detainment Time": DetainmentDateTime.time,
        "Release Date": ReleaseDateTime.date,
        "Release Time": ReleaseDateTime.time,
        "Jail ORI": val.JailORI,
      };
    });
  }
}
