import { useEffect, useState } from "react";
import {
  GetRaceDataIState,
  PhoneNumberDataUIProps,
  PhoneNumberIProps,
  setContactTypeDataIState,
} from "../../ExternalContactI";
import { InvolvementMainFunction } from "../../../Involvement/InvolvementMainFunction";
import { PhoneNumberFunction } from "./PhoneNumberFunction";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { MaskedInputComponent } from "../../../../../../../../CommonComponents/Fields/MaskedInput/MaskedInputComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { Add } from "../../../../../../../../../assets";

export const PhoneNumberDataUI: React.FC<PhoneNumberDataUIProps> = ({
  Mainfields,
  setMainfields,
  PhoneNumberval,
  addressval,
  Participantionvalidation,
}) => {
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const involvementMainFunction = new InvolvementMainFunction();
  const phoneNumberFunction = new PhoneNumberFunction();
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (val.id == addressval.id) {
            return {
              ...val,
              onChange: true,
              PhoneNumber: val.PhoneNumber.map((val) => {
                if (val.id == id) {
                  if (val.id == id) {
                    if (key2) {
                      return { ...val, [key]: value, [key2]: value2 };
                    } else {
                      return { ...val, [key]: value };
                    }
                  } else {
                    return val;
                  }
                } else {
                  return val;
                }
              }),
            };
          } else {
            return val;
          }
        }
      ),
    });
  };

  const initialRender = () => {
    involvementMainFunction.getRaceData(setRaceDataData);
    phoneNumberFunction.getContactTypeData(setContactTypeData);
    involvementMainFunction.getCountry(setCountry);
  };

  useEffect(() => {
    initialRender();
  }, []);

  const remove = (id: number) => {
    if (addressval.PhoneNumber.length > 1) {
      setMainfields({
        ...Mainfields,
        MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
          (val) => {
            if (val.id === addressval.id) {
              return {
                ...val,
                onChange: true,
                PhoneNumber: val.PhoneNumber.filter(
                  (PhoneNumber) => PhoneNumber.id !== id
                ),
              };
            } else {
              return val;
            }
          }
        ),
      });
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="Involvement_left_row" style={{ width: "100%" }}>
          <DropdownComponent
            dataSource={ContactTypeData.map((val) => {
              return {
                id: val.SystemLevelCodeId,
                value: val.LongCode,
              };
            })}
            value={PhoneNumberval.Type}
            handleRowClick={(e, val2) => {
              onChangeValue(
                "Type",
                e,
                PhoneNumberval.id,
                "TypeDropdownvalue",
                val2
              );
            }}
            fieldName="Type"
            validation={Participantionvalidation.EmergencyContact.Phone}
          />

          <DropdownComponent
            dataSource={Country.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
            value={PhoneNumberval.Country}
            handleRowClick={(e) => {
              onChangeValue("Country", e, PhoneNumberval.id);
            }}
            fieldName="Country"
          />
          <MaskedInputComponent
            mask="+1 (999)-999-9999"
            value={PhoneNumberval.Number}
            fieldName="Number"
            onChangeValue={(e) => {
              onChangeValue("Number", e, PhoneNumberval.id);
            }}
            validation={Participantionvalidation.EmergencyContact.Phone}
          />
          <TextBoxComponent
            value={PhoneNumberval.Ext}
            fieldName="Ext."
            onChangeValue={(e) => {
              onChangeValue("Ext", e, PhoneNumberval.id);
            }}
          />
          {addressval.PhoneNumber.length > 1 && (
            <p
              className="removeIcon"
              onClick={() => {
                remove(PhoneNumberval.id);
              }}
              title="Remove"
            >
              -
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export const PhoneNumber: React.FC<PhoneNumberIProps> = ({
  Mainfields,
  addressval,
  setMainfields,
  Participantionvalidation,
}) => {
  const handleClick = () => {
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (val.id == addressval.id) {
            return {
              ...val,
              PhoneNumber: [
                ...val.PhoneNumber,
                {
                  id: val.PhoneNumber.length + 1,
                  Type: "",
                  ExternalContactID: "",
                  Country: "",
                  Number: "",
                  Ext: "",
                  TypeDropdownvalue: "",
                  onChange: true,
                },
              ],
            };
          } else {
            return val;
          }
        }
      ),
    });
  };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_">Phone Number</p>
            {addressval.PhoneNumber.map((PhoneNumberval) => {
              return (
                <>
                  <PhoneNumberDataUI
                    Mainfields={Mainfields}
                    setMainfields={setMainfields}
                    PhoneNumberval={PhoneNumberval}
                    addressval={addressval}
                    Participantionvalidation={Participantionvalidation}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="edit-button_" style={{ marginTop: "5%" }}>
          <ButtonComponents
            name="Add Phone Number"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
