import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "../Components/Layout/Layout/Layout";
import NotFound from "../Components/Pages/NotFound/NotFound";
import { RouteListing } from "./Config";
import ErrorPage from "../Components/Pages/Error/Error";

export const Router = () => {
  let routeListing = RouteListing();

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Navigate to={"/incidents"} />} />
          {routeListing.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path="/error/:statusCode" element={<ErrorPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
