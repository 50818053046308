import delete_icon from "../../../../../assets/icons/delete.svg";
import edit_icon from "../../../../../assets/icons/edit.svg";
import { ColDef } from "ag-grid-community";
import { SetTableData } from "./ArticlesIState";

export const coloum = (
  edit: (id: SetTableData) => void,
): ColDef[] => {
  return [
    { field: "Article Type" },
    { field: "Brand" },
    { field: "Model" },
    { field: "Color" },
    { field: "Description" },
    { field: "Value" },
    { field: "Stolen Date" },
    { field: "Recovery Date" },
    { field: "Created On" },
    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {
              <img
                className="table-cellRenderer-img"
                src={edit_icon}
                title="Edit"
                
              />
            }
            {
              <img
                title="Delete"
                className="table-cellRenderer-img"
                src={delete_icon}
                alt="Delete"
               
              />
            }
          </div>
        );
      },
    },
  ];
};
