import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI } from "./IncidentListI";

export const OrderByFieldName: OrderByFieldNameI = {
  "Incident Number": "IncidentNumber",
  "Report Date": "ReportDateTime",
  "Modified On": "ModifiedOn",
};

export const coloum: () => ColDef[] = () => {
  return [
    { field: "Incident Number" },
    { field: "Report Date" },
    { field: "Modified On" },
  ];
};
