import React, { useEffect, useState } from "react";
import { coloum } from "./Config";
import { InvolvementData, MasterMainIParams } from "../../MasterNameIState";
import { Table } from "../../../../../CommonComponents/Table/Table";
import { InvolvementMainFunction } from "./InvolvementFunction";
import { useParams } from "react-router-dom";

export const Involvement: React.FC = () => {
  const [tableData, setTableData] = useState<InvolvementData[]>([]);
  const involvementFunction = new InvolvementMainFunction();
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const { nameId } = useParams<MasterMainIParams>();
  const getInvolvement= () => {
    if(nameId){
      involvementFunction.getInvolvementMain(
        setTableData,
        setTableTotalRecord,nameId
      );
    }
   
    
  };

  useEffect(() => {
    getInvolvement();
  }, []);
  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Involvements: {tableData.length}</strong>
          </p>
          <p className="DetailTab_top_">
           <strong>Total Records: {tableData.length}</strong>
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
           
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
