import delete_icon from "../../../../../assets/icons/delete.svg";
import edit_icon from "../../../../../assets/icons/edit.svg";
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, SetTableData } from "./CaseIState";

export const OrderByFieldName: OrderByFieldNameI = {
  Case:"Case",
  Date:"Date",
  Agency:"Agency",
  Type:"Type"
};

export const coloum = (
  setdeletePopup: (data: boolean) => void,
  setDeleteExternalCaseId: (id: string) => void,
  edit: (id: SetTableData) => void,
  setshowAddresPopup: (data: boolean) => void,
  setshowAddresPopupId: (id: string) => void,
  setIsExternal:(data:boolean)=>void
): ColDef[] => {
  return [
    { field: "Case" },
    { field: "Date" },
    { field: "Type" },
    { field: "Agency" },

    {
      field: "Action",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            {
              <img
                className="table-cellRenderer-img"
                src={edit_icon}
                title="Edit"
              />
            }
            {
              <img
                title="Delete"
                className="table-cellRenderer-img"
                src={delete_icon}
                alt="Delete"
                onClick={() => {
                  setdeletePopup(true);
                  setDeleteExternalCaseId(e.data.RelatedIncidentId);
                  setIsExternal(e.data?.IsExternal);
                }}
              />
            }
          </div>
        );
      },
    },
  ];
};
