import React, { useEffect, useState } from "react";
import { TabsComponents } from "../../../../CommonComponents/TabsComponents/TabsComponents";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import VehicleForm from "./Vehicle/VehicleForm";
import RecoveryForm from "./Recovery/RecoveryForm";
import TowForm from "./Tow/TowForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LocationMetaData from "../../Location/LocationMetaData";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { setIncidentId } from "../../../../../Redux/CommonRedux";
import { useDispatch } from "react-redux";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

const VehicleCategory: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();  
  const [activeTab, setActiveTab] = useState<number>(0);
  const backButtonHandler = () => {
    navigation(`/incidents/${incidentId}/vehicles`);
  };
  const navigation = useNavigate();
  const { incidentId ,vehicleId} = useParams<InvolvementMainIParams>();
  const [width, setwidth] = useState<number>(10);
  let incidentNumber = localStorage.getItem("Incidentnumber");
 

  const isEditMode = location.pathname.includes('/edit');
  const title = isEditMode ? 'Edit a Vehicle' : 'Add a Vehicle';
   const lastBreadcrumbTitle = isEditMode ? 'Edit' : 'Add';
  return (
    <>
      <div className="_incident_location_container_fluid">
        <div className="_location_left_container">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>
        <div className="_location_right_container">
        <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path:  `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              { title: "Vehicles", path:  `/incidents/${incidentId}/vehicles` },
               { title: lastBreadcrumbTitle, path: "" },
            ]}
          />
          <div className="_back_btn">
            <BackButtonComponent
              name="Back to vehicles"
              onClick={backButtonHandler}
            />
          </div>
          <div className="_add_vehicle_form_title">
            <h3>{title}</h3>
          </div>


          


          <div style={{ backgroundColor: "#B3C9E2" }}>
            <TabsComponents
              activeTab={activeTab}
              tabList={[
                {
                  name: "Vehicle",
                  id: 1,
                  disable:false,
                },
                {
                  name: "Tow",
                  id: 1,
                  disable:vehicleId ? false :true,
                },
                {
                  name: "Recovery",
                  id: 2,
                  disable:vehicleId ? false :true,
                },
              ]}
              handleTabChange={setActiveTab}
            />
          </div>
          {activeTab === 0 && <VehicleForm />}
          {activeTab === 1 && <TowForm />}
          {activeTab === 2 && <RecoveryForm />}
        </div>
      </div>
    </>
  );
};

export default VehicleCategory;
