import React, { useEffect, useState } from "react";
import { SubstanceTableData } from "../ParticipantOverviewIState";
import { coloum } from "./Config";
import { Table } from "../../../../../../../CommonComponents/Table/Table";
import { useNavigate, useParams } from "react-router-dom";
import { SubstanceFunction } from "./SubstanceFunction";
import { InvolvementMainIParams } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";

export const Substance: React.FC = () => {
  const substanceFunction = new SubstanceFunction();
  const [tableData, setTableData] = useState<SubstanceTableData[]>([]);
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const navigation=useNavigate()

  const getContact = () => {
    if (participantId && incidentId) {
      substanceFunction.getSubstance(
        participantId,
        incidentId,
        setTableData,
        setTableTotalRecord
      );
    }
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <div style={{ width: "auto", display: "flex", gap: "20px" }}>
      <div className="overview-container">
        <div className="overview-header">
          <p>
            <strong>Substance Involvement</strong>
          </p>
          <p className="DetailTab_top_">
            <span className="total-records">
              <strong>Total Records:{tableData.length}</strong>
            </span>
            <span onClick={()=>{
            navigation(`/incidents/${incidentId}/participants/${participantId}/edit`)
           }} className="edit" style={{ cursor: "pointer" }} >
              <strong>Edit</strong>
            </span>
            {/* <span className="view">
              <strong>View</strong>
            </span> */}
          </p>
        </div>
        <Table
          columns={coloum()}
          columnsValue={tableData}
          cursorPointer={true}
          
           
          headerBackgroundColor="white"
        />
      </div>
    </div>
  );
};
