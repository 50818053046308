import './Sidebar.css';
import { SidebarExpend } from './Components/SidebarExpend';


export const Sidebar: React.FC = () => {
    return (
        <div>
            <SidebarExpend />
        </div>
    )
}