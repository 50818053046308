import { Fetcher } from "../../../../../../services/Fetcher";
import {
  convertToISOFormat,
  formatDate,
} from "../../../../../../utils/Helper/Helper";
import {
  successCreatedMessage,
  successUpdateMessage,
} from "../../../../../../utils/const";
import {
  SetFormDataIState,
  codecategory,
  setagencyData,
  setvalidationIState,
} from "./AgencyLevelAddEditViewI";

interface getallcodecategory {
  CodeCategoryId: string;
  AppModule: string;
  CategoryName: string;
  IsActive: boolean;
  CategoryType: string;
  ActiveDate: string;
  ExpiryDate: string;
  Description: string;
  DisplayOrder: number;
}

interface getallcodeagency {
  AgencyId: number;
  AgencyName: string;
}

const fetcher = new Fetcher();
export class AgencyLevelAddEditViewFunction {
  async getallcodecategory(
    setcategoryData: (data: codecategory[]) => void,
    setLoading?: (data: boolean) => void,
    agencyId?: string | undefined
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      `/category/agency${agencyId ? `/${agencyId}` : ""}`
    );
    setcategoryData(
      response.Data?.map((val: getallcodecategory) => {
        return {
          CodeCategoryId: val.CodeCategoryId,
          CategoryName: val.CategoryName,
        };
      })
    );

    if (setLoading) {
      setLoading(false);
    }
  }

  async getallcodeagency(
    setagencyData: (data: setagencyData[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/agency/agencyList");
    setagencyData(
      response.Data?.map((val: getallcodeagency) => {
        return {
          CodeCategoryId: val.AgencyId,
          CategoryName: val.AgencyName,
        };
      })
    );

    if (setLoading) {
      setLoading(false);
    }
  }

  async add(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    setshowAddEditView: (data: boolean) => void,
    SaveandAddNewinSameCategorycheck?: boolean
  ) {
    setLoading(true);
    const response = await fetcher.post(
      `/code/agency`,
      {
        CodeCategoryId: formData.Category,
        ShortCode: formData.ShortCode,
        LongCode: formData.LongCode,
        Description: formData.Description,
        DisplayOrder: 0,
        IsActive: formData.IsActive == "1" ? true : false,
        ActiveDate: convertToISOFormat(formData.ActiveDate),
        ExpiryDate: formData.ExpiryDate
          ? convertToISOFormat(formData.ExpiryDate)
          : null,
        IsGlobal: true,
        AgencyId: formData.Agency,
      },
      {
        message: successCreatedMessage,
        show: true,
      }
    );
    setLoading(false);
    if (
      response?.Data?.AgencyLevelCodeId &&
      !SaveandAddNewinSameCategorycheck
    ) {
      setshowAddEditView(false);
    }
  }

  async edit(
    setLoading: (data: boolean) => void,
    formData: SetFormDataIState,
    setshowAddEditView: (data: boolean) => void,
    getRowId: string
  ) {
    setLoading(true);
    const response = await fetcher.put(
      `/code/Agency`,
      {
        AgencyLevelCodeId: getRowId,
        CodeCategoryId: formData.Category,
        ShortCode: formData.ShortCode,
        LongCode: formData.LongCode,
        Description: formData.Description,
        DisplayOrder: 0,
        AgencyId: formData.Agency,
        Status: formData.IsActive == "1" ? true : false,
        ActiveDate: convertToISOFormat(formData.ActiveDate),
        ExpiryDate: formData.ExpiryDate
          ? convertToISOFormat(formData.ExpiryDate)
          : null,
      },
      {
        message: successUpdateMessage,
        show: true,
      }
    );
    setLoading(false);
    if (response?.Data?.AgencyLevelCodeId) {
      setshowAddEditView(false);
    }
  }

  errorValidation(
    formData: SetFormDataIState,
    setvalidation: (data: setvalidationIState) => void
  ) {
    let valdationerror: setvalidationIState = {
      ActiveDate: false,
      Agency: false,
      Category: false,
      Description: false,
      ExpiryDate: false,
      IsActive: false,
      LongCode: false,
      ShortCode: false,
    };

    if (!formData.Agency) {
      valdationerror.Agency = true;
    } else {
      valdationerror.Agency = false;
    }
    if (!formData.Category) {
      valdationerror.Category = true;
    } else {
      valdationerror.Category = false;
    }
    if (!formData.Description) {
      valdationerror.Description = true;
    } else {
      valdationerror.Description = false;
    }

    if (!formData.IsActive) {
      valdationerror.IsActive = true;
    } else {
      valdationerror.IsActive = false;
    }

    if (!formData.LongCode) {
      valdationerror.LongCode = true;
    } else {
      valdationerror.LongCode = false;
    }

    if (!formData.ShortCode) {
      valdationerror.ShortCode = true;
    } else {
      valdationerror.ShortCode = false;
    }

    setvalidation({ ...valdationerror });
  }

  checkRequiredFields(formData: SetFormDataIState) {
    return (
      formData.Agency &&
      formData.Category &&
      formData.Description &&
      formData.IsActive &&
      formData.LongCode &&
      formData.ShortCode
    );
  }

  async getAgencyLevelData(
    setLoading: (data: boolean) => void,
    setFormData: (data: SetFormDataIState) => void,
    agencyLevelCodeID: string,
    addprefill?: boolean
  ) {
    setLoading(true);
    let responce = await fetcher.get(`/code/${agencyLevelCodeID}/agency`);

    let {
      CodeCategoryId,
      ShortCode,
      LongCode,
      Description,
      IsActive,
      ActiveDate,
      ExpiryDate,
      AgencyId,
    } = responce?.Data;

    setFormData({
      ActiveDate: formatDate(ActiveDate),
      ExpiryDate: formatDate(ExpiryDate),
      Category: CodeCategoryId,
      Description: addprefill ? "" : Description,
      IsActive: IsActive ? "1" : "2",
      LongCode: addprefill ? "" : LongCode,
      ShortCode: addprefill ? "" : ShortCode,
      Agency: AgencyId,
    });

    setLoading(false);
  }
}
