import { Fetcher } from "../../../../../../../../../services/Fetcher";
import {
  InvolvementMainIState,
  MultipleEmergencyContactIState,
} from "../../../Involvement/InvolvementMainI";

const fetcher = new Fetcher();
export class MultipleEmergencyContactFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: MultipleEmergencyContactIState[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: MultipleEmergencyContactIState[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.MultipleEmergencyContact);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      MultipleEmergencyContact: [
        ...this.Mainfields.MultipleEmergencyContact.map((val) => {
          return {
            ...val,
            push: true,
          };
        }),
        {
          Address: {
            AddressData: [
              {
                AddressType: "",
                Apartment: "",
                City: "",
                Country: "",
                GridNumber: "",
                id: 1,
                StateProvidence: "",
                Street: "",
                StreetNumber: "",
                StreetPrefix: "",
                StreetSuffix: "",
                ZipCode: "",
                AddressLine1: "",
                AddressLine2: "",
                ForeignAddressCity: "",
                ForeignAddressStateProvinceRegion: "",
                ForeignAddressZip: "",
                ForeignAddressCountry: "",
                ForeignAddress: false,
                CountryDropdownValue: "",
                StateProvidenceDropdownvalue: "",
              },
            ],
            NoPermanentAddress: false,
            UnknownAddress: false,
          },
          EmergencyContact: {
            ExternalContactID: "",
            Relation: "",
            LastName: "",
            Firstname: "",
            Middlename: "",
            Suffix: "",
          },
          PhoneNumber: [
            {
              Country: "",
              Ext: "",
              ExternalContactID: "",
              id: 1,
              Number: "",
              Type: "",
              TypeDropdownvalue: "",
            },
          ],
          Email: [
            {
              Email: "",
              ExternalContactID: "",
              TypeDropdownvalue: "",
              id: 1,
              Type: "",
            },
          ],
          id: this.Mainfields.MultipleEmergencyContact.length + 1,
          ExternalContactID: "",
          push: false,
          onChange: true,
        },
      ],
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.MultipleEmergencyContact);
  };

  DeletePreview = (id: number) => {
    console.log(this.Mainfields.MultipleEmergencyContact);
    this.setMainfields({
      ...this.Mainfields,
      MultipleEmergencyContact: this.Mainfields.MultipleEmergencyContact.filter(
        (AddressDataval) => {
          if (id != AddressDataval.id) {
            return AddressDataval;
          }
        }
      ).length
        ? this.Mainfields.MultipleEmergencyContact.filter((AddressDataval) => {
            if (id != AddressDataval.id) {
              return { ...AddressDataval, onChange: true };
            }
          }).map((val) => {
            return { ...val, onChange: true };
          })
        : [
            {
              Address: {
                AddressData: [
                  {
                    AddressType: "",
                    Apartment: "",
                    City: "",
                    Country: "",
                    GridNumber: "",
                    id: 1,
                    StateProvidence: "",
                    Street: "",
                    StreetNumber: "",
                    StreetPrefix: "",
                    StreetSuffix: "",
                    ZipCode: "",
                    AddressLine1: "",
                    AddressLine2: "",
                    ForeignAddressCity: "",
                    ForeignAddressStateProvinceRegion: "",
                    ForeignAddressZip: "",
                    ForeignAddressCountry: "",
                    ForeignAddress: false,
                    CountryDropdownValue: "",
                    StateProvidenceDropdownvalue: "",
                  },
                ],
                NoPermanentAddress: false,
                UnknownAddress: false,
              },
              EmergencyContact: {
                ExternalContactID: "",
                Relation: "",
                LastName: "",
                Firstname: "",
                Middlename: "",
                Suffix: "",
              },
              PhoneNumber: [
                {
                  Country: "",
                  Ext: "",
                  ExternalContactID: "",
                  id: 1,
                  Number: "",
                  Type: "",
                  TypeDropdownvalue: "",
                },
              ],
              Email: [
                {
                  Email: "",
                  ExternalContactID: "",
                  TypeDropdownvalue: "",
                  id: 1,
                  Type: "",
                },
              ],
              id: 1,
              ExternalContactID: "",
              push: false,
              onChange: true,
            },
          ],
    });

    this.setedit(false);
  };
}
