import { useEffect, useState } from "react";
import {
  EmailAddressDataUIProps,
  EmailAddressIProps,
  setContactTypeDataIState,
} from "../../ExternalContactI";
import { PhoneNumberFunction } from "../Phone/PhoneNumberFunction";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../../../../assets";

export const EmailAddressDataUI: React.FC<EmailAddressDataUIProps> = ({
  EmailAddressval,
  Mainfields,
  addressval,
  setMainfields,
  Participantionvalidation,
}) => {
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const phoneNumberFunction = new PhoneNumberFunction();
  const onChangeValue = (
    key: string,
    value: string,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    if (key === "Email" && !validateEmail(value)) {
      setValidationMessage("Please enter a valid email address");
    } else {
      setValidationMessage("");
    }
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (val.id == addressval.id) {
            return {
              ...val,
              onChange: true,
              Email: val.Email.map((val) => {
                if (val.id == id) {
                  if (key2) {
                    return { ...val, [key]: value, [key2]: value2 };
                  } else {
                    return { ...val, [key]: value };
                  }
                } else {
                  return val;
                }
              }),
            };
          } else {
            return val;
          }
        }
      ),
    });
  };

  const initialRender = () => {
    phoneNumberFunction.getContactTypeData(setContactTypeData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  const remove = (id: number) => {
    if (addressval.Email.length > 1) {
      setMainfields({
        ...Mainfields,
        MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
          (contact) => {
            if (contact.id === addressval.id) {
              return {
                ...contact,
                onChange: true,
                Email: contact.Email.filter((address) => address.id !== id),
              };
            }
            return contact;
          }
        ),
      });
    }
  };
  return (
    <>
      <div className="Email_address_components">
        <div className="Involvement_main">
          <div className="Involvement_left">
            <div className="Involvement_left_row">
              <DropdownComponent
                dataSource={ContactTypeData.map((val) => {
                  return {
                    id: val.SystemLevelCodeId,
                    value: val.LongCode,
                  };
                })}
                value={EmailAddressval.Type}
                handleRowClick={(e, val2) => {
                  onChangeValue(
                    "Type",
                    e,
                    EmailAddressval.id,
                    "TypeDropdownvalue",
                    val2
                  );
                }}
                fieldName="Type"
                validation={Participantionvalidation.EmergencyContact.email}
              />
              <div className="width_size">
                <TextBoxComponent
                  value={EmailAddressval.Email}
                  fieldName="Email Address"
                  validation={Participantionvalidation.EmergencyContact.email}
                  onChangeValue={(e) => {
                    onChangeValue("Email", e, EmailAddressval.id);
                  }}
                />
                {validationMessage && (
                  <div style={{ color: "red", fontSize: "13px" }}>
                    {validationMessage}
                  </div>
                )}
              </div>
              {addressval.Email.length > 1 && (
                <p
                  className="removeIcon"
                  onClick={() => {
                    remove(EmailAddressval.id);
                  }}
                  title="Remove"
                >
                  -
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const EmailAddress: React.FC<EmailAddressIProps> = ({
  Mainfields,
  addressval,
  setMainfields,
  Participantionvalidation,
}) => {
  const handleClick = () => {
    setMainfields({
      ...Mainfields,
      MultipleEmergencyContact: Mainfields.MultipleEmergencyContact.map(
        (val) => {
          if (val.id == addressval.id) {
            return {
              ...val,
              onChange: true,
              Email: [
                ...val.Email,
                {
                  id: val.Email.length + 1,
                  Email: "",
                  ExternalContactID: "",
                  Type: "",
                  TypeDropdownvalue: "",
                },
              ],
            };
          } else {
            return val;
          }
        }
      ),
    });
  };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader_">Email Address</p>
            {addressval.Email.map((EmailAddressval) => {
              return (
                <>
                  <EmailAddressDataUI
                    Mainfields={Mainfields}
                    setMainfields={setMainfields}
                    addressval={addressval}
                    EmailAddressval={EmailAddressval}
                    Participantionvalidation={Participantionvalidation}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div className="edit-button_" style={{ marginTop: "5%" }}>
          <ButtonComponents
            name="Add Email Address"
            handleClick={handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
