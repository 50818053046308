import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { ParticipantOverview } from "./Components/ParticipantOverview/ParticipantOverview";
import { OverviewFunction } from "./Components/Components/Overview/OverviewFunction";
import { OverviewTableData } from "./Components/Components/ParticipantOverviewIState";
import { InvolvementMainIParams } from "../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { formatDate } from "../../../../../utils/Helper/Helper";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

export const ParticipantOverviewList: React.FC = () => {
  const { IncidentId } = useSelector((store: RootState) => store.CommonRedux);
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigate();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const NavigateToUserPage = () => {
    navigation(`/incidents/${IncidentId}/participants`);
  };
  const overviewFunction = new OverviewFunction();
  const [fields, setfields] = useState<OverviewTableData>({
    Age: "",
    DOB: "",
    FirstName: "",
    LastName: "",
    MiddleName: "",
    Eye: "",
    FacialHair: "",
    Glasses: "",
    Hair: "",
    HGT: "",
    Race: "",
    Sex: "",
    Skintone: "",
    WGT: 0,
  });
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const initialRender = () => {
    if (incidentId && participantId) {
      overviewFunction.getInvolvement(
        setLoading,
        incidentId,
        participantId,
        setfields,
        fields
      );
    }
  };
  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="AddMasterName_main">
        <div className="AddMasterName_child">
          <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path: `/incidents` },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              {
                title: "Participants",
                path: `/incidents/${incidentId}/participants`,
              },
              { title: "Overview", path: "" },
            ]}
          />
          <BackButtonComponent
            name="Back to name lookup"
            onClick={NavigateToUserPage}
          />
          <p className="header" style={{ marginBottom: "0%" }}>
            {fields.FirstName} {fields.MiddleName} {fields.LastName}{" "}
            {fields?.DOB ? formatDate(fields.DOB) : ""}
          </p>
          <div>
            <ParticipantOverview />
          </div>
        </div>
      </div>
    </>
  );
};
