import { Fetcher } from "../../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../../utils/const";
import { formatDate } from "../../../../../utils/Helper/Helper";
import { getVehicleRes, SetFormDataIState } from "./VehicleOverviewIState";


const fetcher = new Fetcher();
export class VehicleOverviewFunction {

    async getVehicleoverviewData(
        setLoading: (data: boolean) => void,
        incidentId: string,
        vehicleId: any,
        setFormData: (data: SetFormDataIState) => void,
    ) {
        setLoading(true);
        let response = await fetcher.get(`/incident/53C54E75-8FA5-4576-86E1-BBB9465D4269/vehicle/${vehicleId}`);
        let { VehicleInvolvement, VehicleRegistration, RegisteredOwners }: getVehicleRes = response?.Data;
    console.log(response)
      const ownersData = RegisteredOwners?.map(owner => ({
        IsLien: owner.RegisteredOwnerInformation.IsLien,
        LienHolder: owner.RegisteredOwnerInformation.LienHolder,
        LastName: owner.RegisteredOwnerInformation.LastName,
        FirstName: owner.RegisteredOwnerInformation.FirstName,
        MiddleName: owner.RegisteredOwnerInformation.MiddleName,
        BusinessName: owner.RegisteredOwnerInformation.BusinessName,
        Suffix: owner.RegisteredOwnerInformation.Suffix,
        DOB: owner.RegisteredOwnerInformation.DOB,
        IsPrimary: owner.RegisteredOwnerInformation.IsPrimary,
    })) || [];
    const firstOwner = ownersData.length > 0 ? ownersData[0] : null;
setFormData({
    VehicleId: VehicleInvolvement?.VehicleId || '',
    IncidentId: VehicleInvolvement?.IncidentId || '',
    License: VehicleInvolvement?.License || '',
    State: VehicleInvolvement?.State || '',
    Style: VehicleInvolvement?.Style || '',
    CitationId: VehicleInvolvement?.CitationId || '',
    Ncicmake: VehicleInvolvement?.Ncicmake || '',
    MakerName:VehicleInvolvement?.MakerName || '',
    ModelName:VehicleInvolvement?.ModelName || '',
    NcicModel: VehicleInvolvement?.NcicModel || '',
    Ncictype:VehicleInvolvement?.Ncictype || "",
    TabYear: VehicleInvolvement?.TabYear || null,
    NciccolorPrimaryId:VehicleInvolvement?.NciccolorPrimaryId ||"",
    NciccolorPrimary: VehicleInvolvement?.NciccolorPrimary || '',
    NciccolorSecondary: VehicleInvolvement?.NciccolorSecondary || '',
    NciccolorSecondaryId:VehicleInvolvement?.NciccolorSecondaryId || "",
    Vin: VehicleInvolvement?.Vin || '',
    InsuranceAgencyCodeId: VehicleInvolvement?.InsuranceAgencyCodeId || '',
    VehicleCondition: VehicleInvolvement?.VehicleCondition || 0,
    GrossWeight: VehicleInvolvement?.GrossWeight || 0,
    RegisteredAddressId: VehicleInvolvement?.RegisteredAddressId || null,
    PlateNumber: VehicleInvolvement?.PlateNumber || '',
    AdditionalDetails: VehicleInvolvement?.AdditionalDetails || '',
    OutOfCityRecovery: VehicleInvolvement?.OutOfCityRecovery || false,
    OutOfCityRecoveryCityState: VehicleInvolvement?.OutOfCityRecoveryCityState || '',
    PropertyOffenseId: VehicleInvolvement?.PropertyOffenseId || '',
    NcicpropertyCategoryId: VehicleInvolvement?.NcicpropertyCategoryId || '',
    NcicpropertyCodeId: VehicleInvolvement?.NcicpropertyCodeId || '',
    NcicvehicleInvolvement: VehicleInvolvement?.NcicvehicleInvolvement || '',
    CommercialVehicleIndicator: VehicleInvolvement?.CommercialVehicleIndicator ? true : false,
    HazardousMaterialsIndicator: VehicleInvolvement?.HazardousMaterialsIndicator || false,
    HazardousMaterial: VehicleInvolvement?.HazardousMaterialName || '',
    IsParked: VehicleInvolvement?.IsParked || false,
    NoPlateIndicator: VehicleInvolvement?.NoPlateIndicator || false,
    _21dayTempIndicator: VehicleInvolvement?._21dayTempIndicator || false,
    CommercialDriverLicenseIndicator: VehicleInvolvement?.CommercialDriverLicenseIndicator  || false,
    VehicleOverWeightMeasure: VehicleInvolvement?.VehicleOverWeightMeasure || false,
    PropertyId: VehicleInvolvement?.PropertyId || '',
    ParticipantId: VehicleInvolvement?.ParticipantId || null,
    VehicleRegistrationId:VehicleRegistration?.VehicleRegistrationId || "",
    NcicauthorityId:VehicleRegistration?.NcicauthorityId || "",
    NciccountryId:VehicleRegistration?.NciccountryId || "",
   NcicmakeId:VehicleInvolvement?.NcicmakeId || "",
   NcictypeId:VehicleInvolvement?.NcictypeId || "",
    Year:VehicleRegistration?.Year || 0,
    Status:VehicleRegistration?.Status || "",
    Restrictions:VehicleRegistration?.Restrictions || "",
    Ncicclass:VehicleRegistration?.Ncicclass ||"",
    Grid:VehicleInvolvement?.Grid || "",
    CountryName:VehicleInvolvement?.CountryName || "",
    NcicstateId:VehicleInvolvement?.NcicstateId || "",
    AddressType:VehicleInvolvement?.AddressType || "",
    InsuranceAgencyName:VehicleInvolvement?.InsuranceAgencyName || "",
    ValidDates:VehicleRegistration?.ValidDates || "",
    NcicmodelId:VehicleInvolvement?.NcicmodelId ||"",
    RecovkedPlates: VehicleRegistration?.RecovkedPlates || false,
    TabId:VehicleRegistration?.TabId || "",
    // RegisteredOwnerInformationId:RegisteredOwners?.RegisteredOwnerInformation?.RegisteredOwnerInformationId || "",
    IsLien: firstOwner?.IsLien || false,
    LienHolder: firstOwner?.LienHolder || "",
    LastName: firstOwner?.LastName || "",
    FirstName: firstOwner?.FirstName || "",
    MiddleName: firstOwner?.MiddleName || "",
    BusinessName: firstOwner?.BusinessName || "",
    Suffix: firstOwner?.Suffix || "",
    DOB: firstOwner?.DOB || "",
    IsPrimary: firstOwner?.IsPrimary || false,
    ZipCode:VehicleInvolvement?.ZipCode || ""
    // IsIndividual:RegisteredOwners?.RegisteredOwnerInformation?.IsIndividual || false,

}
);



setLoading(false);
}
        
}
