import { fields } from "../../../../../../CommonComponents/Form/FormI";

export const fieldsListing: () => fields[][] = () => {
  return [
    [
      {
        fieldName: "Status",
        label: "Status*",
        type: "dropdown",
        dropdownDataSource: [
          { id: "1", value: "Active" },
          { id: "2", value: "InActive" },
        ],
        required: true,
      },
    ],
    [
      {
        fieldName: "WhitelistIPAddress",
        label: "IP Address*",
        type: "textbox",
        required: true,
      },
    ],
    [
      {
        fieldName: "Description",
        label: "Description*",
        type: "textarea",
        required: true,
      },
    ],
  ];
};
