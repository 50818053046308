import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface initialStateI {
  UserId: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  EmailAddress: string;
  IsUserActive: boolean;
  IsAgencyActive: boolean;
  IsSuperAdministrator: boolean;
  AgencyId: string;
  AgencyName: string;
  ProfileImage: string;
  userRole: string;
}

export interface setUserInfoPayload {
  UserId: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  EmailAddress: string;
  IsUserActive: boolean;
  IsAgencyActive: boolean;
  IsSuperAdministrator: boolean;
  AgencyId: string;
  AgencyName: string;
  ProfileImage: string;
}

const initialState: initialStateI = {
  UserId: "",
  FirstName: "",
  MiddleName: "",
  LastName: "",
  EmailAddress: "",
  IsUserActive: true,
  IsAgencyActive: true,
  IsSuperAdministrator: false,
  AgencyId: "",
  AgencyName: "",
  ProfileImage: "",
  userRole: "",
};

//slice

const slice = createSlice({
  name: "UserInfoRedux",
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<setUserInfoPayload>) {
      state.AgencyId = action.payload.AgencyId;
      state.AgencyName = action.payload.AgencyName;
      state.EmailAddress = action.payload.EmailAddress;
      state.FirstName = action.payload.FirstName;
      state.IsAgencyActive = action.payload.IsAgencyActive;
      state.IsSuperAdministrator = action.payload.IsSuperAdministrator;
      state.IsUserActive = action.payload.IsUserActive;
      state.LastName = action.payload.LastName;
      state.MiddleName = action.payload.MiddleName;
      state.ProfileImage = action.payload.ProfileImage;
      state.UserId = action.payload.UserId;
    },
  },
});

export const { setUserInfo } = slice.actions;

export default slice.reducer;
