import React, { useState } from "react";
import LocationMetaData from "../LocationMetaData";
import AddLocationForm from "./AddLocationForm";

const AddLocation = () => {
  const [width, setwidth] = useState<number>(10);
  return (
    <>
      <div className="_incident_location_container_fluid">
        <div className="_location_left_container">
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="_location_right_container">
          <AddLocationForm />
        </div>
      </div>
    </>
  );
};

export default AddLocation;
