import React, { useEffect, useRef, useState } from "react";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { useNavigate, useParams } from "react-router-dom";
import { NarrativeListFunction } from "../NarativeListing/NarrativeFunction";
import {
  setTableData as SetTableDataType,
  viewAllNarrative,
} from "../NarativeListing/NarrativeIState";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import NarrativeViewAllList from "./NarrativeViewAllList";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import BreadcrumbComponent from "src/Components/CommonComponents/breadcrumb/Breadcrumb";

const NarrativeViewAll = () => {
  const navigate = useNavigate();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const narrativeFunction = new NarrativeListFunction();
  const [tableData, setTableData] = useState<SetTableDataType[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [Name, setName] = useState<string>("");
  const [Type, setType] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(5);
  const [totalLoadedRecords, setTotalLoadedRecords] = useState<
    viewAllNarrative[]
  >([]);

  const scrollableRef = useRef<HTMLDivElement>(null);

  const backButtonHandler = () => {
    navigate(`/incidents/${incidentId}/narratives`);
  };
  let incidentNumber = localStorage.getItem("Incidentnumber");

  const handleScroll = () => {
    if (scrollableRef.current) {
      const scrollTop = scrollableRef.current.scrollTop;
      const scrollHeight = scrollableRef.current.scrollHeight;
      const clientHeight = scrollableRef.current.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setPageNumber((prevPage) => prevPage + 1);
      }
    }
  };

  const fetchAllData = async () => {
    if (incidentId) {
      const response = await narrativeFunction.getNarrativeListTableData(
        setTableData,
        pageNumber,
        pageCount,
        setLoading,
        setTableTotalRecord,
        Name,
        Type,
        incidentId,
        true,
        "Created On",
        true
      );
      setTotalLoadedRecords((prevState) => [...prevState, ...response?.Data]);
    }
  };

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollableRef.current) {
        scrollableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [pageNumber]);

  return (
    <>
      <div className="narrative_dummy_height" ref={scrollableRef}>
        {totalLoadedRecords?.length < tableTotalRecord && (
          <Loader loading={loading} />
        )}
        <div className="_narrative_bredcrumbs_container">
          <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path: "/incidents" },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              {
                title: "Narratives",
                path: `/incidents/${incidentId}/narratives`,
              },
              { title: "View All", path: `` },
            ]}
          />
        </div>

        <div className="_narrative_back_btn">
          <BackButtonComponent
            name="Back to narratives"
            onClick={backButtonHandler}
          />
        </div>

        <NarrativeViewAllList totalLoadedRecords={totalLoadedRecords} />
      </div>
    </>
  );
};

export default NarrativeViewAll;
