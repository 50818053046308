import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { Table } from "../../../CommonComponents/Table/Table";
import { coloum } from "./config";
import { SetTableData } from "./IncidentListI";
import { IncidentListFunction } from "./IncidentListFunction";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIncidentId } from "../../../../Redux/CommonRedux";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";
import { Filter } from "./Filter";

const IncidentList: React.FC = () => {
  const incidentListFunction = new IncidentListFunction();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [IncidentNumber, setIncidentNumber] = useState<string>("");
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Last Name");

  const onRowClicked = (data: SetTableData) => {
    navigate(`/incidents/${data.IncidentId}/participants`);
    dispatch(setIncidentId(data.IncidentId));
    localStorage.setItem("Incidentnumber", data["Incident Number"]);
  };

  const initialRender = () => {
    incidentListFunction.getIncidentListTable(
      setTableData,
      setLoading,
      setTableTotalRecord,
      pageCount,
      pageNumber,
      IncidentNumber,
      OrderByDescending,
      OrderBy
    );
  };
  useEffect(() => {
    initialRender();
  }, [pageNumber, pageCount]);

  return (
    <>
      <div>
        <div className="UserManagement">
          <Loader loading={loading} />

          <div className="caseHeader">
            <p className="header">List Of Incident</p>
          </div>
          <Filter
            setIncidentNumber={setIncidentNumber}
            IncidentNumber={IncidentNumber}
            initialRender={initialRender}
          />
          <div style={{ marginTop: "1%" }}>
            <Table
              columns={coloum()}
              columnsValue={tableData}
              setpageCount={setpageCount}
              setpageNumber={setpageNumber}
              tableTotalRecord={tableTotalRecord}
              cursorPointer={true}
              onRowClicked={onRowClicked}
              setOrderByDescending={setOrderByDescending}
              OrderByDescending={OrderByDescending}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IncidentList;
