import React, { useEffect, useState } from "react";
import "./CreateIPAddress.css";
import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { useParams } from "react-router-dom";
import { CreateIPAddressFunction } from "./CreateIPAddressFunction";
import { IpAddressIProps } from "./CreateIPAddressInterface";
import { EditAgencyManagemantIParams } from "../../../../EditAgencyManagemant/EditAgencyManagemantI";
import { Form } from "../../../../../../CommonComponents/Form/Form";
import { setFormDataIState } from "../../../../../../CommonComponents/Form/FormI";
import { fieldsListing } from "./Config";
import { useGetPerticularUserPermission } from "../../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const CreateEditIPAddress: React.FC<IpAddressIProps> = ({
  addmode,
  editMode,
  getIpAddressSingleData,
  viewMode,
  setaddMode,
  seteditMode,
  setviewMode,
  setShowAddIPAddress,
}) => {
  const { Update } = useGetPerticularUserPermission("Agency");
  const createIPAddressFunction = new CreateIPAddressFunction();
  const [edit, setEdit] = useState<boolean>(viewMode ? true : false);
  const { agencyId } = useParams<EditAgencyManagemantIParams>();
  const [loading, setLoading] = useState<boolean>(false);

  const ChangeformBehaviour = () => {
    setEdit(false);
    setaddMode(false);
    seteditMode(true);
    setviewMode(false);
  };

  const BackButtonFunction = () => {
    setShowAddIPAddress(false);
  };

  const handleSubmit = async (formData: setFormDataIState) => {
    if (agencyId) {
      if (addmode) {
        createIPAddressFunction.addIPAddress(setLoading, formData, agencyId);
      } else if (editMode && getIpAddressSingleData.WhitelistIPAddressId) {
        createIPAddressFunction.editIPAddress(
          setLoading,
          formData,
          agencyId,
          getIpAddressSingleData.WhitelistIPAddressId
        );
      }
      BackButtonFunction();
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Form
        disabled={edit}
        showborder={true}
        heading={addmode ? "Add IP Address" : "Edit IP Address"}
        formBehaviour={viewMode ? "View" : editMode ? "Edit" : "Add"}
        viewModeUpdate={Update}
        ChangeformBehaviour={ChangeformBehaviour}
        defaultValue={{
          WhitelistIPAddress: getIpAddressSingleData.IPAddress,
          Description: getIpAddressSingleData.Description,
          Status: getIpAddressSingleData.Status ? 1 : 2,
        }}
        fieldsListing={fieldsListing()}
        handleSubmit={handleSubmit}
        BackButtonFunction={BackButtonFunction}
      />
    </>
  );
};
