import { useEffect, useState } from "react";
import { SetTableData } from "./CADInformationI";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { Table } from "../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { CADInformationFunction } from "./CADInformationFunction";
import { useNavigate, useParams } from "react-router-dom";
import { InvolvementMainIParams } from "../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { useDispatch } from "react-redux";
import BreadcrumbComponent from "../../../CommonComponents/breadcrumb/Breadcrumb";

export const IncidentsCADInformation: React.FC = () => {
  const cADInformationFunction = new CADInformationFunction();
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const onRowClicked = (e: SetTableData) => {
    navigate(`/incidents/${incidentId}/cad-information/${e.CadimportId}/info`);
  };

  const initialRender = () => {
    cADInformationFunction.getCADInformationTable(
      setTableData,
      setLoading,
      setTableTotalRecord
    );
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="UserManagement">
        <BreadcrumbComponent
          fixedCrumbItems={[
            { title: "Incidents", path: "/incidents" },
            { title: incidentNumber ? incidentNumber : "", path: "" },
            {
              title: "CAD Information",
              path: `/incidents/${incidentId}/cad-information`,
            },
          ]}
        />
        <div className="caseHeader">
          <p className="header">List Of CAD Information</p>
        </div>

        <div className="table">
          <Table
            columns={coloum()}
            onRowClicked={onRowClicked}
            columnsValue={tableData}
            cursorPointer={true}
            setpageCount={setpageCount}
            setpageNumber={setpageNumber}
            tableTotalRecord={tableTotalRecord}
          />
        </div>
      </div>
    </>
  );
};
