
import React from "react";
import { DeleteConfirmationIProps } from "../../DeleteConfirmationI";
import { DeleteModalRole } from "./DeleteModalRole";

const RoleDeleteModal:React.FC<DeleteConfirmationIProps> = ({ RoleError, confirmationMessage, onClose, onDelete, handleRoleExistDelete }) => {
  return (
    <div className="modal" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div className="modal-content"  style={{ maxHeight:"50vh",overflowY:"auto"}} >
        <p className="modal-message">
          {RoleError && Object.keys(RoleError).length !== 0 ? (
            <div>
              <span>{confirmationMessage}</span>
              <div className="list-container">
                {Object.entries(RoleError).map(([key, value]) => {
                  if (value.length > 0) {
                    const nameField = key === "User" ? "FirstName" : key === "Role" ? "RoleName" : "PolicyName";
                    const descriptionField = key === "User" ? "EmailAddress" : "Description";
                    return (
                      <DeleteModalRole
                        key={key}
                        title={key}
                        data={value}
                        nameField={nameField}
                        descriptionField={descriptionField}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          ) : (
            `Are you sure you want to delete this item?`
          )}
        </p>
        <div className="modal-actions">
          {!RoleError || Object.keys(RoleError).length === 0 ? (
            <>
              <button className="modal-button" onClick={onClose}>
                No
              </button>
              <button className="modal-button-confirm" onClick={onDelete}>
                Yes
              </button>
            </>
          ) : (
            <>
              <button className="modal-button" onClick={onClose}>
                Cancel
              </button>
              <button className="modal-button-confirm" onClick={handleRoleExistDelete}>
                OK
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};


export default RoleDeleteModal;
