import { useState } from "react";
import "./location.css";
import LocationMetaData from "./LocationMetaData";
import LocationTableData from "./LocationTableData";
const Location = () => {
  const [width, setwidth] = useState<number>(10);

  return (
    <>
      <div className="_incident_location_container_fluid">
        <div>
          <LocationMetaData width={width} setWidth={setwidth} />
        </div>

        <div className="_location_right_container">
          <LocationTableData />
        </div>
      </div>
    </>
  );
};

export default Location;
