import add from "../../../../../../../../../assets/icons/Icon.svg";
import { useEffect, useState } from "react";
import { GetRaceDataIState } from "../../../Involvement/InvolvementMainI";
import { InvolvementMainFunction } from "../../../Involvement/InvolvementMainFunction";
import {
  PhoneNumberDataUIProps,
  PhoneNumberIProps,
  setContactTypeDataIState,
} from "../../IdentificationIProps";
import { PhoneNumberFunction } from "../../../ExternalContact/Components/Phone/PhoneNumberFunction";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { MaskedInputComponent } from "../../../../../../../../CommonComponents/Fields/MaskedInput/MaskedInputComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { Add } from "../../../../../../../../../assets";
import { PhonenumberFunction } from "./PhonenumberFunction";
import { PhoneNumberIstate } from "../../../ExternalContact/ExternalContactI";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";

export const PhoneNumberDataUI: React.FC<PhoneNumberDataUIProps> = ({
  Mainfields,
  setMainfields,
  PhoneNumberval,
  Participantionvalidation,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();
  const phoneNumberFunction = new PhoneNumberFunction();
  const [Country, setCountry] = useState<GetRaceDataIState[]>([]);
  const [ContactTypeData, setContactTypeData] = useState<
    setContactTypeDataIState[]
  >([]);

  const onChangeValue = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      IdentificationAndContact: {
        ...Mainfields.IdentificationAndContact,
        onChange: true,
        PhoneNumber: Mainfields.IdentificationAndContact.PhoneNumber.map(
          (val) => {
            if (val.id == id) {
              if (key2) {
                return { ...val, [key]: value, [key2]: value2 };
              } else {
                return { ...val, [key]: value };
              }
            } else {
              return val;
            }
          }
        ),
      },
    });
  };

  const initialRender = () => {
    phoneNumberFunction.getContactTypeData(setContactTypeData);
    involvementMainFunction.getCountry(setCountry);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div className="Involvement_left_row" style={{ width: "90%" }}>
        <DropdownComponent
          dataSource={ContactTypeData.map((val) => {
            return {
              id: val.SystemLevelCodeId,
              value: val.LongCode,
            };
          })}
          value={PhoneNumberval.Type}
          handleRowClick={(e, value2) => {
            onChangeValue(
              "Type",
              e,
              PhoneNumberval.id,
              "TypeDropdownvalue",
              value2
            );
          }}
          fieldName="Type"
          validation={Participantionvalidation.IdentificationAndContact.phone}
        />
        <DropdownComponent
          dataSource={Country.map((val) => {
            return {
              id: val.NciccodeId,
              value: val.LongCode,
            };
          })}
          value={PhoneNumberval.Country}
          handleRowClick={(e) => {
            onChangeValue("Country", e, PhoneNumberval.id);
          }}
          fieldName="Country"
        />

        <MaskedInputComponent
          mask="+1 (999)-999-9999"
          value={PhoneNumberval.Number}
          fieldName="Number"
          onChangeValue={(e) => {
            onChangeValue("Number", e, PhoneNumberval.id);
          }}
          validation={Participantionvalidation.IdentificationAndContact.phone}
        />
        <TextBoxComponent
          value={PhoneNumberval.Ext}
          fieldName="Ext."
          onChangeValue={(e) => {
            onChangeValue("Ext", e, PhoneNumberval.id);
          }}
        />
      </div>
    </>
  );
};

export const PhoneNumber: React.FC<PhoneNumberIProps> = ({
  Mainfields,
  setMainfields,
  Participantionvalidation,
}) => {
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<PhoneNumberIstate[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const phonenumberFunction = new PhonenumberFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    phonenumberFunction.Save();
  };

  const handleClick = () => {
    phonenumberFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    phonenumberFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    phonenumberFunction.DeletePreview(id);
  };

  return (
    <>
      <div>
        <div className="Involvement_main">
          <div className="Involvement_left">
            <p className="subheader">Phone Number</p>

            <Preview
              DeletePreview={DeletePreview}
              ViewPreview={ViewPreview}
              showlist={[["TypeDropdownvalue", "comma", "Number"]]}
              list={
                edit
                  ? (editList.map((item) => ({ ...item })) as {
                      [key: string]: string | number | boolean;
                    }[])
                  : (Mainfields.IdentificationAndContact.PhoneNumber.map(
                      (item) => ({
                        ...item,
                      })
                    ) as { [key: string]: string | number | boolean }[])
              }
            />

            <PhoneNumberDataUI
              Mainfields={Mainfields}
              setMainfields={setMainfields}
              PhoneNumberval={
                edit
                  ? Mainfields.IdentificationAndContact.PhoneNumber.filter(
                      (val) => {
                        if (val.id == previewcount) {
                          return val;
                        }
                      }
                    )[0]
                  : Mainfields.IdentificationAndContact.PhoneNumber[
                      Mainfields.IdentificationAndContact.PhoneNumber.length - 1
                    ]
              }
              Participantionvalidation={Participantionvalidation}
            />
          </div>
        </div>
        <div className={edit ? "edit-button_" : "edit-button_ align_btn"}>
          <ButtonComponents
            name={edit ? "Save" : "Add Phone Number"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
