import React from "react";
import { Navigate } from "react-router-dom";
import { useGetPerticularUserPermission } from "../hooks/Permission/useGetPerticularUserPermission";

export type AllPagesRoles =
  | "Code.Agency"
  | "Code.NCIC"
  | "Code.System"
  | "Incident.Article"
  | "Incident.CAD"
  | "Incident.Location"
  | "Incident.Media"
  | "Incident.Menu.Config"
  | "Incident.Narrative"
  | "Incident.Participant"
  | "Incident.Prosecution"
  | "Incident.Vehicle"
  | "Agency"
  | "CAD"
  | "Config"
  | "Incident"
  | "MasterIndex"
  | "User";

export type actionType = "Create" | "Read" | "Update" | "Delete" | "General";

interface ProtectedRouteProps {
  children: JSX.Element;
  roleName: AllPagesRoles;
  actionType: actionType;
}

export const PrivateRoute: React.FC<ProtectedRouteProps> = ({
  children,
  roleName,
  actionType,
}) => {
  const { Create, Delete, General, Read, Update } =
    useGetPerticularUserPermission(roleName);

  let type = { Create, Delete, General, Read, Update };

  if (!type[actionType]) {
    return <Navigate to={"/not-found"} replace />;
  }

  return children;
};
