import React, { useState, useEffect } from "react";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../CommonComponents/Table/Table";
import { Filter } from "../Components/Filter/Filter";
import { SetTableData } from "./ListPermissionManagementI";
import { coloum } from "./Config";
import { ListPermissionFunction } from "./ListPermissionFunction";
import useDelayedEffect from "../../../../../hooks/useDelayedEffect";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

const ListPermissionManagement = () => {
  const listPermissionFunction = new ListPermissionFunction();
  const [DisplayName, setDisplayName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCategoryAndPermissionData = async () => {
    listPermissionFunction.GetCategoryData(
      setLoading,
      category,
      setTableData,
      setFiltertableData
    );
  };

  const applyFilterOnUserpolicyTable = () => {
    listPermissionFunction.applyFilterOnUserpolicyTable(
      DisplayName,
      FiltertableData,
      setTableData
    );
  };

  useEffect(() => {
    fetchCategoryAndPermissionData();
  }, [category]);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [DisplayName]);

  return (
    <>
      <Loader loading={loading} />
      <div className="AgencyManagement">
     
        <div className="Audit-Header">
          <p className="Audit-Header-name">Permissions</p>
        </div>
        <div className="Audit-filter">
          <Filter
            DisplayName={DisplayName}
            setDisplayName={setDisplayName}
            Category={category}
            setCategory={setCategory}
          />
        </div>
        <div className="table">
          <Table
            columns={coloum()}
            columnsValue={tableData}
            clientSideSorting={true}
            showPagination={false}
            
            
          />
        </div>
      </div>
    </>
  );
};

export default ListPermissionManagement;
