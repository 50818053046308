import React, { useEffect, useState } from "react";
import close from "../../../../../../../assets/icons/close-circle.svg";
import { ButtonComponents } from "../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { DropdownComponent } from "../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { FilterIProps } from "./FilterIProps";
import { ButtonIState } from "../../../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";

export const Filter: React.FC<FilterIProps> = ({
  StatusFilter,
  policyNameFilter,
  setStatusFilter,
  setpolicyNameFilter,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
    useEffect(() => {
        if ((policyNameFilter && policyNameFilter.trim().length > 0) || StatusFilter !== "1") {
          setIsAddButtonDisabled(false);
          setButtonTextColor("red");
        } else {
          setIsAddButtonDisabled(true);
      setButtonTextColor("black");
        }
      }, [policyNameFilter, StatusFilter]);

  const clearAllFilters = () => {
    setpolicyNameFilter("");
    setStatusFilter("1");
    setIsAddButtonDisabled(true);
      setButtonTextColor("black");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds">
          <TextBoxComponent
            fieldName={"Policy Name"}
            value={policyNameFilter}
            onChangeValue={setpolicyNameFilter}
          />

          <DropdownComponent
            fieldName={"Status"}
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            handleRowClick={setStatusFilter}
            value={StatusFilter}
            allowclear={false}
            showTooltip={false}
          />
        </div>
        <div className="clearbutton">
          <div className="clearbutton-sub">
          <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
