import { useEffect, useState } from "react";
import {
  IdentificationIstate,
  MultipleIdentityDataUIProps,
  MultipleIdentityIProps,
} from "../../IdentificationIProps";
import {
  PersonIdentificationIState,
  PersonIdentificationStateIState,
} from "../../../Involvement/InvolvementMainI";
import { IdentificationMainFunction } from "../../IdentificationFunction";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { DateBoxComponents } from "../../../../../../../../CommonComponents/Fields/DateBox/DateBox";
import { Add } from "../../../../../../../../../assets";
import { Preview } from "../../../../../../../../CommonComponents/Preview/Preview";
import { IdentificationFunction } from "./IdentificationFunction";

const MultiIdentificationDataUI: React.FC<MultipleIdentityDataUIProps> = ({
  Mainfields,
  setMainfields,
  MultipleIdentityval,
  PersonIdentification,
  PersonIdentificationState,
}) => {
  const onChangeValueNumber = (
    key: string,
    value: string | number | boolean | null,
    id: number,
    key2?: string,
    value2?: string
  ) => {
    setMainfields({
      ...Mainfields,
      IdentificationAndContact: {
        ...Mainfields.IdentificationAndContact,
        onChange: true,
        IdentificationNumber:
          Mainfields.IdentificationAndContact.IdentificationNumber.map(
            (val) => {
              if (val.id == id) {
                if (key2 && value2) {
                  return { ...val, [key]: value, [key2]: value2 };
                } else {
                  return { ...val, [key]: value };
                }
              } else {
                return val;
              }
            }
          ),
      },
    });
  };

  return (
    <>
      <div className="Involvement_main">
        <div className="Involvement_left">
          <div className="Involvement_left_row" style={{ width: "50%" }}>
            <DropdownComponent
              fieldName="Identification Type*"
              dataSource={PersonIdentification.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              value={MultipleIdentityval.NcicpersonIdentificationCodeId}
              handleRowClick={(e, value2) => {
                onChangeValueNumber(
                  "NcicpersonIdentificationCodeId",
                  e,
                  MultipleIdentityval.id,
                  "IdentificationTypeDropdownValue",
                  value2
                );
              }}
            />
            <TextBoxComponent
              value={MultipleIdentityval.PrimaryIdentification}
              fieldName="Identification Number"
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "PrimaryIdentification",
                  e,
                  MultipleIdentityval.id
                );
              }}
            />
            <TextBoxComponent
              value={MultipleIdentityval.IdentificationClass}
              fieldName="ID Class"
              onChangeValue={(e) => {
                onChangeValueNumber(
                  "IdentificationClass",
                  e,
                  MultipleIdentityval.id
                );
              }}
            />
          </div>
          <div className="MultiplePhoneNumber" style={{ width: "50%" }}>
            <div className="_state_providence_container">
              <DropdownComponent
                value={MultipleIdentityval.LocationStateUspostalServiceCode}
                handleRowClick={(e, value2) => {
                  onChangeValueNumber(
                    "LocationStateUspostalServiceCode",
                    e,
                    MultipleIdentityval.id,
                    "StateProvidenceDropdownValue",
                    value2
                  );
                }}
                fieldName="State/Providence"
                dataSource={PersonIdentificationState.map((val) => {
                  return {
                    id: val.NciccodeId,
                    value: val.LongCode,
                  };
                })}
              />
            </div>

            <div className="_participant_date_conatiner">
              <DateBoxComponents
                fieldName={"Issue Date"}
                value={MultipleIdentityval.EffectiveStartDate}
                onValueChange={(newValue) => {
                  onChangeValueNumber(
                    "EffectiveStartDate",
                    newValue,
                    MultipleIdentityval.id
                  );
                }}
              />
            </div>

            <div className="_participant_date_conatiner">
              <DateBoxComponents
                fieldName={"Exp. Date"}
                value={MultipleIdentityval.EffectiveEndDate}
                onValueChange={(newValue) => {
                  onChangeValueNumber(
                    "EffectiveEndDate",
                    newValue,
                    MultipleIdentityval.id
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const MultiIdentification: React.FC<MultipleIdentityIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const identificationMainFunction = new IdentificationMainFunction();
  const [PersonIdentification, setPersonIdentification] = useState<
    PersonIdentificationIState[]
  >([]);
  const [PersonIdentificationState, setPersonIdentificationState] = useState<
    PersonIdentificationStateIState[]
  >([]);
  const [edit, setedit] = useState<boolean>(false);
  const [editList, seteditList] = useState<IdentificationIstate[]>([]);
  const [previewcount, setpreviewcount] = useState<number>(1);
  const identificationFunction = new IdentificationFunction(
    Mainfields,
    setMainfields,
    setedit,
    seteditList,
    setpreviewcount
  );

  const Save = () => {
    identificationFunction.Save();
  };

  const handleClick = () => {
    identificationFunction.handleClick();
  };

  const ViewPreview = (id: number) => {
    identificationFunction.ViewPreview(id);
  };

  const DeletePreview = (id: number) => {
    identificationFunction.DeletePreview(id);
  };

  const initialRender = () => {
    identificationMainFunction.getPersonIdentification(setPersonIdentification);
    identificationMainFunction.getPersonIdentificationState(
      setPersonIdentificationState
    );
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <p className="subheader">Identification Number</p>

        <Preview
          DeletePreview={DeletePreview}
          ViewPreview={ViewPreview}
          showlist={[
            [
              "IdentificationTypeDropdownValue",
              "comma",
              "StateProvidenceDropdownValue",
              "comma",
              "PrimaryIdentification",
              "comma",
              "IdentificationClass",
            ],
          ]}
          list={
            edit
              ? (editList.map((item) => ({ ...item })) as {
                  [key: string]: string | number | boolean;
                }[])
              : (Mainfields.IdentificationAndContact.IdentificationNumber.map(
                  (item) => ({
                    ...item,
                  })
                ) as { [key: string]: string | number | boolean }[])
          }
        />

        <MultiIdentificationDataUI
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          MultipleIdentityval={
            edit
              ? Mainfields.IdentificationAndContact.IdentificationNumber.filter(
                  (val) => {
                    if (val.id == previewcount) {
                      return val;
                    }
                  }
                )[0]
              : Mainfields.IdentificationAndContact.IdentificationNumber[
                  Mainfields.IdentificationAndContact.IdentificationNumber
                    .length - 1
                ]
          }
          PersonIdentification={PersonIdentification}
          PersonIdentificationState={PersonIdentificationState}
        />
        <div className="edit-button_ align_edit-button_">
          <ButtonComponents
            name={edit ? "Save" : "Add Identification Number"}
            handleClick={edit ? Save : handleClick}
            color="white"
            textColor="#3672b3"
            borderColor="#3672b3"
            showBackgroundColor={true}
            icon={Add}
          />
        </div>
      </div>
    </>
  );
};
