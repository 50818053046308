import { useEffect, useState } from "react";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import "./DetailTab.css";
import {
  DetailTabIProps,
  SetFormDataIState,
  getSateDataIState,
  setAgencyTypeDataIState,
  setFormDataValidation,
} from "./DetailTabInterface";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { DetailTabFunctions } from "./DetailTabFunctions";
import { EditAgencyManagemantIParams } from "../../../EditAgencyManagemant/EditAgencyManagemantI";
import { TextAreaComponent } from "../../../../../CommonComponents/Fields/TextArea/TextAreaComponent";
import { useDispatch, useSelector } from "react-redux";
import useDelayedEffect from "../../../../../../hooks/useDelayedEffect";
import { seteditComponent } from "../../../../../../Redux/AgencyReducer";
import { setchangeTheForm } from "../../../../../../Redux/ValidateRouteReducer";
import { MultiplePhoneNumber } from "../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumber";
import { setMultiplePhoneNumberListIState } from "../../../../../CommonComponents/Fields/MultiplePhoneNumber/MultiplePhoneNumberI";
import { useGetPerticularUserPermission } from "../../../../../../hooks/Permission/useGetPerticularUserPermission";

export const DetailTab: React.FC<DetailTabIProps> = ({ viewComponent }) => {
  const { Update } = useGetPerticularUserPermission("Agency");
  const detailTabFunctions = new DetailTabFunctions();
  const dispatch = useDispatch();
  const { agencyId } = useParams<EditAgencyManagemantIParams>();

  const [edit, setEdit] = useState<boolean>(viewComponent ? true : false);
  const [getagencyname, setgetagencyname] = useState<string>("");
  const [onAgencyChange, setonAgencyChange] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<SetFormDataIState>({
    AgencyName: "",
    AgencyTypeCodeId: "",
    AgencyDescription: "",
    AgencyAddress: "",
    AgencyPhone: [
      { ContactMethodCodeId: "", ContactValue: "", id: 1, IsPrimary: false },
    ],
    AgencyFax: "",
    AgencyCity: "",
    AgencyStateCodeId: "",
    AgencyZipcode: "",
    IsActive: "1",
    IpAddress: "",
  });
  const [formDataValidation, setFormDataValidation] =
    useState<setFormDataValidation>({
      AgencyName: false,
      AgencyTypeCodeId: false,
      AgencyDescription: false,
      AgencyAddress: false,
      AgencyPhone: false,
      AgencyFax: false,
      AgencyCity: false,
      AgencyStateCodeId: false,
      AgencyZipcode: false,
      IsActive: false,
      ContactType: false,
    });

  const [stateData, setStateData] = useState<getSateDataIState[]>([]);
  const [agencyTypeData, setAgencyTypeData] = useState<
    setAgencyTypeDataIState[]
  >([]);
  const [checkagencyname, setcheckagencyname] = useState<boolean>(true);
  const navigation = useNavigate();

  const editEnable = () => {
    setEdit(false);
    dispatch(
      seteditComponent({
        editComponent: true,
        addComponent: false,
        viewComponent: false,
      })
    );
  };
  const editDisable = () => {
    setFormData({
      AgencyName: "",
      AgencyTypeCodeId: "",
      AgencyDescription: "",
      AgencyAddress: "",
      AgencyPhone: [
        { ContactMethodCodeId: "", ContactValue: "", id: 1, IsPrimary: false },
      ],
      AgencyFax: "",
      AgencyCity: "",
      AgencyStateCodeId: "",
      AgencyZipcode: "",
      IsActive: "1",
      IpAddress: "",
    });
    dispatch(setchangeTheForm(false));
  };
  const handleInputChange = (field: string, value: string | number) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    setFormDataValidation({
      ...formDataValidation,
      [field]: false,
    });
    dispatch(setchangeTheForm(true));
  };

  const NavigateToEditPage = (id: number) => {
    navigation(`/agency/${id}/edit`);
  };

  const NavigateToAddPage = () => {
    navigation("/agency");
  };

  const getMultiplePhoneNumberData = (
    data: setMultiplePhoneNumberListIState[]
  ) => {
    setFormData({
      ...formData,
      ["AgencyPhone"]: data,
    });

    if (data[0]?.ContactMethodCodeId) {
      setFormDataValidation({
        ...formDataValidation,
        ["AgencyPhone"]: false,
      });
    }
  };
  const agencyNameValidate = () => {
    if (formData.AgencyName.trim() && onAgencyChange) {
      if (formData.AgencyName.trim() !== getagencyname) {
        detailTabFunctions.agencyNameValidate(
          formData.AgencyName,
          setLoading,
          setcheckagencyname
        );
      }
    }
  };

  const handleSubmit = async () => {
    if (checkagencyname || formData.AgencyName.trim() === getagencyname) {
      detailTabFunctions.errorValidation(formData, setFormDataValidation);
      if (detailTabFunctions.checkRequiredFields(formData)) {
        if (agencyId) {
          detailTabFunctions.editAgency(setLoading, formData, agencyId);
          dispatch(setchangeTheForm(false));
        } else if (!agencyId) {
          if (formData.AgencyName.length) {
            detailTabFunctions.addAgency(
              setLoading,
              formData,
              NavigateToEditPage
            );
            dispatch(setchangeTheForm(false));
          }
        }
      }
    }
  };
  const scrollToTopHandler = () => {
    if (loading) {
      window.scroll({ top: 0 });
    }
  };

  useEffect(() => {
    scrollToTopHandler();
    detailTabFunctions.getAgencyTypeData(setAgencyTypeData, setLoading);
    detailTabFunctions.getSateData(setStateData, setLoading);
    if (agencyId) {
      detailTabFunctions.getAgencyManagemantData(
        setLoading,
        agencyId,
        setFormData,
        dispatch,
        setgetagencyname
      );
    }
  }, []);

  useDelayedEffect(agencyNameValidate, 700, [formData.AgencyName]);

  return (
    <>
      <Loader loading={loading} />

      <div className="DetailTab_top">
        <div className="DetailTab_top_header_">
          <div className="DetailTab_top_header_sub">
            <p>Detail</p>
            {agencyId && viewComponent && Update ? (
              <p onClick={editEnable} className="DetailTab_top_header_edit">
                Edit
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="DetailTab_contant_">
          <div className="DetailTab_contant_1">
            <DropdownComponent
              dataSource={[
                { id: "1", value: "Active" },
                { id: "2", value: "InActive" },
              ]}
              value={formData.IsActive}
              disabled={edit}
              fieldName="Status"
              showTooltip={false}
              handleRowClick={(newValue) => {
                handleInputChange("IsActive", newValue);
              }}
              validation={formDataValidation.IsActive}
            />
            <TextBoxComponent
              disabled={edit}
              value={formData.AgencyName}
              fieldName="Agency Name*"
              onChangeValue={(newValue) => {
                setonAgencyChange(true);
                handleInputChange("AgencyName", newValue);
              }}
              validation={formDataValidation.AgencyName}
            />

            <DropdownComponent
              dataSource={agencyTypeData.map((state) => ({
                id: state.SystemLevelCodeId,
                value: state.LongCode,
              }))}
              disabled={edit}
              fieldName="Agency Type*"
              value={formData.AgencyTypeCodeId}
              handleRowClick={(newValue) => {
                handleInputChange("AgencyTypeCodeId", newValue);
              }}
              validation={formDataValidation.AgencyTypeCodeId}
            />
          </div>
          <div className="DetailTab_contant_2">
            <TextAreaComponent
              value={formData.AgencyDescription}
              disabled={edit}
              fieldName="Agency Description"
              onChangeValue={(newValue) => {
                handleInputChange("AgencyDescription", newValue);
              }}
            />
          </div>
          <div className="DetailTab_contant_Address">
            <div className="DetailTab_contant_Address_1">
              <p className="form_heading">Address</p>
              <TextBoxComponent
                value={formData.AgencyAddress}
                disabled={edit}
                fieldName="Street Address*"
                onChangeValue={(newValue) => {
                  handleInputChange("AgencyAddress", newValue);
                }}
                validation={formDataValidation.AgencyAddress}
              />
              <div className="DetailTab_contant_Address_2">
                <TextBoxComponent
                  value={formData.AgencyCity}
                  disabled={edit}
                  fieldName="City"
                  onChangeValue={(newValue) => {
                    handleInputChange("AgencyCity", newValue);
                  }}
                  validation={formDataValidation.AgencyCity}
                />
                <DropdownComponent
                  dataSource={stateData.map((state) => ({
                    id: state.SystemLevelCodeId,
                    value: state.LongCode,
                  }))}
                  disabled={edit}
                  fieldName="State"
                  value={formData.AgencyStateCodeId}
                  handleRowClick={(newValue) => {
                    handleInputChange("AgencyStateCodeId", newValue);
                  }}
                  validation={formDataValidation.AgencyStateCodeId}
                />
                <TextBoxComponent
                  value={formData.AgencyZipcode}
                  disabled={edit}
                  fieldName="Zip Code"
                  onChangeValue={(newValue) => {
                    handleInputChange("AgencyZipcode", newValue);
                  }}
                  validation={formDataValidation.AgencyZipcode}
                />
              </div>
            </div>
            <div className="DetailTab_contant_Contact_Info">
              <p className="form_heading">Contact Info</p>

              <TextBoxComponent
                value={formData.AgencyFax}
                disabled={edit}
                fieldName="Fax"
                onChangeValue={(newValue) => {
                  handleInputChange("AgencyFax", newValue);
                }}
              />
            </div>
          </div>

          <div className="Status_">
            <div className="Status_dropdown">
              <MultiplePhoneNumber
                disabled={edit}
                getMultiplePhoneNumberData={getMultiplePhoneNumberData}
                value={formData.AgencyPhone}
                validationNumber={formDataValidation.AgencyPhone}
                validationContactType={formDataValidation.ContactType}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="edit-button">
        {!edit && <Submit onCancel={editDisable} onSave={handleSubmit} />}
      </div>
    </>
  );
};
