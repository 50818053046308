import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { OrderByFieldNameI, setTableData } from "./VechicleIState";
import edit_icon from "../../../../../assets/icons/edit.svg";
import delete_icon from "../../../../../assets/icons/delete.svg";
export const OrderByFieldName: OrderByFieldNameI = {
  "License Plate": "PlateNumber",
  Year: "Year",
  Make: "NcicMake",
  Model: "Style",
  Type: "NcicType",
  "Registered Owner(s)": "PrimaryOwner",
  Commercial: "CommercialVehicleIndicator",
  Color: "NcicColorPrimary",
};
export const coloum: (
  NavigateToEditPage: (data: string) => void,
  setdeletePopup: (data: boolean) => void,
  setDeleteVehicleId: (id: string | null) => void,
  Delete: boolean,
  Update: boolean
) => ColDef[] = (
  NavigateToEditPage: (data: string) => void,
  setdeletePopup: (data: boolean) => void,
  setDeleteVehicleId: (id: string | null) => void,
  Delete: boolean,
  Update: boolean
) => {
  return [
    { field: "License Plate" },
    { field: "Year" },
    { field: "Make" },
    { field: "Model" },
    { field: "Type" },
    { field: "Color" },
    { field: "Registered Owner(s)" },
    { field: "Commercial" },
    {
      field: "Action",
      cellRenderer: (e: { data: setTableData }) => {
        return (
          <div className="table-cellRenderer">
            {Update && (
              <img
                className="table-cellRenderer-img"
                src={edit_icon}
                title="Edit"
                onClick={() => {
                  NavigateToEditPage(e?.data?.VehicleId);
                }}
              />
            )}
            {Delete && (
              <img
                title="Delete"
                className="table-cellRenderer-img"
                src={delete_icon}
                alt="Delete"
                onClick={() => {
                  setDeleteVehicleId(e?.data?.VehicleId);
                  setdeletePopup(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
};
