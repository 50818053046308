import { useState } from "react";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { TextAreaComponent } from "../../../../CommonComponents/Fields/TextArea/TextAreaComponent";

export const AddEditViewNCIC: React.FC = () => {
  const [disabled, setdisabled] = useState<boolean>();
  return (
    <>
      <div className="DetailTab_top">
        <div className="DetailTab_top_header_">
          <div className="DetailTab_top_header_sub">
            <p>View NCIC Code</p>
          </div>
        </div>

        <div className="AddEditViewNCIC">
          <div className="AddEditViewNCIC_1">
            <DropdownComponent
              dataSource={[
                { id: "1", value: "Active" },
                { id: "2", value: "InActive" },
              ]}
              value={"1"}
              disabled={disabled}
              fieldName="Status"
              showTooltip={false}
            />
            <TextBoxComponent
              disabled={disabled}
              value={""}
              fieldName="Category"
            />
          </div>
          <div className="AddEditViewNCIC_2">
            <TextBoxComponent
              disabled={disabled}
              value={""}
              fieldName="Short Code"
            />
            <TextBoxComponent
              disabled={disabled}
              value={""}
              fieldName="Long Code"
            />
          </div>
          <div className="add-ip-textbox">
          <TextAreaComponent
            disabled={disabled}
            value={""}
            fieldName="Description"
          />
          </div>
          
        </div>

        <div className="add-ip-content">
          {
            <div
             
              className="button-ip"
            >
              <div
                
                className="submit-ip"
              >
                {/* <Submit onCancel={onCancel} onSave={handleSubmit} /> */}
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};
