import { Fetcher } from "../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../utils/Helper/Helper";
import {
  AddressData,
  EmergencyData,
  SubstanceData,
} from "../../MasterNameIState";

const fetcher = new Fetcher();

export class SubstanceMainFunction {
  async getSubstanceMain(
    setTableData: (data: SubstanceData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    let response = await fetcher.get(
      `/name-master/${nameId}/substance-involvements`
    );
    let res: SubstanceData[] = response.Data;
    const TableJson = this.createTableJson(res);
    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: SubstanceData[]) {
    return data?.map((val: SubstanceData, i: number) => {
      return {
        Case: val.Case,
        Type: val.Type,
        DateEntered:formatDate(val.DateEntered) ,
      };
    });
  }
}
