import { Fetcher } from "../../../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../../../utils/const";
import { GetORITableDataI, SetTableData } from "./ORIInterface";

const fetcher = new Fetcher();

export class ORIFunction {
  async getORITableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    agencyId: string
  ) {
    setLoading(true);
    let response = await fetcher.get(`/agency/${agencyId}/ori`);
    const TableJson = this.createTableJson(response?.Data);
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: GetORITableDataI[]) {
    return data?.map((val: GetORITableDataI, i: number) => {
      return {
        ORI: val.OriNumber,
        Description: val.OriDescription,
        Status: val.Status ? "Active" : "InActive",
        ID: val.AgencyOriInformationId,
      };
    });
  }

  async deleteORI(
    AgencyOriInformationId: string,
    setLoading: (data: boolean) => void,
    setdeletePopup: (data: boolean) => void
  ) {
    setLoading(true);
    const response = await fetcher.delete(
      `/agency/${AgencyOriInformationId}/ori`,
      {},
      {
        message: successDeleteMessage,
        show: true,
      }
    );
    if (response?.Success) {
      setLoading(false);
      setdeletePopup(false);
    }
  }
}
