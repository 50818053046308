import { useState } from "react";
import { TabsComponents } from "../../../../CommonComponents/TabsComponents/TabsComponents";
import { AddEditForm } from "./AddEditForm/AddEditForm";
import PolicyPermissionTab from "./Tab/PolicyPermissionTab/PolicyPermissionTab";

interface TabsIndexIProps {
  viewPolicyComponent: boolean;
}
export const TabsIndex: React.FC<TabsIndexIProps> = ({ viewPolicyComponent }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <>
      <div>
        <TabsComponents
          activeTab={activeTab}
          tabList={[
            { name: "Policy", id: 1 },
            { name: "Assign Policy Permission ", id: 2 },
          ]}
          handleTabChange={setActiveTab}
        />

        {activeTab === 0 ? (
          <AddEditForm viewPolicyComponent={viewPolicyComponent} />
        ) : activeTab === 1 ? (
          <PolicyPermissionTab />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
