import React, { useState, useEffect } from "react";
import add from "../../../../assets/icons/Icon.svg";
import "../SystemLevelTab/SystemLevelTab.css";
import { SetTableData } from "./SystemLevelIState";
import { coloumn } from "./Config";
import { SystemLevelTabFunction } from "./SystemLevelTabFunction";
import { CreateEditSystemLevel } from "./components/CreateEditSystemLevel/CreateEditSystemLevel";
import { Filter } from "./components/Filter/Filter";
import useDelayedEffect from "../../../../hooks/useDelayedEffect";
import { ModalComponent } from "../../../CommonComponents/Modal/ModalComponent";
import { Loader } from "../../../CommonComponents/Loader/Loader";
import { DeleteConfirmation } from "../../../CommonComponents/Modal/DeleteConfirmation/DeleteConfirmation";
import { ButtonComponents } from "../../../CommonComponents/Fields/Button/ButtonComponents";
import { Table } from "../../../CommonComponents/Table/Table";
import { useGetPerticularUserPermission } from "../../../../hooks/Permission/useGetPerticularUserPermission";

export const SystemLevelCodee: React.FC = () => {
  const { Delete, Update, Create } = useGetPerticularUserPermission("Code.System");
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [showAddEditView, setshowAddEditView] = useState<boolean>(false);
  const [pageNumber, setpageNumber] = useState<number>(1);
  const [pageCount, setpageCount] = useState<number>(10);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [editMode, seteditMode] = useState<boolean>(false);
  const [addMode, setaddMode] = useState<boolean>(false);
  const [viewMode, setviewMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [Category, setCategory] = useState<string>("");
  const [SystemLevelCode, setSystemLevelCode] = useState<string>("");
  const [Status, setStatus] = useState<string>("1");
  const [getRowId, setgetRowId] = useState<string>("");
  const [deletePopup, setdeletePopup] = useState<boolean>(false);
  const [OrderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [OrderBy, setOrderBy] = useState<string>("Short Code");

  const systemLevelFunction = new SystemLevelTabFunction();

  const Edit = (id: string) => {
    setaddMode(false);
    seteditMode(true);
    setshowAddEditView(true);
    setviewMode(false);
    setgetRowId(id);
  };

  const onRowClicked = (data: SetTableData) => {
    if (!deletePopup) {
      setgetRowId(data.SystemLevelCodeId);
      setaddMode(false);
      seteditMode(false);
      setshowAddEditView(true);
      setviewMode(true);
    }
  };

  const handleDelete = () => {
    systemLevelFunction
      .deleteSystemLevel(getRowId, setLoading, setdeletePopup)
      .then(() => {
        getSystemLevelData();
      });
  };

  const DeleteRow = (id: string) => {
    setdeletePopup(true);
    setgetRowId(id);
  };

  const getSystemLevelData = () => {
    systemLevelFunction.getSystemLevelTableData(
      setTableData,
      setLoading,
      setTableTotalRecord,
      pageNumber,
      pageCount,
      Category,
      Status,
      SystemLevelCode,
      OrderByDescending,
      OrderBy
    );
  };

  useDelayedEffect(getSystemLevelData, 700, [
    Category,
    Status,
    SystemLevelCode,
  ]);

  useEffect(() => {
    if (!showAddEditView) {
      getSystemLevelData();
    }
  }, [pageNumber, pageCount, showAddEditView, OrderByDescending]);

  const closeDeletePopup = () => {
    setdeletePopup(false);
  };

  const addSystemLevel = () => {
    setaddMode(true);
    seteditMode(false);
    setviewMode(false);
    setshowAddEditView(true);
    setgetRowId("");
  };

  return (
    <>
      <div>
        <Loader loading={loading} />
        <ModalComponent
         onClose={closeDeletePopup}
          open={deletePopup}
          title="Delete"
          innerContant={
            <DeleteConfirmation
              onClose={closeDeletePopup}
              onDelete={handleDelete}
            />
          }
           closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
        />
        {showAddEditView ? (
          <CreateEditSystemLevel
            addmode={addMode}
            editMode={editMode}
            viewMode={viewMode}
            setaddMode={setaddMode}
            seteditMode={seteditMode}
            setviewMode={setviewMode}
            setshowAddEditView={setshowAddEditView}
            getRowId={getRowId}
          />
        ) : (
          <>
            <div className="SystemLevelTab">
              <Filter
                goToAdd={addSystemLevel}
                SystemLevelCode={SystemLevelCode}
                setSystemLevelCode={setSystemLevelCode}
                Category={Category}
                setCategory={setCategory}
                Status={Status}
                setStatus={setStatus}
              />
              {Create && (
                <div className="clearbutton" style={{ width:"28%"}} >
                  <ButtonComponents
                    name="Add System Level Code"
                    icon={add}
                    handleClick={addSystemLevel}
                    showBackgroundColor={true}
                    textColor="white"
                  />
                </div>
              )}
            </div>
            <div className="System_table">
              <Table
                columns={coloumn(Edit, DeleteRow, Update, Delete)}
                columnsValue={tableData}
                setpageCount={setpageCount}
                setpageNumber={setpageNumber}
                tableTotalRecord={tableTotalRecord}
                onRowClicked={onRowClicked}
                setOrderByDescending={setOrderByDescending}
                OrderByDescending={OrderByDescending}
                setOrderBy={setOrderBy}
                OrderBy={OrderBy}
                cursorPointer={true}
                 
              
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
