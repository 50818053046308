import { useEffect, useState } from "react";
import { ExternalContact } from "./ExternalContact/ExternalContact";
import { AdditionalInfo } from "./AdditionalInfo/AdditionalInfo";
import { InvolvementMain } from "./Involvement/InvolvementMain";
import { InvolvementMainFunction } from "./Involvement/InvolvementMainFunction";
import { InvolvementMainIParams } from "./Involvement/InvolvementMainI";
import { useNavigate, useParams } from "react-router-dom";
import { AdditionalMainFunction } from "./AdditionalInfo/AdditionalFunction";
import { IdentificationMainFunction } from "./IdentificationContact/IdentificationFunction";
import { SubstanceDetainmentFunction } from "./SubstanceandDetainment/SubstanceDetainmentFunction";
import { IdentificationAndContact } from "./IdentificationContact/IdentificationAndContact";
import { SubstanceMainDetainment } from "./SubstanceandDetainment/SubstanceMainDetainment";
import { AddNewNameFunction } from "./AddNewNameFunction";
import useIncidentsParticipantStateManagement from "../../../../../../hooks/IncidentsParticipant/useIncidentsParticipantStateManagement";
import { TabsComponents } from "../../../../../CommonComponents/TabsComponents/TabsComponents";
import { Loader } from "../../../../../CommonComponents/Loader/Loader";
import { Submit } from "../../../../../CommonComponents/Fields/Submit/Submit";
import { ParticipantionvalidationIRes } from "./AddNewNameI";
import { successCreatedMessage } from "../../../../../../utils/const";
import { openNotificationWithIcon } from "../../../../../CommonComponents/Toster/Toster";
import { ExternalContactFunction } from "./ExternalContact/ExternalContactFunction";

export const AddNewName: React.FC = () => {
  const { Mainfields, setMainfields } =
    useIncidentsParticipantStateManagement();

  const [Participantionvalidation, setParticipantionvalidation] =
    useState<ParticipantionvalidationIRes>({
      Involvement: {
        InvolvementType: false,
        LastName: false,
        Firstname: false,
        Biases: false,
        address: false,
        ScarsMarksandTattoos: false,
        locationcatigory: false,
      },
      IdentificationAndContact: {
        phone: false,
        email: false,
      },
      EmergencyContact: {
        email: false,
        firstname: false,
        Phone: false,
        locationcatigory: false,
      },
      AdditionalInfo: {
        email: false,
        Phone: false,
      },
    });
  const navigation = useNavigate();
  const involvementMainFunction = new InvolvementMainFunction();
  const substanceMainFunction = new SubstanceDetainmentFunction();
  const identificationMainFunction = new IdentificationMainFunction();
  const additionalMainFunction = new AdditionalMainFunction();
  const externalContactFunction = new ExternalContactFunction();
  const addNewNameFunction = new AddNewNameFunction();
  const { incidentId, participantId } = useParams<InvolvementMainIParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  const RediretToEDitPage = (participantId: string) => {
    navigation(`/incidents/${incidentId}/participants/${participantId}/edit`);
  };

  const onInvolvementSave = async () => {
    if (incidentId) {
      if (Mainfields.InvolvementTab.onChange) {
        await involvementMainFunction.InvolvementSave(
          setLoading,
          Mainfields.InvolvementTab,
          incidentId,
          RediretToEDitPage,
          Participantionvalidation,
          setParticipantionvalidation
        );
      }
      let temp = false;
      Mainfields.MultipleEmergencyContact.map((val) => {
        if (val.onChange) {
          temp = true;
        }
      });
      if (participantId) {
        console.log(temp, Mainfields.MultipleEmergencyContact);
        if (temp) {
          console.log("sdjfh");
          externalContactFunction.addExternalContact(
            setLoading,
            Mainfields.MultipleEmergencyContact,
            incidentId,
            participantId,
            Participantionvalidation,
            setParticipantionvalidation,
            Mainfields
          );
        }
        if (Mainfields.SubstanceTab.onChange) {
          substanceMainFunction.SubstanceSave(
            setLoading,
            Mainfields.SubstanceTab,
            incidentId,
            participantId
          );
        }
        if (Mainfields.IdentificationAndContact.onChange) {
          identificationMainFunction.IdentificationSave(
            setLoading,
            Mainfields.IdentificationAndContact.IdentificationNumber,
            incidentId,
            participantId,
            Mainfields
          );
        }
        Mainfields.AdditionalInfo.changes &&
          additionalMainFunction.AdditionalSave(
            setLoading,
            incidentId,
            participantId,
            Mainfields
          );
      }
      if (
        involvementMainFunction.InvolvementValidation(
          Mainfields.InvolvementTab
        ) ||
        Mainfields.AdditionalInfo.changes ||
        Mainfields.IdentificationAndContact.onChange ||
        temp ||
        Mainfields.SubstanceTab.onChange ||
        externalContactFunction.ExternalContactValidation(
          Mainfields.MultipleEmergencyContact
        )
      ) {
        openNotificationWithIcon("success", successCreatedMessage);
      }
    }
  };

  const InitialRender = () => {
    if (incidentId && participantId) {
      addNewNameFunction.getAllAddNewNameTabData(
        setLoading,
        incidentId,
        participantId,
        setMainfields,
        Mainfields
      );
    }
  };

  useEffect(() => {
    InitialRender();
  }, []);

  return (
    <>
      <Loader loading={loading} />

      <div style={{ backgroundColor: "#B3C9E2" }}>
        <TabsComponents
          activeTab={activeTab}
          tabList={[
            {
              name: "Involvement",
              id: 1,
              disable: false,
            },
            {
              name: "Identifications and Contact",
              id: 1,
              disable: participantId ? false : true,
            },
            {
              name: "Substance and Detainment",
              id: 2,
              disable: participantId ? false : true,
            },
            {
              name: "Emergency Contact",
              id: 3,
              disable: participantId ? false : true,
            },
            {
              name: "Additional Info",
              id: 4,
              disable: participantId ? false : true,
            },
          ]}
          handleTabChange={setActiveTab}
        />
      </div>

      {activeTab === 0 && (
        <InvolvementMain
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          Participantionvalidation={Participantionvalidation}
        />
      )}
      {activeTab === 1 && (
        <IdentificationAndContact
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          Participantionvalidation={Participantionvalidation}
        />
      )}
      {activeTab === 2 && (
        <SubstanceMainDetainment
          Mainfields={Mainfields}
          setMainfields={setMainfields}
        />
      )}
      {activeTab === 3 && (
        <ExternalContact
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          Participantionvalidation={Participantionvalidation}
        />
      )}
      {activeTab === 4 && (
        <AdditionalInfo
          Mainfields={Mainfields}
          setMainfields={setMainfields}
          Participantionvalidation={Participantionvalidation}
        />
      )}

      <div className="edit-button_" style={{ marginBottom: "2%" }}>
        <Submit
          saveLabel="Add"
          onSave={() => {
            onInvolvementSave();
          }}
        />
      </div>
    </>
  );
};
