


import { MultiSubstance } from "./MultipleSubstance";
import { SubstanceInvolvementIProps } from "./SubstanceIProps";

export const SubstanceInvolvement: React.FC<SubstanceInvolvementIProps> = ({
  Mainfields,
  setMainfields,
}) => {
  return (
    <>
      <MultiSubstance Mainfields={Mainfields} setMainfields={setMainfields} />
    </>
  );
};
