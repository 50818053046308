import React, { useEffect, useState } from "react";
import add from "../../../../../assets/icons/Icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FilterIProps } from "./FilterIProps";
import { InvolvementMainIParams } from "../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
interface GetRaceDataIState {
  NciccodeId: string;
  ShortCode: string;
  LongCode: string;
  Description: string;
  DisplayOrder: number;
  SystemLevelCodeId: string;
}
export const Filter: React.FC<FilterIProps> = ({
  Name,
  setName,
  onSearch,
  clearAllFilter,
}) => {
  const navigate = useNavigate();
  const { incidentId } = useParams<InvolvementMainIParams>();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [vehicleType, setVehicleType] = useState<GetRaceDataIState[]>([]);
  const clearAllFilters = () => {
    setName("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
    clearAllFilter();
  };

  useEffect(() => {
    if (Name.trim() !== "") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name]);

  const addProsecutionHandler = () => {
    navigate(`/incidents/${incidentId}/prosecution/add`);
  };
 



  return (
    <>
      <div className="Filter">
        <div className="filds___" style={{ width: "51%" }}>
          <TextBoxComponent
            fieldName={"Search  Prosecution"}
            value={Name}
            onChangeValue={setName}
          />
         
          <div className="width_field">
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              handleClick={() => {
                onSearch(true);
              }}
            />
          </div>
          <div className="clearbutton1">
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
          <ButtonComponents
            name="Add Prosecution"
            icon={add}
            textColor="white"
            showBackgroundColor={true}
            handleClick={addProsecutionHandler}
          />
        </div>
      </div>
    </>
  );
};
