import { Fetcher } from "../../../../services/Fetcher";
import { setContactTypeDataIState } from "./MultiplePhoneNumberI";

const fetcher = new Fetcher();

export class MultiplePhoneNumberFunction {
  async getContactTypeData(
    setContactTypeData: (data: setContactTypeDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get("/code/system/category/AEOHBUE3V5");
    let res: setContactTypeDataIState[] = response.Data;
    setContactTypeData(response.Data);
    if (setLoading) {
      setLoading(false);
    }
  }
}
