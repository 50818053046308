import { InvolvementMainFunction } from "../../../Pages/Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainFunction";
import { getAddressIRes } from "../../../Pages/Incidents/Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { setStateTable } from "./ShowAddressPariticipantI";

const involvementMainFunction = new InvolvementMainFunction();

export class ShowAddressPariticipantFunction {
  async getAddress(
    participantId: string,
    setTableData: (data: setStateTable[]) => void,
    setloading: (data: boolean) => void
  ) {
    setloading(true);
    let data = await involvementMainFunction.getAddress(participantId);
    setloading(false);
    setTableData(this.createTableJson(data));
  }

  createTableJson(data: getAddressIRes[]) {
    return data?.map((val: getAddressIRes, i: number) => {
      return {
        "Street Number": val.Street1Name,
        Street: val.Street2Name,
        Apartment: val.HouseNumber ? val.HouseNumber : val.Address1,
        City: val.City ? val.City : val.OtherCity,
        "Zip Code": val.ZipCode ? val.ZipCode : val.OtherZip,
      };
    });
  }
}
