import React, { useState, useEffect } from "react";

import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { useParams } from "react-router-dom";
import { AddEditFormIParams } from "../../AddEditForm/AddEditFormI";
import { SetTableData } from "./RolePermissionIProps";
import { coloum } from "./Config";
import { RolePermissionTabFunction } from "./RolePermissionFunction";
import { Filter } from "./Components/Filter";
import useDelayedEffect from "../../../../../../../hooks/useDelayedEffect";

const RolePermission = () => {
  const [orderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { roleId } = useParams<AddEditFormIParams>();
  const [Permission, setPermission] = useState<string>("");

  const RoleIFunction = new RolePermissionTabFunction();

  const applyFilterOnUserpolicyTable = () => {
    RoleIFunction.applyFilterOnUserpolicyTable(
      Permission,
      FiltertableData,
      setTableData
    );
  };

  const getRolePerData = () => {
    if (roleId) {
      RoleIFunction.getRoleTableData(
        setTableData,
        setLoading,
        roleId,
        setFiltertableData
      );
    }
  };

  useEffect(() => {
    getRolePerData();
  }, [roleId]);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [Permission]);

  return (
    <>
      <Loader loading={loading} />

      <div className="Audit-filter">
        <Filter Permission={Permission} setPermission={setPermission} />
      </div>
      <div className="table">
        <Table
          columns={coloum()}
          columnsValue={tableData}
          clientSideSorting={true}
          showPagination={false}
          setOrderByDescending={setOrderByDescending}
          OrderByDescending={orderByDescending}
          cursorPointer={false}
          
        />
      </div>
    </>
  );
};

export default RolePermission;
