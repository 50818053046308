import { Fetcher } from "../../../../../../../services/Fetcher";
import { convertToISOFormat } from "../../../../../../../utils/Helper/Helper";
import {
  GetRaceDataIState,
  IdentificationAndContactIState,
  InvolvementMainIState,
} from "../Involvement/InvolvementMainI";
import {
  ContactIRes,
  IdentificationIstate,
  PersonIdentificationIRes,
} from "./IdentificationIProps";

const fetcher = new Fetcher();

export class IdentificationMainFunction {
  async getPersonIdentification(
    setPersonIdentification: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/PersonIdentification"
    );
    let res: GetRaceDataIState[] = response.Data;
    setPersonIdentification(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async getPersonIdentificationState(
    setPersonIdentification: (data: GetRaceDataIState[]) => void,
    setLoading?: (data: boolean) => void
  ) {
    if (setLoading) {
      setLoading(true);
    }
    const response = await fetcher.get(
      "/code/ncic/category/PersonIdentificationState"
    );
    let res: GetRaceDataIState[] = response.Data;
    setPersonIdentification(res);
    if (setLoading) {
      setLoading(false);
    }
  }

  async IdentificationSave(
    setLoading: (data: boolean) => void,
    fields: IdentificationIstate[],
    incidentId: string,
    participantId: string,
    Mainfields: InvolvementMainIState
  ) {
    setLoading(true);
    let body = fields
      .map((field) => {
        if (
          field.EffectiveEndDate ||
          field.EffectiveStartDate ||
          field.PrimaryIdentification ||
          field.IdentificationClass ||
          field.LocationStateUspostalServiceCode ||
          field.NcicpersonIdentificationCodeId
        )
          return {
            PersonIdentificationId: field.PersonIdentificationId
              ? field.PersonIdentificationId
              : null,
            PersonId: Mainfields.InvolvementTab.Involvement.PersonId
              ? Mainfields.InvolvementTab.Involvement.PersonId
              : null,
            NcicpersonIdentificationCodeId: field.NcicpersonIdentificationCodeId
              ? field.NcicpersonIdentificationCodeId
              : null,
            LocationCanadianProvinceCode: field.LocationCanadianProvinceCode
              ? field.LocationCanadianProvinceCode
              : null,
            LocationStateUspostalServiceCode:
              field.LocationStateUspostalServiceCode
                ? field.LocationStateUspostalServiceCode
                : null,
            IdentificationClass: field.IdentificationClass
              ? field.IdentificationClass
              : null,
            PrimaryIdentification: field.PrimaryIdentification
              ? field.PrimaryIdentification
              : null,
            EffectiveStartDate: field.EffectiveStartDate
              ? convertToISOFormat(field.EffectiveStartDate)
              : null,
            EffectiveEndDate: field.EffectiveEndDate
              ? convertToISOFormat(field.EffectiveEndDate)
              : null,
          };
      })
      .filter((val) => val);

    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/identification`,
      body
    );
    let res: PersonIdentificationIRes[] = response?.Data;
    this.ContactsSave(
      setLoading,
      Mainfields.IdentificationAndContact,
      incidentId,
      participantId
    );
    setLoading(false);
  }

  async ContactsSave(
    setLoading: (data: boolean) => void,
    fields: IdentificationAndContactIState,
    incidentId: string,
    participantId: string
  ) {
    setLoading(true);

    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/contacts`,
      {
        Contacts: [
          ...fields.Email.map((Emailval) => {
            if (Emailval.Email && Emailval.Type) {
              return {
                EntityId: participantId ? participantId : null,
                ContactValue: Emailval.Email ? Emailval.Email : null,
                ContactTypeCodeId: Emailval.Type ? Emailval.Type : null,
                ContactType: "Email",
              };
            }
          }).filter((val) => val),

          ...fields.PhoneNumber.map((PhoneNumberval) => {
            if (PhoneNumberval.Number && PhoneNumberval.Type) {
              return {
                EntityId: participantId ? participantId : null,
                ContactValue: PhoneNumberval.Number
                  ? PhoneNumberval.Number
                  : null,
                ContactTypeCodeId: PhoneNumberval.Type
                  ? PhoneNumberval.Type
                  : null,
                CountryCodeId: PhoneNumberval.Country
                  ? PhoneNumberval.Country
                  : null,
                Ext: PhoneNumberval.Ext ? PhoneNumberval.Ext : null,
                ContactType: "Phone",
              };
            }
          }).filter((val) => val),
        ],
        EntityModel: {
          EntityType: "Paricipant",
          EntityId: participantId,
        },
      }
    );

    let res: GetRaceDataIState[] = response.Data;
    setLoading(false);
  }

  async getIdentification(incidentId: string, participantId: string) {
    let response = await fetcher.get(
      `/incident/${incidentId}/participant/${participantId}/identification`
    );
    let personIdentificationIRes: PersonIdentificationIRes[] = response.Data;
    let resContacts = await this.getContacts(incidentId, participantId);

    return { personIdentificationIRes, resContacts };
  }

  async getContacts(incidentId: string, participantId: string) {
    let response = await fetcher.post(
      `/incident/${incidentId}/participant/${participantId}/getcontacts`,
      {
        EntityType: "Participant",
        EntityId: participantId,
      }
    );
    let resContacts: ContactIRes[] = response.Data;
    return resContacts;
  }
}
