

import { ColDef } from "ag-grid-community";
import { GetRolePermissionTableDataI } from "./RolePermissionIProps";


export const coloum: () => ColDef[] = () => {
    return [
      { field: "Permission" },
      { field: "Description" },
      {
        field: "General",
        tooltipField: "PolicyNames",
      },
      {
        field: "Create",
        tooltipField: "PolicyNames",
      },
      {
        field: "Read",
        tooltipField: "PolicyNames",
      },
      {
        field: "Update",
        tooltipField: "PolicyNames",
      },
      {
        field: "Delete",
        tooltipField: "PolicyNames",
      },
    ];
  };

export const createTableJson = (data: GetRolePermissionTableDataI[]) => {
  return data?.map((val: GetRolePermissionTableDataI, i: number) => {
    return {
      PermissionID: val.PermissionID,
      Permission: val.PermissionName,
      Description: val.Description,
      General: val.General,
      Create: val.Create,
      Read: val.Read,
      Update: val.Update,
      Delete: val.Delete,
      PolicyNames: val.PolicyNames
    };
  });
};
