import React from "react";
import { DeleteModalProps } from "../PolicyDeleteModal/DeleteModalProps";
// import { DeleteModalProps } from "./DeleteModalProps";

export const DeleteModalRole: React.FC<DeleteModalProps> = ({
  title,
  data,
  nameField,
  descriptionField,
}) => {
  const isTable = title === "User" || title === "Role" || title === "Policy";

  return (
    <div className="role-modal-wrapper">
      <div className="role-modal-container">
        <h3 className="role-modal-title">{title}</h3>
        {isTable ? (
          <table className="role-modal-table">
            <thead>
              <tr>
                <th className="role-modal-table-header">{nameField}</th>
                {descriptionField && (
                  <th className="role-modal-table-header">{descriptionField}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="role-modal-table-cell">{item[nameField]}</td>
                  {descriptionField && (
                    <td className="role-modal-table-cell">{item[descriptionField]}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          data.map((item, index) => (
            <div key={index} className="role-list-item">
              <p className="role-list-item-text">
                <strong>{nameField}:</strong> {item[nameField]}
              </p>
              {descriptionField && (
                <p className="role-list-item-text">
                  <strong>{descriptionField}:</strong> {item[descriptionField]}
                </p>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
