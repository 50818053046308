import React, { useEffect, useState } from "react";

export const IncidentSummary: React.FC = () => {

  return (
    <div className="incident">
      <div className="incident_heading">
        <div className="overview-header__">
          <p>
            <strong>Incident Summary</strong>
          </p>
        </div>
        <div className="incident_summary">
          Lorem ipsum dolor sit amet, dfghjkl adipi Lorem iyydye eyeyue vvvvvvvvvvvvvvvvvvvvvvvv psum dolor sit amet, consectetur  amet, consectetur  amet, consectetur  amet, consectetur adipi Lorem ipsum dolor sit amet, consectetur adipi Lorem ipsum dolor sit amet, consectetur adipig e Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore lit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>
      </div>
    </div>
  );
};
