import { useEffect, useState } from "react";
import { InvolvementIProps, SetTableData } from "./InvolvementI";
import { InvolvementMainFunction } from "../../InvolvementMainFunction";
import { GetRaceDataIState } from "../../../InvolvementMainI";
import { DropdownComponent } from "../../../../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { CheckBoxComponent } from "../../../../../../../../CommonComponents/Fields/Checkbox/Checkbox";
import { TextBoxComponent } from "../../../../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { ButtonComponents } from "../../../../../../../../CommonComponents/Fields/Button/ButtonComponents";
import useDelayedEffect from "../../../../../../../../../hooks/useDelayedEffect";

export const Involvement: React.FC<InvolvementIProps> = ({
  setMainfieldsBusiness,
  MainfieldsBusiness,
  businessValidation,
}) => {
  const involvementMainFunction = new InvolvementMainFunction();

  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const [BusinessData, setBusinessDaata] = useState<GetRaceDataIState[]>([]);
  const [onNameChange, setonNameChange] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [tableTotalRecord, setTableTotalRecord] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const onChangeValue = (
    name: string,
    value: string | number | boolean | null
  ) => {
    setMainfieldsBusiness({
      ...MainfieldsBusiness,
      Involvement: {
        ...MainfieldsBusiness.Involvement,
        [name]: value,
      },
    });
  };

  const initialRender = () => {
    involvementMainFunction.getRaceData(setRaceDataData);
    involvementMainFunction.getBusinessType(setBusinessDaata);
  };

  useEffect(() => {
    initialRender();
  }, []);

  const masterCount = () => {
    if (MainfieldsBusiness.Involvement.Firstname && onNameChange) {
      involvementMainFunction.getMatches(
        MainfieldsBusiness.Involvement.Firstname,
        setTableTotalRecord,
        setTableData
      );
    }
  };

  useDelayedEffect(masterCount, 700, [
    MainfieldsBusiness.Involvement.Firstname,
  ]);

  return (
    <>
        <div className="subheader business_subheader">Involvement</div>
      <div className="Involvement_main">
        <div className="Involvement_left">
          <div style={{ width: "39.5%" }} className="Involvement_left_row">
            <DropdownComponent
              dataSource={RaceDataData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              handleRowClick={(e) => {
                onChangeValue("InvolvementType", e);
              }}
              validation={businessValidation.involvement.InvolvementType}
              value={MainfieldsBusiness.Involvement.InvolvementType}
              fieldName="Involvement Type*"
            />
            <div
              className="Involvement_left_row_checkbox"
              style={{ marginTop: "38px" }}
            >
              <CheckBoxComponent
                fieldName="Private"
                value={MainfieldsBusiness.Involvement.Private}
                onChangeValue={(e) => {
                  onChangeValue("Private", e);
                }}
              />
              <CheckBoxComponent
                fieldName="Proc ORI"
                onChangeValue={(e) => {
                  onChangeValue("ProcORI", e);
                }}
                value={MainfieldsBusiness.Involvement.ProcORI}
              />
            </div>
          </div>
          <div
            className="Involvement_left_row align_business_role"
            style={{ width: "60%" }}
          >
            <div className="employe-column">
              <TextBoxComponent
                onChangeValue={(e) => {
                  setonNameChange(true);
                  onChangeValue("Firstname", e);
                }}
                value={MainfieldsBusiness.Involvement.Firstname}
                fieldName="Business Name"
                validation={businessValidation.involvement.BusinessName}
              />
              <p className="view-matches">--Matches {tableTotalRecord}</p>
            </div>
            <DropdownComponent
              handleRowClick={(e) => {
                onChangeValue("BusinessType", e);
              }}
              dataSource={BusinessData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              validation={businessValidation.involvement.BusinessType}
              value={MainfieldsBusiness.Involvement.BusinessType}
              fieldName="Business Type"
            />

            <div
              className="view_matches_btn_container"
              style={{ width: "100%" }}
            >
              <ButtonComponents
                name="View Matches"
                showBackgroundColor={true}
                color="#F0F0F0"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
