import { Fetcher } from "../../../../../../../../services/Fetcher";
import { getAddressIRes } from "../../../../AddEditParticipantName/AddNewName/Involvement/InvolvementMainI";
import { AddressTableData } from "../ParticipantOverviewIState";

const fetcher = new Fetcher();
export class AddressFunction {
  async getAddress(
    participantId: string,
    setTableData: (data: AddressTableData[]) => void,
    setTableTotalRecord: (data: number) => void
  ) {
    let response = await fetcher.post(
      `/commonentity/getaddresses`,

      {
        EntityType: "Participant",
        EntityId: participantId,
        EntitySubType: null,
        EntitySubTypeId: null,
      }
    );
    let res: getAddressIRes[] = response?.Data;

    const TableJson = this.createTableJson(res);

    setTableTotalRecord(response?.TotalRecords);
    setTableData(TableJson);
  }

  createTableJson(data: getAddressIRes[]) {
    return data?.map((val: getAddressIRes, i: number) => {
      return {
        "Street Address": val?.Street2Name,
        City: val?.City,
        State: val?.StateName,
        Zip: val?.ZipCode,
      };
    });
  }
}
