
import React, { useEffect } from "react";
import { DeleteConfirmationIProps } from "../../DeleteConfirmationI";
import { DeleteModal } from "./DeleteModalPolicy";

export const PolicyDeleteConfirmation: React.FC<DeleteConfirmationIProps> = ({
  onClose,
  onDelete,
  showAlreadyUserError,
  handlePolicyExistDelete,
}) => {
  let confirmationMessage = "";
  useEffect(() => {
    if (showAlreadyUserError && Object.keys(showAlreadyUserError).length !== 0) {
      confirmationMessage = "Currently used this policy";
    }
  }, [showAlreadyUserError]);

  return (
    <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className="modal-content" style={{ maxHeight:"50vh",overflowY:"auto"}} >
        <p className="modal-message">
          {showAlreadyUserError && Object.keys(showAlreadyUserError).length !== 0 ? (
            <div>
              <span>{confirmationMessage}</span>
              <div className="list-container">
                {Object.entries(showAlreadyUserError).map(([key, value]) => {
                  if (value.length > 0) {
                    const nameField = key === "User" ? "FirstName" : key === "Role" ? "RoleName" : "DisplayName";
                    const descriptionField = key === "User" ? "EmailAddress" : "Description";
                    return (
                      <DeleteModal
                        key={key}
                        title={key}
                        data={value}
                        nameField={nameField}
                        descriptionField={descriptionField}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          ) : (
            `Are you sure you want to delete this item?`
          )}
        </p>
        <div className="modal-actions">
          {!showAlreadyUserError || Object.keys(showAlreadyUserError).length === 0 ? (
            <>
              <button className="modal-button" onClick={onClose}>
                No
              </button>
              <button className="modal-button-confirm" onClick={onDelete}>
                Yes
              </button>
            </>
          ) : (
            <>
              <button className="modal-button" onClick={onClose}>
                Cancel
              </button>
              <button className="modal-button-confirm" onClick={handlePolicyExistDelete}>
                OK
              </button> 
            </>
          )}
        </div>
      </div>
    </div>
  );
};
