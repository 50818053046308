import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface useGetUserPermissionI {
  AGENCY_VIEW: boolean;
  Code_System_VIEW: boolean;
  USER_VIEW: boolean;
  Code_Agency_VIEW: boolean;
  IncidentArticle_VIEW: boolean;
  IncidentCAD_VIEW: boolean;
  IncidentLocation_VIEW: boolean;
  IncidentMedia_VIEW: boolean;
  IncidentMenuConfig_VIEW: boolean;
  IncidentNarrative_VIEW: boolean;
  IncidentParticipant_VIEW: boolean;
  IncidentProsecution_VIEW: boolean;
  IncidentVehicle_VIEW: boolean;
}

export const useGetAllViewPermission = (): useGetUserPermissionI => {
  const {
    "Code.Agency": CodeAgency,
    "Code.System": CodeSystem,
    Agency,
    User,
    "Incident.Article": IncidentArticle,
    "Incident.CAD": IncidentCAD,
    "Incident.Location": IncidentLocation,
    "Incident.Media": IncidentMedia,
    "Incident.Menu.Config": IncidentMenuConfig,
    "Incident.Narrative": IncidentNarrative,
    "Incident.Participant": IncidentParticipant,
    "Incident.Procecution": IncidentProsecution,
    "Incident.Vehicle": IncidentVehicle,
  } = useSelector((store: RootState) => store.UserPermissionReducer);

  const permissions: useGetUserPermissionI = {
    AGENCY_VIEW: Agency?.includes("Read"),
    Code_System_VIEW: CodeSystem?.includes("Read"),
    USER_VIEW: User?.includes("Read"),
    Code_Agency_VIEW: CodeAgency?.includes("Read"),
    IncidentArticle_VIEW: IncidentArticle?.includes("Read"),
    IncidentCAD_VIEW: IncidentCAD?.includes("Read"),
    IncidentLocation_VIEW: IncidentLocation?.includes("Read"),
    IncidentMedia_VIEW: IncidentMedia?.includes("Read"),
    IncidentMenuConfig_VIEW: IncidentMenuConfig?.includes("Read"),
    IncidentNarrative_VIEW: IncidentNarrative?.includes("Read"),
    IncidentParticipant_VIEW: IncidentParticipant?.includes("Read"),
    IncidentProsecution_VIEW: IncidentProsecution?.includes("Read"),
    IncidentVehicle_VIEW: IncidentVehicle?.includes("Read"),
  };

  return permissions;
};
