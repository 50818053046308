import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./assets/GlobalCss/index.css";
import { Provider } from "react-redux";
import { store } from "./store";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Components/Pages/SSO/appConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance
  .initialize()
  .then(() => {
    root.render(
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    );
  })
  .catch((e) => {
    console.error("MSAL initialization error: ", e);
  });
