import React, { useEffect, useState } from "react";
import add from "../../../../../../assets/icons/Icon.svg";
import { FilterIProps } from "./filtersI";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import { ParticipantListFunction } from "../../ParticipantListFunction";
import { GetRaceDataIState } from "../../AddEditParticipantName/AddNewName/ExternalContact/ExternalContactI";
import { useGetPerticularUserPermission } from "src/hooks/Permission/useGetPerticularUserPermission";

export const Filter: React.FC<FilterIProps> = ({
  AddNewName,
  Name,
  setName,
  involvementType,
  setInvolvementType,
  ParticipantType,
  setParticipantType,
  onSearch,
  clearAllFilter,
}) => {
  const { Create } = useGetPerticularUserPermission("Incident.Participant");
  const involvementMainFunction = new ParticipantListFunction();
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const [RaceDataData, setRaceDataData] = useState<GetRaceDataIState[]>([]);
  const clearAllFilters = () => {
    setName("");
    setParticipantType("1");
    setInvolvementType("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
    clearAllFilter();
  };

  const handleClick = () => {
    AddNewName();
  };

  useEffect(() => {
    if (
      Name.trim() !== "" ||
      involvementType !== "" ||
      ParticipantType !== "1"
    ) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, involvementType, ParticipantType]);
  const initialRender = () => {
    involvementMainFunction.getRaceData(setRaceDataData);
  };
  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div className="Filter">
        <div className="filds___ ">
          <div>
            <TextBoxComponent
              fieldName={"Search name"}
              value={Name}
              onChangeValue={setName}
            />
          </div>

          <div>
            <DropdownComponent
              dataSource={RaceDataData.map((val) => {
                return {
                  id: val.NciccodeId,
                  value: val.LongCode,
                };
              })}
              fieldName={"Involvement Type"}
              handleRowClick={(newValue) => {
                setInvolvementType(newValue);
              }}
              value={involvementType}
            />
          </div>

          <div>
            <DropdownComponent
              dataSource={[
                {
                  id: "1",
                  value: "All",
                },
                {
                  id: "2",
                  value: "Person",
                },
                {
                  id: "3",
                  value: "Business",
                },
              ]}
              fieldName={"Participant Type"}
              handleRowClick={setParticipantType}
              value={ParticipantType}
            />
          </div>

          <div>
            <ButtonComponents
              name="Search"
              textColor="white"
              showBackgroundColor={true}
              handleClick={() => {
                onSearch(true);
              }}
            />
          </div>
          <div>
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div style={{ marginTop:"4%"}}>
          <div>
            {Create && (
              <ButtonComponents
                icon={add}
                name="Add New"
                textColor="white"
                showBackgroundColor={true}
                handleClick={handleClick}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
