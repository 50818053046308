import { useState } from "react";
import { TabsComponents } from "../../../../../CommonComponents/TabsComponents/TabsComponents";
import { AddEditRole } from "../AddEditForm/AddEditForm";
import { RolePolicy } from "./RolePolicyTab/RolePolicy";
import { UserPolicyTab } from "./UserPolicyTab/UserPolicyTab";
import RolePermission from "./RolePermissionTab/RolePermission";

interface TabsIndexIProps {
  viewRoleComponent: boolean;
}

export const TabsIndex: React.FC<TabsIndexIProps> = ({ viewRoleComponent }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <>
      <div>
        <TabsComponents
          activeTab={activeTab}
          tabList={[
            { name: "Role", id: 1 },
            { name: "Role Policy ", id: 2 },
            { name: "Role User", id: 3 },
            { name: "Role Permission", id: 4 },
          ]}
          handleTabChange={setActiveTab}
        />

        {activeTab === 0 ? (
          <AddEditRole viewRoleComponent={viewRoleComponent} />
        ) : activeTab === 1 ? (
          <RolePolicy />
        ) : activeTab === 2 ? (
          <UserPolicyTab />
        ) : activeTab === 3 ? (
          <RolePermission />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
