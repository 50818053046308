import delete_icon from "../../../../../assets/icons/delete.svg";
import edit_icon from "../../../../../assets/icons/edit.svg";
import eye_icon from "../../../../../assets/icons/Eyeicon.svg"; // Import the eye icon
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";
import { SetTableData } from "./AddNewCaseIState";
import { CheckBoxComponent } from "src/Components/CommonComponents/Fields/Checkbox/Checkbox";

export const coloum = (
  setdeletePopup: (data: boolean) => void,
  setDeleteAgencyId: (id: string) => void,
  edit: (id: SetTableData) => void,
  setshowAddresPopup: (data: boolean) => void,
  setshowAddresPopupId: (id: string) => void,
  handleOpenModal: () => void
): ColDef[] => {
  return [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      maxWidth: 90,
      sortable: false,
    },
    { field: "Case" },
    { field: "Date" },
    { field: "Incident Title" },
    { field: "Agency" },

    {
      field: "Preview",
      cellRenderer: (e: { data: SetTableData }) => {
        return (
          <div className="table-cellRenderer">
            <img
              className="table-cellRenderer-img"
              src={eye_icon}
              title="Preview"
              onClick={() => {
                handleOpenModal();
              }}
            />
          </div>
        );
      },
    },
  ];
};
