import React from "react";
import { OverviewTab } from "../Components/Overview/OverviewTab";
import { Vehicles } from "../Components/Vehicles/Vehicles";
import { Identifications } from "../Components/Identifications/Identifications";
import { Contacts } from "../Components/Contact/Contact";
import { Alerts } from "../Components/Alerts/Alerts";
import { Address } from "../Components/Address/Address";
import { EmergencyContact } from "../Components/EmergencyContact/EmergencyContact";
import { Involvement } from "../Components/Involvement/Involvement";
import { Statutes } from "../Components/Statutes/Statutes";
import { BloodAlocohol } from "../Components/BloodAlocohol/BloodAlochol";
import { Substance } from "../Components/Substance/Substance";
import { Detainment } from "../Components/Detainment/Detainment";
import { Additional } from "../Components/Additional/Additional";
import { ScarsTatoos } from "../Components/ScarsTatoos/ScarsTatoos";
import "./ParticipantOverview.css";

export const ParticipantOverview: React.FC = () => {
  return (
    <>
      <div className="ParticipantOverview">
        <div className="container">
          <div className="column">
            <OverviewTab />
            <ScarsTatoos />
            <Identifications />
            <Address />
            <Contacts />
          </div>
          <div className="half-width">
            <Alerts />
            <Vehicles />
            <EmergencyContact />
          </div>
        </div>
        <Involvement />
        <div className="container">
          <div className="column">
            <Statutes />
            <BloodAlocohol />
          </div>
          <div className="half-width">
            <Substance />
            <Detainment />
          </div>
        </div>
        <Additional />
      </div>
    </>
  );
};
