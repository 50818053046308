import React from "react";
import { Input } from 'antd';
import { TextAreaComponentIProps } from "./TextAreaComponentI";

export const TextAreaComponent: React.FC<TextAreaComponentIProps> = ({
  fieldName,
  value,
  onChangeValue,
  placeholder,
  disabled,
  validation
  }) => {
  const { TextArea } = Input;
  return (
    <>
      <div style={{ width: "100%" }}>
        <p>{fieldName}</p>
        <div>
          <TextArea
            disabled={disabled}
            placeholder={placeholder}
            style={{ border: validation ? "1px solid red" : "" }}
            value={value}
            rows={4}
            onChange={(e) => {
              if (onChangeValue) {
                onChangeValue(e.target.value)
              }
            }} />

        </div>
      </div>
    </>
  );
};
