import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../CommonComponents/Loader/Loader";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { BusinessOverview } from "./Components/BusinessOverview/BusinessOverview";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

export const BusinessOverviewList: React.FC = () => {
  const { IncidentId } = useSelector((store: RootState) => store.CommonRedux);
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const [loading, setLoading] = useState<boolean>(false);
  const navigation = useNavigate();
  const NavigateToUserPage = () => {
    navigation(`/incidents/${IncidentId}/participants`);
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="AddMasterName_main">
        <div className="AddMasterName_child">
          <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path: "/incidents" },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              {
                title: "Participants",
                path: `/incidents/${IncidentId}/participants`,
              },
              {
                title: "Overview",
                path: ``,
              },
            ]}
          />
          <BackButtonComponent
            name="Back to name lookup"
            onClick={NavigateToUserPage}
          />

          <div>
            <BusinessOverview />
          </div>
        </div>
      </div>
    </>
  );
};
