import { useEffect, useState } from "react";
import { MultiDropdownComponent } from "../../../../../../../../CommonComponents/Fields/MultiDropdown/MultiDropdownComponent";
import {
  AdditionalInfoProps,
  GetDisabilityDataI,
} from "../../AdditionalIState";
import { AdditionalMainFunction } from "../../AdditionalFunction";

export const Disability: React.FC<AdditionalInfoProps> = ({
  Mainfields,
  setMainfields,
}) => {
  const additionalMainFunction = new AdditionalMainFunction();
  const [DisabilityData, setDisabilityData] = useState<GetDisabilityDataI[]>(
    []
  );

  const onChange = (key: string, value: string[] | string | boolean) => {
    if (setMainfields && Mainfields) {
      setMainfields({
        ...Mainfields,
        AdditionalInfo: {
          ...Mainfields.AdditionalInfo,
          changes:true,
          Disability: {
            ...Mainfields.AdditionalInfo.Disability,
            [key]: value,
          },
        },
      });
    }
  };

  const initialRender = () => {
    additionalMainFunction.getDisabilityData(setDisabilityData);
  };

  useEffect(() => {
    initialRender();
  }, []);

  return (
    <>
      <div>
        <p className="subheader">Disability</p>
        <div className="width_field">
          <MultiDropdownComponent
            fieldName="Disability Type"
            handleRowClick={(e) => {
              onChange("Disability", e);
            }}
            value={Mainfields.AdditionalInfo.Disability.Disability}
            dataSource={DisabilityData.map((val) => {
              return {
                id: val.NciccodeId,
                value: val.LongCode,
              };
            })}
          />
        </div>
      </div>
    </>
  );
};
