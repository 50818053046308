import { Radio } from "antd";
import { RadioComponentIProps } from "./RadioComponentI";
import { RadioChangeEvent } from "antd";

export const RadioComponent: React.FC<RadioComponentIProps> = ({
  value,
  RadioTextList,
  onChangeValue,
}) => {
  const onChange = (e: RadioChangeEvent) => {
    if (onChangeValue) {
      onChangeValue(e.target.value);
    }
  };

  return (
    <>
      <Radio.Group onChange={onChange} value={value}>
        {RadioTextList?.map((RadioTextListval, index) => {
          return (
            <>
              <Radio value={index + 1}>{RadioTextListval}</Radio>
            </>
          );
        })}
      </Radio.Group>
    </>
  );
};
