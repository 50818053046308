
import { Fetcher } from "../../../../../../../services/Fetcher";
import { formatDate } from "../../../../../../../utils/Helper/Helper";
import { BusinessInvolvementData, BusinessInvolvementTableIRes } from "./BusinessInvolvementIState";


const fetcher = new Fetcher();

export class BusinessInvolvementMainFunction {
  async getBusinessInvolvementMain(
    setTableData: (data: BusinessInvolvementData[]) => void,
    setTableTotalRecord: (data: number) => void,
    nameId:string,
  ) {
    try {
      let response = await fetcher.get(
        `/name-master/${nameId}/business-involvements`
      );
      let res: BusinessInvolvementTableIRes[] = response.Data;

      const TableJson = this.createTableJson(res);

      setTableTotalRecord(response?.TotalRecords);
      setTableData(TableJson);
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  }

  createTableJson(data: BusinessInvolvementTableIRes[]) {
    return data?.map((val: BusinessInvolvementTableIRes, i: number) => {
      console.log(val)
      return {
        Case:val.IncidentNumber,
        Agency:val.AgencyName,
        Title:val.Title,
        "Reported Date":formatDate(val.CreatedOn),
        Type:val.Type,
        Involvement:val.Involvement,
        ICR:val.ICR,
      };
    });
  }
}
