import React, { useEffect, useState } from "react";
import add from "../../../../../assets/icons/Icon.svg";
import "./Filters.css";
import { TextBoxComponent } from "../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../CommonComponents/Fields/Button/ButtonComponents";
import { FilterIProps } from "./FiltersI";
import { SummaryTabFunction } from "../Tabs/SummaryTab/SummaryTabFunction";
import {
  setHome_UnitDataIState,
  setRankDataIState,
} from "../Tabs/SummaryTab/SummaryTabInterface";
import { ButtonIState } from "../../../RolePermissionManagement/Permission/Components/Filter/FilterIProps";
import { setUsereditComponent } from "../../../../../Redux/UserReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetPerticularUserPermission } from "../../../../../hooks/Permission/useGetPerticularUserPermission";

export const Filter: React.FC<FilterIProps> = ({
  Badge,
  HomeUnit,
  Name,
  PostNo,
  Rank,
  Status,
  setBadge,
  setHomeUnit,
  setName,
  setPostNo,
  setRank,
  setStatus,
}) => {
  const { Create } = useGetPerticularUserPermission("User");
  const summaryTabFunction = new SummaryTabFunction();
  const [rankData, setRankData] = useState<setRankDataIState[]>([]);
  const [Home_Unit, setHome_UnitData] = useState<setHome_UnitDataIState[]>([]);
  useEffect(() => {
    summaryTabFunction.getRankData(setRankData);
    summaryTabFunction.getHome_UnitData(setHome_UnitData);
  }, []);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    if (
      (Name && Name.trim().length > 0) ||
      (Badge && Badge.trim().length > 0) ||
      (HomeUnit && HomeUnit.trim().length > 0) ||
      (Rank && Rank.trim().length > 0) ||
      (PostNo && PostNo.trim().length > 0) ||
      Status !== "1"
    ) {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [Name, Badge, Status, HomeUnit, PostNo, Rank]);

  const clearAllFilters = () => {
    setBadge("");
    setHomeUnit("");
    setName("");
    setStatus("1");
    setPostNo("");
    setRank("");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };
  const NavigateToAddPage = () => {
    dispatch(
      setUsereditComponent({
        editUserComponent: false,
        addUserComponent: true,
        viewUserComponent: false,
      })
    );
    navigation("/user/add");
  };
  return (
    <>
      <div className="Filter">
        <div className="filds">
          <TextBoxComponent
            fieldName={"Name"}
            value={Name}
            onChangeValue={setName}
          />
          <DropdownComponent
            dataSource={Home_Unit.map((rank) => ({
              id: rank.AgencyLevelCodeId,
              value: rank.LongCode,
            }))}
            fieldName={"Home Unit"}
            handleRowClick={setHomeUnit}
            value={HomeUnit}
          />
          <DropdownComponent
            dataSource={rankData.map((rank) => ({
              id: rank.AgencyLevelCodeId,
              value: rank.LongCode,
            }))}
            fieldName="Rank"
            handleRowClick={setRank}
            value={Rank}
          />
          <TextBoxComponent
            fieldName={"Post No"}
            value={PostNo}
            onChangeValue={setPostNo}
          />
          <TextBoxComponent
            fieldName={"Badge"}
            value={Badge}
            onChangeValue={setBadge}
          />
          <DropdownComponent
            fieldName={"Status"}
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            handleRowClick={setStatus}
            value={Status}
            allowclear={false}
            showTooltip={false}
          />
          <div className="clearbutton" style={{ width: "110%" }}>
            <ButtonComponents
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
          {Create && (
            <ButtonComponents
              handleClick={NavigateToAddPage}
              icon={add}
              name="Add new user"
              showBackgroundColor={true}
              textColor="white"
            />
          )}
        </div>
      </div>
    </>
  );
};
