import { useState } from "react";
import "./AddParticipantName.css";
import { AddNewName } from "./AddNewName/AddNewName";
import { AddBusinessName } from "./AddBusinessName/AddBusinessName";
import { useNavigate, useParams } from "react-router-dom";
import { BackButtonComponent } from "../../../../CommonComponents/BackButton/BackButtonComponent";
import { RadioComponent } from "../../../../CommonComponents/Fields/Radio/RadioComponent";
import { AddEditParticipantNameIProps } from "./AddEditParticipantNameI";
import Breadcrumbs from "../../../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { InvolvementMainIParams } from "./AddNewName/Involvement/InvolvementMainI";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../CommonComponents/breadcrumb/Breadcrumb";

export const AddEditParticipantName: React.FC<AddEditParticipantNameIProps> = ({
  defaultTab,
}) => {
  const { IncidentId } = useSelector((store: RootState) => store.CommonRedux);
  let incidentNumber = localStorage.getItem("Incidentnumber");
  const [value, setValue] = useState<number>(defaultTab ? defaultTab : 1);
  const navigation = useNavigate();
  const NavigateToUserPage = () => {
    navigation(`/incidents/${IncidentId}/participants`);
  };
  const { participantId } = useParams<InvolvementMainIParams>();
   const lastBreadcrumbTitle = participantId ? 'Edit' : 'Add';
  return (
    <>
      <div className="AddMasterName_main">
        <div className="AddMasterName_child">
          <BreadcrumbComponent
            fixedCrumbItems={[
              { title: "Incidents", path: "/incidents" },
              { title: incidentNumber ? incidentNumber : "", path: "" },
              {
                title: "Participants",
                path: `/incidents/${IncidentId}/participants`,
              },
              {
                title: lastBreadcrumbTitle,
                path: ``,
              },
            ]}
          />
          <BackButtonComponent
            name="Back to name details"
            onClick={NavigateToUserPage}
          />
          {participantId ? (
            <p className="header">Edit Participant </p>
          ) : (
            <p className="header">Add New </p>
          )}
          <div className="AddMasterName_checkbox_togal">
            {!defaultTab && (
              <RadioComponent
                value={value}
                onChangeValue={setValue}
                RadioTextList={["Add New Name", "Add Business Name "]}
              />
            )}
          </div>

          {(defaultTab ? defaultTab : value) == 1 ? (
            <AddNewName />
          ) : (
            <AddBusinessName />
          )}
        </div>
      </div>
    </>
  );
};
