import React, { useState, useEffect } from "react";
import { Loader } from "../../../../../../CommonComponents/Loader/Loader";
import { Table } from "../../../../../../CommonComponents/Table/Table";
import { coloum } from "./Config";
import { RolePolicyFunction } from "./RolePolicyFunction";
import { RolePolicyIParams, SetTableData } from "./RolePolicyI";
import { useParams } from "react-router-dom";
import { Submit } from "../../../../../../CommonComponents/Fields/Submit/Submit";
import { Filter } from "./Components/Filter";
import useDelayedEffect from "../../../../../../../hooks/useDelayedEffect";

export const RolePolicy = () => {

  const [tableData, setTableData] = useState<SetTableData[]>([]);
  const [FiltertableData, setFiltertableData] = useState<SetTableData[]>([]);
  const [GetrolepoliciesDataLength, setGetrolepoliciesDataLength] =
    useState<number>(0);
  const [PolicyName, setPolicyName] = useState<string>("");
  const [Status, setStatus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { roleId } = useParams<RolePolicyIParams>();
  const rolePolicyFunction = new RolePolicyFunction();
  const [changeValue, setChangeValue] = useState<boolean>(false);
  const [oneTimeRender, setoneTimeRender] = useState<boolean>(true);
  const selectRowCheckBox = (
    data: { [key: string]: string | number | boolean | null }[]
  ) => {
    rolePolicyFunction.selectRowCheckBox(
      FiltertableData,
      data,
      setFiltertableData,
      oneTimeRender,
      GetrolepoliciesDataLength,
      setChangeValue,
      setoneTimeRender,
      tableData
    );
  };

  const applyFilterOnUserpolicyTable = () => {
    rolePolicyFunction.applyFilterOnUserpolicyTable(
      Status,
      PolicyName,
      FiltertableData,
      setTableData
    );
  };

  const onCancel = () => {
    initialData();
  };

  const GetPolicyTableData = async () => {
    if (roleId) {
      await rolePolicyFunction.GetPolicyTableData(
        setTableData,
        setLoading,
        setFiltertableData,
        roleId,
        setGetrolepoliciesDataLength
      );
    }
  };

  const onSave = () => {
    if (roleId) {
      let body: { PolicyId: string }[] = [];
      FiltertableData.map((val) => {
        if (val.isSelected) {
          body.push({ PolicyId: val.ID });
        }
      });
      rolePolicyFunction.rolePolicyAdd(setLoading, roleId, body);
    }
  };

  const initialData = async () => {
    await GetPolicyTableData();
  };

  useEffect(() => {
    initialData();
  }, []);

  useDelayedEffect(applyFilterOnUserpolicyTable, 700, [PolicyName, Status]);

  return (
    <>
      <Loader loading={loading} />
      <Filter
        PolicyName={PolicyName}
        Status={Status}
        setPolicyName={setPolicyName}
        setStatus={setStatus}
      />
      <div className="table">
        <Table
          columns={coloum()}
          columnsValue={tableData}
          clientSideSorting={true}
          showPagination={false}
          selectRowCheckBox={selectRowCheckBox}
          allowMultipleRowSelection={true}
          cursorPointer={false}
              
        />
        { changeValue && (
          <div className="edit-button">
            <Submit onCancel={onCancel} onSave={onSave} />
          </div>
        )}
      </div>
    </>
  );
};
