import { MultipleAddressComponents } from "./MultipleAddress";
import { AddressIProps } from "../../../InvolvementMainI";

export const Address: React.FC<AddressIProps> = ({
  MainfieldsBusiness,
  setMainfieldsBusiness,
  businessValidation
}) => {
  return (
    <>
      <div className="AddressSCroll">
        <p className="subheader_">Address</p>
        <MultipleAddressComponents
          MainfieldsBusiness={MainfieldsBusiness}
          setMainfieldsBusiness={setMainfieldsBusiness}
          businessValidation={businessValidation}
        />
      </div>
    </>
  );
};
