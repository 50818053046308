import { Fetcher } from "../../../../../../../../../services/Fetcher";
import {
  ArrestPrimaryAddressIRes,
  GetRaceDataIState,
  InvolvementMainIState,
} from "../../InvolvementMainI";

const fetcher = new Fetcher();
export class ArrestInformationFunction {
  async ArrestCategoryCode(
    setArrestCategoryCode: (data: GetRaceDataIState[]) => void
  ) {
    const response = await fetcher.get(
      "/code/ncic/category/ArrestType"
    );
    let res: GetRaceDataIState[] = response.Data;
    setArrestCategoryCode(res);
  }

  async getArrestPrimaryAddress(
    incidentId: string,
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void
  ) {
    const response = await fetcher.post("/commonentity/getaddresses", {
      EntityType: "Incident",
      EntityId: incidentId,
      IsPrimary: true,
    });
    let res: ArrestPrimaryAddressIRes[] = response.Data;
    setMainfields({
      ...Mainfields,
      InvolvementTab: {
        ...Mainfields.InvolvementTab,
        Arrestinformation: {
          ...Mainfields.InvolvementTab.Arrestinformation,
          AddressType: res[0]?.AddressType,
          Apartment: res[0]?.HouseNumber,
          StateProvidence: res[0]?.NcicstateId,
          Street: res[0]?.Street2Name,
          StreetNumber: res[0]?.Street1Name,
          StreetPrefix: res[0]?.Ncicstreet1PrefixId,
          StreetSuffix: res[0]?.Ncicstreet1SuffixId,
          ZipCode: res[0]?.ZipCode,
          City: res[0]?.City,
          Country: res[0]?.NciccountryId,
          GridNumber: res[0]?.Grid,
        },
      },
    });
  }
}
