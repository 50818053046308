
import { format } from "date-fns";
import { ColDef } from "ag-grid-community";

export const coloum = (
 
): ColDef[] => {
  return [
    { field: "Type" },
    { field: "User Submitted" },
    { field: "Created On" },
   

   
  ];
};
