import {
  fields,
  setFormDataIState,
  setformDataValidationIState,
} from "./FormI";

export class FormFunction {
  async errorValidation(
    formData: setFormDataIState,
    setFormDataValidation: (data: setformDataValidationIState) => void,
    fieldsListing: fields[][]
  ) {
    let valdationerror: setformDataValidationIState = {};
    fieldsListing.map((fieldsListingitem) => {
      fieldsListingitem.map((fieldsDetail) => {
        if (fieldsDetail.required) {
          if (!formData[fieldsDetail.fieldName]) {
            valdationerror[fieldsDetail.fieldName] = true;
          } else {
            valdationerror[fieldsDetail.fieldName] = false;
          }
        }
      });
    });

    setFormDataValidation({ ...valdationerror });
  }

  checkRequiredFields(formData: setFormDataIState, fieldsListing: fields[][]) {
    let temp: boolean = true;
    fieldsListing.map((fieldsListingitem) => {
      fieldsListingitem.map((fieldsDetail) => {
        if (fieldsDetail.required) {
          if (!formData[fieldsDetail.fieldName]) {
            temp = false;
          }
        }
      });
    });
    return temp;
  }
}
