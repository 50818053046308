import { setIncidentId } from "../../../../Redux/CommonRedux";
import { Fetcher } from "../../../../services/Fetcher";
import { getConfigurationResponceData } from "../../../Pages/Configuration/Components/Menu/MenuI";
import { submenu, SubmenuResponce } from "./SidebarExpendI";

const fetcher = new Fetcher();
export class SidebarExpendFunction {
  async getConfigurationData(
    setDynamicSubMenuList: (data: SubmenuResponce[]) => void,
    IncidentId: string
  ) {
    let response = await fetcher.get(
      `/configuraton/b481379f-0262-4621-b938-49713f5c48ea/menu`
    );
    const DynamicSubMenuList: getConfigurationResponceData[] = response?.Data;
    setDynamicSubMenuList(
      DynamicSubMenuList?.map((val, i) => {
        return {
          id: val.Order,
          MenuId: val.MenuId,
          title: val.MenuName,
          path:
            `/incidents/` +
            IncidentId +
            "/" +
            val.MenuName?.toLowerCase()?.replace(/ /g, "-"),
        };
      }).sort((a, b) => a.id - b.id)
    );
  }

  async setincidenceSideBar(
    setsetSubMenuListshow: (data: boolean) => void,
    dispatch: (action: { type: string; payload: string }) => void,
    incidentId: string | undefined,
    setsetSubMenuList: (data: submenu[]) => void,
    DynamicSubMenuList: SubmenuResponce[],
    pathname: string
  ) {
    if (pathname.includes(`/incidents/`)) {
      setsetSubMenuListshow(true);
      if (incidentId) {
        dispatch(setIncidentId(incidentId));
      }
      setsetSubMenuList(
        DynamicSubMenuList.map((val) => {
          return {
            id: val.id,
            path: val.path,
            title: val.title,
          };
        })
      );
    } else if (pathname == `/incidents`) {
      setsetSubMenuListshow(false);
    }
  }
}
