import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface initialStateI {
  ipaddress: string;
  ModifiedByUserName: string;
  ModifiedOn: string;
  CreatedOn: string;
}
export interface setUserdataPayload {
  ipaddress: string;
  ModifiedByUserName: string;
  ModifiedOn: string;
  CreatedOn: string;
}

const initialState: initialStateI = {
  ipaddress: "",
  ModifiedByUserName: "",
  ModifiedOn: "",
  CreatedOn: "",
};

const slice = createSlice({
  name: "AuditReducer",
  initialState,
  reducers: {
    setUserdata(state, action: PayloadAction<setUserdataPayload>) {
      state.ipaddress = action.payload.ipaddress;
      state.ModifiedOn = action.payload.ModifiedOn;
      state.ModifiedByUserName = action.payload.ModifiedByUserName;
    },
  },
});

export const { setUserdata } = slice.actions;

export default slice.reducer;
