import { PhoneNumberIstate } from "../../../ExternalContact/ExternalContactI";
import { InvolvementMainIState } from "../../../Involvement/InvolvementMainI";

export class PhonenumberFunction {
  private Mainfields: InvolvementMainIState;
  private setMainfields: (data: InvolvementMainIState) => void;
  private setedit: (data: boolean) => void;
  private seteditList: (data: PhoneNumberIstate[]) => void;
  private setpreviewcount: (data: number) => void;

  constructor(
    Mainfields: InvolvementMainIState,
    setMainfields: (data: InvolvementMainIState) => void,
    setedit: (data: boolean) => void,
    seteditList: (data: PhoneNumberIstate[]) => void,
    setpreviewcount: (data: number) => void
  ) {
    this.Mainfields = Mainfields;
    this.setMainfields = setMainfields;
    this.setedit = setedit;
    this.seteditList = seteditList;
    this.setpreviewcount = setpreviewcount;
  }

  Save = () => {
    this.setedit(false);
    this.seteditList(this.Mainfields.IdentificationAndContact.PhoneNumber);
  };

  handleClick = () => {
    this.setMainfields({
      ...this.Mainfields,
      IdentificationAndContact: {
        ...this.Mainfields.IdentificationAndContact,
        onChange: true,
        PhoneNumber: [
          ...this.Mainfields.IdentificationAndContact.PhoneNumber.map((val) => {
            return {
              ...val,
              push: true,
            };
          }),
          {
            id:
              this.Mainfields.IdentificationAndContact.PhoneNumber[
                this.Mainfields.IdentificationAndContact.PhoneNumber.length - 1
              ].id + 1,
            Type: "",
            ExternalContactID: "",
            Country: "",
            Number: "",
            Ext: "",
            TypeDropdownvalue: "",
            push: false,
          },
        ],
      },
    });
  };

  ViewPreview = (id: number) => {
    this.setpreviewcount(id);
    this.setedit(true);
    this.seteditList(this.Mainfields.IdentificationAndContact.PhoneNumber);
  };

  DeletePreview = (id: number) => {
    this.setMainfields({
      ...this.Mainfields,
      IdentificationAndContact: {
        ...this.Mainfields.IdentificationAndContact,
        onChange: true,
        PhoneNumber:
          this.Mainfields.IdentificationAndContact.PhoneNumber.filter((val) => {
            if (val.id != id) {
              return val;
            }
          }).length
            ? this.Mainfields.IdentificationAndContact.PhoneNumber.filter(
                (val) => {
                  if (val.id != id) {
                    return val;
                  }
                }
              )
            : [
                {
                  id: 1,
                  Type: "",
                  ExternalContactID: "",
                  Country: "",
                  Number: "",
                  Ext: "",
                  TypeDropdownvalue: "",
                },
              ],
      },
    });

    this.setedit(false);
  };
}
