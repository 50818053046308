import React, { useEffect, useState } from 'react'
import { BackButtonComponent } from '../../../../CommonComponents/BackButton/BackButtonComponent'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonComponents } from '../../../../CommonComponents/Fields/Button/ButtonComponents';
import VehicleOverviewDesc from './VehicleOverviewDesc';
import VehicleOverviewRegis from './VehicleOverviewRegis';
import VehicleCategory from '../AddEditVehicles/VehicleCategory';
import add from "../../../../../assets/icons/Icon.svg"
import { VehicleOverviewFunction } from './VehicleOverviewFunction';
import { RouteParams, SetFormDataIState } from './VehicleOverviewIState';
import { Loader } from '../../../../CommonComponents/Loader/Loader';
import { useDispatch } from 'react-redux';
import { InvolvementMainIParams } from '../../Participant/AddEditParticipantName/AddNewName/Involvement/InvolvementMainI';
import BreadcrumbComponent from '../../../../CommonComponents/breadcrumb/Breadcrumb';
const VehicleOverviewForm = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    
  const { incidentId } = useParams<InvolvementMainIParams>();
    const VehicleOverview=new VehicleOverviewFunction();
    const [loading, setLoading] = useState<boolean>(false);
    const [incidenId, setIncidentId] = useState<string>("");
    const { vehicleId } = useParams<RouteParams>();
    let incidentNumber = localStorage.getItem("Incidentnumber");
    const [formData, setFormData] = useState<SetFormDataIState>({
        VehicleId: "",
        IncidentId: "",
        License: "",
        State: "",
        NcicmodelId:"",
        Style: "",
        CitationId: "",
        Ncictype: "",
        Ncicmake: "",
        TabYear: null,
        NcicstateId:"",
        CountryName:"",
        Grid:"",
        NciccolorPrimary: "",
        NciccolorSecondary: "",
        Vin: "",
        InsuranceAgencyCodeId: "",
        VehicleCondition: 0,
        GrossWeight: 0,
        RegisteredAddressId: null,
        PlateNumber: "",
        AdditionalDetails: "",
        OutOfCityRecovery: false,
        OutOfCityRecoveryCityState: "",
        PropertyOffenseId: "",
        NcicpropertyCategoryId: "",
        NcicModel:"",
        NcicpropertyCodeId: "",
        NcicvehicleInvolvement: "",
        CommercialVehicleIndicator: false,
        HazardousMaterialsIndicator: false,
        HazardousMaterial: "",
        IsParked: false,
        NoPlateIndicator: false,
        _21dayTempIndicator: false,
        CommercialDriverLicenseIndicator: false,
        VehicleOverWeightMeasure: false,
        PropertyId: "",
        ParticipantId: null,
        VehicleRegistrationId: "",
        NcicauthorityId: "",
        NciccountryId: "",
        Year: 0,
        Status: "",
        Restrictions: "",
        Ncicclass: "",
        ValidDates: "",
        RecovkedPlates: false,
         TabId: "",
        IsLien: false,
        LienHolder: "",
        LastName: "",
        FirstName: "",
        MiddleName: "",
        BusinessName: "",
        Suffix: "",
        DOB: "",
        IsPrimary: false,
    });
    useEffect(() => {
        VehicleOverview.getVehicleoverviewData( setLoading,incidenId,vehicleId,setFormData);
      
        if (vehicleId) {
          VehicleOverview.getVehicleoverviewData(
            setLoading,
            incidenId,
            vehicleId,
            setFormData
          );
        }
      }, []);
      const backButtonHandler = () => {
        navigation(`/incidents/${incidentId}/vehicles`);
      };
     
    return (
        <>
         <Loader loading={loading} />
            <div className="_vehicle_overview_form_container">
                <div className="_overview_form_upper_container">
                <BreadcrumbComponent
            fixedCrumbItems={[
                { title: "Incidents", path:  `/incidents` },
                { title: incidentNumber ? incidentNumber : "", path: "" },
                { title: "Vehicles", path:  `/incidents/${incidentId}/vehicles` },
              { title: "Overview", path: "" },
            ]}
          />
                    <div className="_back_btn">
                        <BackButtonComponent
                            name="Back to vehicles"
                            onClick={backButtonHandler}
                        />
                    </div>

                    <div className="_vehicle_overview_title_container">
                        <div className="_vehicle_overview_left_container">
                            <h3 style={{display:"flex",alignItems:"center"}}>{formData?.PlateNumber} <div style={{ margin: "0 10px", borderLeft: "1px solid #000", height: "20px" }}></div> {formData?.TabYear} {formData?.MakerName} {formData?.ModelName} <div style={{ margin: "0 10px", borderLeft: "1px solid #000", height: "20px" }}></div> {formData?.NciccolorPrimary}</h3>
                        </div>

                        <div className="_vehicle_overview_right_container">
                            <div className="_vehicle_right_btn_container">
                               
                            </div>
                        </div>
                    </div>
                </div>


                <div className="_vehicle_overview_description">
                    <VehicleOverviewDesc />
                </div>
                <div className="_vehicle_overview_registration_overview">
                   <VehicleOverviewRegis/>
                </div>
            </div>
        </>
    )
}

export default VehicleOverviewForm