import React, { useEffect, useState } from "react";
import { FilterIProps } from "./FilterIProps";
import { TextBoxComponent } from "../../../../../CommonComponents/Fields/TextBox/TextBoxComponent";
import { DropdownComponent } from "../../../../../CommonComponents/Fields/Dropdown/DropdownComponent";
import { ButtonComponents } from "../../../../../CommonComponents/Fields/Button/ButtonComponents";
import add from "../../../../../../assets/icons/Icon.svg";
export const Filter: React.FC<FilterIProps> = ({
  RoleName,
  setRoleName,
  Status,
  setStatus,
  addRole,
}) => {
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
  const [buttonTextColor, setButtonTextColor] = useState<string>("black");
  useEffect(() => {
    if ((RoleName && RoleName.trim().length > 0) || Status !== "1") {
      setIsAddButtonDisabled(false);
      setButtonTextColor("red");
    } else {
      setIsAddButtonDisabled(true);
      setButtonTextColor("black");
    }
  }, [RoleName, Status]);

  const clearAllFilters = () => {
    setRoleName("");
    setStatus("1");
    setIsAddButtonDisabled(true);
    setButtonTextColor("black");
  };

  return (
    <>
      <div className="Filter-audit">
        <div className="NCIC_drop">
          <TextBoxComponent
            fieldName="Search"
            value={RoleName}
            onChangeValue={setRoleName}
          />
          <DropdownComponent
            fieldName="Status"
            dataSource={[
              { id: "1", value: "Active" },
              { id: "2", value: "InActive" },
            ]}
            handleRowClick={setStatus}
            value={Status}
            allowclear={false}
            showTooltip={false}
          />
          <div className="clearbutton1">
            <ButtonComponents
              // icon={close}
              name="Clear all Filters"
              handleClick={clearAllFilters}
              disabled={isAddButtonDisabled}
              textColor={buttonTextColor}
              borderColor={isAddButtonDisabled ? "grey" : "red"}
            />
          </div>
        </div>
        <div className="clearbutton">
          {
            <ButtonComponents
              name="Add New Role"
              icon={add}
              handleClick={addRole}
              showBackgroundColor={true}
              textColor="white"
            />
          }
        </div>
      </div>
    </>
  );
};
