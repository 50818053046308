import "./TabsComponents.css";
import { TabsComponentsIProps } from "./TabsComponentsIProps";
import useValidateRoutes from "../../../hooks/useValidateRoutes";
import { ModalComponent } from "../Modal/ModalComponent";
import { SaveTheChangesPopup } from "../Modal/SaveTheChangesPopup/SaveTheChangesPopup";
import useSaveChangesPopup from "../../../hooks/useSaveChangesPopup";

export const TabsComponents: React.FC<TabsComponentsIProps> = ({
  activeTab,
  handleTabChange,
  tabList,
}) => {
  let ValidateRoutes = useValidateRoutes();
  const { showSaveTheChangesPopup, openPopup, onClose, onOk, setTemp, temp } =
    useSaveChangesPopup();

  return (
    <>
      <ModalComponent
        open={showSaveTheChangesPopup}
        title="Alert"
        innerContant={
          <SaveTheChangesPopup
            onClose={onClose}
            onOk={() => {
              onOk();
              if (typeof temp === "number") {
                handleTabChange(temp);
              }
            }}
            
          />
        }
         closeIconStyle={{
          backgroundColor: '#ffd9d9',
          border: '2px solid #ffd9d9',
          width: '30px',
          height: '30px',
        }}
      />

      
      <div>
        <div className="tab-line">
          <div className="tab-buttons">
            {tabList.map((val, i) =>
              val.name ? (
                <button
                  disabled={val.disable}
                  key={i}
                  style={{
                    color: "black",
                    cursor: val.disable ? "auto" : "pointer",
                  }}
                  className={activeTab === i ? "active" : ""}
                  onClick={() => {
                    if (ValidateRoutes) {
                      handleTabChange(i);
                    } else {
                      openPopup();
                      setTemp(i);
                    }
                  }}
                >
                  {val.name}
                </button>
              ) : (
                ""
              )
            )}
          </div>
        </div>

        <div className="head"></div>
      </div>
    </>
  );
};
