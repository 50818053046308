import { Fetcher } from "../../../../services/Fetcher";
import { successDeleteMessage } from "../../../../utils/const";
import { convertToISOFormat, formatDate } from "../../../../utils/Helper/Helper";
import { OrderByFieldName } from "./Config";
import {
  DetailedSearchData,
  getMasterNameIRes,
  OrderByType,
  SetTableData,
} from "./MasterNameIndexListingI";

const fetcher = new Fetcher();
export class ParticipantListFunction {
  async getMasterListTableData(
    setTableData: (data: SetTableData[]) => void,
    setLoading: (data: boolean) => void,
    setTableTotalRecord: (data: number) => void,
    PageNumber: number,
    pageCount: number,
    Name: string,
    EntityType: string,
    OrderByDescending?: boolean,
    OrderBy?: string,
    formData?: DetailedSearchData
  ) {
    setLoading(true);

    let response = await fetcher.post(`/name-master/search`, {
      PageNumber: PageNumber,
      PageSize: pageCount,
      Name: Name ? Name : null,
      EntityType: EntityType ? EntityType : "All",
      ...(OrderByDescending != null ? { OrderByDescending } : {}),
      OrderBy: OrderByFieldName[OrderBy as OrderByType],
      PersonSurName: formData?.PersonSurName ? formData.PersonSurName : null,
      PersonGivenName: formData?.PersonGivenName
        ? formData.PersonGivenName
        : null,
      PersonMiddleName: formData?.PersonMiddleName
        ? formData.PersonMiddleName
        : null,
      SuffixCodeId: formData?.SuffixCodeId ? formData.SuffixCodeId : null,
      DOB: formData?.DOB ? convertToISOFormat(formData?.DOB) : null,
      Age: formData?.Age ? formData.Age : null,
      RaceCodeId: formData?.RaceCodeId ? formData.RaceCodeId : null,
      Gender: formData?.Gender ? formData.Gender : null,
      AddressType: formData?.AddressType ? formData.AddressType : null,
      StreetPrefix: formData?.StreetPrefix ? formData.StreetPrefix : null,
      Street: formData?.Street ? formData.Street : null,
      StreetSuffix: formData?.StreetSuffix ? formData.StreetSuffix : null,
      Apartment: formData?.Apartment ? formData.Apartment : null,
      City: formData?.City ? formData.City : null,
      StateProvidence: formData?.StateProvidence
        ? formData.StateProvidence
        : null,
      ZipCode: formData?.ZipCode ? formData.ZipCode : null,
      Country: formData?.Country ? formData.Country : null,
      StreetNumber: formData?.StreetNumber ? formData.StreetNumber : null,
      Grid: formData?.Grid ? formData.Grid : null,
      Height:formData?.Height ? Number(formData?.Height) : null,
      Weight: formData?.Weight ? Number(formData?.Weight) : null,
      PhoneTypeCodeId:formData?.PhoneTypeCodeId ? formData?.PhoneTypeCodeId :null,
      CountryCodeId:formData?.CountryCodeId ? formData?.CountryCodeId :null,
      PhoneNumber:formData?.PhoneNumber ? formData?.PhoneNumber :null,
      Ext:formData?.Ext ? formData?.Ext : null
    });
    console.log(response);
    const TableJson = this.createTableJson(response?.Data);

    if (setTableTotalRecord) {
      setTableTotalRecord(response?.TotalRecords);
    }
    setTableData(TableJson);
    setLoading(false);
  }

  createTableJson(data: getMasterNameIRes[]) {
    return data?.map((val: getMasterNameIRes, i: number) => {
      return {
        "First Name": val.PersonGivenName,
        "Last Name/Business Name":
          val.PersonSurName +
          (val.BusinessType ? ` (${val.BusinessType})` : ""),
        "Middle Name": val.PersonMiddleName,
        DOB: val.DOB ? formatDate(val.DOB) : "",
        Age: val.Age,
        Address: val.StreetAddress,
        City: val.City,
        State: val.State,
        nameId: val.NameId,
        EntityType: val.EntityType,
      };
    });
  }
}
